<template>
    <div v-if="!items" class="loading">
        <i class="el-icon-loading" />
    </div>
    <div v-else-if="items.length" class="list">
        <ul>
            <li v-for="item of reduced_items" :key="item.id">
                <component
                    :is="component"
                    :data="item"
                    class="suggestion-item"
                    :is_selected="selected_item === item.id"
                    @click="selectItem(item)"
                    @mouseenter="hoverItem(item)"
                />
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'suggestion-list',
    props: {
        items: {
            type: Array,
            default: () => null,
        },
        component: {
            type: Object,
            default: () => null,
        },
        command: {
            type: Function,
            default: () => null,
        },
        client_rect: {
            type: Object,
            default: () => null,
        },
        max_displayed_items: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            selected_item: null,
        };
    },
    watch: {
        items: {
            handler(newVal) {
                if (newVal?.length) {
                    this.selected_item = newVal[0].id;
                }
            },
            immediate: true,
        },
    },
    computed: {
        selected() {
            if (!this.selected_item) return null;
            return this.items.find((item) => item.id === this.selected_item);
        },
        selected_index() {
            if (!this.selected_item) return 0;
            return this.items.findIndex(
                (item) => item.id === this.selected_item
            );
        },
        items_start() {
            if (this.selected_index >= this.max_displayed_items) {
                return this.selected_index - this.max_displayed_items + 1;
            }
            return 0;
        },
        items_end() {
            return this.items_start + this.max_displayed_items;
        },
        reduced_items() {
            return this.items.slice(this.items_start, this.items_end);
        },
    },
    methods: {
        selectItem(item) {
            this.command({id: item});
        },
        hoverItem(item) {
            this.selected_item = item.id;
        },
        onKeyDown({event}) {
            if (
                event.key === 'ArrowDown' ||
                (event.key === 'Tab' && !event.shiftKey)
            ) {
                return this.handleSelectNextItem();
            } else if (
                event.key === 'ArrowUp' ||
                (event.key === 'Tab' && event.shiftKey)
            ) {
                return this.handleSelectPreviousItem();
            }

            if (event.key === 'Enter' && this.selected) {
                this.command({id: this.selected});
                return true;
            }

            return false;
        },
        handleSelectNextItem() {
            if (!this.selected_item) {
                this.selected_item = this.items[0].id;
                return true;
            }

            const index = this.items.findIndex(
                (i) => i.id === this.selected_item
            );

            if (this.items.length > index + 1) {
                this.selected_item = this.items[index + 1].id;
            } else {
                this.selected_item = this.items[0].id;
            }

            return true;
        },
        handleSelectPreviousItem() {
            if (!this.selected_item) {
                this.selected_item = this.items[0].id;
                return true;
            }

            const index = this.items.findIndex(
                (i) => i.id === this.selected_item
            );

            if (index - 1 >= 0) {
                this.selected_item = this.items[index - 1].id;
            } else {
                this.selected_item = this.items[this.items.length - 1].id;
            }

            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.loading {
    position: relative;
    color: $white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 70px;
}

.list {
    background-color: white;
    position: relative;
    box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.07),
        0px 12px 60px 0px rgba(0, 0, 0, 0.07),
        0px 12px 32px -16px rgba(0, 0, 0, 0.05);
    min-width: 330px;
    padding: 4px;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            padding: 0;
            margin: 0;
            border-radius: 3px;
            overflow: hidden;
        }
    }

    .suggestion-item {
        min-height: 36px;
        padding: 0 10px;

        &.selected {
            color: $white;
            background-color: $blue-dark;
        }
    }
}
</style>
