<template>
    <div
        class="notification"
        :class="{read: notification.read}"
        @click="handleNavigate"
    >
        <face-pile :users="users" size="mini" hide_tooltip />

        <div class="content">
            <span class="title">{{ notification.title }}</span>
            <span class="description"
                ><todo-item-content
                    :text="notification.description"
                ></todo-item-content
            ></span>
            <span class="timestamp">
                {{ notification.created_at.toDate() | timeAgo }}
            </span>
        </div>
    </div>
</template>

<script>
import FacePile from '@/components/generic/FacePile';

import TodoItemContent from '@/components/todo/TodoItemContent';

export default {
    name: 'notification-list-item',
    components: {
        FacePile,
        TodoItemContent,
    },
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    computed: {
        users() {
            return this.notification.users.map((user) => {
                return this.$store.getters.userWithId(user);
            });
        },
    },
    methods: {
        handleNavigate() {
            this.$emit('click:notification', this.notification);
        },
    },
};
</script>

<style lang="scss" scoped>
.notification {
    display: flex;
    flex-direction: row;
    background-color: rgba($blue, 0.2);
    cursor: pointer;
    border: 1px solid transparent;
    box-sizing: border-box;
    padding: 10px 20px 10px 0;
    border-bottom: 1px solid $white;

    &.read {
        background-color: transparent;
        border-bottom: 1px solid $border-grey-light;
    }

    &:hover,
    &.read:hover {
        background-color: rgba($blue, 0.05);
    }

    .facepile {
        padding: 2px 13px 0 18px;
        max-width: 30px;
    }

    .content {
        display: flex;
        flex-direction: column;
        flex: 1;

        .title {
            color: $black;
            font-weight: bold;
            font-size: 12px;
            margin-bottom: 8px;
        }

        ::v-deep .description {
            color: $black;
            font-size: 12px;
            margin-bottom: 5px;
            * {
                font-size: 12px;
            }
        }

        .timestamp {
            margin-top: 4px;
            color: rgba($black, 0.6);
            font-size: 9px;
            text-align: right;
        }
    }
}
</style>
