<template>
    <suggestion-list
        ref="list"
        :items="items"
        :component="component"
        :client-rect="client_rect"
        v-bind="$props"
    />
</template>

<script>
import SuggestionList from '../editor/SuggestionList.vue';
import IssueSuggestionListItem from './IssueSuggestionListItem.vue';

export default {
    name: 'issue-suggestion-list',
    components: {
        SuggestionList,
    },
    props: {
        items: {
            type: Array,
            default: () => null,
        },
    },
    computed: {
        component() {
            return IssueSuggestionListItem;
        },
        client_rect() {
            if (this.$props.editor) {
                return this.$props.editor.view.coordsAtPos(
                    this.$props.editor.state.selection.from
                );
            }
            return null;
        },
    },
    methods: {
        onKeyDown(props) {
            return this.$refs.list?.onKeyDown(props);
        },
    },
};
</script>
