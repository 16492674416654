<template>
    <div class="container">
        <div v-if="show_header" class="header">
            <div v-for="env of environments" :key="env.name" class="env">
                <div class="name">{{ env.name }}</div>
            </div>
        </div>
        <div class="all" :class="{release: is_release}">
            <div
                v-for="(leaf, idx) of node"
                :key="leaf ? `${leaf.env}-${leaf.id}` : idx"
                class="leaf"
                :class="{'has-left': hasLeft(idx), 'has-right': hasRight(idx)}"
            >
                <div class="left" :class="{master: isMasterLeft(idx)}"></div>
                <div v-if="leaf">
                    <div class="name" :class="{[buildClass(leaf)]: true}">
                        <span v-if="leaf.build && leaf.build.number">
                            #{{ leaf.build.number }}
                        </span>
                        <span v-else>{{ leaf.id }}</span>
                    </div>

                    <div
                        v-if="active_count === 1"
                        class="build-info inline"
                        :class="{[buildClass(leaf)]: true, release: is_release}"
                    >
                        <div class="left"></div>
                        <div class="content">
                            <div v-if="is_release" class="version">
                                {{ parent.release.version }}
                            </div>
                            <div class="title">{{ title }}</div>
                        </div>
                        <div class="right" />
                    </div>
                </div>
                <div class="right" :class="{master: isMasterRight(idx)}" />
            </div>
        </div>
        <div
            v-if="active_count > 1"
            class="build-info"
            :class="{release: is_release}"
        >
            <div class="top line" />
            <div class="content">
                <div v-if="is_release" class="version">
                    {{ parent.release.version }}
                </div>
                <div class="title">{{ title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'repo-tree-node',
    components: {},
    props: {
        node: {
            type: Array,
            default: () => [],
        },

        environments: {
            type: Array,
            default: () => [],
        },

        show_header: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        active_count() {
            return this.node.filter((n) => !!n).length;
        },
        parent() {
            // Find node with release
            const f1 = this.node.find((n) => {
                if (!n) return false;
                return n.release;
            });

            if (f1) return f1;

            const f = this.node.find((n) => {
                if (!n) return false;
                return this.node.find((n2) => {
                    if (!n2) return;
                    return n2.source_hash === n.merge_hash;
                });
            });

            if (!f) {
                return this.node.find((n) => !!n);
            }

            return f;
        },

        is_release() {
            return !!this.parent.release;
        },
        title() {
            return this.parent.release
                ? this.parent?.release?.note?.title
                : this.parent.title;
        },
    },

    methods: {
        buildClass(leaf) {
            if (!leaf.build) {
                return 'loading';
            }

            return leaf.build.status.toLowerCase();
        },
        hasLeft(idx) {
            if (idx <= 0) return false;

            if (!this.node[idx]) {
                if (idx - 1 >= 0) {
                    if (this.node[idx - 1]) {
                        if (this.hasRight(idx - 1)) return true;
                    }
                }
                return false;
            }

            for (let i = idx - 1; i >= 0; i--) {
                if (this.node[i]) return true;
            }

            if (
                this.node[idx].source_branch ===
                this.environments[idx - 1].branch
            ) {
                return true;
            }

            return false;
        },

        hasRight(idx) {
            if (idx >= this.node.length - 1) return false;

            if (!this.node[idx]) {
                if (idx + 1 < this.node.length) {
                    if (this.node[idx + 1]) {
                        if (this.hasLeft(idx + 1)) return true;
                    }
                }
                return false;
            }

            for (let i = idx + 1; i < this.node.length; i++) {
                if (this.node[i]) return true;
            }

            if (
                this.node[idx].source_branch ===
                this.environments[idx + 1].branch
            ) {
                return true;
            }

            return false;
        },

        isMasterLeft(idx) {
            if (!this.node[idx]) return false;
            if (this.hasLeft(idx)) {
                for (let i = idx - 1; i >= 0; i--) {
                    if (
                        this.node[i] &&
                        this.node[idx].merge_hash === this.node[i].source_hash
                    )
                        return true;
                }
            }

            return false;
        },

        isMasterRight(idx) {
            if (!this.node[idx]) return false;

            if (this.hasRight(idx)) {
                for (let i = idx + 1; i < this.node.length; i++) {
                    if (
                        this.node[i] &&
                        this.node[idx].merge_hash === this.node[i].source_hash
                    )
                        return true;
                }
            }

            return false;
        },
    },
};
</script>
<style scoped lang="scss">
$lineWidth: 2px;
$lineColor: lighten(#000, 70%);

$dividerWidth: 20px;
$dividerHeight: 100px;

.container {
    position: relative;
}

.build-info {
    display: flex;
    position: absolute;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -($dividerHeight / 4);
    font-size: 12px;

    &.release {
        .top {
            background-color: $blue;

            &:after {
                color: $blue;
            }
        }

        .content {
            border-color: $blue;
        }
    }

    .top {
        width: $lineWidth;
        background-color: $lineColor;
        height: $dividerHeight / 4;
        position: absolute;
        z-index: 1;
        top: 0;
        margin-top: -($dividerHeight / 4);
        margin-left: -$lineWidth / 2;

        &:after {
            content: '▼';
            position: absolute;
            width: 100%;
            width: 40px;
            text-align: center;
            font-size: 14px;
            line-height: 0;
            margin-left: -20px + ($lineWidth / 2);
            margin-top: $dividerHeight / 8;
            color: $lineColor;
        }
    }

    &.inline {
        width: auto;
        margin-top: 0;
        position: relative;
    }

    &.loading .content {
        border-color: $grey;
    }
    &.successful .content {
        border-color: $green;
    }
    &.failed .content {
        border-color: $red;
    }
    &.stopped .content {
        border-color: $orange;
    }

    &.inprogress .content {
        border-color: $blue;
    }

    .content {
        display: flex;
        align-items: center;
        border: $lineWidth solid $lineColor;
        background-color: $white;
        z-index: 100;

        .title {
            padding: 4px 20px;
        }

        .version {
            text-align: center;
            padding: 4px 10px;
            background-color: $blue;
            color: $white;
        }
    }

    &.release.inline .content {
        margin-top: 4px;
        border-color: $blue;
    }
}

.header {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    padding: 4px;
    font-size: 14px;

    .env {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;

        .name {
            color: $white;
            background-color: lighten(#000, 50%);
            padding: 4px 20px;
            font-size: 12px;
            font-weight: bold;
        }
    }
}

.all {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-bottom: $lineWidth;

    .leaf {
        display: flex;
        position: relative;
        flex: 1;
        align-items: center;
        justify-content: center;
        height: $dividerHeight;

        &:before {
            position: absolute;
            content: '';
            border-left: $lineWidth dashed $lineColor;
            width: $lineWidth;
            height: 100%;
        }

        .name {
            position: relative;
            font-size: 12px;
            color: white;
            background-color: lighten(#000, 70%);
            padding: 4px 20px;
            text-align: center;
            font-weight: 600;
            z-index: 10;

            &.loading {
                background-color: $grey;
            }

            &.successful {
                background-color: $green;
                border: 1px solid $green;
            }

            &.failed {
                background-color: $red;
            }

            &.stopped {
                background-color: $orange;
            }

            &.inprogress {
                background-color: $blue;
            }
        }

        .left {
            flex: 1;
            height: $lineWidth;
        }

        .right {
            flex: 1;
            height: $lineWidth;
        }

        &.has-left {
            .left {
                background-color: $lineColor;
            }
        }
        &.has-right {
            .right {
                background-color: $lineColor;
            }
        }

        .right,
        .left {
            position: relative;

            &.master {
                &:before {
                    position: absolute;
                    content: '➤';
                    right: 50%;
                    font-size: 18px;
                    line-height: 0px;
                    margin-top: $lineWidth / 2;
                    color: $lineColor;
                }
            }
        }

        .left {
            &.master {
                &:before {
                    transform: scale(-1);
                    right: 50%;
                }
            }
        }
    }

    &.release {
        .has-left {
            .left {
                background-color: $blue;

                &.master {
                    &:before {
                        color: $blue;
                    }
                }
            }
        }
        .has-right {
            .right {
                background-color: $blue;

                &.master {
                    &:before {
                        color: $blue;
                    }
                }
            }
        }
    }
}
</style>
