<template>
    <fullscreen-loader message="Connecting to Hubspot..." />
</template>

<script>
import FullscreenLoader from '@/components/generic/FullscreenLoader';
import {Notification} from 'element-ui';
import {firebaseApp} from '@/utils/firebase';
import {handleCallbackFromHubspot} from '@/api/hubspot-auth';

export default {
    name: 'hubspot',
    components: {
        FullscreenLoader,
    },
    data() {
        return {};
    },
    created() {
        // wait for user auth before calling auth
        firebaseApp.auth().onAuthStateChanged(() => {
            this.authenticate();
        });
    },
    methods: {
        refreshUser() {
            this.$store
                .dispatch('Refresh', true)
                .then(async () => {
                    Notification({
                        type: 'success',
                        title: 'Success',
                        message: 'Hubspot connected!',
                    });

                    this.navigateToHome();
                })
                .catch(() => {});
        },
        async authenticate() {
            const {code, error, error_description} = this.$route.query;

            this.clearQueryString();

            if (error || !code) {
                console.log('Received invalid response from Hubspot');
                Notification({
                    type: 'error',
                    title: 'Unable to connect to Hubspot',
                    message:
                        'Please try authenticating again.' +
                        (error_description || error
                            ? ` (Error: ${error_description || error})`
                            : ''),
                });
                this.navigateToHome();
                return;
            }

            try {
                const result = await handleCallbackFromHubspot(code);
                if (result.success) {
                    this.refreshUser();
                }
            } catch (e) {
                Notification({
                    type: 'error',
                    title: 'Unable to connect to Hubspot',
                    message: 'Please try authenticating again.',
                });
            }

            this.navigateToHome();
        },
        clearQueryString() {
            window.history.replaceState(null, '', window.location.pathname);
        },
        navigateToHome() {
            this.$router.push({name: 'home'}).catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped></style>
