<template>
    <div class="todo-list">
        <content-block :title="todosTitle" no_background>
            <template slot="title_content_suffix">
                <loading-icon
                    v-if="is_atlassian_authed && jira_loading"
                    icon="jira"
                />
                <loading-icon
                    v-if="is_atlassian_authed && confluence_loading"
                    icon="confluence"
                />
            </template>
            <template slot="right">
                <span v-if="is_atlassian_authed" class="jira-info">
                    Backlog issues are hidden.
                </span>
            </template>

            <todo-input
                v-if="
                    $store.getters.is_feature_enabled(SUPPORTED_FEATURES.TODOS)
                "
                :force_project_id="project?.id"
            />

            <el-alert
                v-if="jira_forbidden"
                class="jira_forbidden_alert"
                description="You do not have access to this project's jira board."
                :closable="false"
            />
            <placeholder v-if="has_nothing && !loading">
                <span slot="title">You smashed all your tasks!</span>
            </placeholder>
            <todo-section
                v-for="(group, idx) of grouped_items"
                :key="`section_${idx}`"
                :todos="group.todos"
                :tasks="group.tasks"
                :issues="group.issues"
                :label="group.name"
                :project_ref="group.project_ref"
            />
        </content-block>
    </div>
</template>

<script>
import Placeholder from '@/components/generic/Placeholder';
import TodoSection from '@/components/todo/TodoSection';

import atlassianMixin from '@/mixins/atlassian.mixin';
import userMixin from '@/mixins/user.mixin';
import todoMixin from '@/mixins/todo.mixin';
import TodoInput from '@/components/todo/TodoInput.vue';
import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';
import ContentBlock from '@/components/blocks/ContentBlock.vue';
import LoadingIcon from '@/components/generic/LoadingIcon.vue';

export default {
    name: 'todo-list',
    components: {
        LoadingIcon,
        ContentBlock,
        TodoInput,
        Placeholder,
        TodoSection,
    },
    mixins: [atlassianMixin, userMixin, todoMixin],
    computed: {
        SUPPORTED_FEATURES() {
            return SUPPORTED_FEATURES;
        },
        project() {
            if (this.$route.params.project_id) {
                return this.$store.getters.projectWithId(
                    this.$route.params.project_id
                );
            }
            return null;
        },
    },
    watch: {
        project(val, oldVal) {
            if (val?.id !== oldVal?.id) this.init();
        },
    },
    async created() {
        if (this.project) await this.init();
    },
    methods: {
        async init() {
            if (this.project) await this.refreshCachedIssues();
        },
    },
};
</script>

<style lang="scss" scoped>
.user.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;

    .name {
        margin-left: 10px;
        font-size: 13px;
        font-weight: 600;
    }
}

.jira_forbidden_alert {
    margin-top: 20px;
}

.section {
    padding: 20px 20px 10px 20px;

    user-select: none;

    ul {
        margin: 0;
        padding: 0;

        li {
            user-select: none;
        }
    }

    @media screen and (max-width: 992px) {
        padding: 20px 0px 10px 0px;
    }
}

.jira-info {
    font-size: 9px;
}
</style>
