<template>
    <placeholder-block
        v-if="is_template"
        label="Cover sheet"
        :block="block"
        :can_delete="false"
    />
    <el-card v-else class="content-block coversheet" shadow="never">
        <div slot="header" class="heading">
            <div class="grip">⣿</div>
            <span>Summary cover sheet</span>
        </div>

        <el-skeleton v-if="!project" :rows="1" animated />

        <div v-else>
            <div class="title">Project - {{ project.name }}</div>
            <div class="desc">
                <span>{{ project.ref }}</span>
            </div>
        </div>
    </el-card>
</template>

<script>
import PlaceholderBlock from '@/pages/documents/components/PlaceholderBlock';

export default {
    name: 'summary-cover-sheet-block',
    components: {PlaceholderBlock},
    props: {
        project: {
            type: Object,
            default: null,
        },
        is_template: {
            type: Boolean,
            required: true,
        },
        block: {
            type: Object,
            required: true,
        },
    },
    computed: {
        clientAddress() {
            if (this.client?.addresses?.length) {
                return this.client.addresses[0];
            }
            return {
                address: null,
                city: 'Adelaide',
                region: 'SA',
                postcode: '5000',
            };
        },
    },
    methods: {
        deleteBlock() {
            this.$emit('delete', this.block.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.content-block {
    margin-bottom: 10px;
}
.heading {
    display: flex;
    align-items: center;

    .grip {
        width: 20px;
        height: 23px;
        text-align: left;
        opacity: 0.25;
        user-select: none;
        cursor: grab;
    }

    span {
        font-weight: bold;
        font-size: 14px;
        color: $blue;
        display: flex;
        flex: 1;
    }
}

.title {
    font-weight: bold;
}

.desc {
    margin-bottom: 20px;

    .invalid {
        font-weight: bold;
        font-style: italic;
        color: $red;
    }
}
</style>
