import {isNumeric} from '@/utils';

const estimateTaskMixin = {
    methods: {
        task_cost(task, ignoreOverride = false) {
            return task.rate * this.task_qty(task, ignoreOverride);
        },
        task_qty(task, ignoreOverride = false) {
            let qty = 0;
            // Check if the qty has been overridden
            if (!ignoreOverride && isNumeric(task.override?.qty)) {
                qty = task.override.qty;
            }
            // Otherwise use the initial value
            else if (task.qty) {
                qty = task.qty;
            }
            return qty;
        },
        task_hours(task, ignoreOverride = false) {
            let hours = this.task_qty(task, ignoreOverride);
            if (task.unit === 'day') hours = hours * task.hours_per_day;
            return Number(hours.toFixed(1));
        },
        task_days(task, ignoreOverride = false) {
            let days = this.task_qty(task, ignoreOverride);
            if (task.unit !== 'day') days = days / task.hours_per_day;
            return Number(days.toFixed(1));
        },
    },
};

export default estimateTaskMixin;
