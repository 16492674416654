<template>
    <div
        class="issue-tag"
        :class="{dark: is_dark_mode}"
        @click.stop.prevent="onClick"
    >
        <issue-item-compact :data="data" :show_summary="show_summary" />

        <button @click.stop="onRemove">
            <i class="el-icon-close" />
        </button>
    </div>
</template>

<script>
import IssueItemCompact from '@/components/issues/IssueItemCompact.vue';

export default {
    components: {IssueItemCompact},
    props: {
        data: {
            type: Object,
            required: true,
        },
        is_dark_mode: {
            type: Boolean,
            default: false,
        },
        show_summary: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        onClick() {
            this.$emit('click', this.data);
        },
        onRemove() {
            this.$emit('remove', this.data);
        },
    },
};
</script>

<style lang="scss" scoped>
.issue-tag {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 28px;
    box-sizing: border-box;
    max-width: 250px;
    padding: 0 3px 0 4px;
    border-radius: 3px;
    border: 1px solid rgba($black, 0.12);
    gap: 6px;

    button {
        cursor: pointer;
        background-color: transparent;
        border: none;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        padding: 0;
        width: 20px;
        height: 20px;

        i {
            color: rgba($black, 0.5);
            font-size: 14px;
        }

        &:hover {
            background-color: rgba($black, 0.07);

            i {
                color: rgba($black, 0.7);
            }
        }
    }
}
</style>

<style lang="scss">
.dark .issue-tag {
    color: $white;
    background-color: rgba($white, 0.1);
    border-color: rgba($white, 0.1);

    button {
        i {
            color: rgba($white, 0.5);
        }

        &:hover {
            background-color: rgba($white, 0.12);

            i {
                color: $white;
            }
        }
    }
}
</style>
