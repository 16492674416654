<template>
    <div />
</template>
<script>
import {COMMAND_DEPENDENCIES} from '@/enums';

export default {
    // command attributes (referenced externally)
    command_props: {
        dependencies: [COMMAND_DEPENDENCIES.PROJECT],
        prompt: 'Select a project for this estimate',
    },

    name: 'new-estimate-command',
    components: {},
    props: {
        provided_data: {
            type: Object,
            required: true,
        },
        search_string: {
            type: String,
            required: false,
            default: null,
        },
    },
    watch: {
        provided_data: {
            handler(data) {
                // navigate to the new estimate page for the selected project
                if (data[COMMAND_DEPENDENCIES.PROJECT]) {
                    const path = `/project/${
                        data[COMMAND_DEPENDENCIES.PROJECT].value.id
                    }/estimate/new`;
                    // navigating to the current path throws an exception, which we're ignoring
                    this.$router.push(path).catch((e) => {});
                    this.$emit('close');
                }
            },
        },
    },
};
</script>
