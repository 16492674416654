<template>
    <div v-if="has_links" class="ref-links" @click.stop.prevent>
        <el-popover
            v-model="linksVisible"
            placement="bottom"
            trigger="click"
            popper-class="options-popover"
        >
            <div slot="reference" class="icon">
                <i class="el-icon-link" />
            </div>
            <ref-link-button
                v-for="(link, index) in links"
                :key="`${link.key}_${index}`"
                :link_key="link.key"
                :link_value="link.value"
            />
        </el-popover>
    </div>
</template>

<script>
import RefLinkButton from '@/components/generic/RefLinkButton';

export default {
    name: 'component-ref-links',
    components: {RefLinkButton},
    props: {
        component: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            linksVisible: false,
        };
    },
    computed: {
        links() {
            const keys = Object.keys(this.component?.ref_links ?? {});
            const result = [];
            keys.forEach((key) => {
                const value = this.component.ref_links[key];
                if (Array.isArray(value)) {
                    // if this key is an array, add each index as its own link
                    this.component.ref_links[key].forEach((val, index) => {
                        result.push({key, value: val});
                    });
                } else {
                    result.push({key, value});
                }
            });
            return result;
        },
        has_links() {
            return this.links.length > 0;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.ref-links {
    margin: 1px 0 0 10px;
    background-color: $white;
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        font-size: smaller;
        margin-top: -1px;
        display: block;
    }

    &:hover {
        background-color: $black;

        i {
            color: $white;
        }
    }
}
</style>
