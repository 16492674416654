/**
 * A mixin for any selector within the command palette.
 * The Component must have a data/computed entry for 'results'
 */
export default {
    data() {
        return {
            selected_index: -1,
            page_size: 6,
        };
    },
    computed: {
        selected_item() {
            if (!this.selectable_results.length) return null;
            if (this.selectable_results.length === 1)
                return this.selectable_results[0];
            if (this.selected_index < 0) return null;
            return this.selectable_results[this.selected_index];
        },
    },
    methods: {
        selectItem(event) {
            if (event) {
                switch (event.srcKey) {
                    case 'top':
                        this.selectFirst();
                        break;
                    case 'pageup':
                        this.decreaseSelectedIndexByPage();
                        break;
                    case 'up':
                        this.decreaseSelectedIndex();
                        break;
                    case 'down':
                        this.increaseSelectedIndex();
                        break;
                    case 'pagedown':
                        this.increaseSelectedIndexByPage();
                        break;
                    case 'bottom':
                        this.selectLast();
                        break;
                }
            }
        },

        decreaseSelectedIndex() {
            if (this.selected_index > 0) {
                this.selected_index--;
                this.scrollToSelected();
            }
        },
        increaseSelectedIndex() {
            if (this.selected_index < this.selectable_results.length - 1) {
                this.selected_index++;
                this.scrollToSelected();
            }
        },
        decreaseSelectedIndexByPage() {
            if (this.selected_index > 0) {
                this.selected_index = Math.max(
                    this.selected_index - this.page_size,
                    0
                );
                this.scrollToSelected();
            }
        },
        increaseSelectedIndexByPage() {
            if (this.selected_index < this.selectable_results.length - 1) {
                this.selected_index = Math.min(
                    this.selected_index + this.page_size,
                    this.selectable_results.length - 1
                );
                this.scrollToSelected();
            }
        },
        selectFirst() {
            this.selected_index = 0;
            this.scrollToSelected();
        },
        selectLast() {
            this.selected_index = this.selectable_results.length - 1;
            this.scrollToSelected();
        },
        scrollToSelected() {
            this.$refs[this.selected_item.id][0].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        },
    },
};
