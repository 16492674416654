<template>
    <div />
</template>

<script>
import {COMMAND_DEPENDENCIES} from '@/enums';
import {startTimer, stopAllTimers} from '@/api/sessions';
import userMixin from '@/mixins/user.mixin';

export default {
    // command attributes (referenced externally)
    command_props: {
        dependencies: [COMMAND_DEPENDENCIES.TIMER],
        prompt: 'Timers',
    },

    name: 'timer-command',
    components: {},
    mixins: [userMixin],
    props: {
        provided_data: {
            type: Object,
            required: true,
        },
        search_string: {
            type: String,
            required: false,
            default: null,
        },
    },
    watch: {
        provided_data: {
            async handler(data) {
                if (data[COMMAND_DEPENDENCIES.TIMER]) {
                    if (
                        data[COMMAND_DEPENDENCIES.TIMER].started ||
                        data[COMMAND_DEPENDENCIES.TIMER].value === -1
                    ) {
                        this.$emit('loading', 'Stopping timer');
                        await stopAllTimers(this.current_user.id);
                    } else {
                        this.$emit('loading', 'Starting timer');
                        await startTimer(
                            this.current_user.id,
                            data[COMMAND_DEPENDENCIES.TIMER].value
                        );
                    }
                    this.$emit('close');
                }
            },
        },
    },
};
</script>
