<template>
    <el-popover
        v-model="visible"
        placement="top"
        popper-class="overlay-popover"
        :title="`Edit ${cur_node.meta.type}`"
        width="200"
        trigger="manual"
        @show="handleShow"
        @click="handleClick"
    >
        <div
            slot="reference"
            :class="[
                'overlay',
                `depth-${depth}`,
                cur_node.meta.status,
                is_relative ? 'relative' : '',
                cur_node.meta.type,
                visible ? 'modal-visible' : '',
            ]"
            :style="block_style"
            @click="handleClick"
        >
            <div class="label-wrapper">
                <div class="label">{{ cur_node.label }}</div>
            </div>
        </div>
        <div class="modal">
            <el-input v-model="label" size="mini" />
            <el-date-picker
                :value="startDate"
                type="date"
                size="mini"
                placeholder="Start date"
                format="dd/MM/yyyy"
                @input="(v) => (startDate = v)"
            />

            <el-date-picker
                :value="endDate"
                type="date"
                size="mini"
                placeholder="End date"
                :picker-options="end_date_picker_options"
                format="dd/MM/yyyy"
                @input="(v) => (endDate = v)"
            />
        </div>
        <div style="text-align: right; margin-top: 10px;">
            <el-button type="danger" size="mini" @click="handleDelete">
                delete
            </el-button>
            <el-button type="text" size="mini" @click="handleCancel">
                cancel
            </el-button>
            <el-button type="primary" size="mini" @click="handleSave">
                save
            </el-button>
        </div>
    </el-popover>
</template>

<script>
import dayjs from 'dayjs';
import {
    adjustedStartDate,
    workDaysBetween,
} from '@/components/timeline-v2/utils';

export default {
    name: 'block',

    props: {
        tree: {
            type: Object,
            required: true,
        },

        cur_node: {
            type: Object,
            required: true,
        },

        day_width: {
            type: Number,
            required: true,
        },

        depth: {
            type: Number,
            required: true,
        },

        days: {
            type: Array,
            required: false,
            default: () => [],
        },

        is_relative: {
            type: Boolean,
            required: false,
            default: false,
        },

        relative_start: {
            type: Date,
            required: false,
            default: null,
        },
    },

    data: function () {
        return {
            visible: false,
            startDate: this.cur_node.startDate.toDate(),
            endDate: this.cur_node.endDate.toDate(),
            label: this.cur_node.label,
        };
    },

    computed: {
        end_date_picker_options() {
            return {
                disabledDate: (time) => {
                    return time.getTime() < this.startDate.getTime();
                },
            };
        },

        workDaysBetween() {
            return workDaysBetween(
                this.cur_node.startDate,
                this.cur_node.endDate
            );
        },

        left() {
            if (this.is_relative) {
                return (
                    workDaysBetween(
                        dayjs(this.relative_start),
                        this.cur_node.startDate
                    ) * this.day_width
                );
            }

            return (
                workDaysBetween(
                    adjustedStartDate(this.tree),
                    this.cur_node.startDate
                ) * this.day_width
            );
        },

        block_style() {
            const blockPosition = {
                left: `${this.left}px`,
                width: `${
                    workDaysBetween(
                        this.cur_node.startDate,
                        this.cur_node.endDate
                    ) * this.day_width
                }px`,
            };

            return blockPosition;
        },
    },

    methods: {
        // event handlers
        handleCancel() {
            this.visible = false;
        },

        handleSave() {
            this.$emit('update:node', {
                type: `update-${this.cur_node.meta.type}`,
                payload: {
                    ...this.cur_node,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    label: this.label,
                },
            });
            this.visible = false;
        },

        handleDelete() {
            this.$emit('update:node', {
                type: `delete-${this.cur_node.meta.type}`,
                payload: {
                    id: this.cur_node.id,
                },
            });
            this.visible = false;
        },
        handleClick(e) {
            e.preventDefault();
            e.stopPropagation();

            this.visible = true;
        },

        handleShow(e) {
            window.addEventListener('click', this.handleClickAway);
        },

        handleClickAway() {
            this.visible = false;
            window.removeEventListener('click', this.handleClickAway);
        },
    },
};
</script>

<style scoped lang="scss">
.overlay {
    position: absolute;
    border-radius: 0px;
    height: 16px;
    background-color: rgb(25, 137, 250, 0.75);
    cursor: pointer;

    &.relative {
        margin-top: 0px;
    }

    // Stage
    &.depth-2 {
        background-color: rgb(25, 137, 250, 0.25);

        &:hover {
            background-color: darken(rgb(25, 137, 250, 0.25), 10%);
        }
    }

    // Component
    &.depth-3 {
        background-color: $blue;

        &:hover {
            background-color: darken($blue, 10%);
        }
    }

    &.completed {
        background-color: $face_4;

        &:hover {
            background-color: darken($face_4, 10%);
        }
    }

    &.blocker {
        border: 1px solid $blue;
        background: repeating-linear-gradient(
            -45deg,
            rgba($white, 0.1),
            rgba($white, 0.1) 10px,
            rgba($white, 0.3) 10px,
            rgba($white, 0.3) 20px
        );

        &:hover {
            background-color: darken($blue, 20%);

            .label {
                opacity: 1;
            }
            &.modal-visible {
                .label {
                    opacity: 0;
                }
            }
        }
    }

    .label-wrapper {
        width: 100%;
        display: flex;

        align-items: center;
        justify-content: center;
    }

    .label {
        transition: opacity 0.2s ease-in;
        display: block;

        position: absolute;

        margin-bottom: 2px;

        bottom: 100%;

        opacity: 0;

        background-color: black;

        text-align: center;

        padding: 2px 10px;

        box-sizing: border-box;

        color: white;

        border-radius: 10px;

        font-size: 12px;
    }
}
.overlay-popover {
    .el-input {
        width: 100%;
    }
    .el-input + .el-input {
        margin-top: 5px;
    }
}
</style>
