<template>
    <div
        v-if="active"
        class="bar-segment"
        :style="style"
        :class="{inactive, disabled}"
    >
        <div v-if="show_bracket" class="bracket">
            <label>{{ bracket_label }}</label>
        </div>

        <slot />

        <div v-if="!inactive && !disable_tooltip" class="bar-segment-tooltip">
            <div class="content">
                <slot name="tooltip-prefix">
                    <span class="time">{{ time | milli2duration }}</span>
                    <span class="prefix">{{ tooltip_prefix }}</span>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import Color from 'color';

export default {
    name: 'bar-segment',
    props: {
        index: {
            type: Number,
            default: 1,
        },
        active: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: 'default',
        },
        time: {
            type: Number,
            required: true,
        },
        total_time: {
            type: Number,
            required: true,
        },
        background_color: {
            type: String,
            required: true,
        },
        border_color: {
            type: String,
            default: '',
        },
        height: {
            type: Number,
            default: 20,
        },
        tooltip_prefix: {
            type: String,
            default: '',
        },
        disable_tooltip: {
            type: Boolean,
            default: false,
        },
        show_time: {
            type: Boolean,
            default: true,
        },
        show_bracket: {
            type: Boolean,
            default: false,
        },
        bracket_label: {
            type: String,
            default: null,
        },
    },
    computed: {
        inactive() {
            return !this.active || this.width_percent <= 0;
        },
        width_percent() {
            if (!this.total_time) {
                return this.time > 0 ? 100 : 0;
            }
            return (this.time / this.total_time) * 100;
        },
        style() {
            return {
                '--width': `${this.active ? this.width_percent : 0}%`,
                '--background-color': this.background_color,
                '--border-radius': `${this.height / 2}px`,
                '--height': `${this.height}px`,
                '--right': `-${this.height / 2}px`,
                '--zindex': this.index + 100,
                '--zindex-dot': this.index + 101,
                '--border-color': this.border_color,
                '--background-color-hover': Color(
                    this.background_color
                ).isDark()
                    ? Color(this.background_color).lighten(0.2)
                    : Color(this.background_color).darken(0.2),
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.bar-segment {
    display: flex;
    position: relative;
    width: var(--width);
    height: var(--height);
    z-index: var(--zindex);
    background-color: var(--background-color);
    transition: width 0.5s, background-color 0.5s ease-in-out;
    box-shadow: 2px 0px 0px 0px $grey;

    .bracket {
        height: 20px;
        width: calc(100% - 4px);
        position: absolute;
        margin-top: -27px;
        padding: 0 2px;
        border-top: 2px solid $black;
        border-right: 2px solid $black;
        border-left: 2px solid $black;
        box-sizing: border-box;
        margin-left: 2px;

        label {
            position: absolute;
            font-size: 12px;
            margin-top: -20px;
            width: 100%;
            text-align: center;
            font-weight: 700;
        }
    }

    &.inactive {
        &:after {
            right: 0;
            transition: background-color 0.5s ease-in-out,
                right 0.5s ease-in-out 0.25s;
        }
    }

    &:hover {
        background-color: var(--background-color-hover);

        &:after {
            background-color: var(--background-color-hover);
        }
    }

    &.disabled {
        &:after {
            background: repeating-linear-gradient(
                -45deg,
                var(--background-color),
                var(--background-color) 10px,
                var(--background-color-hover) 10px,
                var(--background-color-hover) 20px
            );
        }
    }

    .bar-segment-tooltip {
        display: flex;
        position: absolute;
        align-items: flex-start;
        justify-content: center;
        max-width: 100%;
        width: 100%;

        .content {
            user-select: none;
            cursor: default;
            position: absolute;
            font-size: 11px;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: flex-end;
            color: rgba($black, 0.6);
            margin-top: var(--height);
            padding-top: 6px;

            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
                text-align: center;
            }

            .prefix,
            .date {
                font-size: 8px;
                margin: 0;
            }

            &::after {
                display: none;
                position: absolute;
                content: '';
                height: 4px;
                width: 4px;
                background-color: var(--background-color);
                top: 0;
            }
        }
    }

    &:nth-child(2n) {
        > .bar-segment-tooltip {
            .content {
                flex-direction: column;
                align-self: flex-end;
                padding-top: 0px;
                padding-bottom: 6px;
                margin-bottom: 4px;
                margin-top: 0px;

                & > .user-tooltip {
                    flex-direction: column;
                }

                &::after {
                    bottom: 0;
                    top: auto;
                }
            }
        }
    }

    &:hover > .bar-segment-tooltip,
    &.bar-segment-tooltip:hover {
        .content {
            opacity: 1;
            color: $black;
        }
    }
}
</style>
