<template>
    <div class="outer">
        <breadcrumb-header
            :labels="[client.name, 'Link project']"
            go_back_to="management_client_detail"
            :go_back_params="{
                client_id: $route.params.client_id,
            }"
        />

        <div class="inner">
            <el-form
                ref="form"
                auto-complete="on"
                label-width="200px"
                class="form-wrapper"
            >
                <el-form-item label="Existing project" prop="project">
                    <el-select
                        filterable
                        :value="project"
                        placeholder="Select"
                        value-key="id"
                        @change="changedProject"
                    >
                        <el-option
                            v-for="p in projects"
                            :key="p.id"
                            :label="p.name"
                            :value="p"
                        />
                    </el-select>
                </el-form-item>

                <el-button :disabled="!project" @click="linkProjectToClient">
                    Link
                </el-button>
            </el-form>

            <hr />

            <projects-new :client="client" @created="onProjectCreated" />
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';

import BreadcrumbHeader from '@/pages/management/components/BreadcrumbHeader';
import ProjectsNew from '@/pages/projects/new';

export default {
    name: 'management-client-project-link',
    components: {
        BreadcrumbHeader,
        ProjectsNew,
    },
    data() {
        return {
            project: null,
        };
    },
    computed: {
        client() {
            return this.$store.getters.clientWithId(
                this.$route.params.client_id
            );
        },
        projects() {
            return this.$store.getters.projects;
        },
    },
    methods: {
        changedProject(p) {
            this.project = p;
        },
        onProjectCreated(p) {
            this.project = p;
            this.navigateToProject();
        },
        linkProjectToClient() {
            this.$fire
                .doc(`clients/${this.client.id}`)
                .update({
                    projects: firebase.firestore.FieldValue.arrayUnion(
                        this.$fire.collection('projects').doc(this.project.id)
                    ),
                })
                .then(() => {
                    this.navigateToProject();
                });
        },
        navigateToProject() {
            this.$router
                .push({
                    name: 'management_project_detail',
                    params: {
                        project_id: this.project.id,
                    },
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    .inner {
        padding: 20px;

        .el-form {
            display: flow-root;
            margin: 40px auto;

            .el-form-item {
                &.disabled {
                    opacity: 0.3;
                    pointer-events: none;
                }

                .el-input,
                .el-select {
                    flex: 1;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .el-button {
            margin-top: 20px;
            width: 200px;
            float: right;
        }

        span.warning {
            color: $red;
        }

        hr {
            margin: 20px 50px;
            border: none;
            height: 1px;
            background-color: $border-grey-light;
        }
    }
}
</style>
