import {COMMAND_DEPENDENCIES} from '@/enums';
import store from '@/store';

/**
 * Provides a list of cube face timers for the current user
 * @type {{provides: string, getResults: CommandProvider.getResults, label: string, filteredBy: null}}
 */
export const TimerProvider = {
    label: 'Timers',
    provides: COMMAND_DEPENDENCIES.TIMER,
    filteredBy: null,
    getResults: async (data, filter_string) => {
        if (data[COMMAND_DEPENDENCIES.TIMER]) return [];

        const cube = store.getters.currentUserCube;
        let timers = [];
        if (cube.active_face > 0) {
            timers.push({
                id: -1,
                shortcut: '⏹',
                label: 'STOP active tracker',
                sub_label: 'Stop All Timers',
                keywords: 'stop all timer timers',
                value: -1,
            });
        }
        timers.push(
            ...Object.entries(cube.face2component).map(([face_id, value]) => {
                const component = store.getters.componentWithId(value);
                if (component) {
                    const project = store.getters.projectWithId(
                        component.project
                    );
                    const stage = store.getters.stageWithId(component.stage);
                    const started = face_id === cube.active_face.toString();
                    return {
                        id: face_id,
                        shortcut: started ? '⏸' : '⏵',
                        label: `${project.ref} ›
                        ${project.name}: ${component.name}`,
                        sub_label: (started ? 'Stop' : 'Start') + ' Timer',
                        keywords: `start timer ${project.ref} ${project.title} ${component.name} ${stage.name}`.toLowerCase(),
                        value: face_id,
                        started,
                    };
                }
                return null;
            })
        );
        // remove any empty timers (due to missing components)
        timers = timers.filter((x) => x);
        if (filter_string) {
            const query = filter_string.toLowerCase();
            return timers.filter((timer) => {
                return (
                    query.includes(timer.keywords) ||
                    timer.keywords.includes(query)
                );
            });
        }
        return timers;
    },
    cleanup() {
        // if this provider holds any resources during an operation, dispose of them here
    },
};
