import request from '@/utils/request';

// {{host}}/hubspot/redirect
export function redirectUserToHubspot() {
    return request({
        url: '/hubspot/redirect',
        method: 'get',
    });
}

// {{host}}/hubspot/authenticate
export function handleCallbackFromHubspot(code) {
    return request({
        url: '/hubspot/authenticate',
        method: 'post',
        data: {
            code,
        },
    });
}

// {{host}}/hubspot/disconnect
export function disconnectHubspot() {
    return request({
        url: '/hubspot/disconnect',
        method: 'delete',
    });
}
