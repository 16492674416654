<template>
    <el-form
        ref="form"
        auto-complete="on"
        :model="form"
        :rules="rules"
        label-width="160px"
        class="form-wrapper"
    >
        <!-- TYPE -->
        <!--*******************************************-->

        <el-form-item label="Type" prop="type_id">
            <el-select
                v-model="form.type_id"
                v-loading="types.loading"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 0.7)"
            >
                <el-option
                    v-for="type in types.items"
                    :key="type.id"
                    :label="type.value"
                    :value="type.id"
                />
            </el-select>
        </el-form-item>

        <!-- SUB-TYPE -->
        <!--*******************************************-->

        <el-form-item v-if="form.type_id" label="Framework" prop="subtype_id">
            <el-select
                v-model="form.subtype_id"
                v-loading="subtypes.loading"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 0.7)"
            >
                <el-option
                    v-for="type in subtypes.items"
                    :key="type.id"
                    :label="type.value"
                    :value="type.id"
                />
            </el-select>
        </el-form-item>

        <!-- NOTES -->
        <!--*******************************************-->

        <el-form-item label="Notes" prop="notes">
            <el-input v-model="form.notes" type="textarea" :rows="2" />
        </el-form-item>

        <hr />

        <el-button :disabled="creating" @click="handleSubmit">
            Create
        </el-button>
    </el-form>
</template>

<script>
export default {
    name: 'module-new',
    data() {
        return {
            creating: false,
            form: {
                type_id: null,
                subtype_id: null,
                status: 'active',
                notes: null,
                repository: null,
            },
            rules: {
                type_id: [{required: true, trigger: 'blur'}],
                subtype_id: [{required: true, trigger: 'blur'}],
                notes: [{required: false, trigger: 'blur'}],
            },
            types: {
                loading: true,
                items: [],
            },
            subtypes: {
                loading: false,
                items: [],
            },
        };
    },
    watch: {
        'form.type_id'() {
            this.fetchSubtypes();
        },
    },
    mounted() {
        this.fetchTypes();
    },
    methods: {
        fetchTypes() {
            this.$fire
                .collection('system/modules/types')
                .get()
                .then((snapshot) => {
                    snapshot.forEach((type) => {
                        this.types.items.push({
                            id: type.id,
                            value: type.data().value,
                        });
                    });
                    this.types.loading = false;
                });
        },
        fetchSubtypes() {
            this.form.subtype_id = null;
            this.subtypes.items = [];
            this.subtypes.loading = true;
            this.$fire
                .collection(
                    `system/modules/types/${this.form.type_id}/subtypes`
                )
                .get()
                .then((snapshot) => {
                    snapshot.forEach((type) => {
                        this.subtypes.items.push({
                            id: type.id,
                            value: type.data().value,
                        });
                    });
                    this.subtypes.loading = false;
                });
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.creating = true;

                    const data = Object.assign({}, this.form);

                    data.type = this.$fire.doc(
                        `system/modules/types/${data.type_id}`
                    );

                    data.subtype = this.$fire.doc(
                        `system/modules/types/${data.type_id}/subtypes/${data.subtype_id}`
                    );

                    delete data.type_id;
                    delete data.subtype_id;

                    data.project_id = this.$route.params.project_id;

                    this.$store.dispatch('addModule', data).then(() => {
                        this.$router.go(-1);
                    });
                } else {
                    console.log('Form not valid...');
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.el-form {
    margin: 40px auto;

    .el-form-item {
        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        .el-input,
        .el-select {
            flex: 1;
            height: 100%;
            width: 100%;
        }
    }
}

.el-button {
    margin-top: 20px;
    width: 200px;
    float: right;
}

span.warning {
    color: $red;
}

hr {
    margin: 20px 50px;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}
</style>
