<template>
    <div v-only-admin>
        <el-alert
            v-if="component.batch_count"
            type="error"
            :closable="false"
            :title="`A batch operation is currently being performed on this component (${component.batch_count})`"
        />

        <content-block title="Settings">
            <el-form label-width="120px" @submit.native.prevent>
                <!-- NAME -->
                <!--*******************************************-->

                <el-form-item label="Name">
                    <el-input
                        v-model="component.name"
                        @change="(v) => updateComponent('name', v)"
                    />
                </el-form-item>

                <!-- REFERENCE -->
                <!--*******************************************-->

                <el-form-item label="Reference">
                    <el-input
                        v-model="component.ref"
                        @change="
                            (v) => updateComponent('ref', v?.toUpperCase())
                        "
                    />
                </el-form-item>
                <hr />

                <!-- STATUS -->
                <!--*******************************************-->

                <el-form-item label="Status">
                    <el-select
                        v-model="component.status"
                        placeholder="Select"
                        style="width: 100%;"
                        @change="(v) => updateComponent('status', v)"
                    >
                        <el-option
                            v-for="item in status_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>

                <hr />

                <!-- USERS -->
                <!--*******************************************-->

                <el-form-item label="Users">
                    <user-selector
                        :users="project_users"
                        :selected="component.users"
                        @change="(v) => updateComponent('users', v)"
                        @add_user="handleAddUser"
                    />
                </el-form-item>

                <hr />

                <!-- TIME ROUNDING -->
                <!--*******************************************-->

                <el-form-item
                    v-if="component.recurring && updated.round > 0"
                    label="Round time to"
                >
                    {{ updated.round }}
                    {{ 'minute' | pluralize(updated.round) }}
                </el-form-item>
                <el-form-item v-if="!component.recurring" label="Round time to">
                    <el-input
                        v-model="updated.round"
                        placeholder="minutes"
                        clearable
                        @change="
                            (value) => updateComponentMinutes('round', value)
                        "
                    >
                        <template slot="append">
                            {{ 'minute' | pluralize(updated.round) }}
                        </template>
                    </el-input>
                </el-form-item>

                <!-- MINIMUM TIME -->
                <!--*******************************************-->

                <el-form-item
                    v-if="
                        component.recurring &&
                        updated.round > 0 &&
                        updated.min > 0
                    "
                    label="Round after"
                >
                    {{ updated.min }}
                    {{ 'minute' | pluralize(updated.min) }}
                </el-form-item>
                <el-form-item v-if="!component.recurring" label="Round after">
                    <el-input
                        v-model="updated.min"
                        placeholder="minutes"
                        clearable
                        :disabled="component.recurring"
                        @change="
                            (value) => updateComponentMinutes('min', value)
                        "
                    >
                        <template slot="append">
                            {{ 'minute' | pluralize(updated.min) }}
                        </template>
                    </el-input>
                </el-form-item>

                <!-- TIMELINE -->
                <!--*******************************************-->

                <template v-if="!component.recurring">
                    <hr />
                    <el-form-item label="Start Date">
                        <el-date-picker
                            :value="computed_start_date"
                            type="date"
                            format="dd/MM/yyyy"
                            @input="(v) => updateComponent('start_date', v)"
                        />
                    </el-form-item>

                    <el-form-item label="End Date">
                        <el-date-picker
                            :value="computed_end_date"
                            type="date"
                            format="dd/MM/yyyy"
                            @input="(v) => updateComponent('end_date', v)"
                        />
                    </el-form-item>
                </template>
            </el-form>
        </content-block>

        <content-block
            v-if="component.recurring"
            title="Recurring"
            class="recurring"
        >
            <el-form label-width="120px" @submit.native.prevent>
                <!-- PERIOD (read only) -->
                <!--*******************************************-->

                <el-form-item label="Period">
                    <span>
                        Every
                        {{ component.recurring_config.period_size }}
                        {{
                            component.recurring_config.period_unit
                                | pluralize(
                                    component.recurring_config.period_size
                                )
                        }}
                    </span>
                </el-form-item>

                <!-- START DATE (read only) -->
                <!--*******************************************-->

                <el-form-item label="Start date">
                    <span>
                        {{
                            component.start_date | dateformat('ddd DD/MM/YYYY')
                        }}
                    </span>
                </el-form-item>

                <!-- END DATE -->
                <!--*******************************************-->

                <!-- TODO end date should be barred from setting a date before end of last block -->
                <el-form-item label="End date">
                    <el-date-picker
                        :value="computed_end_date"
                        type="date"
                        format="dd/MM/yyyy"
                        :picker-options="recurring_picker_options"
                        @input="
                            (v) =>
                                updateComponent(
                                    'end_date',
                                    dayjs(v).endOf('day').toDate()
                                )
                        "
                    />
                </el-form-item>

                <!-- TIME PER BLOCK -->
                <!--*******************************************-->

                <el-form-item
                    label="Time per block"
                    prop="update.time_estimate"
                >
                    <el-input
                        v-model="updated.time_estimate"
                        placeholder="hours"
                        clearable
                        @change="(value) => updateTime('time_estimate', value)"
                    >
                        <template slot="append">
                            {{ 'hour' | pluralize(updated.time_estimate) }}
                        </template>
                    </el-input>
                </el-form-item>

                <!-- EXPIRES IN -->
                <!--*******************************************-->

                <el-form-item label="Expires after">
                    <el-input
                        v-model="updated.expires_in"
                        placeholder="count"
                        clearable
                        @change="(value) => updateRecurringExpiry(value)"
                    >
                        <template slot="append">
                            {{ 'period' | pluralize(updated.expires_in) }}
                        </template>
                    </el-input>
                    <span
                        v-if="!updated.expires_in || updated.expires_in === '0'"
                        class="field-footnote"
                    >
                        Time allocated to future blocks will not expire.
                    </span>
                    <span v-else class="field-footnote">
                        Time allocated to future blocks will expire after
                        <b>
                            {{
                                updated.expires_in *
                                component.recurring_config.period_size
                            }}
                            {{
                                component.recurring_config.period_unit
                                    | pluralize(
                                        updated.expires_in *
                                            component.recurring_config
                                                .period_size
                                    )
                            }}</b
                        >.
                    </span>
                </el-form-item>
                <hr />

                <!-- BLOCK ADJUSTMENTS -->
                <!--*******************************************-->

                <el-form-item label="Adjustments">
                    <div class="adjustment-form">
                        <el-select
                            v-model="adjustment.block"
                            style="flex: unset; width: 160px;"
                            placeholder="Select a block"
                        >
                            <el-option
                                v-for="item in block_list"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id"
                            />
                        </el-select>
                        <el-input
                            v-model="adjustment.description"
                            style="flex: 1;"
                            placeholder="description"
                            clearable
                        />
                        <el-input
                            v-model="adjustment.time"
                            style="flex: 1;"
                            placeholder="hours"
                            clearable
                        >
                            <template slot="append">
                                {{ 'hour' | pluralize(adjustment.time) }}
                            </template>
                        </el-input>
                        <el-button
                            icon="el-icon-plus"
                            :disabled="!adjustment_valid"
                            @click="addAdjustment"
                        />
                    </div>
                    <component-adjustment
                        v-for="(a, index) of adjustment_list"
                        :key="index"
                        :adjustment="a"
                    />
                </el-form-item>
            </el-form>
        </content-block>

        <!-- TODO: restore this for recurring components - calculations need to be redone -->
        <content-block v-if="!component.recurring" title="Time">
            <el-form label-width="120px" @submit.native.prevent>
                <!-- TIME SPENT -->
                <!--*******************************************-->

                <el-form-item label="Spent">
                    <el-input
                        :value="$options.filters.milli2duration(total_spent)"
                        disabled
                    >
                        <template slot="append">
                            {{
                                'hour'
                                    | pluralize(
                                        $options.filters.milli2hours(
                                            total_spent
                                        )
                                    )
                            }}
                        </template>
                    </el-input>
                </el-form-item>

                <!-- TIME AVAILABLE -->
                <!--*******************************************-->

                <el-form-item label="Available" prop="update.time_estimate">
                    <el-input
                        v-model="updated.time_estimate"
                        placeholder="Insert number of hours"
                        clearable
                        @change="(value) => updateTime('time_estimate', value)"
                    >
                        <template slot="append">
                            {{ 'hour' | pluralize(updated.time_estimate) }}
                        </template>
                    </el-input>
                </el-form-item>

                <!-- TIME INVOICED -->
                <!--*******************************************-->

                <el-form-item
                    v-if="$store.getters.is_xero_enabled"
                    label="Invoiced"
                >
                    <el-input
                        :value="$options.filters.hours2duration(invoiced_hours)"
                        disabled
                    >
                        <template slot="append">
                            {{ 'hour' | pluralize(invoiced_hours) }}
                        </template>
                    </el-input>
                </el-form-item>

                <template v-if="sorted_time_changes">
                    <!-- TIME CHANGES -->
                    <!--*******************************************-->
                    <hr />
                    <el-form-item label="Changes">
                        <ul class="time-change">
                            <time-change-block
                                v-for="(change, index) in sorted_time_changes"
                                :key="`time_change_${index}`"
                                :data="change"
                            />
                        </ul>
                    </el-form-item>
                </template>
            </el-form>
        </content-block>

        <!-- INVOICES -->
        <!--*******************************************-->

        <content-block
            v-if="$store.getters.is_xero_enabled"
            title="Linked invoices"
        >
            <ul v-if="sorted_invoices.length" class="invoices-list">
                <invoice-block
                    v-for="invoice in sorted_invoices"
                    :key="invoice.id"
                    :invoice="invoice"
                />
            </ul>
            <el-alert
                v-else
                description="No invoices linked to this component yet."
                :closable="false"
            />
        </content-block>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import firebase from 'firebase/app';

import componentMixin from '@/mixins/component.mixin';
import componentInvoiceMixin from '@/mixins/component.invoice.mixin';
import componentRecurringMixin from '@/mixins/component.recurring.mixin';
import componentSessionMixin from '@/mixins/component.session.mixin';

import ContentBlock from '@/components/blocks/ContentBlock';
import TimeChangeBlock from '@/components/blocks/TimeChangeBlock';
import InvoiceBlock from '@/components/blocks/InvoiceBlock';
import UserSelector from '@/components/generic/UserSelector';

import ComponentAdjustment from '@/pages/components/components/ComponentAdjustment';

import {COMPONENT_STATUS} from '@/enums';
import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';
import {generateUUID} from '@/utils/firebase';

export default {
    name: 'component-tab-settings',
    components: {
        ComponentAdjustment,
        InvoiceBlock,
        ContentBlock,
        TimeChangeBlock,
        UserSelector,
    },
    mixins: [
        componentMixin,
        componentRecurringMixin,
        componentInvoiceMixin,
        componentSessionMixin,
    ],
    data() {
        return {
            sessions: [],
            updated: {
                time_estimate: null,
                time_quoted: null,
                expires_in: null,
                round: null,
                min: null,
            },
            status_options: [
                {
                    label: 'Active',
                    value: COMPONENT_STATUS.ACTIVE,
                },
                {
                    label: 'Standby',
                    value: COMPONENT_STATUS.STANDBY,
                },
                {
                    label: 'Completed',
                    value: COMPONENT_STATUS.COMPLETED,
                },
            ],
            hourly_rate: +process.env.VUE_APP_HOURLY_RATE,
            SUPPORTED_FEATURES: SUPPORTED_FEATURES,

            recurring_blocks: [],
            adjustment: {
                block: null,
                description: null,
                time: null,
            },
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        user() {
            return this.$store.getters.user;
        },
        project_users() {
            return (this.project.team ?? []).map((u) => u.user);
        },
        component() {
            return this.$store.getters.componentWithId(
                this.$route.params.component_id
            );
        },
        recurring_picker_options() {
            return {
                disabledDate: this.disabledEndDate,
            };
        },
        computed_start_date() {
            if (!this.component.start_date) return null;
            return this.component.start_date.toDate();
        },
        computed_end_date() {
            if (!this.component.end_date) return null;
            return this.component.end_date.toDate();
        },
        stage() {
            return this.$store.getters.stageWithId(this.$route.params.stage_id);
        },
        stages() {
            const stages = this.$store.getters.stagesForProjectWithId(
                this.$route.params.project_id
            );
            return stages.map((stage) => {
                const components = this.$store.getters.componentsForStageWithId(
                    stage.id
                );
                stage._components = components;
                return stage;
            });
        },
        total_spent() {
            if (!this.component || !this.component.time_spent) return 0;
            return Object.values(this.component.time_spent).reduce(
                (acc, cur) => (acc += cur),
                0
            );
        },
        sorted_time_changes() {
            if (!this.component || !this.component.time_changes) return null;
            return this.component.time_changes.slice(0).sort((a, b) => {
                if (a.created_at.seconds < b.created_at.seconds) return 1;
                if (a.created_at.seconds > b.created_at.seconds) return -1;
                return 0;
            });
        },
        sorted_invoices() {
            if (!this.$store.getters.is_xero_enabled) return [];

            return [
                ...new Set(
                    this.line_items.map((item) =>
                        this.$store.getters.invoiceWithId(item.invoice)
                    )
                ),
            ]
                .filter((i) => i)
                .sort((a, b) => {
                    if (a.invoice_number < b.invoice_number) return 1;
                    if (a.invoice_number > b.invoice_number) return -1;
                    return 0;
                });
        },
        block_list() {
            if (!this.recurring_blocks) return [];
            return this.recurring_blocks.map((block) => {
                return {
                    id: block.id,
                    label:
                        this.getDateLabelForRecurringBlock(block, true) +
                        ` [${block.id}]`,
                    block,
                };
            });
        },
        adjustment_list() {
            if (!this.recurring_blocks) return [];
            return this.recurring_blocks.reduce((list, block) => {
                if (!block.time_adjustment) return list;
                // get adjustments for each block with most recent first
                const block_adjustments = block.time_adjustment
                    .toReversed()
                    .map((adjustment) => {
                        return {
                            ...adjustment,
                            block,
                            label: this.getDateLabelForRecurringBlock(
                                block,
                                true
                            ),
                        };
                    });
                return list.concat(block_adjustments);
            }, []);
        },
        adjustment_valid() {
            return (
                this.adjustment.block &&
                this.adjustment.description &&
                this.adjustment.time
            );
        },
    },
    mounted() {
        this.updated.min = this.component.min
            ? this.component.min / 60000
            : null;
        this.updated.round = this.component.round
            ? this.component.round / 60000
            : null;

        this.updated.time_estimate = this.$options.filters.milli2hours(
            this.component.time_estimate
        );
        this.updated.time_quoted = this.$options.filters.milli2hours(
            this.component.time_quoted
        );

        this.updated.expires_in = this.component?.recurring_config?.expires_in;

        if (this.component?.recurring) {
            this.$bind(
                'recurring_blocks',
                this.$fire
                    .collection('component_recurring_blocks')
                    .where(
                        'component',
                        '==',
                        this.$fire.doc(`components/${this.component.id}`)
                    )
                    .orderBy('start_date', 'desc')
            );
        }
    },
    methods: {
        dayjs,
        handleAddUser(userId) {
            this.$store.dispatch('addUserToProjectTeam', {
                project_id: this.$route.params.project_id,
                user_id: userId,
            });

            this.updateComponent('users', [...this.component.users, userId]);
        },
        updateComponentMinutes(key, minutes) {
            let millis = null;
            if (minutes) {
                millis = minutes * 60000;
            }
            this.updateComponent(key, millis);
        },
        async updateTime(key, val) {
            let new_time = this.$options.filters.hours2milli(val);
            if (val === '') new_time = null;

            if (this.component[key] !== undefined) {
                await this.updateComponent(
                    'time_changes',
                    firebase.firestore.FieldValue.arrayUnion({
                        changed_by: this.$fire.doc(`users/${this.user.id}`),
                        created_at: new Date(),
                        key,
                        from: this.component[key],
                        to: new_time,
                    })
                );
            }

            this.updateComponent(key, new_time);
        },

        async updateRecurringExpiry(val) {
            const key = 'recurring_config.expires_in';
            const value = Number(val);
            await this.updateComponent(
                'time_changes',
                firebase.firestore.FieldValue.arrayUnion({
                    changed_by: this.$fire.doc(`users/${this.user.id}`),
                    created_at: new Date(),
                    key,
                    from: this.component.recurring_config.expires_in,
                    to: value,
                })
            );
            this.updateComponent(key, value);
        },

        disabledDate(time) {
            if (!this.stage) return false;

            const stageStart = this.calculateStageStartDate(this.stage);

            if (!stageStart) return false;
            return (
                time.getTime() < dayjs(stageStart).toDate().getTime() ||
                time.getTime() >
                    dayjs(stageStart)
                        .add(this.stage.timeframe_weeks, 'weeks')
                        .toDate()
                        .getTime()
            );
        },
        disabledEndDate(time) {
            if (!this.component?.recurring) return false;
            const start = this.component.start_date.toDate();

            // end date can't be less than 1 recurring period after start date
            // TODO should probably also block out dates until after all current recurring blocks
            return (
                time.getTime() <
                dayjs(start)
                    .add(
                        this.component.recurring_config.period_size,
                        this.component.recurring_config.period_unit
                    )
                    .toDate()
                    .getTime()
            );
        },

        addAdjustment() {
            const block = this.recurring_blocks.find(
                (b) => b.id === this.adjustment.block
            );
            if (block) {
                this.$fire
                    .doc(`component_recurring_blocks/${block.id}`)
                    .update({
                        time_adjustment: firebase.firestore.FieldValue.arrayUnion(
                            {
                                id: generateUUID(),
                                description: this.adjustment.description,
                                time: this.$options.filters.hours2milli(
                                    this.adjustment.time
                                ),
                                adjusted_at: new Date(),
                            }
                        ),
                    });
            }

            // reset adjustment form
            this.adjustment.block = null;
            this.adjustment.description = null;
            this.adjustment.time = null;
        },
    },
};
</script>

<style lang="scss" scoped>
section {
    margin: 0 0 10px 0;
    padding: 20px;
    border-radius: 5px;
    background-color: $grey;

    h3 {
        margin-top: 0;
    }

    ul.time-change {
        padding: 0;
        list-style: none;
    }
}

hr {
    margin: 20px 50px;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}
.invoices-list {
    list-style: none;
    padding: 0;
}

.recurring {
    ::v-deep .el-input-group__append {
        width: 85px;
    }
    .el-select {
        flex: 1;
        height: 100%;
        width: 100%;
        width: 120px;
    }
}

.field-footnote {
    font-size: 11px;
    opacity: 0.75;
    line-height: 13px;
    display: block;
    margin-top: 5px;
    max-width: 75%;
}

.adjustment-form {
    display: flex;
    align-items: center;
    gap: 10px;
}
@media screen and (max-width: 480px) {
    ::v-deep .head {
        font-size: 15px;
    }
}
</style>
