<template>
    <div class="wrapper">
        <aside class="menu">
            <ul>
                <li
                    v-for="type in typeCollection"
                    :key="type.name"
                    :class="{
                        active: selectedType ? selectedType === type : false,
                    }"
                    @click="() => (selectedType = type)"
                >
                    <span>{{ type.name | capitalize }}</span>
                    <svgicon name="triangle" class="triangle" />
                </li>
            </ul>
        </aside>
        <content-block class="document-list" title="Documents" no_background>
            <template slot="right">
                <el-dropdown
                    trigger="click"
                    placement="bottom"
                    style="margin-right: 10px;"
                    @command="handleNewDocument"
                    @click.native.stop.prevent
                >
                    <el-button size="mini">
                        New
                    </el-button>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="summary">
                                <span>Summary</span>
                            </el-dropdown-item>
                            <el-dropdown-item command="estimate">
                                <span>Estimate</span>
                            </el-dropdown-item>
                            <el-dropdown-item command="link">
                                <span>From link</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>

            <loader v-if="loading" />
            <el-alert
                v-else-if="!selectedType || !selectedType.documents.length"
                description="No documents have been created yet."
                :closable="false"
            />
            <template v-else>
                <!-- TODO: Convert to a Document component block!!! -->
                <!-- TODO: Move document functions inside the new component. eg handle links, delete, edit etc -->
                <div
                    v-for="doc in selectedType.documents"
                    :key="doc.id"
                    class="document-row"
                    :class="{
                        invalid: !doc.isExternal && !doc.valid,
                        editable: !doc.isExternal,
                    }"
                    :type="doc.isExternal || doc.valid ? null : 'warning'"
                    @click="() => editDocument(doc)"
                >
                    <span class="title">
                        {{ doc.name }}
                    </span>
                    <el-button
                        v-if="doc.isExternal"
                        icon="el-icon-link"
                        @click.stop="() => handleExternalLink(doc)"
                    />
                    <el-button
                        v-else
                        icon="el-icon-download"
                        :disabled="!doc.valid"
                        @click.stop="
                            () => (doc.valid ? generateDocumentPDF(doc) : 0)
                        "
                    />
                    <el-popconfirm
                        v-only-super-admin
                        class="document-delete-confirm"
                        title="Delete this document?"
                        @confirm="() => deleteDocument(doc)"
                        @click.stop
                    >
                        <el-button
                            slot="reference"
                            type="danger"
                            plain
                            icon="el-icon-delete"
                            @click.stop
                        />
                    </el-popconfirm>
                </div>
                <!-- ------------ -->
            </template>
        </content-block>
    </div>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import Loader from '@/components/generic/Loader';

import pdfMixin from '@/mixins/pdf.mixin';
import {COMMANDS, DOCUMENT_TYPES} from '@/enums';

export default {
    name: 'project-tab-documents',
    components: {
        Loader,
        ContentBlock,
    },
    mixins: [pdfMixin],
    data() {
        return {
            loading: true,
            selectedType: null,
            documents: [],
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        estimates() {
            return this.$store.getters.estimatesForProjectWithId(
                this.$route.params.project_id
            );
        },
        typeCollection() {
            return this.documents
                .reduce((collection, doc) => {
                    // check if document belongs to this project
                    // TODO link docs to project to avoid this?
                    if (doc.type === DOCUMENT_TYPES.ESTIMATE) {
                        const estimateId = this.$options.filters.fireRef2id(
                            doc.linked_ref
                        );
                        if (!this.estimates.some((e) => e.id === estimateId)) {
                            return collection;
                        }
                    } else {
                        const projectId = this.$options.filters.fireRef2id(
                            doc.linked_ref
                        );
                        if (projectId !== this.$route.params.project_id) {
                            return collection;
                        }
                    }
                    // get or create collection for doc type
                    let type = collection.find((t) => t.name === doc.type);
                    if (!type) {
                        type = {
                            name: doc.type,
                            documents: [],
                        };
                        collection.push(type);
                    }
                    // add doc to collection
                    type.documents.push({
                        ...doc,
                        id: doc.id,
                        isExternal:
                            doc.type !== DOCUMENT_TYPES.SUMMARY &&
                            doc.type !== DOCUMENT_TYPES.ESTIMATE,
                    });
                    return collection;
                }, [])
                .sort((a, b) => a.name.localeCompare(b.name));
        },
    },
    watch: {
        typeCollection(val) {
            // auto select first element on change
            this.selectedType = val[0];
        },
    },
    async mounted() {
        await this.$bind(
            'documents',
            this.$fire.collection('documents').orderBy('created_at', 'desc'),
            {
                maxRefDepth: 0,
            }
        );

        this.loading = false;
    },
    methods: {
        handleNewDocument(type) {
            switch (type) {
                case 'summary':
                    // new summary document: link directly to new document page
                    this.$router.push({
                        name: 'document_new',
                        params: {
                            doc_type: DOCUMENT_TYPES.SUMMARY,
                            ref_id: this.$route.params.project_id,
                        },
                    });
                    break;
                case 'estimate':
                    // new estimate document: prompt to select estimate -
                    // show command palette and init with command and project
                    this.$bus.$emit('modal:action', {
                        modal: 'command-palette',
                        show: true,
                        emit: {
                            event: 'command:action',
                            payload: {
                                command: COMMANDS.NEW_ESTIMATE_DOCUMENT,
                                data: {
                                    project: {
                                        label: this.project.ref,
                                        value: this.project,
                                    },
                                },
                            },
                        },
                    });
                    break;
                default:
                    // new linked document: prompt for title & url
                    this.$bus.$emit('modal:action', {
                        modal: 'command-palette',
                        show: true,
                        emit: {
                            event: 'command:action',
                            payload: {
                                command: COMMANDS.NEW_DOCUMENT_FROM_LINK,
                                data: {
                                    project: {
                                        label: this.project.ref,
                                        value: this.project,
                                    },
                                },
                            },
                        },
                    });
                    break;
            }
        },
        editDocument(doc) {
            if (!doc.isExternal) {
                this.$router.push({
                    name: 'document_edit',
                    params: {
                        doc_id: doc.id,
                    },
                });
            }
        },
        deleteDocument(doc) {
            this.$fire.doc(`documents/${doc.id}`).delete();
        },
        handleExternalLink(doc) {
            if (doc.url) {
                window.open(doc.url, '_blank');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: -10px;
    width: calc(100% + 20px);
    flex: 1;
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;

    @media screen and (max-width: 992px) {
        flex-direction: column;
    }

    aside {
        background-color: rgba($border-grey, 0.1);
        border-right: 1px solid $border-grey;

        ul {
            padding: 10px 20px 10px 10px;
            margin: 0;
            list-style: none;
            width: 250px;

            @media screen and (max-width: 992px) {
                width: initial;
                padding: 10px;
            }

            li {
                height: 20px;
                padding: 20px;
                border-bottom: 1px solid rgba($border-grey, 0.2);
                cursor: pointer;
                display: flex;
                flex-direction: row;

                span {
                    flex: 1;
                }

                svg {
                    height: 20px;
                    width: 6px;
                    display: none;
                    opacity: 0.1;
                }

                &.active {
                    svg {
                        display: block;
                        opacity: 0.3;
                    }
                }

                &:hover {
                    svg {
                        display: block;
                    }
                }

                @media screen and (max-width: 992px) {
                    height: 40px;
                    padding: 2px 10px;
                    border: none;
                    align-items: center;

                    span {
                        font-size: 14px;
                        font-weight: 500;
                    }

                    svg {
                        display: none !important;
                    }

                    &.active {
                        span {
                            color: $blue;
                        }
                    }
                }
            }
        }
    }
    .document-list {
        flex: 1;
    }
}
.document-row {
    position: relative;
    display: flex;
    width: 100%;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid $border-grey;
    background-color: $white;
    box-sizing: border-box;
    user-select: none;
    align-items: center;

    &.editable {
        cursor: pointer;
    }

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;
    }
    &.invalid {
        background: rgba($orange-soft, 0.5);
        box-shadow: none;
        border: 1px solid $orange;
    }

    .title {
        padding: 9px 0;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
    }

    .el-button.ref {
        width: 120px;
        text-align: center;
        font-size: 11px;
    }

    span.title {
        margin-left: 12px;
        font-size: 13px;
        flex: 1;
    }

    .el-button {
        //width: 100px;
        text-align: center;
        margin-left: 10px;
    }
}
</style>
