<template>
    <el-form
        ref="form"
        auto-complete="on"
        :model="module"
        :rules="rules"
        label-width="160px"
    >
        <content-block title="Module">
            <!-- TYPE -->
            <!--*******************************************-->

            <el-form-item label="Type" prop="type_id">
                <el-select
                    v-loading="types.loading"
                    :value="_module.type.id"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(255, 255, 255, 0.7)"
                    @change="updateType"
                >
                    <el-option
                        v-for="type in types.items"
                        :key="type.id"
                        :label="type.value"
                        :value="type.id"
                    />
                </el-select>
            </el-form-item>

            <!-- SUB-TYPE -->
            <!--*******************************************-->

            <el-form-item
                v-if="_module.type.id"
                label="Framework"
                prop="subtype_id"
            >
                <el-select
                    v-loading="subtypes.loading"
                    :value="subtypeId"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(255, 255, 255, 0.7)"
                    @change="updateSubtype"
                >
                    <el-option
                        v-for="type in subtypes.items"
                        :key="type.id"
                        :label="type.value"
                        :value="type.id"
                    />
                </el-select>
            </el-form-item>

            <!-- REPOSITORY -->
            <!--*******************************************-->

            <el-form-item label="Repository" prop="repository">
                <el-row>
                    <el-col :span="8">
                        <el-select
                            :value="module.workspace"
                            placeholder="Workspace"
                            :loading="loading.workspaces"
                            filterable
                            default-first-option
                            @change="selectWorkspace"
                        >
                            <el-option
                                v-for="workspace in workspaces"
                                :key="workspace.uuid"
                                :value="workspace.slug"
                                :label="workspace.name"
                            />
                        </el-select>
                    </el-col>
                    <template v-if="workspaces.length > 0">
                        <el-col
                            :span="1"
                            style="text-align: center; opacity: 0.5;"
                        >
                            :
                        </el-col>
                        <el-col :span="4">
                            <el-select
                                v-if="projects.length > 0 || loading.projects"
                                filterable
                                default-first-option
                                :value="_module.project"
                                :loading="loading.projects"
                                @change="selectProjectGroup"
                            >
                                <el-option
                                    v-for="proj in projects"
                                    :key="proj.uuid"
                                    :value="proj.name"
                                    :label="proj.name"
                                />
                            </el-select>
                        </el-col>
                    </template>
                    <template v-if="projects.length > 0">
                        <el-col
                            :span="1"
                            style="text-align: center; opacity: 0.5;"
                        >
                            :
                        </el-col>
                        <el-col :span="10">
                            <el-select
                                v-if="
                                    repositories.length > 0 ||
                                    loading.repositories
                                "
                                filterable
                                default-first-option
                                :value="_module.repository"
                                :loading="loading.repositories"
                                @change="selectRepository"
                            >
                                <el-option
                                    v-for="repo in repositories"
                                    :key="repo.uuid"
                                    :value="repo.name"
                                    :label="repo.name"
                                />
                            </el-select>

                            <el-select
                                v-else-if="all_repositories.length > 0"
                                filterable
                                default-first-option
                                :value="_module.repository"
                                :loading="loading.repositories"
                                @change="selectRepository"
                            >
                                <el-option-group
                                    v-for="group in all_repositories_grouped"
                                    :key="group.project.key"
                                    :label="`[${group.project.key}] ${group.project.name}`"
                                >
                                    <el-option
                                        v-for="repo in group.repos"
                                        :key="repo.uuid"
                                        :value="repo.name"
                                        :label="repo.name"
                                    />
                                </el-option-group>
                            </el-select>
                        </el-col>
                    </template>
                </el-row>
            </el-form-item>

            <!-- NOTES -->
            <!--*******************************************-->

            <el-form-item label="Notes" prop="notes">
                <el-input
                    v-model="module.notes"
                    type="textarea"
                    :rows="2"
                    @change="updateNotes"
                />
            </el-form-item>
        </content-block>

        <!-- ENVIRONMENTS -->
        <!--*******************************************-->

        <template v-if="_module.repository">
            <content-block title="Environments">
                <div slot="right" style="float: right;">
                    <el-button size="mini" @click="handleNewEnvironment">
                        <span v-if="create_env">Cancel</span>
                        <span v-else>Add</span>
                    </el-button>
                </div>
                <div v-if="create_env" class="create">
                    <el-form-item label="Name">
                        <el-row class="row">
                            <el-col :span="12">
                                <el-input v-model="env_name" />
                            </el-col>
                            <el-col :span="6">
                                <el-button @click="handleCreateNewEnvironment">
                                    Create
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </div>
                <div v-for="env of environments" :key="env" class="environment">
                    <el-form-item :label="env" class="header">
                        <el-popover
                            placement="bottom"
                            trigger="click"
                            popper-class="options-popover"
                        >
                            <a slot="reference" class="options-trigger">
                                <svgicon name="settings" />
                            </a>
                            <ul>
                                <li
                                    style="color: red;"
                                    @click="() => handleRemoveEnvironment(env)"
                                >
                                    Remove
                                </li>
                            </ul>
                        </el-popover>
                    </el-form-item>
                    <hr />
                    <el-form-item label="Branch">
                        <el-select
                            filterable
                            default-first-option
                            :value="_module.environments[env].branch"
                            :loading="loading.branches"
                            @change="(val) => updateBranch(env, val)"
                        >
                            <el-option
                                v-for="branch in branches"
                                :key="branch.name"
                                :value="branch.name"
                                :label="branch.label"
                            />
                        </el-select>
                    </el-form-item>
                </div>
            </content-block>
        </template>
    </el-form>
</template>

<script>
import firebase from 'firebase/app';
import bitbucket from '@/api/bitbucket';
import ContentBlock from '@/components/blocks/ContentBlock';

export default {
    name: 'module-new',
    components: {ContentBlock},
    data() {
        return {
            create_env: false,
            env_name: '',
            rules: {},
            types: {
                loading: true,
                items: [],
            },
            subtypes: {
                loading: false,
                items: [],
            },
            module: null,
            loading: {
                repositories: false,
                workspaces: false,
                projects: false,
                branches: false,
                all_repositories: false,
            },
            repository: {
                loading: false,
                checked: false,
                exists: false,
            },
            repositories: [],
            all_repositories: [],
            branches: [],
            workspaces: [],
            projects: [],
        };
    },
    computed: {
        environments() {
            return Object.keys(this._module.environments ?? []).sort();
        },
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        _module() {
            if (this.$route.params.module_id) {
                return this.$store.getters.moduleWithId(
                    this.$route.params.module_id
                );
            }
            return null;
        },
        subtypeId() {
            if (this._module.subtype) return this._module.subtype.id;
            return null;
        },
        all_repositories_grouped() {
            return this.all_repositories
                .reduce((acc, curr) => {
                    let existing = acc.find(
                        (group) => group.project.key === curr.project.key
                    );

                    if (!existing) {
                        existing = {
                            project: curr.project,
                            repos: [],
                        };

                        acc.push(existing);
                    }

                    existing.repos.push(curr);

                    return acc;
                }, [])
                .slice(0)
                .sort((a, b) => {
                    if (a.project.key > b.project.key) return 1;
                    if (b.project.key > a.project.key) return -1;
                    return 0;
                });
        },
    },
    watch: {
        _module(val) {
            this.module = {
                ...val,
                id: val.id,
                workspace: val.workspace ? val.workspace : 'codefishstudio',
            };
        },

        '_module.type.id': {
            handler: 'fetchSubtypes',
        },
    },
    created() {
        this.module = {
            ...this._module,
            id: this._module.id,
            workspace: this._module.workspace ?? null,
        };

        if (!this._module.workspace) {
            this.selectWorkspace('codefishstudio');
        }
    },
    async mounted() {
        await this.fetchTypes();

        if (this.module.type.id) {
            this.fetchSubtypes();
        }

        await this.fetchWorkspaces();

        if (this._module && this._module.workspace) {
            await this.fetchProjects(this._module.workspace);
        }

        if (this._module && this._module.workspace && this._module.project) {
            await this.fetchRepositories(
                this._module.workspace,
                this._module.project
            );
        }

        if (this._module && this._module.workspace && this._module.repository) {
            this.fetchBranches(this.module.workspace, this._module.repository);
        }
    },
    methods: {
        handleNewEnvironment() {
            this.create_env = !this.create_env;
        },
        async handleCreateNewEnvironment() {
            this.$fire.doc(`modules/${this._module.id}`).update({
                [`environments.${this.env_name}`]: {branch: ''},
            });

            this.create_env = false;
            this.env_name = '';
        },
        handleRemoveEnvironment(env) {
            this.$fire.doc(`modules/${this._module.id}`).update({
                [`environments.${env}`]: firebase.firestore.FieldValue.delete(),
            });
        },
        selectWorkspace(workspace) {
            this.$fire.doc(`modules/${this._module.id}`).update({
                workspace: workspace,
            });
            this.selectProjectGroup(null);
            this.selectRepository(null);
            this.repositories = [];
            this.fetchProjects(workspace);
        },
        updateType(type) {
            this.$fire.doc(`modules/${this._module.id}`).update({
                type: this.$fire.doc(`system/modules/types/${type}`),
                subtype: null,
            });
        },
        updateNotes(notes) {
            this.$fire.doc(`modules/${this._module.id}`).update({
                notes: notes,
            });
        },
        updateSubtype(subtype) {
            this.$fire.doc(`modules/${this._module.id}`).update({
                subtype: this.$fire.doc(
                    `system/modules/types/${this._module.type.id}/subtypes/${subtype}`
                ),
            });
        },
        selectProjectGroup(val) {
            this.$fire.doc(`modules/${this._module.id}`).update({
                project: val,
            });
            this.selectRepository(null);
            this.repositories = [];

            if (val) {
                this.fetchRepositories(this.module.workspace, val);
            }
        },
        selectRepository(val) {
            this.$fire.doc(`modules/${this._module.id}`).update({
                repository: val,
            });

            if (val) {
                this.fetchBranches(this.module.workspace, val);
            }
        },
        updateBranch(env, branch) {
            this.$fire.doc(`modules/${this._module.id}`).update({
                [`environments.${env}.branch`]: branch,
            });
        },
        async fetchWorkspaces() {
            this.loading.workspaces = true;
            try {
                const response = await bitbucket().workspaces.get();

                if (response.values.length > 0) {
                    this.workspaces = response.values;
                }
            } catch (err) {
                console.log(err);
            }

            this.loading.workspaces = false;
        },
        async fetchBranches(workspace, repo) {
            this.loading.branches = true;
            this.branches = [];
            try {
                const response = await bitbucket(workspace)
                    .repository(repo)
                    .branches({}, true);

                this.branches = response.values;
            } catch (err) {
                console.log(err);
            }
            this.loading.branches = false;
        },
        async fetchProjects(workspace) {
            if (!this.loading.projects) {
                this.loading.projects = true;
                this.projects = [];
                try {
                    const result = await bitbucket(workspace).projects.get(1);

                    this.projects.push(...result.values);

                    if (result.size > result.pagelen) {
                        const total_pages = Math.ceil(
                            result.size / result.pagelen
                        );

                        const promises = [];

                        for (let i = 2; i < total_pages + 1; i += 1) {
                            promises.push(bitbucket(workspace).projects.get(i));
                        }
                        const results = await Promise.all(promises);

                        this.projects.push(
                            ...results.reduce((acc, curr) => {
                                acc.push(...curr.values);
                                return acc;
                            }, [])
                        );
                    }
                } catch (err) {
                    this.projects = [];
                    console.log(err);
                }

                this.loading.projects = false;
            }
        },
        async fetchRepositories(workspace, project) {
            this.loading.repositories = true;
            try {
                const result = await bitbucket(workspace)
                    .project(project)
                    .repositories();

                this.repositories = result.values;
            } catch (err) {
                this.repositories = [];
            }
            this.loading.repositories = false;
        },
        fetchTypes() {
            return this.$fire
                .collection('system/modules/types')
                .get()
                .then((snapshot) => {
                    snapshot.forEach((type) => {
                        this.types.items.push({
                            id: type.id,
                            value: type.data().value,
                        });
                    });
                    this.types.loading = false;
                });
        },
        fetchSubtypes() {
            this.subtypes.items = [];
            this.subtypes.loading = true;
            this.$fire
                .collection(
                    `system/modules/types/${this._module.type.id}/subtypes`
                )
                .get()
                .then((snapshot) => {
                    snapshot.forEach((type) => {
                        this.subtypes.items.push({
                            id: type.id,
                            value: type.data().value,
                        });
                    });
                    this.subtypes.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.el-form {
    .el-form-item {
        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        .el-input,
        .el-select {
            flex: 1;
            height: 100%;
            width: 100%;
        }
    }
}

.environment {
    .header {
        margin-bottom: 0;

        ::v-deep .el-form-item__label {
            text-align: left;
            margin-left: 20px;
        }
    }
}

span.warning {
    color: $red;
}

hr {
    margin: 20px 50px;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}

.create {
    .row {
        .el-button {
            margin: 0;
        }
    }
}
</style>
