import {momentWithTz} from '@/utils';

import {
    INVOICE_STATUS,
    PAYMENT_PLAN_DUE_TERMS,
    PAYMENT_PLAN_STATUS,
} from '@/enums';

import {fireRef2id} from '@/filters';

const paymentPlanMixin = {
    methods: {
        calculateDueDate(invoiceDate, dueValue, dueTerms) {
            if (!invoiceDate) return null;
            switch (dueTerms) {
                case PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_DATE: {
                    // due date is [dueValue] days after [invoiceDate]
                    return momentWithTz(invoiceDate).add(dueValue, 'day');
                }
                case PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_END_OF_MONTH: {
                    // due date is [dueValue] days after last day [invoiceDate]'s month
                    return momentWithTz(invoiceDate)
                        .endOf('month')
                        .startOf('day')
                        .add(dueValue, 'day');
                }
                case PAYMENT_PLAN_DUE_TERMS.DAY_OF_MONTH: {
                    // due date is on the [dueValue]th day of [invoiceDate]'s month
                    return momentWithTz(invoiceDate)
                        .startOf('month')
                        .add(dueValue - 1, 'day');
                }
                case PAYMENT_PLAN_DUE_TERMS.DAY_OF_NEXT_MONTH: {
                    // due date is on the [dueValue]th day of the month after [invoiceDate]
                    return momentWithTz(invoiceDate)
                        .startOf('month')
                        .add(1, 'month')
                        .add(dueValue - 1, 'day');
                }
            }
        },

        // total cost for payment plan invoices
        // NOTE: caller is responsible for loading the payment plan invoices
        getEstimatePaymentPlanTotal(estimate_id, sentOnly = false) {
            if (!this.payment_plan_invoices) return 0;
            return this.payment_plan_invoices.reduce((sum, pp_invoice) => {
                let includeInvoice = true;

                if (sentOnly) {
                    const invoice = this.$store.getters.invoiceWithId(
                        pp_invoice.invoice_id
                    );
                    if (
                        !invoice ||
                        (invoice.status !== INVOICE_STATUS.PAID &&
                            !invoice.sent_to_contact)
                    ) {
                        includeInvoice = false;
                    }
                }

                if (
                    estimate_id === fireRef2id(pp_invoice.estimate) &&
                    includeInvoice
                ) {
                    return pp_invoice.line_items.reduce((sum, item) => {
                        const sign = item.credit ? -1 : 1;
                        return (
                            sum +
                            this.discountValue(item.amount, item.discount) *
                                sign
                        );
                    }, sum);
                }
                return sum;
            }, 0);
        },

        // total paid for payment plan invoices
        // NOTE: caller is responsible for loading the payment plan invoices
        getEstimatePaymentPlanPaid(estimate_id) {
            if (!this.payment_plan_invoices) return 0;
            return this.payment_plan_invoices.reduce((sum, pp_invoice) => {
                if (
                    estimate_id === fireRef2id(pp_invoice.estimate) &&
                    pp_invoice.invoice_id
                ) {
                    const invoice = this.$store.getters.invoiceWithId(
                        pp_invoice.invoice_id
                    );

                    if (invoice) {
                        // Calculate the proportion of amount paid that applies to the sub_total
                        const sub_total_paid =
                            invoice.amount_paid *
                            (invoice.sub_total / invoice.total);
                        return sum + parseFloat(sub_total_paid || 0);
                    }
                }
                return sum;
            }, 0);
        },

        // total paid for payment plan invoices
        // NOTE: caller is responsible for loading the payment plan invoices
        getEstimatePaymentPlanOverdue(estimate_id) {
            const today = momentWithTz().startOf('day');
            if (!this.payment_plan_invoices) return 0;
            return this.payment_plan_invoices.reduce((sum, pp_invoice) => {
                if (
                    estimate_id === fireRef2id(pp_invoice.estimate) &&
                    pp_invoice.invoice_id
                ) {
                    const invoice = this.$store.getters.invoiceWithId(
                        pp_invoice.invoice_id
                    );
                    if (invoice) {
                        const due = momentWithTz(
                            invoice.due_date,
                            'DD/MM/YYYY'
                        );
                        if (
                            today.isAfter(due) &&
                            invoice.sent_to_contact &&
                            invoice.amount_due > 0
                        ) {
                            // amount due includes tax
                            return sum + invoice.amount_due / 1.1;
                        }
                        return sum;
                    }
                }
                return sum;
            }, 0);
        },

        isEstimatePaymentPlanSent(estimate_id) {
            if (!this.payment_plan_invoices) return false;
            this.payment_plan_invoices.forEach((pp_invoice) => {
                if (
                    estimate_id === fireRef2id(pp_invoice.estimate) &&
                    pp_invoice.invoice_id
                ) {
                    const invoice = this.$store.getters.invoiceWithId(
                        pp_invoice.invoice_id
                    );
                    // if any invoice is not sent or paid, plan is not fully sent
                    if (
                        !invoice ||
                        (invoice.status !== INVOICE_STATUS.PAID &&
                            !invoice.sent_to_contact)
                    ) {
                        return false;
                    }
                }
            });
            return true;
        },

        isEstimatePaymentPlanPaid(estimate_id) {
            if (!this.payment_plan_invoices) return false;
            this.payment_plan_invoices.forEach((pp_invoice) => {
                if (
                    estimate_id === fireRef2id(pp_invoice.estimate) &&
                    pp_invoice.invoice_id
                ) {
                    const invoice = this.$store.getters.invoiceWithId(
                        pp_invoice.invoice_id
                    );
                    // if any invoice is not paid, plan is not fully paid
                    if (!invoice || invoice.status !== INVOICE_STATUS.PAID) {
                        return false;
                    }
                }
            });
            return true;
        },

        // payment plan status
        // NOTE: caller is responsible for loading the payment plan invoices
        getPaymentPlanStatus(estimate) {
            // if the estimate does not have a final cost or the PP invoices aren't loaded,
            // this estimate is not ready for payment planning
            if (
                !Object.hasOwn(estimate, 'final_cost') ||
                !this.payment_plan_invoices
            ) {
                return null;
            }

            // if there are no PP invoices, payment plan has not been started
            if (this.payment_plan_invoices.length === 0) {
                return PAYMENT_PLAN_STATUS.PENDING;
            }

            // payment plan is started, but if the PP invoice total is less than the estimate amount,
            // the plan is still in progress
            if (
                this.getEstimatePaymentPlanTotal(estimate.id) <
                estimate.final_cost
            ) {
                return PAYMENT_PLAN_STATUS.IN_PROGRESS;
            }

            // payment plan is complete, but if the total of sent invoices is less than the estimate amount,
            // the plan is not fully invoiced
            if (
                this.getEstimatePaymentPlanTotal(estimate.id, true) <
                estimate.final_cost
            ) {
                return PAYMENT_PLAN_STATUS.PLANNED;
            }

            // payment plan is invoiced, but if the total paid amount is less than the estimate amount,
            // the plan is not fully paid
            if (
                this.getEstimatePaymentPlanPaid(estimate.id) <
                estimate.final_cost
            ) {
                return PAYMENT_PLAN_STATUS.INVOICED;
            }

            return PAYMENT_PLAN_STATUS.PAID;
        },

        // total count for payment plan invoices
        // NOTE: caller is responsible for loading the payment plan invoices
        getPaymentPlanInvoiceCount(estimate_id, sentOnly = false) {
            if (!this.payment_plan_invoices) return 0;
            return this.payment_plan_invoices.reduce((sum, pp_invoice) => {
                let includeInvoice = true;

                if (sentOnly) {
                    const invoice = this.$store.getters.invoiceWithId(
                        pp_invoice.invoice_id
                    );
                    if (
                        !invoice ||
                        (!invoice.sent_to_contact &&
                            !invoice.fully_paid_on_date)
                    ) {
                        includeInvoice = false;
                    }
                }

                if (
                    estimate_id === fireRef2id(pp_invoice.estimate) &&
                    includeInvoice
                ) {
                    return (sum += 1);
                }
                return sum;
            }, 0);
        },

        // total count for paid payment plan invoices
        // NOTE: caller is responsible for loading the payment plan invoices
        getPaymentPlanInvoicePaidCount(estimate_id) {
            if (!this.payment_plan_invoices) return 0;
            return this.payment_plan_invoices.reduce((sum, pp_invoice) => {
                if (
                    estimate_id === fireRef2id(pp_invoice.estimate) &&
                    pp_invoice.invoice_id
                ) {
                    const invoice = this.$store.getters.invoiceWithId(
                        pp_invoice.invoice_id
                    );
                    if (invoice) {
                        // get pp_invoice total
                        const ppi_total = pp_invoice.line_items.reduce(
                            (sum, item) => {
                                const sign = item.credit ? -1 : 1;
                                return (sum +=
                                    this.discountValue(
                                        item.amount,
                                        item.discount
                                    ) * sign);
                            },
                            sum
                        );
                        if (invoice.amount_paid >= ppi_total) {
                            return sum + 1;
                        }
                    }
                }
                return sum;
            }, 0);
        },

        discountValue(value, discount) {
            return value * ((100 - (discount || 0)) / 100);
        },

        undiscountValue(value, discount) {
            if (!discount) return value;
            if (discount === 100) return 0;
            return (value * 100) / (100 - discount);
        },
    },
};

export default paymentPlanMixin;
