<template>
    <command-layout :show_back="show_back" @goBack="$emit('goBack')">
        <div slot="header" class="header">
            <div class="title">New document from external link</div>
            <div class="breadcrumbs">
                <span>
                    <svgicon name="triangle" class="breadcrumb-arrow" />
                    {{ initial_data.project?.value?.ref }}
                </span>
            </div>
        </div>
        <el-form
            class="link-form"
            ref="form"
            :model="form"
            slot="body"
            label-width="80px"
        >
            <el-form-item
                label="Name"
                prop="name"
                :rules="{
                    required: true,
                    message: 'Name is required',
                    trigger: 'blur',
                }"
            >
                <el-input
                    placeholder="Document name"
                    ref="link_name"
                    v-model="form.name"
                    @keyup.backspace.native="() => handleBackspacePress()"
                />
            </el-form-item>
            <el-form-item
                label="URL"
                prop="url"
                :rules="{
                    required: true,
                    message: 'URL is required',
                    trigger: 'blur',
                }"
            >
                <el-input
                    placeholder="URL to resource"
                    v-model="form.url"
                    @keyup.backspace.native="() => handleBackspacePress()"
                />
            </el-form-item>
            <div class="controls">
                <el-button type="primary" @click="handleSubmit">
                    Create
                </el-button>
            </div>
        </el-form>
    </command-layout>
</template>

<script>
import CommandLayout from '@/components/command-palette/components/CommandLayout.vue';
import {momentWithTz} from '@/utils';
import {DOCUMENT_TYPES} from '@/enums';

export default {
    name: 'document-from-link-command-layout',
    components: {CommandLayout},
    props: {
        initial_data: {
            type: Object,
            required: true,
        },
        search_string: {
            type: String,
            default: null,
        },
        show_back: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                name: null,
                url: null,
            },
        };
    },
    computed: {
        project() {
            return this.initial_data.project?.value || null;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.link_name?.focus();
        });
    },
    methods: {
        handleBackspacePress() {
            if (this.form.name === null && this.form.url === null)
                this.$emit('goBack');
            if (this.form.name === '') this.form.name = null;
            if (this.form.url === '') this.form.url = null;
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                console.log('Submit', valid);
                if (valid) {
                    const date = momentWithTz().toDate();
                    const new_doc = {
                        name: this.form.name,
                        url: this.form.url,
                        linked_ref: this.$options.filters.fireRef2id(
                            this.project.id
                        ),
                        created_at: date,
                        updated_at: date,
                    };
                    // determine type of document from URL
                    if (this.form.url.includes('://miro.com')) {
                        new_doc.type = DOCUMENT_TYPES.MIRO;
                    } else if (this.form.url.includes('://www.figma.com')) {
                        new_doc.type = DOCUMENT_TYPES.FIGMA;
                    } else if (this.form.url.includes('.atlassian.net/wiki')) {
                        new_doc.type = DOCUMENT_TYPES.CONFLUENCE;
                    } else if (this.form.url.includes('://docs.google.com')) {
                        new_doc.type = DOCUMENT_TYPES.GOOGLE_DOC;
                    } else if (this.form.url.endsWith('.pdf')) {
                        new_doc.type = DOCUMENT_TYPES.PDF;
                    } else {
                        new_doc.type = DOCUMENT_TYPES.EXTERNAL;
                    }

                    this.$fire
                        .collection('documents')
                        .add(new_doc)
                        .then(() => {
                            this.$router.push({
                                name: 'project_detail_documents',
                                params: {
                                    project_id: this.project.id,
                                },
                            });
                            this.$emit('close');
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    margin-bottom: 10px;
}
.breadcrumbs {
    font-size: 12px;
    opacity: 0.7;
    height: 12px;
    .breadcrumb-arrow {
        width: 8px;
        height: 8px;
        margin: 0 5px;
        opacity: 0.5;
        ::v-deep path {
            fill: $white;
        }
    }
}
.link-form {
    padding: 20px;
}
.controls {
    text-align: right;
}
</style>
