import tippy from 'tippy.js';

import {Node} from '@tiptap/core';
import {VueRenderer} from '@tiptap/vue-2';

import {PluginKey} from 'prosemirror-state';

import MentionList from '../MentionList.vue';
import {SuggestionPlugin} from './SuggestionPlugin';

const SuggestionNodePluginKey = new PluginKey('suggestion-search');

const SuggestionNode = Node.create({
    name: 'suggestion_node',
    addOptions: {
        suggestion: {},
        suggestion_component: MentionList,
        getContent: () => null,
        onMatch: (matched) => null,
        allowSpaces: false,
        search: new RegExp('/^$/'),
    },
    draggable: true,
    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,
    addAttributes() {
        return {
            class: 'suggestion-mention',
        };
    },
    parseHTML() {
        return [
            {
                tag: `[data-type="${this.name}"]`,
            },
        ];
    },
    addProseMirrorPlugins() {
        return [
            SuggestionPlugin({
                editor: this.editor,
                rgx: this.options.search,
                allowSpaces: this.options.allowSpaces,
                pluginKey: SuggestionNodePluginKey,
                command: (input) => {
                    const {editor, range, props} = input;
                    this.options.onMatch(props.id);
                    const content = this.options.getContent(props.id);
                    if (content) {
                        editor
                            .chain()
                            .focus()
                            .insertContentAt(range, content)
                            .insertContent({type: 'text', text: ' '})
                            .run();
                    }
                },
                allow: ({editor, range}) => {
                    return true;
                },
                render: () => {
                    let component;
                    let popup;

                    return {
                        onStart: (props) => {
                            component = new VueRenderer(
                                this.options.suggestion_component,
                                {
                                    // using vue 2:
                                    props,
                                    editor: props.editor,
                                }
                            );

                            popup = tippy('body', {
                                getReferenceClientRect: props.clientRect,
                                appendTo: () => document.body,
                                content: component.element,
                                showOnCreate: true,
                                interactive: true,
                                trigger: 'manual',
                                placement: 'bottom-start',
                                theme: 'suggestion',
                            });
                        },
                        onUpdate: (props) => {
                            if (component) {
                                component.updateProps(props);
                            }

                            if (popup && popup[0]) {
                                popup[0].setProps({
                                    getReferenceClientRect: props.clientRect,
                                });
                            }
                        },
                        onKeyDown(props) {
                            if (popup && props.event.key === 'Escape') {
                                popup[0].hide();
                                return true;
                            }

                            if (component && component.ref) {
                                return component.ref.onKeyDown(props);
                            }

                            return false;
                        },
                        onExit() {
                            if (popup) {
                                popup[0].destroy();
                            }

                            if (component) {
                                component.destroy();
                            }
                        },
                    };
                },
                items: (query) => {
                    return [];
                },
                ...this.options.suggestion,
            }),
        ];
    },
});

export default SuggestionNode;
