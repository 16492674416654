<template>
    <transition name="fade" mode="out-in">
        <fullscreen-loader
            v-if="loading"
            :loading="loading"
            message="Loading"
        />
        <access-login v-else-if="!authenticated" @onLogin="cacheAccessCard" />
        <access-authenticated v-else :project="project" @onLogout="logout" />
    </transition>
</template>

<script>
import AccessLogin from './login';
import AccessAuthenticated from './authenticated';
import moment from 'moment';

import FullscreenLoader from '@/components/generic/FullscreenLoader';

export default {
    name: 'access',
    components: {
        AccessLogin,
        AccessAuthenticated,
        FullscreenLoader,
    },
    data() {
        return {
            loading: true,
            authenticated: false,
            project: null,
        };
    },
    async mounted() {
        if (
            !this.$route.params.project_ref ||
            this.$route.params.project_ref.length > 3
        ) {
            this.$router
                .push({
                    name: 'access_error',
                })
                .catch((err) => {});
        }

        const cachedAccessCard = JSON.parse(
            localStorage.getItem(
                `access_card_${this.$route.params.project_ref}`
            )
        );

        if (!cachedAccessCard) {
            // Access card not found
            this.loading = false;
        } else {
            // Check cached Access Card
            const today = moment().endOf('day').toDate();

            const snapshot = await this.$fire
                .doc(`access_cards/${cachedAccessCard.card_id}`)
                .get();

            const data = snapshot.data();

            if (
                snapshot.exists &&
                data.expiry.toDate() > today &&
                data.enabled
            ) {
                this.authenticated = true;
                await this.bindProject(data.project.id);
                this.removeQueryParams();
            } else {
                this.authenticated = false;
            }

            this.loading = false;
        }
    },
    methods: {
        async cacheAccessCard(data) {
            try {
                localStorage.setItem(
                    `access_card_${this.$route.params.project_ref}`,
                    JSON.stringify({
                        card_id: data.card_id,
                    })
                );
            } catch (e) {
                console.warn(e);
            }

            await this.bindProject(data.project_id);

            this.authenticated = true;
        },
        async bindProject(project_id) {
            return this.$bind(
                'project',
                this.$fire.doc(`projects/${project_id}`),
                {maxRefDepth: 0}
            );
        },
        removeQueryParams() {
            this.$router
                .replace({
                    ...this.$router.currentRoute,
                    query: {},
                })
                .catch((err) => {});
        },
        logout() {
            localStorage.removeItem(
                `access_card_${this.$route.params.project_ref}`
            );
            this.authenticated = false;
        },
    },
};
</script>

<style lang="scss">
body {
    background-color: $actual-black;
}
</style>

<style lang="scss" scoped>
.fade {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
</style>
