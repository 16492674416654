<template>
    <div class="day-project-block" :class="theme">
        <div class="block">
            <component-info :component="component" />
            <span class="actual_time">
                <template v-if="object.time_estimate">
                    {{ time_spent | milli2duration }}
                    /
                    {{ object.time_estimate | seconds2duration }}
                    <i
                        v-if="time_spent > object.time_estimate * 1000"
                        class="el-icon-warning"
                    />
                </template>
                <template v-else>
                    {{ time_spent | milli2duration }}
                </template>
            </span>
        </div>
        <text-editor
            v-if="object.notes"
            :content="object.notes"
            :theme="theme"
            :editable="false"
        />
        <ul v-if="slot_sessions.length" class="sessions">
            <session-activity-block
                v-for="session of slot_sessions"
                ref="sessions"
                :key="session.id"
                class="session"
                :session="session"
                :start="session.start"
                :stop="session.stop"
                :theme="theme"
            />
        </ul>
    </div>
</template>

<script>
import ComponentInfo from '@/components/generic/ComponentInfo';

import SessionActivityBlock from '@/components/blocks/SessionActivityBlock';

import componentSessionMixin from '@/mixins/component.session.mixin';
import TextEditor from '@/components/editor/TextEditor';

export default {
    name: 'day-project-block',
    components: {
        TextEditor,
        ComponentInfo,
        SessionActivityBlock,
    },
    mixins: [componentSessionMixin],
    props: {
        user: {
            // By defining the user here the component.session.mixin will only calculate and count the user session
            type: Object,
            required: true,
        },
        object: {
            type: Object,
            required: true,
        },
        sessions: {
            type: Array,
            default: () => [],
        },
        theme: {
            type: String,
            default: 'light',
        },
    },
    computed: {
        component() {
            return this.$store.getters.componentWithId(this.object.component);
        },
        project() {
            return this.$store.getters.projectWithId(this.component.project);
        },
        /**
         * Returns the sessions contained in the slot
         * @return {sessions[]} array of sessions
         */
        slot_sessions() {
            return this.sessions.filter((s) => {
                return s.component === this.object.component;
            });
        },
        /**
         * Overrides mixing tspent time *
         * How much time has been spent in the given sessions
         * -- Only for slot sessions
         * @return {number} time in milliseconds
         */
        tspent__closed_sessions() {
            return this.$options.filters.getTotalDurationForSessions(
                this.slot_sessions
            );
        },
    },
    methods: {
        getDayProjectSummaryHtml() {
            let html = '';
            if (this.$refs.sessions) {
                html += `${this.component.name}<ul>`;
                this.$refs.sessions.toReversed().reduce((acc, session) => {
                    const sessionHtml = session.getContentHtml();
                    if (
                        sessionHtml &&
                        sessionHtml !== '<p></p>' &&
                        !acc.includes(sessionHtml)
                    ) {
                        acc.push(sessionHtml);
                        html += `<li>${sessionHtml}</li>`;
                    }
                    return acc;
                }, []);
                html += '</ul>';
            }
            return html;
        },
        getDayProjectSummaryRaw() {
            let raw = '';
            if (this.$refs.sessions) {
                raw += `\t-${this.component.name}\n`;
                this.$refs.sessions.toReversed().reduce((acc, session) => {
                    const sessionText = session.getContentText();
                    if (sessionText && !acc.includes(sessionText)) {
                        acc.push(sessionText);
                        raw += `\t\t-${sessionText}\n`;
                    }
                    return acc;
                }, []);
            }
            return raw;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.day-project-block {
    display: flex;
    flex-direction: column;

    &.dark {
        ::v-deep .sessions {
            color: $white;

            .session {
                background-color: rgba(255, 255, 255, 0.05);
                border: none;
            }

            .el-input input {
                background-color: transparent;
                color: $white;
            }
        }
    }

    .block {
        flex: 1;
        padding: 15px 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .actual_time {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding-top: 8px;
            padding-right: 20px;
            flex: 1;
            text-align: right;

            .el-icon-warning {
                color: $orange;
                font-size: 13px;
                margin-left: 10px;
                animation: PULSE 1.4s infinite;
            }
        }
    }

    .text-editor-wrapper {
        padding: 0 20px;
    }

    .sessions {
        padding: 0px 20px;
        color: $black;

        ::v-deep .session {
            background-color: white;
            border: 1px solid $border-grey-light;
        }

        @media screen and (max-width: 992px) {
            padding: 0;
            margin: 4px 0;

            .session:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
</style>
