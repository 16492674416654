<template>
    <section
        class="group-container"
        :class="{excluded: render_excluded, expanded: is_expanded}"
    >
        <div class="head">
            <div class="grip">⣿</div>
            <span class="collapse" @click="toggleExpanded">
                <svgicon name="triangle" class="triangle" />
            </span>
            <svgicon name="jira-epic" class="jira-icon" />
            <el-input
                v-model="title"
                class="title"
                :class="{
                    empty: !title,
                    dirty: is_dirty,
                    invalid: is_invalid,
                    draft: is_estimate_draft,
                }"
                :readonly="is_estimate_locked"
                size="small"
                placeholder="Group title"
                @blur="handleBlur"
                @keyup.enter.native="onSubmitDescription"
            >
                <el-tooltip slot="suffix" placement="top">
                    <div slot="content">
                        <strong>Estimate groups</strong> are top-level groupings
                        of broad tasks.<br /><br />
                        Each group becomes a <strong>Line Item</strong> in the
                        quote,<br />
                        and a <strong>Jira epic</strong> when the estimate is
                        accepted.
                    </div>
                    <span class="info-icon">?</span>
                </el-tooltip>
            </el-input>
            <el-button
                v-if="is_estimate_draft"
                class="add-button"
                size="small"
                @click="handleSaveTask(null)"
            >
                <i class="el-icon-plus" />
            </el-button>

            <template v-if="is_estimate_draft">
                <el-popover
                    v-if="!estimate.change_request"
                    v-model="show_options"
                    placement="bottom"
                    trigger="click"
                    popper-class="options-popover"
                >
                    <a slot="reference" class="options-trigger">
                        <svgicon name="settings" />
                    </a>
                    <ul>
                        <li class="header">
                            Add group:
                        </li>
                        <li @click="handleNewGroup('newGroupAbove')">
                            Above
                        </li>
                        <li @click="handleNewGroup('newGroupBelow')">
                            Below
                        </li>

                        <template v-if="can_delete && !has_nontrivial_tasks">
                            <hr />
                            <li class="destructive" @click="handleDelete">
                                Delete
                            </li>
                        </template>
                        <template
                            v-else-if="can_delete && has_nontrivial_tasks"
                        >
                            <hr />
                            <el-popconfirm
                                class="delete-confirm"
                                placement="bottom-end"
                                :title="confirm_delete_text"
                                @confirm="handleDelete"
                            >
                                <template #reference>
                                    <li class="destructive">
                                        Delete
                                    </li>
                                </template>
                            </el-popconfirm>
                        </template>
                    </ul>
                </el-popover>
            </template>
            <div v-else-if="!is_estimate_locked" class="exclude-toggle">
                <div
                    class="exclude-icon"
                    :class="{
                        excluded: is_excluded,
                    }"
                    @click="handleExclude"
                >
                    <i class="el-icon-circle-close" />
                </div>
            </div>
        </div>

        <div v-show="is_expanded" class="tasks-container">
            <div v-if="tasks.length" class="task-header">
                <el-row :gutter="5">
                    <el-col :span="desc_header_width" class="description">
                        Description
                    </el-col>

                    <el-col
                        v-if="is_estimate_draft"
                        :span="num_width"
                        class="center"
                    >
                        Min ({{ estimate.unit }}s)
                    </el-col>

                    <el-col
                        v-if="is_estimate_draft"
                        :span="num_width"
                        class="center"
                    >
                        Max ({{ estimate.unit }}s)
                    </el-col>

                    <el-col :span="num_width" class="center">
                        {{ !is_estimate_draft ? 'Initial' : 'Qty' }}
                        ({{ estimate.unit }}s)
                    </el-col>

                    <el-col
                        v-if="!is_estimate_draft"
                        :span="num_width"
                        class="center"
                    >
                        Final ({{ estimate.unit }}s)
                    </el-col>
                    <el-col
                        v-if="estimate.unit === RATE_UNIT.DAY"
                        :span="num_width"
                        class="center"
                    >
                        Hours
                    </el-col>
                    <el-col
                        v-if="!is_estimate_draft"
                        :span="cost_width"
                        class="center cost"
                    >
                        Cost
                    </el-col>
                </el-row>
            </div>

            <draggable
                :list="sortedTasks"
                handle=".taskGrip"
                v-bind="dragOptions"
                :disabled="is_estimate_locked"
                class="tasks-dropzone"
                :class="{
                    itemSortable: !is_estimate_locked,
                    empty: !sortedTasks.length,
                }"
                group="tasks"
                :animation="100"
                @end="updateTaskOrder"
                @add="handleChangeTaskGroup"
            >
                <Estimate__task
                    v-for="task in sortedTasks"
                    :ref="`task_${task.id}`"
                    :key="task.id"
                    :estimate="estimate"
                    :group="group"
                    :task="task"
                    :show_subtasks="show_subtasks"
                    :selected="selected_task === task.id"
                    :can_delete="!is_estimate_locked"
                    :readonly="is_estimate_locked"
                    @save="handleSaveTask"
                    @delete="handleDeleteTask"
                    @exclude="handleExcludeTask"
                    @saveSubtask="handleSaveSubtask"
                    @removeSubtask="handleRemoveSubtask"
                    @deleteSubtask="handleDeleteSubtask"
                    @excludeSubtask="handleExcludeSubtask"
                    @select="handleSelectTask"
                />
            </draggable>

            <Estimate__totals
                v-if="tasks.length"
                :estimate="estimate"
                :group="group"
                :tasks="tasks"
            />
        </div>
    </section>
</template>

<script>
import Estimate__task from '@/pages/estimates/components/Estimate_task';
import Estimate__totals from '@/pages/estimates/components/Estimate_totals';

import estimateMixin from '@/mixins/estimate.mixin';

import draggable from 'vuedraggable';

export default {
    name: 'estimate-group',
    components: {
        Estimate__task,
        Estimate__totals,
        draggable,
    },
    mixins: [estimateMixin],
    props: {
        estimate: {
            type: Object,
            required: true,
        },
        group: {
            type: Object,
            required: true,
        },
        tasks: {
            type: Array,
            required: true,
        },
        can_delete: {
            type: Boolean,
            default: false,
        },
        show_subtasks: {
            // force display of subtasks on pending estimates
            type: Boolean,
            default: false,
        },
        selected_task: {
            // force display of subtasks for this task ID on pending estimates
            type: String,
            default: null,
        },
    },
    data() {
        return {
            title: '',
            show_options: false,
            collapsed: false,
            adding_new: false,
            dragOptions: {
                animation: 200,
                ghostClass: 'ghost',
                dragClass: 'dragging',
            },
            sortedTasks: [],
            debounce: false,
            expanded: true,
        };
    },
    computed: {
        is_excluded() {
            return this.group.excluded;
        },
        render_excluded() {
            if (this.is_estimate_draft) return false;
            return this.isGroupExcluded(this.group, this.tasks);
        },
        is_dirty() {
            return this.title !== this.group.title;
        },
        is_invalid() {
            // - Title has to be valid
            if (!this.has_valid_title) return true; //title is not valid
            return false;
        },
        is_expanded() {
            return this.expanded;
        },
        has_valid_title() {
            return !!this.group.title;
        },
        has_nontrivial_tasks() {
            return this.tasks.some((task) => {
                return (
                    task.description ||
                    task.qty ||
                    task.subtasks.some((subtask) => {
                        return (
                            subtask.description ||
                            subtask.min ||
                            subtask.max ||
                            subtask.qty ||
                            subtask.notes
                        );
                    })
                );
            });
        },
        confirm_delete_text() {
            const subtasks = this.tasks.flatMap((task) => {
                return task.subtasks;
            });
            return `Deleting this group will also delete its ${this.tasks.length} tasks and ${subtasks.length} subtasks. Are you sure?`;
        },
    },
    watch: {
        group: {
            handler(val) {
                this.title = val.title;
            },
            immediate: true,
        },
        tasks: {
            handler(val) {
                this.sortedTasks = val.slice(0).sort((a, b) => a.sort - b.sort);
            },
            immediate: true,
        },
    },
    methods: {
        handleBlur() {
            if (this.is_dirty) {
                this.$emit('rename', {
                    group_id: this.group.id,
                    title: this.title,
                });
            }
        },
        handleDelete() {
            this.$emit('delete', this.group.id);
        },
        handleNewGroup(event) {
            this.$emit(event);
        },
        async handleSaveTask(task) {
            let saveTask = {
                ...task,
                group: this.$fire.doc(`estimate_groups/${this.group.id}`),
            };
            // remove temporary display & working values
            delete saveTask.subtasks;
            delete saveTask.movingTo;
            this.$emit('saveTask', saveTask);
            return new Promise((res) => setTimeout(res, 200));
        },
        focusFirstTask() {
            const taskRef = this.$refs[`task_${this.tasks[0].id}`];
            const taskEl = taskRef ? taskRef[0] ?? null : null;
            if (taskEl) {
                taskEl.focus();
            }
        },
        onSubmitDescription() {
            if (this.tasks && this.tasks.length > 0) {
                this.focusFirstTask();
            } else {
                this.handleSaveTask(null).then(() => this.focusFirstTask());
            }
        },
        handleDeleteTask(taskId) {
            this.$emit('deleteTask', taskId);
        },
        handleExcludeTask(taskId) {
            this.$emit('excludeTask', taskId);
        },

        handleSelectTask(taskId) {
            this.$emit('selectTask', taskId);
        },

        handleSaveSubtask(subtaskId) {
            this.$emit('saveSubtask', subtaskId);
        },
        handleRemoveSubtask(taskId) {
            this.$emit('removeSubtask', taskId);
        },
        handleDeleteSubtask(subtask) {
            this.$emit('deleteSubtask', subtask);
        },
        handleExcludeSubtask(subtask) {
            this.$emit('excludeSubtask', subtask);
        },

        updateTaskOrder(event) {
            const item = event.item._underlying_vm_;

            // if item has been dragged to a different group, ignore event
            if (item.movingTo && item.movingTo !== this.group.id) return;

            let before = null;
            let after = null;
            if (event.newIndex > 0) {
                before = this.sortedTasks[event.newIndex - 1];
            }
            if (event.newIndex < this.sortedTasks.length - 1) {
                after = this.sortedTasks[event.newIndex + 1];
            }
            if (!before && !after) return; // only item in list

            let sort = 0;
            if (!before) {
                // first, set sort index 1 before following item
                sort = after.sort - 1;
            } else if (!after) {
                // last, set sort index 1 after preceding item
                sort = before.sort + 1;
            } else {
                // set sort index halfway between neighbours
                sort = (before.sort + after.sort) / 2;
            }
            this.$store.dispatch('updateEstimateTaskOrder', {
                task_id: item.id,
                sort,
            });
        },

        handleChangeTaskGroup(event) {
            const task = event.item._underlying_vm_;
            task.movingTo = this.group.id;
            // move event also contains sorting data
            this.handleSaveTask(task).then(() => this.updateTaskOrder(event));
        },

        handleExclude() {
            if (this.is_estimate_pending) {
                this.$emit('exclude', this.group.id);
            }
        },

        toggleExpanded() {
            this.expanded = !this.expanded;
        },
    },
};
</script>

<style lang="scss" scoped>
.group-container {
    padding: 20px;
    border-radius: 5px;
    background-color: $grey;
    margin: 0 0 10px;
    position: relative;

    &.expanded {
        padding-bottom: 10px;

        .head .collapse .triangle {
            transform: rotate(90deg);
        }
    }

    .head {
        display: flex;
        flex-direction: row;
        align-items: center;

        .grip {
            width: 20px;
            height: 23px;
            padding-left: 2px;
            text-align: center;
            opacity: 0.25;
            user-select: none;
        }

        .collapse {
            padding: 2px 10px;
            opacity: 0.3;
            cursor: pointer;
            display: flex;

            .triangle {
                color: $black;
                width: 10px;
                height: 10px;
                margin: 4px 10px 3px 0;
                transform: rotate(0deg);
                transition: transform ease-in-out 0.1s;
            }
        }

        .jira-icon {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }

        ::v-deep .el-input {
            flex: 1;
            margin-right: 10px;
            font-weight: bold;

            &.title {
                input {
                    font-weight: bold;
                    font-family: Montserrat, sans-serif;
                    font-size: 15px;
                    border: none;
                }

                &:not(.draft) {
                    margin-right: 0;
                }
            }

            &.dirty input {
                border-color: $red;
            }

            &.invalid input {
                background-color: $red-background;

                &::placeholder {
                    color: $red;
                    opacity: 0.5;
                }
            }
        }

        .add-button {
            width: 45px;
            height: 32px;
        }

        hr {
            width: 50%;
            margin: 20px 0;
            border: none;
            height: 1px;
            background-color: $border-grey-light;
        }

        .options-trigger {
            height: 32px;
            margin-left: 10px;
        }

        .el-button {
            display: inline-block;
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
        }
    }

    &.excluded {
        .head {
            position: relative;
            opacity: 0.5;

            &::after {
                display: block;
                width: calc(100% - 90px);
                height: 1px;
                background: $black;
                content: '';
                position: absolute;
                top: 16px;
                left: 58px;
                opacity: 0.6;
            }
        }
    }

    .tasks-container {
        .tasks-dropzone {
            display: block;
            min-height: 36px;
            position: relative;

            &.empty {
                &:not(:has(> *)) {
                    &::after {
                        position: absolute;
                        top: 8px;
                        left: 0;
                        width: 100%;
                        text-align: center;
                        font-size: 13px;
                        color: rgba($black, 0.5);
                        content: 'No tasks';
                    }
                }
            }
        }
    }
}

.task-header {
    font-size: 11px;
    margin: 10px 0;
    padding: 0 4px;

    .cost {
        padding-right: 48px !important;
        text-align: right !important;
    }

    .center {
        text-align: center;
    }

    .description {
        opacity: 0;
    }
}

.row {
    position: relative;
}

.empty ::v-deep input {
    border-color: $orange-soft;
}

.ghost {
    opacity: 0.5;
}

.dragging {
    cursor: grabbing;
}

.sortable .grip {
    cursor: grab;
}

.info-icon {
    width: 14px;
    height: 14px;
    font-size: 12px;
    font-weight: normal;
    color: black;
    border: 1px solid black;
    border-radius: 50%;
    margin-top: 8px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.2;
}

.exclude-toggle {
    padding-right: 4px;

    .exclude-icon {
        opacity: 0.2;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:not(.disabled):hover {
            opacity: 1;
            color: $red-soft;
        }

        &.excluded {
            opacity: 1;
            color: $red;
        }
    }
}
</style>
