export function validateEmail(str) {
    const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;
    return re.test(String(str).toLowerCase());
}

export function validateURL(textval) {
    const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    return urlregex.test(textval);
}

export function validateLowerCase(str) {
    const reg = /^[a-z]+$/;
    return reg.test(str);
}

export function validateUpperCase(str) {
    const reg = /^[A-Z]+$/;
    return reg.test(str);
}

export function validatAlphabets(str) {
    const reg = /^[A-Za-z]+$/;
    return reg.test(str);
}

export function validateRoute(route, rules) {
    for (const rule of rules) {
        const reg = new RegExp('^' + rule + '$');
        if (reg.test(route)) return true;
    }

    return false;
}

/**
 * Validate a password by returning an error message if anything is wrong with the password
 * @param password : String the password to validate
 */
export const validatePassword = (password) => {
    if (!password) return 'Required';
    if (password.length < 5) return 'Password is too short';
    //n.b. if we want harder password constraints, add them below.
};
