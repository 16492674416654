<template>
    <el-card class="player" shadow="never">
        <transition name="fade" mode="out-in">
            <div class="row">
                <div class="col" style="flex: 1;">
                    <span class="name" v-if="show_name">{{ group.name }}</span>

                    <div class="row" v-if="is_playing">
                        <stop-button
                            :loading="loading.pause"
                            @click.native="() => changeState('pause')"
                        />
                        <skip-button
                            :loading="loading.skipToNext"
                            @click.native="() => changeState('skipToNext')"
                        />

                        <el-slider
                            v-model="volume_level"
                            :step="1"
                            :min="0"
                            :max="100"
                            :show-tooltip="false"
                            @change="(v) => changeVolume(v)"
                        />
                    </div>
                    <play-button
                        v-else
                        :loading="is_buffering || loading.play"
                        @click.native="() => changeState('play')"
                    />
                </div>
                <template v-if="current_track">
                    <div class="track-info col right">
                        <span class="title">{{ current_track.title }}</span>
                        <span class="artist">
                            {{ current_track.artist }}
                        </span>
                        <span class="album">{{ current_track.album }}</span>
                    </div>

                    <!-- <img class="cover" :src="current_track.image_url" /> -->
                </template>
            </div>
        </transition>
    </el-card>
</template>

<script>
import {setPlaybackState, setVolume} from '@/api/sonos';

import debounce from 'lodash.debounce';

import StopButton from '@/components/generic/StopButton';
import PlayButton from '@/components/generic/PlayButton';
import SkipButton from '@/components/generic/SkipButton';

export default {
    name: 'sonos-group-player',
    components: {StopButton, PlayButton, SkipButton},
    props: {
        group: {
            type: Object,
            required: true,
        },
        show_name: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: {
                pause: false,
                play: false,
                skipToNext: false,
            },
            volume_level: this.group.volume ?? 0,
        };
    },
    computed: {
        is_playing() {
            return this.group.playbackState === 'PLAYBACK_STATE_PLAYING';
        },
        is_buffering() {
            return this.group.playbackState === 'PLAYBACK_STATE_BUFFERING';
        },
        current_track() {
            return this.group.currentTrack ?? null;
        },
    },
    watch: {
        'group.volume': {
            handler(val) {
                this.volume_level = val;
            },
            immediate: true,
        },
    },
    methods: {
        async changeState(state) {
            this.loading[state] = true;

            await setPlaybackState(this.group.id, state);

            this.loading[state] = false;
        },
        changeVolume: debounce(async function (level) {
            this.loading['volume'] = true;

            await setVolume(this.group.id, level);

            this.loading['volume'] = false;
        }, 500),
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-card__body {
    width: 100%;
}

.player {
    min-height: 100px;
    width: 100%;
    display: flex;
    background-color: $black;
    margin-bottom: 10px;

    span {
        color: white;

        &.name {
            font-weight: bold;
            margin-bottom: 10px;
            display: block;
        }
    }

    div.row {
        display: flex;
        flex-direction: row;
        align-items: center;

        button {
            margin-right: 10px;
        }

        .el-slider {
            width: 140px;
            margin-left: 20px;
        }
    }

    div.col {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.right {
            align-items: flex-end;
        }
    }

    .track-info {
        padding-right: 20px;

        .title {
            font-weight: bold;
        }
        .album {
            font-size: 12px;
            opacity: 0.8;
        }
    }

    .cover {
        width: 80px;
        border-radius: 6px;
    }

    .fade {
        &-enter-active,
        &-leave-active {
            transition: all 0.1s ease-in-out;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
        }
    }
}
</style>
