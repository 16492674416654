<template>
    <div class="container">
        <div class="current-status">
            <label class="title">
                Next Milestone
            </label>
            <el-card shadow="never" class="status-card">
                <h4>{{ current_milestone.content }}</h4>
                <div class="time">
                    {{ current_milestone.start.toDate() | timeAgo }}
                    <span class="light">/</span>
                    {{
                        current_milestone.start.toDate()
                            | dateformat('DD/MM/YY')
                    }}
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'project-milestone',
    props: {
        current_milestone: {
            type: Object,
            default: () => null,
        },
    },
    computed: {
        user() {
            return this.$store.getters.userWithId(
                this.current_milestone.created_by
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.status-option {
    display: flex;
    align-items: center;

    .dot {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 10px;
    }
}

.container {
    padding: 0px 20px;
    box-sizing: border-box;

    .current-status {
        width: 100%;

        .title {
            display: block;
            margin: 18px 0;
        }

        .time {
            margin-top: 10px;
            font-size: 11px;
            color: rgba($black, 0.6);

            .light {
                opacity: 0.3;
                margin: 0 5px;
            }
        }
    }

    .el-card {
        width: 100%;

        .el-select {
            width: 100%;
            margin-bottom: 10px;
        }

        ::v-deep .el-textarea {
            textarea {
                font-family: 'Rubik', sans-serif;
                padding-right: 60px;
            }
        }
    }

    .status-card {
        h4 {
            margin: 0;
        }
    }
}
</style>
