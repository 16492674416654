<template>
    <div
        class="item"
        :class="{selected: is_selected}"
        @click.stop.prevent="onClick"
        @mouseenter="onMouseEnter"
    >
        <issue-item-compact :data="data" :show_summary="true" />
    </div>
</template>

<script>
import IssueItemCompact from '@/components/issues/IssueItemCompact.vue';

export default {
    components: {IssueItemCompact},
    props: {
        data: {
            type: Object,
            required: true,
        },
        is_selected: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onClick() {
            this.$emit('click', this.data);
        },
        onMouseEnter() {
            this.$emit('mouseenter', this.data);
        },
    },
};
</script>

<style lang="scss" scoped>
.item {
    display: flex;
    cursor: pointer;
}
</style>
