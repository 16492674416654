<template>
    <div id="app" :class="{show_tracking_bar}">
        <transition name="fade" mode="out-in">
            <fullscreen-loader v-if="loading" />
            <router-view v-else />
        </transition>
    </div>
</template>

<script>
import FullscreenLoader from '@/components/generic/FullscreenLoader';

import Hotjar from '@hotjar/browser';

import userMixin from '@/mixins/user.mixin';

export default {
    name: 'wrapper',
    components: {
        FullscreenLoader,
    },
    mixins: [userMixin],
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        today() {
            return this.$store.getters.current_date;
        },
        show_tracking_bar() {
            return this.$store.getters.show_tracking_bar;
        },
    },
    watch: {
        today() {
            window.location.reload();
        },
    },
    created() {
        // Identify logged user against Hotjar session
        if (this.current_user && process.env.NODE_ENV !== 'development') {
            Hotjar.identify(this.current_user.id, {
                first_name: this.current_user.name,
            });
        }

        console.log('Binding...');

        Promise.all([
            // todo: many of these can be lazily bound
            // rewrite the bindings to be lazy where possible
            this.$store.dispatch('fetchFeatureToggles'),
            this.$store.dispatch('fetchBitbucketKeyExists'),
            this.$store.dispatch('bindSonos'),

            this.$store.dispatch('bindUsers'),
            this.$store.dispatch('bindCubes'),
            this.$store.dispatch('bindUserRoles'),
            this.$store.dispatch('bindActiveSessions'),

            this.$store.dispatch('bindProjects'),
            this.$store.dispatch('bindStages'),
            this.$store.dispatch('bindComponents'),

            this.$store.dispatch('bindOrganisation'),
            this.$store.dispatch('bindNotifications'),
            this.$store.dispatch('bindEstimates'),
            this.$store.dispatch('bindChangeRequests'),
            this.$store.dispatch('bindEventTypes'),
            this.$store.dispatch('bindEvents'),
            this.$store.dispatch('bindModules'),
            this.$store.dispatch('bindPaths'),
            this.$store.dispatch('bindCredentials'),
            this.$store.dispatch('bindValues'),
            this.$store.dispatch('startGlobalTimer'),
        ])
            .then(() => {
                const promises = [];

                if (this.$store.getters.organisation?.name)
                    document.title = `TOOL x ${this.$store.getters.organisation.name}`;

                if (this.$store.getters.is_jira_enabled)
                    promises.push(this.$store.dispatch('bindJira'));

                // Load admin data
                if (this.user_is_super_admin) {
                    promises.push(
                        this.$store.dispatch('bindInvoices'),
                        this.$store.dispatch('bindClients'),
                        this.$store.dispatch('bindPaymentPlans')
                    );
                }

                return Promise.all(promises);
            })
            .then(() => {
                this.loading = false;

                console.log('Binding done!');
            })
            .catch((e) => {
                console.error(e);
            });
    },
    beforeDestroy() {
        this.$store.dispatch('stopGlobalTimer');
    },
    mounted() {
        this.$bus.$on('session_selected', this.sessionSelected);
        this.$bus.$on('session_deselected', this.sessionSelected);
    },
    unmounted() {
        this.$bus.$off('session_selected', this.sessionSelected);
    },
    methods: {
        sessionSelected(session) {
            this.$store.dispatch('selectSession', session ?? null);
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.fade {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
</style>
