<template>
    <div class="outer">
        <header>
            <el-radio-group v-model="view" size="mini">
                <el-radio-button label="all">
                    All
                </el-radio-button>
                <el-radio-button label="planning">
                    Planning
                </el-radio-button>
                <el-radio-button label="pending_by_date">
                    Pending
                </el-radio-button>
                <el-radio-button label="paid">
                    Paid
                </el-radio-button>
            </el-radio-group>
        </header>

        <loader v-if="loading" />
        <div v-else class="inner">
            <payment-plans-by-date
                v-if="view === 'pending_by_date'"
                :plan_state="view"
                :payment_plan_invoices="payment_plan_invoices"
            />
            <payment-plans-by-project
                v-else
                :plan_state="view"
                :payment_plan_invoices="payment_plan_invoices"
            />
        </div>
    </div>
</template>

<script>
import Loader from '@/components/generic/Loader';
import PaymentPlansByProject from './components/PaymentPlansByProject';
import PaymentPlansByDate from './components/PaymentPlansByDate';

import ReferenceTemplateMixin from '@/mixins/referencetemplate.mixin';

import {fireRef2id} from '@/filters';

export default {
    name: 'payment-plans',
    components: {
        Loader,
        PaymentPlansByProject,
        PaymentPlansByDate,
    },
    mixins: [ReferenceTemplateMixin],
    data() {
        return {
            search: '',
            loading: true,
            payment_plan_invoices: [],
            view: 'all',
        };
    },
    computed: {
        projects() {
            return this.$store.getters.projects;
        },
        payment_plans() {
            return this.$store.getters.paymentPlans;
        },
    },
    async mounted() {
        await this.$bind(
            'payment_plan_invoices',
            this.$fire.collection('payment_plan_invoices'),
            {
                maxRefDepth: 0,
            }
        );

        this.loading = false;
    },
    methods: {
        fireRef2id,
    },
};
</script>

<style lang="scss" scoped>
::v-deep.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
        box-sizing: border-box;
        min-height: 65px;
        padding: 10px 20px;
        border-bottom: 1px solid $border-grey-light;
        display: flex;
        user-select: none;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .el-input {
            width: 200px;
        }

        .el-radio-group {
            margin-left: 10px;
        }
    }

    .inner {
        overflow: scroll;
        padding: 10px 20px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .payment-plan,
        .invoice-date-group,
        .invoice-unscheduled-group {
            margin-top: 10px;
            padding: 10px;
            background-color: $grey;
            border-radius: 4px;

            &.due {
                background-color: rgba($red, 0.075);
            }

            .group-label {
                font-size: 12px;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .invoice-unscheduled-group {
            background-color: rgba($orange, 0.075);
        }
    }
}
</style>
