<template>
    <section
        class="recurring-container"
        :class="component.status + '-background'"
        @click="handleClick"
    >
        <recurring-block-header
            :component="component"
            :selected_label="getDateLabelForRecurringBlock(selected_block)"
            :show_controls="
                !collapsed && !!selected_block && hide_if_highlighted
            "
            :start="selected_start"
            :end="selected_end"
            :prev_enabled="!!prev_block"
            :next_enabled="!!next_block"
            :current_enabled="!!selected_block"
            :current_label="getDateLabelForRecurringBlock(current_block)"
            @prev-block="prev"
            @next-block="next"
            @reset="reset"
        >
            <template v-if="selected_block && !collapsed" slot="times">
                <recurring-block-header-item
                    v-if="rendered_overtime"
                    label="Overtime"
                    :time="rendered_overtime"
                    type="danger"
                    sign="+"
                />
                <recurring-block-header-item
                    v-else
                    :label="`Time ${is_current_block ? 'available' : 'unused'}`"
                    :time="time_available"
                />
                <recurring-block-header-item
                    label="Time spent"
                    :time="active_time_reportable"
                />

                <recurring-block-header-item
                    label="Time allocated"
                    :time="time_allocated"
                    :calculations="time_allocated_calculations"
                    show_calculations
                    :exclude_zero_calculations="true"
                />
            </template>
        </recurring-block-header>

        <div
            v-if="selected_block && !collapsed && hide_if_highlighted"
            class="container"
        >
            <div class="bar">
                <bar-segment
                    key="debt"
                    name="Debt"
                    :active="!!time_debt"
                    :time="time_debt"
                    :total_time="full_time"
                    :height="20"
                    :index="200"
                    disabled
                    tooltip_prefix="Over from last"
                    background_color="#f92139"
                />

                <bar-segment
                    key="spent"
                    name="Spent"
                    background_color="#0f263b"
                    :total_time="full_time"
                    :active="!!active_time_reportable"
                    :time="active_time_reportable"
                    :index="50"
                    :disable_tooltip="true"
                >
                    <template v-for="(spent_block, idk) of tracked_times">
                        <template
                            v-for="(user_block, idx) of Object.entries(
                                spent_block[1]
                            )"
                        >
                            <bar-segment
                                :key="`${spent_block[0]}-${user_block[0]}`"
                                name="Spent"
                                :active="user_block[1] > 0"
                                :time="user_block[1]"
                                :total_time="active_time_reportable"
                                :height="20"
                                :index="
                                    tracked_times.length * 50 - (idx + idk * 50)
                                "
                                :border_color="
                                    (is_past ||
                                        is_future ||
                                        active_sessions.length === 0) &&
                                    idk + 1 === tracked_times.length &&
                                    idx + 1 ===
                                        Object.entries(spent_block[1]).length
                                        ? ''
                                        : '#ffffff'
                                "
                                background_color="#0f263b"
                            >
                                <template #tooltip-prefix>
                                    <div class="user-tooltip">
                                        <avatar
                                            size="mini"
                                            hide_tooltip
                                            :user="
                                                $store.getters.userWithId(
                                                    user_block[0]
                                                )
                                            "
                                        />
                                        <span class="time">
                                            {{ user_block[1] | milli2duration }}
                                        </span>
                                        <span class="date">
                                            {{
                                                new Date(spent_block[0])
                                                    | dateformat('DD/MM/YYYY')
                                            }}
                                        </span>
                                    </div>
                                </template>
                            </bar-segment>
                        </template>
                    </template>
                    <bar-segment
                        v-for="(session, idx) of active_sessions"
                        :key="session.id"
                        :time="timeForSession(session)"
                        :active="!is_past && !is_future"
                        :total_time="tspent__active_sessions"
                        :height="20"
                        background_color="#0f263b"
                        :border_color="
                            idx + 1 === active_sessions.length ? '' : '#ffffff'
                        "
                        :index="60 - idx"
                    >
                        <template #tooltip-prefix>
                            <div class="user-tooltip">
                                <avatar
                                    size="mini"
                                    hide_tooltip
                                    :user="
                                        $store.getters.userWithId(session.user)
                                    "
                                />
                            </div>
                        </template>
                    </bar-segment>
                </bar-segment>

                <bar-segment
                    key="expiring"
                    name="Expiring"
                    :active="active_time_expiring > 0"
                    :time="active_time_expiring"
                    :total_time="full_time"
                    :height="20"
                    background_color="#f5a623"
                    tooltip_prefix="Expiring"
                    :index="40"
                />

                <bar-segment
                    key="rollover"
                    name="Rollover"
                    :active="active_time_rollover > 0"
                    :time="active_time_rollover"
                    :total_time="full_time"
                    :height="20"
                    background_color="#80bc43"
                    tooltip_prefix="Rollover"
                    :index="30"
                />

                <bar-segment
                    key="remaining"
                    name="Remaining"
                    :active="active_time_available > 0"
                    :time="active_time_available"
                    :total_time="full_time"
                    :height="20"
                    background_color="#dcdfe6"
                    :tooltip_prefix="is_current_block ? 'Available' : 'Unused'"
                    :index="20"
                />
                <bar-segment
                    key="overtime"
                    name="Overtime"
                    :active="rendered_overtime > 0"
                    :time="rendered_overtime"
                    :total_time="full_time"
                    :height="20"
                    background_color="#f92139"
                    :index="10"
                    tooltip_prefix="Overtime"
                />
            </div>
        </div>
        <span v-if="!is_component_active" class="tag">
            {{ component.status }}
        </span>
    </section>
</template>

<script>
import BarSegment from '../progress/BarSegment';
import Avatar from '../generic/Avatar';

import RecurringBlockHeader from '@/components/blocks/RecurringBlockHeader';
import RecurringBlockHeaderItem from '@/components/blocks/RecurringBlockHeaderItem';

import componentRecurringMixin from '@/mixins/component.recurring.mixin';

import dayjs from 'dayjs';

import {COMPONENT_STATUS} from '@/enums';
import {momentWithTz} from '@/utils';

export default {
    name: 'recurring-component-block',
    components: {
        Avatar,
        BarSegment,
        RecurringBlockHeader,
        RecurringBlockHeaderItem,
    },
    mixins: [componentRecurringMixin],
    props: {
        component: {
            type: Object,
            required: true,
        },
        simple_ui: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            collapsed: false,
            selected_block: null,
        };
    },
    computed: {
        // If the component is active
        is_component_active() {
            return this.component.status === COMPONENT_STATUS.ACTIVE;
        },

        // Blocks
        // --------------------------
        current_block() {
            return this.getRecurringBlockForDate(dayjs(), true);
        },
        next_block() {
            if (!this.selected_block)
                return this.getRecurringBlockForDate(dayjs());

            const index = this.recurring_blocks.indexOf(this.selected_block);
            if (index >= this.recurring_blocks.length - 1) return null;
            return this.recurring_blocks[index + 1];
        },
        prev_block() {
            if (!this.selected_block)
                return this.getRecurringBlockForDate(dayjs());

            const index = this.recurring_blocks.indexOf(this.selected_block);
            if (index === 0) return null;
            return this.recurring_blocks[index - 1];
        },

        rendered_overtime() {
            return this.active_time_overtime;
        },
        hide_if_highlighted() {
            return !(this.simple_ui && this.component.highlight);
        },
    },
    watch: {
        component: {
            handler(val) {
                if (val.status === COMPONENT_STATUS.ACTIVE) {
                    this.collapsed = false;
                } else {
                    this.collapsed = true;
                }
            },
            immediate: true,
        },
        recurring_blocks: {
            handler(val) {
                if (val) this.selectBlockForDate(dayjs());
            },
            immediate: true,
        },
    },
    methods: {
        selectBlockForDate(date) {
            this.selected_block = this.getRecurringBlockForDate(date);
        },
        handleClick() {
            if (this.collapsed) {
                this.collapsed = false;
            } else {
                this.$router.push({
                    name: 'component_detail_activity',
                    params: {
                        project_id: this.$options.filters.fireRef2id(
                            this.component.project
                        ),
                        stage_id: this.$options.filters.fireRef2id(
                            this.component.stage
                        ),
                        component_id: this.component.id,
                    },
                    query: {
                        start: momentWithTz(this.selected_start).format(
                            'YYYY-MM-DD'
                        ),
                        end: momentWithTz(this.selected_end).format(
                            'YYYY-MM-DD'
                        ),
                    },
                });
            }
        },
        timeForSession(session) {
            return new Date().getTime() - session.start.seconds * 1000;
        },
        prev() {
            if (this.prev_block) {
                this.selected_block = this.prev_block;
            }
        },
        next() {
            if (this.next_block) {
                this.selected_block = this.next_block;
            }
        },
        reset() {
            this.selectBlockForDate(dayjs());
        },
    },
};
</script>

<style lang="scss" scoped>
.recurring-container {
    width: 100%;
    position: relative;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid $border-grey;
    background-color: white;
    cursor: pointer;

    &.standby-background,
    &.completed-background {
        opacity: 0.7;
    }

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;
    }

    @media screen and (max-width: 992px) {
        & > .container {
            display: none;
        }
    }

    .container {
        padding: 30px 0 10px 0;

        .bar {
            display: flex;
            width: 100%;
            height: 20px;
            background-color: $border-grey;
            border-radius: 5px;

            &:before {
                content: '';
                position: absolute;
                border-radius: 10px;
                overflow: hidden;
                width: 100%;
            }

            .bar-segment .bar-segment:last-of-type {
                border-bottom-right-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }

            .bar-segment:first-of-type {
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
            }

            .bar-segment:last-of-type {
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
            }
        }
    }

    .users {
        display: flex;
        padding: 0 10px;
    }

    .user-tooltip {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;

        .date {
            margin-bottom: 4px;
            margin-top: 4px;
        }
    }

    .tag {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 10px 10px;
        font-size: 10px !important;
        text-transform: capitalize;
        background-color: white;
        padding: 5px 10px;
        border-radius: 20px;
        font-weight: 600;
    }
}

.popover {
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    > div {
        display: flex;
        width: 99%;

        margin-bottom: 9px;

        align-items: center;
        justify-content: space-between;

        font-size: 11px;

        ::v-deep .el-button {
            margin-left: 19px;
        }
    }
}
</style>
