<template>
    <content-block title="Pull requests" no_background>
        <ul v-if="pull_requests.length" class="list">
            <pull-request-block
                v-for="pull_request in pull_requests"
                :key="pull_request.id"
                :selected="pull_request.id === active_pull_request"
                :module="module"
                :pull_request="pull_request"
                :releases="releases"
                :tags="tags"
                @click="showDetail"
                @release:create="(data) => $emit('release:create', data)"
            />
        </ul>
        <span v-else class="no-releases">No Pull requests</span>
    </content-block>
</template>

<script>
import PullRequestBlock from '@/components/modules/PullRequestBlock';
import ContentBlock from '@/components/blocks/ContentBlock';

export default {
    name: 'module-tab-environment',
    components: {
        PullRequestBlock,
        ContentBlock,
    },
    props: {
        environment: {
            type: Object,
            required: true,
        },
        module: {
            type: Object,
            required: true,
        },
        tags: {
            type: Array,
            default: () => [],
        },
        pull_requests: {
            type: Array,
            default: () => [],
        },
        releases: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            listener: null,
            active_pull_request: null,
        };
    },

    computed: {
        active() {
            if (!this.active_pull_request) return null;

            return (
                this.pull_requests.find(
                    (pr) => pr.id === this.active_pull_request
                ) ?? null
            );
        },
    },

    beforeDestroy() {
        window.removeEventListener('click', this.clickAway);
    },

    methods: {
        showDetail(id) {
            this.active_pull_request = id;
            window.addEventListener('click', this.clickAway);
        },

        clickAway() {
            this.active_pull_request = null;
            window.removeEventListener('click', this.clickAway);
        },
    },
};
</script>

<style lang="scss" scoped>
.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.no-releases {
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding: 60px 0px;
}
</style>
