var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.linkedObject
    ? _c(
        "el-button",
        {
          class: { clickable: _vm.clickable },
          attrs: { size: "small" },
          on: { click: _vm.handleClick },
        },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }