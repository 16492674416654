<template>
    <el-card shadow="never" style="margin-bottom: 5px; width: 100%;">
        <div class="top">
            <label class="title">{{ content }}</label>

            <div class="right">
                <span class="start"> {{ start_time }}</span>
                <span class="end">{{ end_time }} </span>
            </div>
        </div>

        <div v-if="!hide_attendees" class="attendees">
            <calendar-event-attendee
                v-for="attendee in sorted_attendees"
                :key="attendee.email"
                :attendee="attendee"
            />
        </div>

        <div class="section-footer">
            <div class="time">
                {{ event.start.toDate() | dateformat('ddd Do MMM') }},
            </div>

            <el-dropdown
                trigger="click"
                placement="bottom-end"
                @command="runCommand"
            >
                <div v-only-admin class="down-icon">
                    <svg class="icon" focusable="false" viewBox="0 0 24 24">
                        <path
                            d="M3.5,8.9c0-0.4,0.1-0.7,0.4-1C4.5,7.3,5.4,7.2,6,7.8l5.8,5.2l6.1-5.2C18.5,7.3,19.5,7.3,20,8c0.6,0.6,0.5,1.5-0.1,2.1 l-7.1,6.1c-0.6,0.5-1.4,0.5-2,0L4,10.1C3.6,9.8,3.5,9.4,3.5,8.9z"
                        />
                    </svg>
                </div>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="ignore">
                        Ignore event
                    </el-dropdown-item>
                    <el-dropdown-item
                        v-if="event.recurringEventId"
                        command="ignoreRecurring"
                    >
                        Ignore recurring
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </el-card>
</template>

<script>
import moment from 'moment';
import CalendarEventAttendee from './CalendarEventAttendee';

export default {
    components: {CalendarEventAttendee},
    props: {
        event: {
            type: Object,
            required: true,
        },
        hide_attendees: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        content() {
            return this.event.content;
        },
        start_time() {
            return moment(this.event.start.toDate()).format('LT');
        },
        end_time() {
            return moment(this.event.end.toDate()).format('LT');
        },
        sorted_attendees() {
            return this.event.attendees.slice(0).sort((a, b) => {
                if (a.status === 'accepted' && b.status === 'accepted')
                    return 0;
                if (a.status === 'accepted' && b.status !== 'accepted')
                    return -1;
                if (a.status !== 'accepted' && b.status === 'accepted')
                    return 1;
                return 0;
            });
        },
    },
    methods: {
        runCommand(value) {
            if (value === 'ignore') {
                this.$store.dispatch('ignoreCalendarEvent', this.event.id);
            } else if (value === 'ignoreRecurring') {
                this.$store.dispatch(
                    'ignoreCalendarEvent',
                    this.event.recurringEventId
                );
            }
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .el-card__body {
    position: relative;
}

.top {
    display: flex;
    justify-content: space-between;
}

.title {
    overflow-wrap: anywhere;
}

.right {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;

    .start {
        display: block;
        font-weight: 600;
        font-size: 18px;

        white-space: nowrap;

        color: $black;
    }
    .end {
        margin-top: 2px;
        font-size: 14px;

        white-space: nowrap;

        color: $black;
    }
}

.attendees {
    display: flex;
    flex-direction: row;
    padding: 20px 0px 10px;
}

.section-footer {
    margin-top: 12px;
    margin-bottom: -5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .time {
        color: rgba($black, 0.4);
        font-size: 11px;
    }

    .down-icon {
        cursor: pointer;
        align-items: center;
        fill: rgba($black, 0.4);
        margin-bottom: -5px;

        .icon {
            flex: 0 0 auto;
            height: 12px;
            width: 12px;
        }
    }
}
</style>
