<template>
    <section class="stage-container">
        <div class="head">
            <el-row>
                <el-col :lg="16" :sm="24">
                    <div class="row">
                        <label
                            class="title"
                            @click="handleClickOnStage('_overview')"
                        >
                            {{ stage.name ? stage.name : 'Stage' }}
                            <strong>#{{ stage.number }}</strong>
                        </label>

                        <template>
                            <el-tooltip placement="bottom">
                                <div slot="content">
                                    spent /
                                    <span v-if="total_reportable > 0"
                                        >reportable /</span
                                    >
                                    estimate
                                    <span v-only-super-admin>/ invoiced</span>
                                </div>
                                <span
                                    class="status time"
                                    @click="show_reportable = !show_reportable"
                                >
                                    <span>
                                        {{ total_spent | milli2duration }}
                                    </span>
                                    /
                                    <template v-if="total_reportable > 0">
                                        <span class="reportable">
                                            {{
                                                total_reportable
                                                    | milli2duration
                                            }}
                                        </span>
                                        /
                                    </template>
                                    <span>{{
                                        total_estimate | milli2duration
                                    }}</span>
                                    <span v-only-super-admin class="hidden">
                                        /
                                        <span>
                                            {{
                                                invoiced_hours | hours2duration
                                            }}
                                        </span>
                                    </span>
                                </span>
                            </el-tooltip>

                            <div v-if="!collapsed" class="users desktop-only">
                                <face-pile
                                    :users="[...users]"
                                    size="mini"
                                    :time_data="tracked_users_time"
                                    hide_tooltip
                                />
                            </div>
                        </template>
                    </div>
                    <stage-notes
                        :value="stage.notes || ''"
                        @change="(value) => update('notes', value)"
                    />
                </el-col>

                <el-col v-only-admin :span="8" class="options-col">
                    <!--<stage-info :stage="stage"></stage-info>-->
                    <el-popover
                        placement="bottom"
                        trigger="click"
                        popper-class="options-popover"
                    >
                        <a slot="reference" class="options-trigger">
                            <svgicon name="settings" />
                        </a>
                        <ul>
                            <li @click="handleClickOnNewComponent">
                                Add component
                            </li>
                            <hr />
                            <li
                                v-if="is_stage_completed"
                                @click="
                                    updateStage('status', STAGE_STATUS.ACTIVE)
                                "
                            >
                                Mask as active
                            </li>
                            <li
                                v-if="is_stage_active"
                                @click="
                                    updateStage(
                                        'status',
                                        STAGE_STATUS.COMPLETED
                                    )
                                "
                            >
                                Mark as completed
                            </li>
                            <hr />
                            <li @click="handleClickOnStage('_settings')">
                                Settings
                            </li>
                            <li
                                v-if="is_stage_completed"
                                @click="collapsed = !collapsed"
                            >
                                {{ collapsed ? 'Expand' : 'Collapse' }}
                            </li>
                        </ul>
                    </el-popover>
                </el-col>
            </el-row>
        </div>

        <div v-if="!collapsed" class="components-container">
            <el-alert
                v-if="!components.length"
                description="No components yet."
                :closable="false"
            />
            <template v-else>
                <recurring-component-block
                    v-for="component in recurring_components"
                    :key="`recurring_${component.id}`"
                    :component="component"
                    simple_ui
                />
                <component-block
                    v-for="component in non_recurring_components"
                    :key="`${component.id}`"
                    :component="component"
                />
            </template>
        </div>
    </section>
</template>

<script>
import StageNotes from '@/pages/stages/components/StageNotes';
import FacePile from '@/components/generic/FacePile';
import ComponentBlock from '@/components/blocks/ComponentBlock';
import RecurringComponentBlock from '@/components/blocks/RecurringComponentBlock';

import stageSessionMixin from '@/mixins/stage.session.mixin';
import stageMixin from '@/mixins/stage.mixin';

import {STAGE_STATUS} from '@/enums';
import {fireRef2id} from '@/filters';

export default {
    name: 'stage-block',
    components: {
        RecurringComponentBlock,
        FacePile,
        StageNotes,
        ComponentBlock,
    },
    mixins: [stageSessionMixin, stageMixin],
    props: {
        stage_ref: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            STAGE_STATUS: STAGE_STATUS,
            collapsed: false,
            show_reportable: true,
        };
    },
    computed: {
        tracked_users_time() {
            const tracked_time = Object.assign(this.user_totals);

            this.components.forEach((component) => {
                const active_sessions = this.$store.getters.activeSessionsForComponentWithId(
                    component.id
                );

                active_sessions.forEach((session) => {
                    const time =
                        this.$store.state.current_time -
                        session.start.seconds * 1000;

                    const u_id = fireRef2id(session.user);
                    tracked_time[u_id] += time;
                });
            });

            return tracked_time;
        },
        users() {
            return Object.keys(this.stage?.time_spent ?? {}).map((u_id) => {
                return this.$store.getters.userWithId(u_id);
            });
        },
    },
    watch: {
        is_stage_completed: {
            immediate: true,
            handler(val) {
                this.collapsed = this.is_stage_completed;
            },
        },
    },
    methods: {
        handleClickOnStage(tab = '') {
            this.$router.push({
                name: `stage_detail${tab}`,
                params: {
                    project_id: this.project.id,
                    stage_id: this.stage.id,
                },
            });
        },
        handleClickOnNewComponent() {
            this.$router.push({
                name: 'component_new',
                params: {
                    project_id: this.project.id,
                    stage_id: this.stage.id,
                },
            });
        },
        update(key, val = null) {
            this.$fire
                .collection('stages')
                .doc(this.stage.id)
                .update({
                    [key]: val,
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.stage-container {
    margin: 0;
    padding: 20px;
    border-radius: 5px;
    background-color: $grey;
    margin-bottom: 10px;

    .head {
        padding: 0;

        .row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            user-select: none;

            @media screen and (max-width: 992px) {
                flex-direction: column;
                align-items: flex-start;
            }

            label.title {
                text-transform: capitalize;
                cursor: pointer;
            }

            .status {
                margin-left: 20px;
                padding-left: 20px;
                border-left: 1px solid $border-grey-light;

                @media screen and (max-width: 992px) {
                    margin-left: 0;
                    padding-left: 0;
                    border-left: none;
                    margin-top: 10px;
                }

                .el-tag {
                    width: 120px;
                    text-align: center;
                }

                &.time {
                    font-size: 12px;
                }

                .reportable {
                    color: $blue;
                }
            }
        }

        .options-col {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            @media screen and (max-width: 1199px) {
                display: none;
            }
        }

        hr {
            width: 50%;
            margin: 20px 0;
            border: none;
            height: 1px;
            background-color: $border-grey-light;
        }

        .el-button {
            display: inline-block;
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
        }
    }

    .components-container {
        padding: 0;
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-top: 1px solid $border-grey-light;
        gap: 10px;

        @media screen and (max-width: 992px) {
            .component {
                width: 100%;
                margin-right: 0;
            }
        }
    }
}

.new {
    padding: 20px;
    border-radius: 5px;
    height: 130px;
    background-color: $grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid $border-grey-light;
    box-sizing: border-box;

    .icon {
        width: 25px;
        height: 25px;
        fill: rgba($black, 0.5);
    }

    span {
        color: rgba($black, 0.5);
        font-size: 14px;
        margin-top: 8px;
    }

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;

        .icon {
            fill: $blue;
        }

        span {
            color: $blue;
        }
    }
}

.users {
    display: flex;
    padding: 0px 20px;
}

.avatar {
    margin-left: 4px;

    &.untracked {
        opacity: 0.6;
    }
}
</style>
