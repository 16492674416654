import url from 'url';
import router from '@/router';

export const URL_TYPE = {
    ASANA: 'asana',
    ATLASSIAN: 'atlassian',
    BITBUCKET: 'bitbucket',
    CONFLUENCE: 'confluence',
    GITHUB: 'github',
    JIRA: 'jira',
    TOOL: 'tool',
    XERO: 'xero',
    UNKNOWN: 'link',
};

/**
 * Get Type for a URL, returns an entry from the URL_TYPE object
 * @param urlString string value for the url.
 */
export function getUrlType(urlString) {
    const jira_rgx = /(.*)\/(browse|issues)\/([A-Za-z]{3,}-[0-9]{1,})(\?.*|$)/;
    const confluence_rgx = /http[s]{0,1}:\/\/([A-Za-z-]*)\.(atlassian.net)\/wiki\/spaces\/([A-Za-z]*)\/pages\/([0-9]*)($|\/(.*))/gi;

    /*if (url.hostname.includes('xero.com')) {
        return 'xero';
    }*/

    const parsed = url.parse(urlString);

    const hostname = parsed.hostname;
    if (parsed.host === window.location.host) {
        return URL_TYPE.TOOL;
    }

    if (hostname.includes('atlassian.net')) {
        if (urlString.match(jira_rgx)) return URL_TYPE.JIRA;
        if (urlString.match(confluence_rgx)) return URL_TYPE.CONFLUENCE;
        return URL_TYPE.ATLASSIAN;
    }

    if (hostname.includes('asana.com')) {
        return URL_TYPE.ASANA;
    }

    if (hostname.includes('github.com')) {
        return URL_TYPE.GITHUB;
    }

    if (hostname.includes('bitbucket.org')) {
        return URL_TYPE.BITBUCKET;
    }

    return URL_TYPE.UNKNOWN;
}

export function getTitleForUrlString(urlString) {
    const parsed = url.parse(urlString);
    if (!parsed) return 'invalid';
    switch (getUrlType(urlString)) {
        case URL_TYPE.CONFLUENCE: {
            //note: copied from ConfluenceLink
            const confluence_rgx = /^http[s]{0,1}:\/\/([A-Za-z-]*)\.(atlassian.net)\/wiki\/spaces\/([A-Za-z]*)\/pages\/([0-9]*)($|\/(.*))/i;
            const matches = urlString.match(confluence_rgx);
            if (matches.length > 6)
                return decodeURI(matches[6]).replaceAll('+', ' ');
            break;
        }

        case URL_TYPE.GITHUB:
        case URL_TYPE.BITBUCKET: {
            // note: GITHUB and BITBUCKET are handled the same way
            const gitRegex = /^\/(\w*)\/([0-9A-Z-_]*)/i;
            const matches = parsed.pathname.match(gitRegex);
            if (matches && matches.length > 2) {
                return `${matches[1]} : ${matches[2]}`;
            }
            return parsed.pathname;
        }
        case URL_TYPE.JIRA: {
            // issue id
            const rgx = /(.*)\/(browse|issues)\/([A-Za-z0-9]{3,}-[0-9]+)(\?.*|$)/;
            const matches = urlString.match(rgx);
            return matches[3];
        }
        case URL_TYPE.TOOL: {
            // route info - note that this isn't handled well yet. See TUL-439
            const resolved = router.resolve(parsed.path).resolved;

            if (resolved?.meta?.title) {
                return resolved.meta.title;
            }
            if (resolved?.meta?.tab) {
                return `${resolved.meta.key} → ${resolved.meta.tab.substr(1)}`;
            }
            const rgx = /^\/(\w*)\/([A-Za-z0-9-]*)/;
            const matches = this.url.path.match(rgx);
            if (matches && matches.length >= 2) {
                return `${matches[1]} → ${matches[2]}`;
            }

            return 'unknown';
        }

        case URL_TYPE.ASANA:
        case URL_TYPE.ATLASSIAN:
        case URL_TYPE.UNKNOWN:
        default:
            // don't know how to parse the url
            break;
    }
    //couldn't find a name - just use the hostname
    return parsed.hostname;
}
