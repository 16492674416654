<template>
    <content-block
        v-if="modules.length"
        title="Affected modules"
        :collapsable="is_expanded"
        expanded
    >
        <ul class="modules">
            <module-block
                v-for="module in modules"
                :key="module.id"
                :module="module"
                :class="{selected: isSelected(module), selectable: is_expanded}"
                @click.native="selected(module)"
            />
        </ul>
    </content-block>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import ModuleBlock from '@/components/blocks/ModuleBlock';
import firebase from 'firebase/app';

export default {
    name: 'change-request-modules',
    components: {
        ContentBlock,
        ModuleBlock,
    },
    props: {
        change_request: {
            type: Object,
            required: true,
        },
        project: {
            type: Object,
            required: false,
            default: null,
        },
    },
    computed: {
        all_modules() {
            return this.$store.getters.modulesForProjectWithId(this.project.id);
        },
        modules() {
            if (this.is_expanded) {
                return this.all_modules;
            } else if (this.change_request.modules) {
                return this.all_modules.filter((m) =>
                    this.change_request.modules.includes(`modules/${m.id}`)
                );
            }
            return [];
        },
        is_expanded() {
            return this.change_request.status <= this.$enums.CR_STATUS.SCOPING;
        },
    },
    methods: {
        selected(module) {
            if (this.is_expanded) {
                // Selecting / deselecting the module to be part of the CR
                const ref = this.$fire.doc(`modules/${module.id}`);
                this.update({
                    modules: this.isSelected(module)
                        ? firebase.firestore.FieldValue.arrayRemove(ref)
                        : firebase.firestore.FieldValue.arrayUnion(ref),
                });
            } else {
                // Clicking the module
            }
        },
        update(data) {
            return this.$fire
                .collection('change_requests')
                .doc(this.change_request.id)
                .update(data);
        },
        isSelected(module) {
            if (!this.change_request.modules) return false;
            return this.change_request.modules.includes(`modules/${module.id}`);
        },
    },
};
</script>

<style lang="scss" scoped>
.content-block {
    padding-bottom: 10px;

    .modules {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style: none;

        ::v-deep .container {
            background-color: $grey;
            border-color: $border-grey-light;

            &.selected {
                background-color: $white;
                border-color: $border-grey;

                &.selectable {
                    border-color: $blue;
                }
            }
        }
    }
}
</style>
