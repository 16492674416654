<template>
    <div>
        <section>
            <el-row :gutter="12">
                <el-col :sm="24" :md="12" :lg="6">
                    <widget-card :loading="false">
                        <div class="filters">
                            <span>Filter dates</span>
                            <el-date-picker
                                v-model="date_range"
                                type="daterange"
                                align="right"
                                unlink-panels
                                :clearable="false"
                                size="mini"
                                format="dd/MM/yyyy"
                                range-separator="To"
                                start-placeholder="Start date"
                                end-placeholder="End date"
                                :picker-options="pickerOptions"
                            />
                        </div>
                    </widget-card>
                </el-col>
                <el-col :sm="24" :md="12" :lg="6">
                    <widget-card :loading="false">
                        <div class="filters">
                            <span>Users</span>
                            <div class="users">
                                <template v-if="!filter_users.length">
                                    <div
                                        v-for="(_, idx) in Array(6).fill()"
                                        :key="idx"
                                        class="avatar"
                                    />
                                    <div class="overlay" />
                                </template>
                                <template v-else>
                                    <div
                                        v-for="obj in filter_users"
                                        :key="obj.user.id"
                                        class="avatar"
                                        :class="obj.status"
                                        @click="toggleUser(obj)"
                                    >
                                        <avatar :user="obj.user" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </widget-card>
                </el-col>
                <el-col :sm="24" :md="12" :lg="6">
                    <widget-card :loading="loading">
                        <div class="key-value">
                            <label>Spent by selected users</label>
                            <span>
                                {{
                                    total_time_spent_filter_users
                                        | seconds2duration
                                }}
                            </span>
                        </div>
                    </widget-card>
                </el-col>
                <el-col :sm="24" :md="12" :lg="6">
                    <widget-card
                        v-clipboard:copy="
                            seconds2hours(total_time_spent).toFixed(4)
                        "
                        class="total-hours"
                        :loading="loading"
                        @click.native="
                            Notification({
                                type: 'success',
                                title: 'Copied',
                                message: `${seconds2hours(
                                    total_time_spent
                                ).toFixed(4)} hours copied to clipboard.`,
                            })
                        "
                    >
                        <div
                            v-if="has_reported_hours_rounding"
                            class="key-value"
                        >
                            <label>Total spent / reportable hours</label>
                            <span>
                                {{ total_time_spent | seconds2duration }} /
                                <b>
                                    {{ total_time_reportable | milli2duration }}
                                </b>
                            </span>
                        </div>
                        <div v-else class="key-value">
                            <label>Total time</label>
                            <span>
                                {{ total_time_spent | seconds2duration }}
                            </span>
                        </div>
                    </widget-card>
                </el-col>
            </el-row>
        </section>
        <section>
            <div
                v-if="loading"
                v-loading="true"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0)"
            />
            <el-timeline v-else-if="filtered_daily_sessions.length">
                <el-timeline-item
                    v-for="day in filtered_daily_sessions"
                    :key="day.date.format('dddd, DD MMMM, YYYY')"
                    :timestamp="day.date.format('dddd, DD MMMM, YYYY')"
                    placement="top"
                >
                    <el-card shadow="never">
                        <user-activity-block
                            v-for="(user_sessions, user_id) in day.users"
                            :key="user_id"
                            :user_id="user_id"
                            :sessions="user_sessions"
                        />
                    </el-card>
                </el-timeline-item>
            </el-timeline>
            <el-alert
                v-else
                description="There are no sessions for the selected period."
                :closable="false"
            />
        </section>
    </div>
</template>

<script>
import moment from 'moment';

import WidgetCard from '@/components/generic/WidgetCard';
import Avatar from '@/components/generic/Avatar';
import UserActivityBlock from '@/components/blocks/UserActivityBlock';

import componentSessionMixin from '@/mixins/component.session.mixin';
import {Notification} from 'element-ui';
import {seconds2hours} from '@/filters';
import {momentWithTz} from '@/utils';

export default {
    name: 'component-tab-activity',
    components: {
        WidgetCard,
        Avatar,
        UserActivityBlock,
    },
    mixins: [componentSessionMixin],
    data() {
        return {
            selected_block: null,
            sessions: [],
            date_range: [],
            filter_users: [],
            loading: false,
            loadingTimer: null,
            pickerOptions: {
                firstDayOfWeek: 1,
                shortcuts: [
                    {
                        text: 'Last week',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7
                            );
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: 'This month',
                        onClick(picker) {
                            const today = new Date();
                            const end = today;
                            const start = new Date(
                                today.getFullYear(),
                                today.getMonth(),
                                1
                            );
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: 'Last month',
                        onClick(picker) {
                            const today = new Date();
                            const end = new Date(
                                today.getFullYear(),
                                today.getMonth(),
                                1 - 1
                            );
                            const start = new Date(
                                today.getFullYear(),
                                today.getMonth() - 1,
                                1
                            );
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '3 months',
                        onClick(picker) {
                            const today = new Date();
                            const end = new Date();
                            const start = new Date(
                                today.getFullYear(),
                                today.getMonth() - 3,
                                1
                            );
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '6 months',
                        onClick(picker) {
                            const today = new Date();
                            const end = new Date();
                            const start = new Date(
                                today.getFullYear(),
                                today.getMonth() - 6,
                                1
                            );
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ],
            },
        };
    },
    computed: {
        has_reported_hours_rounding() {
            if (this.component) {
                return !!this.component.min;
            }
            return false;
        },
        current_time() {
            if (this.has_active_sessions) {
                return this.$store.state.current_time;
            }
            return new Date().getTime();
        },
        component() {
            return this.$store.getters.componentWithId(
                this.$route.params.component_id
            );
        },
        users() {
            return this.$store.getters.users;
        },
        involved_users() {
            return [
                ...new Set(
                    this.sessions.map((s) => {
                        return s.user;
                    })
                ),
            ].map((user_ref) => {
                return this.$store.getters.userWithId(
                    this.$options.filters.fireRef2id(user_ref)
                );
            });
        },
        daily_sessions() {
            const daily = this.sessions
                .reduce((acc, cur) => {
                    if (cur.stop && cur.stop.seconds - cur.start.seconds < 60) {
                        return acc;
                    }
                    const date = momentWithTz(moment.unix(cur.start.seconds));
                    const user = this.filter_users.find(
                        (u) => `users/${u.user.id}` === cur.user
                    );

                    if (!user) return acc;

                    let existingDate = acc.find((a) =>
                        date.isSame(a.date, 'day')
                    );

                    if (!existingDate) {
                        existingDate = {
                            date,
                            users: {},
                        };

                        acc.push(existingDate);
                    }

                    let existingUser = existingDate.users[user.user.id];

                    if (!existingUser) {
                        existingUser = existingDate.users[user.user.id] = [];
                    }

                    existingUser.push(cur);

                    return acc;
                }, [])
                .sort((a, b) => {
                    return new Date(b.date) - new Date(a.date);
                });
            return daily;
        },
        has_active_sessions() {
            return this.sessions.some((session) => {
                return !session.stop;
            });
        },
        filtered_daily_sessions() {
            return this.daily_sessions
                .map((day) => {
                    const users = Object.entries(day.users).reduce(
                        (acc, [u_id, cur]) => {
                            const user = this.filter_users.find(
                                (u) =>
                                    u.user.id === u_id && u.status === 'active'
                            );

                            if (user) {
                                acc[u_id] = cur;
                            }
                            return acc;
                        },
                        {}
                    );

                    return {...day, users};
                })
                .filter((day) => Object.keys(day.users).length > 0);
        },
        filtered_sessions() {
            var data = [];
            this.filter_users.map((obj) => {
                if (obj.status == 'active') {
                    data = data.concat(
                        this.sessions.filter(
                            (s) => s.user === `users/${obj.user.id}`
                        )
                    );
                }
            });
            return data;
        },
        total_time_spent() {
            return this.sessions
                .map((s) => {
                    if (s.stop) {
                        return s.stop.seconds - s.start.seconds;
                    } else {
                        //Session is active
                        return this.current_time / 1000 - s.start.seconds;
                    }
                })
                .reduce((a, b) => a + b, 0);
        },
        total_time_reportable() {
            if (!this.component.time_reportable) return 0;
            const date_range_start = momentWithTz(this.date_range[0]).startOf(
                'day'
            );
            const date_range_end = momentWithTz(this.date_range[1]).endOf(
                'day'
            );

            return Object.keys(this.component.time_reportable)
                .filter((day) => {
                    const entryDate = momentWithTz(day, 'YYYY-MM-DD');
                    // ignore days outside date range
                    return (
                        date_range_start.isSameOrBefore(entryDate) &&
                        date_range_end.isSameOrAfter(entryDate)
                    );
                })
                .reduce((acc, day) => {
                    return (
                        acc +
                        Object.values(
                            this.component.time_reportable[day]
                        ).reduce((a, t) => {
                            return a + t;
                        }, 0)
                    );
                }, 0);
        },
        total_time_spent_filter_users() {
            return this.filtered_sessions
                .map((s) => {
                    if (s.stop) {
                        return s.stop.seconds - s.start.seconds;
                    } else {
                        //Session is active
                        return this.current_time / 1000 - s.start.seconds;
                    }
                })
                .reduce((a, b) => a + b, 0);
        },
    },
    watch: {
        date_range: function (val) {
            const today_start = momentWithTz(val[0]).startOf('day').toDate();
            const today_end = momentWithTz(val[1]).endOf('day').toDate();

            // Ending loading state when the bind finishes did not guarantee data has finished loading.
            // Loading is now handled by monitoring for data changes following bind.
            // Loading state ends after a timer delay when filtered_daily_sessions stops updating.
            this.startLoadingTimer();

            this.$bind(
                'sessions',
                this.$fire
                    .collection('sessions')
                    .where(
                        'component',
                        '==',
                        this.$fire.doc(
                            `components/${this.$route.params.component_id}`
                        )
                    )
                    .where('start', '>=', today_start)
                    .where('start', '<=', today_end),
                {maxRefDepth: 0}
            );

            const queryParams = new URLSearchParams(window.location.search);

            queryParams.set('start', momentWithTz(val[0]).format('YYYY-MM-DD'));
            queryParams.set('end', momentWithTz(val[1]).format('YYYY-MM-DD'));

            history.replaceState(null, null, '?' + queryParams.toString());
        },

        sessions(val) {
            this.filter_users = this.involved_users.map((user) => {
                return {
                    user,
                    status: 'active',
                };
            });
        },

        filtered_daily_sessions(newVal, oldVal) {
            // Monitor this property for changes during the loading state.
            // If any changes occur while loading, restart the timer.
            if (this.loading && newVal !== oldVal) {
                this.startLoadingTimer();
            }
        },
    },
    mounted() {
        if (this.$route.query.start && this.$route.query.end) {
            this.date_range = [
                momentWithTz(this.$route.query.start).startOf('day').toDate(),
                momentWithTz(this.$route.query.end).startOf('day').toDate(),
            ];
        }

        if (!this.$route.query.start) {
            const today = new Date();
            const end = new Date();
            const start = new Date(
                today.getFullYear(),
                today.getMonth() - 3,
                1
            );
            this.date_range = [start, end];
        }
    },
    methods: {
        seconds2hours,
        Notification,
        selectDateRange(block) {
            this.date_range = [block.start, block.end];
        },
        toggleUser(obj) {
            obj.status = obj.status == 'active' ? 'inactive' : 'active';
        },

        /**
         *  Starts the loading timer.
         *  Whenever the data state changes during loading, call this function again to restart the timer.
         *  After the specified delay with no further updates, loading state ends.
         */
        startLoadingTimer() {
            this.loading = true;
            if (this.loadingTimer) clearTimeout(this.loadingTimer);
            this.loadingTimer = setTimeout(() => {
                this.loading = false;
                this.loadingTimer = null;
            }, 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
section {
    margin: 0 0 10px 0;
    padding: 20px;
    border-radius: 5px;
    background-color: $grey;
}

.filters {
    flex: 1;
    display: flex;
    flex-direction: column;

    span {
        pointer-events: none;
        display: block;
        text-align: center;
        font-size: 12px;
        color: rgba($black, 0.5);
        margin-bottom: 10px;
    }

    .el-date-editor--daterange.el-input__inner {
        width: unset;
    }
}

.users {
    display: flex;
    flex-direction: row;

    .overlay {
        position: absolute;
        width: 200px;
        height: 24px;
        background: linear-gradient(90deg, rgba($white, 0) 0%, $white 100%);
    }

    .avatar {
        margin: 0 2.5px;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        background-color: $border-grey-light;

        &.inactive {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            opacity: 0.7;
        }
    }
}

.el-timeline {
    margin-top: 30px;

    @media screen and (max-width: 992px) {
        padding: 0;

        ::v-deep .el-timeline-item {
            &__wrapper {
                padding-left: 0;
            }

            &__node {
                display: none;
            }
        }
    }
}

.total-hours {
    &:hover {
        cursor: pointer;
    }

    b {
        color: $blue;
        font-weight: 400;
    }
}
</style>
