/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'steps-done-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 16A8 8 0 118 0a8 8 0 010 16zm0-2A6 6 0 108 2a6 6 0 000 12zm0-2a4 4 0 110-8 4 4 0 010 8z" _fill="#000" fill-rule="evenodd"/>'
  }
})
