/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'steps-progress-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M.052 8a7.77 7.77 0 00.637 3.105 8.055 8.055 0 001.707 2.551 8.055 8.055 0 002.55 1.707A7.77 7.77 0 008.053 16a7.77 7.77 0 003.105-.637 8.055 8.055 0 002.55-1.707 8.055 8.055 0 001.708-2.551A7.77 7.77 0 0016.052 8a7.77 7.77 0 00-.637-3.105 8.055 8.055 0 00-1.707-2.551A8.055 8.055 0 0011.157.637 7.77 7.77 0 008.052 0a7.77 7.77 0 00-3.105.637 8.055 8.055 0 00-2.551 1.707A8.055 8.055 0 00.689 4.895 7.77 7.77 0 00.052 8zm12.39 1.396a4.675 4.675 0 00-5.883-5.883l.61 1.904a2.676 2.676 0 013.368 3.369l1.905.61zM8.74 10.583a2.677 2.677 0 01-2.715-.653 2.678 2.678 0 01-.653-2.715l-1.905-.611a4.677 4.677 0 005.883 5.884l-.61-1.905z" _fill="currentColor" fill-rule="evenodd"/>'
  }
})
