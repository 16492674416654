<template>
    <div>
        <div class="block">
            <el-button @click="find">
                Find orphaned sessions
            </el-button>
            <el-table
                v-if="orphans.sessions.length"
                :data="orphans.sessions"
                size="mini"
            >
                <el-table-column prop="id" label="ID" />
                <el-table-column prop="start" label="Start" />
                <el-table-column prop="stop" label="Stop" />
            </el-table>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'find-orphaned-sessions',
    data() {
        return {
            orphans: {
                sessions: [],
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {
        async find() {
            const sessionsSnapshot = await this.$fire
                .collection('sessions')
                .get();

            const promises = [];

            sessionsSnapshot.forEach((docSnapshot) => {
                const p = new Promise((resolve, reject) => {
                    docSnapshot
                        .data()
                        .component.get()
                        .then((component) => {
                            if (!component.exists) {
                                resolve({
                                    id: docSnapshot.id,
                                    start: moment(
                                        docSnapshot.data().start.seconds * 1000
                                    ).format('DD/MM/YY HH:mm'),
                                    stop: docSnapshot.data().stop
                                        ? moment(
                                              docSnapshot.data().stop.seconds *
                                                  1000
                                          ).format('DD/MM/YY HH:mm')
                                        : 'Now',
                                });
                            } else {
                                resolve(null);
                            }
                        });
                });

                promises.push(p);
            });

            const results = await Promise.all(promises);

            this.orphans.sessions = [...results.filter((s) => !!s)];
        },
    },
};
</script>

<style lang="scss" scoped>
.el-table {
    width: 600px;
}

.block {
    align-items: flex-start;
    justify-content: flex-start;
}
</style>
