<template>
    <div class="wrapper">
        <div class="container" @click="handleClick">
            <el-tag
                :disable-transitions="true"
                size="small"
                class="ref"
                :type="tag_type"
            >
                {{ estimate.ref }}
            </el-tag>

            <span class="title">
                {{ estimate.title }}
            </span>
        </div>

        <payment-plan-summary-block
            v-if="show_paymentplan"
            :estimate="estimate"
        />
    </div>
</template>

<script>
import estimateMixin from '@/mixins/estimate.mixin';
import paymentPlanMixin from '@/mixins/paymentplan.mixin';

import PaymentPlanSummaryBlock from '@/components/blocks/PaymentPlanSummaryBlock';

import {ESTIMATE_STATUS} from '@/enums';

export default {
    name: 'estimate-block',
    components: {PaymentPlanSummaryBlock},
    mixins: [estimateMixin, paymentPlanMixin],
    props: {
        project: {
            type: Object,
            required: true,
        },
        estimate: {
            type: Object,
            required: true,
        },
    },
    computed: {
        tag_type() {
            return this.status2TagType(this.estimate.status);
        },
        show_paymentplan() {
            return (
                !this.estimate.payment_voided &&
                this.estimate.status !== ESTIMATE_STATUS.DRAFT &&
                this.estimate.status !== ESTIMATE_STATUS.DECLINED
            );
        },
    },
    methods: {
        handleClick() {
            this.$router.push({
                name: 'project_detail_estimate+detail',
                params: {
                    project_id: this.project.id,
                    estimate_id: this.estimate.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    &:last-of-type {
        margin: 0;
    }

    .container {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        width: 100%;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid $border-grey;
        cursor: pointer;
        background-color: $white;
        box-sizing: border-box;
        user-select: none;

        &:hover {
            box-shadow: 0 0 0 1px $blue;
            border: 1px solid $blue;
        }

        .el-tag.ref {
            width: 120px;
            text-align: center;
            font-size: 11px;
        }

        span.title {
            margin-left: 12px;
            font-size: 13px;
            flex: 1;
        }

        .el-tag {
            width: 100px;
            text-align: center;
            margin-left: 10px;
        }

        .info {
            span {
                font-size: 8px;
                opacity: 0.8;
            }
        }
    }
}
</style>
