<template>
    <div class="adjustment-row">
        <div class="block">{{ adjustment.label }}</div>
        <div class="description">
            {{ adjustment.description }}
        </div>
        <div class="hours">{{ adjustment.time | milli2duration }} hours</div>
        <div class="remove">
            <el-popconfirm
                title="Delete this time adjustment?"
                @confirm="removeAdjustment()"
            >
                <i slot="reference" class="el-icon-circle-close" />
            </el-popconfirm>
        </div>
    </div>
</template>

<script>
export default {
    name: 'component-adjustment',
    props: {
        adjustment: {
            type: Object,
            required: true,
        },
    },
    methods: {
        removeAdjustment() {
            const block = this.adjustment.block;
            if (block?.time_adjustment) {
                const index = block.time_adjustment.findIndex(
                    (i) => i.id === this.adjustment.id
                );
                if (index >= 0) {
                    // remove adjustment from array
                    const time_adjustment = block.time_adjustment.toSpliced(
                        index,
                        1
                    );
                    this.$fire
                        .doc(`component_recurring_blocks/${block.id}`)
                        .update({time_adjustment});
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.adjustment-row {
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding: 5px 0 5px 20px;
    font-size: 12px;
    line-height: 20px;
    gap: 10px;
    background: #f4f4f5;
    border-radius: 4px;

    .block {
        width: 160px;
    }
    .description {
        flex: 1;
    }
    .hours {
        text-align: right;
        font-weight: bold;
    }
    .remove {
        text-align: right;
        width: 50px;
        cursor: pointer;
        padding-right: 10px;

        i {
            font-size: 16px;
            padding: 5px;
            color: $red;
        }
    }
}
</style>
