<template>
    <el-select
        filterable
        :value="project"
        :placeholder="placeholder"
        value-key="id"
        size="mini"
        @change="(val) => $emit('change', val)"
    >
        <el-option
            v-for="p in sorted_projects"
            :key="p.id"
            :label="p.name"
            :value="p"
        >
            <span style="float: left;">
                {{ p.name }}
            </span>
            <span style="float: right; color: #8492a6; font-size: 13px;">
                {{ p.ref }}
            </span>
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: 'project-picker',
    props: {
        projects: {
            type: Array,
            required: true,
            default: () => [],
        },
        project: {
            type: Object,
            default: null,
        },
        placeholder: {
            type: String,
            default: 'Select project',
        },
    },
    computed: {
        sorted_projects() {
            const statusOrder = {active: 1, lead: 2, archived: 3};

            return this.projects.slice(0).sort((a, b) => {
                // Compare statuses based on the predefined order
                let statusComparison =
                    statusOrder[a.status] - statusOrder[b.status];

                // If statuses are the same, sort alphabetically by 'name'
                if (statusComparison === 0) {
                    return a.name.localeCompare(b.name);
                }

                return statusComparison;
            });
        },
    },
};
</script>
