<template>
    <span v-if="status" class="status" :class="size" :style="cssStyles">
        {{ status.label }}
    </span>
</template>

<script>
export default {
    name: 'project-list-item',
    props: {
        status: {
            type: Object,
            default: () => null,
        },
        size: {
            type: String,
            default: '',
        },
    },
    computed: {
        cssStyles() {
            return {
                '--color': this.status.color,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.status {
    font-size: 10px;
    text-transform: capitalize;
    padding: 3px 10px;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    width: 50px;
    text-align: center;
    border: 1px solid var(--color);

    &.mini {
        font-size: 0;
        padding: 0;
        border-radius: 4px;
        width: 6px;
        height: 6px;
        background-color: var(--color);
    }
}
</style>
