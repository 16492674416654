<template>
    <nav class="breadcrumb">
        <div class="medium_wrapper row">
            <transition name="slide-back">
                <div v-if="can_go_back" class="back" @click="goBackAction">
                    <svgicon name="triangle" class="triangle" />
                    <label>BACK <span>/</span></label>
                </div>
            </transition>

            <transition name="slide-title" mode="out-in">
                <div
                    v-if="item"
                    :key="item.meta.title || remote_title"
                    class="title"
                >
                    <span>{{ item.meta.title || remote_title }}</span>
                    <span v-if="item.meta.ref" class="ref">
                        [{{ item.meta.ref }}]
                    </span>
                </div>
            </transition>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'breadcrumb',
    data() {
        return {
            remote_title: '',
        };
    },
    computed: {
        can_go_back() {
            return this.item && this.item.meta.back_to;
        },
        go_back_to() {
            return this.item && this.item.meta.back_to
                ? this.item.meta.back_to
                : null;
        },
        item() {
            let matched = this.$route.matched.filter(
                (item) => item.meta.title || item.meta.key
            );
            if (matched.length > 0) {
                var item = matched[0];
                return item;
            }

            return null;
        },
    },
    watch: {
        item: {
            immediate: true,
            handler(item) {
                if (!item) return;
                switch (item.meta.key) {
                    case 'project.name':
                        this.remote_title =
                            this.$store.getters.projectWithId(
                                this.$route.params.project_id
                            )?.name || '';
                        break;

                    case 'component.name':
                        this.remote_title =
                            this.$store.getters.componentWithId(
                                this.$route.params.component_id
                            )?.name || '';
                        break;

                    default:
                        break;
                }
            },
        },
    },
    methods: {
        goBackAction() {
            switch (this.go_back_to) {
                default:
                    this.$router.go(-1);
                    break;
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
nav {
    top: 61px;
    position: fixed;
    width: 100%;
    z-index: 3000;
    user-select: none;

    border-bottom: 1px solid $border-grey-light;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    background-color: rgba(255, 255, 255, 0.9);

    @media screen and (max-width: 992px) {
        top: auto;
        position: relative;
        background-color: $white;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 40px;
        height: 78px;
        box-sizing: border-box;

        @media screen and (max-width: 992px) {
            padding: 5px 20px;
            height: 40px;
        }
    }

    .back {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;
        width: 85px;

        .triangle {
            color: $black;
            width: 10px;
            height: 10px;
            margin: 4px 10px 3px 0;
            transform: scaleX(-1) translateX(0);
            filter: FlipH;
            -ms-filter: 'FlipH';
            transition: transform 0.3s ease-in-out 0.1s;
        }

        label {
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            text-align: right;
            color: $black;
            margin-top: 1px;
            text-transform: uppercase;
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;

            span {
                margin: 0 3px;
                font-size: 13px;
                margin-top: 1px;
            }
        }

        &:hover {
            .triangle {
                transform: scaleX(-1) translateX(3px);
            }

            label {
                transform: translateX(3px);
            }
        }
    }

    .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        height: 30px;

        span {
            font-weight: 600;
            font-size: 20px;
            margin: 0;
            text-transform: uppercase;

            @media screen and (max-width: 992px) {
                font-size: 14px;
            }
        }

        .ref {
            font-weight: 300;
            font-size: 14px;
            margin-left: 10px;
        }
    }

    .slide-title {
        &-enter-active,
        &-leave-active {
            transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
            transform: translateX(5px);
        }
    }

    .slide-back {
        &-enter-active {
            transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out 0.3s,
                transform 0.3s ease-in-out 0.3s;
        }

        &-leave-active {
            transition: width 0.3s ease-in-out 0.2s, opacity 0.2s ease-in-out,
                transform 0.3s ease-in-out;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
            width: 0;
            transform: translateX(-5px);
        }
    }
}
</style>
