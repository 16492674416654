<template>
    <el-timeline-item
        :timestamp="timestamp"
        :color="color"
        :icon="icon"
        :size="size"
        :placement="placement"
    >
        <div class="status">
            <div class="content">{{ content }}</div>
            <div class="info">
                <avatar v-if="created_by" :user="created_by" size="normal" />
                <div class="time">
                    {{ event.created_at.toDate() | timeAgo }}
                </div>
            </div>
        </div>
    </el-timeline-item>
</template>

<script>
import moment from 'moment';
import Avatar from '../generic/Avatar';

export default {
    name: 'timeline-item',
    components: {Avatar},
    props: {
        event: {
            type: Object,
            required: true,
        },
        event_types: {
            type: Array,
            required: true,
        },
    },
    computed: {
        timestamp() {
            return moment(this.event.created_at.toDate()).fromNow();
        },
        type() {
            return this.event_types.find(
                (type) => `system/events/types/${type.id}` === this.event.type
            );
        },
        color() {
            const status = this.type.statuses.find(
                (status) => status.value === this.event.status
            );

            if (!status) return '#cecece';

            return status.color;
        },
        created_by() {
            if (!this.event.created_by) return null;
            return this.$store.getters.userWithId(this.event.created_by);
        },
        title() {
            return null;
        },
        content() {
            return this.event.content;
        },
        icon() {
            return null;
        },
        size() {
            return 'large';
        },
        placement() {
            return 'none';
        },
    },
};
</script>

<style lang="scss" scoped>
.status {
    .content {
        margin-bottom: 10px;
    }

    .info {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .time {
            margin-left: 10px;
            font-size: 12px;
            color: rgba($black, 0.6);
        }
    }
}

.milestone {
    label {
        margin: 0;
    }

    p {
        margin: 0;
        margin-top: 10px;
        font-size: 12px;
        color: rgba($black, 0.6);
    }
}
</style>
