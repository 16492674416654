var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    _vm._l(_vm.projects_with_payment_plans, function (project) {
      return _c(
        "div",
        { key: project.ref, staticClass: "project" },
        [
          _c("span", [
            _c("strong", [_vm._v(_vm._s(project.ref))]),
            _vm._v(" - " + _vm._s(project.name) + " "),
          ]),
          _vm._l(project.payment_plans, function (plan) {
            return _c(
              "div",
              {
                key: plan.id,
                staticClass: "payment-plan",
                class: { invoiced: plan.invoiced },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "group-label",
                    on: {
                      click: function ($event) {
                        _vm.handleClickEstimate(
                          _vm.$store.getters.estimateWithId(
                            _vm.$options.filters.fireRef2id(plan.estimate)
                          )
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm._f("fireRef2id")(plan.estimate)) + " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "payment-plan-invoices" },
                  _vm._l(plan.invoices, function (invoice) {
                    return _c("payment-plan-invoice-block", {
                      key: invoice.id,
                      attrs: {
                        invoice: invoice,
                        project_ref: project.ref,
                        estimate: invoice.estimate,
                        invoices_count: plan.quantity,
                      },
                      on: { click: _vm.handleClickPaymentPlanInvoice },
                    })
                  }),
                  1
                ),
              ]
            )
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }