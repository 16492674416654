import moment from 'moment';
import {momentWithTz} from '@/utils';
import {RECURRING_UNITS} from '@/enums';

const componentRecurringMixin = {
    data() {
        return {
            recurring_blocks: [],
        };
    },
    computed: {
        /**
         * NOTE: DUPLICATE FROM component.session.mixin - REMOVE
         * All the active sessions for the given stage
         * @return {sessions[]}
         */
        active_sessions() {
            if (!this.component) return [];

            // If user is defined only grab that specific session
            if (this.user) {
                const session = this.$store.getters.activeSessionForUserWithId(
                    this.user.id
                );

                // Make sure this active session is running on the selected component
                if (
                    session &&
                    session.component === `components/${this.component.id}`
                ) {
                    return [session];
                }

                // This user doesn't have any active session
                return [];
            }

            // Grab every active session for the component
            return this.$store.getters.activeSessionsForComponentWithId(
                this.component.id
            );
        },
        /**
         * NOTE: DUPLICATE FROM component.session.mixin - REMOVE
         * How much time has been spent for the given component
         * -- Only for active sessions
         * @return {number} time in milliseconds
         */
        tspent__active_sessions() {
            if (this.active_sessions.length) {
                return (
                    this.active_sessions
                        .map((session) => {
                            if (!session.start) return 0; // TODO firebase is delaying setting the serverTimestamp value??
                            return this.$options.filters.secondsDiff(
                                session.start.seconds,
                                this.$store.state.current_time / 1000
                            );
                        })
                        .reduce((a, b) => a + b, 0) * 1000
                );
            }
            return 0;
        },

        /**
         * The duration of the recurring blocks
         * @return moment.Duration
         */
        recurring_period_duration() {
            return moment.duration(
                this.component.recurring_config.period_size,
                this.$options.filters.pluralize(
                    this.component.recurring_config.period_unit
                )
            );
        },

        // Header values
        // --------------------------

        time_allocated_calculations() {
            return [
                {
                    value: this.selected_block.time_allocated,
                    offset: false,
                    label: 'allocated',
                },
                {
                    value: -this.time_debt,
                    offset: true,
                    label: 'overtime',
                },
                {
                    value: this.selected_block.rollover_expiring ?? 0,
                    color: '#f5a623',
                    label: 'expiring',
                },
                {
                    value:
                        this.time_rollover -
                        (this.selected_block.rollover_expiring ?? 0),
                    offset: true,
                    label: 'rollover',
                },
                {
                    value: this.time_adjustment,
                    color: '#1989fa',
                    label: 'adjustment',
                },
            ];
        },

        // User tracking
        // --------------------------

        // time tracked by date for each user within this block period
        tracked_times() {
            if (!this.component?.time_reportable) return [];
            return Object.entries(this.component.time_reportable)
                .filter(([key, value]) => {
                    const date = momentWithTz(key);
                    if (date.startOf('day').toDate() < this.selected_start)
                        return false;
                    if (date.isAfter(this.selected_end)) return false;

                    return Object.values(value).reduce((a, c) => a + c, 0) > 0;
                })
                .sort(([a], [b]) => {
                    if (a > b) return 1;
                    if (b > a) return -1;
                    return 0;
                });
        },

        // Flags
        // --------------------------

        // If the selected recurring block is the current
        is_current_block() {
            return this.selected_block === this.current_block;
        },
        // If the selected recurring block is in the future
        is_future() {
            if (!this.selected_block) return false;
            return this.selected_block.start_date.toDate() > new Date();
        },
        // If theselected recurring block is in the past
        is_past() {
            if (!this.selected_block) return false;
            return this.selected_block.end_date.toDate() < new Date();
        },

        selected_start() {
            const start = this.selected_block?.start_date ?? null;
            if (start) return momentWithTz(start.toDate());
            return null;
        },
        selected_end() {
            const end = this.selected_block?.end_date ?? null;
            if (end) return momentWithTz(end.toDate());
            return null;
        },

        // Time
        // --------------------------

        // snapshot of rollover hours from previous blocks
        time_rollover() {
            if (!this.selected_block) return 0;
            return this.selected_block.rollover > 0
                ? this.selected_block.rollover
                : 0;
        },

        /**
         * The time available in milliseconds for the selected block
         * @return number
         */
        time_available() {
            return (
                this.active_time_available +
                this.active_time_expiring +
                this.active_time_rollover
            );
        },

        /**
         * The time allocated for the selected block
         * @return number
         */
        time_allocated() {
            return (
                this.selected_block.time_allocated +
                this.time_rollover -
                this.time_debt +
                this.time_adjustment
            );
        },

        /**
         * The snapshot of overtime from previous block
         * @return number
         */
        time_debt() {
            if (!this.selected_block) return 0;
            return this.selected_block.rollover < 0
                ? -this.selected_block.rollover
                : 0;
        },

        /**
         * The total of time adjustment entries for this block
         * @return number
         */
        time_adjustment() {
            if (!this.selected_block?.time_adjustment) return 0;
            return this.selected_block.time_adjustment.reduce((sum, entry) => {
                return sum + entry.time;
            }, 0);
        },

        // amount of time tracked, including active trackers if current block
        active_time_reportable() {
            const total_tracked = this.tracked_times.reduce((sum, day) => {
                const sessions = Object.entries(day[1]);
                return sessions.reduce((daysum, session) => {
                    return daysum + session[1];
                }, sum);
            }, 0);
            return (
                total_tracked +
                (this.is_current_block ? this.tspent__active_sessions : 0)
            );
        },

        // amount of time left from expiring hours
        active_time_expiring() {
            if (!this.selected_block) return 0;
            // calculate unused expiring time for past block
            // (minus adjustment, if negative)
            return Math.max(
                (this.selected_block.rollover_expiring ?? 0) -
                    this.active_time_reportable +
                    Math.min(this.time_adjustment, 0),
                0
            );
        },
        // amount of time left from rollover
        active_time_rollover() {
            // calculate unused rollover for block
            if (this.active_time_expiring > 0) {
                // is there is expiring remaining, display full rollover
                return (
                    (this.selected_block.rollover ?? 0) -
                    (this.selected_block.rollover_expiring ?? 0)
                );
            } else {
                // otherwise display rollover minus time spent
                // (minus adjustment, if negative)
                return Math.max(
                    (this.selected_block.rollover ?? 0) -
                        this.active_time_reportable +
                        Math.min(this.time_adjustment, 0),
                    0
                );
            }
        },

        // amount of remaining time left
        active_time_available() {
            // calculate available for block
            if (this.active_time_rollover > 0) {
                // if there is rollover remaining, display full available time
                // (plus adjustment, if positive)
                return (
                    this.selected_block.time_allocated +
                    Math.max(this.time_adjustment, 0)
                );
            } else {
                // otherwise, display available time minus time spent
                // (plus adjustment)
                return (
                    this.selected_block.time_allocated +
                    (this.selected_block.rollover ?? 0) -
                    this.active_time_reportable +
                    this.time_adjustment
                );
            }
        },

        // overtime for this block, including active trackers if current block
        active_time_overtime() {
            return Math.max(
                this.active_time_reportable -
                    (this.selected_block.time_allocated +
                        this.time_adjustment) -
                    (this.selected_block.rollover ?? 0),
                0
            );
        },

        // total value of all segments
        full_time() {
            return (
                this.time_debt +
                this.active_time_reportable +
                this.active_time_expiring +
                this.active_time_rollover +
                this.active_time_available +
                this.rendered_overtime
            );
        },

        recurring_time_reportable_percentage() {
            if (!this.time_allocated) return 0;
            return (1 - this.active_time_available / this.time_allocated) * 100;
        },
    },
    watch: {
        component: {
            handler(val) {
                if (!val) {
                    this.$unbind('recurring_blocks');
                } else {
                    const now = new Date();
                    const componentRef = this.$fire.doc(`components/${val.id}`);
                    const blocksRef = this.$fire
                        .collection('component_recurring_blocks')
                        .where('component', '==', componentRef)
                        .where('start_date', '<', now)
                        .orderBy('start_date', 'asc');

                    this.$bind('recurring_blocks', blocksRef);
                }
            },
            immediate: true,
        },
    },
    methods: {
        /**
         * get a display label for this block in the format 'DD/MMM - DD/MMM'
         * @param block
         * @returns {string|null}
         */
        getDateLabelForRecurringBlock(block, includeYear = false) {
            if (!block || this.collapsed) return null;
            const start = momentWithTz(block.start_date.toDate());
            const end = momentWithTz(block.end_date.toDate());
            const template = includeYear ? 'DD/MM/YY' : 'DD/MMM';
            return `${start.format(template)} - ${end.format(template)}`;
        },

        /**
         * gets the recurring block for a given date, or nearest if out of date bounds,
         * or null if there are no available blocks
         * @param date
         * @returns {{start: moment.Moment, end: moment.Moment}|null}
         */
        getRecurringBlockForDate(date, exactMatch = false) {
            if (this.recurring_blocks.length > 0) {
                const d = momentWithTz(date);
                if (!exactMatch) {
                    if (
                        d.isBefore(this.recurring_blocks[0].start_date.toDate())
                    ) {
                        return this.recurring_blocks[0];
                    }
                    if (
                        d.isAfter(
                            this.recurring_blocks[
                                this.recurring_blocks.length - 1
                            ].end_date.toDate()
                        )
                    ) {
                        return this.recurring_blocks[
                            this.recurring_blocks.length - 1
                        ];
                    }
                }
                const b = this.recurring_blocks.find((block) => {
                    if (d.isBefore(block.start_date.toDate())) return false;
                    const diff = d.diff(block.start_date.toDate(), 'day');
                    return (
                        diff >= 0 &&
                        diff <= this.recurring_period_duration.asDays()
                    );
                });
                return b;
            }
            return null;
        },
    },
};

export default componentRecurringMixin;
