<template>
    <el-timeline-item
        :timestamp="timestamp"
        :color="color"
        :icon="icon"
        :size="size"
        :placement="placement"
    >
        <calendar-item-card :event="event" />
    </el-timeline-item>
</template>
<script>
import moment from 'moment';
import CalendarItemCard from './CalendarItemCard';
export default {
    components: {CalendarItemCard},
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    computed: {
        icon() {
            return null;
        },

        placement() {
            return 'top';
        },

        color() {
            return '#cecece';
        },

        timestamp() {
            return moment(this.event.start.toDate()).fromNow();
        },

        size() {
            return 'large';
        },
    },
};
</script>
<style scoped lang="scss">
.attendees {
    display: flex;
    flex-direction: row;

    margin-top: 10px;
}
.time {
    margin: 0;
    margin-top: 10px;
    font-size: 12px;
    color: rgba($black, 0.6);
}
</style>
