/**
 * Debounce with a promise.
 * Binds all callers as listeners to the result of an internal promise. Allows for a 'then' chain after the debounce fires.
 * This also allows a promise function to be used as the passed in function,
 * so listeners will wait for that function to complete, as well as the debounce to occur.
 * Note: assumes 'this' does not change
 * @param func : function(any=) - the function to debounce
 * @param delay - the delay after last invocation to fire func
 * @returns {function(any=) : Promise} a function which when called returns the debounced promise
 */
function debouncePromise(func, delay) {
    let timeoutId;
    const listeners = [];
    function debounced(...args) {
        const thisRef = this;
        return new Promise((res, rej) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                // consume listeners for actual invocation
                const listenersToInvoke = [...listeners];
                listeners.length = 0;
                Promise.resolve(func.apply(thisRef, args)).then(
                    (success) => {
                        listenersToInvoke.forEach(({resolve}) =>
                            resolve(success)
                        );
                    },
                    (failure) => {
                        listenersToInvoke.forEach(({reject}) =>
                            reject(failure)
                        );
                    }
                );
            }, delay);
            listeners.push({resolve: res, reject: rej});
        });
    }

    return debounced;
}

module.exports = debouncePromise;
