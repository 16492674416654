<template>
    <div class="container">
        <div class="center-container medium_wrapper">
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </div>

        <footer>
            <span class="signature">
                Made with <heart :width="8" /> by CodeFish Studio
            </span>
        </footer>
    </div>
</template>

<script>
import Heart from '@/components/generic/Heart';

export default {
    name: 'base-container',
    components: {
        Heart,
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: 160px;
    padding-bottom: 0;
    box-sizing: border-box;

    @media screen and (max-width: 992px) {
        padding-top: 4px;
        padding-left: 4px;
        padding-right: 4px;
    }

    ::v-deep .center-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
        border: solid 1px #e6e6e6;
        box-sizing: border-box;
        transition: margin-bottom 0.4s ease-in-out;
        flex: 1;

        & .content-block:last-of-type {
            margin-bottom: 0;
        }
    }

    footer {
        height: calc(79px + 50px);
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .signature {
            height: 50px;
            line-height: 52px;
            padding: 0;

            pointer-events: none;
            user-select: none;
            font-size: 11px;
            font-weight: 400;
            color: rgba($black, 0.3);

            svg {
                margin: 0 3px;
            }
        }

        hr {
            background: $border-grey-light;
            border: none;
            height: 1px;
            width: 100%;
            margin: 0;
            margin-bottom: 10px;
        }

        span {
            pointer-events: none;
            user-select: none;
            font-size: 9px;
            font-weight: 400;
            padding-top: 10px;
            color: rgba($black, 0.3);
        }
    }
}
</style>
