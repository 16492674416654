<template>
    <router-view />
</template>

<script>
import {firebaseApp} from '@/utils/firebase';

export default {
    name: 'app',
    created() {
        // Handle changes to user auth status
        firebaseApp.auth().onAuthStateChanged((user) => {
            if (user) {
                // Refresh the user auth data
                this.$store.dispatch('Refresh', true);
            } else {
                this.$store.dispatch('ResetVuex');
            }
        });
    },
};
</script>
