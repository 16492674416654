<template>
    <content-block title="Releases" no_background>
        <ul v-if="sorted_releases.length" class="list">
            <module-release-block
                v-for="release of sorted_releases"
                :key="release.id"
                class="release"
                :release="release"
            />
        </ul>
        <span v-else class="no-releases">No Releases</span>
    </content-block>
</template>

<script>
import ModuleReleaseBlock from '@/components/modules/ModuleReleaseBlock';
import ContentBlock from '@/components/blocks/ContentBlock';

export default {
    name: 'module-tab-releases',
    components: {
        ModuleReleaseBlock,
        ContentBlock,
    },
    props: {
        releases: {
            type: Array,
            required: true,
        },
    },
    computed: {
        sorted_releases() {
            return this.releases.slice(0).sort((a, b) => {
                if (a.version > b.version) return -1;
                return 1;
            });
        },
    },
};
</script>

<style scoped>
.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.no-releases {
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding: 60px 0px;
}
</style>
