import request from '@/utils/request';

export function updateClaims() {
    return request({
        url: '/auth/claims',
        method: 'put',
    });
}

export function createUser(name, email, role, password = true) {
    return request({
        url: '/users/create',
        method: 'post',
        data: {
            password,
            name,
            email,
            role,
        },
    });
}

export function changePassword(newPassword) {
    return request({
        url: '/password/change',
        method: 'post',
        data: {
            new_password: newPassword,
        },
    });
}

export function resetPassword(userId) {
    return request({
        url: '/password/reset',
        method: 'post',
        data: {
            id: userId,
        },
    });
}
