import request from '@/utils/request';

// {{host}}/contacts
export function getContacts(type = null) {
    return request({
        url: '/contacts',
        method: 'get',
        params: {
            type,
        },
    });
}

// {{host}}/contact/:contact_id
export function getContact(contact_id) {
    return request({
        url: `/contact/${contact_id}`,
        method: 'get',
    });
}

// {{host}}/contact
export function addContact(contact) {
    return request({
        url: '/contact',
        method: 'post',
        data: contact,
    });
}
