<template>
    <div key="component" class="selection">
        <h2 class="title">Choose component:</h2>
        <hr />
        <div class="back" @click="$emit('onBack')">
            <svgicon name="triangle" class="triangle"></svgicon>
            <label>BACK <span>/</span></label>
            <span>
                {{ selected_project.name }} / Stage #{{ selected_stage.number }}
            </span>
        </div>
        <hr />
        <ul v-if="components">
            <component-selector-block
                v-for="component in components"
                :key="component.id"
                :component="component"
                @onSelection="$emit('onSelection', component)"
            />
        </ul>
        <el-alert
            v-else
            style="margin-top: 10px;"
            description="No components available"
            :closable="false"
        />
    </div>
</template>

<script>
import ComponentSelectorBlock from '@/components/blocks/ComponentSelectorBlock';

import {COMPONENT_STATUS} from '@/enums';

export default {
    name: 'component-selector',
    components: {ComponentSelectorBlock},
    props: {
        selected_project: {
            type: Object,
            required: true,
        },
        selected_stage: {
            type: Object,
            required: true,
        },
    },
    computed: {
        components() {
            const components = this.$store.getters
                .componentsForStageWithId(this.selected_stage.id)
                .filter(
                    (c) =>
                        c.status === COMPONENT_STATUS.ACTIVE ||
                        c.status === COMPONENT_STATUS.STANDBY
                );

            if (components.length === 0) return null;
            return components;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.selection {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 5px;

    h2.title {
        font-size: 15px;
    }

    ul {
        padding: 10px 2px 20px 2px;
        margin: 0;
        overflow: scroll;
    }

    .back {
        cursor: pointer;
        margin: 10px 2px;
        padding: 18px 20px;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0 0 0 1px $border-grey-light;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        &:hover {
            box-shadow: 0 0 0 2px $blue;
        }

        .triangle {
            color: $black;
            width: 10px;
            height: 10px;
            margin: 4px 10px 3px 0;
            transform: scaleX(-1) translateX(0);
            filter: FlipH;
            -ms-filter: 'FlipH';
            transition: transform 0.3s ease-in-out 0.1s;
        }

        label {
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            text-align: right;
            color: $black;
            text-transform: uppercase;
            margin-right: 4px;
            margin-top: 1px;
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;

            span {
                margin: 0 3px;
                font-size: 13px;
                margin-top: 1px;
            }
        }

        span {
            transition: transform 0.3s ease-in-out;
        }

        &:hover {
            .triangle {
                transform: scaleX(-1) translateX(3px);
            }

            label,
            span {
                transform: translateX(3px);
            }
        }
    }

    h2 + hr {
        margin-bottom: 0;
    }

    hr {
        width: 100%;
        margin: 0;
        border: none;
        height: 1px;
        background-color: $border-grey-light;
    }
}
</style>
