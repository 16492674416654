<template>
    <div>
        <template v-for="(integration, index) in filtered_integrations">
            <component
                :is="integration.component"
                :key="index"
                :show_dialog="show_dialog"
            />
        </template>
        <template v-if="!filtered_integrations.length">
            <el-alert
                class="placeholder"
                description="Looks like there are no integrations enabled."
                :closable="false"
            />
        </template>
    </div>
</template>

<script>
import userMixin from '@/mixins/user.mixin';

import XeroIntegration from '@/components/settings/integrations/XeroIntegration';
import AtlassianIntegration from '@/components/settings/integrations/AtlassianIntegration';
import CalendarIntegration from '@/components/settings/integrations/CalendarIntegration';
import BitbucketIntegration from '@/components/settings/integrations/BitbucketIntegration';
import HubspotIntegration from '@/components/settings/integrations/HubspotIntegration.vue';
import SonosIntegration from '@/components/settings/integrations/SonosIntegration';

import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';

export default {
    name: 'integrations-settings',
    components: {
        CalendarIntegration,
        AtlassianIntegration,
        XeroIntegration,
        BitbucketIntegration,
        SonosIntegration,
    },
    mixins: [userMixin],
    props: {
        show_dialog: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            SUPPORTED_FEATURES: SUPPORTED_FEATURES,
            integrations: [
                {
                    component: AtlassianIntegration,
                    super_admin_only: false,
                    features: [SUPPORTED_FEATURES.ATLASSIAN],
                },
                {
                    component: BitbucketIntegration,
                    super_admin_only: true,
                    features: [SUPPORTED_FEATURES.BITBUCKET],
                },
                {
                    component: XeroIntegration,
                    super_admin_only: true,
                    features: [SUPPORTED_FEATURES.XERO],
                },
                {
                    component: HubspotIntegration,
                    super_admin_only: true,
                    features: [SUPPORTED_FEATURES.HUBSPOT],
                },
                {
                    component: SonosIntegration,
                    super_admin_only: false,
                    features: [],
                },
                {
                    component: CalendarIntegration,
                    super_admin_only: true,
                    features: [SUPPORTED_FEATURES.GCAL],
                },
            ],
        };
    },
    computed: {
        filtered_integrations() {
            return this.integrations.filter((integration) => {
                if (integration.super_admin_only && !this.user_is_super_admin) {
                    return false;
                } else if (
                    !this.$store.getters.are_all_features_enabled(
                        integration.features
                    )
                ) {
                    return false;
                }

                return true;
            });
        },
    },
};
</script>
