<template>
    <el-dropdown
        v-if="user"
        trigger="click"
        placement="bottom-end"
        @command="assignRole"
    >
        <div class="user">
            <avatar :user="user" hide_tooltip />
            <div class="info">
                <div class="name">{{ user.name }}</div>
                <div class="role">
                    {{ assigned_role ? assigned_role.label : '+ Add role' }}
                </div>
            </div>
            <div v-if="!assigned_role" v-only-admin class="down-icon">
                <svg class="icon" focusable="false" viewBox="0 0 24 24">
                    <path
                        d="M3.5,8.9c0-0.4,0.1-0.7,0.4-1C4.5,7.3,5.4,7.2,6,7.8l5.8,5.2l6.1-5.2C18.5,7.3,19.5,7.3,20,8c0.6,0.6,0.5,1.5-0.1,2.1 l-7.1,6.1c-0.6,0.5-1.4,0.5-2,0L4,10.1C3.6,9.8,3.5,9.4,3.5,8.9z"
                    />
                </svg>
            </div>
        </div>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="option in roles"
                :key="option.id"
                :command="option.id"
            >
                {{ option.label }}
            </el-dropdown-item>
            <el-dropdown-item divided class="destructive">
                <span command="x">Remove</span>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import Avatar from '../generic/Avatar';
import {mixin as clickaway} from 'vue-clickaway';
import userMixin from '@/mixins/user.mixin';

import store from '@/store';

export default {
    name: 'project-team-user',
    components: {Avatar},
    mixins: [clickaway, userMixin],
    props: {
        team_user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            assigned_role: null,
        };
    },
    computed: {
        user() {
            return this.$store.getters.userWithId(this.team_user.user);
        },
    },
    mounted() {
        this.assigned_role = this.team_user.role
            ? this.$store.getters.userRoleWithId(this.team_user.role)
            : null;
    },
    methods: {
        assignRole(value) {
            //Only admins can change roles
            if (!this.user_is_admin) return;

            if (value === undefined) {
                this.removeUser();
            } else {
                this.assigned_role = this.$store.getters.userRoleWithId(value);
                this.$store.dispatch('assignRoleToUserForProject', {
                    user_id: this.user.id,
                    project_id: this.$route.params.project_id,
                    role_id: value,
                });
            }
        },
        removeUser() {
            this.$store.dispatch('removeUserFromProjectTeam', {
                project_id: this.$route.params.project_id,
                user_id: this.user.id,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.team-user {
    width: 33.3%;
    min-width: 220px;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;

    @media screen and (max-width: 992px) {
        width: 100%;
    }

    .user {
        background: #fff;
        box-shadow: none;
        transition-duration: 200ms;
        transition-property: background, border-color, box-shadow;
        outline: none;
        border-radius: 6px;
        display: flex;
        flex: 1;
        padding: 12px;
        flex-direction: row;
        align-items: center;
        position: relative;

        &:hover {
            background-color: $grey;

            .delete {
                display: block;
                position: absolute;
            }
        }

        .delete {
            display: none;
            right: 0;
            top: 0;
            margin-right: -12px;
            margin-top: -12px;
            z-index: 101;
        }

        .info {
            padding-left: 12px;
            flex: 1;

            .name {
                font-size: 13px;
                font-weight: 600;
                line-height: normal;
            }

            .el-select {
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
            }

            .role {
                font-size: 11px;
                color: rgba($black, 0.6);
                display: flex;
                align-items: flex-start;
                margin-top: 4px;
            }
        }

        .down-icon {
            align-items: center;
            fill: #6f7782;
            flex: 0;
            margin-left: 4px;

            .icon {
                flex: 0 0 auto;
                height: 12px;
                width: 12px;
            }
        }
    }
}
</style>
