<template>
    <div class="outer">
        <header>
            <div></div>
            <el-button size="mini" @click="addUser">
                New
            </el-button>
        </header>

        <div class="inner">
            <ul>
                <li
                    v-for="user in users"
                    :key="user.id"
                    @click="navigateToUser(user)"
                >
                    <span>{{ user.name }}</span>
                    <svgicon name="triangle" class="triangle" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'management-users',
    computed: {
        users() {
            return this.$store.getters.users;
        },
    },
    methods: {
        addUser() {
            this.$router
                .push({
                    name: 'management_users_new',
                })
                .catch((err) => {});
        },
        navigateToUser(user) {
            this.$router
                .push({
                    name: 'management_users_detail',
                    params: {
                        user_id: user.id,
                    },
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
        height: 50px;
        border-bottom: 1px solid $border-grey-light;
        padding: 10px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .el-input {
            width: 200px;
        }
    }

    .inner {
        overflow: scroll;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;

                &:hover {
                    svg {
                        display: block;
                    }
                }

                span {
                    flex: 1;
                    user-select: none;
                }

                svg {
                    width: 6px;
                    display: none;
                    opacity: 0.1;
                }
            }
        }
    }
}
</style>
