<template>
    <div class="command-layout">
        <header>
            <div v-if="show_back" class="back-button" @click="$emit('goBack')">
                <svgicon name="triangle" class="arrow" />
            </div>
            <div class="content">
                <slot name="header" />
            </div>
        </header>
        <div class="content">
            <slot name="body" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'command-layout',
    props: {
        show_back: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.command-layout {
    header {
        position: sticky;
        top: 0;
        padding: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid $border-grey-light;
        background-color: $black-dark;
        display: flex;
        flex-direction: row;
        height: 100px;
        align-items: center;
        gap: 10px;
        color: white;
        z-index: 1000;

        .back-button {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: rgba(255, 255, 255, 0.05);
            }

            .arrow {
                transform: rotate(180deg);
                width: 8px;

                ::v-deep path {
                    fill: $white;
                }
            }
        }

        .content {
            flex: 1;
        }
    }
}
</style>
