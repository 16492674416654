import {firebaseApp} from '@/utils/firebase';
import {firestoreAction} from 'vuexfire';

const user = {
    state: {
        object: null,
    },
    mutations: {
        SET_USER: (state, user) => {
            state.object = user;
        },
    },
    actions: {
        Refresh({commit}, forceTokenRefresh = false) {
            return new Promise((resolve, reject) => {
                if (forceTokenRefresh)
                    console.log('Refreshing token and fetching user claims...');
                else console.log('Fetching user claims...');

                firebaseApp
                    .auth()
                    .currentUser.getIdTokenResult(forceTokenRefresh)
                    .then((result) => {
                        commit('SET_USER', result.claims);
                        resolve();
                    })
                    .catch((e) => {
                        console.error('Error in token refresh', e);
                        reject(e);
                    });
            });
        },
        ResetVuex({commit, dispatch, state}) {
            console.log('********************************************');

            console.log('Resetting store...');
            commit('SET_USER', null);
            dispatch('UnbindFirestore');
            localStorage.removeItem('vuex');

            console.log('********************************************');
        },
        UnbindFirestore: firestoreAction(({rootState, unbindFirestoreRef}) => {
            Object.keys(rootState).forEach((boundKey) => {
                // Unbind all bound keys
                unbindFirestoreRef(boundKey);
            });
        }),
    },
};

export default user;
