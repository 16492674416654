import {COMMAND_DEPENDENCIES} from '@/enums';
import store from '@/store';
import {firebaseFirestore} from '@/utils/firebase';
import {fireRef2id} from '@/filters';

/**
 * Provides a list of estimates
 * @type {{provides: string, getResults: CommandProvider.getResults, label: string, filteredBy: null}}
 */
export const EstimateProvider = {
    label: 'Estimates',
    provides: COMMAND_DEPENDENCIES.ESTIMATE,
    filteredBy: [COMMAND_DEPENDENCIES.PROJECT],
    getResults: async (data, filter_string) => {
        if (data[COMMAND_DEPENDENCIES.ESTIMATE]) return [];

        // only return results if filter or project is set
        if (!filter_string && !data[COMMAND_DEPENDENCIES.PROJECT]) return null;

        let estimates = store.getters.estimates;
        if (data[COMMAND_DEPENDENCIES.PROJECT]) {
            estimates = estimates.filter(
                (estimate) =>
                    fireRef2id(estimate.project) ===
                    data[COMMAND_DEPENDENCIES.PROJECT].value.id
            );
        }
        if (filter_string) {
            const query = filter_string.toLowerCase();
            estimates = estimates.filter((estimate) => {
                const title_match = estimate.title
                    ? estimate.title?.toLowerCase().includes(query)
                    : false;
                const ref_match = estimate.ref
                    ? estimate.ref?.toLowerCase().includes(query)
                    : false;
                return title_match || ref_match;
            });
        }
        return estimates
            .map((estimate) => {
                const project = store.getters.projectWithId(estimate.project);
                if (project) {
                    return {
                        id: estimate.id,
                        label: estimate.title,
                        sub_label: `${estimate.ref} - ${project.name}`,
                        value: estimate,
                    };
                }
            })
            .filter((x) => x); // filter out unmapped estimates
    },
    cleanup() {
        // if this provider holds any resources during an operation, dispose of them here
    },
};
