<template>
    <el-form
        ref="form"
        auto-complete="off"
        :model="form"
        :rules="rules"
        label-width="120px"
        class="form-wrapper"
    >
        <!-- NAME -->
        <!--*******************************************-->

        <el-form-item label="Name" prop="name">
            <el-input
                v-model="form.name"
                name="name"
                type="text"
                auto-complete="off"
            />
        </el-form-item>

        <!-- REFERENCE -->
        <!--*******************************************-->

        <el-form-item label="Reference" prop="ref">
            <el-input
                v-model="form.ref"
                name="ref"
                type="text"
                auto-complete="off"
                placeholder="XXX"
            />
        </el-form-item>

        <hr />

        <!-- USERS -->
        <!--*******************************************-->

        <el-form-item label="Users">
            <div class="team">
                <user-selector
                    :users="team_users"
                    :selected="selected_team"
                    @change="teamChange"
                    @add_user="handleAddUser"
                />
            </div>
        </el-form-item>

        <hr />

        <!-- RECURRING -->
        <!--*******************************************-->

        <el-form-item label="Recurring" prop="recurring">
            <el-switch v-model="form.recurring" />
        </el-form-item>

        <!-- START DATE -->
        <!--*******************************************-->

        <el-form-item label="Start date" prop="start_date">
            <el-date-picker
                v-model="form.start_date"
                style="width: 100%;"
                type="date"
                format="dd/MM/yyyy"
                :picker-options="picker_options"
            />
        </el-form-item>

        <!-- DEADLINE -->
        <!--*******************************************-->

        <el-form-item label="End date" prop="end_date">
            <el-date-picker
                v-model="form.end_date"
                style="width: 100%;"
                type="date"
                format="dd/MM/yyyy"
                :picker-options="picker_options_end"
            />
        </el-form-item>

        <!-- TIME ESTIMATE -->
        <!--*******************************************-->

        <el-form-item
            :label="form.recurring ? 'Time per block' : 'Time estimate'"
            prop="hours_estimate"
            class="form-number"
        >
            <el-input
                v-model="form.hours_estimate"
                placeholder="hours"
                clearable
            >
                <span slot="append">
                    {{ 'hour' | pluralize(form.hours_estimate) }}
                </span>
            </el-input>
        </el-form-item>

        <!-- RECURRING FIELDS -->
        <!--*******************************************-->

        <template v-if="form.recurring">
            <!-- PERIOD -->
            <!--*******************************************-->
            <el-form-item
                label="Period"
                prop="recurring_config.period_size"
                class="form-number recurring"
            >
                <el-input
                    v-model="form.recurring_config.period_size"
                    :placeholder="form.recurring_config.period_unit | pluralize"
                >
                    <el-select
                        slot="append"
                        v-model="form.recurring_config.period_unit"
                    >
                        <el-option
                            value="day"
                            :label="
                                'day'
                                    | pluralize(
                                        form.recurring_config.period_size
                                    )
                            "
                        />
                        <el-option
                            value="month"
                            :label="
                                'month'
                                    | pluralize(
                                        form.recurring_config.period_size
                                    )
                            "
                        />
                    </el-select>
                </el-input>
                <span v-if="is_month_period" class="field-footnote">
                    {{ period_message }}
                </span>
            </el-form-item>

            <!-- EXPIRES IN -->
            <!--*******************************************-->
            <el-form-item
                label="Expires after"
                prop="recurring_config.expires_in"
                class="form-number"
            >
                <el-input
                    v-model="form.recurring_config.expires_in"
                    placeholder="count"
                    clearable
                >
                    <span slot="append">
                        {{
                            'period'
                                | pluralize(form.recurring_config.expires_in)
                        }}
                    </span>
                </el-input>
                <span
                    v-if="
                        !form.recurring_config.expires_in ||
                        form.recurring_config.expires_in === '0'
                    "
                    class="field-footnote"
                >
                    Time allocated each block will not expire.
                </span>
                <span v-else class="field-footnote">
                    Time allocated each block will expire after
                    <b>
                        {{
                            form.recurring_config.expires_in *
                            form.recurring_config.period_size
                        }}
                        {{
                            form.recurring_config.period_unit
                                | pluralize(
                                    form.recurring_config.expires_in *
                                        form.recurring_config.period_size
                                )
                        }}</b
                    >.
                </span>
            </el-form-item>
        </template>

        <hr />

        <!-- ROUNDING -->
        <!--*******************************************-->

        <el-form-item label="Round to" prop="round" class="form-number">
            <el-input v-model="form.round" placeholder="minutes" clearable>
                <span slot="append">
                    {{ 'minute' | pluralize(form.round) }}
                </span>
            </el-input>
        </el-form-item>

        <!-- MINIMUM -->
        <!--*******************************************-->

        <el-form-item
            v-if="form.round"
            label="Round after"
            prop="min"
            class="form-number"
        >
            <el-input
                v-model="form.min"
                placeholder="minutes"
                clearable
                :disabled="!form.round"
            >
                <span slot="append">
                    {{ 'minute' | pluralize(form.min) }}
                </span>
            </el-input>
            <span v-if="form.round && form.min" class="field-footnote">
                Once a user, for a given day, has tracked more than
                <b>{{ form.min }} {{ 'minute' | pluralize(form.min) }}</b
                >, the reportable time will be rounded up to the nearest
                <b>{{ form.round }} {{ 'minute' | pluralize(form.round) }}</b
                >.
            </span>
        </el-form-item>

        <el-button :disabled="creating" @click="handleSubmit">
            Create
        </el-button>
    </el-form>
</template>

<script>
import moment from 'moment';

import UserSelector from '@/components/generic/UserSelector';

import {momentWithTz} from '@/utils';

import componentMixin from '@/mixins/component.mixin';
import {COMPONENT_STATUS} from '@/enums';

export default {
    name: 'component-new',
    components: {UserSelector},
    mixins: [componentMixin],
    data() {
        return {
            creating: false,
            form: {
                name: null,
                ref: null,
                start_date: null,
                end_date: null,
                recurring: false,
                time_spent: {},
                time_reportable: {},
                recurring_config: {
                    period_unit: 'month',
                    period_size: 1,
                    expires_in: 3,
                },
            },
            selected_team: [],
        };
    },

    computed: {
        rules() {
            return {
                name: [{required: true, trigger: 'blur'}],
                start_date: [
                    {
                        required: this.form.recurring,
                        trigger: 'blur',
                        message:
                            'Start date is required for recurring components',
                    },
                ],
                end_date: [
                    {
                        required: this.form.recurring,
                        trigger: 'blur',
                        message:
                            'End date is required for recurring components',
                    },
                ],
                hours_estimate: [
                    {
                        type: 'number',
                        validator: this.validateTimeEstimate,
                    },
                ],
                round: [
                    {
                        type: 'number',
                        validator: this.validateOptionalNumber,
                    },
                ],
                min: [
                    {
                        type: 'number',
                        validator: this.validateOptionalNumber,
                    },
                ],
                'recurring_config.period_size': [
                    {
                        type: 'number',
                        required: true,
                        validator: this.validatePeriod,
                    },
                ],
                'recurring_config.expires_in': [
                    {
                        type: 'number',
                        validator: this.validateExpiry,
                    },
                ],
            };
        },
        stage() {
            return this.$store.getters.stageWithId(this.$route.params.stage_id);
        },
        users() {
            return this.$store.getters.users;
        },
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        team_users() {
            return (this.project.team || []).map((user) => user.user);
        },
        picker_options() {
            return {
                shortcuts: [
                    {
                        text: 'Two weeks',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(moment().add({days: 14}));
                            picker.$emit('pick', date);
                        },
                    },
                    {
                        text: 'One month',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(moment().add({months: 1}));
                            picker.$emit('pick', date);
                        },
                    },
                    {
                        text: 'Two month',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(moment().add({months: 2}));
                            picker.$emit('pick', date);
                        },
                    },
                ],
            };
        },
        picker_options_end() {
            const options = {...this.picker_options};
            options.disabledDate = (d) => {
                if (!this.form.recurring) return false;
                if (!this.form.recurring_config.period_size) {
                    return momentWithTz().isAfter(d, 'day');
                } else {
                    const start = momentWithTz(
                        this.form.start_date ?? undefined
                    );
                    // earliest allowed end date is 1 period after the start date
                    return start
                        .add(
                            this.form.recurring_config.period_size,
                            this.form.recurring_config.period_unit
                        )
                        .subtract(1, 'day')
                        .isAfter(d, 'day');
                }
            };
            return options;
        },
        is_day_period() {
            return this.form.recurring_config.period_unit === 'day';
        },
        is_month_period() {
            return this.form.recurring_config.period_unit === 'month';
        },
        period_message() {
            const startDate = this.form.start_date
                ? momentWithTz(this.form.start_date)
                : null;

            let startDay = 'same day';

            if (this.form.start_date) {
                startDay = startDate.format('Do');
                if (startDate.date() > 28) {
                    startDay += ' (or nearest)';
                }
            }

            return `Blocks will start on the ${startDay} of the month`;
        },
    },

    watch: {
        'form.recurring_config.period_unit'(newVal, oldVal) {
            if (newVal !== oldVal) {
                if (this.is_day_period) {
                    // if not set, default to minimum 7 days
                    if (!this.form.recurring_config.period_size) {
                        this.form.recurring_config.period_size = 7;
                    } else {
                        // convert period_size from months to days
                        this.form.recurring_config.period_size =
                            this.form.recurring_config.period_size * 30;
                    }
                } else {
                    // if not set, default to minimum 1 month
                    if (!this.form.recurring_config.period_size) {
                        this.form.recurring_config.period_size = 1;
                    } else {
                        // convert period_size from days to months
                        this.form.recurring_config.period_size = Math.ceil(
                            this.form.recurring_config.period_size / 30
                        );
                    }
                }
            }
        },
        'form.hours_estimate'(newVal) {
            if (newVal) {
                this.form.time_estimate = this.$options.filters.hours2milli(
                    this.form.hours_estimate
                );
            }
        },
    },

    methods: {
        teamChange(users) {
            this.selected_team = users;
        },
        handleAddUser(userId) {
            this.$store.dispatch('addUserToProjectTeam', {
                project_id: this.$route.params.project_id,
                user_id: userId,
            });

            this.selected_team.push(userId);
        },
        handleSubmit() {
            try {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.creating = true;

                        const newComponent = {...this.form};

                        newComponent.project = this.$fire.doc(
                            `projects/${this.$route.params.project_id}`
                        );
                        newComponent.stage = this.$fire.doc(
                            `stages/${this.$route.params.stage_id}`
                        );
                        // Force the start date to be at the beginning of the day
                        if (newComponent.start_date) {
                            newComponent.start_date = momentWithTz(
                                newComponent.start_date
                            )
                                .startOf('day')
                                .toDate();
                        }
                        if (newComponent.end_date) {
                            // Force the end date to be at the end of the day
                            newComponent.end_date = momentWithTz(
                                newComponent.end_date
                            )
                                .endOf('day')
                                .toDate();
                        }
                        delete newComponent.hours_estimate;

                        // future components will start in standby status by default
                        // they will need to be activated manually
                        if (
                            momentWithTz(newComponent.start_date).isAfter(
                                momentWithTz()
                            )
                        ) {
                            newComponent.status = COMPONENT_STATUS.STANDBY;
                        } else {
                            newComponent.status = COMPONENT_STATUS.ACTIVE;
                        }

                        // clean up any undefined values
                        if (newComponent.time_estimate === undefined)
                            delete newComponent.time_estimate;

                        if (newComponent.round === undefined)
                            delete newComponent.round;
                        // convert to milliseconds
                        else newComponent.round = newComponent.round * 60000;

                        if (newComponent.min === undefined)
                            delete newComponent.min;
                        // convert to milliseconds
                        else newComponent.min = newComponent.min * 60000;

                        if (newComponent.recurring) {
                            // ensure period_size is a number
                            newComponent.recurring_config.period_size = Number(
                                newComponent.recurring_config.period_size
                            );
                            // expires_in should be a number or null, or removed if not set
                            if (
                                newComponent.recurring_config.expires_in ===
                                undefined
                            ) {
                                delete newComponent.recurring_config.expires_in;
                            } else if (
                                newComponent.recurring_config.expires_in !==
                                null
                            ) {
                                newComponent.recurring_config.expires_in = Number(
                                    newComponent.recurring_config.expires_in
                                );
                            }
                        } else {
                            // remove recurring config if not used
                            delete newComponent.recurring_config;
                        }

                        this.$store
                            .dispatch('addComponent', {
                                ...newComponent,
                                ref: newComponent.ref?.toUpperCase() ?? null,
                                users: this.selected_team,
                            })
                            .then((response) => {
                                const component_id = response.id;

                                this.$router.push({
                                    name: 'project_detail_components',
                                    params: {
                                        project_id: this.$route.params
                                            .project_id,
                                        stage_id: this.$route.params.stage_id,
                                        component_id: component_id,
                                    },
                                });
                            });
                    } else {
                        console.warn('Form not valid...');
                        return false;
                    }
                });
            } catch (e) {
                console.error('Error creating new component', e);
            }
        },

        /* FORM VALIDATORS */

        validateOptionalNumber(rule, value, callback) {
            if (value) {
                const numValue = Number(value);
                if (isNaN(numValue)) {
                    callback(new Error('Value must be numeric or blank'));
                    return;
                } else if (numValue < 0) {
                    callback(new Error('Value must not be negative'));
                    return;
                }
            }
            callback();
        },

        validateTimeEstimate(rule, value, callback) {
            if (this.form.recurring && !value) {
                callback(
                    new Error('Time is required for recurring components')
                );
            } else {
                this.validateOptionalNumber(rule, value, callback);
            }
        },

        validatePeriod(rule, value, callback) {
            if (!value) {
                callback(
                    new Error('Period is required for recurring components')
                );
            } else {
                const numValue = Number(value);
                if (isNaN(numValue)) {
                    callback(new Error('Period must be a number'));
                } else if (this.is_month_period && numValue < 1) {
                    callback(new Error('Minimum period is 1 month'));
                } else if (this.is_day_period && numValue < 7) {
                    callback(new Error('Minimum period is 7 days'));
                } else {
                    callback();
                }
            }
        },

        validateExpiry(rule, value, callback) {
            if (value) {
                const numValue = Number(value);
                if (isNaN(numValue)) {
                    callback(new Error('Expiry value must be a number'));
                    return;
                }
            }
            callback();
        },
    },
};
</script>

<style lang="scss" scoped>
.el-form {
    margin: 40px auto;

    .el-form-item {
        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        .el-input,
        .el-select {
            flex: 1;
            height: 100%;
            width: 100%;
        }
    }
    .form-number {
        ::v-deep .el-input-group__append {
            width: 85px;
        }

        .field-footnote {
            font-size: 11px;
            opacity: 0.75;
            line-height: 13px;
            display: block;
            margin-top: 5px;
            max-width: 75%;
        }

        &.is-error .field-footnote {
            display: none;
        }

        &.recurring {
            ::v-deep .el-input-group__append {
                width: 85px;
            }
            .el-select {
                width: 120px;
                padding-left: 4px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .el-form {
        .form-number {
            &.recurring {
                ::v-deep .el-input-group__append {
                    width: 80px;

                    span {
                        white-space: normal;
                    }
                }
                .el-select {
                    width: 120px;
                }
            }

            .field-footnote {
                font-size: 9px;
            }
        }
        ::v-deep .el-form-item__error {
            font-size: 10px;
        }
    }
}

.el-button {
    margin-top: 20px;
    width: 200px;
    float: right;
}

hr {
    margin: 20px 50px;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}
</style>
