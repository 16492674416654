<template>
    <div class="day" :class="{today: is_today}">
        <div class="day-header">
            <span class="day-name">
                {{ date | dateformat('dddd') }}
            </span>
            <span class="day-number">
                {{ date | dateformat('D') }}
            </span>
        </div>

        <div v-if="has_sessions" class="content">
            <span class="quantity">
                {{ sessions.length }}
                {{ 'session' | pluralize(sessions.length) }}
            </span>

            <div class="period">
                <span>
                    {{ (first_session.start.seconds * 1000) | milli2date }}
                </span>
                <svgicon name="triangle" class="arrow" />
                <span>
                    {{ (last_session.stop.seconds * 1000) | milli2date }}
                </span>
            </div>

            <div class="period">
                <!-- Mark red is below 6 hours -->
                <span
                    class="total-duration"
                    :class="{error: total_duration < 21600}"
                >
                    {{ total_duration | seconds2duration }}
                </span>
                <span class="small">h tracked</span>
            </div>

            <ul class="breakdown">
                <li
                    v-for="project in Object.keys(projects_breakdown)"
                    :key="project"
                >
                    <span>{{ project }}:</span>
                    <span>
                        {{ projects_breakdown[project] | seconds2duration }}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import * as dayjs from 'dayjs';

export default {
    name: 'hours-day',
    props: {
        data: {
            type: Object,
            required: true,
        },
        date: {
            type: Object,
            required: true,
        },
        sessions: {
            type: Array,
            required: true,
        },
    },
    computed: {
        is_today() {
            return this.date.isSame(moment(), 'day');
        },
        has_sessions() {
            return this.sessions.length;
        },
        first_session() {
            return this.sessions[0];
        },
        last_session() {
            return this.sessions[this.sessions.length - 1];
        },
        total_duration() {
            return this.sessions
                .map((s) => {
                    if (s.stop) {
                        return s.stop.seconds - s.start.seconds;
                    } else {
                        //Session is active
                        return new Date().getTime() / 1000 - s.start.seconds;
                    }
                })
                .reduce((a, b) => a + b, 0);
        },
        projects_breakdown() {
            let projects = {};

            this.sessions.slice(0).map((s) => {
                const p = this.$store.getters.projectForComponentWithId(
                    s.component
                );
                const p_ref = p ? p.ref : '---';

                let d = 0;

                if (s.stop) {
                    d = s.stop.seconds - s.start.seconds;
                } else {
                    //Session is active
                    d = new Date().getTime() / 1000 - s.start.seconds;
                }

                if (projects[p_ref] !== undefined) {
                    projects[p_ref] += d;
                } else {
                    projects[p_ref] = d;
                }
            });

            return Object.keys(projects)
                .sort()
                .reduce((obj, key) => {
                    obj[key] = projects[key];
                    return obj;
                }, {});
        },
    },
    methods: {
        moment(params) {
            return moment(params);
        },
    },
};
</script>

<style lang="scss" scoped>
.day {
    flex: 1;
    height: 100%;
    padding: 20px 15px 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &.today {
        .day-header {
            color: $blue;
        }
    }

    .day-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $border-grey-light;
        padding-bottom: 8px;

        span.day-name {
            font-size: 10px;
            margin-right: 5px;
        }

        span.day-number {
            font-size: 20px;
            font-weight: bold;
        }
    }

    div.content {
        padding: 4px;

        span.quantity {
            font-size: 12px;
        }

        .period {
            display: flex;
            flex-direction: row;
            margin: 4px 0;

            span {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                font-size: 13px;

                &.total-duration {
                    font-weight: bold;
                }

                &.error {
                    color: $red;
                }

                &.small {
                    font-size: 9px;
                    padding-bottom: 1px;
                    margin-left: 2px;
                }
            }

            .arrow {
                width: 5px;
                margin: 0 5px;
            }
        }

        .breakdown {
            list-style: none;
            padding: 5px 0 0 0;
            margin: 6px 0 0 0;
            border-top: 1px dashed $border-grey;

            li {
                padding: 3px 0;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                span {
                    font-size: 10px;

                    &:first-of-type {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
</style>
