/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'steps-alert-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.061 9h2V4h-2v5zm0 3h2v-2h-2v2zm8.367-7.102a8.039 8.039 0 00-1.703-2.546A8.122 8.122 0 0011.17.641 7.765 7.765 0 008.061 0a7.792 7.792 0 00-3.102.633 8.055 8.055 0 00-2.547 1.703A8.11 8.11 0 00.701 4.891 7.764 7.764 0 00.061 8c0 1.083.21 2.117.632 3.102.422.984.99 1.833 1.703 2.546a8.122 8.122 0 002.555 1.711 7.77 7.77 0 003.11.641 7.788 7.788 0 003.101-.633 8.055 8.055 0 002.547-1.703 8.1 8.1 0 001.711-2.555A7.765 7.765 0 0016.061 8a7.796 7.796 0 00-.633-3.102z" _fill="currentColor" fill-rule="evenodd"/>'
  }
})
