<template>
    <img v-if="image" :src="image" draggable="false" />
</template>

<script>
export default {
    name: 'avatar',
    props: {
        url: {
            type: String,
            default: null,
        },
    },
    computed: {
        key() {
            if (!this.url) return '';

            const crypto = require('crypto');
            const hash = crypto
                .createHash('sha256')
                .update(this.url)
                .digest('hex');

            return hash.substring(0, 10);
        },
        image() {
            const cached = JSON.parse(
                localStorage.getItem(`orglogo_${this.key}`)
            );

            if (cached && cached.image) return cached.image;
            else if (this.url) return this.url;
            else return null;
        },
    },
    watch: {
        url: function () {
            this.checkImage();
        },
    },
    mounted() {
        this.checkImage();
    },
    methods: {
        checkImage() {
            if (this.url) {
                const cached = JSON.parse(
                    localStorage.getItem(`orglogo_${this.key}`)
                );

                if (
                    !cached ||
                    (cached && cached.url != this.url) ||
                    (cached && !cached.image)
                ) {
                    this.cacheImage(this.url);
                }
            } else {
                localStorage.removeItem(`orglogo_${this.key}`);
            }
        },
        cacheImage(url) {
            if (!this.url) return;

            // Saving logo URL
            try {
                localStorage.setItem(
                    `orglogo_${this.key}`,
                    JSON.stringify({
                        url,
                        image: null,
                    })
                );

                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = () => {
                    var reader = new FileReader();
                    reader.onloadend = () => {
                        // Saving logo image data
                        try {
                            localStorage.setItem(
                                `orglogo_${this.key}`,
                                JSON.stringify({
                                    url: url,
                                    image: reader.result,
                                })
                            );
                        } catch (e) {
                            console.warn(e);
                        }
                    };
                    reader.readAsDataURL(xhr.response);
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (e) {
                console.warn(e);
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dot-container {
    position: absolute;
    margin-top: -3px;
    margin-left: -3px;
    padding: 2px;
    border-radius: 50%;
    background-color: white;
    z-index: 10;
    display: flex;

    .dot {
        margin: 0;
    }
}

.el-tooltip {
    &.large {
        height: 27px;
        line-height: 27px;
        min-width: 27px;
        width: 27px;
    }

    &.normal {
        height: 24px;
        line-height: 24px;
        min-width: 24px;
        width: 24px;
    }

    &.mini {
        height: 18px;
        line-height: 18px;
        min-width: 18px;
        width: 18px;
    }

    .initials {
        text-align: center;
        width: 100%;
        font-size: 8px;
        color: $black;
        font-weight: bold;
    }
    .avatar {
        outline: none;
        background: center/cover #d5dce0;
        border-radius: 50%;
        box-shadow: 0 0 0 2px, inset 0 0 0 1px rgba(0, 0, 0, 0.07);
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        color: #ffffff;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        display: inline-flex;
        position: relative;
        vertical-align: top;
        flex-shrink: 0;
        font-size: 11px;
        overflow: hidden;

        width: 100%;
        height: 100%;

        img {
            width: 100%;
            object-fit: cover;
        }
    }
}
</style>
