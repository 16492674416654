<template>
    <el-select
        v-model="selectedEstimate"
        placeholder="Select an estimate"
        :disabled="disabled"
        clearable
    >
        <el-option
            v-for="estimate of filteredEstimates"
            :key="estimate.id"
            :value="estimate.id"
            :label="estimate.title"
        >
            <div class="estimate-row">
                <span class="estimate-name">
                    {{ estimate.title }}
                </span>
                <span class="estimate-ref">
                    {{ estimate.ref }}
                </span>
            </div>
        </el-option>
    </el-select>
</template>

<script>
import {ESTIMATE_STATUS} from '@/enums';

export default {
    name: 'estimate-dropdown',
    title: 'Estimate Selector',
    description: 'Estimate selector dropdown component',

    model: {
        prop: 'estimate_id',
        event: 'change',
    },

    props: {
        project_id: {
            type: String,
            required: true,
        },
        estimate_id: {
            type: String,
            default: null,
        },
        statuses: {
            type: Array,
            default: () => [
                ESTIMATE_STATUS.DRAFT,
                ESTIMATE_STATUS.PENDING,
                ESTIMATE_STATUS.SENT,
                ESTIMATE_STATUS.ACCEPTED,
            ],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedEstimate: null,
        };
    },

    computed: {
        estimates() {
            if (this.disabled) {
                if (this.selectedEstimate) {
                    const estimate = this.$store.getters.estimateWithId(
                        this.selectedEstimate
                    );
                    if (estimate) {
                        return [estimate];
                    }
                }
                return [];
            }
            return this.$store.getters.estimatesForProjectWithId(
                this.project_id
            );
        },
        filteredEstimates() {
            return this.estimates.filter((e) => {
                return this.statuses.includes(e.status);
            });
        },
    },

    watch: {
        estimate_id: {
            handler(id) {
                this.selectedEstimate = id;
            },
            immediate: true,
        },
        selectedEstimate(estimate_id) {
            this.$emit('change', estimate_id);
        },
    },
};
</script>

<style lang="scss" scoped>
.estimate-row {
    display: flex;
    justify-content: space-between;

    .estimate-name {
        font-size: 13px;
        margin-right: 20px;
    }

    .estimate-ref {
        color: #8492a6;
        font-size: 11px;
    }
}
</style>
