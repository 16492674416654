<template>
    <div class="badge">
        <svgicon :name="icon" class="icon" :class="icon_class" />
    </div>
</template>

<script>
import {DUE_STATUS, INVOICE_STATUS} from '@/enums';

export default {
    name: 'invoice-status-icon',
    props: {
        status: {
            type: String,
            required: true,
        },
        due: {
            type: Number,
            default: DUE_STATUS.PENDING,
        },
    },
    computed: {
        icon() {
            switch (this.status) {
                case INVOICE_STATUS.SCHEDULED:
                    return 'invoice_scheduled';
                case INVOICE_STATUS.AUTHORISED:
                    return 'invoice_authorised';
                case INVOICE_STATUS.SENT:
                    return 'invoice_sent';
                case INVOICE_STATUS.PAID:
                    return 'invoice_paid';
                case INVOICE_STATUS.PENDING:
                    return 'invoice_pending';
                case INVOICE_STATUS.ERROR:
                case INVOICE_STATUS.UNDEFINED:
                    return 'invoice_error';
                default:
                    return '';
            }
        },
        icon_class() {
            switch (this.status) {
                case INVOICE_STATUS.SCHEDULED:
                    switch (this.due) {
                        case DUE_STATUS.PENDING:
                            return 'blue';
                        case DUE_STATUS.DUE:
                            return 'orange';
                        case DUE_STATUS.OVERDUE:
                            return 'red';
                        default:
                            return null;
                    }
                case INVOICE_STATUS.PAID:
                    return 'green';
                case INVOICE_STATUS.ERROR:
                    return 'red';
                case INVOICE_STATUS.SENT:
                    switch (this.due) {
                        case DUE_STATUS.OVERDUE:
                            return 'red';
                        default:
                            return null;
                    }
                case INVOICE_STATUS.AUTHORISED:
                case INVOICE_STATUS.UNDEFINED:
                default:
                    return '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.badge {
    width: 20px;
    height: 20px;
    background-color: white;
    box-shadow: 0 0 0 4px white;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .icon {
        width: 20px;
        height: 20px;
        fill: $black;

        &.green {
            fill: $green;
        }

        &.blue {
            fill: $blue;
        }

        &.orange {
            fill: $orange;
        }

        &.red {
            fill: $red;
            stroke: $red;
        }
    }
}
</style>
