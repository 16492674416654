<template>
    <div>
        <actions-buttons>
            <el-tabs v-model="activeTab" @tab-click="tabChanged">
                <el-tab-pane label="Overview" name="_overview" />
                <el-tab-pane label="Settings" name="_settings" />
            </el-tabs>
        </actions-buttons>

        <transition name="slide" mode="out-in">
            <StageTab_overview v-if="activeTab == '_overview'" key="overview" />
            <StageTab_settings v-if="activeTab == '_settings'" key="settings" />
        </transition>
    </div>
</template>

<script>
import ActionsButtons from '@/components/generic/ActionsButtons';

import StageTab_overview from '@/pages/stages/tabs/StageTab_overview';
import StageTab_settings from '@/pages/stages/tabs/StageTab_settings';

export default {
    name: 'stages-detail',
    components: {
        ActionsButtons,
        StageTab_overview,
        StageTab_settings,
    },
    data() {
        return {
            activeTab: '_overview',
        };
    },
    watch: {
        $route(to) {
            this.activeTab = to.meta.tab;
        },
    },
    created() {
        this.activeTab = this.$router.currentRoute.meta.tab;
    },
    methods: {
        tabChanged(event) {
            this.$router.replace({
                name: `stage_detail${event.name}`,
                params: {
                    project_id: this.$route.params.project_id,
                    stage_id: this.$route.params.stage_id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.slide {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateX(5px);
    }
}
</style>
