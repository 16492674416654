<template>
    <el-dialog
        :visible.sync="show"
        width="45%"
        :show-close="false"
        @closed="handleCloseDialog"
    >
        <command-palette
            :command="command"
            :initial_data="command_data"
            :showing="show"
            @close="handleCloseDialog"
        />
    </el-dialog>
</template>
<script>
import CommandPalette from '@/components/command-palette/components/CommandPalette.vue';
import {Commands} from '@/components/command-palette/providers/Commands';

export default {
    name: 'command-palette-modal',
    components: {CommandPalette},
    props: {
        show_dialog: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            show: this.show_dialog,
            command: null,
            command_data: null,
        };
    },
    computed: {},
    watch: {
        show_dialog: function (val) {
            this.show = val;
        },
    },
    mounted() {
        this.$bus.$on('command:action', this.initCommand);
    },
    unmounted() {
        this.$bus.$off('command:action');
    },
    methods: {
        initCommand(arg) {
            // init with preset command and data
            this.command =
                Commands.find((c) => c.id === arg?.command)?.value || null;
            this.command_data = arg?.data || null;
        },
        handleCloseDialog() {
            this.command = null;
            this.command_data = null;
            this.$emit('update:show_dialog', false);
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
::v-deep .el-dialog {
    overflow: hidden;

    .el-dialog__header {
        padding: 0;
    }

    .el-dialog__body {
        padding: 0;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        max-height: 50vh;
        min-height: 300px;

        ::-webkit-scrollbar {
            display: none;
        }
    }
}
</style>
