<template>
    <div v-if="todos.length || issues.length">
        <label
            v-if="label"
            @click="clickOnLabel"
            class="section-title"
            :class="{clickable: !!project_ref}"
        >
            <template v-if="project_ref">
                <span>{{ project_ref }}</span> -
            </template>
            {{ label }}:
        </label>
        <ul class="section">
            <todo-item
                v-for="item in todos"
                :key="`todo_${item.id}-${item.status}`"
                :item="item"
                :show_user="show_user"
                :show_project="show_project_on_items"
                :show_priority="show_priority_on_items"
            />
            <todo-item
                v-for="task in tasks"
                :key="`task_${task.id}-${task.status}`"
                :item="task"
                :show_user="show_user"
                :show_project="show_project_on_items"
            />
            <issue-item
                v-for="issue in issues"
                :key="`issue_${issue.project.jira_project}_${issue.id}`"
                :item="issue"
                :show_user="show_user"
                :show_project="show_project_on_items"
            />
        </ul>
    </div>
</template>

<script>
import TodoItem from '@/components/todo/TodoItem';
import IssueItem from '@/components/issues/IssueItem';

export default {
    name: 'todo-section',
    components: {
        TodoItem,
        IssueItem,
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        project_ref: {
            type: String,
            default: null,
        },
        todos: {
            type: Array,
            default: () => [],
        },
        tasks: {
            type: Array,
            default: () => [],
        },
        issues: {
            type: Array,
            default: () => [],
        },
        show_project_on_items: {
            type: Boolean,
            default: false,
        },
        show_priority_on_items: {
            type: Boolean,
            default: false,
        },
        show_user: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        project() {
            return this.$store.getters.projectWithRef(this.project_ref);
        },
    },
    methods: {
        clickOnLabel() {
            if (this.project) {
                this.$router.push({
                    name: 'project_detail',
                    params: {
                        project_id: this.project.id,
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
div {
    padding: 20px 0;
    border-bottom: 1px solid $border-grey-light;
    user-select: none;

    label {
        padding: 0 10px;
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
        &.clickable {
            cursor: pointer;
        }

        span {
            font-weight: bold;
        }
    }

    .section {
        padding: 0;
        margin: 0;
        list-style: none;
    }
}
</style>
