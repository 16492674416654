// TodoNode displays an input field until a TodoItem is created, then it displays that item

import {Node, nodeInputRule} from '@tiptap/core';
import {VueNodeViewRenderer} from '@tiptap/vue-2';

import TodoNodeView from './TodoNode.vue';

const TodoNode = Node.create({
    name: 'todo_node',
    addOptions: {
        source: null,
        project_id: null,
    },
    draggable: true,
    group: 'block',
    addAttributes() {
        return {
            ref: {
                default: null,
            },
            project_id: {
                default: null,
            },
            source: {
                default: null,
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: `[data-type="${this.name}"]`,
                getAttrs: (dom) => {
                    return {
                        ref: dom.getAttribute('data-ref'),
                    };
                },
            },
        ];
    },
    renderHTML(node) {
        return [
            'div',
            {
                'data-type': 'todo_node',
                'data-ref': node.HTMLAttributes.ref,
            },
        ];
    },
    addNodeView() {
        return VueNodeViewRenderer(TodoNodeView);
    },
    addInputRules() {
        return [
            nodeInputRule({
                find: /^\s*(\/TODO)\s$/i,
                type: this.type,
                getAttributes: {
                    project_id: this.options.project_id,
                    source: this.options.source,
                },
            }),
        ];
    },
});

export default TodoNode;
