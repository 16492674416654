const bindMixin = {
    unmounted() {
        this.unbindAll();
    },
    data() {
        return {
            snapshot_bindings: {},
        };
    },
    methods: {
        unbindAll() {
            Object.values(this.snapshot_bindings).forEach((value) => {
                this.unbindFor(value);
            });
        },
        unbindFor(collection) {
            this.snapshot_bindings[collection]?.forEach((unsub) => {
                unsub();
            });
            // immutably remove the entry
            const {[collection]: discard, ...rest} = this.snapshot_bindings;
            this.snapshot_bindings = rest;
            this[collection] = [];
        },
        /**
         * Bind to a collection using similar syntax to vuefire
         */
        bindDynamicSnapshot(field, firebaseBind) {
            const unsub = firebaseBind.onSnapshot((snapshot) => {
                this.onSnapshotChange(field, snapshot);
            });
            if (!this.snapshot_bindings[field]) {
                this.snapshot_bindings[field] = [];
            }
            this.snapshot_bindings[field].push(unsub);
        },
        // Generic handler for onSnapshot on a firebase binding
        onSnapshotChange(collection, collection_snapshot) {
            let temp = this[collection];
            collection_snapshot.docChanges().forEach((change) => {
                const snapshot = change.doc;
                switch (change.type) {
                    case 'added':
                        temp.push({
                            ...snapshot.data(),
                            id: snapshot.id,
                            path: snapshot.ref.path,
                        });
                        break;
                    case 'removed':
                        temp = temp.filter((task) => task.id !== snapshot.id);
                        break;
                    case 'modified':
                        temp = temp.map((item) => {
                            if (item.id === snapshot.id) {
                                return {
                                    ...snapshot.data(),
                                    id: snapshot.id,
                                    path: snapshot.ref.path,
                                };
                            }
                            return item;
                        });
                        break;
                }
            });
            this[collection] = temp;
        },
    },
};

export default bindMixin;
