<template>
    <div />
</template>
<script>
import {COMMAND_DEPENDENCIES} from '@/enums';

export default {
    // command attributes (referenced externally)
    command_props: {
        dependencies: [COMMAND_DEPENDENCIES.COMMAND],
        prompt: 'What would you like to do?',
    },

    name: 'default-command',
    components: {},
    props: {
        provided_data: {
            type: Object,
            required: true,
        },
        search_string: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            dependencies: [COMMAND_DEPENDENCIES.COMMAND],
            prompt: 'What would you like to do?',
        };
    },
    // The DefaultCommand does not need to watch for data changes,
    // command switching happens in CommandPalette based on active_data's command
};
</script>
