/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jira-design': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g transform="translate(1 1)" _fill="none" fill-rule="evenodd"><rect pid="0" _fill="#4BAEE8" width="14" height="14" rx="2"/><path pid="1" d="M11 5.543v.279c-.005.022-.014.044-.015.066-.018.261-.078.513-.166.759-.217.604-.576 1.122-1.001 1.598-.803.898-1.768 1.601-2.78 2.246a.092.092 0 01-.081 0c-.862-.545-1.686-1.14-2.412-1.857-.472-.466-.892-.972-1.19-1.568-.248-.497-.396-1.017-.345-1.578.063-.68.38-1.219.948-1.603.518-.351 1.093-.478 1.709-.315.532.141.934.473 1.267.896l.057.071c.122-.134.229-.267.352-.385.514-.489 1.11-.738 1.838-.617.833.138 1.567.81 1.752 1.63.028.125.045.252.067.378" _fill="#FFF"/></g>'
  }
})
