<template>
    <div>
        <user-day-tracker :user="current_user" theme="dark" />

        <template v-if="user_is_admin">
            <user-day-tracker
                v-for="user in other_users"
                :key="user.id + '_day-tracker'"
                :user="user"
            />
        </template>
        <template v-else>
            <user-block
                v-for="user in other_users"
                :key="user.id + '_user-tracker'"
                :user="user"
            />
        </template>
    </div>
</template>

<script>
import UserBlock from '@/components/blocks/UserBlock';
import UserDayTracker from '@/components/generic/UserDayTracker';

import userMixin from '@/mixins/user.mixin';

export default {
    name: 'home-tab-team',
    components: {
        UserBlock,
        UserDayTracker,
    },
    mixins: [userMixin],
    computed: {
        other_users() {
            return this.users.filter((u) => {
                return u.id !== this.current_user.id;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.user-day-tracker:last-of-type {
    margin: 0;
}
.user:last-of-type {
    margin: 0;
}
</style>
