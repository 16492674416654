<template>
    <div class="container" :style="cssStyles">
        <div class="top-panel">
            <div class="content-controls">
                <div class="info-level-picker">
                    <el-button size="mini" @click="handleCollapseAll">
                        Collapse All
                    </el-button>
                    <el-button size="mini" @click="handleExpandAll">
                        Expand All
                    </el-button>
                </div>
                <div style="display: flex; align-items: center;">
                    <el-switch
                        style="margin-right: 20px;"
                        :value="filterShyNodes"
                        size="mini"
                        @input="toggleFilterShyNodes"
                    />
                    <div>
                        <el-button size="mini" @click="handleShyAll">
                            Shy All
                        </el-button>
                        <el-button size="mini" @click="handleShyNone">
                            Shy None
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="resolution-picker">
                <el-checkbox-group
                    :value="checkList"
                    class="resolution-checkbox-group"
                    size="mini"
                    @input="handleCheckboxChange"
                >
                    <el-checkbox-button label="day" />
                    <el-checkbox-button label="week" />
                    <el-checkbox-button label="month" />
                </el-checkbox-group>
                <el-button size="mini" @click="scrollToToday">Today </el-button>
            </div>
            <div class="zoom-picker">
                <el-select
                    :value="zoomValue"
                    size="mini"
                    @input="handleSetZoomValue"
                >
                    <el-option
                        v-for="option in zoomOptions"
                        :key="option"
                        :label="`${option}%`"
                        :value="option"
                    />
                </el-select>
            </div>
        </div>

        <div
            id="timeline"
            v-dragscroll
            class="timeline"
            @scroll="handleVerticalScroll"
        >
            <div class="timeline-left-panel">
                <div class="timeline-bar-spacer" />
                <tree-list
                    style=""
                    :nodes="tree.children"
                    :depth="0"
                    :cur_node="tree"
                />
            </div>

            <div class="chart-box" :style="{width: `${dimensions.width}px`}">
                <div class="timeline-bar-spacer" />
                <tree-chart
                    :cur_node="tree"
                    :nodes="tree.children"
                    :day_width="DAY_WIDTH"
                    :row_height="ROW_HEIGHT"
                    :depth="0"
                />
            </div>

            <timeline-bg-lines
                :resolutions="resolutions"
                :vertical_scroll_offset="verticalScrollOffset"
                :day_width="DAY_WIDTH"
                :row_height="ROW_HEIGHT"
            />
        </div>
    </div>
</template>
<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import debounce from 'lodash.debounce';

import TimelineBgLines from './TimelineBgLines';
import TreeList from './TreeList';
import TreeChart from './TreeChart';

import {
    adjustedEndDate,
    adjustedStartDate,
    countNodes,
    workDaysBetween,
} from '@/components/timeline-v2/utils';
import timelineStore from '@/components/timeline-v2/timelineStore';
import EventBus from '@/components/timeline-v2/EventBus';

export default {
    name: 'timeline',
    components: {
        TimelineBgLines,
        TreeList,
        TreeChart,
    },
    props: {
        tree: {
            type: Object,
            required: true,
        },
        count: {
            type: Number,
            default: 0,
        },
        default_hidden: {
            type: Array,
            default: () => [],
        },
        ready: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        zoomValue: 100,
        zoomOptions: [10, 25, 50, 75, 100, 125, 150, 200],
        verticalScrollOffset: 0,
        resolutions: {
            day: false,
            week: true,
            month: true,
            year: false,
        },
        sliderVal: 1500,
        ROW_HEIGHT: 30,
        TIMELINE_BAR_HEIGHT: 100,
        LEFT_PANEL_WIDTH: 250,
        TOP_PANEL_HEIGHT: 60,
        TOP_PANEL_MARGIN: 0,
        TREE_BOTTOM_MARGIN: 10,
    }),
    computed: {
        DAY_WIDTH() {
            return (this.zoomValue / 100) * 40;
        },
        cssStyles() {
            return {
                '--row-height': `${this.ROW_HEIGHT}px`,
                '--timeline-bar-height': `${this.TIMELINE_BAR_HEIGHT}px`,
                '--left-panel-width': `${this.LEFT_PANEL_WIDTH}px`,
                '--top-panel-height': `${this.TOP_PANEL_HEIGHT}px`,
                '--top-panel-margin': `${this.TOP_PANEL_MARGIN}px`,
                '--tree-bottom-margin': `${this.TREE_BOTTOM_MARGIN}px`,
            };
        },
        dimensions() {
            return {
                height:
                    countNodes(this.tree) * this.ROW_HEIGHT +
                    this.TIMELINE_BAR_HEIGHT,
                width:
                    workDaysBetween(
                        adjustedStartDate(this.tree),
                        adjustedEndDate(this.tree)
                    ) * this.DAY_WIDTH,
            };
        },
        checkList() {
            return Object.entries(this.resolutions)
                .filter(([_, v]) => v)
                .map(([k, _]) => k);
        },

        ...mapState(['filterShyNodes', 'hiddenNodes']),
        ...mapGetters(['days']),
    },
    watch: {
        tree: {
            handler: function (newVal) {
                this.set({tree: newVal});
            },
            immediate: true,
        },
        ready(newVal) {
            if (newVal) {
                this.set({hiddenNodes: this.default_hidden});
            }
        },
    },
    store: timelineStore,
    created() {
        // external events
        EventBus.$on('update:node', (payload) =>
            this.$emit('update:node', payload)
        );

        EventBus.$on('click:node', (e) => this.$emit('click:node', e));

        this.$store.commit('set', {hiddenNodes: this.default_hidden});
    },
    mounted() {
        this.scrollToToday();
    },
    beforeDestroy() {
        // external events
        EventBus.$off('update:node');
        EventBus.$off('click:node');
    },
    methods: {
        scrollToToday() {
            const todayEl = document.getElementById('today');
            const timelineArea = document.getElementById('timeline');

            todayEl.scrollIntoView({
                inline: 'start',
            });

            timelineArea.scrollLeft =
                timelineArea.scrollLeft -
                timelineArea.getBoundingClientRect().width * 0.2;
        },

        // event handlers
        handleShyAll() {
            this.set({
                shyNodes: this.tree.children,
            });
        },

        handleShyNone() {
            this.set({
                shyNodes: [],
            });
        },

        handleCollapseAll() {
            this.set({
                hiddenNodes: this.tree.children,
            });
        },

        handleExpandAll() {
            this.set({
                hiddenNodes: [...this.default_hidden],
            });
        },

        handleSetZoomValue(v) {
            this.zoomValue = v;
            this.$nextTick(() => {
                this.scrollToToday();
            });
        },

        handleVerticalScroll: debounce(function (e) {
            this.verticalScrollOffset = e.target.scrollTop;
        }, 1),

        handleSliderChange: debounce(function (v) {
            this.sliderVal = v;
        }, 5),

        handleCheckboxChange(e) {
            this.resolutions = Object.keys(this.resolutions).reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur]: e.indexOf(cur) > -1,
                }),
                {}
            );
        },

        ...mapMutations(['set']),

        ...mapActions(['toggleFilterShyNodes']),
    },
};
</script>

<style scoped lang="scss">
.container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;

    .top-panel {
        height: var(--top-panel-height);
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: var(--top-panel-margin);
        padding: 0 10px;
        box-sizing: border-box;

        .content-controls {
            display: flex;
            align-items: center;

            .info-level-picker {
                margin-right: 20px;
            }

            .shy-filter-switch {
                margin-right: 20px;
            }
        }

        .resolution-picker {
            display: flex;
            align-items: center;
            margin-left: auto;

            .resolution-checkbox-group {
                margin-right: 20px;
            }

            margin-right: 20px;
        }
    }

    ::v-deep .timeline {
        width: 100%;
        height: calc(100% - var(--top-panel-height) - var(--top-panel-margin));
        background-color: $white;
        display: flex;
        position: relative;
        color: $black;
        user-select: none;

        overflow: scroll; // Only scrolling div

        .row {
            height: var(--row-height);
            display: flex;
            align-items: center;
            font-size: 12px;
        }

        .timeline-left-panel {
            position: -webkit-sticky;
            position: sticky;
            left: 0;
            z-index: 100;

            &,
            .timeline-bar-spacer,
            .tree-list-container {
                background-color: white;
                box-shadow: 1px 0 0 0 $border-grey-light;
                width: var(--left-panel-width);
            }
        }

        .chart-box {
            left: var(--left-panel-width);
            position: absolute;
            z-index: 1;
        }

        .timeline-bar-spacer {
            height: var(--timeline-bar-height);
        }
    }
}
</style>
