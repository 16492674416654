<template>
    <div
        v-if="editor"
        class="editor session-input"
        :class="{responsive: simple_responsive_design}"
        @click.stop.prevent
    >
        <div class="content">
            <template v-if="!show_editor && bound_tasks.length">
                <add-issue-tag-button
                    v-if="bound_tasks.length < 3"
                    @click="showEditor"
                />
            </template>

            <editor-content
                v-else
                ref="editor_content"
                v-shortkey.avoid
                :editor="editor"
                @keyup.native="handleKeyUp"
            />
        </div>
        <div class="bound_tasks">
            <issue-tag
                v-for="task in bound_tasks"
                :key="task.key"
                :data="task"
                :show_summary="bound_tasks.length < 3"
                @click="() => onClickTask(task)"
                @remove="() => unbindTask(task.ref)"
            />
        </div>
    </div>
</template>

<script>
import LinkNode from '@/components/editor/extensions/LinkNode.js';
import OneLiner from '@/components/editor/extensions/OneLiner.js';
import IssueSuggestionList from '@/components/issues/IssueSuggestionList.vue';
import AddIssueTagButton from '@/components/issues/AddIssueTagButton.vue';

import Placeholder from '@tiptap/extension-placeholder';

import {Extension} from '@tiptap/core';
import {Editor, EditorContent} from '@tiptap/vue-2';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import FilterNode from '@/components/editor/extensions/FilterNode';
import IssueTag from '@/components/issues/IssueTag.vue';
import firebase from 'firebase/app';
import debounce from 'lodash.debounce';
import bindMixin from '@/mixins/bind.mixin';

/**
 * Component for session related inputs
 * Allows for linking independently of the note:
 * - Jira issues
 * - Todos
 */
export default {
    name: 'session-input',
    components: {
        IssueTag,
        EditorContent,
        AddIssueTagButton,
    },
    mixins: [bindMixin],
    props: {
        session: {
            type: Object,
            required: true,
        },
        simple_responsive_design: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: 'Add note',
        },
    },
    data() {
        return {
            editor: null,
            debounce: null,
            bound_tasks: [],
            show_editor: false,
            value: '',
            saved_value: '',
            project: null,
            component: null,
            groups: [],
            tasks: [],
            subtasks: [],
        };
    },
    watch: {
        value(val, old) {
            if (!this.debounce && val !== old && val !== this.saved_value) {
                this.saved_value = val;
                this.editor.commands.setContent(this.textToJson(val), false);
            }
        },
        project(val, old) {
            if (val !== old) {
                this.init();
            }
        },
        component(val, old) {
            if (val) {
                // we don't need to bind - just fetch once
                this.$fire
                    .collection('estimate_groups')
                    .where(
                        'ref_links.tracking_component',
                        '==',
                        this.$fire.doc(val.path)
                    )
                    .get()
                    .then((snapshot) => {
                        const groups = [];
                        snapshot.forEach((group) => {
                            const data = group.data();
                            groups.push({
                                ...data,
                                doc: this.$fire.doc(
                                    `estimate_groups/${group.id}`
                                ),
                            });
                        });
                        this.groups = groups;
                    });
            }
        },
        groups(val, old) {
            if (val) {
                this.unbindFor('tasks');
                this.unbindFor('subtasks');
                val.forEach((group) => {
                    this.bindDynamicSnapshot(
                        'tasks',
                        this.$fire
                            .collection('estimate_tasks')
                            .where('group', '==', group.doc)
                    );
                    this.bindDynamicSnapshot(
                        'subtasks',
                        this.$fire
                            .collection('estimate_subtasks')
                            .where('group', '==', group.doc)
                    );
                });
            }
        },
        editable: {
            immediate: true,
            handler() {
                if (!this.editor) return;
                this.editor.setOptions({
                    editable: this.editable,
                });
            },
        },
        'session.note': {
            handler(newValue) {
                this.value = newValue;
            },
            immediate: true,
        },
        'session.bound_tasks': {
            async handler(newValue) {
                if (newValue && newValue.length > 0) {
                    this.bound_tasks = await Promise.all(
                        newValue?.map(async (ref) => {
                            const doc = await this.$fire.doc(ref).get();
                            return {...doc.data(), ref};
                        })
                    );
                } else {
                    this.bound_tasks = [];
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        showEditor() {
            this.show_editor = true;
            // hack to change placeholder dynamically...
            this.editor.extensionManager.extensions.filter(
                (x) => x.name === 'placeholder'
            )[0].options['placeholder'] = 'Ticket Key';
            this.focus();
        },
        bindTask(binding) {
            this.show_editor = false;
            const binding_ref = this.$fire.doc(binding);
            return this.$fire
                .collection('sessions')
                .doc(this.session.id.toString())
                .update({
                    bound_tasks: firebase.firestore.FieldValue.arrayUnion(
                        binding_ref
                    ),
                    note: '',
                });
        },
        unbindTask(ref) {
            return this.$fire
                .collection('sessions')
                .doc(this.session.id.toString())
                .update({
                    bound_tasks: firebase.firestore.FieldValue.arrayRemove(
                        this.$fire.doc(ref)
                    ),
                });
        },
        onClickTask(task) {
            //TODO: this assumes the task if a jira issue and not a todo
            window.open(
                `https://${task.resource.name}.atlassian.net/browse/${task.key}`,
                '_blank'
            );
        },
        focus() {
            this.$nextTick(() => this.editor.commands.focus('start'));
        },

        handleKeyUp(event) {
            switch (event.key) {
                case 'Enter':
                    this.$emit('enter', this.jsonToText(this.editor.getJSON()));
                    break;
                case 'Backspace':
                    this.$emit(
                        'backspace',
                        this.jsonToText(this.editor.getJSON())
                    );
                    break;
                case 'Escape':
                    this.editor.commands.blur();
                    break;
                default:
                    this.$emit(
                        'keyup',
                        this.jsonToText(this.editor.getJSON()),
                        event.key
                    );
            }
        },
        init() {
            this.project = this.$store.getters.projectWithId(
                this.session?.project
            );
            this.component = this.$store.getters.componentWithId(
                this.session?.component
            );
            if (this.editor) {
                this.editor.destroy();
            }

            const handleCommand = () => {
                this.$bus.$emit('modal:action', {
                    modal: 'command-palette',
                    show: true,
                });
            };

            const ShortcutExtension = Extension.create({
                addKeyboardShortcuts() {
                    return {
                        'Mod-k': handleCommand,
                    };
                },
            });

            const extensions = [
                OneLiner,
                Paragraph,
                Text,
                LinkNode,
                ShortcutExtension,
                Placeholder.configure({placeholder: this.placeholder}),
            ];

            if (this.project) {
                extensions.push(
                    FilterNode.configure({
                        suggestion: {
                            items: this.filterIssues,
                        },
                        suggestion_component: IssueSuggestionList,
                        onSelect: (item) => {
                            this.bindTask(item);
                        },
                    })
                );
            }

            this.editor = new Editor({
                extensions,
                editable: this.editable,
                content: this.textToJson(this.value),
                onUpdate: () => {
                    clearTimeout(this.debounce);

                    this.debounce = setTimeout(
                        () => (this.debounce = null),
                        50
                    );

                    this.noteChanged();
                },
            });
        },
        noteChanged: debounce(function () {
            this.saved_value = this.jsonToText(this.editor.getJSON());
            return this.$fire
                .collection('sessions')
                .doc(this.session.id.toString())
                .update({
                    note: this.saved_value,
                });
        }, 400),
        textToJson(text) {
            if (!text) {
                return null;
            }
            const link_regx = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.?[a-zA-Z0-9()]{0,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))($|\s)/gi;

            let matches;
            let current_text = text;
            let nodes = [];
            let lastIndex = 0;

            while ((matches = link_regx.exec(current_text)) !== null) {
                const previous = current_text.substr(
                    lastIndex,
                    matches.index - lastIndex
                );

                if (previous !== '') {
                    nodes.push({
                        type: 'text',
                        text: previous,
                    });
                }

                nodes.push({
                    type: 'link_node',
                    attrs: {
                        url: matches[1],
                    },
                });

                lastIndex = matches.index + matches[1].length;
            }

            const last_text = current_text.substr(lastIndex);

            if (last_text !== '') {
                nodes.push({
                    type: 'text',
                    text: last_text,
                });
            }

            return {
                type: 'doc',
                content: [{type: 'paragraph', content: nodes}],
            };
        },
        jsonToText(json) {
            if (json && json.content && json.content.length > 0) {
                if (
                    json.content[0].content &&
                    json.content[0].content.length > 0
                ) {
                    return json.content[0].content
                        .map((node) => {
                            if (node.type === 'text') {
                                return node.text;
                            }
                            if (node.type === 'link_node')
                                return node.attrs.url;

                            return '';
                        })
                        .join('');
                }
            }

            return '';
        },
        getContentHtml() {
            return this.editor.getHTML();
        },
        async filterIssues(query) {
            /**
             * Only allow the user to get issues associated with the
             * component (if relevant). Use a map to ensure uniqueness.
             */

            // get all issues for estimate groups linked to this component
            const issuesMap = new Map();
            [...this.groups, ...this.tasks, ...this.subtasks].forEach(
                (record) => {
                    const id = record.linked_issue?.path ?? record.linked_issue;

                    if (id && !issuesMap.get(id)) {
                        issuesMap.set(
                            id,
                            this.$store.getters.issueWithDocumentPath(id)
                        );
                    }
                }
            );
            const issues = [...issuesMap.values()];

            // if no linked estimate groups, get all issues for project
            // (disabled to encourage linking estimates)
            // if (issues.length === 0) {
            //     issues.push(
            //         ...this.$store.getters.issuesForJiraProject(
            //             this.project.jira_project
            //         )
            //     );
            // }
            const bound_issues =
                this.session.bound_tasks?.map((bound) => {
                    //todo: this is flimsy
                    const id = bound.split('/')[1];
                    return this.$store.getters.issueWithDocumentId(id);
                }) || [];
            const filtered_issues = issues.filter((x) => {
                return !bound_issues?.some((bound) => {
                    return bound.key === x.key;
                });
            });

            return filtered_issues.filter((issue) => {
                return `${issue.key} ${issue.summary}`
                    .toLowerCase()
                    .includes(query.toLowerCase());
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.editor {
    --gap: 5px;

    display: flex;
    outline: none;
    align-items: center;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    color: $black;
    overflow: hidden;
    gap: var(--gap);

    ::v-deep p {
        margin: 0;
    }

    .content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        overflow: hidden !important;
        white-space: nowrap !important;

        p {
            display: inline-block !important;
            white-space: nowrap !important;
        }

        > div {
            flex: 1;
        }

        ::v-deep .ProseMirror {
            text-align: right;

            p {
                margin: 0;

                &.is-editor-empty:first-child::before {
                    content: attr(data-placeholder);
                    position: absolute;
                    right: 0;
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }

    .bound_tasks {
        display: flex;
        gap: var(--gap);
    }

    @media screen and (max-width: 992px) {
        min-height: 30px;
        padding: 0;

        .content ::v-deep .ProseMirror {
            text-align: left !important;
        }
    }

    &.responsive ::v-deep .ProseMirror p.is-editor-empty:first-child::before {
        left: 0;
    }
}

.dark {
    color: $white;

    ::v-deep .link {
        color: $white;

        a {
            color: $white;
        }
    }
}
</style>
