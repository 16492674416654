import {firebaseFirestore} from '@/utils/firebase';

export function startTimer(user_id, face_id) {
    return firebaseFirestore.doc(`cubes/${user_id}`).update({
        active_face: parseInt(face_id),
    });
}

export function stopAllTimers(user_id) {
    return firebaseFirestore.doc(`cubes/${user_id}`).update({
        active_face: 0,
    });
}
