<template>
    <button class="play" :class="{loading: loading}">
        <div v-if="loading" v-loading="true" class="spinner" />
        <svgicon name="play" />
    </button>
</template>

<script>
export default {
    name: 'stop-button',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
button.play {
    position: relative;
    outline: none;
    border: 2px solid $border-grey-light;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.1s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
    background-color: white;

    @media screen and (max-width: 992px) {
        width: 35px;
        height: 35px;

        .spinner,
        .el-loading-mask .el-loading-spinner,
        .el-loading-mask .el-loading-spinner svg {
            width: 43px !important;
            height: 43px !important;
        }
    }

    &.loading {
        border-color: transparent;
        cursor: default;

        svg {
            opacity: 0.4;
        }
    }

    ::v-deep .spinner {
        top: -6px;
        left: -6px;

        width: 48px;
        height: 48px;

        position: absolute !important;

        .el-loading-mask {
            background: unset;

            .el-loading-spinner {
                height: 48px;
                width: 48px;

                svg {
                    height: 48px;
                    width: 48px;
                }

                .path {
                    stroke: $black;
                }
            }
        }
    }

    svg {
        width: 10px;
        flex-shrink: 0;
        margin-left: 3px;
    }

    &:hover:not(.loading) {
        transform: scale(1.1);
        border: 2px solid $black;
    }
}
</style>
