import request from '@/utils/request';

// {{host}}/sonos/:group_id/playback/:state
export function setPlaybackState(group_id, state) {
    return request({
        url: `/sonos/${group_id}/playback/${state}`,
        method: 'PUT',
    });
}

// {{host}}/sonos/:group_id/volume/:level
export function setVolume(group_id, level) {
    return request({
        url: `/sonos/${group_id}/volume/${level}`,
        method: 'PUT',
    });
}
