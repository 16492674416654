const lineItemMixin = {
    methods: {
        lineItem_discount(line_item) {
            if (line_item.discount_rate)
                return parseFloat(line_item.discount_rate);

            return 0;
        },
        lineItem_rate(line_item, used_stored = true) {
            // If the line item has a custom rate
            if (typeof line_item.rate !== 'undefined' && used_stored) {
                return line_item.rate;
            }

            switch (this.lineItem_rate_type(line_item)) {
                case 'day':
                    // If the line item unit amount is over $1000
                    // Consider as daily rate
                    if (line_item.unit_amount >= 1000) {
                        return (
                            line_item.unit_amount -
                            line_item.unit_amount *
                                (this.lineItem_discount(line_item) / 100)
                        );
                    }

                    break;

                case 'hour':
                    // If the line item has been setup without hours quantity
                    // Calculate based on system hourly rate
                    if (line_item.quantity <= 1) {
                        return +process.env.VUE_APP_HOURLY_RATE;
                    }

                    break;

                default:
                    break;
            }

            return line_item.line_amount / line_item.quantity;
        },
        lineItem_rate_type(line_item) {
            // If the line item has a custom rate type
            if (typeof line_item.rate_type !== 'undefined') {
                return line_item.rate_type;
            }

            // If the line item unit amount is over $1000
            // Consider as daily rate
            if (line_item.unit_amount >= 1000) {
                return 'day';
            }

            return 'hour';
        },
        lineItem_daily_hours(line_item) {
            // If the line item has a custom daily_hours
            if (typeof line_item.daily_hours !== 'undefined') {
                return line_item.daily_hours;
            }

            return +process.env.VUE_APP_DAILY_HOURS;
        },
        lineItem_quantity(line_item) {
            if (!this.lineItem_rate(line_item)) return 0;
            switch (this.lineItem_rate_type(line_item)) {
                case 'day':
                    const days =
                        line_item.line_amount / this.lineItem_rate(line_item);
                    return days * this.lineItem_daily_hours(line_item);
                case 'hour':
                    return (
                        line_item.line_amount / this.lineItem_rate(line_item)
                    );
                default:
                    return 0;
            }
        },
    },
};

export default lineItemMixin;
