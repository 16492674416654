<template>
    <div class="container">
        <label>Hubspot</label>

        <div class="right">
            <el-button
                v-if="loading"
                v-loading="true"
                disabled
                class="spinner"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0)"
            >
                <i class="el-icon-loading" style="visibility: hidden;" />
            </el-button>
            <template v-else>
                <template v-if="is_hubspot_authed">
                    <el-button disabled>
                        Connected
                    </el-button>
                    <el-button
                        style="flex-grow: inherit;"
                        @click="handleDisconnectHubspot"
                    >
                        <i class="el-icon-delete" />
                    </el-button>
                </template>
                <el-button
                    v-else
                    :disabled="loading"
                    @click="redirectToHubspotAuth"
                >
                    Connect
                </el-button>
            </template>
        </div>
    </div>
</template>

<script>
import userMixin from '@/mixins/user.mixin';
import {disconnectHubspot, redirectUserToHubspot} from '@/api/hubspot-auth';

export default {
    name: 'hubspot-integration',
    components: {},
    mixins: [userMixin],
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        refreshUser() {
            return this.$store.dispatch('Refresh', true);
        },
        async redirectToHubspotAuth() {
            this.loading = true;

            redirectUserToHubspot()
                .then((result) => {
                    window.location = result;
                })
                .catch((e) => {})
                .finally(() => {
                    this.loading = false;
                });
        },
        async handleDisconnectHubspot() {
            this.loading = true;

            await disconnectHubspot();
            await this.refreshUser();

            this.loading = false;
        },
    },
};
</script>
