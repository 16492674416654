<template>
    <content-block title="Modules" no_background>
        <el-alert
            v-if="!modules.length"
            description="No modules created for this project."
            :closable="false"
        />
        <div v-else class="list">
            <project-module
                v-for="module in modules"
                :key="module.id"
                :module="module"
            />
        </div>
    </content-block>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import ProjectModule from './ProjectModule';

export default {
    name: 'project-modules',
    components: {
        ProjectModule,
        ContentBlock,
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        modules() {
            return this.$store.getters.modulesForProjectWithId(this.project.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
</style>
