<template>
    <div v-if="is_loaded">
        <div class="header">
            <el-button @click="goBackAction">Back</el-button>
            <span class="ref">
                /
                <span>
                    {{ estimate_id }} / Payment plan / {{ payment_plan.ref }}
                </span>
            </span>
        </div>

        <hr />

        <span class="contact-email-header">
            Contact emails for pending invoices:
        </span>

        <paymentplan-emails
            :payment_plan="payment_plan"
            @change="handleUpdateContactEmails"
        />

        <div class="paymentplan-form-footer">
            <el-button @click="goBackAction">Cancel</el-button>
            <el-button :disabled="is_disabled" @click="handleSave">
                Save
            </el-button>
        </div>
    </div>
</template>

<script>
import PaymentplanEmails from '@/pages/paymentplans/components/PaymentPlan_emails.vue';
import {EMAIL_TYPE} from '@/enums';
import {Notification} from 'element-ui';

export default {
    name: 'payment-plan-plan',
    components: {PaymentplanEmails},
    data() {
        return {
            payment_plan: null,
            estimate: null,
            loading: false,
            contact_emails: [],
        };
    },
    computed: {
        estimate_id() {
            if (!this.payment_plan) return null;
            return this.$options.filters.fireRef2id(this.payment_plan.estimate);
        },
        project_id() {
            if (!this.payment_plan) return null;
            return this.$options.filters.fireRef2id(this.payment_plan.project);
        },
        is_loaded() {
            return this.payment_plan;
        },
        is_disabled() {
            // check if at least 1 contact email has been provided and selected as the addressee
            if (!this.contact_emails || this.contact_emails.length === 0) {
                return true;
            }

            if (
                !this.contact_emails.some(
                    (c) => c.type === EMAIL_TYPE.ADDRESSEE
                )
            ) {
                return true;
            }
            return false;
        },
    },
    watch: {
        '$route.params.plan_id'() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.loading = true;

            /* PAYMENT PLAN */
            await this.$bind(
                'payment_plan',
                this.$fire.doc(`payment_plans/${this.$route.params.plan_id}`),
                {
                    maxRefDepth: 0,
                }
            ).catch((e) => console.warn('payment_plan: ', e));

            this.loading = false;
        },
        handleUpdateContactEmails(emails) {
            this.contact_emails = emails;
        },
        async handleSave() {
            this.loading = true;
            try {
                await this.$fire
                    .doc(`payment_plans/${this.payment_plan.id}`)
                    .update({contact_emails: this.contact_emails});

                this.goBackAction();
            } catch (err) {
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: err.message,
                });
            } finally {
                this.loading = false;
            }
        },
        goBackAction() {
            if (this.$route.params.invoice_id) {
                // return to invoice
                this.$router.push({
                    name: 'project_detail_paymentplan_invoice',
                    params: {
                        project_id: this.project_id,
                        invoice_id: this.$route.params.invoice_id,
                    },
                });
            } else {
                // return to plan list for estimate
                this.$router.push({
                    name: 'project_detail_paymentplan',
                    params: {
                        project_id: this.project_id,
                        estimate_id: this.estimate_id,
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    span.ref {
        padding: 0 10px;

        span {
            padding: 0 10px;
            font-weight: bold;
        }
    }
}

hr {
    width: 100%;
    margin: 10px 0;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}

.contact-email-header {
    font-size: 13px;
    margin-top: 20px;
}

.paymentplan-form-footer {
    margin-top: 20px;
    text-align: right;
}
</style>
