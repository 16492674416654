<template>
    <div class="notifications">
        <ul v-if="notifications.length > 0" class="actions">
            <li><a href="#" @click="clearAll">Clear all</a></li>
            <li><a href="#" @click="markAllAsRead">Mark all as Read</a></li>
        </ul>
        <ul>
            <template v-if="notifications.length > 0">
                <li
                    v-for="notification in notifications"
                    :key="notification.id"
                >
                    <notification-list-item
                        :notification="notification"
                        @click:notification="handleClick"
                    />
                </li>
            </template>
            <template v-else>
                <li class="placeholder">
                    No notifications!
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import NotificationListItem from './NotificationListItem';

export default {
    name: 'notification-list',
    components: {NotificationListItem},

    props: {
        notifications: {
            type: Array,
            default: () => [],
        },
    },

    mounted() {
        setTimeout(() => {
            window.addEventListener('click', this.close);
        }, 250);
    },

    destroyed() {
        window.removeEventListener('click', this.close);
    },

    methods: {
        close() {
            this.$emit('close');
        },
        clearAll() {
            this.notifications.forEach((notification) => {
                this.$fire.doc(`notifications/${notification.id}`).delete();
            });
        },
        markAllAsRead() {
            this.notifications.forEach((notification) => {
                if (!notification.read) {
                    this.$fire
                        .doc(`notifications/${notification.id}`)
                        .update({read: true});
                }
            });
        },
        handleClick(notification) {
            this.$emit('click', notification);
        },
    },
};
</script>

<style lang="scss" scoped>
$width: 400px;

.notifications {
    width: $width;
    overflow: hidden;
    margin-top: 20px;
    margin-left: -($width - 20px);
    position: absolute;
    background-color: white;
    box-shadow: 0 20px 66px 0 rgba(34, 48, 73, 0.2);
    user-select: none;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;

    ul.actions {
        margin: 0;
        padding: 10px;
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 11px;

        li {
            a {
                color: rgba($black, 0.7);
                text-decoration: none;

                &:hover {
                    color: $black;
                }
            }
        }
    }

    > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        max-height: 400px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }

        > li {
            margin: 0;
            padding: 0;

            &.placeholder {
                text-align: center;
                padding: 20px;
                font-size: 12px;
                color: rgba($black, 0.6);
            }
        }
    }
}
</style>
