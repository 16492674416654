<template>
    <div class="outer">
        <header>
            <el-button size="mini" @click="createTemplate">
                New
            </el-button>
        </header>

        <div class="inner">
            <loader v-if="loading" />
            <ul v-else>
                <li
                    v-for="template in sorted_templates"
                    :key="template.id"
                    @click="() => editTemplate(template.id)"
                >
                    <span>{{ template.title }}</span>
                    <svgicon name="triangle" class="triangle" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/generic/Loader.vue';

export default {
    name: 'estimates-templates',
    components: {Loader},
    data() {
        return {
            templates: [],
            loading: true,
        };
    },
    computed: {
        sorted_templates() {
            return this.templates;
        },
    },
    mounted() {
        this.loadTemplates();
    },
    methods: {
        loadTemplates() {
            this.loading = true;

            this.$bind(
                'templates',
                this.$fire
                    .collection('estimates')
                    .where('is_template', '==', true)
                    .orderBy('title', 'asc'),
                {
                    maxRefDepth: 0,
                }
            ).then(() => {
                this.loading = false;
            });
        },
        createTemplate() {
            this.$router
                .push({
                    name: 'estimate_template_new',
                })
                .catch((err) => {});
        },
        editTemplate(id) {
            this.$router
                .push({
                    name: 'estimate_template_edit',
                    params: {
                        estimate_id: id,
                    },
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
        box-sizing: border-box;
        height: 65px;
        padding: 10px 20px;
        border-bottom: 1px solid $border-grey-light;
        display: flex;
        user-select: none;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .el-input {
            width: 200px;
        }
    }

    .inner {
        overflow: scroll;

        .loader {
            padding: 20px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;

                &:hover {
                    svg {
                        display: block;
                    }
                }

                span {
                    flex: 1;
                    user-select: none;
                }

                svg {
                    width: 6px;
                    display: none;
                    opacity: 0.1;
                }
            }
        }
    }
}
</style>
