<template>
    <placeholder-block label="Agreement" :block="block" :can_delete="false" />
</template>

<script>
import PlaceholderBlock from '@/pages/documents/components/PlaceholderBlock';

export default {
    name: 'agreement-block',
    components: {PlaceholderBlock},
    props: {
        project: {
            type: Object,
            default: null,
        },
        is_template: {
            type: Boolean,
            required: true,
        },
        block: {
            type: Object,
            required: true,
        },
    },
};
</script>
