<template>
    <div
        class="document-container"
        @click="() => setActiveBlockId(null)"
        :class="{locked: read_only}"
    >
        <div v-if="requestError" class="error-message">
            <el-alert
                type="error"
                :closable="false"
                title="Unable to edit document"
            >
                {{ requestError }}
            </el-alert>
        </div>
        <template v-else>
            <div class="header">
                <div class="row">
                    <el-button @click="goBack">Close</el-button>

                    <span class="document-label">
                        <span class="doc-type">{{ documentType }}</span>
                        <span v-if="documentDisplayName">
                            Document for {{ documentDisplayName }}
                            <span v-if="document" class="document-name">
                                {{ document.name }}
                            </span>
                        </span>
                        <span v-else> Template</span>
                    </span>
                </div>
                <div class="row">
                    <template v-if="!document">
                        <el-select
                            v-model="template"
                            placeholder="Select template"
                            value-key="id"
                            class="template-selector"
                        >
                            <el-option
                                v-for="t in templateList"
                                :key="t.id"
                                :label="t.name"
                                :value="t"
                            />
                        </el-select>

                        <el-button
                            v-if="linkedRefId"
                            :disabled="!template"
                            @click="handleCreateDocument"
                        >
                            Create document
                        </el-button>
                        <el-button
                            v-else
                            :disabled="!template"
                            @click="handleCreateTemplate"
                        >
                            Create template
                        </el-button>
                    </template>
                    <template v-else>
                        <el-dropdown
                            v-if="!read_only"
                            trigger="click"
                            placement="bottom"
                            style="margin-right: 10px;"
                            @command="addBlock"
                            @click.native.stop.prevent
                        >
                            <el-button>
                                Add
                            </el-button>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-tooltip
                                        v-for="(value,
                                        name) in filteredDocumentBlocks"
                                        :key="name"
                                        :content="value.description"
                                        placement="left"
                                    >
                                        <el-dropdown-item
                                            :key="name"
                                            class="add-option"
                                            :command="name"
                                        >
                                            <span>{{ value.name }}</span>
                                        </el-dropdown-item>
                                    </el-tooltip>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>

                        <template v-if="!isTemplate">
                            <el-tooltip
                                content="Preview PDF"
                                placement="bottom"
                            >
                                <el-button
                                    plain
                                    :icon="
                                        showPreview
                                            ? 'el-icon-s-platform'
                                            : 'el-icon-monitor'
                                    "
                                    style="min-width: 50px;"
                                    @click="() => (showPreview = !showPreview)"
                                />
                            </el-tooltip>

                            <el-tooltip
                                content="Download PDF"
                                placement="bottom"
                            >
                                <el-button
                                    plain
                                    icon="el-icon-download"
                                    style="min-width: 50px; margin-right: 10px;"
                                    @click="downloadPDF"
                                />
                            </el-tooltip>
                        </template>

                        <el-popconfirm
                            v-if="!read_only"
                            :title="`Delete this ${
                                isTemplate ? 'template' : 'document'
                            }?`"
                            @confirm="deleteDocument"
                        >
                            <el-button
                                slot="reference"
                                type="danger"
                                plain
                                icon="el-icon-delete"
                                style="min-width: 50px;"
                            />
                        </el-popconfirm>
                    </template>
                </div>
            </div>
            <div class="split-pane" :class="{'show-preview': showPreview}">
                <div class="document">
                    <template v-if="document">
                        <el-alert
                            v-if="!isValid"
                            type="warning"
                            :closable="false"
                        >
                            This document is incomplete. Please fill in the
                            highlighted fields.
                        </el-alert>

                        <el-row :gutter="20" class="title-row">
                            <el-col>
                                <el-form
                                    ref="docForm"
                                    :model="document"
                                    :rules="rules"
                                    @validate="handleFormValidated"
                                >
                                    <el-form-item
                                        prop="name"
                                        :label="
                                            isTemplate
                                                ? 'Template name'
                                                : 'Document name'
                                        "
                                    >
                                        <el-input
                                            v-model="document.name"
                                            class="document-name-input"
                                            name="name"
                                            type="text"
                                            auto-complete="off"
                                            placeholder="Template name"
                                            :valid="false"
                                            :readonly="read_only"
                                        />
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </el-row>
                        <draggable
                            :disabled="read_only"
                            :list="sortedBlocks"
                            handle=".grip"
                            v-bind="dragOptions"
                            class="content-blocks"
                            @end="updateBlockOrder"
                        >
                            <transition-group>
                                <div
                                    v-for="block in sortedBlocks"
                                    :key="block.id"
                                    :ref="block.id"
                                    class="block-wrapper"
                                    :class="
                                        selectedBlockId === block.id
                                            ? 'selected-block'
                                            : ''
                                    "
                                    @click.stop="
                                        () => setActiveBlockId(block.id)
                                    "
                                >
                                    <component
                                        :is="
                                            DOCUMENT_BLOCKS[block.type]
                                                .component
                                        "
                                        v-if="
                                            DOCUMENT_BLOCKS[block.type]
                                                ?.component
                                        "
                                        :block="block"
                                        :is_template="isTemplate"
                                        :read_only="read_only"
                                        :document="document"
                                        :estimate="linkedRecord"
                                        :project="linkedRecord"
                                        @delete="handleDeleteBlock"
                                        @update="handleUpdateBlock"
                                    />
                                </div>
                            </transition-group>
                        </draggable>
                    </template>
                </div>
                <!-- TODO if more control is required, try PDF.js for preview rendering -->
                <object
                    class="pdf-preview"
                    v-if="showPreview && pdf_data"
                    :data="`${pdf_url}#toolbar=0&navpanes=0`"
                    type="application/pdf"
                ></object>
            </div>
        </template>
    </div>
</template>

<script>
import {generateUUID} from '@/utils/firebase';
import {
    DOCUMENT_BLOCK_TYPES,
    DOCUMENT_SUMMARY_DETAIL as DOCUMENT_SUMMARY_DETAILS,
    DOCUMENT_SUMMARY_TOGGLES,
    DOCUMENT_TYPES,
    DOCUMENT_VALUES,
    ESTIMATE_STATUS,
} from '@/enums';
import debounce from 'lodash.debounce';
import draggable from 'vuedraggable';

import estimateMixin from '@/mixins/estimate.mixin';
import documentMixin from '@/mixins/document.mixin';

import TextBlock from '@/pages/documents/components/TextBlock';
import AiTextBlock from '@/pages/documents/components/AiTextBlock';
import CoverSheetBlock from '@/pages/documents/components/CoverSheetBlock';
import EstimateGroupBlock from '@/pages/documents/components/EstimateGroupBlock';
import EstimateSummaryBlock from '@/pages/documents/components/EstimateSummaryBlock';
import JiraListBlock from '@/pages/documents/components/JiraListBlock';
import ModuleListBlock from '@/pages/documents/components/ModuleListBlock';
import PageBreakBlock from '@/pages/documents/components/PageBreakBlock';
import SummaryCoverSheetBlock from '@/pages/documents/components/SummaryCoverSheetBlock';
import AgreementBlock from '@/pages/documents/components/AgreementBlock';
import {DOCUMENT_BLOCKS} from '@/pages/documents/components/BlockTypes';

import pdfMixin from '@/mixins/pdf.mixin';

export default {
    name: 'document',
    components: {
        AgreementBlock,
        JiraListBlock,
        SummaryCoverSheetBlock,
        AiTextBlock,
        EstimateGroupBlock,
        EstimateSummaryBlock,
        CoverSheetBlock,
        TextBlock,
        PageBreakBlock,
        ModuleListBlock,
        draggable,
    },
    mixins: [estimateMixin, documentMixin, pdfMixin],
    data() {
        return {
            templates: [],
            template: null,
            document: null,
            requestError: null,
            formValid: true,
            documentValid: true,
            dragOptions: {
                animation: 200,
                ghostClass: 'ghost',
                dragClass: 'dragging',
            },
            selectedBlockId: null,
            sortedBlocks: [],
            linkedRecord: null,
            rules: {
                name: [
                    {validator: this.validateDocumentName, trigger: 'change'},
                ],
            },
            showPreview: false,
        };
    },
    computed: {
        DOCUMENT_BLOCKS() {
            return DOCUMENT_BLOCKS;
        },
        filteredDocumentBlocks() {
            return Object.fromEntries(
                Object.entries(DOCUMENT_BLOCKS).filter(
                    ([_, block]) =>
                        block.documentTypes.includes(this.documentType) &&
                        block.canAdd()
                )
            );
        },
        DOCUMENT_BLOCK_TYPES() {
            return DOCUMENT_BLOCK_TYPES;
        },
        documentType() {
            return this.document?.type || this.$route.params.doc_type;
        },
        requestedDocumentId() {
            return this.$route.params.doc_id;
        },
        requestedTemplateId() {
            return this.$route.params.template_id;
        },
        requestedRefId() {
            return this.$route.params.ref_id;
        },
        linkedRefId() {
            // get linked ID from document, otherwise reference from param (if supplied)
            if (this.document?.linked_ref) {
                return this.$options.filters.fireRef2id(
                    this.document.linked_ref
                );
            }
            return this.requestedRefId;
        },
        documentDisplayName() {
            if (this.linkedRecord?.name) {
                return this.linkedRecord.name;
            } else if (this.linkedRefId) {
                return this.linkedRefId;
            }
            return null;
        },
        linkedReference() {
            if (this.linkedRefId) {
                return this.$fire.doc(
                    `${this.documentTypeCollection}/${this.linkedRefId}`
                );
            }
            return null;
        },
        isTemplate() {
            return !this.linkedRefId;
        },
        read_only() {
            if (
                !this.isTemplate &&
                this.documentType === DOCUMENT_TYPES.ESTIMATE
            ) {
                if (this.linkedRecord) {
                    if (
                        this.linkedRecord.status === ESTIMATE_STATUS.SENT ||
                        this.linkedRecord.status === ESTIMATE_STATUS.ACCEPTED ||
                        this.linkedRecord.status === ESTIMATE_STATUS.DECLINED
                    ) {
                        // read only if estimate is locked
                        return true;
                    }
                    // estimate is in editable state
                    return false;
                }
                // read only until estimate is loaded
                return true;
            }
            // non-estimate documents are always editable for now
            return false;
        },
        documentReference() {
            if (this.document) {
                if (this.isTemplate) {
                    return this.$fire.doc(
                        `system/${this.documentTypeCollection}/document_templates/${this.document.id}`
                    );
                }
                return this.$fire.doc(`documents/${this.document.id}`);
            }
            return null;
        },
        blankTemplateName() {
            return `Bare ${this.documentType}`;
        },
        blankTemplateListEntry() {
            return {id: 0, name: `( ${this.blankTemplateName} )`};
        },
        templateList() {
            const list = this.sortTemplates(this.templates);
            list.push(this.blankTemplateListEntry);
            return list;
        },
        blankTemplate() {
            switch (this.documentType) {
                case DOCUMENT_TYPES.ESTIMATE: {
                    return {
                        type: DOCUMENT_TYPES.ESTIMATE,
                        name: '',
                        blocks: [
                            {
                                type: DOCUMENT_BLOCK_TYPES.COVER_SHEET,
                                id: generateUUID(),
                            },
                            {
                                type: DOCUMENT_BLOCK_TYPES.ESTIMATE_GROUP,
                                id: generateUUID(),
                                values: [],
                            },
                            {
                                type: DOCUMENT_BLOCK_TYPES.ESTIMATE_SUMMARY,
                                id: generateUUID(),
                                values: [],
                            },
                            {
                                type: DOCUMENT_BLOCK_TYPES.AGREEMENT,
                                id: generateUUID(),
                            },
                        ],
                    };
                }
                case DOCUMENT_TYPES.SUMMARY: {
                    return {
                        type: DOCUMENT_TYPES.SUMMARY,
                        name: '',
                        blocks: [
                            {
                                type: DOCUMENT_BLOCK_TYPES.SUMMARY_COVER_SHEET,
                                id: generateUUID(),
                            },
                        ],
                    };
                }
            }
            return null;
        },
        isValid() {
            return this.formValid && this.documentValid;
        },
        pdf_url() {
            if (this.pdf_data) {
                return `data:application/pdf;base64,${this.pdf_data}`;
            }
            return null;
        },
    },
    watch: {
        requestError(val) {
            if (val) {
                setTimeout(() => {
                    this.goBack();
                }, 3000);
            }
        },
        documentType: {
            handler(docType) {
                if (docType) {
                    // check if doctype is a valid type
                    if (!Object.values(DOCUMENT_TYPES).includes(docType)) {
                        this.requestError = 'Invalid document type requested';
                    }

                    this.$bind(
                        'templates',
                        this.$fire.collection(
                            `system/${this.documentTypeCollection}/document_templates`
                        ),
                        {maxRefDepth: 0}
                    );
                }
            },
            immediate: true,
        },
        requestedDocumentId: {
            async handler(id) {
                if (id) {
                    await this.$bind(
                        'document',
                        this.$fire.doc(`documents/${id}`),
                        {maxRefDepth: 0}
                    );
                    if (!this.document) {
                        this.requestError = 'Invalid document request';
                    } else {
                        await this.getLinkedRecord();
                        this.validateDocument();
                    }
                }
            },
            immediate: true,
        },
        requestedTemplateId: {
            async handler(id) {
                if (id && this.documentType) {
                    await this.$bind(
                        'document',
                        this.$fire.doc(
                            `system/${this.documentTypeCollection}/document_templates/${id}`
                        ),
                        {maxRefDepth: 0}
                    );
                    if (!this.document) {
                        this.requestError = 'Invalid template requested';
                    } else {
                        this.validateDocument();
                    }
                }
            },
            immediate: true,
        },
        requestedRefId: {
            async handler(id) {
                if (id && this.documentType) {
                    await this.getLinkedRecord();
                    if (!this.linkedRecord) {
                        this.requestError =
                            'Invalid document reference requested';
                    }
                }
            },
            immediate: true,
        },
        templateList: {
            handler(list) {
                // automatically select the blank template if it is the only option
                if (list) {
                    if (list.length > 1) {
                        this.template = null;
                    } else {
                        this.template = list[0];
                    }
                }
            },
            immediate: true,
        },
        'document.blocks'(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.sortedBlocks = newVal;
            }
        },

        showPreview(val) {
            if (val) {
                if (!this.pdf_data) {
                    this.generateDocumentPDF(this.document, false);
                }
            } else {
                this.pdf_data = false;
            }
        },
        nested_estimate(val) {
            if (val) {
                // if nested_estimate has been bound, linkedRecord is an estimate
                // keep linkedRecord in sync with updated to nested_estimate
                this.linkedRecord = val;
                // regenerate preview if visible
                if (this.showPreview) {
                    this.generatePreview();
                }
            }
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async getLinkedRecord() {
            if (this.documentType && this.linkedRefId) {
                // get new data if record hasn't been loaded or ID has changed
                if (
                    !this.linkedRecord ||
                    this.linkedRecord.id !== this.linkedRefId
                ) {
                    // return the data structure matching the doctype
                    switch (this.documentType) {
                        case DOCUMENT_TYPES.ESTIMATE:
                            await this.bindNestedEstimate(this.linkedRefId);
                            // linkedRecord will stay updated with bound estimate
                            break;
                        case DOCUMENT_TYPES.SUMMARY:
                            this.linkedRecord = await this.getProject(
                                this.linkedRefId
                            );
                    }
                }
            } else {
                console.log(
                    '- not available',
                    this.documentType,
                    this.linkedRefId
                );
                this.linkedRecord = null;
            }
            return this.linkedRecord;
        },
        async deleteDocument() {
            this.documentReference.delete();
            this.goBack();
        },
        handleFormValidated(form, valid) {
            this.formValid = valid;
        },
        validateDocument() {
            // validate form to highlight top level form elements
            // (blocks are responsible for their own highlighting)
            this.$refs.docForm?.validate().catch(() => {});
            this.documentValid = !this.document.blocks.some(
                (b) => !DOCUMENT_BLOCKS[b.type]?.isValid(b, this.isTemplate)
            );

            if (this.isValid !== this.document.valid) {
                this.updateValidState();
            }

            if (this.showPreview) {
                this.generatePreview();
            }
        },
        setActiveBlockId(id) {
            this.selectedBlockId = id;
        },
        async handleCreateDocument() {
            let docTemplate = this.template;
            if (!docTemplate?.id) {
                docTemplate = this.blankTemplate;
            }
            await this.createDocumentFromTemplate(docTemplate);
        },
        async handleCreateTemplate() {
            let srcTemplate = {...this.template};
            if (!srcTemplate.id) {
                // template not selected, use blank
                srcTemplate = this.blankTemplate;
            } else {
                // strip ID from selected template
                delete srcTemplate.id;
            }
            await this.createTemplate(srcTemplate);
        },
        async addBlock(blockType) {
            let newBlock = {
                id: generateUUID(),
                type: blockType,
            };

            newBlock = {
                ...newBlock,
                ...DOCUMENT_BLOCKS[blockType].defaultState,
            };
            let index = -1;
            if (this.selectedBlockId) {
                index = this.sortedBlocks.findIndex(
                    (sortedBlock) => sortedBlock.id === this.selectedBlockId
                );
            }
            const newBlocks = this.sortedBlocks.toSpliced(
                index + 1,
                0,
                newBlock
            );

            await this.documentReference
                .update({
                    blocks: newBlocks,
                })
                .then(() => {
                    this.setActiveBlockId(newBlock.id);
                    this.$nextTick(() => {
                        this.$refs[newBlock.id][0].scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                        });
                    });

                    this.validateDocument();
                });
        },
        handleUpdateBlock(block) {
            const newBlocks = [...this.sortedBlocks];
            const blockIndex = newBlocks.findIndex((b) => b.id === block.id);
            if (blockIndex >= 0) {
                newBlocks[blockIndex] = block;
                this.updateBlocks(newBlocks);
            }
        },
        async handleDeleteBlock(blockId) {
            // remove the block (using record as currently stored, potential changes will be discarded)
            const newBlocks = this.document.blocks.filter(
                (block) => block.id !== blockId
            );

            await this.documentReference.update({
                blocks: newBlocks,
            });

            this.validateDocument();
        },
        async createDocumentFromTemplate(docTemplate) {
            // Create a document of the same type as the template,
            // containing all the blocks defined in the template.

            //todo: handle project as well as estimate here
            const linked = await this.getLinkedRecord();

            let docName = linked?.ref;
            if (docTemplate.name) docName += ` - ${docTemplate.name}`;

            const newDocument = {
                name: docName,
                type: this.documentType,
                linked_ref: this.linkedReference,
                created_at: new Date(),
                blocks: [],
                valid: true,
            };

            for (const block of docTemplate.blocks) {
                //todo: find a meaningful way to move this into the DOCUMENT_BLOCKS enum.
                switch (block.type) {
                    // Blocks replaced with custom processed content:
                    case DOCUMENT_BLOCK_TYPES.ESTIMATE_GROUPS:
                    case DOCUMENT_BLOCK_TYPES.ESTIMATE_GROUP:
                        // Get nested estimate record
                        const estimate = await this.getLinkedRecord();

                        estimate.groups.forEach((group) => {
                            // add a block for each group in the estimate
                            newDocument.blocks.push({
                                id: generateUUID(),
                                type: DOCUMENT_BLOCK_TYPES.ESTIMATE_GROUP,
                                group: this.$fire.doc(
                                    `estimate_groups/${group.id}`
                                ),
                                values: [],
                            });
                        });
                        break;
                    case DOCUMENT_BLOCK_TYPES.ESTIMATE_SUMMARY:
                        // always use default summary options in new documents
                        newDocument.blocks.push({
                            ...block,
                            values: [
                                DOCUMENT_SUMMARY_DETAILS.COST,
                                DOCUMENT_SUMMARY_TOGGLES.GST,
                            ],
                        });
                        break;

                    // All other blocks copied as-is from the template:
                    default:
                        newDocument.blocks.push({...block, id: generateUUID()});
                        break;
                }
            }

            const newDocumentRef = await this.$fire
                .collection('documents')
                .add(newDocument);

            // update URL to reflect new document but do not alter routing state
            history.replaceState({}, null, `/document/${newDocumentRef.id}`);

            await this.$bind('document', newDocumentRef, {maxRefDepth: 0});

            // immediately validate in case template has incomplete values
            this.validateDocument();
        },
        async createTemplate(srcTemplate) {
            const newTemplateRef = await this.$fire
                .collection(
                    `system/${this.documentTypeCollection}/document_templates`
                )
                .add(srcTemplate);

            // update URL to reflect new document but do not alter routing state
            history.replaceState(
                {},
                null,
                `/document/${this.documentType}/template/${newTemplateRef.id}`
            );

            await this.$bind('document', newTemplateRef, {maxRefDepth: 0});

            // immediately validate in case name is duplicated
            this.validateDocument();
        },
        async updateBlocks(blocks) {
            await this.documentReference.update({
                updated_at: new Date(),
                blocks,
            });

            this.validateDocument();
        },
        validateDocumentName(rule, value, callback) {
            this.updateTemplateName(value);
            if (value === '') {
                callback(
                    `${
                        this.isTemplate ? 'Template' : 'Document'
                    } name must be provided`
                );
                return;
            }
            if (this.isTemplate) {
                // check if this name is already in use
                if (
                    this.templates &&
                    this.templates.some(
                        (t) => t.id !== this.document.id && t.name === value
                    )
                ) {
                    callback('This template name is already in use');
                    return;
                }
            }
            callback();
        },
        updateTemplateName: debounce(function (name) {
            this.documentReference.update({updated_at: new Date(), name});
        }, 500),
        updateBlockOrder() {
            // after changing block order, update document with new order
            this.updateBlocks(this.sortedBlocks);
        },
        updateValidState() {
            this.documentReference.update({valid: this.isValid});
        },
        getProject(linkedRefId) {
            return this.$store.getters.projectWithId(linkedRefId);
        },

        generatePreview: debounce(function () {
            this.generateDocumentPDF(this.document, false);
        }, 1000),

        downloadPDF() {
            if (!this.pdf_data) {
                // generate PDF and download
                this.generateDocumentPDF(this.document);
            } else {
                // download already generated PDF
                const downloadLink = document.createElement('a');
                const fileName = `${this.document.name}.pdf`;
                downloadLink.href = this.pdf_url;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.document-container {
    padding: 0 10px 10px;

    .error-message {
        padding: 20px;
    }

    .header {
        position: sticky;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        top: 0;
        z-index: 1000;
        background-color: $grey;
        border-bottom: 1px solid $border-grey-light;

        .document-label {
            margin-left: 15px;

            .doc-type {
                text-transform: capitalize;
            }

            .document-name {
                font-weight: bold;
                margin-left: 20px;
            }
        }

        .row {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
        }

        .add-block {
            display: flex;
            align-items: center;
            padding: 4px 4px 4px 10px;
            margin-right: 20px;
            background-color: rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.5);
            cursor: pointer;

            span {
                flex: 1;
                margin-right: 8px;
            }

            .el-button {
                min-width: auto;
            }
        }

        .el-button {
            min-width: 100px;
        }

        .template-selector {
            margin-right: 10px;
            width: 250px;
        }
    }

    .split-pane {
        --preview-width: 500px;
        .document {
            display: inline-block;
            width: calc(100% - 40px);
            padding: 20px;
            transition: width 0.25s ease-in;

            .title-row {
                margin-top: 10px;
            }

            .content-blocks {
                .selected-block > .el-card {
                    border: 1px solid rgba($blue, 0.5);
                }

                .content-block {
                    margin-bottom: 10px;
                }
            }

            .document-name-input {
                ::v-deep .el-input__inner {
                    font-weight: bold;
                    font-size: 18px;
                }
            }
        }

        &.show-preview .document {
            transition: width 0.5s ease-out;
            width: calc(100% - var(--preview-width) - 50px);
        }

        .pdf-preview {
            border: 1px solid $border-grey;
            vertical-align: top;
            display: inline-block;
            position: sticky;
            top: 80px;
            width: var(--preview-width);
            height: calc(100vh - 100px);
        }
    }

    &.locked {
        ::v-deep .grip {
            cursor: default;
        }
    }
}

.ghost {
    opacity: 0.3;
}

.dragging {
    cursor: grabbing;
}

.add-option {
    display: flex;
    line-height: 20px;
    padding: 5px 10px;
    font-size: 30px;

    span {
        font-size: 13px;
        margin-left: 5px;
    }
}
</style>
