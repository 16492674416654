<template>
    <el-dialog
        v-if="
            !$store.getters.is_feature_enabled(SUPPORTED_FEATURES.GOOGLE_AUTH)
        "
        :visible="show || show_password_dialog_override"
        width="45%"
        :close-on-click-modal="can_close"
        :close-on-press-escape="can_close"
        :show-close="can_close"
        @close="onCloseDialog"
        @update:show="(val) => (show = val)"
    >
        <template #title>
            <label>
                Change your password
            </label>
            <hr />
        </template>
        <el-alert type="error" :closable="false" class="warning">
            After changing your password, you will be required to log in again.
        </el-alert>
        <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="200px"
            class="form-wrapper"
        >
            <el-form-item label="New Password" prop="new_password">
                <el-input
                    v-model="form.new_password"
                    name="New Password"
                    type="password"
                    auto-complete="off"
                    placeholder="New password"
                    @change="validatePasswords"
                />
            </el-form-item>
            <el-form-item label="Confirm Password" prop="confirm_password">
                <el-input
                    v-model="form.confirm_password"
                    name="Confirm Password"
                    type="password"
                    auto-complete="off"
                    placeholder="Confirm password"
                    @change="validatePasswords"
                />
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button
                class="create-button"
                :disabled="updating"
                @click="handleSubmit"
            >
                Update Password
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import userMixin from '@/mixins/user.mixin';
import {validatePassword} from '@/utils/validate';
import {changePassword} from '@/api/user';
import {Notification} from 'element-ui';
import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';

export default {
    name: 'change-password-modal',
    components: {},
    mixins: [userMixin],
    props: {
        show_dialog: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            form: {
                new_password: null,
                confirm_password: null,
            },
            show: this.show_dialog,
            updating: false,
            rules: {
                new_password: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: this.validateNewPassword,
                    },
                ],
                confirm_password: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: this.validateNewPassword,
                    },
                ],
            },
        };
    },
    computed: {
        SUPPORTED_FEATURES() {
            return SUPPORTED_FEATURES;
        },
        can_close() {
            return !this.user_needs_password_change;
        },
        show_password_dialog_override() {
            return this.user_needs_password_change;
        },
    },
    watch: {
        show_dialog: function (val) {
            this.show = val;
        },
    },
    methods: {
        validateNewPassword(rule, value, callback) {
            const errString = validatePassword(this.form[rule.field]);
            if (errString) {
                callback(new Error(errString));
            } else if (this.form.new_password && this.form.confirm_password) {
                if (this.form.new_password === this.form.confirm_password) {
                    callback();
                } else {
                    callback(
                        new Error("New and confirm passwords don't match!")
                    );
                }
            } else {
                callback();
            }
        },
        validatePasswords() {
            this.$refs.form.validate((valid) => {});
        },
        onCloseDialog() {
            this.$refs.form.fields.forEach((field) => field.resetField());
            this.$emit('update:show_dialog', false);
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.updating = true;

                    changePassword(this.form.new_password)
                        .then((res) => {
                            this.show = false;
                            this.$store.dispatch('Refresh', true);
                        })
                        .catch((err) => {
                            Notification({
                                type: 'error',
                                title: 'Error updating password.',
                                message: err.message,
                            });
                        })
                        .finally(() => {
                            this.updating = false;
                            this.$router.push({
                                name: 'logout',
                            });
                        });
                }
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
label {
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
    font-weight: 600;
    flex: 1;
}

::v-deep .el-dialog {
    .el-dialog__header {
        label {
            font-weight: 400;
        }
        hr {
            width: 100%;
            margin: 15px 0;
            border: none;
            height: 1px;
            background-color: $border-grey-light;
        }
    }
    .el-dialog__body {
        padding: 0 20px;
        .warning {
            margin-bottom: 20px;
        }
    }
}
</style>
