<template>
    <div>
        <content-block>
            <project-timeline
                ref="timeline_ref"
                :project_ids="[$route.params.project_id]"
                :milestones="milestones"
            />
        </content-block>
        <project-milestone
            :project_id="$route.params.project_id"
            :milestones="milestones"
            :types="event_types"
            @click:milestone="milestoneClick"
        />
    </div>
</template>

<script>
import ProjectTimeline from '@/components/timeline/ProjectTimeline';
import ContentBlock from '@/components/blocks/ContentBlock';
import ProjectMilestone from '@/components/timeline/ProjectMilestone';

export default {
    name: 'project-tab-timeline',
    components: {
        ContentBlock,
        ProjectTimeline,
        ProjectMilestone,
    },
    data() {
        return {
            event_types: ['milestone'],
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },

        types() {
            return this.eventTypes.filter((type) =>
                this.event_types.includes(type.type)
            );
        },

        milestones() {
            const typeIds = this.types.map(
                (type) => `system/events/types/${type.id}`
            );

            const ms = this.$store.getters
                .eventsForProjectWithId(this.$route.params.project_id)
                .filter((ev) => typeIds.includes(ev.type));

            return ms.slice(0).sort((a, b) => {
                if (a.start.toDate() > b.start.toDate()) return -1;
                return 1;
            });
        },
        eventTypes() {
            return this.$store.getters.eventTypes();
        },
    },

    methods: {
        milestoneClick(milestone) {
            if (this.$refs.timeline_ref) {
                this.$refs.timeline_ref.timeline.focus(milestone.id);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.content-block {
    margin-bottom: 10px;
}
</style>
