<template>
    <div v-if="estimate">
        <div class="header">
            <el-button @click="goBackAction">Back</el-button>
            <span class="ref" v-if="!is_template">
                /
                <span> {{ estimate.ref }}</span>
            </span>
            <template v-else>
                <el-alert
                    show-icon
                    type="info"
                    :closable="false"
                    title="Changes to this template only apply to new estimates, and will not affect any existing estimates"
                    class="template-banner"
                />
                <el-popconfirm
                    title="Delete this template?"
                    @confirm="handleDeleteEstimate"
                >
                    <el-button plain type="danger" slot="reference">
                        Delete
                    </el-button>
                </el-popconfirm>
            </template>
        </div>

        <hr />

        <el-alert
            v-if="is_estimate_accepted"
            class="status_banner"
            type="success"
            :closable="false"
            title="Accepted"
        />

        <el-alert
            v-if="is_estimate_declined"
            class="status_banner"
            type="error"
            :closable="false"
            title="Declined"
        />

        <Estimate__form
            v-if="editing"
            :estimate="estimate"
            @saved="closeEdit"
            @cancel="closeEdit"
        />

        <content-block
            v-else-if="estimate"
            :title="estimate.title"
            class="estimate-details"
        >
            <div v-if="estimate.description" :key="increment">
                {{ estimate.description }}
            </div>
            <el-alert
                v-if="!is_estimate_draft && !estimate.client"
                class="error-alert"
                type="error"
                center
                title="Estimate cannot be finalised until a client is selected. Click 'Edit' above to adjust estimate details."
                :closable="false"
            />

            <div slot="right" style="float: right;">
                <el-button
                    v-if="estimate.change_request"
                    size="small"
                    type="success"
                    plain
                    @click="handleViewCR"
                >
                    <i class="el-icon-link" />
                    {{ estimate.change_request | fireRef2id }}
                </el-button>

                <el-button
                    size="small"
                    :disabled="editing || finalising"
                    @click="handleEdit"
                >
                    <span v-if="is_estimate_locked">View Details</span>
                    <span v-else>Edit</span>
                </el-button>
                <el-button
                    v-if="!is_template && is_estimate_draft"
                    size="small"
                    type="info"
                    plain
                    @click="handleSubmitForReview"
                >
                    Submit for review
                </el-button>

                <el-button
                    v-if="is_estimate_pending"
                    size="small"
                    type="warning"
                    plain
                    @click="handleSetStatus(ESTIMATE_STATUS.DRAFT)"
                >
                    Return to draft
                </el-button>
            </div>
        </content-block>

        <hr style="margin: 20px 0;" />

        <div v-if="!is_estimate_draft" class="group-toolbar">
            <div class="subtask-toggle">
                Show subtasks
                <el-switch v-model="showSubtasks" />
            </div>
        </div>

        <draggable
            id="estimate-groups"
            :class="{sortable: multiple_groups && !is_estimate_locked}"
            :list="sortingGroups"
            v-bind="dragOptions"
            handle=".grip"
            :animation="100"
            :disabled="!multiple_groups || is_estimate_locked"
            @end="updateGroupOrder"
        >
            <transition-group>
                <Estimate__group
                    v-for="group in sortingGroups"
                    :key="group.id"
                    :estimate="estimate"
                    :group="group"
                    :tasks="groupTasks(group.id)"
                    :show_subtasks="!is_estimate_draft && showSubtasks"
                    :can_delete="multiple_groups"
                    :selected_task="selectedTask"
                    @rename="handleRenameGroup"
                    @delete="handleDeleteGroup"
                    @exclude="handleExcludeGroup"
                    @saveTask="handleSaveTask"
                    @deleteTask="handleDeleteTask"
                    @excludeTask="handleExcludeTask"
                    @selectTask="handleSelectTask"
                    @saveSubtask="handleSaveSubtask"
                    @removeSubtask="handleRemoveSubtask"
                    @deleteSubtask="handleDeleteSubtask"
                    @excludeSubtask="handleExcludeSubtask"
                    @newGroupAbove="handleClickOnNewGroupAbove(group)"
                    @newGroupBelow="handleClickOnNewGroupBelow(group)"
                />
            </transition-group>
        </draggable>

        <div class="estimate-footer" :class="{tracking: show_tracking_bar}">
            <div class="footer-total-section">
                <template v-only-super-admin v-if="is_estimate_pending">
                    <div
                        class="credit-toggle"
                        :disabled="credit.label || credit.value"
                    >
                        <el-switch v-model="credit.enabled" />
                        <span class="credit-toggle-label">Include credit</span>

                        <template v-if="credit.enabled">
                            <el-input
                                v-model="credit.label"
                                class="credit-label"
                                size="small"
                                placeholder="Credit description"
                            />
                            <money
                                ref="input_credit"
                                v-model="credit.value"
                                v-bind="money"
                                class="credit-value el-input__inner"
                            />
                        </template>
                    </div>
                </template>
                <div class="footer-totals">
                    <Estimate__totals
                        :estimate="estimate"
                        :tasks="sorted_tasks"
                        :groups="groups"
                        :credit="credit"
                    />
                </div>
            </div>

            <!-- DOCUMENT-->
            <template>
                <hr />
                <div class="button-bar">
                    <div class="buttons-left">
                        <el-button
                            v-if="!this.is_estimate_locked"
                            @click="createDocument"
                        >
                            Create document
                        </el-button>
                    </div>
                    <div class="buttons-right">
                        <div class="document-list">
                            <template v-if="documents.length">
                                <div
                                    v-for="doc in documents"
                                    :key="doc.id"
                                    class="document-row"
                                >
                                    <el-button
                                        :icon="getDocIcon(doc)"
                                        size="small"
                                        class="document-button"
                                        :plain="!doc.valid"
                                        :type="doc.valid ? null : 'warning'"
                                        @click="() => editDocument(doc)"
                                    >
                                        {{ doc.name }}
                                    </el-button>
                                    <el-button
                                        size="small"
                                        icon="el-icon-download"
                                        :disabled="!doc.valid"
                                        @click="() => generateDocumentPDF(doc)"
                                    />
                                    <el-popconfirm
                                        v-if="!is_estimate_locked"
                                        class="document-delete-confirm"
                                        title="Delete this document?"
                                        @confirm="() => deleteDocument(doc)"
                                    >
                                        <el-button
                                            slot="reference"
                                            size="small"
                                            type="danger"
                                            plain
                                            icon="el-icon-delete"
                                        />
                                    </el-popconfirm>
                                </div>
                            </template>
                            <el-alert
                                v-else
                                title="No documents have been created."
                                :closable="false"
                            />
                        </div>
                    </div>
                </div>
            </template>

            <!-- ESTIMATE FOOTER -->
            <hr />
            <div class="button-bar">
                <div class="buttons-left">
                    <el-select
                        v-if="!is_estimate_draft && !no_exclusions"
                        v-model="copy_type"
                        class="primary-selector"
                    >
                        <el-option value="full" label="Full copy" />
                        <el-option
                            value="included"
                            label="Copy only included"
                            :disabled="all_groups_excluded"
                        />
                        <el-option
                            value="excluded"
                            label="Copy only excluded"
                        />
                    </el-select>
                    <el-button
                        type="info"
                        plain
                        :disabled="finalising"
                        @click="handleCloneEstimate"
                    >
                        Create a copy
                    </el-button>
                </div>

                <div class="buttons-right">
                    <!-- DRAFT -->

                    <el-button
                        v-if="!is_template && is_estimate_draft"
                        type="info"
                        plain
                        @click="handleSubmitForReview"
                    >
                        Submit for review
                    </el-button>

                    <!-- REVIEW -->

                    <el-button
                        v-if="!is_estimate_draft && !estimate.payment_voided"
                        plain
                        @click="handleViewPaymentPlan"
                    >
                        <span>
                            {{
                                payment_plan_needs_planning
                                    ? 'Prepare payment plan'
                                    : 'View payment plan'
                            }}
                        </span>
                    </el-button>

                    <el-tooltip
                        placement="top"
                        :content="mark_sent_tooltip"
                        :disabled="!mark_sent_tooltip"
                    >
                        <el-button
                            v-if="is_estimate_pending && can_approve"
                            v-loading.fullscreen.lock="finalising"
                            element-loading-text="Submitting quote to Xero..."
                            :disabled="
                                all_groups_excluded ||
                                !estimate.client ||
                                finalising ||
                                payment_plan_needs_planning
                            "
                            type="info"
                            plain
                            @click="handleFinaliseEstimate"
                        >
                            Mark as sent
                        </el-button>
                    </el-tooltip>

                    <!-- SENT -->

                    <template v-if="is_estimate_sent && can_approve">
                        <el-button
                            type="danger"
                            plain
                            :disabled="finalising"
                            @click="handleDeclineEstimate"
                        >
                            Decline
                        </el-button>
                        <el-button
                            type="success"
                            plain
                            :disabled="finalising"
                            @click="handleAcceptEstimate"
                        >
                            Accept
                        </el-button>
                    </template>

                    <!-- ACCEPTED -->

                    <template v-if="is_estimate_accepted">
                        <el-button plain @click="handleViewComponentsLinking">
                            <span v-if="estimate.ticketed">
                                Components
                            </span>
                            <span v-else>
                                Link to components
                            </span>
                        </el-button>
                    </template>

                    <el-button
                        v-if="is_estimate_declined"
                        type="warning"
                        plain
                        @click="handleRestoreForReview"
                    >
                        Restore for review
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';

import ContentBlock from '@/components/blocks/ContentBlock';

import {
    DOCUMENT_TYPES,
    ESTIMATE_STATUS,
    PAYMENT_PLAN_STATUS,
    XERO_QUOTE_STATUS,
} from '@/enums';

import Estimate__form from './components/Estimate_form';
import Estimate__group from './components/Estimate_group';
import Estimate__totals from './components/Estimate_totals';

import estimateMixin from '@/mixins/estimate.mixin';
import estimateTaskMixin from '@/mixins/estimate.task.mixin';
import documentMixin from '@/mixins/document.mixin';
import paymentplanMixin from '@/mixins/paymentplan.mixin';

import moment from 'moment';

import draggable from 'vuedraggable';

import pdfMixin from '@/mixins/pdf.mixin';

import {updateXeroQuoteStatus} from '@/api/quotes';
import {submitEstimateToXero} from '@/api/estimates';
import {Notification} from 'element-ui';
import {fireRef2id} from '@/filters';

import * as Sentry from '@sentry/vue';

export default {
    name: 'estimate-detail',
    components: {
        ContentBlock,
        Estimate__form,
        Estimate__group,
        Estimate__totals,
        draggable,
    },
    mixins: [
        estimateMixin,
        estimateTaskMixin,
        pdfMixin,
        documentMixin,
        paymentplanMixin,
    ],
    data() {
        return {
            ESTIMATE_STATUS,
            estimate: null,
            editing: false,
            groups: [],
            tasks: [],
            subtasks: [],
            loading: false,
            finalising: false,
            dragOptions: {
                animation: 200,
                ghostClass: 'ghost',
                dragClass: 'dragging',
            },
            sortingGroups: [],
            showSubtasks: false,
            increment: 1,
            showTemplateConfirm: false,
            templateName: '',
            pdf_type: 'basic',
            pdf_excluded: 0,
            copy_type: 'full',
            selectedTask: null,
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
            },
            documents: [],
            payment_plan_invoices: [],
            credit: {
                enabled: false,
                label: '',
                value: 0,
            },
        };
    },
    computed: {
        is_template() {
            return this.estimate.is_template;
        },
        estimate_id() {
            return this.$route.params.estimate_id;
        },
        project() {
            if (!this.$route.params.project_id) return null;
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        product_owner() {
            if (this.project) {
                for (const team_member of this.project.team) {
                    const role = this.$store.getters.userRoleWithId(
                        this.$options.filters.fireRef2id(team_member.role)
                    );
                    if (role === this.product_owner_role)
                        return this.$store.getters.userWithId(
                            this.$options.filters.fireRef2id(team_member.user)
                        );
                }
            }
            return null;
        },
        stages() {
            if (!this.$route.params.project_id) return null;
            return this.$store.getters.stagesForProjectWithId(
                this.$route.params.project_id
            );
        },
        show_tracking_bar() {
            return this.$store.getters.show_tracking_bar;
        },
        multiple_groups() {
            return this.sortingGroups.length > 1;
        },
        sorted_groups() {
            return [...this.groups].sort((a, b) => {
                return a.sort - b.sort;
            });
        },
        groups_with_included_items() {
            return this.sorted_groups.filter((g) => {
                // don't add excluded groups
                if (g.excluded) return false;
                // if not excluded, check group for any included tasks
                // (groups with no included tasks are considered excluded)
                return this.groupTasks(g.id).find((t) => {
                    if (t.excluded) return false;
                    // if task is not excluded, check for any included subtasks
                    // (tasks with no included subtasks are considered excluded)
                    return t.subtasks.find((s) => !s.excluded);
                });
            });
        },
        groups_with_excluded_items() {
            return this.sorted_groups.filter((g) => {
                // always add excluded groups
                if (g.excluded) return true;
                // if not excluded, check if group has any excluded tasks
                return this.groupTasks(g.id).find((t) => {
                    if (t.excluded) return true;
                    // if task is not excluded, check for any excluded subtasks
                    return t.subtasks.find((s) => s.excluded);
                });
            });
        },
        sorted_tasks() {
            // sorted tasks list, with injected subtasks
            return this.tasks
                .map((t) => {
                    return {
                        ...t,
                        id: t.id,
                        subtasks: this.subtasks.filter(
                            (s) => t.id === fireRef2id(s.task)
                        ),
                    };
                })
                .sort((a, b) => {
                    return a.sort - b.sort;
                });
        },
        all_groups_excluded() {
            for (const group of this.groups) {
                if (!this.isGroupExcluded(group, this.groupTasks(group.id))) {
                    return false;
                }
            }
            return true;
        },
        no_exclusions() {
            if (this.groups.find((g) => g.excluded)) return false;
            if (this.tasks.find((t) => t.excluded)) return false;
            if (this.subtasks.find((s) => s.excluded)) return false;
            return true;
        },
        payment_plan_status() {
            return this.getPaymentPlanStatus(this.estimate);
        },
        payment_plan_needs_planning() {
            return (
                !this.estimate.payment_voided &&
                (this.payment_plan_status === PAYMENT_PLAN_STATUS.PENDING ||
                    this.payment_plan_status ===
                        PAYMENT_PLAN_STATUS.IN_PROGRESS)
            );
        },

        mark_sent_tooltip() {
            if (this.all_groups_excluded) {
                return 'No groups included in estimate';
            }
            if (!this.estimate.client) {
                return 'Client must be set';
            }
            if (this.payment_plan_needs_planning) {
                return 'Payment plan must be prepared first';
            }
            return null;
        },
    },
    watch: {
        estimate_id() {
            this.init();
        },
        estimate: {
            handler(newEst, oldEst) {
                if (newEst && newEst !== oldEst) {
                    this.validateEstimate();

                    const estRef = this.$fire.doc(`estimates/${newEst.id}`);
                    // get documents for this estimate
                    this.$bind(
                        'documents',
                        this.$fire
                            .collection('documents')
                            .where('linked_ref', '==', estRef)
                            .orderBy('created_at'),
                        {maxRefDepth: 0}
                    );
                }
            },
        },
        groups: {
            handler(val) {
                this.sortingGroups = val
                    .slice(0)
                    .sort((a, b) => a.sort - b.sort);

                this.updatePendingCost();
            },
            immediate: true,
        },
        tasks: {
            handler(val) {
                this.updatePendingCost();
            },
        },
        'estimate.title': {
            handler(val) {
                this.templateName = val;
            },
        },
        'estimate.status': {
            async handler(val) {
                if (
                    val === ESTIMATE_STATUS.PENDING ||
                    val === ESTIMATE_STATUS.SENT ||
                    val === ESTIMATE_STATUS.ACCEPTED
                ) {
                    // get payment plan invoices to determine paid status
                    this.$bind(
                        'payment_plan_invoices',
                        this.$fire
                            .collection('payment_plan_invoices')
                            .where(
                                'estimate',
                                '==',
                                this.$fire.doc(`estimates/${this.estimate.id}`)
                            ),
                        {maxRefDepth: 0}
                    );
                }
            },
            immediate: true,
        },
        showSubtasks() {
            this.selectedTask = null;
        },
        'credit.enabled': {
            handler(enabled) {
                if (!enabled) {
                    this.$store.dispatch('setEstimateCredit', {
                        estimate_id: this.estimate.id,
                        label: '',
                        value: 0,
                    });
                }
            },
        },
        'credit.label': {
            handler() {
                this.updateCredit();
            },
        },
        'credit.value': {
            handler() {
                this.updateCredit();
            },
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        /* INIT */
        async init() {
            this.loading = true;

            // TODO: Move these binding to store - to avoid to do it in multiple places

            await this.$bind(
                'estimate',
                this.$fire.doc(`estimates/${this.estimate_id}`),
                {
                    maxRefDepth: 0,
                }
            );

            if (!this.estimate) {
                // No estimate found for this ID, return to estimate list
                this.goBackAction();
                return;
            }

            await this.$bind(
                'groups',
                this.$fire
                    .collection('estimate_groups')
                    .where(
                        'estimate',
                        '==',
                        this.$fire.doc(`estimates/${this.estimate.id}`)
                    ),
                {
                    maxRefDepth: 0,
                }
            );

            await this.$bind(
                'tasks',
                this.$fire
                    .collection('estimate_tasks')
                    .where(
                        'estimate',
                        '==',
                        this.$fire.doc(`estimates/${this.estimate.id}`)
                    ),
                {
                    maxRefDepth: 0,
                }
            );

            await this.$bind(
                'subtasks',
                this.$fire
                    .collection('estimate_subtasks')
                    .where(
                        'estimate',
                        '==',
                        this.$fire.doc(`estimates/${this.estimate.id}`)
                    ),
                {
                    maxRefDepth: 0,
                }
            );

            // Check line items calculations
            this.validateEstimate();

            this.loading = false;
        },

        /* NAVIGATION */

        goBackAction() {
            if (this.$route.params.project_id) {
                // project is set, return to project view
                this.$router.push({
                    name: 'project_detail_estimates',
                    params: {
                        project_id: this.$route.params.project_id,
                    },
                });
            } else if (this.user_is_super_admin) {
                // no project but admin, return to management view
                this.$router.push({
                    name: 'management_estimates',
                });
            } else {
                // return to project list
                this.$router.push({
                    name: 'projects',
                });
            }
        },
        handleViewCR() {
            if (this.estimate.change_request) {
                this.$router.push({
                    name: 'project_detail_changerequests+detail',
                    params: {
                        project_id: this.$route.params.project_id,
                        change_request_id: this.estimate.change_request,
                    },
                });
            }
        },
        handleViewComponentsLinking() {
            this.$router.push({
                name: 'project_detail_estimate+componentslinking',
                params: {
                    project_id: this.$route.params.project_id,
                    estimate_id: this.$route.params.estimate_id,
                },
            });
        },
        handleViewPaymentPlan() {
            this.$router.push({
                name: 'project_detail_paymentplan',
                params: {
                    project_id: this.$route.params.project_id,
                    estimate_id: this.$route.params.estimate_id,
                },
            });
        },

        /* FORM HANDLING */

        validateEstimate() {
            if (this.estimate.credit?.label || this.estimate.credit?.value) {
                this.credit.enabled = true;
            }

            this.credit.label = this.estimate.credit?.label || '';
            const new_credit_value = this.estimate.credit?.value || 0;
            if (this.credit.value !== new_credit_value) {
                this.credit.value = new_credit_value;
                this.updatePendingCost();
            }

            this.increment += 1;

            // Scan line items and check calculations
            this.tasks.forEach((item) => {
                let needsUpdate = false;

                // check unit matches the estimates
                if (item.unit !== this.estimate.unit) {
                    needsUpdate = true;
                }

                // check rate matches the estimates
                else if (item.rate !== this.estimate.rate) {
                    needsUpdate = true;
                }

                // check hours_per_day matches the estimates
                else if (item.hours_per_day !== this.estimate.hours_per_day) {
                    needsUpdate = true;
                }

                if (item.cost !== estimateTaskMixin.methods.task_cost(item)) {
                    needsUpdate = true;
                }

                if (needsUpdate) {
                    this.handleSaveTask(item);
                }
            });
        },
        handleEdit() {
            this.editing = true;
        },
        closeEdit() {
            this.editing = false;
        },

        /* GROUP MANAGEMENT */

        handleClickOnNewGroupAbove(group) {
            // Set the new group index above the given group sort
            // This will work in case the given group is the first one
            let aboveIdx = group.sort - 1;

            // Find the given group index
            const idx = this.sortingGroups.findIndex((g) => g.id === group.id);

            // If the given group is not the first one
            if (idx !== 0) {
                // Get the above group
                const aboveGroup = this.sortingGroups[idx - 1];
                // Calculate the absolute step value between the two groups
                const step = Math.abs(aboveGroup.sort - group.sort);
                // Add half of the step to the above group
                aboveIdx = step / 2 + aboveGroup.sort;
                // The resulting value will place the new group between the above and the given group
            }

            this.handleClickOnNewGroup(aboveIdx);
        },
        handleClickOnNewGroupBelow(group) {
            // Set the new group index below the given group sort
            // This will work in case the given group is the last one
            let belowIdx = group.sort + 1;

            // Find the given group index
            const idx = this.sortingGroups.findIndex((g) => g.id === group.id);

            // If the given group is not the last one
            if (idx !== this.sortingGroups.length - 1) {
                // Get the below group
                const belowGroup = this.sortingGroups[idx + 1];
                // Calculate the absolute step value between the two groups
                const step = Math.abs(belowGroup.sort - group.sort);
                // Add half of the step to the above group
                belowIdx = step / 2 + group.sort;
                // The resulting value will place the new group between the above and the given group
            }
            this.handleClickOnNewGroup(belowIdx);
        },
        async handleClickOnNewGroup(position) {
            await this.$store.dispatch('addGroupToEstimate', {
                estimate: this.$fire.doc(`estimates/${this.estimate.id}`),
                sort: position,
            });
            await this.updateDocumentEstimateGroups(this.estimate, this.groups);
        },
        async handleDeleteGroup(group_id) {
            // delete all tasks from group as well
            this.tasks.forEach((t) => {
                if (group_id === fireRef2id(t.group)) {
                    this.handleDeleteTask(t.id);
                }
            });
            await this.$store.dispatch('deleteGroupFromEstimate', {group_id});
            await this.updateDocumentEstimateGroups(this.estimate, this.groups);
        },
        handleRenameGroup(params) {
            this.$store.dispatch('renameEstimateGroup', params);
        },
        handleExcludeGroup(group_id) {
            const item = this.groups.find((t) => t.id === group_id);
            const excluded = !item.excluded;
            this.$store.dispatch('excludeEstimateGroup', {
                group_id: group_id,
                excluded,
            });
        },

        /* TASK MANAGEMENT */

        groupTasks(group_id) {
            return this.sorted_tasks.filter(
                (t) => group_id === fireRef2id(t.group)
            );
        },
        handleSaveTask(item) {
            // TODO TUL-847 bug detection failsafe & logging, remove once resolved
            const item_estimate_id = fireRef2id(item.estimate);
            if (item.id && item_estimate_id !== this.estimate.id) {
                const message = `Task estimate (${item_estimate_id}) does not match current estimate (${this.estimate.id})`;
                Notification({
                    type: 'error',
                    title: 'Error TUL-847',
                    message: `${message} - it is unsafe to proceed, please reload estimate`,
                });
                const err = new Error(`TUL-847 ${message}`);
                Sentry.captureException(err);
                this.$router.push({
                    name: 'project_detail_estimates',
                    params: {
                        project_id: this.$route.params.project_id,
                    },
                });
                throw err;
            }

            // Check for updated estimates props
            let unit = this.estimate.unit;
            let hours_per_day = this.estimate.hours_per_day;
            let rate = this.estimate.rate;

            // Clean object from undefined fields
            Object.keys(item).forEach((key) => {
                if (item[key] === undefined) item[key] = null;
            });

            const payload = {
                ...item,
                rate,
                hours_per_day,
                unit,
                estimate: this.$fire.doc(`estimates/${this.estimate.id}`),
                group: this.$fire.doc(
                    `estimate_groups/${fireRef2id(item.group)}`
                ),
            };

            // Calculate item cost
            payload.cost = estimateTaskMixin.methods.task_cost(payload);

            // Update the task
            if (item.id) {
                this.$store.dispatch('updateEstimateTask', {
                    id: item.id,
                    ...payload,
                });
            }
            // Add a new task to the estimate
            else {
                this.$store.dispatch('addTaskToEstimateGroup', {
                    ...payload,
                    sort: moment().unix(),
                });
            }
        },
        handleDeleteTask(itemId) {
            // delete all subtasks as well
            this.subtasks.forEach((t) => {
                if (itemId === fireRef2id(t.task)) {
                    this.$store.dispatch('deleteEstimateSubtask', t.id);
                }
            });
            this.$store.dispatch('deleteEstimateTask', itemId);
        },
        handleExcludeTask(itemId) {
            const item = this.tasks.find((t) => t.id === itemId);
            const excluded = !item.excluded;
            this.$store.dispatch('updateEstimateTask', {
                id: item.id,
                excluded,
            });
        },
        handleSelectTask(itemId) {
            if (!this.is_estimate_draft) {
                if (this.selectedTask === itemId) this.selectedTask = null;
                else this.selectedTask = itemId;
            }
        },

        /* SUBTASK MANAGEMENT */

        handleSaveSubtask(item) {
            // Clean object from undefined fields
            Object.keys(item).forEach((key) => {
                if (item[key] === undefined) item[key] = null;
            });

            item.qty = Number(item.qty.toFixed(2));

            const payload = {
                ...item,
                estimate: this.$fire.doc(`estimates/${this.estimate.id}`),
                group: this.$fire.doc(
                    `estimate_groups/${fireRef2id(item.group)}`
                ),
                task: this.$fire.doc(`estimate_tasks/${fireRef2id(item.task)}`),
            };

            // Update the subtask
            if (item.id) {
                this.$store.dispatch('updateEstimateSubtask', {
                    id: item.id,
                    ...payload,
                });
            }
            // Add a new item to the task
            else {
                this.$store.dispatch('addSubtaskToEstimateTask', {
                    ...payload,
                    sort: moment().unix(),
                });
            }

            this.updateTaskOnSubtaskChange(item);
        },
        handleRemoveSubtask(itemId) {
            const item = this.subtasks.find((t) => t.id === itemId);
            if (item) {
                this.updateTaskOnSubtaskChange(item, false);
            }
        },
        handleDeleteSubtask(itemId) {
            const item = this.subtasks.find((t) => t.id === itemId);
            if (item) {
                this.updateTaskOnSubtaskChange(item, false);
            }
            this.$store.dispatch('deleteEstimateSubtask', itemId);
        },
        handleExcludeSubtask(itemId) {
            const item = this.subtasks.find((t) => t.id === itemId);
            const excluded = !item.excluded;
            if (item) {
                this.updateTaskOnSubtaskChange(item, !excluded);
            }
            this.$store.dispatch('updateEstimateSubtask', {
                id: item.id,
                excluded,
            });
        },
        updateTaskOnSubtaskChange(item, includeItemInCalc = true) {
            // sum all sibling subtask's qtys & overrides
            let totals = this.subtasks.reduce(
                (t, subtask) => {
                    if (
                        subtask.id !== item.id &&
                        fireRef2id(subtask.task) === fireRef2id(item.task) &&
                        !subtask.excluded
                    ) {
                        t.totalQty += subtask.qty;
                        t.totalOverride += subtask.override?.qty ?? subtask.qty;
                    }
                    return t;
                },
                {totalQty: 0, totalOverride: 0}
            );
            if (includeItemInCalc) {
                totals.totalQty += item.qty;
                totals.totalOverride += item.override?.qty ?? item.qty;
            }

            // update parent task's qty & override
            const parentTask = this.tasks.find(
                (task) => task.id === fireRef2id(item.task)
            );
            parentTask.qty = totals.totalQty;
            if (totals.totalOverride !== totals.totalQty) {
                parentTask.override.qty = totals.totalOverride ?? null;
            } else {
                parentTask.override.qty = null;
            }
            this.handleSaveTask(parentTask);
        },

        /* SORTING */

        updateGroupOrder(event) {
            const group = this.sortingGroups[event.newIndex];
            let before = null;
            let after = null;
            if (event.newIndex > 0) {
                before = this.sortingGroups[event.newIndex - 1];
            }
            if (event.newIndex < this.sortingGroups.length - 1) {
                after = this.sortingGroups[event.newIndex + 1];
            }
            if (!before && !after) return; // only item in list

            let sort = 0;
            if (!before) {
                // first, set sort index 1 before following item
                sort = after.sort - 1;
            } else if (!after) {
                // last, set sort index 1 after preceding item
                sort = before.sort + 1;
            } else {
                // set sort index halfway between neighbours
                sort = (before.sort + after.sort) / 2;
            }
            this.$store.dispatch('updateEstimateGroupOrder', {
                group_id: group.id,
                sort,
            });
        },

        /* STATUS */

        handleSubmitForReview() {
            this.handleSetStatus(ESTIMATE_STATUS.PENDING);

            this.calculateFinalCost();

            this.updateDocumentEstimateGroups(this.estimate, this.groups);
        },
        handleRestoreForReview() {
            this.handleSetStatus(ESTIMATE_STATUS.PENDING);
        },
        handleAcceptEstimate() {
            this.handleSetStatus(ESTIMATE_STATUS.ACCEPTED);
            updateXeroQuoteStatus(this.estimate.id, XERO_QUOTE_STATUS.ACCEPTED);
            this.notifyProductOwner(true);
        },
        handleDeclineEstimate() {
            this.handleSetStatus(ESTIMATE_STATUS.DECLINED);
            updateXeroQuoteStatus(this.estimate.id, XERO_QUOTE_STATUS.DECLINED);
            this.notifyProductOwner(false);
        },
        handleSetStatus(status) {
            this.$store.dispatch('setEstimateStatus', {
                estimate_id: this.estimate.id,
                status,
            });
        },

        getDocIcon(doc) {
            if (this.is_estimate_locked) return 'el-icon-document';
            return doc.valid ? 'el-icon-edit' : 'el-icon-warning-outline';
        },

        /* CLONE ESTIMATE */

        handleCloneEstimate() {
            this.cloneEstimate(this.copy_type);
        },
        cloneEstimate(clone_rule = 'full') {
            if (this.sorted_groups.length) {
                let clone_groups = this.sorted_groups;

                if (clone_rule === 'included')
                    clone_groups = this.groups_with_included_items;
                else if (clone_rule === 'excluded')
                    clone_groups = this.groups_with_excluded_items;

                const copied_form = {...this.estimate};
                // remove values that shouldn't be copied
                delete copied_form.status;
                delete copied_form.stage;
                delete copied_form.xero;

                this.$router.push({
                    name: 'estimate_new',
                    params: {
                        groups: clone_groups,
                        copied_form,
                        clone_rule,
                    },
                });
            }
        },

        /* CREDIT */

        updateCredit: debounce(function () {
            if (this.credit.enabled) {
                this.$store.dispatch('setEstimateCredit', {
                    estimate_id: this.estimate.id,
                    label: this.credit.label,
                    value: this.credit.value,
                });
                this.updatePendingCost();
            }
        }, 500),

        /* DOCUMENT MANAGEMENT */

        createDocument() {
            this.$router.push({
                name: 'document_new',
                params: {
                    doc_type: DOCUMENT_TYPES.ESTIMATE,
                    ref_id: this.estimate.id,
                },
            });
        },
        editDocument(doc) {
            this.$router.push({
                name: 'document_edit',
                params: {
                    doc_id: doc.id,
                },
            });
        },
        async deleteDocument(doc) {
            await this.$fire.doc(`documents/${doc.id}`).delete();
        },

        /* ESTIMATE CREATION */
        // TODO move to helper?

        updatePendingCost: debounce(function () {
            if (this.is_estimate_pending) this.calculateFinalCost();
        }, 500),

        async calculateFinalCost() {
            // Set final values for estimate
            let final_cost = 0;
            // Update groups with task total
            for (const group of this.groups) {
                if (!group.excluded) {
                    let totals = this.groupTasks(group.id).reduce(
                        (total, task) => {
                            if (task.excluded) return total;
                            total.totalQty += task.override?.qty || task.qty;
                            total.totalCost += task.cost;
                            total.desc += `\n • ${task.description}`;
                            return total;
                        },
                        {totalQty: 0, totalCost: 0, desc: ''}
                    );

                    await this.$store.dispatch('setEstimateGroupFinalValues', {
                        group_id: group.id,
                        qty: totals.totalQty,
                        extended_description: totals.desc,
                    });

                    final_cost +=
                        totals.totalCost *
                        ((100 - (group.discount || 0)) / 100);
                }
            }

            // apply credit if set
            if (this.credit.value) {
                final_cost -= this.credit.value ?? 0;
            }

            // update estimate with final cost (with discounts applied) for use in payment plans
            await this.$store.dispatch('setEstimateFinalCost', {
                estimate_id: this.estimate.id,
                final_cost,
            });
        },

        async handleFinaliseEstimate() {
            this.finalising = true;
            try {
                await this.calculateFinalCost();

                let result = await submitEstimateToXero(this.estimate.id);
                if (result.success) {
                    /**
                     * API does not appear to allow skipping steps with status
                     * quote is always created as DRAFT, and must be set to SENT before ACCEPTED
                     * explicitly set quote to the required statuses
                     */
                    result = await updateXeroQuoteStatus(
                        this.estimate.id,
                        XERO_QUOTE_STATUS.SENT
                    );
                }

                this.finalising = false;
                if (!result.success) {
                    Notification({
                        type: 'error',
                        title: 'Error',
                        message: 'Unable to finalise estimate. (300)',
                    });
                }
            } catch (e) {
                console.log(e);
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: 'Unable to finalise estimate. (301)',
                });
                this.finalising = false;
            }
        },

        // DELETE ESTIMATE
        // (reserved for deleting templates at this stage)
        async handleDeleteEstimate() {
            this.loading = true;
            // delete all groups for this estimate
            for (const group of this.groups) {
                this.handleDeleteGroup(group.id);
            }
            // delete estimate
            await this.$fire.doc(`estimates/${this.estimate.id}`).delete();
            this.goBackAction();
        },

        notifyProductOwner(accepted) {
            if (this.product_owner) {
                const accepted_status = accepted ? 'accepted' : 'declined';
                const project_ref = this.$fire.doc(this.estimate.project);
                const estimate_ref = this.$fire.doc(
                    `estimates/${this.estimate.id}`
                );
                const estimate_label =
                    this.estimate_id +
                    (this.estimate.description
                        ? ` - ${this.estimate.description}`
                        : '');

                this.$fire.collection('notifications').add({
                    for: this.$fire
                        .collection('users')
                        .doc(this.product_owner.id),
                    type: accepted ? 'ESTIMATE_ACCEPTED' : 'ESTIMATE_DECLINED',
                    title: `Estimate ${accepted_status}`,
                    target_id: this.estimate.id,
                    target_ref: estimate_ref,
                    description: `Estimate "${estimate_label}" has been ${accepted_status}`,
                    project: project_ref,
                    created_at: new Date(),
                    read: false,
                    users: [],
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.status_banner {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.header {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    span.ref {
        padding: 0 10px;

        span {
            padding: 0 10px;
            font-weight: bold;
        }
    }
}

.template-banner {
    margin: 0 10px;
}

.estimate-details {
    ::v-deep label {
        font-weight: bold;
        font-size: 18px;
    }
}

hr {
    width: 100%;
    margin: 10px 0;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .el-button {
        width: 200px;
    }
}

.estimate-group-new {
    padding: 20px;
    border-radius: 5px;
    height: 30px;
    background-color: $grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid $grey;

    .icon {
        width: 25px;
        height: 25px;
        fill: rgba($black, 0.5);
    }

    span {
        color: rgba($black, 0.5);
        font-size: 14px;
        margin-top: 8px;
    }

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;

        .icon {
            fill: $blue;
        }

        span {
            color: $blue;
        }
    }
}

.estimate-footer {
    position: sticky;
    bottom: 0;
    z-index: 1002;
    padding: 10px 0;
    text-align: right;
    background: white;
    border-top: 1px solid $transparent-grey;
    margin-top: 10px;
    margin-bottom: -10px;

    &.tracking {
        bottom: #{$bottom-bar-height};
    }

    .footer-total-section {
        display: flex;
        flex-direction: column;
        min-height: 32px;
        position: relative;

        .credit-toggle {
            position: absolute;
            margin-left: 20px;
            font-size: 11px;
            height: 32px;
            display: flex;
            align-items: center;
            z-index: 10;
            bottom: 0;

            > * {
                margin-right: 10px;
            }

            .credit-toggle-label {
                width: 80px;
            }

            .credit-label {
                width: auto;
            }
            .credit-value {
                width: 100px;
                height: 32px;
                font-family: Montserrat, sans-serif;
            }
        }

        .footer-totals {
            padding: 0 20px;
            flex: 1;
            display: flex;
            align-items: flex-start;
        }
    }

    .button-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .buttons-left {
            text-align: left;

            > *:not(:first-child) {
                margin-left: 10px;
            }

            .primary-selector {
                width: 250px;
            }
        }

        .buttons-right {
            text-align: right;

            > *:not(:first-child) {
                margin-left: 10px;
            }

            .document-list {
                .document-row {
                    margin-bottom: 5px;

                    .document-button {
                        min-width: 300px;
                        text-align: left;
                    }

                    .document-delete-confirm {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

.group-toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px 10px;
    color: #888;
    font-size: 12px;

    .subtask-toggle {
        display: flex;
        align-items: center;
        margin-right: 20px;
        > * {
            margin-left: 10px;
        }
    }
}

.footnote {
    font-size: 11px;
    color: rgba($black, 0.5);

    &.inline {
        display: inline-block;
        vertical-align: middle;
    }
}

.template-confirm {
    font-size: 12px;
    width: 150px;
    text-align: center;

    .buttons {
        margin-top: 5px;
    }
}

.error-alert {
    margin-top: 20px;
}
</style>
