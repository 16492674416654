<template>
    <div class="outer">
        <breadcrumb-header
            :labels="['New client']"
            go_back_to="management_clients"
        />
        <div class="inner">
            <el-form
                v-if="$store.getters.is_xero_enabled"
                ref="form"
                auto-complete="on"
                label-width="160px"
                class="form-wrapper"
                style="margin-bottom: 20px;"
            >
                <el-form-item label="Import from Xero" prop="project">
                    <el-select
                        filterable
                        remote
                        :remote-method="remoteMethod"
                        :loading="loading"
                        :value="contact"
                        placeholder="Search contact"
                        value-key="id"
                        @change="changedContact"
                    >
                        <el-option
                            v-for="c in options"
                            :key="c.id"
                            :label="c.name"
                            :value="c"
                        />
                    </el-select>
                </el-form-item>
            </el-form>

            <hr />

            <el-alert v-if="contact.id" type="warning" :closable="false">
                Imported clients are not editable.
            </el-alert>

            <client-form
                ref="clientForm"
                :client="contact"
                :readonly="!!contact.id"
                :loading="loading"
                @submit="handleSubmit"
            />
        </div>
    </div>
</template>

<script>
import {getContacts, addContact} from '@/api/contacts';

import ClientForm from './components/ClientForm';
import BreadcrumbHeader from '@/pages/management/components/BreadcrumbHeader';

import {Notification} from 'element-ui';

export default {
    name: 'management-clients-new',
    components: {
        ClientForm,
        BreadcrumbHeader,
    },
    data() {
        return {
            contact: {
                addresses: [{}],
                additional_contacts: [],
            },
            contacts: [],
            options: [],
            loading: false,
            saving: false,
        };
    },
    methods: {
        changedContact(c) {
            this.contact = c;
        },
        async remoteMethod(query) {
            if (query !== '') {
                if (this.contacts.length === 0) {
                    this.loading = true;
                    const result = await getContacts();
                    if (result.success) {
                        this.contacts = result.data.contacts;
                        this.loading = false;
                    } else {
                        // TODO: do something with error
                        this.loading = false;
                        this.options = [];
                        return;
                    }
                }

                this.options = this.contacts?.filter((item) => {
                    return (
                        item.name.toLowerCase().indexOf(query.toLowerCase()) >
                        -1
                    );
                });
            } else {
                this.options = [];
            }
        },
        handleSubmit() {
            this.$refs['clientForm'].validate((valid) => {
                if (valid) {
                    this.saveContact();
                }
            });
        },
        async saveContact() {
            this.saving = true;

            // remove any unpopulated additional contacts
            const additional_contacts = this.contact.additional_contacts?.filter(
                (contact) => {
                    return contact.first_name && contact.email;
                }
            );
            if (additional_contacts && additional_contacts.length) {
                this.contact.additional_contacts = additional_contacts;
            }

            // if new client, send to Xero first
            if (!this.contact.id) {
                try {
                    const result = await addContact(this.contact);
                    // replace entered contact with returned data
                    // (may be pre-existing values if a matching contact was found)
                    this.contact = result.data;
                } catch (e) {
                    let errmsg = e.message;
                    if (e.response) {
                        errmsg = e.response.data.message;
                    }

                    Notification({
                        type: 'error',
                        title: 'Not saved',
                        message: `Client was not saved to Xero: ${errmsg}`,
                        duration: 10000,
                    });

                    this.saving = false;
                    return false;
                }
            }

            // save client to Firestore then view client details
            this.$store.dispatch('addClient', this.contact).then(() => {
                this.saving = false;
                this.$router
                    .push({
                        name: 'management_client_detail',
                        params: {
                            client_id: this.contact.id,
                        },
                    })
                    .catch((err) => {});
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    .inner {
        padding: 20px;

        hr {
            margin: 20px 50px;
            border: none;
            height: 1px;
            background-color: $border-grey-light;
        }

        .el-form {
            display: flow-root;
            margin: 40px auto;

            .el-form-item {
                &.disabled {
                    opacity: 0.3;
                    pointer-events: none;
                }

                .el-input,
                .el-select {
                    flex: 1;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}
</style>
