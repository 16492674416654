<template>
    <div class="milestones-container" :style="cssStyles">
        <div class="day-grid">
            <div
                v-for="milestone in cur_node.milestones"
                :key="keyForMilestone(milestone)"
                class="milestone-cell-container"
                :style="{left: `${getMilestoneLeftOffset(milestone.date)}px`}"
            >
                <milestone-cell
                    :milestone="milestone"
                    :cur_node="cur_node"
                    @update:node="(p) => $emit('update:node', p)"
                />
            </div>
            <div
                v-if="cur_node.currentStatus"
                class="milestone-cell-container"
                :style="{
                    left: `${getStatusLeftOffset(
                        cur_node.currentStatus.created_at
                    )}px`,
                }"
            >
                <project-status-cell
                    :project_status="cur_node.currentStatus"
                    :cur_node="cur_node"
                />
            </div>
        </div>
    </div>
</template>

<script>
import MilestoneCell from '@/components/timeline-v2/MilestoneCell';
import ProjectStatusCell from './ProjectStatusCell';

import {
    adjustedStartDate,
    workDaysBetween,
} from '@/components/timeline-v2/utils';
import {momentWithTz} from '@/utils';

export default {
    name: 'milestones',

    components: {
        MilestoneCell,
        ProjectStatusCell,
    },

    props: {
        tree: {
            type: Object,
            required: true,
        },

        cur_node: {
            type: Object,
            required: true,
        },

        day_width: {
            type: Number,
            required: true,
        },

        row_height: {
            type: Number,
            required: true,
        },

        depth: {
            type: Number,
            required: true,
        },

        days: {
            type: Array,
            required: true,
        },
    },

    computed: {
        cssStyles() {
            return {
                '--day-width': `${this.day_width}px`,
                '--row-height': `${this.row_height}px`,
                '--day-width-offset': `${(-1 * this.day_width) / 2}px`,
            };
        },
    },
    methods: {
        keyForMilestone(milestone) {
            return `milestone-cell-${milestone.id}-${
                milestone.meta.type
            }-${momentWithTz(milestone.date.toDate()).format('YYYY-MM-DD')}`;
        },
        getMilestoneLeftOffset(d) {
            const daysBetween = workDaysBetween(
                adjustedStartDate(this.tree),
                d
            );
            return daysBetween * this.day_width;
        },
        getStatusLeftOffset(d) {
            const date = momentWithTz(d.toDate()).startOf('day');

            const daysBetween = workDaysBetween(
                adjustedStartDate(this.tree),
                date
            );
            return daysBetween * this.day_width;
        },
    },
};
</script>

<style lang="scss">
.day-grid {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
</style>

<style scoped lang="scss">
.milestone-cell-container {
    position: absolute;
}

.milestones-container {
    position: relative;
    background-color: rgba(43, 43, 43, 0.05);
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
</style>
