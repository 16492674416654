<template>
    <div class="yearly-view">
        <div class="event-filter">
            <el-select
                v-model="eventFilter"
                size="small"
                placeholder="Showing all event types"
                multiple
                clearable
            >
                <el-option
                    v-for="type in calendarEventTypes"
                    :key="type.type"
                    :label="type.label"
                    :value="type.type"
                >
                    <div class="event-type-option">
                        <div
                            class="color-preview"
                            :style="`background-color: ${type.color}`"
                        />
                        <span>{{ type.label }}</span>
                    </div>
                </el-option>
            </el-select>
        </div>
        <calendar
            :display-header="false"
            :week-start="1"
            :display-week-number="true"
            :data-source="filteredEvents"
            :enable-range-selection="true"
            :custom-day-renderer="handleDayRender"
            @mouse-on-day="handleMouseOnDay"
            @mouse-out-day="handleMouseOutDay"
            @select-range="handleSelectRange"
        />
        <div
            v-show="!!selectedDay"
            ref="eventPopup"
            class="event-popup"
            @mouseenter="handlePopperMouseOver"
            @mouseleave="handlePopperMouseLeave"
        >
            <template v-if="selectedDay">
                <div
                    v-for="event of selectedDay.events"
                    :key="event.id"
                    class="event-item"
                    @click="
                        () => {
                            updateEvent(event);
                        }
                    "
                >
                    <span
                        class="event-type"
                        :style="`background-color: ${event.color}`"
                    >
                        {{ event.type.label }}
                    </span>
                    <span class="event-description">{{ event.name }}</span>
                    <span v-if="event.project" class="event-project">
                        {{ event.project }}
                    </span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Popper from 'popper.js';
import dayjs from 'dayjs';

import userMixin from '@/mixins/user.mixin';

import Calendar from 'v-year-calendar';

export default {
    name: 'planner-yearly-view',
    components: {Calendar},
    mixins: [userMixin],
    data() {
        return {
            events: [],
            loading: true,
            selectedDay: null,
            popper: null,
            popperTimeout: 200, // delay before closing popper, to allow mouseover when exiting day
            popperCloseTimer: null,
            eventFilter: this.$store.getters.user.events_filter || [],
        };
    },
    computed: {
        filteredEvents() {
            if (this.loading) return [];

            return this.events
                .filter((e) => {
                    return (
                        e.type?.calendar === true &&
                        e.start &&
                        (this.eventFilter.length === 0 ||
                            this.eventFilter.find((f) => f === e.type?.type))
                    );
                })
                .map((e) => {
                    return {
                        id: e.id,
                        name: e.content,
                        startDate: e.start.toDate(),
                        endDate: e.end?.toDate() ?? e.start.toDate(),
                        type: e.type,
                        color: e.type.color ?? 'null',
                        project: e.project?.ref ?? null,
                    };
                });
        },
        eventTypes() {
            return this.$store.getters.eventTypes() || [];
        },
        calendarEventTypes() {
            return this.eventTypes.filter((t) => t.calendar === true);
        },
        calendarEventTypeList() {
            return this.calendarEventTypes.map((t) => {
                return t.type;
            });
        },
    },
    watch: {
        eventFilter: function (val) {
            this.$store.dispatch('updateCurrentUser', {
                key: 'events_filter',
                value: val,
            });
        },
    },
    mounted() {
        this.loading = true;

        this.$bind(
            'events',
            this.$fire.collection('events').orderBy('start', 'asc'),
            {maxRefDepth: 1}
        ).then(() => {
            this.loading = false;
        });
    },
    methods: {
        handleDayRender(el, date) {
            if (dayjs().isSame(date, 'day')) {
                el.classList.add('today');
            }
        },
        handleMouseOnDay(data) {
            if (data.events.length) {
                if (this.popper) this.closePopper();
                this.selectedDay = data;
                this.popper = new Popper(data.element, this.$refs.eventPopup, {
                    placement: 'bottom-start',
                });
            }
        },
        handleMouseOutDay() {
            if (this.popper) {
                clearTimeout(this.popperCloseTimer);
                this.popperCloseTimer = setTimeout(() => {
                    this.closePopper();
                }, this.popperTimeout);
            }
        },
        handlePopperMouseOver() {
            // if mouse enters an active popper, cancel close timer
            clearTimeout(this.popperCloseTimer);
            this.popperCloseTimer = null;
        },
        handlePopperMouseLeave() {
            // if mouse leave popper, close immediately
            this.closePopper();
        },
        closePopper() {
            this.selectedDay = null;
            clearTimeout(this.popperCloseTimer);
            this.popperCloseTimer = null;
            this.popper.destroy();
            this.popper = null;
        },
        handleSelectRange(data) {
            this.createEvent(data.startDate, data.endDate);
        },
        createEvent(startDate, endDate) {
            this.$router.push({
                name: 'events_new',
                params: {
                    types: this.calendarEventTypeList,
                    startDate,
                    endDate,
                },
            });
        },
        updateEvent(data) {
            this.$router.push({
                name: 'events_update',
                params: {
                    event_id: data.id,
                    types: this.calendarEventTypeList,
                },
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
::v-deep .event-filter {
    padding: 15px;

    .el-select {
        width: 100%;
    }
}

::v-deep .calendar {
    .months-container {
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

        .month-container {
            padding: 0 20px;
            width: auto !important;
            height: unset;
        }
    }

    .day-content {
        &.today {
            background-color: rgba($blue, 0.2);
        }
    }

    @media screen and (max-width: 992px) {
        .month-container {
            &:not(:last-of-type) {
                margin-bottom: 48px;
            }
            .month {
                width: 100%;
            }
        }
    }
}

.event-popup {
    background: white;
    border: 1px solid $border-grey;
    border-radius: 4px;
    padding: 5px 10px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    margin-left: -12px;

    .event-item {
        display: flex;
        align-items: center;
        margin: 1px 0;
        background: $transparent-grey;
        border-radius: 4px;
        border: 2px solid white;
        cursor: pointer;
        &:hover {
            border: 2px solid $blue;
            background-color: #daeeff;
        }
        .event-type {
            font-size: 10px;
            font-weight: bold;
            padding: 4px;
            border-radius: 3px;
            background-color: $transparent-grey;
            color: white;
            width: 110px;
            text-align: center;
        }
        .event-description {
            font-size: 14px;
            margin: 0 10px;
            flex: 1;
        }
        .event-project {
            font-size: 10px;
            font-weight: bold;
            padding: 4px 0;
            border-radius: 3px;
            background-color: $transparent-grey;
            width: 30px;
            text-align: center;
        }
    }
}
</style>

<style rel="stylesheet/scss" lang="scss">
.event-type-option {
    display: flex;
    align-items: center;

    .color-preview {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 15px;
        background-color: transparent;
        border-radius: 2px;
    }
}

.calendar-context-menu {
    font-size: 14px;

    * {
        box-sizing: border-box;
    }
}

@media screen and (max-width: 992px) {
    .container {
        padding-top: 0 !important;
    }
}
</style>
