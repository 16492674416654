<template>
    <div
        class="time-indicator desktop-only"
        @mouseover="disable_hover ? null : (hover = true)"
        @mouseleave="disable_hover ? null : (hover = false)"
    >
        <span v-if="hover && time_estimate" class="time">
            <span>
                <strong>{{ remaining | milli2duration }}</strong>
            </span>
            <span>left</span>
        </span>
        <div v-else class="circle">
            <div class="inner" :style="{backgroundColor: color}">
                <div :class="ring_class" class="ring" />
            </div>
        </div>
    </div>
</template>

<script>
import componentSessionMixin from '@/mixins/component.session.mixin';
import componentRecurringMixin from '@/mixins/component.recurring.mixin';
import {momentWithTz} from '@/utils';
import componentMixin from '@/mixins/component.mixin';

export default {
    name: 'time-indicator',
    mixins: [componentMixin, componentSessionMixin, componentRecurringMixin],
    props: {
        component: {
            type: Object,
            required: true,
        },
        disable_hover: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hover: false,
        };
    },
    computed: {
        selected_block() {
            return this.getRecurringBlockForDate(momentWithTz());
        },
        current_block() {
            return this.selected_block;
        },
        remaining() {
            if (this.selected_block && this.component.recurring) {
                return this.time_available;
            }
            if (this.has_reported_hours_rounding) {
                return this.time_left_reportable;
            }
            return this.time_left;
        },
        color() {
            if (this.time_left_percentage === 0) {
                return "rgba('#dcdfe6', 0.4)";
            }

            const percentColors = [
                {pct: 10, color: {r: 0xf9, g: 0x21, b: 0x39}},
                {pct: 40, color: {r: 0xf5, g: 0xa6, b: 0x23}},
                {pct: 60, color: {r: 0x90, g: 0xd0, b: 0x50}},
                {pct: 100, color: {r: 0x90, g: 0xd0, b: 0x50}},
            ];
            let i = 1;
            for (; i < percentColors.length; ++i) {
                if (this.time_left_percentage < percentColors[i].pct) {
                    break;
                }
            }

            const lower = percentColors[i - 1];
            const upper = percentColors[i];
            const range = upper.pct - lower.pct;
            const rangePct = (this.time_left_percentage - lower.pct) / range;
            const pctLower = 1 - rangePct;
            const pctUpper = rangePct;
            const color = {
                r: Math.floor(
                    lower.color.r * pctLower + upper.color.r * pctUpper
                ),
                g: Math.floor(
                    lower.color.g * pctLower + upper.color.g * pctUpper
                ),
                b: Math.floor(
                    lower.color.b * pctLower + upper.color.b * pctUpper
                ),
            };

            return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
        },
        ring_class() {
            if (this.time_left_percentage === 0) return null;
            if (this.time_left_percentage > 0 && this.time_left_percentage < 20)
                return 'ring-slow';
            if (this.time_left_percentage < 20) return 'ring-fast';

            return null;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.time-indicator {
    width: 40px;
    height: 30px;
    padding: 0 15px;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 10;

    .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid $border-grey-light;
        background-color: $white-dark;
        padding: 2px;

        .inner {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            position: relative;

            .ring {
                position: absolute;
                top: 50%;
                margin-top: -8px;
                left: 50%;
                margin-left: -8px;
                width: 16px;
                height: 16px;
                border-radius: 8px;
                opacity: 0;
                z-index: 10;
            }

            .ring-slow {
                box-shadow: 0 0 2px 1px $red, inset 0 0 2px 1px $red;
                animation: ring 2s ease-in-out;
                animation-iteration-count: infinite;
            }

            .ring-fast {
                box-shadow: 0 0 2px 1px $red, inset 0 0 2px 1px $red;
                animation: ring 0.7s ease-in-out;
                animation-iteration-count: infinite;
            }
        }

        @keyframes ring {
            0% {
                transform: scale(0.4, 0.4);
                opacity: 0;
            }
            50% {
                opacity: 0.6;
            }
            100% {
                transform: scale(1.4, 1.4);
                opacity: 0;
            }
        }
    }

    .time {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            font-size: 8px;

            strong {
                font-size: 9px;
                font-weight: 600;
                margin-right: 1px;
            }
        }
    }
}
</style>
