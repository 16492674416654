<template>
    <div>
        <content-block title="Change requests" no_background>
            <template slot="right">
                <el-button size="mini" @click="handleClickOnNewCR">
                    New
                </el-button>
            </template>

            <ul v-if="active_change_requests.length" class="list-active">
                <change-request-block
                    v-for="change_request in active_change_requests"
                    :key="change_request.id"
                    :change_request="change_request"
                    :project="project"
                />
            </ul>
            <ul v-if="future_change_requests.length" class="list-future">
                <change-request-block
                    v-for="change_request in future_change_requests"
                    :key="change_request.id"
                    :change_request="change_request"
                    :project="project"
                />
            </ul>
            <ul v-if="closed_change_requests.length" class="list-closed">
                <change-request-block
                    v-for="change_request in closed_change_requests"
                    :key="change_request.id"
                    :change_request="change_request"
                    :project="project"
                />
            </ul>
        </content-block>

        <div
            v-if="
                !change_requests_options.show || change_requests_options.loading
            "
            class="load-more"
            @click="handleClickLoadClosed"
        >
            <loader v-if="change_requests_options.loading" />
            <span v-else>Show closed CRs</span>
        </div>
    </div>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import ChangeRequestBlock from '@/components/blocks/ChangeRequestBlock';

import {CR_STATUS} from '@/enums';
import Loader from '@/components/generic/Loader';

export default {
    name: 'project-tab-changerequests',
    components: {
        ContentBlock,
        ChangeRequestBlock,
        Loader,
    },
    data() {
        return {
            custom_colors: [
                {color: '#f56c6c', percentage: 30},
                {color: '#0f263b', percentage: 100},
                {color: '#90d050', percentage: 100},
            ],
            change_requests_options: {
                loading: false,
                show: false,
            },
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        change_requests_loaded() {
            return this.$store.getters.changeRequestsForProjectWithIdLoaded(
                this.$route.params.project_id
            );
        },
        change_requests() {
            return this.$store.getters.changeRequestsForProjectWithId(
                this.$route.params.project_id
            );
        },
        active_change_requests() {
            return this.change_requests
                .filter((cr) => {
                    return (
                        cr.status !== CR_STATUS.REQUESTED &&
                        cr.status < CR_STATUS.CLOSED
                    );
                })
                .reduce((acc, curr) => {
                    const existing = acc.find((a) => a.id === curr.id);
                    if (!existing) {
                        acc.push(curr);
                    }
                    return acc;
                }, [])
                .slice(0)
                .sort((a, b) => {
                    return b.status - a.status;
                });
        },
        future_change_requests() {
            return this.change_requests
                .filter((cr) => {
                    return cr.status === CR_STATUS.REQUESTED;
                })
                .reduce((acc, curr) => {
                    const existing = acc.find((a) => a.id === curr.id);
                    if (!existing) {
                        acc.push(curr);
                    }
                    return acc;
                }, []);
        },
        closed_change_requests() {
            return this.change_requests
                .filter((cr) => {
                    return (
                        cr.status === CR_STATUS.CLOSED ||
                        cr.status === CR_STATUS.ARCHIVED
                    );
                })
                .reduce((acc, curr) => {
                    const existing = acc.find((a) => a.id === curr.id);
                    if (!existing) {
                        acc.push(curr);
                    }
                    return acc;
                }, []);
        },
    },
    methods: {
        handleClickOnNewCR() {
            this.$router.push({
                name: 'changerequest_new',
                params: {
                    project_id: this.$route.params.project_id,
                },
            });
        },
        async handleClickLoadClosed() {
            this.change_requests_options.show = true;

            if (!this.change_requests_loaded.closed) {
                this.change_requests_options.loading = true;
                await this.$store.dispatch('bindProjectChangeRequests', {
                    project: this.$route.params.project_id,
                    closed: true,
                });
                this.change_requests_options.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.graphs {
    padding: 10px 20px 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .graph-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        label {
            position: absolute;
            margin-top: 10px;
            width: 70px;
            height: 70px;
            font-size: 12px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &.single {
                font-size: 16px;
            }
        }
    }
}

.list-active,
.list-future,
.list-closed {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-future,
.list-closed {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $border-grey-light;
}

.new {
    padding: 20px;
    border-radius: 5px;
    height: 60px;
    background-color: $grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid $grey;

    .icon {
        width: 25px;
        height: 25px;
        fill: rgba($black, 0.5);
    }

    span {
        color: rgba($black, 0.5);
        font-size: 14px;
        margin-top: 8px;
    }

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;

        .icon {
            fill: $blue;
        }

        span {
            color: $blue;
        }
    }
}

.load-more {
    cursor: pointer;
    margin: 0 10px;
    margin-top: -20px;
    box-sizing: border-box;
    padding: 20px 0;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: $black;
    opacity: 0.5;

    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 1;
    }

    .loader {
        height: 15px;
    }
}
</style>
