<template>
    <div class="background">
        <div class="container">
            <svgicon name="logo" class="logo" />
            <svgicon name="logo-text" class="logo-text" />

            <span v-if="!hasLoggedOut">Logging out...</span>
            <span v-else>Logged out successfully</span>
        </div>

        <span class="copyright">
            Made with <heart :width="12" /> by CodeFish Studio
        </span>
    </div>
</template>

<script>
import Heart from '@/components/generic/Heart';
import {firebaseApp} from '@/utils/firebase';

export default {
    name: 'logout',
    components: {
        Heart,
    },
    data() {
        return {
            hasLoggedOut: false,
            DELAY: 1000,
        };
    },
    mounted() {
        setTimeout(() => this.logout(), this.DELAY);
    },
    methods: {
        logout() {
            firebaseApp
                .auth()
                .signOut()
                .then(() => {
                    this.hasLoggedOut = true;
                    setTimeout(() => this.$router.go(), this.DELAY);
                });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.background {
    background-color: $actual-black;
    width: 100%;
    height: 100vh;
    position: fixed;

    .container {
        position: fixed;
        height: 90%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .logo {
            width: 70px;
            display: block;
            margin: 0 auto;
            fill: $red;
            margin-bottom: 25px;
        }

        ::v-deep .logo-text {
            width: 124px;
            display: block;
            margin: 0 auto;
            margin-bottom: 40px;

            g {
                fill: white;
            }
        }

        span {
            width: 350px;
            max-width: 100%;
            padding: 35px;
            color: white;
            text-align: center;
            height: 38px;
        }
    }

    .copyright {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: block;
        text-align: center;
        user-select: none;
        font-size: 13px;
        font-weight: 400;
        color: white;
        margin-bottom: 50px;

        svg {
            margin: 0 3px;
        }
    }
}
</style>
