/**
 * List of all providers to be loaded by the command palette
 */
import {CommandProvider} from '@/components/command-palette/providers/CommandProvider';
import {NavigationProvider} from '@/components/command-palette/providers/NavigationProvider';
import {ProjectProvider} from '@/components/command-palette/providers/ProjectProvider';
import {ComponentProvider} from '@/components/command-palette/providers/ComponentProvider';
import {EstimateProvider} from '@/components/command-palette/providers/EstimateProvider';
import {PaymentPlanProvider} from '@/components/command-palette/providers/PaymentPlanProvider';
import {TimerProvider} from '@/components/command-palette/providers/TimerProvider';
import {InvoiceProvider} from '@/components/command-palette/providers/InvoiceProvider';
import {DocumentProvider} from '@/components/command-palette/providers/DocumentProvider';

export const Providers = [
    CommandProvider,
    NavigationProvider,
    TimerProvider,
    ProjectProvider,
    ComponentProvider,
    EstimateProvider,
    PaymentPlanProvider,
    InvoiceProvider,
    DocumentProvider,
];
