<template>
    <div class="tag-wrapper">
        <el-tag
            :type="type"
            :disable-transitions="true"
            :size="size"
            class="tag"
        >
            <slot />
        </el-tag>
        <div
            class="progress"
            :class="direction"
            :style="{
                backgroundColor: color,
                width: progressAmount,
            }"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'arrow-spacer',
    props: {
        size: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        value: {
            type: Number,
            default: 0,
        },
        color: {
            type: String,
            default: 'rgba(0,0,0,0.1)',
        },
        direction: {
            type: String,
            default: 'left',
        },
    },
    computed: {
        progressAmount() {
            if (this.value < this.min) return 0;
            if (this.value > this.max) return '100%';
            return `${
                ((this.value - this.min) / (this.max - this.min)) * 100
            }%`;
        },
    },
};
</script>

<style lang="scss" scoped>
.tag-wrapper {
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    .progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        mix-blend-mode: multiply;

        &.right {
            left: auto;
            right: 0;
        }
    }
}
</style>
