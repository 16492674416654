<template>
    <div>
        <div class="block">
            <el-button @click="find">
                Find orphaned line items
            </el-button>

            <div v-if="orphans.line_items.length && !searching" class="row">
                <span>
                    {{ orphans.line_items.length }} line
                    {{ 'item' | pluralize(orphans.line_items.length) }} found
                </span>

                <el-button size="mini" type="danger" @click="remove">
                    Delete
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {Notification} from 'element-ui';

export default {
    name: 'find-orphaned-lineitems',
    data() {
        return {
            searching: false,
            orphans: {
                line_items: [],
            },
        };
    },
    methods: {
        sleep(ms) {
            return new Promise((r) => setTimeout(r, ms));
        },
        async find() {
            Notification({
                type: 'info',
                title: 'Searching',
                message: 'orphaned line items...',
            });

            const line_items_snap = await this.$fire
                .collection('line_items')
                .get();

            line_items_snap.forEach(async (line_item) => {
                const data = line_item.data();
                if (data.invoice) {
                    const invoice = await data.invoice.get();
                    if (!invoice.exists)
                        this.orphans.line_items.push(line_item);
                } else {
                    this.orphans.line_items.push(line_item);
                }
            });

            await this.sleep(5000);

            this.searching = false;

            Notification({
                type: 'success',
                title: 'Done',
            });
        },
        async remove() {
            Notification({
                type: 'error',
                title: 'Deleting',
                message: 'orphaned line items...',
            });

            this.orphans.line_items.forEach((line_item) => {
                this.$fire.doc(`line_items/${line_item.id}`).delete();
            });

            await this.sleep(5000);

            this.orphans.line_items = [];

            Notification({
                type: 'success',
                title: 'Done',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.row {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .el-button {
        width: 100px;
        margin-left: 15px !important;
    }
}
</style>
