/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jira-feature': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g transform="translate(1 1)" _fill="none" fill-rule="evenodd"><rect pid="0" _fill="#904EE2" width="14" height="14" rx="2"/><path pid="1" d="M3.5 4.5h7M3.5 6.5h7M3.5 8.5h4" _stroke="#FFF" stroke-linecap="round"/></g>'
  }
})
