<template>
    <div>
        <div class="block">
            <el-button @click="findSessions">Find Short Sessions</el-button>
            <div v-if="sessions.length">
                <span>Found {{ sessions.length }} sessions matching</span>
            </div>
        </div>
        <div class="block_options">
            <el-input
                v-model="max_time"
                size="mini"
                placeholder="Max Session Length in seconds"
            />
            <el-input v-model="user_id" size="mini" placeholder="User Id" />
        </div>
        <div class="block_options">
            <el-date-picker
                v-model="from_date"
                type="date"
                size="mini"
                :clearable="false"
                format="dd/MM/yyyy"
            />
            <el-date-picker
                v-model="to_date"
                type="date"
                size="mini"
                :clearable="false"
                format="dd/MM/yyyy"
            />
        </div>
        <div v-if="sessions.length" class="block">
            <el-button @click="deleteSessions">Delete Sessions?</el-button>
        </div>
    </div>
</template>
<script>
import {momentWithTz} from '@/utils';

export default {
    name: 'find-short-sessions',
    data() {
        return {
            from_date: null,
            to_date: null,
            user_id: null,
            max_time: 60,
            sessions: [],
        };
    },
    methods: {
        async findSessions() {
            if (!this.from_date || !this.to_date) return;
            if (this.user_id === null) return;

            this.sessions = [];
            const range_start = momentWithTz(this.from_date)
                .startOf('day')
                .toDate();
            const range_end = momentWithTz(this.to_date).endOf('day').toDate();

            const sessionsRef = await this.$fire
                .collection('sessions')
                .where('start', '>=', range_start)
                .where('start', '<=', range_end)
                .get();

            sessionsRef.forEach((doc) => {
                const sess = doc.data();

                console.log(sess);

                const diff = sess.stop.seconds - sess.start.seconds;

                if (diff < this.max_time) {
                    this.sessions.push({id: doc.id, ref: doc.ref, ...sess});
                }
            });
        },

        async deleteSessions() {
            const batch = this.$fire.batch();

            this.sessions.forEach((s) => {
                batch.delete(s.ref);
            });

            await batch.commit();

            this.sessions = [];
        },
    },
};
</script>
<style lang="scss" scoped>
.block_options {
    width: 410px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    & > * {
        margin-right: 5px;
        width: 200px;
    }
}
</style>
