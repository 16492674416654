<template>
    <div>
        <content-block title="Estimates" no_background>
            <template slot="right">
                <el-button size="mini" @click="handleClickOnNewEstimate">
                    New
                </el-button>
            </template>

            <loader v-if="loading" />
            <el-alert
                v-else-if="!estimates.length"
                description="No estimates have been created yet."
                :closable="false"
            />
            <template v-else>
                <content-block
                    v-if="estimates_accepted.length"
                    title="Accepted"
                >
                    <estimate-block
                        v-for="estimate in estimates_accepted"
                        :key="estimate.id"
                        :estimate="estimate"
                        :project="project"
                    />
                </content-block>

                <content-block v-if="estimates_sent.length" title="Sent">
                    <estimate-block
                        v-for="estimate in estimates_sent"
                        :key="estimate.id"
                        :estimate="estimate"
                        :project="project"
                    />
                </content-block>

                <content-block
                    v-if="estimates_pending.length"
                    title="In review"
                >
                    <estimate-block
                        v-for="estimate in estimates_pending"
                        :key="estimate.id"
                        :estimate="estimate"
                        :project="project"
                    />
                </content-block>

                <content-block v-if="estimates_draft.length" title="Draft">
                    <estimate-block
                        v-for="estimate in estimates_draft"
                        :key="estimate.id"
                        :estimate="estimate"
                        :project="project"
                    />
                </content-block>

                <content-block
                    v-if="estimates_declined.length"
                    title="Declined"
                >
                    <estimate-block
                        v-for="estimate in estimates_declined"
                        :key="estimate.id"
                        :estimate="estimate"
                        :project="project"
                    />
                </content-block>
            </template>
        </content-block>
    </div>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import EstimateBlock from '@/components/blocks/EstimateBlock';
import Loader from '@/components/generic/Loader';

import {ESTIMATE_STATUS} from '@/enums';

export default {
    name: 'project-tab-estimates',
    components: {
        Loader,
        ContentBlock,
        EstimateBlock,
    },
    data() {
        return {
            loading: true,
            estimates: [],
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },

        /* Estimates */

        estimates_draft() {
            return this.estimates.filter(
                (e) => e.status === ESTIMATE_STATUS.DRAFT
            );
        },
        estimates_pending() {
            return this.estimates.filter(
                (e) => e.status === ESTIMATE_STATUS.PENDING
            );
        },
        estimates_sent() {
            return this.estimates.filter(
                (e) => e.status === ESTIMATE_STATUS.SENT
            );
        },
        estimates_accepted() {
            return this.estimates.filter(
                (e) => e.status === ESTIMATE_STATUS.ACCEPTED
            );
        },
        estimates_declined() {
            return this.estimates.filter(
                (e) => e.status === ESTIMATE_STATUS.DECLINED
            );
        },
    },
    watch: {
        selectedTab(tab) {
            this.$router
                .replace({
                    name: `project_detail_${tab}`,
                })
                .catch((e) => {});
        },
    },
    async mounted() {
        await this.$bind(
            'estimates',
            this.$fire
                .collection('estimates')
                .where(
                    'project',
                    '==',
                    this.$fire
                        .collection('projects')
                        .doc(this.$route.params.project_id.toString())
                ),
            {
                maxRefDepth: 0,
            }
        );

        this.loading = false;
    },
    methods: {
        handleClickOnNewEstimate() {
            this.$router.push({
                name: 'estimate_new',
                params: {
                    project_id: this.$route.params.project_id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.list-active,
.list-future,
.list-closed {
    list-style: none;
    padding: 0;
    margin: 0;
}

::v-deep .el-collapse {
    border: none;

    .el-collapse-item {
        .el-collapse-item__wrap,
        .el-collapse-item__header {
            border: none;
        }

        .el-collapse-item__wrap {
            overflow: visible;
        }

        &.is-disabled {
            .el-collapse-item__wrap,
            .el-collapse-item__arrow {
                display: none;
            }
        }
    }
}

.payment-plan-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
</style>
