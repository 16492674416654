<template>
    <el-card class="card" shadow="never" :style="{justifyContent: align}">
        <transition name="fade" mode="out-in">
            <template v-if="!loading">
                <slot />
            </template>
            <div
                v-else
                v-loading="loading"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0)"
            />
        </transition>
    </el-card>
</template>

<script>
export default {
    name: 'widget-card',
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
        align: {
            type: String,
            default: 'center',
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    min-height: 100px;
    width: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1199px) {
        margin: 3px 0;
    }

    .fade {
        &-enter-active,
        &-leave-active {
            transition: all 0.1s ease-in-out;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
        }
    }

    ::v-deep .key-value {
        &.selected {
            color: $blue;
        }

        label {
            pointer-events: none;
            display: block;
            text-align: center;
            font-size: 12px;
            color: rgba($black, 0.5);
        }

        span {
            pointer-events: none;
            font-family: 'Rubik', sans-serif;
            font-size: 25px;
            margin-top: 5px;
            display: block;
            text-align: center;
        }
    }

    a {
        text-decoration: none;
        color: $black;
        display: block;
        padding: 20px;
        text-align: center;
        height: 100%;

        &:hover {
            color: $blue;
        }
    }
}
</style>
