var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.pendingPaymentPlanInvoicesByDate, function (invoiceGroup) {
        return _c(
          "div",
          {
            key: invoiceGroup.label,
            staticClass: "invoice-date-group",
            class: { due: invoiceGroup.due },
          },
          [
            _c("div", { staticClass: "group-label" }, [
              _vm._v(" " + _vm._s(invoiceGroup.label) + " "),
            ]),
            _c(
              "div",
              { staticClass: "payment-plan-invoices" },
              _vm._l(invoiceGroup.invoices, function (invoice) {
                return _c("payment-plan-invoice-block", {
                  key: invoice.id,
                  attrs: {
                    invoice: invoice,
                    project_ref: invoice.projectRef,
                    estimate: invoice.estimate,
                    invoices_count: invoice.planQuantity,
                    consecutive: false,
                  },
                  on: { click: _vm.handleClickPaymentPlanInvoice },
                })
              }),
              1
            ),
          ]
        )
      }),
      _c("div", { staticClass: "invoice-unscheduled-group" }, [
        _c("div", { staticClass: "group-label" }, [_vm._v(" Unscheduled ")]),
        _c(
          "div",
          { staticClass: "payment-plan-invoices" },
          _vm._l(_vm.pendingPaymentPlansUnscheduled, function (invoice) {
            return _c("payment-plan-invoice-block", {
              key: invoice.id,
              attrs: {
                invoice: invoice,
                project_ref: invoice.projectRef,
                estimate: invoice.estimate,
                invoices_count: invoice.planQuantity,
                consecutive: false,
              },
              on: { click: _vm.handleClickPaymentPlanInvoice },
            })
          }),
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }