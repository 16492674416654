/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus-circle': {
    width: 31,
    height: 31,
    viewBox: '0 0 31 31',
    data: '<g _fill="#010002" fill-rule="nonzero"><path pid="0" d="M15.529 31.059C6.966 31.059 0 24.092 0 15.529 0 6.966 6.966 0 15.529 0c8.563 0 15.529 6.966 15.529 15.529.001 8.563-6.966 15.53-15.529 15.53zm0-29.285c-7.585 0-13.755 6.171-13.755 13.755 0 7.584 6.17 13.754 13.755 13.754 7.584 0 13.754-6.17 13.754-13.754S23.113 1.774 15.529 1.774z"/><path pid="1" d="M21.652 16.416H9.406a.887.887 0 110-1.775h12.246a.888.888 0 010 1.775z"/><path pid="2" d="M15.529 22.539a.888.888 0 01-.888-.887V9.406a.889.889 0 011.775 0v12.246a.886.886 0 01-.887.887z"/></g>'
  }
})
