import {Node, nodeInputRule} from '@tiptap/core';
import {VueNodeViewRenderer} from '@tiptap/vue-2';

import {nodePasteRule} from './nodePasteRule';

import LinkNodeView from './LinkNode.vue';
import {getTitleForUrlString} from '@/utils/links';

const paste_rgx = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.?[a-zA-Z0-9()]{0,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/gi;
const input_rgx = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.?[a-zA-Z0-9()]{0,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))\s/i;

const LinkNode = Node.create({
    name: 'link_node',
    priority: 1000,

    inline: true,

    group: 'inline',

    addAttributes() {
        return {
            url: {
                default: null,
            },
        };
    },

    renderHTML(node) {
        return [
            'a',
            {
                'data-type': 'link_node',
                'data-url': node.HTMLAttributes.url,
                href: node.HTMLAttributes.url,
            },
            getTitleForUrlString(node.HTMLAttributes.url),
        ];
    },

    renderText({node}) {
        return node.attrs.url;
    },

    addNodeView() {
        return VueNodeViewRenderer(LinkNodeView);
    },

    /*addInputRules() {
        return [
            nodeInputRule(input_rgx, this.type, (match) => {
                return {url: match[0]};
            }),
        ];
    }, */

    addProseMirrorPlugins() {
        return [
            nodePasteRule(paste_rgx, this.type, (match) => {
                return {url: match[0]};
            }),
        ];
    },
});

export default LinkNode;
