/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cube-face': {
    width: 97,
    height: 86,
    viewBox: '0 0 97 86',
    data: '<path pid="0" d="M95.4 75.2L54.6 4.5c-2.7-4.7-9.4-4.7-12.1 0L1.7 75.2c-2.7 4.7.7 10.5 6.1 10.5h81.7c5.2 0 8.6-5.9 5.9-10.5z" _fill="#000" fill-rule="nonzero"/>'
  }
})
