<template>
    <div class="todo-item-content" @click="$emit('click')">
        <p class="wrapper">
            <component
                :is="chunk.component"
                v-for="(chunk, idx) of text_parts"
                :key="idx"
                v-bind="chunk"
            />
        </p>
    </div>
</template>

<script>
import TodoText from './TodoText';
import PrettyLink from '@/components/links/PrettyLink';

export default {
    name: 'todo-item-content',
    props: {
        text: {
            type: String,
            default: '',
        },
        date: {
            type: String,
            default: '',
        },
        jira_project: {
            type: String,
            default: null,
        },
        jira_org: {
            type: String,
            default: null,
        },
    },
    computed: {
        text_parts() {
            if (!this.text) return [];
            return this.detectLinks(this.text);
        },
    },

    methods: {
        detectLinks(textToCheck) {
            // eslint-disable-next-line no-useless-escape
            // const expression = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/gi;
            const expression = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.?[a-zA-Z0-9()]{0,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/gi;
            const regex = new RegExp(expression);
            const splitText = [];

            let match = null;
            let startIndex = 0;

            while ((match = regex.exec(textToCheck)) != null) {
                splitText.push({
                    type: 'text',
                    text: textToCheck.substr(
                        startIndex,
                        match.index - startIndex
                    ),
                    component: TodoText,
                });

                const link = textToCheck.substr(match.index, match[0].length);

                splitText.push({
                    link: link,
                    type: 'link',
                    component: PrettyLink,
                });

                startIndex = match.index + match[0].length;
            }

            if (startIndex < textToCheck.length)
                splitText.push({
                    type: 'text',
                    text: textToCheck.substr(startIndex),
                    component: TodoText,
                });

            const checkForJira = splitText.reduce((acc, m) => {
                if (m.type === 'text') {
                    if (this.jira_org && this.jira_project) {
                        const expr = new RegExp(
                            `(${this.jira_project}-[0-9]{1,})`,
                            'i'
                        );

                        let mtchs = null;
                        let text = m.text;
                        let idx = 0;

                        while ((mtchs = expr.exec(text)) !== null) {
                            const len = mtchs[0].length;
                            const start = mtchs.index;

                            if (start !== 0) {
                                acc.push({
                                    type: 'text',
                                    text: text.substr(0, start),
                                    component: TodoText,
                                });
                            }

                            acc.push({
                                link: `https://${
                                    this.jira_org
                                }.atlassian.net/browse/${mtchs[0].toUpperCase()}`,
                                type: 'link',
                                component: PrettyLink,
                            });

                            idx = mtchs.index + len;
                            text = text.substr(idx);
                        }

                        acc.push({
                            type: 'text',
                            text: text,
                            component: TodoText,
                        });

                        return acc;
                    }
                }

                acc.push(m);

                return acc;
            }, []);

            return checkForJira;
        },
    },
};
</script>

<style lang="scss" scoped>
.todo-item-content {
    flex: 1;
    display: flex;
    align-items: center;

    .wrapper {
        margin: 0;
        line-height: 20px;
    }
}
</style>
