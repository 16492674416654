/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jira-action': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g transform="translate(1 1)" _fill="none" fill-rule="evenodd"><rect pid="0" _fill="#4BAEE8" width="14" height="14" rx="2"/><rect pid="1" _stroke="#FFF" x="3" y="3" width="5" height="5" rx=".8"/><rect pid="2" _stroke="#FFF" _fill="#FFF" x="6" y="6" width="5" height="5" rx=".8"/></g>'
  }
})
