<template>
    <div class="wrapper">
        <div class="paymentplan-form">
            <div class="paymentplan-list-header">
                <el-row :gutter="5" type="flex" align="middle">
                    <el-col :span="10" class="description">
                        Line item
                    </el-col>

                    <el-col :span="3">
                        <span>Amount</span>
                    </el-col>

                    <el-col :span="2">
                        <span>Discount</span>
                    </el-col>

                    <el-col :span="3">
                        <span>Cost</span>
                    </el-col>

                    <el-col :span="2">
                        <span>Inv. %</span>
                    </el-col>

                    <el-col :span="3">
                        <span>Remaining</span>
                    </el-col>

                    <el-col :span="1" />

                    <el-col :span="2" class="center">
                        <span>%</span>
                    </el-col>

                    <el-col :span="3" class="center">
                        <span>Amount</span>
                    </el-col>
                </el-row>
            </div>

            <div class="paymentplan-form-content">
                <payment-plan-form-line
                    v-for="line_item in sorted_line_items"
                    :key="line_item.id"
                    :line_item="line_item"
                    @update="updateLineItem"
                />

                <hr />

                <div class="paymentplan-form-header">
                    <el-row
                        :gutter="5"
                        type="flex"
                        align="middle"
                        style="margin-bottom: 10px;"
                    >
                        <el-col :span="14">
                            Invoice reference template:
                        </el-col>
                        <el-col :span="10">
                            Purchase order:
                        </el-col>
                    </el-row>
                </div>

                <el-row
                    :gutter="5"
                    class="paymentplan-form-fields"
                    type="flex"
                    align="middle"
                >
                    <el-col :span="14" class="end-field">
                        <el-input
                            v-model="form.reference"
                            class="input-value left"
                        >
                            <el-button
                                slot="prepend"
                                icon="el-icon-question"
                                @click="toggleReferenceTemplateHelp"
                            />
                            <span slot="append" style="font-size: 12px;">
                                Preview:
                                <b>{{ reference_preview }}</b>
                            </span>
                        </el-input>
                    </el-col>

                    <el-col :span="10" class="end-field">
                        <el-input
                            v-model="form.purchase_order"
                            class="input-value left"
                        >
                        </el-input>
                    </el-col>
                </el-row>

                <div class="paymentplan-form-header">
                    <el-row
                        :gutter="5"
                        type="flex"
                        align="middle"
                        style="margin-bottom: 10px;"
                    >
                        <el-col :span="scheduled_width" class="left">
                            <div v-if="multiple_invoices">Define schedule</div>
                        </el-col>
                        <el-col v-if="show_schedule" :span="5">
                            Occurs every
                        </el-col>
                        <el-col v-if="show_schedule" :span="3">
                            Starting on
                        </el-col>
                        <el-col :span="7">
                            Due date terms
                        </el-col>

                        <el-col :span="2" class="center">
                            # invoices
                        </el-col>
                    </el-row>
                </div>

                <el-row
                    :gutter="5"
                    class="paymentplan-form-fields"
                    type="flex"
                    align="middle"
                >
                    <el-col :span="scheduled_width" class="left">
                        <el-switch
                            v-if="multiple_invoices"
                            v-model="is_scheduled"
                            @change="toggleScheduled"
                        />
                    </el-col>
                    <el-col v-if="show_schedule" :span="2">
                        <el-input-number
                            v-model="form.occurrence_interval"
                            class="input-value"
                            :controls="false"
                        />
                    </el-col>
                    <el-col v-if="show_schedule" :span="3" class="end-field">
                        <el-select v-model="form.occurrence_period">
                            <el-option
                                :value="PAYMENT_PLAN_FREQUENCY.WEEK"
                                label="week(s)"
                            />
                            <el-option
                                :value="PAYMENT_PLAN_FREQUENCY.MONTH"
                                label="month(s)"
                            />
                        </el-select>
                    </el-col>
                    <el-col v-if="show_schedule" :span="3" class="end-field">
                        <el-date-picker
                            v-model="form.start_date"
                            format="dd/MM/yyyy"
                            :picker-options="{
                                disabledDate: disabledStartDate,
                            }"
                        />
                    </el-col>
                    <el-col :span="2">
                        <el-input-number
                            v-model="form.due_value"
                            class="input-value"
                            :controls="false"
                            :min="1"
                            @focus="selectInput"
                        />
                    </el-col>
                    <el-col :span="5" class="end-field">
                        <el-select v-model="form.due_terms">
                            <el-option
                                :value="PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_DATE"
                                label="days after invoice date"
                            />
                            <el-option
                                :value="
                                    PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_END_OF_MONTH
                                "
                                label="days after end of invoice month"
                            />
                            <el-option
                                :value="PAYMENT_PLAN_DUE_TERMS.DAY_OF_MONTH"
                                label="of the current month"
                            />
                            <el-option
                                :value="
                                    PAYMENT_PLAN_DUE_TERMS.DAY_OF_NEXT_MONTH
                                "
                                label="of the following month"
                            />
                        </el-select>
                    </el-col>

                    <el-col :span="2" class="center">
                        <el-input-number
                            v-model="form.quantity"
                            class="input-value"
                            :controls="false"
                            :min="1"
                            @focus="selectInput"
                        />
                    </el-col>
                </el-row>

                <paymentplan-reference-help
                    v-if="showReferenceTemplateHelp"
                    @close="toggleReferenceTemplateHelp"
                />
            </div>
        </div>

        <div class="paymentplan-form-controls">
            <span> </span>
            <el-button
                :disabled="none_selected"
                @click="handleCreatePaymentPlan"
            >
                Continue
            </el-button>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';

import PaymentPlanFormLine from './PaymentPlan_form_line';
import ReferenceTemplateMixin from '@/mixins/referencetemplate.mixin';

import {PAYMENT_PLAN_FREQUENCY, PAYMENT_PLAN_DUE_TERMS} from '@/enums';

import PaymentplanReferenceHelp from './PaymentPlan_reference_help';
import PaymentplanMixin from '@/mixins/paymentplan.mixin';

export default {
    name: 'paymentplan-form',
    components: {
        PaymentplanReferenceHelp,
        PaymentPlanFormLine,
    },
    mixins: [ReferenceTemplateMixin, PaymentplanMixin],
    props: {
        estimate: {
            type: Object,
            required: true,
        },
        line_items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            PAYMENT_PLAN_FREQUENCY: PAYMENT_PLAN_FREQUENCY,
            PAYMENT_PLAN_DUE_TERMS: PAYMENT_PLAN_DUE_TERMS,
            is_scheduled: false,
            lineItemMap: {},
            form: {
                reference: '{est}_{seq}',
                purchase_order: null,
                occurrence_interval: 1,
                occurrence_period: PAYMENT_PLAN_FREQUENCY.MONTH,
                start_date: dayjs(),
                due_value: 14,
                due_terms: PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_DATE,
                quantity: 1,
            },
            showReferenceTemplateHelp: false,
        };
    },
    computed: {
        scheduled_width() {
            return this.show_schedule ? 7 : 15;
        },
        sorted_line_items() {
            return Object.values(this.lineItemMap).sort((a, b) => {
                return a.sort - b.sort;
            });
        },
        selected_line_items() {
            return Object.values(this.lineItemMap)
                .filter((item) => item.selected)
                .sort((a, b) => {
                    return a.sort - b.sort;
                });
        },

        none_selected() {
            return this.selected_line_items.length === 0;
        },

        reference_preview() {
            let reference = this.processTemplate(
                this.form.reference,
                this.form.start_date,
                1,
                this.form.quantity
            );
            return reference;
        },

        multiple_invoices() {
            return this.form.quantity > 1;
        },

        show_schedule() {
            return this.is_scheduled && this.multiple_invoices;
        },
    },
    watch: {
        line_items: {
            handler() {
                this.setLineItemSelection(true);
            },
            immediate: true,
        },
    },
    methods: {
        updateLineItem(lineItem) {
            this.lineItemMap[lineItem.id] = lineItem;
        },

        setLineItemSelection(selected) {
            this.lineItemMap = this.line_items.reduce((map, item) => {
                map[item.id] = {
                    ...item,
                    selected: selected,
                    selected_amount: item.selected_amount || item.cost,
                };
                return map;
            }, {});
        },

        toggleReferenceTemplateHelp() {
            this.showReferenceTemplateHelp = !this.showReferenceTemplateHelp;
        },

        toggleScheduled() {
            // reset start date to today if not scheduled
            if (!this.is_scheduled) {
                this.form.start_date = dayjs();
            }
        },

        disabledStartDate(date) {
            // if (dayjs().startOf('day').isSameOrAfter(date)) return true;
            // return false;
        },

        handleCreatePaymentPlan() {
            this.$emit('create', {
                is_scheduled: this.show_schedule,
                reference: this.form.reference,
                purchase_order: this.form.purchase_order,
                occurrence_interval: this.form.occurrence_interval,
                occurrence_period: this.form.occurrence_period,
                start_date: this.form.start_date,
                due_value: this.form.due_value,
                due_terms: this.form.due_terms,
                quantity: this.form.quantity,
                estimate: this.$fire.doc(
                    `estimates/${this.$options.filters.fireRef2id(
                        this.estimate.id
                    )}`
                ),
                selected_line_items: this.selected_line_items,
            });
        },
        selectInput(event) {
            setTimeout(() => {
                event.target.select();
            }, 50);
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    overflow: auto;

    hr {
        width: 100%;
        margin: 20px 0;
        border: none;
        height: 1px;
        background-color: $transparent-grey;
    }

    .el-date-editor {
        width: 100%;
    }

    .el-select {
        width: 100%;
    }

    .paymentplan-form {
        min-width: 810px;
        border-radius: 4px;
        padding: 10px 20px 20px 20px;
        border: 2px solid $border-grey-light;
        background-color: $grey;
    }

    .paymentplan-list-header,
    .paymentplan-form-header {
        font-size: 11px;
        margin: 10px 0 2px 0;

        span {
            padding-left: 15px;
        }

        .center {
            text-align: center;
        }
    }

    .paymentplan-form-fields {
        ::v-deep .input-value {
            width: 100%;

            &.read-only,
            &.read-only input,
            input:read-only {
                background: transparent;
                color: #a0a0a0;
                pointer-events: none;
                border: none;
            }

            input {
                padding-left: 5px !important;
                padding-right: 5px !important;
                text-align: center;
            }

            &.left input {
                text-align: left;
            }
        }
    }

    .paymentplan-form-controls {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
}
</style>
