var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.project
    ? _c(
        "div",
        {
          staticClass: "container",
          class: _vm.project.status + "-background",
          on: { click: _vm.handleClick },
        },
        [
          _c("span", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.project.name)),
          ]),
          _vm.total_invoiced
            ? _c("div", { staticClass: "total" }, [
                _c("label", [
                  _vm._v(_vm._s(_vm._f("currency")(_vm.total_invoiced))),
                ]),
                _c("span", [_vm._v("(ex. GST)")]),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }