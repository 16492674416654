<template>
    <div>
        <div class="container">
            <avatar class="avatar" :user="user" size="large" hide-tooltip />
            <file-uploader
                folder="avatars"
                :max_file_size_bytes="500 * 1024"
                @uploaded="(val) => update('avatar', val)"
            />
        </div>
        <div class="container">
            <label>Personal color</label>
            <color-picker
                :value="color"
                :show_dialog="show_dialog"
                @input="(val) => update('color', val)"
            />
        </div>

        <user-policy-settings :user="user" />

        <div
            v-if="
                !$store.getters.is_feature_enabled(
                    SUPPORTED_FEATURES.GOOGLE_AUTH
                )
            "
            class="container"
        >
            <label>Password</label>
            <div class="right">
                <el-button
                    @click="
                        $bus.$emit('modal:action', {
                            modal: 'change-password',
                            show: true,
                        })
                    "
                >
                    Change Password
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import FileUploader from '@/components/generic/FileUploader';
import ColorPicker from '@/components/generic/ColorPicker';
import Avatar from '@/components/generic/Avatar';
import UserPolicySettings from '@/components/settings/profile/UserPolicySettings';

import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';

export default {
    components: {Avatar, ColorPicker, FileUploader, UserPolicySettings},
    props: {
        user: {
            type: Object,
            required: true,
        },
        show_dialog: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            SUPPORTED_FEATURES: SUPPORTED_FEATURES,
        };
    },
    computed: {
        color() {
            return this.user?.color || null;
        },
    },
    methods: {
        update(key, val) {
            console.log('Updating user...', key, val);
            this.$fire
                .collection('users')
                .doc(this.user.id)
                .update({
                    [key]: val,
                });
        },
    },
};
</script>

<style lang="scss" scoped></style>
