<template>
    <el-tooltip
        class="avatar-wrapper"
        :class="size"
        :content="user.name"
        placement="bottom"
        :disabled="hide_tooltip"
        popper-class="avatar-tooltip"
    >
        <div>
            <div v-if="live" class="dot-container">
                <live-dot :animated="!color" :color="color" />
            </div>
            <div class="avatar">
                <span v-if="show_initials" class="initials">
                    {{ custom_initials || initials }}
                </span>
                <img v-else-if="image" :src="image" draggable="false" />
            </div>
        </div>
    </el-tooltip>
</template>

<script>
import LiveDot from '@/components/generic/LiveDot';

export default {
    name: 'avatar',
    components: {
        LiveDot,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        live: {
            type: Boolean,
            default: false,
        },
        hide_tooltip: {
            type: Boolean,
            default: false,
        },
        show_initials: {
            type: Boolean,
            default: false,
        },
        custom_initials: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: 'normal',
            validator: (val) => ['mini', 'normal', 'large'].includes(val),
        },
        color: {
            type: String,
            default: null,
        },
    },
    computed: {
        image() {
            const cached = JSON.parse(
                localStorage.getItem(`avatar_${this.user.id}`)
            );
            if (cached && cached.image) return cached.image;
            else if (this.user.avatar) return this.user.avatar;
            else return null;
        },
        initials() {
            if (this.user.name.toLowerCase() === 'all') return 'ALL';
            var initials = this.user.name.match(/\b\w/g) || [];
            return (
                (initials.shift() || '') + (initials.pop() || '')
            ).toUpperCase();
        },
    },
    watch: {
        user: function () {
            this.checkImage();
        },
    },
    mounted() {
        this.checkImage();
    },
    methods: {
        checkImage() {
            if (this.user.avatar) {
                const cached = JSON.parse(
                    localStorage.getItem(`avatar_${this.user.id}`)
                );
                if (
                    !cached ||
                    (cached && cached.url != this.user.avatar) ||
                    (cached && !cached.image)
                ) {
                    this.cacheUserImage(this.user);
                }
            } else {
                localStorage.removeItem(`avatar_${this.user.id}`);
            }
        },
        cacheUserImage(user) {
            if (!this.user.avatar) return;

            // Saving user avatar url
            try {
                localStorage.setItem(
                    `avatar_${user.id}`,
                    JSON.stringify({
                        user_id: user.id,
                        url: user.avatar,
                        image: null,
                    })
                );

                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = () => {
                    var reader = new FileReader();
                    reader.onloadend = () => {
                        // Saving user image data
                        try {
                            localStorage.setItem(
                                `avatar_${user.id}`,
                                JSON.stringify({
                                    user_id: user.id,
                                    url: user.avatar,
                                    image: reader.result,
                                })
                            );
                        } catch (e) {
                            console.warn(e);
                        }
                    };
                    reader.readAsDataURL(xhr.response);
                };
                xhr.open('GET', user.avatar);
                xhr.send();
            } catch (e) {
                console.warn(e);
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dot-container {
    position: absolute;
    margin-top: -3px;
    margin-left: -3px;
    padding: 2px;
    border-radius: 50%;
    background-color: white;
    z-index: 10;
    display: flex;

    .dot {
        margin: 0;
    }
}

.el-tooltip {
    &.large {
        height: 27px;
        line-height: 27px;
        min-width: 27px;
        width: 27px;
    }

    &.normal {
        height: 24px;
        line-height: 24px;
        min-width: 24px;
        width: 24px;
    }

    &.mini {
        height: 18px;
        line-height: 18px;
        min-width: 18px;
        width: 18px;
    }

    .initials {
        text-align: center;
        width: 100%;
        font-size: 8px;
        color: $black;
        font-weight: bold;
    }
    .avatar {
        outline: none;
        background: center/cover #d5dce0;
        border-radius: 50%;
        box-shadow: 0 0 0 2px, inset 0 0 0 1px rgba(0, 0, 0, 0.07);
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        color: #ffffff;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        display: inline-flex;
        position: relative;
        vertical-align: top;
        flex-shrink: 0;
        font-size: 11px;
        overflow: hidden;

        width: 100%;
        height: 100%;

        img {
            width: 100%;
            object-fit: cover;
        }
    }
}
</style>
