<template>
    <div v-if="is_template">
        <placeholder-block
            label="Module List"
            :block="block"
            @delete="deleteBlock"
        />
    </div>
    <div v-else>
        <el-card class="content-block estimate-summary" shadow="never">
            <div slot="header" class="heading">
                <div class="grip">⣿</div>
                <span class="label">Module list</span>
                <el-tooltip :content="fetchTooltip">
                    <el-popconfirm
                        title="Replace text block with fetched list of project modules?"
                        :disabled="loading"
                        @confirm="getModules"
                    >
                        <el-button
                            slot="reference"
                            class="control"
                            icon="el-icon-refresh"
                            plain
                        />
                    </el-popconfirm>
                </el-tooltip>
                <el-button
                    class="control"
                    plain
                    type="danger"
                    icon="el-icon-delete"
                    @click="deleteBlock"
                />
            </div>
            <text-editor
                ref="content_editor"
                :content.sync="editingBlock.content"
                :floating_menu="false"
                :drag_drop="false"
                @update:content="updateBlock"
            />
        </el-card>
    </div>
</template>

<script>
import userMixin from '@/mixins/user.mixin';
import PlaceholderBlock from '@/pages/documents/components/PlaceholderBlock';
import TextEditor from '@/components/editor/TextEditor';

export default {
    name: 'module-list-block',
    components: {TextEditor, PlaceholderBlock},
    mixins: [userMixin],
    props: {
        block: {
            type: Object,
            required: true,
        },
        project: {
            type: Object,
            default: null,
        },
        is_template: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            editingBlock: null,
            loading: false,
        };
    },
    computed: {
        fetchTooltip() {
            return 'Re-fetch modules list';
        },
    },
    watch: {
        block: {
            handler(newVal, oldVal) {
                this.editingBlock = {...newVal};
            },
            immediate: true,
        },
        project: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal && !this.editingBlock.content) {
                    this.getModules();
                }
            },
            immediate: true,
        },
    },
    methods: {
        async getModules() {
            if (this.is_template) return;
            if (!this.project) return;
            this.editingBlock.modules = this.$store.getters.modulesForProjectWithId(
                this.project.id
            );
            if (
                this.editingBlock.modules &&
                this.editingBlock.modules.length > 0
            ) {
                const modulesToText = this.editingBlock.modules.map(
                    (module) => {
                        return {
                            type: 'listItem',
                            content: [
                                {
                                    type: 'paragraph',
                                    content: [
                                        {
                                            type: 'text',
                                            marks: [{type: 'bold'}],
                                            text: ` ${module.type.value}`,
                                        },
                                        {
                                            type: 'text',
                                            text: ` - ${module.subtype.value}`,
                                        },
                                    ],
                                },
                            ],
                        };
                    }
                );
                const content = {
                    type: 'doc',
                    content: [
                        {
                            type: 'bulletList',
                            content: modulesToText,
                        },
                    ],
                };
                this.$refs.content_editor.setContent(content);
                this.editingBlock.content = content;
                this.updateBlock();
            } else {
                const value = 'Project has no modules';
                this.editingBlock.content = value;
                this.$refs.content_editor.setContent(value);
                this.editingBlock.content = value;
                this.updateBlock();
            }
        },
        updateBlock() {
            this.$emit('update', this.editingBlock);
        },

        deleteBlock() {
            this.$emit('delete', this.block.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.content-block {
    margin-bottom: 10px;
}
.heading {
    display: flex;
    align-items: center;

    .grip {
        width: 20px;
        height: 23px;
        text-align: left;
        opacity: 0.25;
        user-select: none;
        cursor: grab;
    }

    .label {
        font-weight: bold;
        font-size: 14px;
        color: $blue;
        margin-right: 20px;
        flex: 1;
    }
}
</style>
