<template>
    <div />
</template>

<script>
export default {
    // command attributes (referenced externally)
    command_props: {
        dependencies: [],
        prompt: '',
    },

    name: 'open-settings-command',
    components: {},
    props: {
        provided_data: {
            type: Object,
            required: true,
        },
        search_string: {
            type: String,
            required: false,
            default: null,
        },
    },
    mounted() {
        // no dependencies, immediately show settings
        this.$bus.$emit('modal:action', {modal: 'settings-modal', show: true});
        this.$emit('close');
    },
};
</script>
