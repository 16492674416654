<template>
    <el-select v-model="selectedProject" placeholder="No project selected">
        <el-option-group
            v-for="group of project_groups"
            :key="group.id"
            :label="group.label"
        >
            <el-option
                v-for="project of group.projects"
                :key="project.id"
                :value="project.id"
                :label="project.name"
            >
                <div class="project-row">
                    <span class="project-name">
                        {{ project.name }}
                    </span>
                    <span class="project-ref">
                        {{ project.ref }}
                    </span>
                </div>
            </el-option>
        </el-option-group>
    </el-select>
</template>

<script>
import {PROJECT_STATUS} from '@/enums';

export default {
    name: 'project-dropdown',
    model: {
        prop: 'project_id',
        event: 'change',
    },
    props: {
        project_id: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            selectedProject: null,
        };
    },
    computed: {
        projects() {
            return this.$store.getters.projects;
        },
        project_groups() {
            return this.projects
                .reduce((acc, curr) => {
                    let existing = acc.find((p) => p.id === curr.status);
                    if (!existing) {
                        existing = {
                            id: curr.status,
                            label: `${curr.status[0].toUpperCase()}${curr.status.substr(
                                1
                            )}`,
                            projects: [],
                        };

                        acc.push(existing);
                    }

                    existing.projects.push(curr);
                    return acc;
                }, [])
                .slice(0)
                .sort((a, b) => {
                    if (a.id === b.id) return 0;
                    if (a.id === PROJECT_STATUS.ACTIVE) return -1;
                    if (a.id === PROJECT_STATUS.MAINTENANCE) {
                        if (b.id === PROJECT_STATUS.ACTIVE) return 1;
                        return -1;
                    }
                    if (a.id === PROJECT_STATUS.LEAD) {
                        if (
                            b.id === PROJECT_STATUS.ACTIVE ||
                            b.id === PROJECT_STATUS.MAINTENANCE
                        )
                            return 1;
                        return -1;
                    }
                    if (a.id === PROJECT_STATUS.ARCHIVED) return 1;
                    return 1;
                });
        },
    },
    watch: {
        project_id: {
            handler(id) {
                this.selectedProject = id;
            },
            immediate: true,
        },
        selectedProject(project_id) {
            this.$emit('change', project_id);
        },
    },
};
</script>

<style lang="scss" scoped>
.project-row {
    display: flex;
    justify-content: space-between;
    .project-name {
        font-size: 13px;
        margin-right: 20px;
    }
    .project-ref {
        color: #8492a6;
        font-size: 11px;
    }
}
</style>
