<template>
    <content-block title="Tasks linking" :expanded="false">
        <div slot="right">
            <el-alert
                v-if="is_estimate_ticketed"
                type="success"
                show-icon
                :closable="false"
            >
                Tasks have been created
            </el-alert>
            <el-alert v-else-if="!can_create" type="warning" :closable="false">
                All groups must be linked to components before you can create
                task links
            </el-alert>
            <el-alert v-else-if="loading" type="info" :closable="false">
                Generating, you will be notified when complete
            </el-alert>
            <template v-else>
                <el-button
                    v-if="is_jira_available"
                    plain
                    :disabled="loading"
                    @click="handleCreateJiraTickets"
                >
                    Create Jira tickets
                </el-button>
                <el-button plain :disabled="loading" @click="handleCreateTodos">
                    Create TODOs
                </el-button>
            </template>
        </div>
    </content-block>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import estimateMixin from '@/mixins/estimate.mixin';
import {createJiraIssuesForEstimate} from '@/api/estimates';
import {Notification} from 'element-ui';

export default {
    name: 'estimate-task-linking',
    components: {
        ContentBlock,
    },
    props: {
        estimate: {
            type: Object,
            required: true,
        },
    },
    mixins: [estimateMixin],
    data() {
        return {
            groups: [],
            loading: false,
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(this.estimate.project);
        },
        is_estimate_ticketed() {
            return this.estimate.ticketed || false;
        },
        is_jira_available() {
            return (
                this.project &&
                this.project.jira_org &&
                this.project.jira_project
            );
        },
        can_create() {
            return this.sorted_groups.every((group) => {
                return (
                    group.ref_links?.['tracking_component'] &&
                    this.$store.getters.componentWithId(
                        group.ref_links?.['tracking_component']
                    )
                );
            });
        },
        // TODO this code is repeated elsewhere, refactor to mixin?
        sorted_groups() {
            // groups, sorted and filtered by exclusion status
            return [...this.groups]
                .filter((g) => !this.isGroupExcluded(g, this.groupTasks(g.id)))
                .sort((a, b) => {
                    return a.sort - b.sort;
                });
        },
        mapped_tasks() {
            // tasks list, with injected subtasks
            return this.tasks.map((t) => {
                return {
                    ...t,
                    id: t.id,
                    subtasks: this.subtasks.filter(
                        (s) => t.id === this.$options.filters.fireRef2id(s.task)
                    ),
                };
            });
        },
    },
    watch: {
        'estimate.ticketed': {
            handler(newVal, oldVal) {
                if (oldVal === true && newVal === false) {
                    this.loading = false;
                }
            },
        },
    },
    methods: {
        groupTasks(group_id) {
            return this.mapped_tasks.filter(
                (t) => group_id === this.$options.filters.fireRef2id(t.group)
            );
        },
        async createGroupTodos(groups, common_data) {
            const promises = [];
            const {project_ref, estimate_ref, hours_per_qty} = common_data;
            for (const group of groups) {
                const component = group.ref_links?.['tracking_component'];
                const group_todo = {
                    assigned_to: this.current_user.id,
                    user_id: this.current_user.id,
                    text: group.title,
                    priority: 0,
                    project: project_ref,
                    component: component,
                    source: estimate_ref,
                    due_by: null,
                    estimate: hours_per_qty * group.qty,
                    status: 'open',
                    parent: null,
                    linked_ref: this.$fire.doc(`estimate_groups/${group.id}`),
                };
                const group_todo_id = await this.$store.dispatch(
                    'addTodo',
                    group_todo
                );
                promises.push(
                    this.createTaskTodos(group.tasks, group_todo_id, {
                        project_ref,
                        estimate_ref,
                        hours_per_qty,
                        component,
                    })
                );
            }
            return Promise.all(promises);
        },
        async createTaskTodos(tasks, parent_todo_id, common_data) {
            const promises = [];
            const {
                project_ref,
                estimate_ref,
                hours_per_qty,
                component,
            } = common_data;
            for (const task of tasks) {
                const task_todo = {
                    assigned_to: this.current_user.id,
                    user_id: this.current_user.id,
                    text: task.description,
                    priority: 0,
                    project: project_ref,
                    component: component,
                    source: estimate_ref,
                    due_by: null,
                    estimate: hours_per_qty * task.qty,
                    status: 'open',
                    linked_ref: this.$fire.doc(`estimate_tasks/${task.id}`),
                    parent: this.$fire.doc(`todo/${parent_todo_id}`),
                };
                const task_todo_id = await this.$store.dispatch(
                    'addTodo',
                    task_todo
                );
                promises.push(
                    this.createSubTaskTodos(task.subtasks, task_todo_id, {
                        project_ref,
                        estimate_ref,
                        hours_per_qty,
                        component,
                    })
                );
            }
            return Promise.all(promises);
        },
        async createSubTaskTodos(
            subtasks,
            parent_todo_id,
            {project_ref, estimate_ref, hours_per_qty, component}
        ) {
            const promises = [];
            for (const subtask of subtasks) {
                const subtask_todo = {
                    assigned_to: this.current_user.id,
                    user_id: this.current_user.id,
                    text: subtask.description,
                    note: subtask.note || '',
                    priority: 0,
                    project: project_ref,
                    component: component,
                    source: estimate_ref,
                    due_by: null,
                    estimate: hours_per_qty * subtask.qty,
                    status: 'open',
                    linked_ref: this.$fire.doc(
                        `estimate_subtasks/${subtask.id}`
                    ),
                    parent: this.$fire.doc(`todo/${parent_todo_id}`),
                };
                promises.push(this.$store.dispatch('addTodo', subtask_todo));
            }
            return Promise.all(promises);
        },
        async handleCreateTodos() {
            this.loading = true;
            try {
                const project_ref = this.$fire.doc(this.estimate.project);
                const estimate_ref = this.$fire.doc(
                    `estimates/${this.estimate.id}`
                );
                await this.bindNestedEstimate(this.estimate_id);
                const hours_per_qty =
                    this.estimate.unit === 'day'
                        ? this.estimate.hours_per_day
                        : 1;
                const common_data = {
                    project_ref,
                    estimate_ref,
                    hours_per_qty,
                };
                await this.createGroupTodos(
                    this.nested_estimate.groups,
                    common_data
                );
                estimate_ref.set({ticketed: true}, {merge: true});
                this.$fire.collection('notifications').add({
                    for: this.$fire
                        .collection('users')
                        .doc(this.current_user.id),
                    type: 'TODO-SUCCESS',
                    title: 'Todos created',
                    target_id: this.estimate_id,
                    target_ref: estimate_ref,
                    description: `Todos have been created for estimate ${this.estimate_id}`,
                    project: project_ref,
                    created_at: new Date(),
                    read: false,
                    users: [],
                });
            } catch (err) {
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: err.message,
                });
                console.log('An error occurred linking estimate tasks', err);
            }
        },

        async handleCreateJiraTickets() {
            this.loading = true;

            // don't wait for response, notification will be sent on completion
            createJiraIssuesForEstimate(this.estimate.id);
        },
    },
    mounted() {
        this.$bind(
            'groups',
            this.$fire
                .collection('estimate_groups')
                .where(
                    'estimate',
                    '==',
                    this.$fire.doc(`estimates/${this.estimate.id}`)
                ),
            {
                maxRefDepth: 0,
            }
        );
        this.$bind(
            'tasks',
            this.$fire
                .collection('estimate_tasks')
                .where(
                    'estimate',
                    '==',
                    this.$fire.doc(`estimates/${this.estimate.id}`)
                ),
            {
                maxRefDepth: 0,
            }
        );
        this.$bind(
            'subtasks',
            this.$fire
                .collection('estimate_subtasks')
                .where(
                    'estimate',
                    '==',
                    this.$fire.doc(`estimates/${this.estimate.id}`)
                ),
            {
                maxRefDepth: 0,
            }
        );
    },
};
</script>

<style lang="scss" scoped></style>
