<template>
    <el-select
        v-if="project"
        ref="select"
        filterable
        :filter-method="filterComponents"
        :value="component"
        :placeholder="placeholder"
        value-key="id"
        size="mini"
        @change="(val) => $emit('change', val)"
    >
        <!-- HIGHLIGHTED COMPONENTS -->
        <el-option-group
            key="highlighted"
            :label="`Highlighted:`"
            class="highlighted-option-group"
        >
            <el-option
                v-for="c in highlighted_components"
                :key="c.id"
                :label="`${c.name}`"
                :value="c"
            >
                <span class="component-name">
                    {{ c.name }}
                </span>
                <span v-if="c.ref" class="component-ref">
                    {{ c.ref }}
                </span>
            </el-option>
        </el-option-group>

        <!-- STAGES -> COMPONENTS -->
        <el-option-group
            v-for="s in stages"
            :key="s.id"
            :label="`Stage #${s.number}${s.notes ? ` - ${s.notes}` : ''}`"
            class="stage-option-group"
            :class="s.status"
        >
            <el-option
                v-for="c in s._components"
                :key="c.id"
                :label="`${c.name} - Stage #${s.number}`"
                :value="c"
                class="component-option"
                :class="c.status"
            >
                <span class="component-name">
                    {{ c.name }}
                </span>
                <span v-if="c.ref" class="component-ref">
                    {{ c.ref }}
                </span>
            </el-option>
        </el-option-group>
    </el-select>
</template>

<script>
export default {
    name: 'component-picker',
    props: {
        project_id: {
            type: String,
            required: true,
        },
        component: {
            type: Object,
            default: null,
        },
        placeholder: {
            type: String,
            default: 'Select component',
        },
    },
    data() {
        return {
            filter: '',
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(this.project_id);
        },
        stages() {
            if (!this.project) return [];

            const stages = this.$store.getters.stagesForProjectWithId(
                this.project.id
            );

            // return only stages containing components whose name or ref matches the current filter
            return stages
                .map((stage) => {
                    const components = this.$store.getters.componentsForStageWithId(
                        stage.id
                    );
                    if (this.filter !== '') {
                        stage._components = components.filter(
                            (component) =>
                                component.name
                                    .toLowerCase()
                                    .includes(this.filter) ||
                                (component.ref
                                    ?.toLowerCase()
                                    .includes(this.filter) ??
                                    false)
                        );
                    } else {
                        stage._components = components;
                    }
                    return stage;
                })
                .filter((stage) => stage._components.length > 0);
        },
        highlighted_components() {
            let components = this.project.components;
            if (!components) return [];

            components = components
                .map((component) =>
                    this.$store.getters.componentWithId(component)
                )
                .filter((component) => component.highlight);
            if (this.filter !== '') {
                return components.filter(
                    (component) =>
                        component.name.toLowerCase().includes(this.filter) ||
                        (component.ref?.toLowerCase().includes(this.filter) ??
                            false)
                );
            } else {
                return components;
            }
        },
    },
    methods: {
        filterComponents(value) {
            this.filter = value.toLowerCase();
        },
    },
};
</script>

<style lang="scss">
//.highlighted-option-group {}
.stage-option-group {
    &.active {
        .el-select-group__title {
            color: $black;
        }
    }
    &.completed {
        .el-select-group__title {
            opacity: 0.5;
        }
    }

    .component-option {
        &.standby,
        &.completed {
            span {
                opacity: 0.4;
            }
        }
    }
}

.component-name {
    float: left;
}
.component-ref {
    float: right;
    color: #8492a6;
    font-size: 13px;
}
</style>
