<template>
    <div>
        <el-row>
            <el-col
                v-for="row in range(0, 1)"
                :key="`row_${row}`"
                :span="12"
                :md="12"
                :sm="24"
                :xs="24"
            >
                <ul>
                    <flat-cube-face
                        v-for="face_id in range(row * 4, 4 + row * 4 - 1)"
                        :id="'face_' + face_id"
                        :key="face_id"
                        :face_id="face_id"
                        @click="$emit('selected', face_id)"
                        @showTrackerDetail="handleShowTrackerDetail"
                    />
                </ul>
            </el-col>
        </el-row>

        <tracker-detail
            :show_dialog.sync="show_dialog"
            :face_id="selected_face_id"
        />
    </div>
</template>

<script>
import FlatCubeFace from './FlatCubeFace';
import TrackerDetail from './TrackerDetail';

export default {
    name: 'flat-cube',
    components: {
        FlatCubeFace,
        TrackerDetail,
    },
    data() {
        return {
            show_dialog: false,
            selected_face_id: null,
        };
    },
    methods: {
        range(start, end) {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        },
        handleShowTrackerDetail(face_id) {
            this.selected_face_id = face_id;
            this.show_dialog = true;
        },
    },
};
</script>

<style lang="scss" scoped>
ul {
    list-style: none;
    padding: 0;
}
</style>
