<template>
    <div key="stage" class="selection">
        <h2 class="title">Choose stage:</h2>
        <hr />
        <div class="back" @click="$emit('onBack')">
            <svgicon name="triangle" class="triangle"></svgicon>
            <label>BACK <span>/</span></label>
            <span>{{ selected_project.name }}</span>
        </div>
        <hr />
        <ul v-if="highlighted_components.length" class="highlighted-components">
            <component-selector-block
                v-for="component in highlighted_components"
                :key="component.id"
                :component="component"
                @onSelection="$emit('onSelection', component)"
            />
        </ul>
        <ul v-if="stages" class="stages">
            <li
                v-for="stage in stages"
                :key="stage.id"
                class="stage"
                @click="$emit('onSelection', stage)"
            >
                <span>Stage #{{ stage.number }}</span>
                <p v-if="stage.notes">{{ stage.notes }}</p>
            </li>
        </ul>
        <el-alert
            v-else
            style="margin-top: 10px;"
            description="No stages available"
            :closable="false"
        />
    </div>
</template>

<script>
import {STAGE_STATUS} from '@/enums';
import ComponentSelectorBlock from '@/components/blocks/ComponentSelectorBlock';

export default {
    name: 'stage-selector',
    components: {ComponentSelectorBlock},
    props: {
        selected_project: {
            type: Object,
            required: true,
        },
    },
    computed: {
        stages() {
            const stages = this.$store.getters
                .stagesForProjectWithId(this.selected_project.id)
                .filter((i) => i.status === STAGE_STATUS.ACTIVE);
            if (stages.length === 0) return null;
            return stages;
        },
        highlighted_components() {
            const components = this.selected_project.components;
            if (!components) return [];

            return components
                .map((component) =>
                    this.$store.getters.componentWithId(component)
                )
                .filter((c) => c.highlight);
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.selection {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 5px;

    h2.title {
        font-size: 15px;
    }

    ul.highlighted-components {
        padding: 10px 2px 0px;
        margin: 0;
    }

    ul.stages {
        padding: 10px 2px 20px 2px;
        margin: 0;
        overflow: scroll;

        li.stage {
            cursor: pointer;
            margin: 0 0 10px 0;
            padding: 20px 20px;
            border-radius: 5px;
            background-color: white;
            box-shadow: 0 0 0 1px $border-grey-light;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;

            &:hover {
                box-shadow: 0 0 0 2px $blue;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .back {
        cursor: pointer;
        margin: 10px 2px;
        padding: 18px 20px;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0 0 0 1px $border-grey-light;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        &:hover {
            box-shadow: 0 0 0 2px $blue;
        }

        .triangle {
            color: $black;
            width: 10px;
            height: 10px;
            margin: 4px 10px 3px 0;
            transform: scaleX(-1) translateX(0);
            filter: FlipH;
            -ms-filter: 'FlipH';
            transition: transform 0.3s ease-in-out 0.1s;
        }

        label {
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            text-align: right;
            color: $black;
            text-transform: uppercase;
            margin-right: 4px;
            margin-top: 1px;
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;

            span {
                margin: 0 3px;
                font-size: 13px;
                margin-top: 1px;
            }
        }

        span {
            transition: transform 0.3s ease-in-out;
        }

        &:hover {
            .triangle {
                transform: scaleX(-1) translateX(3px);
            }

            label,
            span {
                transform: translateX(3px);
            }
        }
    }

    h2 + hr {
        margin-bottom: 0;
    }

    hr {
        width: 100%;
        margin: 0;
        border: none;
        height: 1px;
        background-color: $border-grey-light;
    }

    ul.stages {
        li {
            flex-direction: column;
            align-items: flex-start;

            span {
                display: block;
            }

            p {
                font-size: 12px;
                opacity: 0.6;
                margin-bottom: 0;
            }
        }
    }
}
</style>
