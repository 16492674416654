<template>
    <el-dialog :visible.sync="show" width="60%" @closed="onCloseDialog">
        <div v-if="face_id" class="modal-content">
            <transition name="fade" mode="out-in">
                <project-selector
                    v-if="!selected_project"
                    @onSelection="
                        (value) => {
                            selected_project = value;
                        }
                    "
                />
                <stage-selector
                    v-else-if="selected_project && !selected_stage"
                    :selected_project="selected_project"
                    @onBack="selected_project = null"
                    @onSelection="
                        (value) => {
                            // The selected object it's a component, not a stage
                            if (value.stage) {
                                selected_stage = null;
                                handleAssociation(value);
                            } else {
                                selected_stage = value;
                            }
                        }
                    "
                />
                <component-selector
                    v-else-if="selected_stage"
                    :selected_project="selected_project"
                    :selected_stage="selected_stage"
                    @onBack="selected_stage = null"
                    @onSelection="handleAssociation"
                />
            </transition>
        </div>
    </el-dialog>
</template>

<script>
import ProjectSelector from '@/components/generic/ProjectSelector';
import StageSelector from '@/components/generic/StageSelector';
import ComponentSelector from '@/components/generic/ComponentSelector';

import userMixin from '@/mixins/user.mixin';

export default {
    name: 'tracker-detail',
    components: {
        ProjectSelector,
        StageSelector,
        ComponentSelector,
    },
    mixins: [userMixin],
    props: {
        show_dialog: {
            type: Boolean,
            required: true,
        },
        face_id: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            show: false,

            selected_project: null,
            selected_stage: null,
            selected_component: null,
        };
    },
    watch: {
        show_dialog: function (val) {
            this.show = val;
        },
    },
    methods: {
        onCloseDialog() {
            this.$emit('update:show_dialog', false);

            this.selected_project = null;
            this.selected_stage = null;
            this.selected_component = null;
        },
        async handleAssociation(component) {
            this.selected_component = component;

            var update = {};
            update[`face2component.${this.face_id}`] = this.$fire.doc(
                `components/${this.selected_component.id.toString()}`
            );

            this.$fire
                .doc(`cubes/${this.current_user.id.toString()}`)
                .update(update);

            this.onCloseDialog();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
    .el-dialog__header {
        padding: 0;
    }
    .el-dialog__body {
        padding: 0 20px;

        .modal-content {
            height: calc(100vh - 33vh);
            overflow: hidden;
            display: flex;
        }
    }
}
</style>
