import moment from 'moment';
import firebase from 'firebase/app';

const atlassianMixin = {
    methods: {
        processConfluenceTasks(tasks) {
            const processedTasks = [];
            tasks.forEach((task) => {
                // map each task's confluence space to TOOL project
                const project = this.$store.getters.activeProjectsWithConfluence.find(
                    (p) => {
                        return p.confluence_space == task.confluence.space;
                    }
                );
                if (project) task.project = `projects/${project.id}`;

                // convert epoch timestamps to Firebase timestamps for compatibility
                if (task.due_by) {
                    task.due_by = new firebase.firestore.Timestamp(
                        task.due_by / 1000,
                        0
                    );
                }
                if (task.updated_at) {
                    task.updated_at = new firebase.firestore.Timestamp(
                        task.updated_at / 1000,
                        0
                    );
                }
                // only include closed tasks that were updated recently
                if (task.status == 'closed') {
                    const updatedAt = this.$options.filters.tstamp2moment(
                        task.updated_at
                    );
                    if (updatedAt && moment().diff(updatedAt, 'hours') < 24) {
                        processedTasks.push(task);
                    }
                } else {
                    processedTasks.push(task);
                }
            });
            return processedTasks;
        },
    },
};

export default atlassianMixin;
