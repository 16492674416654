<template>
    <div class="user-block">
        <div class="head">
            <avatar class="avatar desktop-only" :user="user" hide-tooltip />
            <span class="name">{{ user.name }}</span>
        </div>

        <tracking-block :user="user" :editable="false" />
    </div>
</template>

<script>
import Avatar from '@/components/generic/Avatar';
import TrackingBlock from '@/components/blocks/TrackingBlock';

export default {
    name: 'user-block',
    components: {
        Avatar,
        TrackingBlock,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-block {
    position: relative;
    min-height: 100px;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: $grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $black;

    @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
    }

    .head {
        width: 150px;
        display: flex;
        justify-content: flex-start;
        padding-right: 18px;

        .avatar {
            margin-right: 10px;
        }

        span.name {
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
        }
    }
}
</style>
