<template>
    <jira-link v-if="type === URL_TYPE.JIRA" :link="link" show_summary />
    <confluence-link v-else-if="type === URL_TYPE.CONFLUENCE" :link="link" />
    <tool-link v-else-if="type === URL_TYPE.TOOL" :link="link" :url="url" />
    <xero-link v-else-if="type === URL_TYPE.XERO" :link="link" />
    <a v-else :href="link" class="link" target="_blank" @click.stop="">
        <img
            v-if="display_favicon"
            :src="favicon"
            class="favicon"
            @error="fallbackIcon"
        />
        {{ title }}
    </a>
</template>

<script>
import JiraLink from './JiraLink';
import ConfluenceLink from './ConfluenceLink';
import ToolLink from './ToolLink';
import XeroLink from './XeroLink';

import url from 'url';
import {getTitleForUrlString, getUrlType, URL_TYPE} from '@/utils/links';

export default {
    name: 'pretty-link',
    components: {
        JiraLink,
        ConfluenceLink,
        ToolLink,
        XeroLink,
    },
    props: {
        link: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            display_favicon: true,
        };
    },
    computed: {
        URL_TYPE() {
            return URL_TYPE;
        },
        type() {
            return getUrlType(this.link);
        },
        url() {
            return url.parse(this.link);
        },
        hostname() {
            return this.url.hostname;
        },
        title() {
            return getTitleForUrlString(this.link);
        },
        favicon() {
            return `https://${this.hostname}/favicon.ico`;
        },
    },
    watch: {
        hostname() {
            this.display_favicon = true;
        },
    },
    methods: {
        fallbackIcon() {
            this.display_favicon = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.link {
    color: $black;
    text-decoration: none;
    border-radius: 4px;
    font-size: 14px;

    &:hover {
        color: $blue;
    }

    .favicon {
        height: 10px;

        &.failed {
            margin-right: 0px;
        }
    }
}
</style>
