<template>
    <content-block title="Scope">
        <template slot="right">
            <template v-if="is_editable">
                <el-button v-if="!editing" size="mini" @click="editScope">
                    Edit
                </el-button>
                <el-button v-if="editing" size="mini" @click="stopEditingScope">
                    Cancel
                </el-button>
                <el-button
                    v-if="editing"
                    type="primary"
                    size="mini"
                    @click="saveScope"
                >
                    Save
                </el-button>
            </template>
            <template v-if="!editing">
                <el-button
                    v-if="is_scope_assigned_to_me"
                    size="mini"
                    type="primary"
                    @click="confirmScope"
                >
                    Confirm scope
                </el-button>
                <template v-if="is_waiting_for_approval">
                    <el-button
                        :key="
                            is_sent_for_approval
                                ? 'send-button'
                                : 'resend-button'
                        "
                        size="mini"
                        :type="is_sent_for_approval ? 'secondary' : 'primary'"
                        @click="sendToClient"
                    >
                        {{ is_sent_for_approval ? 'Re-send' : 'Send' }} to
                        client
                    </el-button>
                    <el-button
                        v-if="is_sent_for_approval"
                        size="mini"
                        type="primary"
                        @click="confirmPreApproval"
                    >
                        Confirm pre-approval
                    </el-button>
                </template>

                <!-- FINISH QUOTING -->
                <!--*******************************************-->

                <el-button
                    v-if="is_estimate_assigned"
                    size="mini"
                    type="primary"
                    @click="finishQuoting"
                >
                    Finish quoting
                </el-button>

                <el-button
                    v-if="is_waiting_for_contract"
                    size="mini"
                    type="primary"
                    @click="confirmContract"
                >
                    Confirm contract
                </el-button>
            </template>
        </template>

        <h2 v-if="!editing">{{ change_request.title }}</h2>
        <el-input v-else v-model="change_request.title" class="title-input" />

        <text-editor
            ref="content_editor"
            :content.sync="change_request.content"
            placeholder="Add scope info here…"
            :editable="editing"
        />

        <text-editor
            v-show="!(is_closed && !change_request.note)"
            ref="note_editor"
            :content.sync="change_request.note"
            style="margin-top: 10px;"
            placeholder="Add notes here…"
            :editable="!is_closed"
            @update:content="saveNote"
        />
    </content-block>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import TextEditor from '@/components/editor/TextEditor';

import debounce from 'lodash.debounce';
import firebase from 'firebase/app';
import userMixin from '@/mixins/user.mixin';

import {CR_STATUS} from '@/enums';

export default {
    name: 'change-request-scope',
    components: {
        ContentBlock,
        TextEditor,
    },
    mixins: [userMixin],
    props: {
        change_request: {
            type: Object,
            required: true,
        },
        project: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            editing: false,
            closing: false,
        };
    },
    computed: {
        component() {
            if (!this.change_request.component) return null;
            return this.$store.getters.componentWithId(
                this.change_request.component
            );
        },
        is_editable() {
            return this.change_request.status < CR_STATUS.QUOTING;
        },
        is_scope_assigned_to_me() {
            // CR is not in 'scoping' state
            // Scope can't be confirmed
            if (this.change_request.status !== CR_STATUS.SCOPING) return false;

            // CR has not being assigned for scoping
            // Scope can't be confirmed
            if (!this.change_request.assigned_to_for_scoping) return false;

            // CR is assigned to current user
            if (
                this.$options.filters.fireRef2id(
                    this.change_request.assigned_to_for_scoping
                ) === this.current_user.id
            )
                return true;

            return false;
        },
        is_waiting_for_approval() {
            return this.change_request.status === CR_STATUS.PRE_APPROVAL;
        },
        is_sent_for_approval() {
            return this.change_request.sent_to_client_at;
        },
        is_estimate_assigned() {
            return (
                this.change_request.status === CR_STATUS.QUOTING &&
                this.change_request.estimate
            );
        },
        is_waiting_for_contract() {
            return this.change_request.status === CR_STATUS.CONTRACT;
        },
        is_in_progress() {
            return this.change_request.status === CR_STATUS.IN_PROGRESS;
        },
        is_closed() {
            return this.change_request.status === CR_STATUS.CLOSED;
        },
        now() {
            return new Date();
        },
    },
    watch: {
        'change_request.ref': {
            handler(val, oldVal) {
                if (val && val !== oldVal) {
                    this.$refs.content_editor.setContent(
                        this.change_request.content
                    );
                    this.$refs.note_editor.setContent(this.change_request.note);
                }
            },
        },
    },
    methods: {
        editScope() {
            this.change_request.original_content = this.change_request.content;
            this.editing = true;

            // TODO: do we need to do this?
            // Clears client approval - considering the CR gets edited
            // and moves the CR to 'scoping' state
            // this.update({
            //     sent_to_client_at: null,
            //     scope_confirmed_at: null,
            //     status: CR_STATUS.SCOPING,
            // });
        },
        stopEditingScope() {
            this.editing = false;

            this.$refs.content_editor.setContent(
                this.change_request.original_content
            );

            delete this.change_request.original_content;
        },
        saveScope() {
            this.editing = false;

            this.update({
                title: this.change_request.title,
                content: this.change_request.content,
            });
        },
        confirmScope() {
            // Saves current date as reference for scope confirmation
            // and moves the CR to 'backlog' state
            this.update({
                scope_confirmed_at: this.now,
                status: CR_STATUS.PRE_APPROVAL,
            });
        },
        saveNote: debounce(function () {
            this.update({
                note: this.change_request.note,
            });
        }, 400),
        sendToClient() {
            // Saves current date as reference for submission
            // and handles submission to client

            this.update({
                sent_to_client_at: this.now,
            });

            // TODO: pre-fill the email
            // window.open('mailto:sim@codefishstudio.com', '_blank');
        },
        confirmPreApproval() {
            // Saves current date as reference for preapproval
            // and handles submission to client
            // moves to 'quoting' if the CR has not being quoted yet
            // otherwise jumps to 'contract'

            this.update({
                preapproval_confirmed_at: this.now,
                status: this.change_request.estimate
                    ? CR_STATUS.CONTRACT
                    : CR_STATUS.QUOTING,
            });
        },

        finishQuoting() {
            this.update({status: CR_STATUS.CONTRACT});
        },

        async confirmContract() {
            await this.update({
                contract_confirmed_at: this.now,
                status: CR_STATUS.IN_PROGRESS,
            });

            this.addTimeToComponent()
                .then(() => {
                    console.log('Time added to component.');
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async close() {
            this.closing = true;
            await this.$store.dispatch('bindProjectChangeRequests', {
                project: this.$route.params.project_id,
                closed: true,
            });

            this.update({
                closed_at: this.now,
                status: CR_STATUS.CLOSED,
            });

            this.closing = false;
        },
        update(data) {
            return this.$fire
                .collection('change_requests')
                .doc(this.change_request.id)
                .update(data);
        },
        addTimeToComponent() {
            if (this.component) {
                return this.$fire
                    .collection('components')
                    .doc(this.component.id)
                    .update({
                        time_changes: firebase.firestore.FieldValue.arrayUnion({
                            changed_by: this.$fire.doc(
                                `change_requests/${this.change_request.id}`
                            ),
                            created_at: new Date(),
                            key: 'time_estimate',
                            from: this.component['time_estimate'],
                            to:
                                this.component['time_estimate'] +
                                this.change_request.time_estimate,
                        }),
                        time_estimate: firebase.firestore.FieldValue.increment(
                            this.change_request.time_estimate
                        ),
                    });
            }
        },
        textUpdated(val) {
            console.log(val);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep.content-block {
    .left-col {
        min-height: 28px;
    }

    h2 {
        padding-left: 10px;
    }

    .title-input {
        input {
            background: unset;
            font-size: 1.5em;
            color: $black;
            font-family: Montserrat, sans-serif;
            margin-block-end: 0.83em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
        }
    }
}
</style>
