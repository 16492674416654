<template>
    <li
        class="pull-request"
        :class="{
            [`${pull_request.build.status.toLowerCase()}`]: true,
            selected,
        }"
        @click.stop="$emit('click', pull_request.id)"
    >
        <div class="title">
            <commit-build-status
                :class="pull_request.build.status.toLowerCase()"
                :pull_request="pull_request_name"
                :build_status="pull_request.build.status"
                :build_number="pull_request.build.number"
            />
        </div>
        <el-form inline>
            <div class="content">
                <div class="sub-content">
                    <div class="branches">
                        <el-tag size="mini" type="info">
                            {{ pull_request.source.branch.name }}
                        </el-tag>
                        <i class="el-icon-right" />
                        <el-tag size="mini" type="info">
                            {{ pull_request.destination.branch.name }}
                        </el-tag>
                    </div>
                    <div class="tag">
                        <template v-if="pr_tags.length > 0">
                            <el-tag
                                v-for="tag of pr_tags"
                                :key="tag.id"
                                size="mini"
                                type="primary"
                            >
                                <i class="el-icon-price-tag" />
                                {{ tag.name }}
                            </el-tag>
                        </template>
                    </div>
                    <div class="date">
                        {{ new Date(pull_request.updated_on) | timeAgo }}
                    </div>
                </div>
                <div class="commit-title">
                    <div v-if="selected" class="hash">
                        <el-form-item label="Title">
                            <el-input
                                v-model="form.title"
                                :readonly="!creating_release"
                                size="mini"
                                inline-message
                            />
                        </el-form-item>
                        <el-form-item label="Version">
                            <el-input
                                v-model="form.version"
                                :readonly="!creating_release"
                                size="mini"
                                inline-message
                            />
                        </el-form-item>
                    </div>
                    <div v-if="selected && !pr_release">
                        <template v-if="creating_release">
                            <el-button
                                size="mini"
                                type="primary"
                                @click="release"
                            >
                                Release
                            </el-button>
                            <el-button size="mini" @click="cancelRelease">
                                Cancel
                            </el-button>
                        </template>
                        <el-button v-else size="mini" @click="createRelease">
                            Create release
                        </el-button>
                    </div>
                </div>
                <template v-if="selected">
                    <text-editor
                        ref="editor"
                        :editable="creating_release"
                        class="detail"
                        :content="form.content"
                    />
                </template>
            </div>
        </el-form>
    </li>
</template>

<script>
import CommitBuildStatus from './CommitBuildStatus';
import TextEditor from '../editor/TextEditor';

export default {
    name: 'pull-request-block',
    components: {CommitBuildStatus, TextEditor},
    props: {
        selected: {
            type: Boolean,
            default: false,
        },
        pull_request: {
            type: Object,
            required: true,
        },
        module: {
            type: Object,
            required: true,
        },
        tags: {
            type: Array,
            default: () => [],
        },
        releases: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            creating_release: false,

            form: {
                title: null,
                content: null,
                version: null,
            },
        };
    },

    computed: {
        pull_request_name() {
            return `Pull Request #${this.pull_request.id}`;
        },

        pr_tags() {
            return this.tags.filter((t) => {
                const tlength = this.pull_request.merge_commit.hash.length;
                const shortHash = t.target.hash.substr(0, tlength);
                return shortHash === this.pull_request.merge_commit.hash;
            });
        },

        pr_release() {
            return (
                this.releases.find(
                    (r) => r.pull_request_id === this.pull_request.id
                ) ?? null
            );
        },
    },

    mounted() {
        this.form.title = this.pull_request.title;
        this.form.content = this.pull_request.summary.html;
        this.form.version = this.tag
            ? this.tag.name
            : `${this.build_number || this.pull_request.id}`;
    },

    methods: {
        createRelease() {
            this.creating_release = true;
        },

        cancelRelease() {
            this.creating_release = false;
        },

        release() {
            this.$emit('release:create', {
                pull_request_id: this.pull_request.id,
                ...this.form,
                content: this.$refs.editor.getContent(),
            });

            this.creating_release = false;
        },
    },
};
</script>

<style scoped lang="scss">
.pull-request {
    padding: 0;
    border: 1px solid $border-grey-light;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-right: 20px;
    position: relative;

    &.loading {
        opacity: 0.4;

        .title {
            background-color: lighten(#000, 70%);
        }
    }

    &.successful {
        border-color: $green;

        .title {
            background-color: $green;
        }
    }

    &.failed {
        border-color: $red;

        .title {
            background-color: $red;
        }
    }

    &.stopped {
        border-color: $orange;

        .title {
            background-color: $orange;
        }
    }

    &.inprogress {
        border-color: $blue;

        .title {
            background-color: $blue;
        }
    }

    &.no {
        &.build {
            border-color: lighten(#000, 70%);

            .title {
                background-color: lighten(#000, 70%);
            }
        }
    }

    &.selected {
        border-color: $blue !important;

        .title {
            background-color: rgba($blue, 0.2) !important;

            .build ::v-deep {
                color: $blue;
            }
        }
    }

    .title {
        padding: 10px 20px;
        color: $white;
        background-color: $border-grey-light;
    }

    .message {
        font-size: 12px;
        position: absolute;
        left: 540px;
        background-color: $white;
        box-shadow: 0px 0px 20px 0px rgba($black, 0.1);
        padding: 20px;
        border-radius: 10px;
        opacity: 0;
        width: 0px;
        margin-left: -100px;
        overflow: hidden;
        box-sizing: border-box;
    }

    .content {
        padding: 20px;

        .commit-title {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .hash {
                font-size: 14px;
                font-weight: bold;
            }
        }

        .sub-content {
            display: flex;
            justify-content: space-between;

            > div {
                flex: 1;
            }

            .date {
                font-size: 11px;
                color: rgba($black, 0.6);
                text-align: right;
            }

            .tag {
                text-align: center;
            }

            .branches {
                font-size: 12px;
                margin-bottom: 10px;
            }
        }
    }

    &:hover {
        .message {
            opacity: 1;
            width: 540px;
            margin-left: 0px;
        }
    }

    .detail ::v-deep {
        font-size: 13px;
        color: $black;

        ul {
            li {
                margin-bottom: 14px;
            }
        }
    }
}
</style>
