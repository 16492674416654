<template>
    <div class="tree-chart-container" :style="containerStyle">
        <!-- the thing -->
        <div v-if="cur_node.chartComponent" class="row" :style="rowStyle">
            <component
                :is="cur_node.chartComponent"
                :tree="tree"
                :cur_node="cur_node"
                :day_width="day_width"
                :row_height="row_height"
                :depth="depth"
                :days="days"
                @update:node="handleUpdateNode"
            />
        </div>

        <!-- the recursion -->
        <template v-if="showChildren(cur_node)">
            <tree-chart
                v-for="node in filteredNodes"
                :key="`tree-chart-${node.id}`"
                :cur_node="node"
                :depth="depth + 1"
                :day_width="day_width"
                :row_height="row_height"
                :nodes="node.children"
            />
        </template>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import EventBus from '@/components/timeline-v2/EventBus';

export default {
    name: 'tree-chart',

    props: {
        cur_node: {
            type: Object,
            required: true,
        },
        nodes: {
            type: Array,
            required: true,
        },
        depth: {
            type: Number,
            required: true,
        },
        day_width: {
            type: Number,
            required: true,
        },
        row_height: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {};
    },

    computed: {
        filteredNodes() {
            if (!this.filterShyNodes) {
                return this.nodes;
            }

            return this.nodes.filter((n) => !this.isNodeShy(n));
        },

        containerStyle() {
            return this.cur_node.containerStyle;
        },

        rowStyle() {
            return this.cur_node.rowStyle;
        },

        ...mapState(['tree', 'filterShyNodes']),
        ...mapGetters(['showChildren', 'days', 'isNodeShy']),
    },

    methods: {
        handleUpdateNode(payload) {
            EventBus.$emit('update:node', payload);
        },
    },
};
</script>

<style scoped lang="scss"></style>
