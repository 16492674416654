import dayjs from 'dayjs';

export const insert = (coll, el) => [...coll, el];
export const remove = (coll, el) => coll.filter((n) => n.id !== el.id);
export const min = (coll) =>
    coll.reduce((acc, cur) => (cur < acc ? cur : acc), coll[0]);

export const max = (coll) =>
    coll.reduce((acc, cur) => (cur > acc ? cur : acc), coll[0]);

// node methods
export const hasChildren = (node) => {
    return !!node.children && node.children.length > 0;
};

export const getChildren = (node) => {
    if (!hasChildren(node)) {
        return [];
    }

    return node.children;
};

export const setChildren = (node, val) => ({...node, children: val});

// tree methods
export const reduce = (reducerFn, hasChildren, getChildren, init, node) => {
    const children = getChildren(node);
    const acc = reducerFn(init, node);

    if (!hasChildren(node)) {
        return acc;
    }

    return children.reduce(
        (iAcc, n) => reduce(reducerFn, hasChildren, getChildren, iAcc, n),
        acc
    );
};

export const treeSeq = (hasChildren, getChildren, tree) => {
    const concat = (arr, node) => {
        return arr.concat(node);
    };

    return reduce(concat, hasChildren, getChildren, [], tree);
};

export const treeMap = (fn, hasChildren, getChildren, setChildren, tree) =>
    setChildren(
        tree,
        getChildren(tree).map((node) =>
            hasChildren(node)
                ? treeMap(fn, hasChildren, getChildren, setChildren, node)
                : fn(node)
        )
    );

export const treeFilter = (fn, hasChildren, getChildren, setChildren, tree) =>
    setChildren(
        tree,
        getChildren(tree)
            .filter(fn)
            .map((node) =>
                hasChildren(node)
                    ? treeFilter(
                          fn,
                          hasChildren,
                          getChildren,
                          setChildren,
                          node
                      )
                    : node
            )
    );

export const countNodes = (tree) => {
    return treeSeq(hasChildren, getChildren, tree).length;
};

export const flatMap = (tree, fn) => {
    return treeSeq(hasChildren, getChildren, tree).map(fn);
};

export const earliestDate = (tree) => {
    return dayjs.unix(min(flatMap(tree, (node) => node.minDate.unix())));
};

export const latestDate = (tree) => {
    return dayjs.unix(max(flatMap(tree, (node) => node.maxDate.unix())));
};

export const adjustedStartDate = (tree) => {
    //return earliestDate(tree).subtract(2, 'week');
    return dayjs().add(-4, 'week');
};

export const adjustedEndDate = (tree) => {
    return max([dayjs().add(8, 'weeks'), latestDate(tree).add(2, 'week')]);
    //return dayjs().add(2, 'month');
};

const CACHE_daysOmitted = new Map();

export const isDayOmitted = (d) => {
    return false;

    // const k = `${d.unix()}`;
    //
    // if (CACHE_daysOmitted.has(k)) {
    //     return CACHE_daysOmitted.get(k);
    // }
    //
    // const dayOfWeek = d.day();
    // const val = dayOfWeek === 6 || dayOfWeek === 0; // omit sat and sun
    //
    // CACHE_daysOmitted.set(k, val);
    //
    // return val;
};

const CACHE_workDaysBetween = new Map();

export const workDaysBetween = (startDate, endDate) => {
    const k = `s:${startDate.unix()},e${endDate.unix()}}`;

    if (startDate.isSame(endDate)) return 0;

    if (CACHE_workDaysBetween.has(k)) {
        return CACHE_workDaysBetween.get(k);
    }

    let res = 0;
    let cursor = dayjs(startDate);

    while (cursor.isBefore(endDate) || cursor.isSame(endDate)) {
        if (!isDayOmitted(cursor)) {
            res++;
        }
        cursor = cursor.add(1, 'days');
    }

    CACHE_workDaysBetween.set(k, res);

    return res;
};
