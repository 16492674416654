<template>
    <el-popover
        v-model="visible"
        placement="top"
        :title="title"
        trigger="click"
        popper-class="milestone-popover"
        :disabled="isAutomaticMilestone"
    >
        <el-tooltip
            slot="reference"
            :content="milestone.content"
            placement="top"
        >
            <div
                :key="milestone.date.unix()"
                :is-milestone="isMilestone"
                :class="[
                    'milestone-cell',
                    isAutomaticMilestone && 'auto',
                    isDayOmitted && 'hidden',
                    milestone.meta.type,
                ]"
            >
                <div
                    v-if="isMilestone"
                    :class="[
                        'milestone',
                        isAutomaticMilestone && 'auto',
                        milestone.meta.type,
                        milestone.meta.external ? 'external' : 'internal',
                    ]"
                />
            </div>
        </el-tooltip>
        <div>
            <el-input v-model="content" placeholder="Content" size="mini" />
            <el-date-picker v-model="date" size="mini" format="dd/MM/yyyy" />
            <el-switch
                v-model="external"
                size="mini"
                active-text="External"
                inactive-text="Internal"
            />
        </div>
        <div style="text-align: right; margin-top: 10px;">
            <el-button size="mini" type="danger" @click="handleDelete">
                delete
            </el-button>
            <el-button size="mini" type="text" @click="handleCancel">
                cancel
            </el-button>
            <el-button type="primary" size="mini" @click="handleSave">
                save
            </el-button>
        </div>
    </el-popover>
</template>

<script>
import {isDayOmitted} from '@/components/timeline-v2/utils';

export default {
    name: 'milestone-cell',

    props: {
        milestone: {
            type: Object,
            required: true,
        },

        cur_node: {
            type: Object,
            required: true,
        },
    },

    data: function () {
        return {
            visible: false,
            content: this.milestone.content,
            date: this.milestone.date.toDate(),
            external: this.milestone.meta.external ? true : false,
        };
    },

    computed: {
        isAutomaticMilestone() {
            return this.milestone.meta.type.includes('auto');
        },

        isMilestone() {
            return true;
        },

        isDayOmitted() {
            return isDayOmitted(this.milestone.date);
        },

        title() {
            if (this.isMilestone) {
                return 'Edit Milestone';
            }

            return 'Add Milestone';
        },
    },

    methods: {
        handleDelete() {
            this.$emit('update:node', {
                type: 'delete-event',
                payload: {
                    id: this.milestone.id,
                },
            });

            this.visible = false;
        },

        handleCancel() {
            this.visible = false;
        },

        handleSave() {
            this.$emit('update:node', {
                type: 'update-event',
                payload: {
                    ...this.milestone,
                    content: this.content,
                    date: this.date,
                    external: this.external,
                },
            });

            this.visible = false;
        },
    },
};
</script>

<style scoped lang="scss">
.milestone-cell {
    height: var(--row-height);
    width: var(--day-width);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.1s;
    transition-property: background-color;
    cursor: pointer;

    &.auto {
        cursor: unset;
    }

    &.auto-start {
        justify-content: flex-start;
    }

    &.auto-end {
        justify-content: flex-end;
    }

    &.hidden {
        width: 0;
    }

    &:hover {
        background-color: rgba(43, 43, 43, 0.25);
    }

    .milestone {
        position: absolute;
        width: 7px;
        height: 7px;
        background-color: rgb(25, 137, 250);

        &.internal {
            background-color: $blue;
        }

        &.external {
            background-color: $face_5;
        }

        border-radius: 5px;
        z-index: 5;

        &.auto {
            z-index: 0;
            width: 0;
            height: 0;
            border-radius: unset;
            background-color: unset;
        }

        &.auto-start {
            border-left: 0 solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid #b1b1b1;
        }

        &.auto-end {
            border-right: 0 solid transparent;
            border-left: 7px solid transparent;
            border-top: 7px solid #6b6b6b;
        }
    }
}

.milestone-popover {
    .el-input {
        width: 100%;
    }
    .el-input + .el-input {
        margin-top: 5px;
    }
}
</style>
