<template>
    <div class="tracking-block content">
        <stop-button
            v-if="is_tracking || is_loading"
            v-only-super-admin="!show_stop_button"
            :loading="stopping || is_loading"
            @click.native="handleStopAllTimers"
        />

        <div class="info">
            <component-info
                v-if="is_tracking || is_loading"
                :component="component"
            />
            <span v-else class="label">Not tracking</span>
        </div>

        <transition name="fade" mode="out-in">
            <span :key="cube.active_face" class="time" v-if="session">
                {{ tspent__active_sessions | milli2duration }}
            </span>
        </transition>

        <transition name="fade" mode="out-in">
            <div
                class="note desktop-only"
                v-if="session"
                :key="cube.active_face"
            >
                <template v-if="is_current_user">
                    <span>
                        What are you working on?
                    </span>
                </template>
                <session-input
                    :placeholder="is_current_user ? 'Add note' : null"
                    :editable="editable"
                    :session="session"
                    class="el-input session-input"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import {stopAllTimers} from '@/api/sessions';

import ComponentInfo from '@/components/generic/ComponentInfo';
import StopButton from '@/components/generic/StopButton';

import componentSessionMixin from '@/mixins/component.session.mixin';
import SessionInput from '@/components/editor/SessionInput.vue';

export default {
    name: 'tracking-block',
    components: {
        SessionInput,
        StopButton,
        ComponentInfo,
    },
    mixins: [componentSessionMixin],
    props: {
        user: {
            type: Object,
            required: true,
        },
        show_stop_button: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            stopping: false,
        };
    },
    computed: {
        cube() {
            return this.$store.getters.cubeForUserWithId(this.user.id);
        },
        session() {
            const _session = this.$store.getters.activeSessionForUserWithId(
                this.user.id
            );

            // If the session is from a different face than the current active, assume the server still needs to switch to the updated session
            if (_session?.face_id !== this.cube.active_face) return null;

            // Return the active session
            return _session;
        },
        is_loading() {
            // If we are showing the tracking bar and the component is loaded from cube face
            // but there is no session, assume we are loading
            return !!(
                this.$store.getters.show_tracking_bar &&
                this.component &&
                !this.session
            );
        },
        component() {
            if (!this.cube) return null;
            if (!this.cube.face2component[this.cube.active_face]) return null;
            return this.$store.getters.componentWithId(
                this.cube.face2component[this.cube.active_face]
            );
        },
        project() {
            if (!this.session) return null;
            return this.$store.getters.projectWithId(this.session.project);
        },
        is_current_user() {
            return this.user.id === this.$store.getters.user.id;
        },
        is_tracking() {
            return this.session && this.component;
        },
    },
    methods: {
        async handleStopAllTimers() {
            if (this.stopping) return;

            this.stopping = true;
            try {
                await stopAllTimers(this.user.id);
            } catch (err) {
                console.log(err);
            }

            this.stopping = false;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.content {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;

    @media screen and (max-width: 992px) {
        width: 100%;
    }

    .info {
        min-width: 300px;

        @media screen and (max-width: 992px) {
            min-width: unset;
            flex: 1;
            margin-left: 20px;
        }

        .label {
            font-size: 13px;
            margin-left: 20px;

            @media screen and (max-width: 992px) {
                margin: 0;
            }
        }
    }

    .time {
        font-size: 20px;
        color: $black;
        min-width: 150px;
        line-height: 40px;
        margin-left: 20px;

        @media screen and (max-width: 992px) {
            min-width: auto;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .note {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: $bottom-bar-height;
        justify-content: center;

        .session-input {
            min-height: 39px;
        }

        ::v-deep .input {
            background-color: unset;
            font-size: 14px;
            text-align: right;
            width: 100%;
            overflow-y: auto;

            .ProseMirror p.is-editor-empty:first-child::before {
                position: absolute;
                right: 0;
            }

            .content {
                .ProseMirror {
                    text-align: right;
                }
            }
        }

        span {
            margin: 8px 0 0 0;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .fade {
        &-enter-active,
        &-leave-active {
            transition: opacity 0.8s ease-in-out;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
        }
    }
}
</style>
