<template>
    <ul class="list">
        <note-block
            v-for="note in notes"
            :key="note.id"
            :note="note"
            :search="search"
            @click.native="handleClick(note)"
        />
    </ul>
</template>

<script>
import NoteBlock from '@/components/blocks/NoteBlock';

export default {
    name: 'project-tab-notes-list',
    components: {
        NoteBlock,
    },
    props: {
        notes: {
            type: Array,
            default: () => [],
        },
        search: {
            type: String,
            default: null,
        },
    },
    methods: {
        handleClick(note) {
            this.$router.push({
                name: 'note',
                params: {
                    note_id: note.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.list {
    padding: 0;
    list-style: none;
    margin: 0;

    &.temporary {
        background-color: $black;
    }

    .note-block:last-of-type {
        margin-bottom: 0;
    }
}
</style>
