<template>
    <div />
</template>

<script>
import {COMMAND_DEPENDENCIES} from '@/enums';

export default {
    // command attributes (referenced externally)
    command_props: {
        dependencies: [
            COMMAND_DEPENDENCIES.NAVIGATION,
            COMMAND_DEPENDENCIES.PROJECT,
            COMMAND_DEPENDENCIES.COMPONENT,
            COMMAND_DEPENDENCIES.ESTIMATE,
            COMMAND_DEPENDENCIES.PAYMENT_PLAN,
            COMMAND_DEPENDENCIES.INVOICE,
            COMMAND_DEPENDENCIES.DOCUMENT,
            //COMMAND_DEPENDENCIES.CHANGE_REQUEST,
        ],
        prompt: 'Where would you like to go?',
    },

    name: 'navigate-command',
    components: {},
    props: {
        provided_data: {
            type: Object,
            required: true,
        },
        search_string: {
            type: String,
            required: false,
            default: null,
        },
    },
    watch: {
        provided_data: {
            handler(data) {
                // navigate to the first dependency that is fulfilled
                let path = null;
                if (data[COMMAND_DEPENDENCIES.NAVIGATION]) {
                    path = data[COMMAND_DEPENDENCIES.NAVIGATION].value;
                } else if (data[COMMAND_DEPENDENCIES.PROJECT]) {
                    path = `/project/${
                        data[COMMAND_DEPENDENCIES.PROJECT].value.id
                    }`;
                } else if (data[COMMAND_DEPENDENCIES.COMPONENT]) {
                    const project_id = this.$options.filters.fireRef2id(
                        data[COMMAND_DEPENDENCIES.COMPONENT].value.project
                    );
                    const stage_id = this.$options.filters.fireRef2id(
                        data[COMMAND_DEPENDENCIES.COMPONENT].value.stage
                    );
                    path = `/project/${project_id}/stage/${stage_id}/component/${
                        data[COMMAND_DEPENDENCIES.COMPONENT].value.id
                    }`;
                } else if (data[COMMAND_DEPENDENCIES.ESTIMATE]) {
                    const project_id = this.$options.filters.fireRef2id(
                        data[COMMAND_DEPENDENCIES.ESTIMATE].value.project
                    );
                    path = `/project/${project_id}/estimate/${
                        data[COMMAND_DEPENDENCIES.ESTIMATE].value.id
                    }`;
                } else if (data[COMMAND_DEPENDENCIES.PAYMENT_PLAN]) {
                    const project_id = this.$options.filters.fireRef2id(
                        data[COMMAND_DEPENDENCIES.PAYMENT_PLAN].value.project
                    );
                    const estimate_id = this.$options.filters.fireRef2id(
                        data[COMMAND_DEPENDENCIES.PAYMENT_PLAN].value.estimate
                    );
                    path = `/project/${project_id}/paymentplan/${estimate_id}`;
                } else if (data[COMMAND_DEPENDENCIES.INVOICE]) {
                    const invoice_number =
                        data[COMMAND_DEPENDENCIES.INVOICE].value.invoice_number;
                    path = `/manage/invoice/${invoice_number.toUpperCase()}`;
                } else if (data[COMMAND_DEPENDENCIES.DOCUMENT]) {
                    const document_id =
                        data[COMMAND_DEPENDENCIES.DOCUMENT].value.id;
                    path = `/document/${document_id}`;
                }

                if (path) {
                    // navigating to the current path throws an exception, which we're ignoring
                    this.$router.push(path).catch((e) => {});
                    this.$emit('close');
                }
            },
        },
    },
};
</script>
