<template>
    <section v-if="!notes.loading">
        <div class="header">
            <el-input
                v-model="search"
                name="search"
                type="text"
                auto-complete="off"
                placeholder="Search in notes"
            />

            <el-button
                v-if="temporary_note"
                type="primary"
                @click="handleClickOnContinueEditing"
            >
                Continue editing
            </el-button>
            <el-button v-else @click="handleClickOnNew">
                New
            </el-button>
        </div>

        <hr />

        <list :notes="sorted_notes" :search="search" />
    </section>
</template>

<script>
import List from './sub/ProjectTab_notes__list';

import userMixin from '@/mixins/user.mixin';

export default {
    name: 'project-tab-notes',
    components: {
        List,
    },
    mixins: [userMixin],
    data() {
        return {
            notes: {
                data: [],
                loading: true,
            },
            search: null,
        };
    },
    computed: {
        sorted_notes() {
            const search =
                this.search && this.search.length > 1
                    ? this.search.toLowerCase()
                    : '';
            var filtered = this.notes.data
                .filter((note) => {
                    if (note.is_temporary) return false;
                    return note.title
                        ? note.title.toLowerCase().includes(search)
                        : false;
                })
                .sort(function (a, b) {
                    if (a.created_at.seconds > b.created_at.seconds) return -1;
                    if (a.created_at.seconds < b.created_at.seconds) return 1;
                    return 0;
                });

            if (!this.user_is_admin) {
                filtered = filtered.filter((note) => {
                    return !note.is_private;
                });
            }

            return filtered;
        },
        temporary_note() {
            var temporary = this.notes.data.filter((note) => {
                return (
                    note.is_temporary &&
                    note.author === `users/${this.current_user.id}`
                );
            });

            if (temporary && temporary.length > 0) return temporary[0];

            return null;
        },
    },
    mounted() {
        this.$bind(
            'notes.data',
            this.$fire
                .collection('notes')
                .where(
                    'project',
                    '==',
                    this.$fire
                        .collection('projects')
                        .doc(this.$route.params.project_id.toString())
                ),
            {maxRefDepth: 0}
        ).then(() => {
            this.notes.loading = false;
        });
    },
    methods: {
        handleClickOnContinueEditing() {
            this.$router.push({
                name: 'note',
                params: {
                    note_id: this.temporary_note.id,
                },
            });
        },
        handleClickOnNew() {
            this.$fire
                .collection('notes')
                .add({
                    is_temporary: true,
                    is_private: false,
                    created_at: new Date(),
                    updated_at: new Date(),
                    author: this.$fire
                        .collection('users')
                        .doc(this.current_user.id.toString()),
                    project: this.$fire
                        .collection('projects')
                        .doc(this.$route.params.project_id.toString()),
                })
                .then((response) => {
                    this.$router.push({
                        name: 'note',
                        params: {
                            note_id: response.id,
                        },
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .el-input {
        max-width: 300px;
    }
}

hr {
    width: 100%;
    margin: 10px 0;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}
</style>
