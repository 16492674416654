<template>
    <div class="container">
        <transition name="fade" mode="out-in">
            <router-view />
        </transition>
    </div>
</template>

<script>
export default {
    name: 'maximum-container',
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.container {
    padding-top: 140px;
    min-height: calc(100vh - 140px);
    box-sizing: content-box;

    ::v-deep .content-block:last-of-type {
        margin-bottom: 0;
    }
}
</style>
