<template>
    <div class="timeline-container">
        <project-timeline />
    </div>
</template>

<script>
import ProjectTimeline from '@/components/timeline/ProjectTimeline';

export default {
    components: {ProjectTimeline},
};
</script>
<style scoped lang="scss">
.timeline-container {
    height: calc(100vh - 140px);
}
</style>
