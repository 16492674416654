<template>
    <div class="wrapper">
        <el-button
            v-if="!(reminders && reminders.length) && !show_form"
            size="mini"
            @click="
                () => {
                    show_form = true;
                }
            "
        >
            Add reminder
        </el-button>
        <div v-else class="reminders">
            <div class="reminders-header">
                <el-row :gutter="5" type="flex" align="middle">
                    <el-col :span="24">
                        Reminders
                    </el-col>
                </el-row>
            </div>
            <div
                class="reminders-row"
                v-for="(reminder, index) of reminders"
                :key="index"
            >
                <el-row :gutter="5" type="flex" align="middle">
                    <el-col :span="5">
                        <div class="value" style="padding-left: 30px;">
                            {{ reminder.date | dateformat('DD/MM/YYYY') }}
                        </div>
                    </el-col>
                    <el-col>
                        <div class="value">
                            {{ getUser(reminder.user).name }}
                        </div>
                    </el-col>
                    <el-col :span="1">
                        <el-popconfirm
                            v-if="canDelete(reminder)"
                            title="Delete this reminder?"
                            @confirm="handleDeleteReminder(reminder)"
                        >
                            <div slot="reference" class="delete">
                                <el-button
                                    icon="el-icon-circle-close"
                                    type="danger"
                                    plain
                                />
                            </div>
                        </el-popconfirm>
                    </el-col>
                </el-row>
            </div>

            <div class="reminders-row">
                <el-row :gutter="5" type="flex" align="middle">
                    <el-col :span="5">
                        <el-date-picker
                            size="small"
                            v-model="form.date"
                            placeholder="Reminder date"
                            format="dd/MM/yyyy"
                            :picker-options="pickerOptions"
                        />
                    </el-col>
                    <el-col>
                        <el-select v-model="form.user_id" size="small">
                            <el-option
                                v-for="u in users"
                                :key="u.id"
                                :value="u.id"
                                :label="u.name"
                            >
                                {{ u.name }}
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="1">
                        <el-button
                            :disabled="!is_valid"
                            icon="el-icon-plus"
                            @click="handleAddReminder"
                        />
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import {USER_ROLES} from '@/enums';
import userMixin from '@/mixins/user.mixin';

export default {
    name: 'reminder-list',
    props: {
        reminders: {
            type: Array,
            required: true,
        },
    },
    mixins: [userMixin],
    data() {
        return {
            show_form: false,
            form: {
                date: null,
                user_id: null,
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                },
                shortcuts: [
                    {
                        text: 'Tomorrow',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        },
                    },
                    {
                        text: 'In a week',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        },
                    },
                    {
                        text: 'In 2 weeks',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(
                                date.getTime() + 3600 * 1000 * 24 * 14
                            );
                            picker.$emit('pick', date);
                        },
                    },
                ],
            },
        };
    },
    computed: {
        is_valid() {
            return this.form.date && this.form.user_id;
        },
    },
    mounted() {
        this.resetForm();
    },
    methods: {
        resetForm() {
            // form defaults to active user
            this.form.date = null;
            this.form.user_id = this.current_user.id;
        },
        getUser(user_ref) {
            return this.users.find(
                (u) => u.id === this.$options.filters.fireRef2id(user_ref)
            );
        },
        canDelete(reminder) {
            // reminder can be deleted if this user is super-admin,
            // or reminder is set for, or created by, this user
            return (
                this.session_user.role.includes(USER_ROLES.SUPER_ADMIN) ||
                this.$options.filters.fireRef2id(reminder.user) ===
                    this.current_user.id ||
                this.$options.filters.fireRef2id(reminder.created_by) ===
                    this.current_user.id
            );
        },
        handleAddReminder() {
            this.$emit('change', [
                ...this.reminders,
                {
                    date: this.form.date,
                    user: this.$fire.doc(`users/${this.form.user_id}`),
                    created_by: this.$fire.doc(`users/${this.current_user.id}`),
                },
            ]);
            this.resetForm();
        },
        handleDeleteReminder(reminder) {
            const index = this.reminders.indexOf(reminder);
            const reminders = this.reminders.toSpliced(index, 1);
            this.$emit('change', reminders);
        },
    },
};
</script>

<style lang="scss" scoped>
.reminders {
    overflow: hidden;

    .reminders-header {
        font-size: 11px;
        margin: 10px 0 6px 0;
    }

    .reminders-row {
        margin-bottom: 5px;

        .value {
            border: 1px solid $border-grey;
            line-height: 32px;
            height: 32px;
            padding: 0 15px;
            border-radius: 4px;
            font-size: 13px;
            box-sizing: border-box;
        }

        .center {
            text-align: center;
        }

        .el-select {
            width: 100%;
        }

        .el-date-editor.el-input {
            width: unset;
        }

        .el-button {
            width: 100%;
            height: 32px;
            padding: 0;
        }
    }
}
</style>
