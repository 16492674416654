<template>
    <div class="container">
        <label>Calendar</label>

        <div class="right">
            <span v-if="loading">
                Syncing...
            </span>
            <span v-else-if="calendar_total_sync">
                <b>
                    {{ calendar_total_sync }}
                    {{ 'event' | pluralize(calendar_total_sync) }}
                </b>
                updated
            </span>
            <span v-else-if="system_calendars" class="last-updated">
                Last updated
                {{
                    system_calendars.last_updated?.seconds
                        | timestamp2moment
                        | timeAgo
                }}
            </span>

            <el-button
                style="width: 100%;"
                :disabled="loading"
                icon="el-icon-refresh"
                :loading="loading"
                @click="sync"
            />
        </div>
    </div>
</template>

<script>
import {syncCalendar} from '@/api/calendar';
import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';

export default {
    name: 'calendar-integration',
    data() {
        return {
            SUPPORTED_FEATURES: SUPPORTED_FEATURES,
            calendar_total_sync: null,
            system_calendars: null,
            loading: false,
        };
    },
    props: {
        show_dialog: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        show_dialog: function (val) {
            if (!val) this.calendar_total_sync = null;
        },
    },
    mounted() {
        this.$bind('system_calendars', this.$fire.doc('system/calendar'), {
            maxRefDepth: 0,
        });
    },
    methods: {
        async sync() {
            this.calendar_total_sync = null;
            this.loading = true;

            syncCalendar()
                .then((result) => {
                    if (result.success) {
                        this.calendar_total_sync = result.data.total;
                    }
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
span {
    margin-right: 10px;
    opacity: 0.8;
    font-size: 12px;

    &.last-updated {
        font-size: 10px;
    }
}
</style>
