<template>
    <div class="outer">
        <breadcrumb-header
            v-if="client"
            :labels="[client.name]"
            go_back_to="management_clients"
        />

        <div v-if="client" class="inner">
            <content-block title="Projects">
                <div slot="right" style="float: right;">
                    <el-button size="mini" @click="linkProject">
                        Link
                    </el-button>
                </div>
                <client-projects :client="client" />
            </content-block>

            <content-block title="Contact">
                <div slot="right" class="contact-buttons">
                    <el-button
                        size="mini"
                        :loading="updating"
                        @click="updateContact"
                    >
                        Update from Xero
                    </el-button>
                    <el-button size="mini" @click="editOnXero">
                        Edit
                    </el-button>
                </div>
                <el-alert v-if="!client.email" type="error" :closable="false">
                    Client is missing primary contact email.
                </el-alert>
                <client-form :client="client" readonly :loading="false" />
            </content-block>

            <content-block title="Invoices">
                <client-invoices :client="client" />
            </content-block>
        </div>
    </div>
</template>

<script>
import {getContact} from '@/api/contacts';

import ContentBlock from '@/components/blocks/ContentBlock';

import ClientForm from './components/ClientForm';
import ClientProjects from './components/ClientProjects';
import ClientInvoices from './components/ClientInvoices';

import BreadcrumbHeader from '@/pages/management/components/BreadcrumbHeader';

import {Notification} from 'element-ui';

export default {
    name: 'management-clients-detail',
    components: {
        ContentBlock,

        ClientForm,
        ClientProjects,
        ClientInvoices,

        BreadcrumbHeader,
    },
    data() {
        return {
            updating: false,
        };
    },
    computed: {
        client() {
            return this.$store.getters.clientWithId(
                this.$route.params.client_id
            );
        },
    },
    mounted() {
        if (!this.client) {
            this.$router.replace({name: 'management_clients'});
            return;
        }
    },
    methods: {
        linkProject() {
            this.$router
                .push({
                    name: 'management_client_project_link',
                    params: {
                        client_id: this.client.id,
                    },
                })
                .catch((err) => {});
        },
        async updateContact() {
            this.updating = true;

            const result = await getContact(this.client.id);
            this.$store.dispatch('updateClient', result.data);

            this.updating = false;

            Notification({
                type: 'success',
                title: 'Done!',
                message: 'The client has been updated successfully.',
            });
        },
        editOnXero() {
            window.open(
                `https://go.xero.com/Contacts/Edit.aspx?contactID=${this.client.id}`
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    .inner {
        .content-block {
            background: $white;
            margin-bottom: 0;
        }
    }
}

.contact-buttons {
    float: right;

    @media screen and (max-width: 992px) {
        float: none !important;
    }
}

@media screen and (max-width: 992px) {
    ::v-deep {
        .head.el-row {
            flex-direction: column;
        }

        .right-col {
            justify-content: flex-start !important;
            margin-top: 12px;
        }
    }
}
</style>
