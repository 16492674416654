import moment from 'moment-timezone';
import chrono from 'chrono-node-zh';
/**
 * Created by jiachenpan on 16/11/18.
 */

export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null;
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
        date = time;
    } else {
        if (('' + time).length === 10) time = parseInt(time) * 1000;
        date = new Date(time);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    };
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        if (key === 'a')
            return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
        if (result.length > 0 && value < 10) {
            value = '0' + value;
        }
        return value || 0;
    });
    return time_str;
}

export function formatTime(time, option) {
    time = +time * 1000;
    const d = new Date(time);
    const now = Date.now();

    const diff = (now - d) / 1000;

    if (diff < 30) {
        return '刚刚';
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前';
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前';
    } else if (diff < 3600 * 24 * 2) {
        return '1天前';
    }
    if (option) {
        return parseTime(time, option);
    } else {
        return (
            d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
        );
    }
}

export function isNumeric(value) {
    return typeof value === 'number';
}

/**
 * Get a moment date with the timezone set for this environment
 * @param date
 * @param format
 * @returns {moment.Moment}
 */
export function momentWithTz(date = undefined, format = undefined) {
    return moment(date, format).tz(process.env.VUE_APP_TIMEZONE);
}

/**
 * Parse a written date
 * @param text
 * @returns {{end_date: null, now: Date, time: null, edited_text}}
 */
export function parseDate(text) {
    const now = new Date();
    let time = null;
    let end_date = null;

    let edited_text = text;

    const parsed_text = chrono.en_GB.parse(edited_text, new Date(), {
        forwardDate: true,
    });

    const getDate = (parsed, index) => {
        if (parsed.length <= index) return null;

        if (
            parsed[index].index !== 0 &&
            edited_text[parsed[index].index - 1] !== ' '
        ) {
            return getDate(parsed, index + 1);
        }

        time = parsed[index].start.date();

        const start = edited_text.substr(0, parsed[index].index);
        const end = edited_text.substr(
            parsed[index].index + parsed[index].text.length
        );

        edited_text = `${start}{{date}}${end}`;
    };

    getDate(parsed_text, 0);

    return {
        now,
        edited_text,
        time,
        end_date,
    };
}

export function sleep(ms) {
    return new Promise((r) => setTimeout(r, ms));
}

/**
 * If a number is within the given (or default) tolerance, round to the nearest integer.
 * For handling floating point errors when performing boundary comparisons, eg. x >= y
 * @param value number
 * @param tolerance number
 * @returns {number}
 */
export function toleranceRound(value, tolerance = 0.0001) {
    const nearestRoundNumber = Math.round(value);
    const difference = Math.abs(nearestRoundNumber - value);
    if (difference <= tolerance) {
        value = nearestRoundNumber;
    }

    return value;
}
