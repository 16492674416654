<template>
    <div class="attendee">
        <avatar
            :user="user"
            :show_initials="user.show_initials"
            :hide_tooltip="!user.show_initials"
            :color="color"
            :live="true"
            :custom_initials="user.custom_initials"
        />
    </div>
</template>

<script>
import Avatar from '../generic/Avatar';
export default {
    components: {Avatar},
    props: {
        attendee: {
            type: Object,
            required: true,
        },
    },

    computed: {
        status() {
            switch (this.attendee.status) {
                case 'accepted':
                    return 'Attending';
                case 'declined':
                    return 'Declined';
                default:
                    return 'Maybe?';
            }
        },
        user() {
            const u = this.$store.getters.userWithEmail(this.attendee.email);

            if (u) {
                return u;
            }

            let initials = null;

            if (!this.attendee.name) {
                initials = this.attendee.email[0].toUpperCase();
            }
            return {
                id: this.attendee.email,
                avatar: null,
                name: this.attendee.name || this.attendee.email,
                show_initials: true,
                custom_initials: initials,
            };
        },

        color() {
            switch (this.attendee.status) {
                case 'accepted':
                    return '#80bc43';
                case 'declined':
                    return '#f92139';
                default:
                    return '#dcdfe6';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.attendee {
    margin-right: 6px;
}
</style>
