<template>
    <el-form
        ref="form"
        auto-complete="on"
        :model="form"
        :rules="rules"
        label-width="160px"
        class="form-wrapper"
    >
        <!-- REFERENCE -->
        <!--*******************************************-->

        <el-form-item label="Reference">
            <el-input
                v-model="ref"
                name="ref"
                type="text"
                auto-complete="off"
                disabled
            />
        </el-form-item>

        <h1>What is the client asking for?</h1>

        <!-- TITLE -->
        <!--*******************************************-->

        <el-form-item label="Title" prop="title">
            <el-input
                ref="title"
                v-model="form.title"
                name="title"
                type="text"
                auto-complete="off"
            />
        </el-form-item>

        <!-- DESCRIPTION -->
        <!--*******************************************-->

        <el-form-item label="Description" prop="content">
            <text-editor ref="content_editor" :content.sync="form.content" />
        </el-form-item>

        <div class="row">
            <el-checkbox v-model="another">Add another</el-checkbox>

            <el-button :disabled="creating" @click="handleSubmit">
                Create
            </el-button>
        </div>
    </el-form>
</template>

<script>
import {Notification} from 'element-ui';

import TextEditor from '@/components/editor/TextEditor';

export default {
    name: 'change-request-new',
    components: {
        TextEditor,
    },
    data() {
        return {
            creating: false,
            form: {
                ref: null,
                title: null,
                content: null,
            },
            rules: {
                ref: [{required: true, trigger: 'blur'}],
                title: [{required: true, trigger: 'blur'}],
            },
            another: false,
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        ref() {
            // max_ref_number is auto-incremented by cloud function upon save
            const max_ref_number = this.project.max_ref_number || 0;
            return `${this.project.ref}-CR-${`${max_ref_number + 1}`.padStart(
                3,
                '0'
            )}`;
        },
    },
    mounted() {
        this.$refs.title.focus();
    },
    methods: {
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.creating = true;

                    let data = {
                        ...this.form,
                        ref: this.ref?.toUpperCase(),
                        project: this.$fire.doc(`projects/${this.project.id}`),
                        status: this.$enums.CR_STATUS.BACKLOG,
                    };

                    this.$store
                        .dispatch('addChangeRequest', data)
                        .then(() => {
                            if (!this.another) {
                                this.$router.push({
                                    name: 'project_detail_changerequests',
                                    params: {
                                        project_id: this.project.id,
                                    },
                                });
                            } else {
                                this.creating = false;
                                this.form = {
                                    ref: null,
                                    title: null,
                                    content: null,
                                };

                                this.$refs.content_editor.setContent(null);
                            }
                        })
                        .catch((err) => {
                            this.creating = false;
                            Notification({
                                type: 'error',
                                title: 'Error',
                                message: err.message,
                            });
                        });
                } else {
                    console.log('Form not valid...');
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.el-form {
    margin: 40px auto;

    .el-form-item {
        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        .el-input,
        .el-select {
            flex: 1;
            height: 100%;
            width: 100%;
        }
    }

    h1 {
        text-align: right;
        font-size: 17px;
        margin-top: 10px;
    }
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .el-checkbox {
        margin-right: 20px;
    }

    .el-button {
        width: 200px;
    }
}

span.warning {
    color: $red;
}

hr {
    margin: 20px 50px;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}
</style>
