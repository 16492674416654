<template>
    <el-form
        ref="form"
        auto-complete="on"
        :model="form"
        label-width="160px"
        class="form-wrapper"
    >
        <!-- NOTES -->
        <!--*******************************************-->

        <el-form-item label="Notes" prop="notes">
            <el-input v-model="form.notes" type="textarea" :rows="2" />
        </el-form-item>

        <hr />

        <!-- START DATE -->
        <!--*******************************************-->

        <el-form-item label="Start date" prop="start_date">
            <el-date-picker
                v-model="form.start_date"
                type="date"
                :picker-options="pickerOptions"
                format="dd/MM/yyyy"
            />
            <span v-if="start_diff">
                <strong>{{ start_diff }}</strong>
                {{ 'day' | pluralize(start_diff) }} away
            </span>
        </el-form-item>

        <!-- DEADLINE -->
        <!--*******************************************-->

        <el-form-item label="Deadline" prop="deadline_date">
            <el-date-picker
                v-model="form.deadline_date"
                type="date"
                :picker-options="pickerOptions"
                format="dd/MM/yyyy"
            />
            <span v-if="deadline_diff">
                <strong>{{ deadline_diff }}</strong>
                {{ 'day' | pluralize(deadline_diff) }} away
            </span>
        </el-form-item>

        <el-button :disabled="creating" @click="handleSubmit">
            Create
        </el-button>
    </el-form>
</template>

<script>
import moment from 'moment';

export default {
    name: 'stages-new',
    data() {
        return {
            creating: false,
            form: {
                status: 'active',
                notes: '',
                start_date: null,
                deadline_date: null,
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: 'Two weeks',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(moment().add({days: 14}));
                            picker.$emit('pick', date);
                        },
                    },
                    {
                        text: 'One month',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(moment().add({months: 1}));
                            picker.$emit('pick', date);
                        },
                    },
                    {
                        text: 'Two month',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(moment().add({months: 2}));
                            picker.$emit('pick', date);
                        },
                    },
                ],
            },
        };
    },
    computed: {
        stages() {
            return this.$store.getters.stagesForProjectWithId(
                this.$route.params.project_id
            );
        },
        start_diff() {
            if (this.form.start_date === '' || !this.form.start_date)
                return null;
            return this.$options.filters.daysDiff(this.form.start_date);
        },
        deadline_diff() {
            if (this.form.deadline_date == '' || !this.form.deadline_date)
                return null;
            return this.$options.filters.daysDiff(this.form.deadline_date);
        },
    },
    methods: {
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.creating = true;

                    this.form.number = this.$route.params.stage_number;
                    this.form.project = this.$fire.doc(
                        `projects/${this.$route.params.project_id}`
                    );

                    this.$store.dispatch('addStage', this.form).then(() => {
                        this.$router.go(-1);
                    });
                } else {
                    console.log('Form not valid...');
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.el-form {
    margin: 40px auto;

    .el-form-item {
        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        .el-input,
        .el-select {
            flex: 1;
            height: 100%;
            width: 100%;
        }
    }
}

.el-button {
    margin-top: 20px;
    width: 200px;
    float: right;
}

span.warning {
    color: $red;
}

hr {
    margin: 20px 50px;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}

@media screen and (max-width: 992px) {
    .form-wrapper .el-form-item {
        display: flex;
        flex-direction: column;

        ::v-deep {
            .el-form-item__label {
                text-align: left;
            }

            .el-form-item__content {
                margin-left: 0 !important;

                .el-slider {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>
