<template>
    <li :class="[component.status]" @click="$emit('onSelection', component)">
        <time-indicator class="time-indicator" :component="component" />

        <span class="name">{{ component.name }}</span>

        <span v-if="component.status === COMPONENT_STATUS.STANDBY" class="tag">
            {{ component.status }}
        </span>
        <span class="ref" v-if="component.ref">{{ component.ref }}</span>
    </li>
</template>

<script>
import TimeIndicator from '@/components/generic/TimeIndicator';

import {COMPONENT_STATUS} from '@/enums';

export default {
    name: 'component-selector-block',
    components: {TimeIndicator},
    props: {
        component: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            COMPONENT_STATUS: COMPONENT_STATUS,
        };
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
li {
    cursor: pointer;
    margin: 0 0 10px 0;
    padding: 20px 20px 20px 0;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 0 0 1px $border-grey-light;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &:hover {
        box-shadow: 0 0 0 2px $blue;
    }

    &.placeholder {
        padding-left: 20px;
        pointer-events: none;

        span {
            font-size: 12px;
            opacity: 0.7;
        }
    }

    &.standby {
        opacity: 0.6;
        pointer-events: none;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    span.name {
        flex: 1;
    }

    .tag {
        margin: 2px 10px 0 10px;
        font-size: 10px !important;
        text-transform: capitalize;
        background-color: white;
        padding: 5px 10px;
        border-radius: 20px;
        font-weight: bold;
    }

    span.ref {
        font-size: 9px;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 2px;
        background-color: white;
        padding: 5px 10px;
        border-radius: 20px;
    }
}
</style>
