<template>
    <content-block title="Scoping" collapsable :expanded="is_expanded">
        <el-form
            ref="form"
            auto-complete="on"
            label-width="160px"
            class="form-wrapper"
        >
            <!-- ASSIGN FOR SCOPING -->
            <!--*******************************************-->

            <el-form-item label="Assign for scoping">
                <el-select
                    v-model="change_request.assigned_to_for_scoping"
                    filterable
                    placeholder="Select user"
                    value-key="id"
                    :disabled="!can_edit"
                >
                    <el-option key="un-assign" label="Unassign" :value="null" />
                    <hr />
                    <el-option
                        v-for="u in users"
                        :key="u.id"
                        :label="u.name"
                        :value="`users/${u.id}`"
                    />
                </el-select>
            </el-form-item>
        </el-form>
    </content-block>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import {COMPONENT_STATUS, STAGE_STATUS} from '@/enums';

export default {
    name: 'change-request-link',
    components: {
        ContentBlock,
    },
    props: {
        change_request: {
            type: Object,
            required: true,
        },
        project: {
            type: Object,
            required: false,
            default: null,
        },
    },
    computed: {
        stages() {
            if (!this.project) return [];
            const stages = this.$store.getters.stagesForProjectWithId(
                this.project.id
            );

            return stages
                .filter((stage) => {
                    return stage.status === STAGE_STATUS.ACTIVE;
                })
                .map((stage) => {
                    const components = this.$store.getters
                        .componentsForStageWithId(stage.id)
                        .filter((component) => {
                            return (
                                component.status === COMPONENT_STATUS.ACTIVE ||
                                component.status === COMPONENT_STATUS.COMPLETED
                            );
                        });

                    stage._components = components;
                    return stage;
                });
        },
        users() {
            return this.$store.getters.users;
        },
        is_expanded() {
            return this.change_request.status < this.$enums.CR_STATUS.SCOPING;
        },
        can_edit() {
            return (
                this.change_request.status < this.$enums.CR_STATUS.PRE_APPROVAL
            );
        },
    },
    watch: {
        // 'change_request.component': function (val) {
        //     if (val) {
        //         // Associates the CR to the selected component
        //         // and moves the CR to 'backlog' state
        //
        //         this.update({
        //             component: this.$fire.doc(val),
        //             status: this.$enums.CR_STATUS.BACKLOG,
        //         });
        //     } else {
        //         // Removes the associated component
        //         // and moves the CR to 'requested' state
        //
        //         this.update({
        //             component: null,
        //             status: this.$enums.CR_STATUS.REQUESTED,
        //             assigned_to_for_scoping: null,
        //         });
        //     }
        // },
        'change_request.assigned_to_for_scoping': function (val) {
            if (val) {
                // Assigns the CR to someone for scoping
                // and moves the CR to 'scoping' if current state is 'backlog'

                this.update({
                    assigned_to_for_scoping: this.$fire.doc(val),
                    status:
                        this.change_request.status <=
                        this.$enums.CR_STATUS.BACKLOG // If status is 'backlog'
                            ? this.$enums.CR_STATUS.SCOPING // Move to 'scoping'
                            : this.change_request.status, // Otherwise maintain state
                });
            } else {
                // Removes the user assigned for scoping
                // and moves the CR to 'backlog' if current state is 'scoping'

                this.update({
                    assigned_to_for_scoping: null,
                    status:
                        this.change_request.status ===
                        this.$enums.CR_STATUS.SCOPING // If status is 'backlog'
                            ? this.$enums.CR_STATUS.BACKLOG // Move to 'scoping'
                            : this.change_request.status, // Otherwise maintain state
                });
            }
        },
    },
    methods: {
        update(data) {
            return this.$fire
                .collection('change_requests')
                .doc(this.change_request.id)
                .update(data);
        },
    },
};
</script>

<style lang="scss" scoped>
.el-select {
    width: 250px;
}

span.info {
    font-size: 11px;
    opacity: 0.5;
    margin-left: 10px;
}
</style>
