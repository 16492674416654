<template>
    <li class="issue-item" @click="openItem">
        <el-tag v-if="show_resource" type="info" class="resource" size="small">
            {{ item.resource.name }}
        </el-tag>
        <el-tag v-if="show_project" type="info" class="project" size="small">
            {{ item.jira_project.label }}
        </el-tag>

        <div class="type">
            <img
                class="type_icon"
                :alt="item.type.label"
                :src="item.type.icon"
                @error="imgError"
            />
        </div>

        <div class="content">
            <div class="issue-container">
                <el-tooltip :content="item.priority.label" placement="top">
                    <img
                        class="priority_icon"
                        :alt="item.priority.label"
                        :src="item.priority.icon"
                    />
                </el-tooltip>
                <span class="key">{{ item.key }}</span>
            </div>
            <span class="summary">{{ item.summary }}</span>

            <div class="right">
                <el-tag v-if="show_status" :type="type" size="mini">
                    {{ item.status.label }}
                </el-tag>
                <el-tooltip
                    v-if="item.assignee && show_assignee"
                    :content="item.assignee.name"
                    placement="top"
                >
                    <img class="assignee" :src="item.assignee.avatar" />
                </el-tooltip>
            </div>
        </div>

        <div class="info">
            <div v-if="item.parent" class="component" @click="openParentItem">
                <span v-if="item.parent.type === 'Epic'">
                    {{ item.parent.summary }}
                </span>
                <span v-else>Subtask of {{ item.parent.key }}</span>
            </div>

            <div class="row">
                <div
                    v-if="item.due_date"
                    class="date due-by"
                    :class="date_priority"
                >
                    {{ due_date.toDate() | relativeDate() }}
                </div>

                <div v-if="item.estimate" class="estimate">
                    {{ estimate_hours }}h
                </div>
            </div>

            <!-- spacer of same width as to-do dropdowm menu -->
            <div class="spacer desktop-only" />
        </div>
    </li>
</template>

<script>
import JiraDefault from '@/assets/svg/jira-default.svg';
import dayjs from 'dayjs';

export default {
    name: 'issue-item',
    props: {
        item: {
            type: Object,
            required: true,
        },
        project: {
            type: Object,
            default: () => null,
        },
        show_resource: {
            type: Boolean,
            default: false,
        },
        show_project: {
            type: Boolean,
            default: false,
        },
        show_assignee: {
            type: Boolean,
            default: false,
        },
        show_status: {
            type: Boolean,
            default: false,
        },
        allow_open: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        type() {
            if (this.project && this.project.jira_settings) {
                if (this.project.jira_settings.labels[this.item.status.label]) {
                    return this.project.jira_settings.labels[
                        this.item.status.label
                    ].color;
                }
            }

            switch (this.item.status.color) {
                case 'green':
                    return 'success';
                case 'blue':
                case 'yellow':
                    return 'primary';
                case 'blue-gray':
                default:
                    return 'info';
            }
        },
        due_date() {
            return dayjs(
                this.item.due_date.toDate
                    ? this.item.due_date.toDate()
                    : this.item.due_date
            );
        },
        date_priority() {
            if (!this.item.due_date) return null;

            const date = this.due_date;

            if (date.isSame(dayjs(), 'day')) {
                return 'today';
            } else if (date < dayjs()) {
                return 'late';
            } else if (date.isSame(dayjs().add(1, 'days'), 'day')) {
                return 'soon';
            }

            return 'low';
        },
        estimate_hours() {
            if (!this.item.estimate) return 0;
            return Number((this.item.estimate / 3600).toFixed(1));
        },
    },
    methods: {
        openItem() {
            if (this.allow_open) {
                window.open(
                    `https://${this.item.resource.name}.atlassian.net/browse/${this.item.key}`,
                    '_blank'
                );
            }
        },
        openParentItem() {
            if (this.allow_open && this.item.parent) {
                window.open(
                    `https://${this.item.resource.name}.atlassian.net/browse/${this.item.parent.key}`,
                    '_blank'
                );
            }
        },
        imgError(e) {
            e.target.onerror = '';
            e.target.src = JiraDefault;
            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
li.issue-item {
    padding: 5px 12px 5px 15px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    user-select: auto;
    box-shadow: none;
    border-radius: 10px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    min-height: 40px;
    cursor: pointer;

    @media screen and (max-width: 992px) {
        align-items: flex-start;
    }

    .resource {
        text-align: center;
        margin-right: 10px;
    }

    .project {
        min-width: 75px;
        margin-right: 10px;
        text-align: left;

        .project_icon {
            width: 16px;
            vertical-align: middle;
        }
    }

    .type {
        width: 27px;
        min-width: 27px;
        height: 27px;
        background-color: #dddddd;
        border-radius: 50%;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .type_icon {
        width: 13px;
        height: 13px;
        object-fit: contain;
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;

        @media screen and (max-width: 992px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .issue-container {
            display: flex;
        }

        .priority_icon {
            width: 15px;
            vertical-align: middle;
        }

        span.key {
            font-size: 13px;
            font-weight: bold;
            margin-left: 7px;
            min-width: 65px;
        }

        span.summary {
            margin-left: 5px;
            padding-left: 10px;
            border-left: 1px solid $border-grey;
            font-size: 13px;
            flex: 1;

            @media screen and (max-width: 992px) {
                margin-left: 6.5px;
            }
        }

        .right {
            display: flex;
            flex-direction: row;
            align-items: center;

            .assignee {
                width: 24px;
                border-radius: 50%;
                margin-left: 10px;
            }

            .el-tag {
                font-size: 11px;
            }
        }
    }

    .info {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 992px) {
            flex-direction: column;
            align-items: flex-end;
            row-gap: 5px;
        }

        .component {
            font-size: 10px;
            margin: 0 10px;
            opacity: 0.7;
        }

        .date {
            display: inline-block;
            padding: 4px 8px;
            color: $black;
            font-size: 12px;
            font-weight: bold;
            border-radius: 4px;

            &.today {
                background-color: rgba($blue, 0.1);
            }

            &.late {
                background-color: rgba($red, 0.1);
            }

            &.soon {
                background-color: rgba($orange, 0.1);
            }
        }
        .due-by {
            font-size: 12px;
            margin-right: 10px;
        }

        .estimate {
            display: inline-block;
            padding: 4px 8px;
            margin-right: 10px;
            color: $black;
            font-size: 12px;
            font-weight: bold;
            border-radius: 4px;
            background: $transparent-grey;

            @media screen and (max-width: 992px) {
                font-size: 10px;
                padding: 4px 6px;
            }
        }

        .spacer {
            width: 40px;
        }
    }
}
</style>
