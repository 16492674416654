import request from '@/utils/request';

// {{host}}/xero/redirect
export function redirectUserToXero() {
    return request({
        url: '/xero/redirect',
        method: 'get',
    });
}

// {{host}}/xero/disconnect
export function disconnectXero() {
    return request({
        url: '/xero/disconnect',
        method: 'delete',
    });
}

// {{host}}/xero/authenticate
export function handleCallbackFromXero(code, state) {
    return request({
        url: '/xero/authenticate',
        method: 'post',
        data: {
            code,
            state,
        },
    });
}

// {{host}}/xero/tenant
export function handleSelectXeroTenant(tenant_id) {
    return request({
        url: '/xero/tenant',
        method: 'post',
        data: {
            tenant_id,
        },
    });
}
