<template>
    <div class="container">
        <div v-if="today_calendar.length > 0" class="current-status">
            <label class="title">
                Today
            </label>
            <calendar-item-card
                v-for="cal in today_calendar"
                :key="cal.id"
                shadow="never"
                class="status-card"
                :event="cal"
            />
        </div>
        <div v-if="tomorrow_calendar.length > 0" class="current-status">
            <label class="title">
                Tomorrow
            </label>
            <calendar-item-card
                v-for="cal in tomorrow_calendar"
                :key="cal.id"
                shadow="never"
                class="status-card"
                :event="cal"
            />
        </div>
    </div>
</template>

<script>
import {momentWithTz} from '@/utils';
import CalendarItemCard from './CalendarItemCard';

export default {
    components: {CalendarItemCard},
    props: {
        calendar: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        today_calendar() {
            const today = momentWithTz().startOf('day');
            return this.calendar.filter((cal) => {
                return today.isSame(momentWithTz(cal.start.toDate()), 'day');
            });
        },
        tomorrow_calendar() {
            const tomorrow = momentWithTz().startOf('day').add(1, 'day');
            return this.calendar.filter((cal) => {
                return tomorrow.isSame(momentWithTz(cal.start.toDate()), 'day');
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    padding: 0px 20px;
    box-sizing: border-box;

    .current-status {
        width: 100%;

        .title {
            display: block;
            margin: 18px 0;
        }

        .time {
            margin-top: 10px;
            font-size: 11px;
            color: rgba($black, 0.6);

            .light {
                opacity: 0.3;
                margin: 0 5px;
            }
        }
    }

    .el-card {
        width: 100%;

        .el-select {
            width: 100%;
            margin-bottom: 10px;
        }

        ::v-deep .el-textarea {
            textarea {
                font-family: 'Rubik', sans-serif;
                padding-right: 60px;
            }
        }
    }

    .status-card {
        h4 {
            margin: 0;
        }
    }
}
</style>
