<template>
    <div class="outer">
        <breadcrumb-header
            v-if="user"
            :labels="[user.name]"
            go_back_to="management_users"
        >
            <template
                v-if="
                    !$store.getters.is_feature_enabled(
                        SUPPORTED_FEATURES.GOOGLE_AUTH
                    )
                "
            >
                <el-button-group>
                    <el-button
                        v-if="tempPassword"
                        v-clipboard:copy="tempPassword"
                        readonly
                        size="mini"
                        icon="el-icon-document-copy"
                        @click="showNotification"
                    >
                        {{ tempPassword }}
                    </el-button>
                    <el-popconfirm
                        title="This will reset the users password to a random password"
                        @confirm="resetTargetPassword"
                    >
                        <el-button
                            slot="reference"
                            size="mini"
                            :disabled="resetting || userIsMe"
                        >
                            Reset Password
                        </el-button>
                    </el-popconfirm>
                    <!--                todo: if we want user deletion-->
                    <!--                <el-popconfirm title="This will delete the user.">-->
                    <!--                    <el-button slot="reference" size="mini" type="danger">-->
                    <!--                        Delete-->
                    <!--                    </el-button>-->
                    <!--                </el-popconfirm>-->
                </el-button-group>
            </template>
        </breadcrumb-header>
        <div class="inner">
            <div v-if="user" class="list">
                <hours-week
                    v-for="week in weeks"
                    :key="week.format('W[-week]')"
                    :week="week"
                    :sessions="sessions_grouped[week.isoWeek()]"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BreadcrumbHeader from '@/pages/management/components/BreadcrumbHeader';
import HoursWeek from '@/pages/management/users/components/HoursWeek';
import {Notification} from 'element-ui';

import moment from 'moment';
import {momentWithTz} from '@/utils';
import {resetPassword} from '@/api/user';
import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';

export default {
    name: 'management-users-detail',
    components: {
        HoursWeek,
        BreadcrumbHeader,
    },
    data() {
        return {
            weeks_period: 4,
            loading: true,
            sessions: [],
            resetting: false,
            tempPassword: null,
        };
    },
    computed: {
        SUPPORTED_FEATURES() {
            return SUPPORTED_FEATURES;
        },
        user() {
            return this.$store.getters.userWithId(this.$route.params.user_id);
        },
        userIsMe() {
            return this.$store.getters.user.id === this.$route.params.user_id;
        },
        sessions_grouped() {
            const weeks = this.weeks
                .map((w) => w.isoWeek())
                .reduce((acc, curr) => ((acc[curr] = []), acc), {});

            this.sessions
                .filter((s) => s.stop)
                .map((session) => {
                    const week = momentWithTz(
                        moment.utc(session.start.seconds * 1000)
                    )
                        .local()
                        .isoWeek();
                    weeks[week].push(session);
                });

            return weeks;
        },
        period_start() {
            return momentWithTz()
                .startOf('isoWeek')
                .startOf('day')
                .add(-this.weeks_period + 1, 'weeks');
        },
        weeks() {
            return Array(this.weeks_period)
                .fill()
                .map((_, idx) =>
                    momentWithTz(this.period_start).add(idx, 'weeks')
                );
        },
    },
    mounted() {
        if (!this.user) {
            this.$router.replace({name: 'management_users'});
            return;
        }

        const from_date = this.period_start.toDate();
        const to_date = momentWithTz().endOf('isoWeek').endOf('day').toDate();

        this.$bind(
            'sessions',
            this.$fire
                .collection('sessions')
                .where('user', '==', this.$fire.doc(`users/${this.user.id}`))
                .where('start', '>=', from_date)
                .where('start', '<=', to_date)
                .orderBy('start', 'asc'),
            {maxRefDepth: 0}
        )
            .then(() => {})
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
        moment(params) {
            return momentWithTz(params);
        },
        showNotification() {
            Notification({
                type: 'success',
                title: 'Copied',
                message: 'Password copied to clipboard.',
            });
        },
        resetTargetPassword() {
            this.resetting = true;
            this.tempPassword = null;
            resetPassword(this.user.id)
                .then((res) => {
                    this.tempPassword = res.data.password;
                })
                .catch((err) => {
                    Notification({
                        type: 'error',
                        title: 'Error',
                        message: `An error occurred while resetting user's password, ${err}`,
                    });
                })
                .finally(() => {
                    this.resetting = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    .inner {
        overflow: scroll;

        .list {
            max-width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}
</style>
