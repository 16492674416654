<template>
    <el-form
        ref="form"
        auto-complete="on"
        :model="form"
        :rules="rules"
        label-width="160px"
        class="form-wrapper"
    >
        <!-- IS_TEMPLATE (ON CREATE ONLY) -->
        <!--*******************************************-->

        <el-form-item v-if="!estimate && !is_template" label="New template">
            <el-switch v-model="form.is_template" />
        </el-form-item>

        <!-- PROJECT (ON CREATE ONLY) -->
        <!--*******************************************-->

        <el-form-item v-if="!estimate && !form.is_template" label="Project">
            <project-dropdown v-model="selectedProjectId" />
        </el-form-item>

        <!-- REFERENCE -->
        <!--*******************************************-->

        <el-form-item label="Reference" v-if="!form.is_template">
            <el-input
                v-model="form.ref"
                name="ref"
                type="text"
                auto-complete="off"
                disabled
            />
        </el-form-item>

        <!-- ESTIMATE TYPE -->
        <!--*******************************************-->

        <el-form-item>
            <div class="type-selector">
                <el-button
                    class="explore"
                    :class="{selected: form.type === ESTIMATE_TYPES.EXPLORE}"
                    :disabled="!!estimate"
                    @click="form.type = ESTIMATE_TYPES.EXPLORE"
                >
                    Explore
                </el-button>
                <el-button
                    class="create"
                    :class="{selected: form.type === ESTIMATE_TYPES.CREATE}"
                    :disabled="!!estimate"
                    @click="form.type = ESTIMATE_TYPES.CREATE"
                >
                    Create
                </el-button>
            </div>
        </el-form-item>

        <!-- TEMPLATE (ON CREATE ONLY) -->
        <!--*******************************************-->

        <el-form-item v-if="!is_editing && !groups.length" label="Template">
            <el-select
                v-model="template"
                filterable
                placeholder="Start from scratch"
                value-key="id"
                :disabled="is_estimate_completed || !!change_request_link"
            >
                <el-option
                    v-for="t in filtered_templates"
                    :key="t.id"
                    :label="t.title"
                    :value="t"
                />
            </el-select>
        </el-form-item>

        <hr />

        <!-- TITLE -->
        <!--*******************************************-->

        <el-form-item label="Title" prop="title">
            <el-input
                ref="title"
                v-model="form.title"
                name="title"
                type="text"
                auto-complete="off"
                :disabled="is_estimate_locked"
            />
        </el-form-item>

        <!-- DESCRIPTION -->
        <!--*******************************************-->

        <el-form-item label="Description" prop="content">
            <el-input
                v-model="form.description"
                :disabled="is_estimate_locked"
            />
        </el-form-item>

        <!-- CLIENT -->
        <!--*******************************************-->

        <el-form-item label="Client" prop="client" v-if="!form.is_template">
            <el-select
                v-model="form.client"
                filterable
                placeholder="No client selected"
                value-key="id"
                :disabled="is_estimate_locked"
                clearable
            >
                <el-option-group key="no-client" label="">
                    <el-option
                        key="no-client"
                        label="Empty client"
                        :value="null"
                    >
                        <span>Empty client</span>
                        <span class="option-subtitle">
                            Select one later on
                        </span>
                    </el-option>
                </el-option-group>
                <el-option-group key="clients" label="Existing clients:">
                    <el-option
                        v-for="c in clients"
                        :key="c.id"
                        :label="c.name"
                        :value="`clients/${c.id}`"
                    />
                </el-option-group>
            </el-select>
        </el-form-item>

        <!-- UNIT -->
        <!--*******************************************-->

        <hr />

        <el-row>
            <el-col :sm="12">
                <el-form-item label="Unit">
                    <el-select
                        v-model="form.unit"
                        :disabled="is_estimate_locked"
                    >
                        <el-option
                            v-for="u in unit_types"
                            :key="u.value"
                            :label="u.label"
                            :value="u.value"
                        />
                    </el-select>
                </el-form-item>
            </el-col>

            <!-- HOURS PER DAY -->
            <!--*******************************************-->

            <el-col :sm="12">
                <el-form-item label="Hours / day">
                    <el-input-number
                        v-model="form.hours_per_day"
                        :disabled="is_estimate_locked"
                        class="small"
                    />
                </el-form-item>
            </el-col>
        </el-row>

        <!-- RATE -->
        <!--*******************************************-->

        <el-form-item label="Rate">
            <span class="small-label">ex GST</span>
            <money
                v-model="form.rate"
                :disabled="is_estimate_locked"
                v-bind="money"
                class="el-input__inner"
            />
        </el-form-item>

        <div class="row">
            <el-button
                v-if="is_editing"
                :disabled="loading"
                @click="handleCancel"
            >
                <span v-if="is_estimate_locked">Close</span>
                <span v-else>Cancel</span>
            </el-button>
            <el-button
                v-if="!is_estimate_locked"
                :disabled="loading"
                @click="handleSubmit"
            >
                {{ estimate && estimate.id ? 'Save' : 'Create' }}
            </el-button>
        </div>

        <template v-if="change_request_link">
            <hr />
            <div class="cr-link">
                <span>
                    This estimate with be linked to change request
                    <strong>{{ change_request_link }}</strong>
                </span>
            </div>
        </template>

        <template v-if="groups.length">
            <hr />
            <div class="group-summary">
                <span>
                    The following groups will be copied into the new estimate
                    from
                    <strong> {{ source_estimate }} </strong>
                    <span v-if="clone_rule === 'included'">
                        (included items only)
                    </span>
                    <span v-if="clone_rule === 'excluded'">
                        (excluded items only)
                    </span>
                    :
                </span>
                <ul>
                    <li v-for="group in groups" :key="group.id">
                        {{ group.title }}
                    </li>
                </ul>
            </div>
        </template>
    </el-form>
</template>

<script>
import {Notification} from 'element-ui';

import {ESTIMATE_STATUS, ESTIMATE_TYPES, RATE_UNIT} from '@/enums';

import estimateMixin from '@/mixins/estimate.mixin';

import ProjectDropdown from '@/components/generic/ProjectDropdown';
import firebase from 'firebase/app';

export default {
    name: 'estimate-form',
    components: {
        ProjectDropdown,
    },
    mixins: [estimateMixin],
    props: {
        is_template: {
            type: Boolean,
            default: false,
        },
        estimate: {
            type: Object,
            default: null,
        },
        copied_form: {
            type: Object,
            default: null,
        },
        groups: {
            type: Array,
            default: () => [],
        },
        clone_rule: {
            type: String,
            default: 'full',
        },
    },
    data() {
        return {
            ESTIMATE_TYPES: ESTIMATE_TYPES,
            selectedProjectId: this.$route.params.project_id ?? null,
            // TODO: Improve this initialisation, pretty ugly!
            form: this.copied_form ??
                this.estimate ?? {
                    is_template: this.is_template,
                    ref: null,
                    title: this.$route.params.title ?? null,
                    type: null,
                    description: this.$route.params.description ?? null,
                    client: null,
                    unit: RATE_UNIT.DAY,
                    hours_per_day: +process.env.VUE_APP_DAILY_HOURS,
                    rate:
                        +process.env.VUE_APP_HOURLY_RATE *
                        +process.env.VUE_APP_DAILY_HOURS,
                },
            rules: {
                ref: [{required: true, trigger: 'blur'}],
                title: [{required: true, trigger: 'blur'}],
                template: [{required: true, trigger: 'blur'}],
            },
            unit_types: [
                {value: RATE_UNIT.HOUR, label: 'Hour'},
                {value: RATE_UNIT.DAY, label: 'Day'},
            ],
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
            },
            templates: [],
            template: null,
            loading: false,
        };
    },
    computed: {
        is_editing() {
            return this.estimate && this.estimate.id;
        },
        project() {
            if (!this.selectedProjectId) return null;
            return this.$store.getters.projectWithId(this.selectedProjectId);
        },
        clients() {
            return this.$store.getters.clientsForProjectWithId(
                this.selectedProjectId
            );
        },
        new_ref() {
            if (!this.project) return null;
            // max_ref_number is auto-incremented by cloud function upon save
            const max_ref_number = this.project.max_ref_number || 0;
            return `${this.project.ref}-E-${`${max_ref_number + 1}`.padStart(
                3,
                '0'
            )}`;
        },
        source_estimate() {
            if (this.groups.length) {
                return this.$options.filters.fireRef2id(
                    this.groups[0].estimate
                );
            }
            return null;
        },
        change_request_link() {
            return this.$route.params.change_request_id || null;
        },
        filtered_templates() {
            if (this.templates) {
                const templates = this.templates.filter(
                    (template) =>
                        !template.type || template.type === this.form.type
                );
                // move default template to top of list
                const default_index = templates.findIndex(
                    (t) => t.title === 'Default'
                );
                if (default_index >= 0) {
                    templates.unshift(templates.splice(default_index, 1)[0]);
                }
                return templates;
            }
            return [];
        },
    },
    watch: {
        project: function (val) {
            this.form.ref = this.new_ref;

            if (this.clients.length)
                this.form.client = `clients/${this.clients[0].id}`;
        },
        estimate: function (val) {
            this.initForm(val);
        },
        template: {
            handler(val) {
                // overwrite form details with template details
                this.form.title =
                    this.form.is_template || this.template.title === 'Default'
                        ? ''
                        : this.template.title;
                this.form.description = this.template.description;
                this.form.hours_per_day = this.template.hours_per_day;
                this.form.unit = this.template.unit;
                // set rate after calculation based on hours & unit
                this.$nextTick(() => {
                    this.form.rate = this.template.rate;
                });
            },
        },
        'form.unit': {
            handler(val, oldVal) {
                if (!oldVal || oldVal === val) return;
                // If the unit changes, recalculate the rate from the default rate
                if (val === RATE_UNIT.HOUR) {
                    // In case of hourly rate, assign the default rate
                    this.form.rate = +process.env.VUE_APP_HOURLY_RATE;
                } else if (val === RATE_UNIT.DAY) {
                    // In case of daily rate, calculate the daily rate
                    this.form.rate =
                        +process.env.VUE_APP_HOURLY_RATE *
                        this.form.hours_per_day;
                }
            },
        },
        'form.hours_per_day': {
            handler(val, oldVal) {
                if (!oldVal || oldVal === val) return;
                if (this.form.unit === RATE_UNIT.DAY) {
                    // In case of daily rate, calculate the daily rate
                    this.form.rate = +process.env.VUE_APP_HOURLY_RATE * val;
                }
            },
        },
        filtered_templates(val) {
            if (val.length) {
                this.template = val[0];
            } else {
                this.template = null;
            }
        },
    },
    mounted() {
        this.initForm();
    },
    methods: {
        initForm() {
            if (this.is_editing) {
                this.form = {...this.form, ...this.estimate};
            } else {
                this.form.ref = this.new_ref;
                this.form.type = this.form.type || ESTIMATE_TYPES.EXPLORE;

                if (this.clients.length)
                    this.form.client = `clients/${this.clients[0].id}`;

                // load templates if not already loaded, and not copying an estimate
                if (!this.templates.length && !this.copied_form) {
                    this.$bind(
                        'templates',
                        this.$fire
                            .collection('estimates')
                            .where('is_template', '==', true)
                            .orderBy('title', 'asc')
                    ).catch((e) => {
                        console.log('Error loading templates: ', e);
                    });
                }
            }
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true;

                    let payload = {
                        ...this.form,
                        ref: this.form.ref?.toUpperCase(),
                    };

                    if (this.project) {
                        payload.project = this.$fire.doc(
                            `projects/${this.project.id}`
                        );
                    }
                    if (payload.client) {
                        // remap string ref from vuefire binding to actual ref
                        payload.client = this.$fire.doc(payload.client);
                    } else {
                        // remove client value from estimate if not set (vuefire does not detect null->ref updates)
                        payload.client = firebase.firestore.FieldValue.delete();
                    }

                    if (this.is_editing) {
                        this.updateEstimate(payload);
                    } else {
                        this.addEstimate(payload);
                    }

                    this.$emit('saved');
                } else {
                    console.log('Form not valid...');
                    return false;
                }
            });
        },
        handleCancel() {
            this.$emit('cancel');
        },
        async addEstimate(estimateData) {
            const data = {...estimateData};
            if (data.is_template) {
                // remove non-template fields
                delete data.ref;
                delete data.project;
                delete data.client;
            }

            try {
                const estimate = await this.$store.dispatch('addEstimate', {
                    ...data,
                    status: ESTIMATE_STATUS.DRAFT,
                });

                if (this.groups.length) {
                    // Clone provided groups
                    await this.cloneGroupsFromExisting(estimate, this.groups);
                } else {
                    // Add line items from template
                    await this.populateFromTemplate(estimate);
                }

                // assign to change request if ID was provided
                if (this.change_request_link) {
                    this.$store.dispatch('assignEstimateToChangeRequest', {
                        estimate_id: estimate.id,
                        change_request_id: this.$route.params.change_request_id,
                    });
                }
                if (data.is_template) {
                    this.$router.push({
                        name: 'estimate_template_edit',
                        params: {
                            estimate_id: estimate.id,
                        },
                    });
                } else {
                    this.$router.push({
                        name: 'project_detail_estimate+detail',
                        params: {
                            project_id: this.project.id,
                            estimate_id: estimate.id,
                        },
                    });
                }
            } catch (err) {
                this.creating = false;
                console.log('error adding estimate', err);
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: err.message,
                });
            }
        },
        updateEstimate(data) {
            if (data.is_template) {
                data.id = this.estimate.id;
                delete data.ref;
            }
            this.$store.dispatch('updateEstimate', data).catch((err) => {
                this.creating = false;
                console.log('error updating estimate', err);
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: err.message,
                });
            });
        },
        async populateFromTemplate(estimate) {
            if (this.template === null) {
                // add a placeholder group
                await this.$store.dispatch('addGroupToEstimate', {
                    estimate: estimate,
                    title: '',
                    sort: 0,
                    discount: 0,
                });
                return;
            }

            const sourceGroups = [];
            // get all groups for template
            const sourceGroupSnapshot = await this.$fire
                .collection('estimate_groups')
                .where(
                    'estimate',
                    '==',
                    this.$fire.doc(`estimates/${this.template.id}`)
                )
                .get();
            sourceGroupSnapshot.forEach((sourceGroup) => {
                sourceGroups.push({...sourceGroup.data(), id: sourceGroup.id});
            });
            await this.cloneGroupsFromExisting(estimate, sourceGroups);
        },
        async cloneGroupsFromExisting(estimate, groups) {
            if (groups?.length === 0) return;

            // TODO: put these operations in a transaction?
            try {
                // loop over source groups
                for (const sourceGroup of groups) {
                    // create a new group copying the source data
                    const newGroup = await this.$store.dispatch(
                        'addGroupToEstimate',
                        {
                            estimate: estimate,
                            title: sourceGroup.title,
                            sort: sourceGroup.sort || 0,
                            discount: sourceGroup.discount || 0,
                        }
                    );

                    // get all tasks for the source group
                    const sourceTasks = await this.$fire
                        .collection('estimate_tasks')
                        .where(
                            'group',
                            '==',
                            this.$fire.doc(`estimate_groups/${sourceGroup.id}`)
                        )
                        .get();

                    // loop over source tasks
                    for (const task of sourceTasks.docs) {
                        let newTaskData = {
                            ...task.data(),
                            estimate: estimate,
                            group: newGroup,
                        };
                        if (
                            newTaskData.excluded &&
                            this.clone_rule === 'included'
                        ) {
                            // if we're cloning included only, we can skip excluded tasks
                            continue;
                        }

                        // get all subtasks for the source task
                        const sourceSubtasks = await this.$fire
                            .collection('estimate_subtasks')
                            .where(
                                'task',
                                '==',
                                this.$fire.doc(`estimate_tasks/${task.id}`)
                            )
                            .get();

                        // check status of subtasks to see if this task should be added:
                        // at least one subtask must match the clone rule condition.
                        // (We can skip this if doing a full clone, or cloning excluded and this task is excluded)
                        if (
                            !(
                                this.clone_rule === 'full' ||
                                (this.clone_rule === 'excluded' &&
                                    (sourceGroup.excluded ||
                                        newTaskData.excluded))
                            )
                        ) {
                            const valid = sourceSubtasks.docs.find(
                                (subtask) => {
                                    const subtaskData = subtask.data();
                                    if (this.clone_rule === 'excluded')
                                        return subtaskData.excluded;
                                    return !subtaskData.excluded;
                                }
                            );
                            if (!valid) continue;
                        }

                        // if we've met the clone conditions, create a new task copying the source data
                        const newTask = await this.$store.dispatch(
                            'addTaskToEstimateGroup',
                            newTaskData
                        );

                        // loop over source subtasks
                        for (const subtask of sourceSubtasks.docs) {
                            const subtaskData = subtask.data();
                            // add this subtask if it matches the clone rule
                            if (
                                (this.clone_rule === 'excluded' &&
                                    (sourceGroup.excluded ||
                                        newTaskData.excluded ||
                                        subtaskData.excluded)) ||
                                (this.clone_rule === 'included' &&
                                    !subtaskData.excluded) ||
                                this.clone_rule === 'full'
                            ) {
                                let newSubtaskData = {
                                    ...subtaskData,
                                    estimate: estimate,
                                    group: newGroup,
                                    task: newTask,
                                    excluded: false,
                                };

                                // create a new subtask copying the source data
                                const newSubtask = await this.$store.dispatch(
                                    'addSubtaskToEstimateTask',
                                    newSubtaskData
                                );
                            }
                        }
                    }
                }
            } catch (err) {
                console.log('Error cloning groups', err);
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: err.message,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.el-form {
    margin: 40px auto;

    ::v-deep .el-form-item {
        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        .el-input,
        .el-select {
            flex: 1;
            height: 100%;
            width: 100%;
        }

        .el-input-number {
            width: auto;
        }
    }

    .el-form-item__content {
        .small-label {
            font-size: 8px;
            position: absolute;
            left: -172px;
            width: 160px;
            text-align: right;
            top: 13px;
        }
    }

    h1 {
        text-align: right;
        font-size: 17px;
        margin-top: 10px;
    }
}

.option-subtitle {
    float: right;
    color: #8492a6;
    font-size: 11px;
    font-weight: normal;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .el-checkbox {
        margin-right: 20px;
    }

    .el-button {
        width: 200px;
    }
}

hr {
    margin: 20px 50px;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}

.group-summary {
    font-size: 12px;
    background-color: $transparent-grey;
    padding: 10px;
    border-radius: 4px;
}

.cr-link {
    font-size: 12px;
    background-color: $transparent-grey;
    padding: 10px;
    border-radius: 4px;
}

.type-selector {
    display: flex;

    .el-button {
        width: 50%;

        &.explore {
            &:hover:not(.is-disabled) {
                color: $blue;
                background-color: rgba($blue, 0.1);
                border-color: $blue;
            }
            &.selected {
                color: $blue;
                background-color: rgba($blue, 0.1);
                box-shadow: 0 0 0 1px $blue;
                border-color: $blue;
            }
        }

        &.create {
            &:hover:not(.is-disabled) {
                color: $orange;
                background-color: rgba($orange, 0.1);
                border-color: $orange;
            }
            &.selected {
                color: $orange;
                background-color: rgba($orange, 0.1);
                box-shadow: 0 0 0 1px $orange;
                border-color: $orange;
            }
        }
    }
}
</style>
