<template>
    <div class="loader" :class="color">
        <div
            v-loading="true"
            class="spinner"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0)"
        />
        <span>{{ message }}</span>
    </div>
</template>

<script>
export default {
    name: 'loader',
    props: {
        message: {
            type: String,
            required: false,
            default: 'Loading',
        },
        color: {
            type: String,
            required: false,
            default: 'black',
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.loader {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    ::v-deep .spinner {
        width: 40px;
        height: 40px;
    }

    span {
        margin-top: -2px;
    }

    &.black {
        ::v-deep .spinner {
            .el-loading-spinner i {
                color: $black;
            }
        }
        span {
            color: $black;
        }
    }

    &.white {
        ::v-deep .spinner {
            .el-loading-spinner i {
                color: $white;
            }
        }
        span {
            color: $white;
        }
    }
}
</style>
