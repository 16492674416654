<template>
    <div class="issue-item-container">
        <template v-if="image_icon">
            <img
                class="icon"
                :alt="image_label"
                :src="image_icon"
                @error="imgError"
            />
        </template>
        <template v-else>
            <svgicon
                :name="ISSUE_ICONS[ISSUE_CATEGORIES.TODO]"
                :class="ISSUE_ICONS[ISSUE_CATEGORIES.TODO]"
                class="icon"
            />
        </template>

        <span class="key">{{ data.key ?? 'Todo' }}</span>
        <span v-if="show_summary" class="summary">{{ data.summary }}</span>
    </div>
</template>

<script>
import JiraDefault from '@/assets/svg/jira-default.svg';
import {ISSUE_CATEGORIES, ISSUE_ICONS} from '@/enums';

export default {
    name: 'issue-item-compact',
    props: {
        data: {
            type: Object,
            required: true,
        },
        show_summary: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ISSUE_CATEGORIES() {
            return ISSUE_CATEGORIES;
        },
        ISSUE_ICONS() {
            return ISSUE_ICONS;
        },
        image_label() {
            return this.data.type?.label ?? 'Todo';
        },
        image_icon() {
            return this.data.type?.icon;
        },
    },
    methods: {
        imgError(e) {
            e.target.onerror = '';
            e.target.src = JiraDefault;
            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.issue-item-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    user-select: none;
    overflow: hidden;
    gap: 5px;

    .icon {
        width: 13px;
        height: 13px;
        object-fit: contain;
    }

    span.key {
        font-size: 13px;
        font-weight: bold;
    }

    span.summary {
        font-size: 13px;
        flex: 1;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
