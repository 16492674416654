<template>
    <svgicon
        name="heart"
        :style="{width: width + 'px', fill: color}"
        class="heart"
        draggable="false"
    />
</template>

<script>
export default {
    name: 'heart',
    props: {
        color: {
            type: String,
            default: '#F92139',
        },
        width: {
            type: Number,
            default: 15,
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.heart {
    animation: HEARTBEAT 2.5s infinite;
    pointer-events: none;
    user-select: none;
}

@keyframes HEARTBEAT {
    0% {
        transform: scale(1);
    }
    5% {
        transform: scale(1.2);
    }
    10% {
        transform: scale(1.1);
    }
    15% {
        transform: scale(1.3);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}
</style>
