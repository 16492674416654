import request from '@/utils/request';

// {{host}}/paymentplan/invoice/:invoice_id/submit
export function submitPaymentPlanInvoiceToXero(invoice_id) {
    return request({
        url: `/paymentplan/invoice/${invoice_id}/submit`,
        method: 'post',
    });
}

// {{host}}/paymentplan/invoice/:invoice_id/email
export function sendPaymentPlanInvoiceEmail(invoice_id) {
    return request({
        url: `/paymentplan/invoice/${invoice_id}/email`,
        method: 'post',
    });
}
