<template>
    <div class="todo-item-view">
        <todo-checkbox
            :id="todo_id"
            :checked="is_checked"
            :actual_priority="priority"
            class="checkbox"
            @on_change="toggleStatus"
            @priority_changed="handlePriorityChange"
        />

        <div class="content" @click="$emit('click')">
            <slot />
        </div>
    </div>
</template>

<script>
import TodoCheckbox from '@/components/todo/TodoCheckbox';

export default {
    name: 'rendered-todo-item',
    components: {
        TodoCheckbox,
    },
    props: {
        todo_id: {
            type: String,
            default: null,
        },
        is_checked: {
            type: Boolean,
            default: false,
        },
        priority: {
            type: Number,
            default: null,
        },
        show_priority: {
            type: Boolean,
            default: false,
        },
        content: {
            type: String,
            default: () => null,
        },
    },
    mounted() {},

    methods: {
        handlePriorityChange(value) {
            this.$emit('priority:change', value);
        },

        toggleStatus() {
            this.$emit('status:toggle');
        },
    },
};
</script>

<style lang="scss" scoped>
.todo-item-view {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    font-size: 14px;

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        margin-right: 10px;
    }

    .checkbox {
        margin-right: 10px;
        z-index: 10;
    }
}
</style>
