<template>
    <div>
        <actions-buttons>
            <el-tabs v-model="activeTab" @tab-click="tabChanged">
                <el-tab-pane label="Timeline" name="_timeline" />
                <el-tab-pane label="Calendar" name="_calendar" />
                <el-tab-pane label="Schedule" name="_schedule" />
            </el-tabs>
        </actions-buttons>

        <transition name="slide" mode="out-in">
            <PlannerTab_timeline
                v-if="activeTab == '_timeline'"
                key="timeline"
            />
            <PlannerTab_calendar
                v-else-if="activeTab == '_calendar'"
                key="calendar"
            />
            <PlannerTab_schedule
                v-else-if="activeTab == '_schedule'"
                key="schedule"
            />
        </transition>
    </div>
</template>

<script>
import ActionsButtons from '@/components/generic/ActionsButtons';

import PlannerTab_timeline from '@/pages/planner/tabs/PlannerTab_timeline';
import PlannerTab_calendar from '@/pages/planner/tabs/PlannerTab_calendar';
import PlannerTab_schedule from '@/pages/planner/tabs/PlannerTab_schedule';

export default {
    name: 'component-detail',
    components: {
        ActionsButtons,
        PlannerTab_timeline,
        PlannerTab_calendar,
        PlannerTab_schedule,
    },
    data() {
        return {
            activeTab: '_timeline',
        };
    },
    watch: {
        $route(to) {
            this.activeTab = to.meta.tab;
        },
    },
    created() {
        this.activeTab = this.$router.currentRoute.meta.tab;
    },
    methods: {
        tabChanged(event) {
            this.$router.replace({
                name: `planner${event.name}`,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.slide {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateX(5px);
    }
}
</style>
