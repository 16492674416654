<template>
    <div class="container">
        <div class="widget-container">
            <div class="grid">
                <users-widget />
                <datetime-widget />
            </div>
        </div>

        <span class="copyright">
            Made with <heart :width="12" /> by CodeFish Studio
        </span>
    </div>
</template>

<script>
import DatetimeWidget from '@/components/widgets/DateTimeWidget';
import UsersWidget from '@/components/widgets/UsersWidget';
import Heart from '@/components/generic/Heart';

export default {
    name: 'tv',
    components: {
        Heart,
        DatetimeWidget,
        UsersWidget,
    },
};
</script>

<style lang="scss">
#app {
    background: $actual-dark-black;

    .copyright {
        color: white !important;
    }
}
</style>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    padding: 20px 20px;

    .widget-container {
        width: 100%;
        height: 90%;
        box-sizing: border-box;
        padding: 20px 20px;
        .grid {
            width: 100%;
            height: 100%;
            color: white;
            display: flex;
            * {
                flex-grow: 1;
            }
        }
    }

    .copyright {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: block;
        text-align: center;
        user-select: none;
        font-size: 13px;
        font-weight: 400;
        color: white;
        margin-bottom: 50px;

        svg {
            margin: 0 3px;
        }
    }
}
</style>
