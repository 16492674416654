/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'triangle': {
    width: 25,
    height: 32,
    viewBox: '0 0 25 32',
    data: '<path pid="0" d="M22.86 19.86L3.753 31.634a2.45 2.45 0 01-3.394-.842A2.537 2.537 0 010 29.491V2.508C0 1.123 1.106 0 2.471 0c.452 0 .896.126 1.282.364L22.86 12.14c2.1 1.294 2.768 4.072 1.493 6.203-.37.62-.883 1.14-1.493 1.516z" _fill="#000" fill-rule="nonzero"/>'
  }
})
