import {Plugin} from '@tiptap/pm/state';
import {Decoration, DecorationSet} from '@tiptap/pm/view';

export function SuggestionPlugin({
    pluginKey,
    editor,
    rgx,
    allowSpaces,
    startOfLine,
    decorationTag,
    decorationClass,
    command = () => null,
    items = async () => [],
    render = () => ({}),
    allow = () => true,
}) {
    const renderer = render?.();

    return new Plugin({
        key: pluginKey,

        view() {
            return {
                update: async (view, prevState) => {
                    const prev = this.key?.getState(prevState);
                    const next = this.key?.getState(view.state);

                    // See how the state changed
                    const moved =
                        prev.active &&
                        next.active &&
                        prev.range.from !== next.range.from;
                    const started = !prev.active && next.active;
                    const stopped = prev.active && !next.active;
                    const changed =
                        !started && !stopped && prev.query !== next.query;
                    const handleStart = started || moved;
                    const handleChange = changed && !moved;
                    const handleExit = stopped || moved;

                    // Cancel when suggestion isn't active
                    if (!handleStart && !handleChange && !handleExit) {
                        return;
                    }

                    const state = handleExit && !handleStart ? prev : next;
                    const decorationNode = document.querySelector(
                        `[data-decoration-id="${state.decorationId}"]`
                    );

                    const props = {
                        editor,
                        range: state.range,
                        query: state.query,
                        text: state.text,
                        items: handleChange ? await items(state.query) : null,
                        command: (commandProps) => {
                            command({
                                editor,
                                range: state.range,
                                props: commandProps,
                            });
                        },
                        decorationNode,
                        // virtual node for popper.js or tippy.js
                        // this can be used for building popups without a DOM node
                        clientRect: decorationNode
                            ? () => {
                                  // because `items` can be asynchronous we’ll search for the current decoration node
                                  const {decorationId} = this.key?.getState(
                                      editor.state
                                  );
                                  const currentDecorationNode = document.querySelector(
                                      `[data-decoration-id="${decorationId}"]`
                                  );

                                  if (currentDecorationNode) {
                                      return currentDecorationNode.getBoundingClientRect();
                                  }
                                  return null;
                              }
                            : null,
                    };

                    if (handleExit) {
                        //todo: we are definitely calling on exit well before the item gets the click event.
                        // Setting this timeout to something small/undefined doesn't work.
                        // likely due to the RenderedInput implementation debouncing with a value of 50
                        setTimeout(() => {
                            renderer?.onExit?.(props);
                        }, 100);
                    }

                    if (handleChange) {
                        renderer?.onUpdate?.(props);
                    }

                    if (handleStart) {
                        renderer?.onStart?.(props);

                        const its = await items(state.query);

                        renderer?.onUpdate?.({
                            ...props,
                            items: its,
                        });
                    }
                },
            };
        },

        state: {
            // Initialize the plugin's internal state.
            init() {
                return {
                    active: false,
                    range: {},
                    query: null,
                    text: null,
                    composing: false,
                };
            },

            // Apply changes to the plugin state from a view transaction.
            apply(transaction, prev) {
                const {composing} = editor.view;
                const {selection} = transaction;
                const {empty, from} = selection;
                const next = {...prev};

                next.composing = composing;

                // We can only be suggesting if there is no selection
                // or a composition is active (see: https://github.com/ueberdosis/tiptap/issues/1449)
                if ((empty || editor.view.composing) && editor.isFocused) {
                    // Reset active state if we just left the previous suggestion range
                    if (
                        (from < prev.range.from || from > prev.range.to) &&
                        !composing &&
                        !prev.composing
                    ) {
                        next.active = false;
                    }

                    const textFrom =
                        selection.$from.depth <= 0
                            ? 0
                            : selection.$from.before();

                    const text = selection.$from.doc.textBetween(
                        textFrom,
                        selection.$from.pos,
                        '\0',
                        '\0'
                    );

                    const strpos = allowSpaces
                        ? -1
                        : text.lastIndexOf(' ', selection.$from.pos);

                    const word = text.substr(strpos + 1, selection.$from.pos);

                    const matches = rgx.exec(word);

                    if (matches && allow({editor, word})) {
                        const decorationId = `id_${Math.floor(
                            Math.random() * 0xffffffff
                        )}`;

                        next.active = true;

                        next.decorationId = prev.decorationId
                            ? prev.decorationId
                            : decorationId;

                        next.range = {
                            from: textFrom + (strpos > -1 ? strpos + 2 : 0),
                            to: selection.$from.pos,
                        };

                        next.query = word;
                    } else {
                        next.active = false;
                    }
                } else {
                    next.active = false;
                }

                // Make sure to empty the range if suggestion is inactive
                if (!next.active) {
                    next.decorationId = null;
                    next.range = {};
                    next.query = null;
                    next.text = null;
                }

                return next;
            },
        },

        props: {
            // Call the keydown hook if suggestion is active.
            handleKeyDown(view, event) {
                const {active, range} = this.getState(view.state);

                if (!active) {
                    return false;
                }

                return renderer?.onKeyDown?.({view, event, range}) || false;
            },

            // Setup decorator on the currently active suggestion.
            decorations(state) {
                const {active, range, decorationId} = this.getState(state);

                if (!active) {
                    return null;
                }
                if (range.from === 0 && range.to === 1) {
                    return DecorationSet.create(state.doc, [
                        Decoration.node(0, state.doc.content.size, {
                            nodeName: decorationTag,
                            class: decorationClass,
                            'data-decoration-id': decorationId,
                        }),
                    ]);
                }
                return DecorationSet.create(state.doc, [
                    Decoration.inline(range.from, range.to, {
                        nodeName: decorationTag,
                        class: decorationClass,
                        'data-decoration-id': decorationId,
                    }),
                ]);
            },
        },
    });
}
