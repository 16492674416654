<template>
    <div class="week">
        <div class="week-header">
            <span class="week-number"> {{ week | dateformat('[W]W') }}: </span>
            <div v-if="days_with_sessions">
                <label>Average day:</label>
                <span>
                    {{ average_start_time }}
                    <svgicon name="triangle" class="arrow" />
                    {{ average_end_time }}
                </span>
                <span>{{ average_diff_time }}h</span>
            </div>
        </div>
        <div class="days">
            <hours-day
                v-for="(day, idx) in data"
                :key="day.date.unix()"
                :date="day.date"
                :data="day"
                :sessions="data[idx].sessions"
            />
        </div>
    </div>
</template>

<script>
import HoursDay from '@/pages/management/users/components/HoursDay';

import moment from 'moment';

export default {
    name: 'hours-week',
    components: {
        HoursDay,
    },
    props: {
        week: {
            type: Object,
            required: true,
        },
        sessions: {
            type: Array,
            required: true,
        },
    },
    computed: {
        data() {
            var days = Array(7)
                .fill()
                .map((_, idx) => moment(this.week).add(idx, 'day'))
                .map((d) => ({
                    date: d,
                    sessions: [],
                    start: 0,
                    end: 0,
                }));

            this.sessions.slice(0).map((session) => {
                const date = moment.utc(session.start.seconds * 1000).local();
                const week_day = date.weekday();

                // Don't add the session if is not in the days range
                if (days[week_day].sessions !== undefined) {
                    days[week_day].sessions.push(session);
                }
            });

            return days;
        },
        days_with_sessions() {
            return this.data.reduce(
                (acc, cur) => acc + (cur.sessions.length ? 1 : 0),
                0
            );
        },
        average_start_time() {
            return this.$options.filters.averageTimes(
                this.data
                    .filter((d) => d.sessions.length)
                    .map((d) => {
                        return moment
                            .utc(d.sessions[0].start.seconds * 1000)
                            .local()
                            .format('HH:mm:ss a');
                    })
            );
        },
        average_end_time() {
            return this.$options.filters.averageTimes(
                this.data
                    .filter((d) => d.sessions.length)
                    .map((d) => {
                        return moment
                            .utc(
                                d.sessions[d.sessions.length - 1].stop.seconds *
                                    1000
                            )
                            .local()
                            .format('HH:mm:ss a');
                    })
            );
        },
        average_diff_time() {
            return this.$options.filters.diffTimes(
                this.average_start_time,
                this.average_end_time
            );
        },
    },
    methods: {
        moment(params) {
            return moment(params);
        },
    },
};
</script>

<style lang="scss" scoped>
.week {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 10px 10px 0 10px;
    padding: 5px;
    background-color: $white-dark;
    border-radius: 8px;

    .week-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: $black;
        color: $white;
        width: 100%;
        padding: 5px;
        border-radius: 4px;
        box-sizing: border-box;

        span.week-number {
            font-size: 12px;
            font-weight: bold;
        }

        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            label {
                font-size: 11px;
                margin-right: 5px;
            }
            span {
                font-size: 14px;
                display: flex;

                .arrow {
                    width: 5px;
                    margin: 0 5px;
                    fill: $white;
                }

                &:last-of-type {
                    font-weight: bold;
                    margin-left: 5px;
                }
            }
        }
    }

    .days {
        width: 100%;
        display: flex;
        flex-direction: row;
    }
}
</style>
