/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pause': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="st0" d="M23 0h-7c-.6 0-1 .4-1 1v22c0 .6.4 1 1 1h7c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM8 0H1C.4 0 0 .4 0 1v22c0 .6.4 1 1 1h7c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1z"/>'
  }
})
