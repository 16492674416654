<template>
    <div class="wrapper">
        <div class="paymentplan-totals">
            <div class="paymentplan-totals-header">
                <el-row :gutter="5" type="flex" align="middle">
                    <el-col :span="19" class="description">
                        Line item totals
                    </el-col>

                    <el-col :span="2" class="center">
                        % allocated
                    </el-col>

                    <el-col :span="3" class="center">
                        Amount remaining
                    </el-col>
                </el-row>
            </div>
            <div class="paymentplan-totals-line-items">
                <payment-plan-invoice-line-item
                    v-for="line_item in totals"
                    :key="line_item.id"
                    :line_item="line_item"
                    :plan_total="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import PaymentPlanInvoiceLineItem from './PaymentPlan_invoice_line_item';
import ReferenceTemplateMixin from '@/mixins/referencetemplate.mixin';
import PaymentPlanMixin from '@/mixins/paymentplan.mixin';

import {PAYMENT_PLAN_DUE_TERMS} from '@/enums';

export default {
    name: 'paymentplan-totals',
    components: {
        PaymentPlanInvoiceLineItem,
    },
    mixins: [ReferenceTemplateMixin, PaymentPlanMixin],
    props: {
        payment_plan: {
            type: Object,
            required: true,
        },
        totals: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            PAYMENT_PLAN_DUE_TERMS: PAYMENT_PLAN_DUE_TERMS,
            draft: true,
            lineItemMap: {},
            is_scheduled: true,
            form: {
                reference: '',
                schedule_date: null,
                due_date: null,
                due_value: 14,
                due_terms: PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_DATE,
            },
            editingRef: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    overflow: auto;

    .paymentplan-totals {
        min-width: 810px;
        padding: 20px;
        margin-top: 10px;
        border-radius: 4px;
        border: 2px solid $border-grey-light;
        background-color: $grey;
    }

    .paymentplan-totals-header {
        font-size: 11px;
        padding: 0 0 5px;
    }

    .center {
        text-align: center;
    }
}
</style>
