<template>
    <el-form v-if="invoice" label-width="160px" @submit.native.prevent>
        <el-form-item label="Invoice number">
            <el-input
                v-clipboard:copy="
                    show_invoice_id ? invoice.id : invoice.invoice_number
                "
                :value="show_invoice_id ? invoice.id : invoice.invoice_number"
                readonly
                @shortkey.native="show_invoice_id = !show_invoice_id"
                @click.native="
                    showNotification(
                        show_invoice_id ? 'Invoice id' : 'Invoice number'
                    )
                "
            />
        </el-form-item>
        <el-form-item label="Reference">
            <el-input
                v-clipboard:copy="invoice.reference"
                :value="invoice.reference"
                readonly
                @click.native="showNotification('Reference')"
            />
        </el-form-item>
        <el-form-item label="Status">
            <el-input
                v-clipboard:copy="invoice.status"
                :value="invoice.status"
                readonly
                @click.native="showNotification('Status')"
            />
        </el-form-item>
        <hr />
        <el-form-item label="Subtotal">
            <el-input
                v-clipboard:copy="invoice.sub_total"
                :value="$options.filters.currency(invoice.sub_total)"
                readonly
                @click.native="showNotification('Subtotal')"
            />
        </el-form-item>
        <el-form-item label="GST">
            <el-input
                v-clipboard:copy="invoice.total_tax"
                :value="$options.filters.currency(invoice.total_tax)"
                readonly
                @click.native="showNotification('GST')"
            />
        </el-form-item>
        <hr />
        <el-form-item label="Total">
            <el-input
                v-clipboard:copy="invoice.total"
                :value="$options.filters.currency(invoice.total)"
                readonly
                @click.native="showNotification('Total')"
            />
        </el-form-item>
        <hr />
        <el-form-item label="Amount paid">
            <el-input
                v-clipboard:copy="invoice.amount_paid"
                :value="$options.filters.currency(invoice.amount_paid)"
                readonly
                @click.native="showNotification('Amount paid')"
            />
        </el-form-item>
        <el-form-item label="Amount due">
            <el-input
                v-clipboard:copy="invoice.amount_due"
                :value="$options.filters.currency(invoice.amount_due)"
                readonly
                @click.native="showNotification('Amount due')"
            />
        </el-form-item>
    </el-form>
</template>

<script>
import {Notification} from 'element-ui';

export default {
    name: 'invoice-form',
    props: {
        invoice: {
            type: Object,
            default: null,
        },
        show_invoice_id: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        showNotification(key) {
            Notification({
                type: 'success',
                title: 'Copied',
                message: `${key} copied to clipboard.`,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.el-form {
    margin-top: 20px;
    padding: 20px;

    hr {
        margin: 20px 50px;
        border: none;
        height: 1px;
        background-color: $border-grey-light;
    }

    ::v-deep .el-input {
        .el-input__inner {
            cursor: pointer;

            &:hover {
                border-color: $blue-light;
            }
        }
    }

    .el-input + .el-input {
        margin-top: 5px;
    }
}
</style>
