/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'invoice_sent': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M256 0C114.833 0 0 114.833 0 256s114.833 256 256 256 256-114.853 256-256S397.167 0 256 0zm0 472.341c-119.275 0-216.341-97.046-216.341-216.341S136.725 39.659 256 39.659c119.295 0 216.341 97.046 216.341 216.341S375.275 472.341 256 472.341z"/><path pid="1" d="M343.668 170.915a11.222 11.222 0 00-1.129-1.331c-.018-.02-.034-.042-.054-.06-.016-.017-.036-.032-.053-.05-2.275-2.25-5.438-3.474-9.1-3.474-2.079 0-4.31.393-6.63 1.167l-170.823 56.936c-9.037 3.013-10.684 8.273-10.86 11.14-.179 2.867.807 8.29 9.402 12.393l42.894 20.485-19.196 47.4c-.047.114-.09.23-.129.347-2.133 6.4-1.274 12.153 2.36 15.785 2.32 2.32 5.505 3.508 9.172 3.508 2.073 0 4.3-.38 6.61-1.15.119-.04.234-.081.349-.129l47.403-19.195 20.485 42.891c3.915 8.197 9.075 9.422 11.804 9.422 2.437 0 8.458-1.061 11.73-10.88l56.941-170.813c1.876-5.627 1.458-10.738-1.176-14.392h0zM158 236.112a13.71 13.71 0 011.849-.754L323 181l-92.045 92c-3.358-2.823-6.869-5.198-10.199-6.787l-61.016-29.127a13.853 13.853 0 01-1.74-.974zm34.632 86.38c-2.023.648-3.17.537-3.537.413-.124-.368-.235-1.514.414-3.537L208.286 273l7.46 3.564c7.1 3.39 16.3 12.59 19.69 19.69l3.564 7.46-46.368 18.777zm84.011 29.659a13.625 13.625 0 01-.754 1.849 13.482 13.482 0 01-.977-1.743l-29.125-61.014c-1.59-3.33-3.964-6.84-6.787-10.198L331 189l-54.357 163.151z" _stroke="#000" stroke-width="6"/></g>'
  }
})
