<template>
    <div>
        <highlighted-components-block />

        <stage-block
            v-for="stage in stages"
            :key="stage.id"
            :stage_ref="`stages/${stage.id}`"
        />

        <div v-only-admin class="stage new" @click="handleClickOnNewStage">
            <svgicon name="plus-square" class="icon" />
        </div>
    </div>
</template>

<script>
import StageBlock from '@/components/blocks/StageBlock';
import HighlightedComponentsBlock from '@/components/blocks/HighlightedComponentsBlock';

import componentTimelineMixin from '@/mixins/component.timeline.mixin';

export default {
    name: 'project-tab-components',
    components: {
        StageBlock,
        HighlightedComponentsBlock,
    },
    mixins: [componentTimelineMixin],
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        stages() {
            return this.$store.getters.stagesForProjectWithId(
                this.$route.params.project_id
            );
        },
    },
    methods: {
        handleClickOnNewStage() {
            this.$router.push({
                name: 'stage_new',
                params: {
                    project_id: this.project.id,
                    stage_number: this.project.stages
                        ? this.project.stages.length + 1
                        : 1,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.new {
    padding: 20px;
    border-radius: 5px;
    height: 60px;
    background-color: $grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid $grey;

    .icon {
        width: 25px;
        height: 25px;
        fill: rgba($black, 0.5);
    }

    span {
        color: rgba($black, 0.5);
        font-size: 14px;
        margin-top: 8px;
    }

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;

        .icon {
            fill: $blue;
        }

        span {
            color: $blue;
        }
    }
}
</style>
