import request from '@/utils/request';

// {{host}}/atlassian/redirect
export function redirectUserToAtlassian() {
    console.log('Redirecting to Atlassian account...');
    return request({
        url: '/atlassian/redirect',
        method: 'get',
    });
}

// {{host}}/atlassian/authenticate
export function handleCallbackFromAtlassian(code, state) {
    console.log('Authenticating Atlassian account...');
    return request({
        url: '/atlassian/authenticate',
        method: 'post',
        data: {
            code,
            state,
        },
    });
}

// {{host}}/atlassian/connect
export function connectAtlassian(user, token) {
    console.log('Connecting Atlassian account...');
    return request({
        url: '/atlassian/connect',
        method: 'post',
        data: {
            user,
            token,
        },
    });
}

// {{host}}/atlassian/disconnect
export function disconnectAtlassian() {
    console.log('Disconnecting Atlassian account...');
    return request({
        url: '/atlassian/disconnect',
        method: 'delete',
    });
}
