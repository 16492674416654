<template>
    <div v-if="is_template">
        <placeholder-block
            label="Estimate Groups"
            :block="block"
            :can_delete="false"
        />
    </div>
    <div v-else>
        <el-card class="content-block estimate-group" shadow="never">
            <div slot="header" class="heading">
                <div class="grip">⣿</div>
                <span class="label">Estimate group</span>

                <span class="title" :class="{excluded: groupExcluded}">
                    {{ group?.title }}
                </span>

                <div v-if="group" class="controls">
                    <span>Options:</span>
                    <el-select
                        :disabled="read_only"
                        v-model="values"
                        class="select-detail"
                        multiple
                        @change="
                            (val) =>
                                $emit('update', {
                                    ...block,
                                    values: val,
                                })
                        "
                    >
                        <el-option-group key="toggles" label="Toggles">
                            <el-option
                                v-if="
                                    hasExcludedTasks ||
                                    hasExcludedSubtasks ||
                                    groupExcluded
                                "
                                label="Show excluded"
                                :value="DOCUMENT_GROUP_TOGGLES.EXCLUDED"
                            />
                            <el-option
                                :disabled="
                                    !showSubtasks || (!showDays && !showHours)
                                "
                                label="Show subtask time"
                                :value="DOCUMENT_GROUP_TOGGLES.SUBTASK_TIME"
                            />
                        </el-option-group>
                        <el-option-group key="display" label="Display">
                            <el-option
                                label="Subtasks"
                                :value="DOCUMENT_GROUP_DETAIL.SUBTASKS"
                            />
                            <el-option
                                label="Days"
                                :value="DOCUMENT_GROUP_DETAIL.DAYS"
                            />
                            <el-option
                                label="Hours"
                                :value="DOCUMENT_GROUP_DETAIL.HOURS"
                            />
                        </el-option-group>
                    </el-select>
                </div>
            </div>

            <el-skeleton v-if="!group" :rows="3" animated />
            <el-alert
                v-else-if="groupExcluded && !showExcluded"
                class="excluded-notice"
                type="warning"
                :closable="false"
            >
                This group is excluded, and will only appear in the final
                document if 'show excluded' is enabled.
            </el-alert>
            <table v-else class="group-table">
                <tr>
                    <th class="col-task">Task</th>
                    <th v-if="showDays" class="col-value">Days</th>
                    <th v-if="showHours" class="col-value">Hours</th>
                </tr>

                <template v-for="task in filteredTasks">
                    <tr
                        :key="task.id"
                        :class="{excluded: getTaskExcluded(task)}"
                    >
                        <td
                            class="col-task"
                            :class="{
                                sub: showSubtasks,
                                header: showSubtaskTime,
                            }"
                        >
                            {{ task.description }}
                        </td>
                        <td
                            v-if="showDays"
                            class="col-value"
                            :class="{header: showSubtaskTime}"
                            :rowSpan="
                                !showSubtasks || showSubtaskTime
                                    ? 1
                                    : 1 + getFilteredSubtasks(task).length
                            "
                        >
                            {{ task_days(task) }}
                        </td>
                        <td
                            v-if="showHours"
                            class="col-value"
                            :class="{header: showSubtaskTime}"
                            :rowSpan="
                                !showSubtasks || showSubtaskTime
                                    ? 1
                                    : 1 + getFilteredSubtasks(task).length
                            "
                        >
                            {{ task_hours(task) }}
                        </td>
                    </tr>
                    <template v-if="showSubtasks">
                        <tr
                            v-for="subtask in getFilteredSubtasks(task)"
                            :key="`${task.id}_${subtask.id}`"
                            :class="{
                                excluded: task.excluded || subtask.excluded,
                            }"
                        >
                            <td class="col-subtask">
                                {{ subtask.description }}
                            </td>
                            <td
                                v-if="showDays && showSubtaskTime"
                                class="col-value"
                            >
                                {{ getSubtaskDays(subtask, task) }}
                            </td>
                            <td
                                v-if="showHours && showSubtaskTime"
                                class="col-value"
                            >
                                {{ getSubtaskHours(subtask, task) }}
                            </td>
                        </tr>
                        <tr v-if="showSubtasks" :key="`${task.id}_spacer`">
                            <td class="spacer" :colspan="columns"></td>
                        </tr>
                    </template>
                </template>

                <tr v-if="showDays || showHours">
                    <td class="total-time-label">Total time</td>
                    <td
                        v-if="showDays"
                        class="total-time"
                        :class="{excluded: groupExcluded}"
                    >
                        {{ totalDays.toFixed(2) }}
                    </td>
                    <td
                        v-if="showHours"
                        class="total-time"
                        :class="{excluded: groupExcluded}"
                    >
                        {{ totalHours.toFixed(2) }}
                    </td>
                </tr>
            </table>
        </el-card>
    </div>
</template>

<script>
import {DOCUMENT_GROUP_DETAIL, DOCUMENT_GROUP_TOGGLES} from '@/enums';
import EstimateMixin from '@/mixins/estimate.mixin';
import estimateTaskMixin from '@/mixins/estimate.task.mixin';
import PlaceholderBlock from '@/pages/documents/components/PlaceholderBlock';

export default {
    name: 'estimate-group-block',
    components: {PlaceholderBlock},
    mixins: [EstimateMixin, estimateTaskMixin],
    props: {
        block: {
            type: Object,
            required: true,
        },
        estimate: {
            type: Object,
            default: null,
        },
        is_template: {
            type: Boolean,
            required: true,
        },
        read_only: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            values: this.block?.values || [],
            DOCUMENT_GROUP_DETAIL: DOCUMENT_GROUP_DETAIL,
            DOCUMENT_GROUP_TOGGLES: DOCUMENT_GROUP_TOGGLES,
        };
    },
    computed: {
        group() {
            if (this.is_template) {
                return null;
            } else if (this.block.group && this.estimate) {
                return this.getNestedGroup(
                    this.estimate,
                    this.$fire.doc(this.block.group).id
                );
            } else {
                return null;
            }
        },
        groupExcluded() {
            if (this.group) {
                return this.isGroupExcluded(this.group, this.group.tasks);
            }
            return false;
        },
        hasExcludedTasks() {
            return this.group?.tasks.filter((task) => {
                return this.isTaskExcluded(task, task.subtasks);
            }).length;
        },
        hasExcludedSubtasks() {
            return this.group?.tasks.filter((task) => {
                return this.isAnySubtaskExcluded(task.subtasks);
            }).length;
        },
        filteredTasks() {
            if (this.group) {
                if (this.showExcluded) return this.group.tasks;

                return this.group.tasks.filter(
                    (task) => !this.isTaskExcluded(task, task.subtasks)
                );
            }
            return [];
        },
        totalDays() {
            return this.filteredTasks.reduce((total, task) => {
                if (
                    !this.groupExcluded &&
                    this.isTaskExcluded(task, task.subtasks)
                )
                    return total;
                return (total += this.task_days(task));
            }, 0);
        },
        totalHours() {
            return this.filteredTasks.reduce((total, task) => {
                if (
                    !this.groupExcluded &&
                    this.isTaskExcluded(task, task.subtasks)
                )
                    return total;
                return (total += this.task_hours(task));
            }, 0);
        },

        // TOGGLES
        showExcluded() {
            return this.values.includes(DOCUMENT_GROUP_TOGGLES.EXCLUDED);
        },
        showSubtaskTime() {
            return this.values.includes(DOCUMENT_GROUP_TOGGLES.SUBTASK_TIME);
        },
        // DETAIL
        showSubtasks() {
            return this.values.includes(DOCUMENT_GROUP_DETAIL.SUBTASKS);
        },
        showDays() {
            return this.values.includes(DOCUMENT_GROUP_DETAIL.DAYS);
        },
        showHours() {
            return this.values.includes(DOCUMENT_GROUP_DETAIL.HOURS);
        },

        columns() {
            let cols = 1;
            if (this.showDays) ++cols;
            if (this.showHours) ++cols;
            return cols;
        },
    },
    watch: {
        values(val) {
            // If subtasks is turned off or both time columns are turned off, also turn off subtask_time
            if (
                val.includes(DOCUMENT_GROUP_TOGGLES.SUBTASK_TIME) &&
                (!val.includes(DOCUMENT_GROUP_DETAIL.SUBTASKS) ||
                    (!val.includes(DOCUMENT_GROUP_DETAIL.DAYS) &&
                        !val.includes(DOCUMENT_GROUP_DETAIL.HOURS)))
            ) {
                const i = val.indexOf(DOCUMENT_GROUP_TOGGLES.SUBTASK_TIME);
                val.splice(i, 1);
            }
            this.$emit('update', {
                ...this.block,
                values: val,
            });
        },
    },
    methods: {
        getSubtaskHours(subtask, task) {
            let hours = this.task_qty(subtask);
            if (task.unit === 'day') hours = hours * task.hours_per_day;
            return Number(hours.toFixed(1));
        },
        getSubtaskDays(subtask, task) {
            let days = this.task_qty(subtask);
            if (task.unit !== 'day') days = days / task.hours_per_day;
            return Number(days.toFixed(1));
        },
        getFilteredSubtasks(task) {
            if (this.showExcluded) return task.subtasks;
            return task.subtasks.filter((s) => !s.excluded);
        },
        getTaskExcluded(task) {
            return this.isTaskExcluded(task, task.subtasks);
        },
    },
};
</script>

<style lang="scss" scoped>
.content-block {
    margin-bottom: 10px;
}
.heading {
    display: flex;
    align-items: center;

    .grip {
        width: 20px;
        height: 23px;
        text-align: left;
        opacity: 0.25;
        user-select: none;
        cursor: grab;
    }

    .label {
        font-weight: bold;
        font-size: 14px;
        color: $blue;
        margin-right: 20px;
    }

    .title {
        font-weight: bold;
        font-size: 18px;
        flex: 1;
        &.excluded {
            text-decoration: line-through;
            color: rgba($black, 0.4);
        }
    }

    .controls {
        flex: 0.5;
        display: flex;
        align-items: center;
        gap: 10px;

        .el-switch {
            margin: 0 10px;
        }

        .select-detail {
            width: 100%;
        }
    }
}

.group-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        border: 1px solid $black;
        padding: 5px 10px;
    }

    .excluded td {
        text-decoration: line-through;
        color: rgba($black, 0.4);
    }

    th {
        background-color: $border-grey;
    }

    .col-task {
        text-align: left;

        &.sub {
            font-weight: bold;
        }
        &.header {
            background-color: rgba($border-grey, 0.5);
        }
    }

    .col-value {
        vertical-align: top;
        text-align: right;
        width: 150px;
        &.header {
            font-weight: bold;
            background-color: rgba($border-grey, 0.5);
        }
    }

    .col-subtask {
        padding-left: 30px;
    }

    .spacer {
        padding: 2px;
        background-color: $border-grey;
    }

    .total-time-label,
    .total-time {
        background-color: $border-grey;
        font-weight: bold;
        text-align: right;
        &.excluded {
            color: rgba($black, 0.3);
            text-decoration: line-through;
        }
    }
}
.excluded-notice {
    font-weight: bold;
    justify-content: center;
}
</style>
