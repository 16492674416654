/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus-square': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g _fill="none" fill-rule="nonzero"><path pid="0" d="M6.234 0h19.532c2.167 0 2.954.225 3.746.648A4.412 4.412 0 0131.35 2.48c.424.79.65 1.574.65 3.735v19.473c0 2.16-.226 2.945-.65 3.735a4.412 4.412 0 01-1.838 1.832c-.792.423-1.579.648-3.746.648H6.234c-2.167 0-2.954-.225-3.746-.648A4.412 4.412 0 01.65 29.423c-.424-.79-.65-1.574-.65-3.735V6.215C0 4.055.226 3.27.65 2.48A4.412 4.412 0 012.488.648C3.28.225 4.067 0 6.234 0zm.057 1.773c-1.57 0-2.138.163-2.712.468a3.194 3.194 0 00-1.33 1.327c-.308.572-.47 1.14-.47 2.704v19.456c0 1.565.162 2.132.47 2.704.306.572.756 1.02 1.33 1.327.574.305 1.143.468 2.712.468h19.516c1.569 0 2.138-.163 2.711-.468a3.194 3.194 0 001.331-1.327c.307-.572.47-1.14.47-2.704V6.272c0-1.565-.163-2.132-.47-2.704a3.194 3.194 0 00-1.33-1.327c-.574-.305-1.143-.468-2.712-.468H6.29z" _fill="#000"/><path pid="1" d="M22.134 16.775H9.888a.887.887 0 110-1.775h12.246a.888.888 0 010 1.775z" _fill="#010002"/><path pid="2" d="M15.888 23.021a.888.888 0 01-.888-.887V9.888a.889.889 0 011.775 0v12.246a.886.886 0 01-.887.887z" _fill="#010002"/></g>'
  }
})
