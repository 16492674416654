<template>
    <component
        :is="component"
        v-if="component"
        :id="id"
        :link="link"
        :url="url"
        :resolved="resolved"
    />
    <a v-else href="javascript:void(0)" class="link" @click.stop="handleClick">
        <img class="favicon" src="/favicon.ico" />
        {{ title }}
    </a>
</template>

<script>
import ProjectLink from './ProjectLink';

export default {
    name: 'tool-link',
    props: {
        link: {
            type: String,
            required: true,
        },
        url: {
            type: Object,
            required: true,
        },
    },
    computed: {
        data() {
            const rgx = /^\/(\w*)\/([A-Za-z0-9\-]*)/;
            const matches = this.url.path.match(rgx);

            if (matches) {
                return {
                    type: matches[1],
                    id: matches[2],
                };
            }

            return {type: 'unknown', id: null};
        },
        type() {
            return this.data.type;
        },
        id() {
            return this.data.id;
        },
        resolved() {
            return this.$router.resolve(this.url.path).resolved;
        },
        title() {
            if (this.resolved.meta.title) {
                return this.resolved.meta.title;
            }

            if (this.resolved.meta.tab) {
                return `${
                    this.resolved.meta.key
                } → ${this.resolved.meta.tab.substr(1)}`;
            }

            return `${this.type} → ${this.id}`;
        },
        component() {
            switch (this.type) {
                case 'project':
                    return ProjectLink;
                default:
                    return null;
            }
        },
    },
    methods: {
        async handleClick() {
            try {
                await this.$router.push(this.resolved);
            } catch (e) {
                console.log(e);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.favicon {
    height: 10px;
}
</style>
