<template>
    <div class="status">
        <label>{{ environment }}</label>

        <div
            v-if="loading"
            v-loading="loading"
            class="spinner"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0)"
        />
        <el-tooltip
            v-if="!loading"
            :content="time"
            :open-delay="300"
            placement="right"
        >
            <el-tag
                size="medium"
                :disable-transitions="true"
                :type="status_type"
            >
                {{ build_name }}
            </el-tag>
        </el-tooltip>
    </div>
</template>

<script>
import bitbucket from '@/api/bitbucket';

import moment from 'moment';

import userMixin from '@/mixins/user.mixin';

export default {
    name: 'module-environment-status',
    mixins: [userMixin],
    props: {
        environment: {
            type: String,
            required: true,
        },
        branch: {
            type: String,
            required: true,
        },
        repository: {
            type: String,
            required: true,
        },
        workspace: {
            type: String,
            default: 'codefishstudio',
        },
    },
    data() {
        return {
            loading: true,
            status: null,
        };
    },
    computed: {
        build_status() {
            if (this.loading) return null;
            if (this.status === null) return 'UNAVAILABLE';
            return this.status.state;
        },
        build_name() {
            if (this.build_status === 'UNAVAILABLE') return 'No build';

            if (this.status === null || this.loading) return null;
            const rgx = /.*#([0-9]*)/;

            const matches = this.status.name.match(rgx);
            if (matches.length > 1) return `Build #${matches[1]}`;

            return this.status.name;
        },
        status_type() {
            if (this.loading) return 'info';
            switch (this.build_status) {
                case 'SUCCESSFUL':
                    return 'success';
                case 'STOPPED':
                    return 'warning';
                case 'INPROGRESS':
                    return 'default';
                case 'FAILED':
                    return 'danger';
                case 'UNAVAILABLE':
                    return null;
                default:
                    return null;
            }
        },
        time() {
            if (!this.status) return null;
            const time = moment(this.status.updated_on).fromNow();
            switch (this.build_status) {
                case 'INPROGRESS':
                    return `Build Started ${time}`;
                case 'SUCCESSFUL':
                    return `Built ${time}`;
                case 'STOPPED':
                    return `Build Stopped ${time}`;
                case 'FAILED':
                    return `Build Failed ${time}`;
                default:
                    return null;
            }
        },
    },
    created() {
        this.fetchStatus();
    },
    methods: {
        async fetchStatus() {
            if (!this.is_atlassian_authed) {
                this.loading = false;
                return;
            }

            try {
                const result = await bitbucket(this.workspace)
                    .repository(this.repository)
                    .branch(this.branch)
                    .statuses();

                if (result.values.length > 0) {
                    this.status = result.values[0];
                }
            } catch (err) {
                console.log(err);
            }

            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.status {
    margin-top: 5px;
    display: flex;
    flex-direction: row;

    .spinner {
        width: 28px;
        height: 28px;
        display: block;
    }

    label {
        font-size: 13px;
        flex: 1;
        height: 28px;
        line-height: 28px;
    }
}
</style>
