<template>
    <div
        v-long-press="300"
        class="checkbox"
        :class="{
            disable: !clickable,
            editing: show_priority,
        }"
        @long-press-start="onLongPressStart"
        @long-press-stop="onLongPressStop"
    >
        <input
            :id="id"
            type="checkbox"
            name="checkbox"
            value="checkbox"
            :checked="checked"
            :disabled="show_priority"
            @change="clicked"
        />
        <label :for="id" :class="{show_priority: show_priority}">
            <svg
                class="svgIcon detailsTitle-checkmarkSvg"
                viewBox="0 0 32 32"
                title="checkmark"
            >
                <polygon
                    points="27.672,4.786 10.901,21.557 4.328,14.984 1.5,17.812 10.901,27.214 30.5,7.615 "
                />
            </svg>
        </label>

        <div class="priority" :class="{show: show_priority}">
            <div class="cursor" :style="{bottom: cursor_position + 'px'}">
                <span>{{ priority }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import LongPress from 'vue-directive-long-press';

export default {
    name: 'todo-checkbox',
    directives: {
        'long-press': LongPress,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        clickable: {
            type: Boolean,
            required: false,
            default: true,
        },
        checked: {
            type: Boolean,
            required: true,
        },
        actual_priority: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            show_priority: false,
            start_position: 0,
            priority: 0,
        };
    },
    computed: {
        cursor_position() {
            return ((this.priority - 0) * (75 - 6)) / (100 - 0) + 6;
        },
    },
    destroyed() {
        window.removeEventListener('mousemove', this.mouseIsMoving);
    },
    methods: {
        clicked() {
            if (this.show_priority) return;
            this.$emit('on_change');
        },
        onLongPressStart() {
            if (this.checked) return;

            this.show_priority = true;
            this.priority = this.actual_priority;

            // Start tracking cursor position
            window.addEventListener('mousemove', this.mouseIsMoving);
        },
        onLongPressStop() {
            // Stop tracking cursor position
            window.removeEventListener('mousemove', this.mouseIsMoving);

            // Timeout needed to avoid click
            setTimeout(() => {
                this.show_priority = false;
                this.start_position = 0;
                this.$emit('priority_changed', this.priority);
            }, 200);
        },
        mouseIsMoving(e) {
            var y = e.pageY;

            if (this.start_position === 0) {
                this.start_position = y;
                return;
            }

            let value = this.start_position - y;
            if (value > 100) value = 100;
            if (value < 0) value = 0;
            this.priority = value;
        },
    },
};
</script>

<style lang="scss" scoped>
.checkbox {
    transform: scale(0.9);
    z-index: 1;

    &.editing {
        z-index: 100;
    }

    &.disable {
        pointer-events: none;
    }

    .priority {
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        border-radius: 14px;
        background: white;
        box-shadow: none;
        opacity: 0;
        padding: 6px;
        box-sizing: border-box;
        z-index: 99999;
        box-shadow: 0 20px 66px 0 rgba(34, 48, 73, 0.2), inset 0 0 0 1px #3be8b0,
            0 0 0 2px #3be8b0;

        transition: all 0.2s ease-in-out, box-shadow 0s ease-in-out;

        &.show {
            opacity: 1;
            height: 100px;
        }

        .cursor {
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            background-color: #dddddd;

            span {
                margin-left: -43px;
                background-color: $black;
                color: $white;
                width: 20px;
                text-align: center;
                display: block;
                padding: 2px 5px;
                border-radius: 5px;
                font-size: 11px;
                font-weight: 600;
            }
        }
    }

    input {
        display: none;

        &:checked + label {
            background: #3be8b0;

            &:hover {
                box-shadow: inset 0 0 0 0 #3be8b0, 0 0 0 0 #3be8b0;

                svg {
                    fill: #ffffff;
                }
            }
        }
    }

    label {
        cursor: pointer;
        background: #dddddd;
        display: flex;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        position: relative;
        transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

        align-items: center;
        justify-content: center;

        &:hover,
        &.show_priority {
            box-shadow: inset 0 0 0 1px #3be8b0, 0 0 0 2px #3be8b0;
            background: #ffffff;

            svg {
                fill: #3be8b0;
            }
        }

        svg {
            display: block;
            fill: #ffffff;
            transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

            width: 16px;
            height: 16px;
        }
    }
}
</style>
