/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings': {
    width: 35,
    height: 35,
    viewBox: '0 0 35 35',
    data: '<g _fill="#000" fill-rule="evenodd"><circle pid="0" cx="17.5" cy="9.5" r="1.5"/><circle pid="1" cx="17.5" cy="17.5" r="1.5"/><circle pid="2" cx="17.5" cy="25.5" r="1.5"/></g>'
  }
})
