<template>
    <div class="step">
        <span>
            <div class="icon">
                <div class="circle" :class="status">
                    <svgicon :name="icon" :color="color" />
                </div>
            </div>
            <div class="name" title="Build">
                {{ title }}
            </div>
        </span>
        <div
            v-if="substeps"
            class="content"
            :style="{height: `${substepsHeight}px`}"
        >
            <div
                v-for="substep of substeps"
                :key="substep.key"
                class="substep"
                :class="{clickable: substep.action}"
                @click="handleSubstepAction(substep)"
            >
                <svgicon
                    :name="
                        substep.completed
                            ? 'steps-success-icon'
                            : 'steps-todo-icon'
                    "
                    :color="substepColor(substep)"
                />
                <span>{{ substep.title }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'step-item',
    props: {
        title: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: null,
        },
        status: {
            type: String,
            required: true,
        },
        content_height: {
            type: Number,
            default: 0,
        },
        substeps: {
            type: Array,
            default: null,
        },
    },
    computed: {
        icon() {
            if (this.status === 'inprogress')
                return this.type === 'action'
                    ? 'steps-progress-icon'
                    : 'steps-done-icon';

            if (this.status === 'completed')
                return this.type === 'action'
                    ? 'steps-success-icon'
                    : 'steps-done-icon';

            return 'steps-todo-icon';
        },
        color() {
            if (this.status === 'archived') return '#dcdfe6';
            if (this.status === 'inprogress') return '#1989fa';
            if (this.status === 'completed') return '#36B27E';

            return '#b5b5b5';
        },
        substepsHeight() {
            return this.substeps.length * 25;
        },
    },
    methods: {
        substepColor(substep) {
            if (substep.completed) return '#36B27E';
            return '#b5b5b5';
        },
        handleSubstepAction(substep) {
            if (substep.action) {
                console.log('Go to', substep.action);
                this.$router.push(substep.action);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.step {
    //&:not(:first-of-type)::before,
    //&:not(:last-of-type)::after {
    //    content: '';
    //    display: block;
    //    height: 1px;
    //    width: 70%;
    //    position: absolute;
    //    right: 0px;
    //    margin-top: -1px;
    //    background: rgb(235, 236, 240);
    //}

    background: rgb(255, 255, 255);

    &:nth-child(even) {
        background: $white-dark;
    }

    & > span {
        display: flex;
        height: 50px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        box-sizing: border-box;
        transition: background 200ms ease-in-out 0s;
        padding: 0px 0px 0px 17px;
    }
    &.tall > span {
        height: 120px;
    }

    .substeps {
        height: 80px;
    }

    .icon {
        width: 16px;
        height: 16px;
        display: block;
        z-index: 10;
        border: 2px solid white;
        border-radius: 12px;
        background: $white;
        overflow: hidden;

        & > * {
            height: 16px;
        }

        .circle {
            transition: opacity 1s ease-in 0s;

            &.inprogress {
                animation: rotating 2s ease-in-out infinite;
            }

            svg {
                width: 16px;
                height: 16px;
                transform-origin: 50% center;
                pointer-events: none;
            }
        }

        @keyframes rotating {
            from {
                -ms-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            to {
                -ms-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        .dWddFU[data-state='icon-success'],
        .dWddFU[data-state='icon-paused'] {
            animation: 0.25s ease-in 0s 1 normal none running jZdfHz;
        }
    }

    .name {
        color: rgb(23, 43, 77);
        margin-left: 20px;
        font-weight: 300;
        margin-right: auto;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1;
    }
}

.substep {
    display: flex;
    align-items: center;
    margin-left: 40px;
    height: 20px;
    color: rgb(23, 43, 77);
    font-weight: 300;
    font-size: 13px;
    svg {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        transform-origin: 50% center;
        pointer-events: none;
    }
    &.clickable {
        cursor: pointer;
        span:hover {
            color: $blue;
            font-weight: bold;
        }
    }
}
</style>
