/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 24,
    height: 28,
    viewBox: '0 0 24 28',
    data: '<path pid="0" d="M22.475 11.327L5.51.57A3.728 3.728 0 003.517 0C1.575 0 0 1.452 0 3.244v21.512c0 .656.216 1.297.619 1.838 1.1 1.476 3.29 1.85 4.891.835l16.965-10.756c.355-.225.662-.508.906-.835 1.1-1.477.695-3.496-.906-4.511z" _fill="#000" fill-rule="nonzero"/>'
  }
})
