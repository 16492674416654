<template>
    <div>
        <div class="block">
            <el-button @click="deleteStage">
                Delete stage
            </el-button>
            <div>
                <span>Double check stage before running this!</span>
            </div>
        </div>
        <div class="block_options">
            <el-input
                v-model="deletion.stage"
                size="mini"
                placeholder="Stage"
            />
        </div>
        <div v-if="messages.delete.stage.length > 0" class="dialog">
            <template v-if="messages.delete.stage.length > 0">
                <div
                    v-for="(m, idx) of messages.delete.stage"
                    :key="idx"
                    class="error"
                >
                    <span>
                        {{ m }}
                    </span>
                </div>
            </template>
            <div>
                <el-button-group>
                    <template v-if="promises.deletion.stage.resolve">
                        <el-button
                            type="danger"
                            size="mini"
                            @click="promises.deletion.stage.resolve"
                        >
                            Delete Anyway
                        </el-button>
                    </template>
                    <template v-if="promises.deletion.stage.reject">
                        <el-button
                            size="mini"
                            @click="promises.deletion.stage.reject"
                        >
                            Cancel
                        </el-button>
                    </template>
                </el-button-group>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';

import {Notification} from 'element-ui';

export default {
    name: 'move-component',
    data() {
        return {
            messages: {
                delete: {
                    stage: [],
                },
            },
            deletion: {
                stage: null,
            },
            promises: {
                deletion: {
                    stage: {
                        resolve: null,
                        reject: null,
                    },
                },
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {
        async deleteStage() {
            Notification({
                type: 'info',
                title: 'Deleting',
                message: 'stage...',
            });

            this.messages.delete.stage = [];

            const stage__snap = await this.$fire
                .doc(`stages/${this.deletion.stage}`)
                .get();

            const stage = await stage__snap.data();

            console.log('stage id : ', stage__snap.id);
            console.log('project id : ', stage.project.id);

            // Find all sessions
            const components__snap = await this.$fire
                .collection('components')
                .where(
                    'stage',
                    '==',
                    this.$fire.doc(`stages/${this.deletion.stage}`)
                )
                .get();

            const components = [];
            const sessions = [];
            const invoices = [];

            components__snap.forEach((componentDoc) => {
                components.push(componentDoc);
            });

            if (components.length > 0) {
                this.messages.delete.stage.push(
                    `There is ${
                        components.length
                    } ${this.$options.filters.pluralize(
                        'component',
                        components.length
                    )} attached`
                );

                const sessions__snap = await this.$fire
                    .collection('sessions')
                    .where(
                        'stage',
                        '==',
                        this.$fire.doc(`stages/${this.deletion.stage}`)
                    )
                    .get();

                sessions__snap.forEach((sessionDoc) => {
                    sessions.push(sessionDoc.id);
                });

                if (sessions.length > 0) {
                    this.messages.delete.stage.push(
                        `There is ${
                            sessions.length
                        } ${this.$options.filters.pluralize(
                            'session',
                            sessions.length
                        )} attached`
                    );
                }
            }

            if (components.length > 0) {
                const invoices__snap = await this.$fire
                    .collection('line_items')
                    .where(
                        'component',
                        'in',
                        components.map((c) => c.ref)
                    )
                    .get();

                invoices__snap.forEach((invoiceDoc) => {
                    invoices.push(invoiceDoc);
                });

                if (invoices.length > 0) {
                    this.messages.delete.stage.push(
                        `There is ${
                            invoices.length
                        } ${this.$options.filters.pluralize(
                            'invoice',
                            invoices.length
                        )} attached`
                    );
                }
            }

            try {
                if (
                    components.length > 0 ||
                    invoices.length > 0 ||
                    sessions.length > 0
                ) {
                    const promise = await new Promise((resolve, reject) => {
                        this.promises.deletion.stage.resolve = resolve;
                        this.promises.deletion.stage.reject = reject;
                    });
                }
            } catch (err) {
                this.deletion.stage = null;
                return;
            } finally {
                this.messages.delete.stage = [];
                this.promises.deletion.stage.resolve = null;
                this.promises.deletion.stage.reject = null;
            }

            const batch = this.$fire.batch();

            const prj = this.$fire.doc(`projects/${stage.project.id}`);

            batch.update(prj, {
                components: firebase.firestore.FieldValue.arrayRemove(
                    ...components.map((c) => c.ref)
                ),
                stages: firebase.firestore.FieldValue.arrayRemove(
                    this.$fire.doc(`stages/${this.deletion.stage}`)
                ),
            });

            components.forEach((c) => {
                this.removeComponentFromCubes(c.id);
                batch.delete(c.ref);
            });

            batch.delete(this.$fire.doc(`stages/${this.deletion.stage}`));

            await batch.commit();

            this.deletion.stage = null;

            Notification({
                type: 'success',
                title: 'Done',
            });
        },

        async removeComponentFromCubes(component_id) {
            const cubes__snap = await this.$fire.collection('cubes').get();

            cubes__snap.forEach((snapshot) => {
                const data = snapshot.data();

                let has_update = false;
                const face2component = Object.entries(
                    data.face2component
                ).reduce((acc, [face_id, comp]) => {
                    if (comp && comp.id === component_id) {
                        acc[face_id] = null;
                        has_update = true;
                    } else {
                        acc[face_id] = comp;
                    }
                    return acc;
                }, {});

                if (has_update) {
                    snapshot.ref.update({face2component});
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.block_options {
    width: 410px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    & > * {
        margin-right: 5px;
        width: 200px;
    }
}
.dialog {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid $border-grey;
    background-color: white;
    border-radius: 6px;
    margin-bottom: 10px;

    div {
        margin: 0;
        font-size: 11px;

        &.error {
            color: $red;
            margin-bottom: 10px;
        }
    }
}
</style>
