<template>
    <li
        v-only-super-admin="note.is_private"
        class="item"
        :class="{locked: note.is_private}"
    >
        <div class="content">
            <div>
                <span>{{ note.title }}</span>
            </div>
            <div class="info">
                <div v-if="note.updated_at" class="row updated_at">
                    <span>
                        Last update
                        {{
                            note.updated_at.seconds | timestamp2moment | timeAgo
                        }}
                    </span>
                </div>
                <div class="row created_at">
                    <span>
                        Created
                        {{
                            note.created_at.seconds | timestamp2moment | timeAgo
                        }}
                        by
                    </span>
                    <avatar :user="author"></avatar>
                </div>
            </div>
        </div>

        <ul v-if="search && search.length > 1" class="search">
            <li v-for="result in results" :key="result.id" class="result">
                <p>
                    {{ result.before }} <span>{{ result.match }}</span>
                    {{ result.after }}
                </p>
            </li>
        </ul>
    </li>
</template>

<script>
import Avatar from '@/components/generic/Avatar';

export default {
    name: 'note-block',
    components: {
        Avatar,
    },
    props: {
        note: {
            type: Object,
            required: true,
        },
        search: {
            type: String,
            default: null,
        },
    },
    computed: {
        results() {
            if (this.search && this.search.length <= 1) return;

            var indexes = [];
            var results = [];
            var content = this.note.content.toLowerCase();
            var search = this.search.toLowerCase();
            var i = content.indexOf(search);
            var prev_i = 0;

            while (i != -1) {
                indexes.push(i + prev_i);
                prev_i = i + prev_i + search.length;
                content = content.slice(i * 1 + search.length);
                i = content.indexOf(search);
            }

            for (let index of indexes) {
                results.push({
                    id: Math.random().toString(36).substring(2, 15),
                    before: `...${this.note.content.substring(
                        index - 40,
                        index
                    )}`,
                    match: this.note.content.substring(
                        index,
                        index + search.length
                    ),
                    after: `${this.note.content.substring(
                        index + search.length,
                        index + 150
                    )}...`,
                });
            }

            return results;
        },
        author() {
            return this.$store.getters.userWithId(this.note.author);
        },
    },
};
</script>

<style lang="scss" scoped>
.item {
    cursor: pointer;
    margin: 0 0 10px 0;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: $grey;

    &.locked {
        box-shadow: 2px 0 0 0 $black;
    }

    &:hover {
        box-shadow: 0 0 0 2px $blue;
    }

    .content {
        min-height: 36px;

        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .info {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            min-width: 20%;
            height: 100%;

            .row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;

                &.updated_at {
                    margin-bottom: 5px;
                }

                span {
                    font-size: 10px;
                    color: rgba($black, 0.3);
                }

                ::v-deep .el-tooltip {
                    --size: 18px;
                    height: var(--size);

                    .avatar {
                        margin: 0 0 0 5px;
                        width: var(--size);
                        min-width: var(--size);
                        height: var(--size);
                    }
                }
            }
        }
    }

    .search {
        margin-top: 10px;
        padding-left: 10px;
        border-left: 1px solid $border-grey-light;
        list-style: none;

        p,
        span {
            display: inline-block;
        }

        p {
            color: rgba($black, 0.7);
            font-weight: 300;
        }

        span {
            background: rgba($blue, 0.2);
            padding: 5px 10px;
            margin: 0 3px;
            border-radius: 5px;
            color: $black;
        }
    }
}
</style>
