<template>
    <div class="user-day-tracker" :class="theme">
        <template v-if="show_tracking_block">
            <user-block :user="user" />
        </template>

        <div v-if="slots.length || fake_slots.length" class="day">
            <template v-if="slots.length">
                <hr style="margin: 0;" />

                <span class="title">Scheduled projects :</span>
                <day-project-block
                    v-for="slot in slots"
                    :key="slot.id"
                    ref="slotRef"
                    class="slot-row"
                    :user="user"
                    :object="slot"
                    :sessions="sessions"
                    :theme="theme"
                />
            </template>

            <template v-if="fake_slots.length">
                <template v-if="slots.length">
                    <hr />
                    <span class="title">Extra projects :</span>
                </template>

                <day-project-block
                    v-for="slot in fake_slots"
                    :key="slot.id"
                    ref="fakeSlotRef"
                    class="slot-row"
                    :user="user"
                    :object="slot"
                    :sessions="sessions"
                    :theme="theme"
                />
            </template>

            <template v-if="user == current_user">
                <hr style="margin-bottom: 20px;" />

                <div class="day-summary">
                    <el-button
                        class="clipboard_button"
                        size="small"
                        icon="el-icon-document-copy"
                        @click="copyToClipboard"
                    >
                        Copy summary to clipboard
                    </el-button>
                    <span class="time_total">
                        {{ time_total | seconds2duration }}
                    </span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import userMixin from '@/mixins/user.mixin';

import UserBlock from '@/components/blocks/UserBlock';
import DayProjectBlock from '@/components/blocks/DayProjectBlock';

import {Notification} from 'element-ui';
import {seconds2duration} from '@/filters';
import {momentWithTz} from '@/utils';

export default {
    name: 'user-day-tracker',
    components: {
        UserBlock,
        DayProjectBlock,
    },
    mixins: [userMixin],
    props: {
        user: {
            type: Object,
            required: true,
        },
        show_tracking_block: {
            type: Boolean,
            default: true,
        },
        theme: {
            type: String,
            default: 'light',
        },
    },
    data() {
        return {
            slots: [],
            sessions: [],
        };
    },
    computed: {
        fake_slots() {
            const expected_components = this.slots.map(
                (slot) => slot.component
            );
            const extra_components = this.sessions
                .filter(
                    (session) =>
                        !expected_components.includes(session.component)
                )
                .map((session) => session.component);
            return [...new Set(extra_components)].map((obj) => {
                return {
                    component: obj,
                };
            });
        },
        today() {
            return this.$store.getters.current_date;
        },

        time_total() {
            return this.sessions.reduce((total, elem) => {
                if (elem.stop) {
                    return total + elem.stop.seconds - elem.start.seconds;
                } else {
                    return (
                        total +
                        this.$store.state.current_time / 1000 -
                        elem.start.seconds
                    );
                }
            }, 0);
        },
        summary_text() {
            const slots = [...this.slots, ...this.fake_slots];
            const slotRefs = [
                ...(this.$refs.slotRef || []),
                ...(this.$refs.fakeSlotRef || []),
            ];
            for (let i = 0; i < slots.length; i++) {
                slots[i].ref = slotRefs[i];
                slots[
                    i
                ].project = this.$store.getters.projectForComponentWithId(
                    slots[i].component
                );
            }
            const mapProjectsToRefs = slots.reduce((acc, slot) => {
                const match = acc.find((x) => x.project === slot.project);
                if (!match) {
                    acc.push({project: slot.project, refs: [slot.ref]});
                } else {
                    match.refs.push(slot.ref);
                }
                return acc;
            }, []);

            let rawText = '';
            let htmlText = '';
            for (const mapping of mapProjectsToRefs.toReversed()) {
                htmlText += `<p>${mapping.project.ref}</p><ul>`;
                rawText += `${mapping.project.ref}\n`;
                mapping.refs.toReversed().forEach((slot) => {
                    htmlText += `<li>${slot.getDayProjectSummaryHtml()}</li>`;
                    rawText += slot.getDayProjectSummaryRaw();
                });
                rawText += '\n';
                htmlText += '</ul>';
            }
            return {rawText, htmlText};
        },
    },
    watch: {
        today() {
            this.bindData();
        },
    },
    mounted() {
        this.bindData();
    },
    methods: {
        seconds2duration,
        bindData() {
            const today_start = momentWithTz().startOf('day').toDate();
            const today_end = momentWithTz().endOf('day').toDate();
            this.$bind(
                'slots',
                this.$fire
                    .collection('slots')
                    .where('day', '>=', today_start)
                    .where('day', '<=', today_end)
                    .where(
                        'user',
                        '==',
                        this.$fire.doc(`users/${this.user.id}`)
                    ),
                {maxRefDepth: 0}
            );

            this.$bind(
                'sessions',
                this.$fire
                    .collection('sessions')
                    .where('start', '>=', today_start)
                    .where('start', '<=', today_end)
                    .where(
                        'user',
                        '==',
                        this.$fire.doc(`users/${this.user.id}`)
                    )
                    .orderBy('start', 'desc'),
                {maxRefDepth: 0}
            );
        },
        async copyToClipboard() {
            try {
                if (navigator.clipboard.write) {
                    await navigator.clipboard.write([
                        new window.ClipboardItem({
                            ['text/html']: new Blob(
                                [this.summary_text.htmlText],
                                {type: 'text/html'}
                            ),
                            ['text/plain']: new Blob(
                                [this.summary_text.rawText],
                                {type: 'text/plain'}
                            ),
                        }),
                    ]);
                } else {
                    // some browsers either don't have full support or require some configuration to enable non-raw-text copy
                    // to avoid this on firefox go to about:config and toggle dom.events.asyncClipboard.clipboardItem to true
                    await navigator.clipboard.writeText(
                        this.summary_text.rawText
                    );
                }
                Notification({
                    type: 'success',
                    title: 'Copied',
                    message: 'Summary copied to clipboard.',
                });
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-day-tracker {
    background-color: $grey;
    border-radius: 5px;
    margin-bottom: 10px;

    hr {
        opacity: 0.1;
    }

    .day {
        padding: 10px 15px 10px 15px;

        .title {
            opacity: 0.5;
            display: block;
            font-size: 12px;
            margin-top: 5px;
            margin-bottom: 10px;
        }

        .day-summary {
            display: flex;
            flex-direction: row;
            padding: 0 0 10px 0;

            .time_total {
                justify-content: flex-end;
                flex: 1;
                text-align: right;
                vertical-align: center;
                padding-right: 20px;
            }

            .clipboard_button {
                color: rgba($white, 0.5);
                background-color: transparent;
                border-color: transparent;
                margin-left: 10px;
                padding: 0;
                font-size: 13px;

                &:hover {
                    color: $white;
                }
            }
        }
    }

    ::v-deep .user-block {
        margin: 0;
    }

    &.dark {
        background-color: $black;

        .day {
            color: $white;
        }

        ::v-deep .user-block {
            background-color: $black;

            .head span {
                color: $white;
            }
        }

        ::v-deep .tracking-block {
            .time,
            .note,
            .note .el-input input,
            .info span {
                color: $white;
            }
        }

        ::v-deep .editor {
            color: $white;

            .link {
                color: white;
            }
        }
    }
}
</style>
