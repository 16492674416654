<template>
    <a
        class="link"
        :href="link"
        target="_blank"
        :style="computed_style"
        @click.stop="() => null"
    >
        <div class="content">
            <template v-if="stored_issue">
                <img
                    :alt="stored_issue.type.label"
                    :src="stored_issue.type.icon"
                    class="type-icon"
                />
                <span class="ticket_id">{{ ticket_id }}</span>
                <span v-if="show_summary" class="summary">
                    {{ stored_issue.summary | truncate(50) }}
                </span>
                <el-tag :type="status_color" size="mini" class="status">
                    {{ stored_issue.status.label.toUpperCase() }}
                </el-tag>
            </template>
            <span v-else class="ticket_id">
                {{ ticket_id }}
            </span>
        </div>
    </a>
</template>

<script>
export default {
    name: 'jira-link',
    props: {
        link: {
            type: String,
            required: true,
        },
        show_summary: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        status_color() {
            if (!this.stored_issue) return;
            switch (this.stored_issue.status.color) {
                case 'green':
                    return 'success';
                case 'blue':
                case 'yellow':
                    return 'primary';
                case 'blue-gray':
                default:
                    return 'info';
            }
        },
        ticket_id() {
            const rgx = /(.*)\/(browse|issues)\/([A-Za-z0-9]{3,}-[0-9]{1,})(\?.*|$)/;
            const matches = this.link.match(rgx);
            return matches[3];
        },
        ticket_org() {
            const rgx = /http[s]{0,1}:\/\/([A-Za-z0-9-]*)\.(.*)/;
            const matches = this.link.match(rgx);
            return matches[1];
        },
        computed_style() {
            if (!this.stored_issue) return null;
            return {
                '--color': this.stored_issue.status.color,
            };
        },
        stored_issue() {
            return this.$store.getters.issueWithKey(this.ticket_id);
        },
    },
};
</script>

<style lang="scss" scoped>
.link {
    display: inline-block;

    .content {
        display: flex;
        align-items: center;
        justify-content: center;

        img.type-icon {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 5px;
        }

        span.ticket_id {
            font-weight: 600;
        }

        span.summary {
            border-left: 1px solid $border-grey;
            margin-left: 8px;
            padding-left: 8px;
        }

        .status {
            font-size: 8px;
            text-transform: uppercase;
            margin-left: 5px;
            align-items: center;
            height: 15px;
            display: flex;
            padding: 5px;
        }
    }
}
</style>
