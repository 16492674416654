/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jira-dev': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g transform="translate(1 1)" _fill="none" fill-rule="evenodd"><rect pid="0" _fill="#4BAEE8" width="14" height="14" rx="2"/><g _stroke="#FFF" stroke-width="1.2" stroke-linecap="round"><path pid="1" d="M5.5 4.6L3 7M8.5 4.6L11 7M3 7l2.5 2.4M11 7L8.5 9.4"/></g></g>'
  }
})
