<template>
    <div class="outer">
        <header>
            <div>
                <el-input
                    v-model="search"
                    placeholder="Search invoice"
                    size="mini"
                />

                <el-radio-group
                    v-model="group_by"
                    :disabled="!!search.length"
                    size="mini"
                >
                    <el-radio-button label="linking">
                        Unlinked
                    </el-radio-button>
                    <el-radio-button label="due">
                        Due
                    </el-radio-button>
                    <el-radio-button label="all">
                        All
                    </el-radio-button>
                </el-radio-group>
            </div>

            <div class="row">
                <div class="ex-GST">
                    <span>(Prices ex. GST)</span>
                    <span v-if="sysInvoices">
                        Last updated
                        {{
                            sysInvoices.last_updated?.seconds
                                | timestamp2moment
                                | timeAgo
                        }}
                    </span>
                </div>

                <el-button
                    size="mini"
                    :disabled="loading"
                    @click="fetchInvoices"
                >
                    Refresh
                </el-button>
            </div>
        </header>

        <loader v-if="loading" />
        <div v-else class="inner">
            <placeholder v-if="!paginated_invoices.length && !loading">
                <span slot="title">
                    {{ placeholder_content.title }}
                </span>
                <span slot="subtitle">
                    {{ placeholder_content.subtitle }}
                </span>
            </placeholder>

            <ul v-else class="invoices-list">
                <invoice-block
                    v-for="invoice in paginated_invoices"
                    :key="invoice.id"
                    :invoice="invoice"
                />
            </ul>

            <el-pagination
                layout="prev, pager, next"
                :current-page="current_page"
                :page-count="page_count"
                :page-size="page_size"
                :hide-on-single-page="true"
                @current-change="(val) => (current_page = val)"
            />
        </div>
    </div>
</template>

<script>
import Placeholder from '@/components/generic/Placeholder';
import InvoiceBlock from '@/components/blocks/InvoiceBlock';
import Loader from '@/components/generic/Loader';

import invoiceMixin from '@/mixins/invoice.mixin';

export default {
    name: 'management-invoices',
    components: {
        Placeholder,
        InvoiceBlock,
        Loader,
    },
    mixins: [invoiceMixin],
    data() {
        return {
            search: '',
            loading: false,
            page_size: 30,
            current_page: 1,
            group_by: 'linking',
            sysInvoices: null,
        };
    },
    computed: {
        invoices() {
            return this.$store.getters.invoices;
        },
        clients_ids() {
            return this.$store.getters.clients.map((c) => c.id);
        },
        filtered_invoices() {
            if (this.search.length) {
                return this.invoices.filter((i) => {
                    const search = this.search.toLowerCase();
                    return (
                        (i.invoice_number &&
                            i.invoice_number.toLowerCase().includes(search)) ||
                        (i.reference &&
                            i.reference.toLowerCase().includes(search))
                    );
                });
            } else {
                return this.invoices.filter((i) => {
                    switch (this.group_by) {
                        case 'all':
                            return true;
                        case 'due':
                            return !i.fully_paid_on_date;
                        case 'linking':
                            return !i.is_linked;
                        default:
                            return false;
                    }
                });
            }
        },
        paginated_invoices() {
            const from = (this.current_page - 1) * this.page_size;
            return this.filtered_invoices.slice(from, from + this.page_size);
        },
        page_count() {
            return Math.ceil(this.filtered_invoices.length / this.page_size);
        },
        placeholder_content() {
            switch (this.group_by) {
                case 'linking':
                    return {
                        title: 'Every invoice has been linked.',
                        subtitle: 'Good job!',
                    };
                case 'due':
                    return {
                        title: 'Every invoice has been paid!',
                        subtitle: 'We should celebrate!',
                    };
            }

            return '';
        },
    },
    watch: {
        search() {
            this.current_page = 1;
        },
        current_page(newVal, oldVal) {
            // If page didn't change or the query param is already set - avoid
            if (
                newVal === oldVal ||
                parseInt(this.$route.query.page ?? '1') === newVal
            )
                return;

            this.$router.replace({
                query: {filter: this.group_by, page: newVal},
            });
        },
        group_by(newVal, oldVal) {
            // If filter didn't change or the query param is already set - avoid
            if (newVal === oldVal || this.$route.query.filter === newVal)
                return;

            this.current_page = 1;

            this.$router.replace({
                query: {filter: newVal, page: this.current_page},
            });
        },
    },
    async mounted() {
        this.$bind('sysInvoices', this.$fire.doc('system/invoices'), {
            maxRefDepth: 0,
        });

        this.group_by = this.$route.query.filter ?? 'linking';
        this.current_page = parseInt(this.$route.query.page ?? '1');
    },
    methods: {
        async fetchInvoices() {
            this.loading = true;
            try {
                await this.updateInvoices(this.clients_ids);
            } catch (e) {
                console.log('Exception updating invoices', e);
            }
            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
        box-sizing: border-box;
        min-height: 65px;
        padding: 10px 20px;
        border-bottom: 1px solid $border-grey-light;
        display: flex;
        user-select: none;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .row {
            display: flex;
            flex-direction: row;
        }

        .ex-GST {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            margin-right: 10px;

            span {
                opacity: 0.8;
                font-size: 10px;

                &:last-of-type {
                    font-size: 8px;
                }
            }
        }

        .el-input {
            width: 200px;
        }

        .el-radio-group {
            margin-left: 10px;
        }
    }

    .inner {
        overflow: scroll;
        padding: 0 20px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .invoices-list {
            padding: 0;
            list-style: none;
            width: 100%;
        }

        .el-pagination {
            margin-bottom: 15px;
        }
    }
}
</style>
