<template>
    <div class="event" :class="{ignored: ignored}">
        <div class="content">
            <div class="title">
                {{ event.content }}
            </div>
            <div class="action">
                <project-dropdown v-model="selectedProjectId" />
            </div>
        </div>
        <div class="info">
            <div class="date">
                {{ event.start.toDate() | dateformat('DD/MM/YY') }}
            </div>
            <div class="time">
                {{ event.start.toDate() | dateformat('HH:mm') }} -
                {{ event.end.toDate() | dateformat('HH:mm') }}
            </div>
            <el-dropdown
                trigger="click"
                placement="bottom-end"
                popper-class="popper-over-modal"
                @command="runCommand"
            >
                <div class="down-icon">
                    <svg class="icon" focusable="false" viewBox="0 0 24 24">
                        <path
                            d="M3.5,8.9c0-0.4,0.1-0.7,0.4-1C4.5,7.3,5.4,7.2,6,7.8l5.8,5.2l6.1-5.2C18.5,7.3,19.5,7.3,20,8c0.6,0.6,0.5,1.5-0.1,2.1 l-7.1,6.1c-0.6,0.5-1.4,0.5-2,0L4,10.1C3.6,9.8,3.5,9.4,3.5,8.9z"
                        />
                    </svg>
                </div>

                <el-dropdown-menu slot="dropdown">
                    <template v-if="!ignored">
                        <el-dropdown-item command="ignore">
                            Ignore event
                        </el-dropdown-item>
                    </template>
                    <template v-else>
                        <el-dropdown-item command="unignore">
                            Unignore event
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-if="event.recurringEventId && ignored_recurring"
                            command="unignoreRecurring"
                        >
                            Unignore recurring
                        </el-dropdown-item>
                    </template>
                    <el-dropdown-item
                        v-if="event.recurringEventId && !ignored_recurring"
                        command="ignoreRecurring"
                    >
                        Ignore recurring
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import ProjectDropdown from '@/components/generic/ProjectDropdown.vue';

export default {
    components: {ProjectDropdown},
    props: {
        event: {
            type: Object,
            required: true,
        },
        projects: {
            type: Array,
            required: true,
        },
        ignored_events: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selectedProjectId: null,
        };
    },
    computed: {
        ignored() {
            if (
                this.ignored_events.includes(this.event.id) ||
                this.ignored_events.includes(this.event.recurringEventId)
            )
                return true;
            return false;
        },
        ignored_recurring() {
            return this.ignored_events.includes(this.event.recurringEventId);
        },
    },
    watch: {
        selectedProjectId: function (val) {
            this.selectProject();
        },
    },
    methods: {
        selectProject() {
            this.$store.dispatch('assignProjectToCalendarEvent', {
                id: this.event.id,
                project_id: this.selectedProjectId,
                recurring: this.event.recurringEventId,
            });
        },
        runCommand(value) {
            if (value === 'ignore') {
                this.$store.dispatch('ignoreCalendarEvent', this.event.id);
            } else if (value === 'ignoreRecurring') {
                this.$store.dispatch(
                    'ignoreCalendarEvent',
                    this.event.recurringEventId
                );
            } else if (value === 'unignore') {
                this.$store.dispatch('unignoreCalendarEvent', this.event.id);
            } else if (value === 'unignoreRecurring') {
                this.$store.dispatch(
                    'unignoreCalendarEvent',
                    this.event.recurringEventId
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.event {
    display: flex;
    height: 80px;
    justify-content: space-between;
    border: 1px solid $border-grey-light;
    border-radius: 5px;
    margin: 4px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;

    &.ignored {
        opacity: 0.6;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-size: 12px;
            margin-bottom: 10px;
            max-height: 16px;
            overflow: hidden;
        }
    }

    .info {
        width: 120px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;

        .time,
        .date {
            color: rgba($black, 0.6);
            font-size: 11px;
            text-align: right;
        }

        .down-icon {
            cursor: pointer;
            align-items: center;
            fill: rgba($black, 0.4);
            margin-bottom: -5px;

            .icon {
                flex: 0 0 auto;
                height: 12px;
                width: 12px;
            }
        }
    }
}
</style>
