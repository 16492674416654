/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jira-bug': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g transform="translate(1 1)" _fill="none" fill-rule="evenodd"><rect pid="0" _fill="#E5493A" width="14" height="14" rx="2"/><path pid="1" d="M10 7a3 3 0 11-6 0 3 3 0 016 0" _fill="#FFF"/></g>'
  }
})
