import dayjs from 'dayjs';

const referenceTemplateMixin = {
    computed: {
        projectRef() {
            let project = this.$store.getters.projectWithId(
                this.estimate.project
            );
            if (project) return project.ref;
            return '';
        },
        estimateId() {
            if (this.estimate && this.estimate.id) return this.estimate.id;
            return '';
        },
    },
    methods: {
        processTemplate(
            template,
            date,
            index,
            count,
            projectRef = this.projectRef
        ) {
            // find each tag in the template and replace it with the appropriate value

            // check if date is in Firebase format

            const result = template.replaceAll(
                /\{(.*?)(\|([+-][0-9]+)\w*?(.*?)}|})/g,
                (match, tag, unusedRegex, offset, offsetUnit) => {
                    tag = tag.toLowerCase();

                    // Project ref
                    if (tag === 'prj') {
                        return projectRef;
                    }
                    // Estimate id
                    if (tag === 'est') {
                        return this.estimateId;
                    }

                    // Sequence
                    if (tag === 'seq') {
                        return `${index}/${count}`;
                    }

                    // Date tags (handle firebase date or regular date)
                    let tagDate = dayjs(date.seconds ? date.toDate() : date);

                    if (offset) {
                        // attempt to offset date
                        const offsetNum = Number(offset);
                        if (!isNaN(offsetNum)) {
                            // use provided unit if found
                            let unit = offsetUnit?.trim();
                            // if none, use the tag as the unit
                            if (!unit) unit = tag;
                            unit = unit.toLowerCase();
                            if (unit === 'm' || unit === 'mm' || unit === 'mon')
                                unit = 'month';
                            // whatever unit we end up with, throw it as dayjs; if invalid, no adjustment is made
                            tagDate = tagDate.add(offsetNum, unit);
                        }
                    }
                    if (tag === 'week') {
                        return tagDate.isoWeek();
                    }
                    if (tag === 'mm') {
                        return tagDate.format('MM');
                    }
                    if (tag === 'mon') {
                        return tagDate.format('MMM');
                    }
                    if (tag === 'month') {
                        return tagDate.format('MMMM');
                    }
                    if (tag === 'year') {
                        return tagDate.format('YYYY');
                    }

                    // no match, return as-is
                    return match;
                }
            );

            // let result = template.replace('{prj}', this.projectRef);
            //
            // result = result.replace('{week}', dayjs(date).isoWeek());
            //
            // result = result.replace('{mm}', dayjs(date).format('MM'));
            // result = result.replace('{mon}', dayjs(date).format('MMM'));
            // result = result.replace('{month}', dayjs(date).format('MMMM'));
            //
            // result = result.replace('{year}', dayjs(date).format('YYYY'));

            return result;
        },
    },
};

export default referenceTemplateMixin;
