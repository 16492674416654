<template>
    <div class="outer">
        <header>
            <el-radio-group v-model="documentType" size="mini">
                <el-radio-button
                    v-for="type in DOCUMENT_TYPES"
                    :key="type"
                    :label="type"
                >
                    {{ type | capitalize }}
                </el-radio-button>
            </el-radio-group>

            <el-button size="mini" @click="createTemplate">
                New
            </el-button>
        </header>

        <div class="inner">
            <loader v-if="loading" />
            <ul v-else>
                <li
                    v-for="template in sorted_templates"
                    :key="template.id"
                    @click="() => editTemplate(template.id)"
                >
                    <span>{{ template.name }}</span>
                    <svgicon name="triangle" class="triangle" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {DOCUMENT_TYPES} from '@/enums';

import documentMixin from '@/mixins/document.mixin';

import Loader from '@/components/generic/Loader';

export default {
    name: 'documents-templates',
    components: {Loader},
    mixins: [documentMixin],
    data() {
        return {
            documentType: DOCUMENT_TYPES.ESTIMATE,
            templates: [],
            loading: true,
        };
    },
    computed: {
        DOCUMENT_TYPES() {
            return DOCUMENT_TYPES;
        },
        sorted_templates() {
            return this.sortTemplates(this.templates);
        },
    },
    watch: {
        documentType() {
            this.loadTemplates();
        },
    },
    mounted() {
        this.loadTemplates();
    },
    methods: {
        loadTemplates() {
            this.loading = true;

            this.$bind(
                'templates',
                this.$fire.collection(
                    `system/${this.documentTypeCollection}/document_templates`
                ),
                {maxRefDepth: 0}
            ).then(() => {
                this.loading = false;
            });
        },
        createTemplate() {
            this.$router
                .push({
                    name: 'document_template_new',
                    params: {
                        doc_type: this.documentType,
                    },
                })
                .catch((err) => {});
        },
        editTemplate(id) {
            this.$router
                .push({
                    name: 'document_template_edit',
                    params: {
                        doc_type: this.documentType,
                        template_id: id,
                    },
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
        box-sizing: border-box;
        height: 65px;
        padding: 10px 20px;
        border-bottom: 1px solid $border-grey-light;
        display: flex;
        user-select: none;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .el-input {
            width: 200px;
        }
    }

    .inner {
        overflow: scroll;

        .loader {
            padding: 20px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;

                &:hover {
                    svg {
                        display: block;
                    }
                }

                span {
                    flex: 1;
                    user-select: none;
                }

                svg {
                    width: 6px;
                    display: none;
                    opacity: 0.1;
                }
            }
        }
    }
}
</style>
