/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'invoice_share': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M256 0C114.833 0 0 114.833 0 256s114.833 256 256 256 256-114.853 256-256S397.167 0 256 0zm0 472.341c-119.275 0-216.341-97.046-216.341-216.341S136.725 39.659 256 39.659c119.295 0 216.341 97.046 216.341 216.341S375.275 472.341 256 472.341z"/><path pid="1" d="M166.155 334a6.968 6.968 0 01-1.797-.227 8.267 8.267 0 01-6.358-8.07v-16.89c0-60.527 47.628-109.774 106.167-109.774h2.722v-36.593c0-3.445 2.025-6.541 5.118-7.836 3.08-1.282 6.62-.507 8.928 1.981l70.779 75.998c3.048 3.266 3.048 8.444 0 11.71l-70.779 75.998c-2.309 2.488-5.87 3.255-8.928 1.98-3.093-1.294-5.118-4.39-5.118-7.835V277.85h-12.936c-34.256 0-65.04 19.68-80.35 51.352-1.405 2.927-4.356 4.797-7.448 4.797h0zm98.285-118.41c-45.884 0-83.879 35.574-89.44 81.41 19.86-22.866 48.34-36.308 79.192-36.308h21.172c4.522 0 8.193 3.79 8.193 8.457v24.13L335 238.14 283.557 183v24.132c0 4.668-3.67 8.457-8.193 8.457H264.44z" _stroke="#000" stroke-width="4"/></g>'
  }
})
