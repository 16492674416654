var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-container" },
    [
      _c(
        "el-select",
        {
          attrs: { placeholder: "Select", size: "mini" },
          model: {
            value: _vm.env,
            callback: function ($$v) {
              _vm.env = $$v
            },
            expression: "env",
          },
        },
        _vm._l(_vm.options, function (item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value },
          })
        }),
        1
      ),
      _c("el-input", {
        attrs: { size: "mini", placeholder: "Insert title", maxlength: 24 },
        model: {
          value: _vm.title,
          callback: function ($$v) {
            _vm.title = $$v
          },
          expression: "title",
        },
      }),
      _c("el-input", {
        attrs: { size: "mini", placeholder: "Insert credentials" },
        nativeOn: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.addItem.apply(null, arguments)
          },
        },
        model: {
          value: _vm.credentials,
          callback: function ($$v) {
            _vm.credentials = $$v
          },
          expression: "credentials",
        },
      }),
      _c(
        "el-button",
        {
          attrs: { disabled: !_vm.is_valid, size: "mini" },
          on: { click: _vm.addItem },
        },
        [_vm._v(" add ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }