<template>
    <el-tooltip :content="project_status.content" placement="top">
        <div class="project-status-cell">
            <div class="marker" :style="{backgroundColor}"></div>
        </div>
    </el-tooltip>
</template>

<script>
export default {
    name: 'milestone-cell',
    props: {
        project_status: {
            type: Object,
            required: true,
        },
        cur_node: {
            type: Object,
            required: true,
        },
    },
    computed: {
        backgroundColor() {
            return this.currentStatusType.color;
        },
        currentStatusType() {
            if (this.cur_node.currentStatus) {
                return (
                    this.cur_node.currentStatus.statusType.statuses.find(
                        (s) => s.value === this.cur_node.currentStatus.status
                    ) ?? null
                );
            }
            return null;
        },
    },
};
</script>

<style scoped lang="scss">
.project-status-cell {
    height: var(--row-height);
    width: var(--day-width);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.1s;
    transition-property: background-color;
    cursor: pointer;

    .marker {
        position: absolute;
        width: 7px;
        height: 7px;

        border-radius: 50%;
    }
}
</style>
