<template>
    <div
        class="calendar-view"
        :class="{'padding-tracking-bar': active_session}"
    >
        <planner-yearly-view />
    </div>
</template>

<script>
import userMixin from '@/mixins/user.mixin';
import PlannerYearlyView from '@/components/planner/PlannerYearlyView';

export default {
    name: 'planner-tab-calendar',
    components: {
        PlannerYearlyView,
    },
    mixins: [userMixin],
    computed: {
        active_session() {
            return this.$store.getters.activeSessionForUserWithId(
                this.current_user.id
            );
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.calendar-view {
    position: relative;
}
.padding-tracking-bar {
    padding-bottom: calc(71px + 20px);
}

.el-switch,
.el-radio-group {
    position: absolute;
    right: 20px;
    margin: 15px;
}
</style>
