<template>
    <div class="bg-lines">
        <div
            class="background"
            :style="{width: `${days.length * day_width}px`}"
        >
            <div class="blur" />
            <div class="opacity" />
        </div>

        <div
            v-for="item in days"
            :key="`bg-line-${item}`"
            :class="[
                'bg-line-container',
                ...bgLineClasses(item),
                {today: isToday(item)},
            ]"
            :style="{
                marginLeft: `${
                    workDaysBetween(adjustedStartDate(tree), item) * day_width
                }px`,
                ...cssStyles,
            }"
        >
            <div
                :id="isToday(item) && 'today'"
                :class="['bg-line', ...bgLineClasses(item)]"
            />
            <div class="markers">
                <div
                    v-if="
                        isToday(item) ||
                        ((hasClass(item, 'day') || hasClass(item, 'week')) &&
                            !isDayOmitted(item))
                    "
                    class="date-marker label"
                >
                    <span class="number">{{ item.format('D') }}</span>
                    <span class="day-week">{{ item.format('ddd') }}</span>
                </div>
                <div v-if="hasClass(item, 'month')" class="month-marker label">
                    <span>
                        {{ item.format('MMM') }}
                    </span>
                </div>
                <div v-if="hasClass(item, 'week')" class="week-marker label">
                    <span>W{{ item.isoWeek() }} </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {
    adjustedStartDate,
    isDayOmitted,
    workDaysBetween,
} from '@/components/timeline-v2/utils';
import * as dayjs from 'dayjs';

export default {
    name: 'timeline-bg-lines',

    props: {
        resolutions: {
            type: Object,
            required: true,
        },

        day_width: {
            type: Number,
            required: true,
        },

        row_height: {
            type: Number,
            required: true,
        },

        vertical_scroll_offset: {
            type: Number,
            required: true,
        },
    },

    data: () => ({
        CACHE_bgLineClasses: new Map(),
        adjustedStartDate: adjustedStartDate,
        workDaysBetween: workDaysBetween,
        isDayOmitted: isDayOmitted,
    }),

    computed: {
        ...mapState(['tree']),
        ...mapGetters(['days']),
        cssStyles() {
            return {
                '--day-width': `${this.day_width}px`,
                '--row-height': `${this.row_height}px`,
                '--day-width-offset': `${(-1 * this.day_width) / 2}px`,
            };
        },
    },

    methods: {
        isToday(d) {
            return d.isSame(dayjs(), 'day');
        },
        bgLineClasses(d) {
            const k = `${d.unix()},resolutions:${JSON.stringify(
                this.resolutions
            )}`;

            if (this.CACHE_bgLineClasses.has(k)) {
                return this.CACHE_bgLineClasses.get(k);
            }

            let res = [];

            if (this.resolutions.day) {
                res.push('day');
            }

            if (d.day() === 1 && this.resolutions.week) {
                res.push('week');
            }

            if (d.date() === 1 && this.resolutions.month) {
                res.push('month');
            }

            if (d.dayOfYear() === 1 && this.resolutions.year) {
                res.push('year');
            }

            this.CACHE_bgLineClasses.set(k, res);
            return res;
        },

        hasClass(item, className) {
            return this.bgLineClasses(item).some(
                (_className) => _className === className
            );
        },
    },
};
</script>

<style scoped lang="scss">
.bg-lines {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-bottom: 10px;
    z-index: 99;

    .background {
        top: 0;
        left: 0;
        position: absolute;
        height: var(--timeline-bar-height);

        & > div {
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .blur {
            backdrop-filter: blur(5px) grayscale(100%);
            -webkit-backdrop-filter: blur(5px) grayscale(100%);
        }
        .opacity {
            background-color: rgba($white, 0.7);
        }
    }

    .bg-line-container {
        height: 100%;
        position: absolute;
        left: 0;
        pointer-events: none;

        &.month {
            z-index: 1;
        }
    }

    .bg-line {
        height: 100%;
        position: absolute;
        left: 0;

        &.day {
            &:after {
                content: '';
                position: absolute;
                bottom: 0px;
                top: var(--timeline-bar-height);
                width: 1px;
                border-left: 1px solid $border-grey-light;
            }
        }

        &.week {
            border-left: 1px $border-grey-light solid;

            &:after {
                // Disable .day pseudo element
                content: none;
            }
        }

        &.month {
            border-left: 1px $border-grey solid;
        }

        &.year {
            border-left: 2px $border-grey solid;
        }

        &#today {
            z-index: 99;

            &:after {
                content: '';
                position: absolute;
                bottom: 0px;
                box-sizing: border-box;
                top: var(--timeline-bar-height);
                width: var(--day-width);
                border-left: 1px solid $blue;
                border-right: 1px solid $blue;
                background-color: rgba($blue, 0.2);
            }
        }
    }

    .today {
        .markers {
            .date-marker {
                background-color: $blue;
                height: 65px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border: 1px $blue solid;
                z-index: 99;
                box-sizing: border-box;

                .number {
                    color: white;
                }

                .day-week {
                    color: white;
                }
            }
        }
    }

    .markers {
        font-size: 10px;
        height: var(--timeline-bar-height);
        color: #000;
        z-index: 50;
        border-bottom: 1px $border-grey solid;
        width: var(--day-width);

        .label {
            position: absolute;
        }

        .date-marker {
            top: 35px;
            height: 60px;
            width: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            border-radius: 5px;

            .number {
                font-size: 15px;
                font-weight: bold;
            }

            .day-week {
                font-size: 10px;
                font-weight: 400;
                margin-top: 5px;
            }
        }

        .month-marker {
            top: 8px;
            width: var(--day-width);
            margin-left: 8px;
            z-index: 10;

            span {
                text-transform: uppercase;
                font-size: 12px;
                color: $black;
                font-weight: bold;
                margin-right: 5px;
            }
        }

        .week-marker {
            top: 8px;
            padding-left: 8px;

            span {
                font-size: 9px;
                color: $blue;
                font-weight: bold;
            }
        }
    }
}
</style>
