<template>
    <div class="content">
        <div class="bar">
            <!-- Loading -->
            <!--*******************************************-->

            <bar-segment
                v-if="loading"
                key="loading_bar"
                :time="0"
                :total_time="full_time"
                :height="20"
                :index="200"
                disabled
                background_color="#0f263b"
                :show_time="false"
            />

            <!-- Segments -->
            <!--*******************************************-->

            <template v-else>
                <bar-segment
                    v-for="item in data"
                    :key="item.project.id"
                    :time="item.time_full"
                    :total_time="full_time"
                    :height="20"
                    :index="200"
                    disabled
                    background_color="#0f263b"
                    :show_time="false"
                    show_bracket
                    :bracket_label="item.project.ref"
                >
                    <bar-segment
                        v-if="item.time_spent"
                        :key="`${item.project.id}_tspent`"
                        :time="item.time_spent"
                        :total_time="item.time_full"
                        :height="20"
                        :index="200"
                        disabled
                        :background_color="
                            item.time_over ? `#f92139` : `#69ebbd`
                        "
                    />
                </bar-segment>
                <bar-segment
                    v-if="data.length > 1"
                    key="project_swap"
                    :time="5 * 60 * 1000 * (data.length - 1)"
                    :total_time="full_time"
                    :height="20"
                    :index="200"
                    disabled
                    background_color="#ffc567"
                />
            </template>
        </div>
    </div>
</template>

<script>
import BarSegment from '@/components/progress/BarSegment';

export default {
    name: 'day-bar-block',
    components: {
        BarSegment,
    },
    props: {
        slots: {
            type: Array,
            default: null,
        },
        day: {
            type: Object,
            default: null,
        },
        user: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            loading: true,
            sessions: [],

            min_tracking_hours: parseInt(
                process.env.VUE_APP_DAILY_MIN_TRACKING_HOURS
            ),
        };
    },
    computed: {
        full_time() {
            return +process.env.VUE_APP_DAILY_HOURS * 60 * 60 * 1000;
        },
        projects() {
            const slots_projects = this.slots.map((slot) => {
                return this.$store.getters.projectForComponentWithId(
                    slot.component
                );
            });

            const sessions_projects = this.sessions.map((session) => {
                return this.$store.getters.projectForComponentWithId(
                    session.component
                );
            });

            return [...new Set([...slots_projects, ...sessions_projects])].sort(
                (a, b) => {
                    // Sort by Ref
                    if (a.ref > b.ref) return 1;
                    if (a.ref < b.ref) return -1;
                    return 0;
                }
            );
        },
        data() {
            const d = this.projects.map((project) => {
                const sessions = this.sessions.filter((session) => {
                    return (
                        project.id ===
                        this.$store.getters.projectForComponentWithId(
                            session.component
                        ).id
                    );
                });

                const slots = this.slots.filter((session) => {
                    return (
                        project.id ===
                        this.$store.getters.projectForComponentWithId(
                            session.component
                        ).id
                    );
                });

                const time_allocated =
                    slots.reduce((a, b) => a + b.time_estimate, 0) * 1000;

                const time_spent = this.$options.filters.getTotalDurationForSessions(
                    sessions
                );

                const time_over =
                    time_allocated < time_spent
                        ? time_spent - time_allocated
                        : 0;

                const time_full = time_over ? time_spent : time_allocated;

                return {
                    project,
                    time_allocated,
                    time_full,
                    time_over,
                    time_spent,
                    sessions,
                };
            });

            return d;
        },
        slots_with_sessions() {
            return this.slots.map((slot) => {
                return {
                    ...slot,
                    sessions: this.sessions.filter((session) => {
                        return session.component === slot.component;
                    }),
                };
            });
        },
        extra_sessions() {
            return this.sessions.filter((session) => {
                if (
                    this.slots.find((slot) => {
                        return slot.component == session.component;
                    })
                ) {
                    return false;
                }

                return true;
            });
        },
    },
    mounted() {
        const today_start = this.day.startOf('day').toDate();
        const today_end = this.day.endOf('day').toDate();

        this.$bind(
            'sessions',
            this.$fire
                .collection('sessions')
                .where('start', '>=', today_start)
                .where('start', '<=', today_end)
                .where('user', '==', this.$fire.doc(`users/${this.user.id}`))
                .orderBy('start', 'desc'),
            {maxRefDepth: 0}
        ).then(() => {
            this.loading = false;
        });
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.content {
    width: 100%;
    padding: 60px 20px;
    box-sizing: border-box;

    .bar {
        display: flex;
        width: 100%;
        height: 20px;
        background-color: $border-grey;

        &:before {
            content: '';
            position: absolute;
            border-radius: 10px;
            overflow: hidden;
            width: 100%;
        }

        //.bar-segment .bar-segment:last-of-type {
        //    border-radius: 0 !important;
        //}

        //.bar-segment:first-of-type {
        //    border-bottom-left-radius: 5px;
        //    border-top-left-radius: 5px;
        //}
        //
        //.bar-segment:last-of-type {
        //    border-bottom-right-radius: 5px;
        //    border-top-right-radius: 5px;
        //}
    }
}
</style>
