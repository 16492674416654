<template>
    <div class="facepile" :class="{has_time_data: time_data}">
        <div class="facepile-inner">
            <transition-group name="slide-face-pile" tag="div">
                <div v-for="user in users" :key="user.id" class="group">
                    <avatar
                        :user="user"
                        :size="size"
                        :hide_tooltip="hide_tooltip"
                    />
                    <span v-if="time_data" class="time_spent">
                        {{ time_data[user.id] | milli2duration }}
                    </span>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import Avatar from '@/components/generic/Avatar';

export default {
    name: 'face-pile',
    components: {
        Avatar,
    },
    props: {
        users: {
            type: Array,
            required: true,
        },
        size: {
            type: String,
            default: 'normal',
            validator: (val) => ['mini', 'normal', 'large'].includes(val),
        },
        hide_tooltip: {
            type: Boolean,
            default: false,
        },
        time_data: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.facepile {
    display: flex;
    justify-content: flex-end;

    .facepile-inner > ::v-deep div {
        align-items: center;
        display: inline-flex;
        margin-left: -5px;
        transition: all 0.3s ease-in-out;

        .group {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            .time_spent {
                position: absolute;
                left: 17px;
                font-size: 8px;
                margin-left: 5px;
                opacity: 0;

                transition: all 0.3s ease-in-out 0.1s;
            }
        }

        .el-tooltip {
            transition: all 0.4s ease-in-out;
        }

        .slide-face-pile {
            &-enter-active,
            &-leave-active {
                transition: all 0.3s ease-in-out;
            }

            &-enter,
            &-leave-to {
                opacity: 0;
                transform: translateX(5px);
            }
        }
    }

    &.has_time_data {
        &:hover {
            .facepile-inner > ::v-deep div {
                .el-tooltip {
                    margin-right: 35px;
                }

                .group {
                    .time_spent {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
</style>
