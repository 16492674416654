<template>
    <div class="overview-container">
        <div class="main">
            <!-- TODOS -->
            <!--*******************************************-->

            <todo-list />

            <!-- TEAM -->
            <!--*******************************************-->

            <project-team />
        </div>

        <aside>
            <project-status />
            <project-calendar-upcoming :calendar="filtered_calendar" />
            <project-milestone
                v-if="current_milestone"
                :current_milestone="current_milestone"
            />
            <project-events
                :current_milestone="current_milestone"
                :calendar="filtered_calendar"
            />
        </aside>
    </div>
</template>

<script>
import {momentWithTz} from '@/utils';

import ProjectStatus from '@/components/overview/ProjectStatus';
import ProjectEvents from '@/components/overview/ProjectEvents';
import ProjectMilestone from '@/components/overview/ProjectMilestone';
import ProjectTeam from '@/components/overview/ProjectTeam';
import ProjectCalendarUpcoming from '@/components/overview/ProjectCalendarUpcoming';
import TodoList from '@/components/todo/TodoList.vue';

export default {
    name: 'project-tab-overview',
    components: {
        TodoList,
        ProjectStatus,
        ProjectEvents,
        ProjectMilestone,
        ProjectTeam,
        ProjectCalendarUpcoming,
    },
    data() {
        return {
            calendar: [],
            calendar_ignore: null,
        };
    },
    computed: {
        current_milestone() {
            return this.$store.getters.currentMilestoneForProjectWithId(
                this.$route.params.project_id
            );
        },
        ignored_events() {
            if (!this.calendar_ignore || !this.calendar_ignore.ignored_events)
                return [];
            return this.calendar_ignore.ignored_events;
        },
        filtered_calendar() {
            return this.calendar.filter((cal) => {
                if (
                    this.ignored_events.includes(cal.id) ||
                    this.ignored_events.includes(cal.recurringEventId)
                )
                    return false;
                return true;
            });
        },
    },
    watch: {
        '$route.params.project_id'(val, oldVal) {
            if (val !== oldVal) {
                this.init();
            }
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            const start = momentWithTz().startOf('day').toDate();
            const end = momentWithTz().startOf('day').add(1, 'month').toDate();
            this.$bind('calendar_ignore', this.$fire.doc('system/calendar'), {
                maxRefDepth: 0,
            });

            this.$bind(
                'calendar',
                this.$fire
                    .collection('calendar')
                    .where(
                        'project',
                        '==',
                        this.$fire.doc(
                            `projects/${this.$route.params.project_id}`
                        )
                    )
                    .where('start', '>', start)
                    .where('start', '<', end),
                {maxRefDepth: 0}
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.overview-container {
    margin-top: -10px;
    margin-left: -10px;
    width: calc(100% + 20px);
    margin-bottom: -10px;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .main {
        flex: 1;
        margin: 10px;
    }

    aside {
        width: 400px;
        border-left: 1px solid $border-grey;
        background-color: $border-grey-light;
    }

    @media screen and (max-width: 992px) {
        margin: 0px;
        width: 100%;

        .main {
            margin: 0;
        }

        aside {
            display: none;
        }
    }
}
</style>
