<template>
    <section>
        <estimate-detail />
    </section>
</template>

<script>
import EstimateDetail from '@/pages/estimates/detail';

export default {
    name: 'project-tab-estimate',
    components: {
        EstimateDetail,
    },
};
</script>

<style lang="scss" scoped>
//
</style>
