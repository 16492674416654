<template>
    <todo-list />
</template>

<script>
import TodoList from '@/components/todo/TodoList.vue';

export default {
    components: {
        TodoList,
    },
};
</script>

<style rel="stylesheet/scss" lang="scss"></style>
