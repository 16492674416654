<template>
    <div class="outer">
        <header>
            <el-radio-group v-model="view" size="mini">
                <el-radio-button :label="ESTIMATE_STATUS.DRAFT">
                    Draft
                </el-radio-button>
                <el-radio-button :label="ESTIMATE_STATUS.PENDING">
                    In Review
                </el-radio-button>
                <el-radio-button :label="ESTIMATE_STATUS.SENT">
                    Sent
                </el-radio-button>
            </el-radio-group>
        </header>

        <div class="inner">
            <ul>
                <li
                    v-for="{estimates, project} in grouped_estimates"
                    :key="project.id"
                    class="group"
                >
                    <span class="name">
                        <strong>{{ project.ref }}</strong> -
                        {{ project.name }}
                    </span>
                    <ul>
                        <li v-for="estimate in estimates" :key="estimate.id">
                            <estimate-block
                                :estimate="estimate"
                                :project="project"
                            />
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {ESTIMATE_STATUS} from '@/enums';
import EstimateBlock from '@/components/blocks/EstimateBlock.vue';

export default {
    name: 'management-estimates',
    components: {EstimateBlock},
    data() {
        return {
            view: ESTIMATE_STATUS.PENDING,
        };
    },
    computed: {
        ESTIMATE_STATUS() {
            return ESTIMATE_STATUS;
        },
        grouped_estimates() {
            // Filter estimates by the selected view
            const estimates = this.$store.getters.estimates.filter(
                (e) => e.status === this.view && e.is_template === false
            );

            // Group estimates by project
            const grouped = estimates.reduce((grouped, estimate) => {
                const project = this.$store.getters.projectWithId(
                    estimate.project
                );

                if (!grouped[project.id]) {
                    grouped[project.id] = {
                        estimates: [],
                        project,
                    };
                }

                grouped[project.id]['estimates'].push(estimate);
                return grouped;
            }, {});

            // Convert the grouped object into an array and sort by project name
            return Object.values(grouped).sort((a, b) => {
                return a.project.name.localeCompare(b.project.name);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
        box-sizing: border-box;
        min-height: 65px;
        padding: 10px 20px;
        border-bottom: 1px solid $border-grey-light;
        display: flex;
        user-select: none;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .el-radio-group {
            margin-left: 10px;
        }
    }

    .inner {
        overflow: scroll;
        padding: 10px 20px;
        height: 100%;
        display: flex;
        flex-direction: column;

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                padding: 0;
                list-style: none;
            }
        }

        .group {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            span.name {
                margin-bottom: 10px;
                display: block;
            }

            ul li {
                margin-bottom: 10px;
            }
        }
    }
}
</style>
