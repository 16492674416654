/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cross': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.225 19.483L15.742 12l7.483-7.481a2.653 2.653 0 00.002-3.743 2.64 2.64 0 00-3.74-.002L12 8.262 4.512.775a2.64 2.64 0 00-3.74.002A2.648 2.648 0 00.776 4.52L8.258 12 .775 19.483a2.648 2.648 0 00-.002 3.742A2.63 2.63 0 002.644 24c.677 0 1.353-.26 1.869-.773L12 15.74l7.488 7.487a2.631 2.631 0 001.868.773 2.64 2.64 0 001.872-.775 2.649 2.649 0 00-.003-3.742z" _fill="#000" fill-rule="nonzero"/>'
  }
})
