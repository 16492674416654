<template>
    <li @click="$emit('click')">
        <div class="head">
            <div class="avatar-container">
                <avatar v-if="product_owner" :user="product_owner" />
                <div v-else class="avatar-spacer" />
            </div>

            <div>
                <label>
                    <span>{{ project.ref }}</span>
                    {{ project.name }}
                </label>
                <span v-if="project.description" class="description">
                    {{ project.description }}
                </span>
            </div>
        </div>
        <span v-if="group.name != 'active'" class="tag desktop-only">
            {{ group.name }}
        </span>
        <div v-if="status" class="status-block">
            <span>
                {{ project_status.content }}
            </span>
            <project-status-tag :status="status" size="mini" />
        </div>
    </li>
</template>

<script>
import ProjectStatusTag from '@/components/generic/ProjectStatusTag';
import Avatar from '@/components/generic/Avatar';
import UserMixin from '@/mixins/user.mixin';

export default {
    components: {ProjectStatusTag, Avatar},
    mixins: [UserMixin],
    props: {
        project: {
            type: Object,
            required: true,
        },
        group: {
            type: Object,
            required: true,
        },
    },
    computed: {
        project_status() {
            return this.project.currentStatus;
        },
        status() {
            if (this.project_status) {
                const type = this.$store.getters.eventTypeWithId(
                    this.project_status.type
                );

                if (type && type.statuses) {
                    return (
                        type.statuses.find(
                            (s) => s.value === this.project_status.status
                        ) ?? null
                    );
                }
            }
            return null;
        },
        product_owner() {
            const member = this.project.team?.find(
                (user) =>
                    this.product_owner_role?.id ===
                    this.$options.filters.fireRef2id(user.role)
            );

            if (member) return this.$store.getters.userWithId(member.user);
            return null;
        },
    },
};
</script>

<style lang="scss" scoped>
li {
    @media screen and (max-width: 992px) {
        min-height: 80px !important;
    }

    .head {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        @media screen and (max-width: 992px) {
            flex-direction: column;
            align-items: flex-start;

            .avatar-container {
                display: none;
            }
        }

        .avatar-wrapper {
            display: inline-block;
            margin-right: 15px;
        }

        .avatar-spacer {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 15px;
            border: 1px solid $transparent-grey;
            border-radius: 50%;
        }

        img {
            background-color: $border-grey;

            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 20px;
        }

        label {
            font-size: 18px;
            display: block;

            span {
                font-weight: bold;
                margin-right: 10px;
                width: 55px;
                display: inline-block;
                border-right: 1px solid $border-grey-light;

                @media screen and (max-width: 992px) {
                    border-right: 0;
                    margin-right: 0;
                }
            }
        }

        .description {
            font-size: 10px;
            margin: 0;
            margin-top: 8px;
            font-weight: 300;
            margin-top: 5px;

            @media screen and (max-width: 992px) {
                display: none;
            }
        }
    }

    .tag {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 10px 15px;
        font-size: 10px;
        text-transform: capitalize;
        background-color: white;
        padding: 5px 10px;
        border-radius: 20px;
    }

    .status-block {
        display: flex;
        flex-direction: row;
        align-items: center;

        span:first-of-type {
            display: inline-block;
            font-size: 12px;
            margin-right: 10px;
            color: rgba($black, 0.5);
            font-style: italic;

            @media screen and (max-width: 992px) {
                display: none;
            }
        }

        .status {
            margin-top: 1px;
        }
    }

    &.placeholder {
        background: none;
        pointer-events: none;
    }

    &:hover {
        box-shadow: 0 0 0 2px $blue;
    }

    &:last-of-type {
        margin: 0;
    }

    .active-users {
        float: right;

        &.lift {
            margin-bottom: 20px;
        }
    }
}
</style>
