import {COMMAND_DEPENDENCIES} from '@/enums';
import store from '@/store';
import {firebaseFirestore} from '@/utils/firebase';
import {fireRef2id, pluralize} from '@/filters';

/**
 * Provides a list of documents.
 * Documents are not bound in the store, so will be dynamically queried
 * @type {{provides: string, getResults: CommandProvider.getResults, label: string, filteredBy: null}}
 */

export const DocumentProvider = {
    label: 'Documents',
    provides: COMMAND_DEPENDENCIES.DOCUMENT,
    filteredBy: [COMMAND_DEPENDENCIES.ESTIMATE],
    getResults: async (data, filter_string) => {
        if (data[COMMAND_DEPENDENCIES.DOCUMENT]) return [];

        // only return results if filter, project or estimate is set
        if (!filter_string && !data.project && !data.estimate) return null;

        // query & cache documents if cache has not been populated yet
        if (DocumentProvider.documents === null) {
            let docQuery = firebaseFirestore.collection('documents');
            if (data.project && data.estimate) {
                docQuery = docQuery.where(
                    'linked_ref',
                    '==',
                    firebaseFirestore.doc(`estimates/${data.estimate.value.id}`)
                );
            }

            let docs = [];
            const docResult = await docQuery.get();
            docResult.forEach((doc) => {
                docs.push({...doc.data(), id: doc.id});
            });

            DocumentProvider.documents = docs;
        }
        let documents = DocumentProvider.documents;

        if (filter_string) {
            const query = filter_string.toLowerCase();
            documents = documents.filter((doc) => {
                return doc.name.toLowerCase().includes(query);
            });
        }
        return documents.map((doc) => {
            return {
                id: doc.id,
                label: doc.name,
                sub_label: doc.type,
                value: doc,
            };
        });
    },

    documents: null,
    cleanup() {
        // if this provider holds any resources during an operation, dispose of them here
        DocumentProvider.documents = null;
    },
};
