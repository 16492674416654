/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jira-qa': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g transform="translate(1 1)" _fill="none" fill-rule="evenodd"><rect pid="0" _fill="#F79232" width="14" height="14" rx="2"/><path pid="1" d="M8 6v-.99a1 1 0 10-2 0V6h2zM5 6h-.01A1 1 0 004 7v3c0 .556.444 1 .99 1h4.02a1 1 0 00.99-1V7c0-.556-.444-1-.99-1H9v-.994C9 3.886 8.105 3 7 3c-1.112 0-2 .898-2 2.006V6z" _fill="#FFF"/></g>'
  }
})
