<template>
    <content-block title="Team" no_background>
        <div slot="right" style="float: right;">
            <el-dropdown
                v-if="available_users.length > 0"
                v-only-admin
                trigger="click"
                placement="bottom"
                @command="addUser"
            >
                <el-button size="mini" @click="$emit('click_new')">
                    Add
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="user in available_users"
                        :key="user.id"
                        :command="user.id"
                    >
                        {{ user.name }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="team">
            <project-team-user
                v-for="user in project.team"
                :key="user.user"
                class="team-user"
                :team_user="user"
            />
        </div>
    </content-block>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import ProjectTeamUser from './ProjectTeamUser';

export default {
    name: 'project-team',
    components: {
        ContentBlock,
        ProjectTeamUser,
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        team_users() {
            return this.project?.team?.map((u) => u.user) || [];
        },
        available_users() {
            return this.$store.getters.users.filter((u) => {
                return !this.team_users.includes(`users/${u.id}`);
            });
        },
    },
    methods: {
        async addUser(value) {
            this.$store.dispatch('addUserToProjectTeam', {
                project_id: this.project.id,
                user_id: value,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.team {
    display: flex;
    flex-wrap: wrap;
}
</style>
