<template>
    <div
        v-if="editor"
        class="editor"
        :class="{responsive: simple_responsive_design, is_todo: is_todo}"
        @click.stop.prevent
    >
        <div v-if="has_prepend_slot" class="prepend">
            <slot name="prepend" />
        </div>
        <div v-if="prefix_icon" class="prefix-icon">
            <i :class="prefix_icon" />
        </div>
        <div class="content">
            <editor-content
                ref="editor_content"
                v-shortkey.avoid
                :editor="editor"
                @keyup.native="handleKeyUp"
            />
        </div>
        <div v-if="suffix_icon" class="suffix-icon">
            <i :class="suffix_icon" />
        </div>
        <div v-if="has_append_slot" class="append">
            <slot name="append" />
        </div>
    </div>
</template>

<script>
import SuggestionNode from '@/components/editor/extensions/SuggestionNode.js';
import LinkNode from '@/components/editor/extensions/LinkNode.js';
import OneLiner from '@/components/editor/extensions/OneLiner.js';
import IssueSuggestionList from '@/components/issues/IssueSuggestionList.vue';

import Placeholder from '@tiptap/extension-placeholder';

import {Extension} from '@tiptap/core';
import {Editor, EditorContent} from '@tiptap/vue-2';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';

export default {
    name: 'rendered-input',
    components: {
        EditorContent,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        project: {
            type: Object,
            default: () => null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        prefix_icon: {
            type: String,
            default: null,
        },
        suffix_icon: {
            type: String,
            default: null,
        },
        simple_responsive_design: {
            type: Boolean,
            default: false,
        },
        is_todo: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            editor: null,
            debounce: null,
        };
    },
    computed: {
        has_prepend_slot() {
            return !!this.$slots.prepend;
        },
        has_append_slot() {
            return !!this.$slots.append;
        },
    },
    watch: {
        value(val) {
            if (!this.debounce) {
                this.editor.chain().setContent(this.textToJson(val)).run();
            }
        },
        project(val) {
            this.init();
        },
        editable: {
            immediate: true,
            handler() {
                if (!this.editor) return;
                this.editor.setOptions({
                    editable: this.editable,
                });
            },
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        focus() {
            this.$nextTick(() => this.editor.commands.focus('start'));
        },

        handleKeyUp(event) {
            switch (event.key) {
                case 'Enter':
                    this.$emit('enter', this.jsonToText(this.editor.getJSON()));
                    break;
                case 'Backspace':
                    this.$emit(
                        'backspace',
                        this.jsonToText(this.editor.getJSON())
                    );
                    break;
                default:
                    this.$emit(
                        'keyup',
                        this.jsonToText(this.editor.getJSON()),
                        event.key
                    );
            }
        },
        init() {
            if (this.editor) {
                this.editor.destroy();
            }

            const handleCommand = () => {
                this.$bus.$emit('modal:action', {
                    modal: 'command-palette',
                    show: true,
                });
            };

            const ShortcutExtension = Extension.create({
                addKeyboardShortcuts() {
                    return {
                        'Mod-k': handleCommand,
                    };
                },
            });

            const extensions = [
                OneLiner,
                Paragraph,
                Text,
                LinkNode,
                ShortcutExtension,
                Placeholder.configure({placeholder: this.placeholder}),
            ];

            if (
                this.$store.getters.is_jira_enabled &&
                this.project &&
                this.project.jira_project
            ) {
                // The 'project.jira_project' and project.reference could both be used interchangeably. e.g. TUL and TOOL
                let projectPattern = '';
                if (this.project.jira_project && this.project.ref) {
                    projectPattern = `(?:${this.project.jira_project}|${this.project.ref})`;
                } else {
                    projectPattern =
                        this.project.jira_project || this.project.ref;
                }
                const ext = SuggestionNode.configure({
                    search: new RegExp(
                        `^(${projectPattern}-[A-Za-z0-9]*)$`,
                        'i'
                    ),
                    suggestion: {
                        items: this.filterIssues,
                    },
                    suggestion_component: IssueSuggestionList,
                    getContent: (item) => [
                        {
                            type: 'link_node',
                            attrs: {
                                url: `https://${this.project.jira_org}.atlassian.net/browse/${item.key}`,
                            },
                        },
                    ],
                });

                extensions.push(ext);
            }

            this.editor = new Editor({
                extensions,
                editable: this.editable,
                content: this.textToJson(this.value),
                onUpdate: () => {
                    clearTimeout(this.debounce);

                    this.debounce = setTimeout(
                        () => (this.debounce = null),
                        50
                    );

                    this.$emit('input', this.jsonToText(this.editor.getJSON()));
                },
            });
        },
        textToJson(text) {
            if (!text) {
                return null;
                /*return {
                    type: 'doc',
                    content: [{type: 'paragraph'}],
                }; */
            }
            const link_regx = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.?[a-zA-Z0-9()]{0,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))($|\s)/gi;

            let matches;
            let current_text = text;
            let nodes = [];
            let lastIndex = 0;

            while ((matches = link_regx.exec(current_text)) !== null) {
                const previous = current_text.substr(
                    lastIndex,
                    matches.index - lastIndex
                );

                if (previous !== '') {
                    nodes.push({
                        type: 'text',
                        text: previous,
                    });
                }

                nodes.push({
                    type: 'link_node',
                    attrs: {
                        url: matches[1],
                    },
                });

                lastIndex = matches.index + matches[1].length;
            }

            const last_text = current_text.substr(lastIndex);

            if (last_text !== '') {
                nodes.push({
                    type: 'text',
                    text: last_text,
                });
            }

            return {
                type: 'doc',
                content: [{type: 'paragraph', content: nodes}],
            };
        },
        jsonToText(json) {
            if (json && json.content && json.content.length > 0) {
                if (
                    json.content[0].content &&
                    json.content[0].content.length > 0
                ) {
                    return json.content[0].content
                        .map((node) => {
                            if (node.type === 'text') {
                                return node.text;
                            }
                            if (node.type === 'link_node')
                                return node.attrs.url;

                            return '';
                        })
                        .join('');
                }
            }

            return '';
        },
        getContentHtml() {
            return this.editor.getHTML();
        },

        async filterIssues(query) {
            const issues = this.$store.getters.issuesForJiraProject(
                this.project.jira_project
            );
            const search_content = query.substring(
                this.project.jira_project.length + 1
            );

            if (+search_content > 0) {
                return issues.filter((issue) => {
                    return issue.key.split('-')[1].startsWith(search_content);
                });
            }

            return issues.filter((issue) => {
                return issue.summary
                    .toLowerCase()
                    .includes(search_content.toLowerCase());
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.editor {
    display: flex;
    outline: none;
    align-items: center;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    padding: 0px;
    border-radius: 4px;
    color: $black;
    min-height: 39px;
    padding: 3px 0;
    overflow: hidden;

    .content {
        line-height: 14px;
        flex: 1;
        width: 0px;
        overflow-x: hidden !important;
        overflow-y: hidden;
        white-space: nowrap !important;

        p {
            display: inline-block !important;
            white-space: nowrap !important;
        }
    }

    .append,
    .prepend {
        display: flex;
        background-color: #f5f7fa;
        color: #909399;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        height: 39px;
    }

    .prepend {
        border-right: 2px solid rgb(220 223 230 / 40%);
        margin-right: 10px;

        @media screen and (max-width: 992px) {
            display: none;
        }
    }

    .append {
        border-left: 2px solid rgb(220 223 230 / 40%);
        margin-left: 10px;
    }

    .suffix-icon,
    .prefix-icon {
        display: flex;
        height: 100%;
        align-items: center;
        margin: 5px;
    }

    ::v-deep p {
        line-height: 20px;
        margin: 0;
    }

    .content {
        ::v-deep .ProseMirror {
            padding: unset !important;
            border: none !important;
            border-radius: 0px;
            background: unset;
            line-height: unset;
            box-shadow: none;

            &[contenteditable='true'] {
                &.ProseMirror-focused {
                    box-shadow: none;
                }
            }
        }
    }

    @media screen and (max-width: 992px) {
        min-height: 30px;
        padding: 0;

        .content ::v-deep .ProseMirror {
            text-align: left !important;
        }
    }

    &.responsive ::v-deep .ProseMirror p.is-editor-empty:first-child::before {
        left: 0;
    }
}

.is_todo .content {
    @media screen and (max-width: 992px) {
        padding-left: 14px;
    }
}

::v-deep .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    position: absolute;
    opacity: 0.5;
    pointer-events: none;
    font-family: Montserrat, sans-serif;
}
</style>
