<template>
    <fullscreen-loader message="Connecting to Bitbucket..." />
</template>

<script>
import {handleCallbackFromBitbucket} from '@/api/bitbucket-auth';

import FullscreenLoader from '@/components/generic/FullscreenLoader';
import {Notification} from 'element-ui';
import {firebaseApp} from '@/utils/firebase';

export default {
    name: 'bitbucket',
    components: {
        FullscreenLoader,
    },
    created() {
        // wait for user auth before calling xero auth
        firebaseApp.auth().onAuthStateChanged(() => {
            this.authenticate();
        });
    },
    methods: {
        sleep(ms) {
            return new Promise((r) => setTimeout(r, ms));
        },
        refreshUser() {
            this.$store
                .dispatch('Refresh', true)
                .then(async () => {
                    Notification({
                        type: 'success',
                        title: 'Success',
                        message: 'Bitbucket connected!',
                    });

                    // sleep for 2 seconds to show success screen
                    await this.sleep(2000);

                    this.navigateToHome();
                })
                .catch(() => {});
        },
        async authenticate() {
            const {code, state, error, error_description} = this.$route.query;
            this.clearQueryString();
            if (error || !code) {
                console.log('Received invalid response from bitbucket');
                Notification({
                    type: 'error',
                    title: 'Unable to connect to BitBucket',
                    message:
                        'Please try authenticating again.' +
                        (error_description || error
                            ? ` (Error: ${error_description || error})`
                            : ''),
                });
                this.navigateToHome();
                return;
            }

            const result = await handleCallbackFromBitbucket(code, state);

            if (result.success) {
                this.refreshUser();
            } else {
                Notification({
                    type: 'error',
                    title: 'Unable to connect to BitBucket',
                    message: 'Please try authenticating again.',
                });
                this.navigateToHome();
            }
        },
        clearQueryString() {
            window.history.replaceState(null, '', window.location.pathname);
        },
        navigateToHome() {
            this.$router.push({name: 'home'}).catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
body {
    background: $black;
}
</style>
