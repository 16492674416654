import request from '@/utils/request';

// {{host}}/bitbucket/redirect
export function redirectUserToBitbucket() {
    console.log('Redirecting to bitbucket account...');
    return request({
        url: '/bitbucket/redirect',
        method: 'get',
    });
}

// {{host}}/bitbucket/disconnect
export function disconnectBitbucket() {
    return request({
        url: '/bitbucket/disconnect',
        method: 'delete',
    });
}

// {{host}}/bitbucket/authenticate
export function handleCallbackFromBitbucket(code, state) {
    return request({
        url: '/bitbucket/authenticate',
        method: 'post',
        data: {
            code,
            state,
        },
    });
}
