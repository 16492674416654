<template>
    <li
        :key="session.id"
        :class="{
            grouped: in_group,
            tracking: is_tracking,
            header,
            [theme]: true,
        }"
    >
        <div class="row">
            <span v-if="show_rounded_time" class="start-stop rounded">
                <strong>
                    {{ ((start || session.start).seconds * 1000) | milli2date }}
                </strong>
                -
                <strong v-if="stop && session.stop">
                    {{ ((stop || session.stop).seconds * 1000) | milli2date }}
                </strong>
                <strong v-else>Now</strong>
            </span>
            <span class="start-stop real">
                <strong>
                    {{ (session.start.seconds * 1000) | milli2date }}
                </strong>
                -
                <strong v-if="session.stop">
                    {{ (session.stop.seconds * 1000) | milli2date }}
                </strong>
                <strong v-else>Now</strong>
            </span>
            <span v-if="show_rounded_time" class="duration rounded">
                {{ duration | milli2duration }}
            </span>
            <span class="duration real">
                {{ real_duration | milli2duration }}
            </span>
        </div>

        <session-input
            ref="editor"
            class="el-input session-input"
            :placeholder="input_placeholder"
            :session="session"
            :simple_responsive_design="true"
        />
    </li>
</template>

<script>
import SessionInput from '@/components/editor/SessionInput.vue';

export default {
    name: 'session-activity-block',
    components: {SessionInput},
    props: {
        session: {
            type: Object,
            required: true,
        },
        show_rounded_time: {
            type: Boolean,
            default: true,
        },
        start: {
            type: Object,
            default: () => null,
        },
        stop: {
            type: Object,
            default: () => null,
        },
        in_group: {
            type: Boolean,
            default: false,
        },
        header: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: '',
        },
    },
    computed: {
        is_tracking() {
            if (!this.session.stop) return true;
            return false;
        },
        input_placeholder() {
            return this.is_tracking
                ? 'What are you working on?'
                : 'What did you work on?';
        },
        duration() {
            const start = this.start || this.session.start;
            const stop = this.session.stop
                ? this.stop || this.session.stop
                : null;

            if (stop) {
                const value = (stop.seconds - start.seconds) * 1000;

                return Math.ceil(value);
            } else {
                //Session is active
                return (
                    (this.$store.state.current_time / 1000 - start.seconds) *
                    1000
                );
            }
        },
        real_duration() {
            const start = this.session.start;
            const stop = this.session.stop;

            if (stop) {
                const value = (stop.seconds - start.seconds) * 1000;

                return Math.ceil(value);
            } else {
                //Session is active
                return (
                    (this.$store.state.current_time / 1000 - start.seconds) *
                    1000
                );
            }
        },
        component() {
            return this.$store.getters.componentWithId(this.session.component);
        },
        project() {
            if (!this.session) return null;
            return this.$store.getters.projectWithId(this.session.project);
        },
    },
    methods: {
        focusTextBlock() {
            this.$refs.editor.focus();
        },
        getContentHtml() {
            if (!this.session.bound_tasks) {
                return this.$refs.editor.getContentHtml();
            }

            return this.session.bound_tasks
                .map((task) => {
                    const path = task?.path ?? task;
                    const issue = this.$store.getters.issueWithDocumentPath(
                        path
                    );
                    return `${issue.key}: ${issue.summary}`;
                })
                .join('; ');
        },
        getContentText() {
            if (!this.session.bound_tasks) {
                return this.session.note;
            }
            return this.session.bound_tasks
                .map((task) => {
                    const path = task?.path ?? task;
                    const issue = this.$store.getters.issueWithDocumentPath(
                        path
                    );
                    return `${issue.key}: ${issue.summary}`;
                })
                .join('; ');
        },
    },
};
</script>

<style lang="scss" scoped>
li {
    margin-bottom: 5px;
    padding: 0 20px;
    border-radius: 3px;
    background-color: $grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all 0.1s ease-out;
    box-sizing: border-box;

    .start-stop {
        width: 100px;
        text-align: left;
    }

    .session-input {
        min-height: 39px;
    }

    .start-stop.real,
    .duration.real {
        display: none;
        opacity: 0;
        color: $blue;
    }

    &.grouped {
        box-shadow: -2px 0 0 0 $border-grey-light;
        background-color: rgba($grey, 0.6);

        &:hover {
            box-shadow: 0 0 0 2px $border-grey-light,
                inset 2px 0 0 0 $border-grey-light;
        }

        &:last-child {
            margin-bottom: 20px;
        }

        &:first-child {
            margin-top: 20px;
            background-color: $grey;
        }
    }

    &.tracking {
        border-left-color: $blue;
        animation-duration: 3s;
        animation-name: fadeBorder;
        animation-iteration-count: infinite;
    }

    &:hover {
        background-color: white;
        box-shadow: 0 0 0 2px $border-grey-light;
    }

    @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 10px;
        padding-bottom: 6px;

        .notes {
            margin-top: 10px;
        }
    }

    .row {
        user-select: none;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        min-width: 300px;
        height: 19px;

        span {
            margin-right: 10px;
            border-right: 1px solid $border-grey;
            font-size: 13px;

            &:last-of-type,
            &:nth-child(3) {
                border: none;
                padding: 0;
                margin: 0;
            }
        }

        span.info {
            color: rgba($black, 0.5);
            font-size: 11px;
            font-weight: 300;
        }
    }
}
.dark {
    color: $white;

    ::v-deep .link {
        color: $white;

        a {
            color: $white;
        }
    }
}

@keyframes fadeBorder {
    from {
        border-left-color: $blue;
    }

    50% {
        border-left-color: lighten($blue, 20%);
    }

    to {
        border-left-color: $blue;
    }
}
</style>
