<template>
    <div class="background">
        <div class="login-container">
            <el-form class="card-box form">
                <span v-if="!showError" class="message">
                    Do you have an Access card?
                </span>
                <span v-else class="message error">
                    Access card not found or not enabled.
                </span>
                <el-form-item prop="email">
                    <el-input
                        v-model="passcode"
                        type="text"
                        auto-complete="off"
                        placeholder="Passcode"
                        maxlength="7"
                        @input="showError = false"
                    />
                </el-form-item>
                <el-button
                    :loading="loading"
                    native-type="submit"
                    @click="tryPasscode"
                >
                    Unlock
                </el-button>
            </el-form>
        </div>

        <span class="copyright">
            Made with <heart :width="12" /> by CodeFish Studio
        </span>
    </div>
</template>

<script>
import moment from 'moment';
import Heart from '@/components/generic/Heart';

export default {
    name: 'access-login',
    components: {
        Heart,
    },
    data() {
        return {
            passcode: null,
            loading: false,
            showError: false,
        };
    },
    mounted() {
        if (this.$route.query && this.$route.query.passcode) {
            this.passcode = this.$route.query.passcode;
            this.tryPasscode();
            this.removeQueryParams();
        }
    },
    methods: {
        tryPasscode() {
            this.loading = true;
            const today = moment().endOf('day').toDate();

            this.$fire
                .collection('access_cards')
                .where(
                    'project_ref',
                    '==',
                    this.$route.params.project_ref.toUpperCase()
                )
                .where('passcode', '==', this.passcode.toUpperCase())
                .where('enabled', '==', true)
                .where('expiry', '>', today)
                .limit(1)
                .get()
                .then((snapshot) => {
                    if (snapshot.size) {
                        const accessCard = snapshot.docs[0];
                        const data = accessCard.data();
                        this.$emit('onLogin', {
                            card_id: accessCard.id,
                            project_id: data.project.id,
                        });
                    } else {
                        console.warn('Access card not found.');
                        this.showError = true;
                    }

                    this.loading = false;
                });
        },
        removeQueryParams() {
            this.$router
                .replace({
                    ...this.$router.currentRoute,
                    query: {},
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.background {
    background-color: $actual-black;
    width: 100%;
    height: 100vh;
    position: fixed;
}

svg {
    width: 100%;
    margin-bottom: 20px;
}

.copyright {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: block;
    text-align: center;
    user-select: none;
    font-size: 13px;
    font-weight: 400;
    color: white;
    margin-bottom: 50px;

    svg {
        margin: 0 3px;
    }
}

.login-container {
    position: fixed;
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form {
        width: 450px;
        max-width: 100%;
        padding: 35px 35px 15px 35px;
        box-sizing: border-box;

        h1 {
            text-align: center;
            margin: 0;
            margin-bottom: 10px;
            color: white;
            font-size: 40px;
        }

        .message {
            text-align: center;
            width: 100%;
            display: block;
            margin-bottom: 30px;
            color: $white;

            &.error {
                color: $red;
            }
        }

        .el-form-item {
            background: $actual-dark-black;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            ::v-deep .el-form-item__error {
                display: none;
            }

            ::v-deep .el-input {
                width: 100%;
                height: 44px;
                border-radius: 5px;
                font-family: 'Rubik', sans-serif;
                font-size: 13px;
                color: white;
                background: $actual-dark-black;

                input:-webkit-autofill {
                    border-radius: 5px;
                    -webkit-text-fill-color: white;
                    -webkit-box-shadow: 0 0 0px 1000px $actual-dark-black inset;
                    border-radius: 0;
                }

                input {
                    font-family: 'Rubik', sans-serif;
                    text-transform: uppercase;
                    text-align: center;
                    font-weight: bold;
                    font-size: 25px;
                    height: 44px;
                    background: transparent;
                    border: none;
                    -webkit-appearance: none;
                    border-radius: 0px;
                    padding: 0;
                    background-color: transparent;
                    color: white;
                    padding-top: 5px;
                }
            }
        }

        .el-button {
            width: 100%;
            border: none;
            color: $actual-dark-black;
            font-weight: 500;
            background: white;
        }
    }

    .el-form-item__error {
        padding-top: 8px;
    }

    ::placeholder {
        color: rgba(white, 0.5);
    }

    :-ms-input-placeholder {
        color: rgba(white, 0.5);
    }

    ::-ms-input-placeholder {
        color: rgba(white, 0.5);
    }

    .el-form-item__error {
        color: white;
        font-weight: 400;
    }
}
</style>
