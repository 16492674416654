/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'skip': {
    width: 23,
    height: 24,
    viewBox: '0 0 23 24',
    data: '<path pid="0" d="M19.086 9.619L4.679.485A3.166 3.166 0 002.987 0C1.337 0 0 1.233 0 2.755v18.268c0 .557.183 1.101.526 1.56.934 1.254 2.794 1.572 4.153.71l14.407-9.134a2.88 2.88 0 00.77-.71c.934-1.253.59-2.968-.77-3.83z" _fill="#000"/><path pid="1" d="M22 0h-2c-.6 0-1 .4-1 1v22c0 .6.4 1 1 1h2c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1z" _fill="#000"/>'
  }
})
