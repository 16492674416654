import {COMMAND_DEPENDENCIES} from '@/enums';
import store from '@/store';

/**
 * Provides a list of projects
 * @type {{provides: string, getResults: CommandProvider.getResults, label: string, filteredBy: null}}
 */
export const ProjectProvider = {
    label: 'Projects',
    provides: COMMAND_DEPENDENCIES.PROJECT,
    filteredBy: null,
    getResults: async (data, filter_string) => {
        if (data[COMMAND_DEPENDENCIES.PROJECT]) return [];

        let projects = store.getters.projects;
        if (filter_string) {
            const query = filter_string.toLowerCase();
            projects = projects.filter((project) => {
                const name_match =
                    project.name && project.name.toLowerCase().includes(query);
                const ref_match =
                    project.ref && project.ref.toLowerCase().includes(query);

                return name_match || ref_match;
            });
        }
        return projects.map((project) => ({
            id: project.id,
            label: project.name,
            sub_label: project.ref,
            value: project,
        }));
    },
    cleanup() {
        // if this provider holds any resources during an operation, dispose of them here
    },
};
