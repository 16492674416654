import store from '../../../store';
import {
    DOCUMENT_AI_MODES,
    DOCUMENT_BLOCK_TYPES,
    DOCUMENT_SUMMARY_DETAIL,
    DOCUMENT_TYPES,
    DOCUMENT_VALUES,
} from '@/enums';
export const DOCUMENT_BLOCKS = {
    [DOCUMENT_BLOCK_TYPES.COVER_SHEET]: {
        name: 'Cover Sheet',
        component: 'cover-sheet-block',
        description: 'A pre-filled coversheet page',
        defaultState: {},
        canAdd: () => false,
        documentTypes: [DOCUMENT_TYPES.ESTIMATE],
        isValid: (block) => true,
    },
    [DOCUMENT_BLOCK_TYPES.SUMMARY_COVER_SHEET]: {
        name: 'Cover Sheet',
        component: 'summary-cover-sheet-block',
        description: 'A pre-filled cover sheet with client information',
        canAdd: () => false,
        documentTypes: [DOCUMENT_TYPES.SUMMARY],
        defaultState: {},
        isValid: (block) => true,
    },
    [DOCUMENT_BLOCK_TYPES.TEXT]: {
        name: 'Text',
        component: 'text-block',
        description: 'Editable text block and/or heading',
        documentTypes: [DOCUMENT_TYPES.ESTIMATE, DOCUMENT_TYPES.SUMMARY],
        canAdd: () => true,
        defaultState: {
            content: null,
            mandatory: false,
            note: null,
        },
        isValid: (block) => {
            return block.content || block.heading;
        },
    },
    [DOCUMENT_BLOCK_TYPES.AI_TEXT]: {
        name: 'AI Text',
        component: 'ai-text-block',
        description:
            'A heading and text block with an AI prompt. The resulting text can be edited or regenerated',
        documentTypes: [DOCUMENT_TYPES.ESTIMATE, DOCUMENT_TYPES.SUMMARY],
        canAdd: () => true,
        defaultState: {
            prompt: '',
            content: null,
            mandatory: false,
            note: null,
            mode: DOCUMENT_AI_MODES.SUMMARISE_DOCUMENT,
        },
        isValid: (block, isTemplate) => {
            if (isTemplate) {
                return !!block.prompt;
            }
            return block.content || block.heading;
        },
    },
    [DOCUMENT_BLOCK_TYPES.PAGE_BREAK]: {
        name: 'Page Break',
        component: 'page-break-block',
        description: 'Explicitly start a new page',
        documentTypes: [DOCUMENT_TYPES.ESTIMATE, DOCUMENT_TYPES.SUMMARY],
        canAdd: () => true,
        defaultState: {},
        isValid: (block, isTemplate) => true,
    },
    /*n.b. disabled, but kept for backwards compatibility*/
    [DOCUMENT_BLOCK_TYPES.ESTIMATE_GROUPS]: {
        name: 'Estimate Groups',
        component: 'estimate-group-block',
        description: '',
        canAdd: () => false,
        documentTypes: [],
        defaultState: {},
        isValid: (block, isTemplate) => true,
    },
    [DOCUMENT_BLOCK_TYPES.ESTIMATE_GROUP]: {
        name: 'Estimate Group',
        component: 'estimate-group-block',
        description: 'Render a table for the tasks within an estimate group',
        canAdd: () => false,
        documentTypes: [DOCUMENT_TYPES.ESTIMATE],
        defaultState: {},
        isValid: (block, isTemplate) => true,
    },
    [DOCUMENT_BLOCK_TYPES.ESTIMATE_SUMMARY]: {
        name: 'Estimate Summary',
        component: 'estimate-summary-block',
        description: 'A summary table for all Estimate Groups',
        canAdd: () => false,
        documentTypes: [DOCUMENT_TYPES.ESTIMATE],
        defaultState: {
            show_excluded: true,
            values: DOCUMENT_SUMMARY_DETAIL.DAYS,
        },
        isValid: (block, isTemplate) => true,
    },
    /*experimental*/
    [DOCUMENT_BLOCK_TYPES.JIRA_LIST]: {
        name: 'Jira List',
        component: 'jira-list-block',
        description:
            'A text block populated with jira cards completed within a date window',
        canAdd: () => store.getters.is_jira_enabled,
        documentTypes: [DOCUMENT_TYPES.SUMMARY],
        defaultState: {},
        isValid: (block, isTemplate) => {
            if (isTemplate) return true;
            return !!block.content;
        },
    },
    /*experimental*/
    [DOCUMENT_BLOCK_TYPES.MODULE_LIST]: {
        name: 'Module List',
        component: 'module-list-block',
        description: "A text block populated with the project's modules",
        canAdd: () => true,
        documentTypes: [DOCUMENT_TYPES.SUMMARY],
        defaultState: {},
        isValid: (block, isTemplate) => {
            if (isTemplate) return true;
            return !!block.content;
        },
    },
    [DOCUMENT_BLOCK_TYPES.AGREEMENT]: {
        name: 'Agreement',
        component: 'agreement-block',
        description:
            'An agreement proposal page including two tables for signing',
        canAdd: () => false,
        documentTypes: [DOCUMENT_TYPES.ESTIMATE],
        defaultState: {},
        isValid: (block, isTemplate) => true,
    },
};
