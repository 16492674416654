<template>
    <div v-if="is_template">
        <placeholder-block
            label="Estimate Summary"
            :block="block"
            :can_delete="false"
        />
    </div>
    <div v-else>
        <el-card class="content-block estimate-summary" shadow="never">
            <div slot="header" class="heading">
                <div class="grip">⣿</div>
                <span class="label">Estimate summary</span>
                <div v-if="estimate" class="controls">
                    <span>Options:</span>
                    <el-select
                        :disabled="read_only"
                        v-model="values"
                        multiple
                        placeholder="Select"
                        @change="
                            (val) =>
                                $emit('update', {
                                    ...block,
                                    values: val,
                                })
                        "
                    >
                        <el-option-group key="toggles" label="Toggles">
                            <el-option
                                v-if="hasExcludedGroups || hasExcludedTasks"
                                :disabled="!hasExcludedGroups && !showTasks"
                                label="Show excluded"
                                :value="DOCUMENT_SUMMARY_TOGGLES.EXCLUDED"
                            />
                            <el-option
                                :disabled="!showTasks"
                                label="Show task time/cost"
                                :value="DOCUMENT_SUMMARY_TOGGLES.TASK_COST"
                            />
                            <el-option
                                label="Show GST"
                                :value="DOCUMENT_SUMMARY_TOGGLES.GST"
                            />
                        </el-option-group>
                        <el-option-group key="display" label="Display">
                            <el-option
                                label="Tasks"
                                :value="DOCUMENT_SUMMARY_DETAIL.TASKS"
                            />
                            <el-option
                                label="Days"
                                :value="DOCUMENT_SUMMARY_DETAIL.DAYS"
                            />
                            <el-option
                                label="Hours"
                                :value="DOCUMENT_SUMMARY_DETAIL.HOURS"
                            />
                            <el-option
                                label="Cost"
                                :value="DOCUMENT_SUMMARY_DETAIL.COST"
                            />
                        </el-option-group>
                    </el-select>
                </div>
            </div>

            <el-skeleton v-if="!estimate" :rows="3" animated />
            <table v-else class="summary-table">
                <tr>
                    <th class="col-activity">Activity</th>
                    <th
                        v-if="!showDays && !showHours && !showCost"
                        class="col-value"
                    />
                    <th v-if="showDays" class="col-value">Days</th>
                    <th v-if="showHours" class="col-value">Hours</th>
                    <th v-if="showCost" class="col-value">Cost</th>
                </tr>

                <template v-for="group in filteredGroups">
                    <tr
                        :key="group.id"
                        :class="{
                            excluded: group.excluded_from_summary,
                            tasks: showTasks,
                            'task-cost': showTasks && showTaskCost,
                        }"
                    >
                        <td class="col-activity">
                            {{ group.title }}
                            <div v-if="group.discount" class="discount-note">
                                Includes {{ group.discount }}% discount
                            </div>
                        </td>
                        <td
                            v-if="!showDays && !showHours && !showCost"
                            class="col-value"
                        />
                        <td
                            v-if="showDays"
                            class="col-value"
                            :rowspan="
                                showTasks && !showTaskCost
                                    ? getFilteredTasks(group).length + 1
                                    : 1
                            "
                        >
                            {{ getGroupDays(group).toFixed(1) }}
                        </td>
                        <td
                            v-if="showHours"
                            class="col-value"
                            :rowspan="
                                showTasks && !showTaskCost
                                    ? getFilteredTasks(group).length + 1
                                    : 1
                            "
                        >
                            {{ getGroupHours(group).toFixed(1) }}
                        </td>
                        <td
                            v-if="showCost"
                            class="col-value"
                            :rowspan="
                                showTasks && !showTaskCost
                                    ? getFilteredTasks(group).length + 1
                                    : 1
                            "
                        >
                            {{ getGroupCost(group) | currency }}
                        </td>
                    </tr>
                    <template v-if="showTasks">
                        <tr
                            v-for="task in getFilteredTasks(group)"
                            :key="task.id"
                            :class="{
                                excluded:
                                    group.excluded_from_summary ||
                                    task.excluded_from_summary,
                            }"
                        >
                            <td class="col-activity task">
                                {{ task.description }}
                            </td>
                            <td
                                v-if="!showDays && !showHours && !showCost"
                                class="col-value"
                            />
                            <template v-if="showTaskCost">
                                <td v-if="showDays" class="col-value">
                                    {{ getTaskDays(task).toFixed(1) }}
                                </td>
                                <td v-if="showHours" class="col-value">
                                    {{ getTaskHours(task).toFixed(1) }}
                                </td>
                                <td v-if="showCost" class="col-value">
                                    {{ task.cost | currency }}
                                </td>
                            </template>
                        </tr>
                    </template>
                </template>
                <tr v-if="showDays || showHours">
                    <td class="total-days-label right">
                        Total time
                    </td>
                    <td v-if="showDays" class="total-days right">
                        {{ totalDays?.toFixed(1) }}
                    </td>
                    <td v-if="showHours" class="total-days right">
                        {{ totalHours?.toFixed(1) }}
                    </td>
                    <td v-if="showCost" class="total-days right" />
                </tr>
                <tr v-else-if="showGST && !creditValue">
                    <td class="spacer" :colspan="columns + 1" />
                </tr>
                <template v-if="creditValue > 0">
                    <tr>
                        <td class="total-cost-label right" :colspan="columns">
                            Subtotal
                        </td>
                        <td class="total-cost right">
                            {{ totalGroupCost | currency }}
                        </td>
                    </tr>
                    <tr>
                        <td class="right" :colspan="columns">
                            {{ estimate.credit.label }}
                        </td>
                        <td class="right">
                            {{ creditValue | currency }}
                        </td>
                    </tr>
                </template>
                <template v-if="showGST">
                    <tr>
                        <td class="right" :colspan="columns">
                            Subtotal
                        </td>
                        <td class="right">
                            {{ totalCost | currency }}
                        </td>
                    </tr>
                    <tr>
                        <td class="right" :colspan="columns">
                            GST
                        </td>
                        <td class="right">
                            {{ totalGST | currency }}
                        </td>
                    </tr>
                </template>
                <tr>
                    <td class="total-cost-label right" :colspan="columns">
                        <span v-if="!showGST" class="tax-note">ex. GST</span>
                        Total
                    </td>
                    <td class="total-cost right">
                        <span v-if="showGST">
                            {{ (totalCost + totalGST) | currency }}
                        </span>
                        <span v-else>{{ totalCost | currency }}</span>
                    </td>
                </tr>
            </table>
        </el-card>
    </div>
</template>

<script>
import {
    DOCUMENT_SUMMARY_TOGGLES,
    DOCUMENT_SUMMARY_DETAIL,
    DOCUMENT_BLOCK_TYPES,
    DOCUMENT_GROUP_TOGGLES,
    DOCUMENT_GROUP_DETAIL,
} from '@/enums';
import EstimateMixin from '@/mixins/estimate.mixin';
import estimateTaskMixin from '@/mixins/estimate.task.mixin';
import documentMixin from '@/mixins/document.mixin';
import PlaceholderBlock from '@/pages/documents/components/PlaceholderBlock';

import {fireRef2id} from '@/filters';

export default {
    name: 'estimate-summary-block',
    components: {PlaceholderBlock},
    mixins: [EstimateMixin, estimateTaskMixin, documentMixin],
    props: {
        document: {
            type: Object,
            required: true,
        },
        block: {
            type: Object,
            required: true,
        },
        estimate: {
            type: Object,
            default: null,
        },
        is_template: {
            type: Boolean,
            required: true,
        },
        read_only: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            values: this.block?.values || [],
            DOCUMENT_SUMMARY_TOGGLES: DOCUMENT_SUMMARY_TOGGLES,
            DOCUMENT_SUMMARY_DETAIL: DOCUMENT_SUMMARY_DETAIL,
        };
    },
    computed: {
        hasExcludedGroups() {
            return this.estimate?.groups.filter((group) => {
                return this.isGroupExcluded(group, group.tasks);
            }).length;
        },
        hasExcludedTasks() {
            const tasks = [];
            this.estimate?.groups.map((group) => {
                tasks.push(
                    ...group.tasks.filter((task) => {
                        return this.isTaskExcluded(task, task.subtasks);
                    })
                );
            });
            return tasks.length;
        },
        filteredGroups() {
            if (this.estimate?.groups) {
                const filtered = this.estimate.groups.filter((group) => {
                    group.excluded_from_summary = this.isGroupExcluded(
                        group,
                        group.tasks
                    );
                    if (this.showExcluded || !group.excluded_from_summary)
                        return group;
                });
                return this.sortGroupsInDocumentOrder(filtered, this.document);
            }
            return [];
        },
        totalDays() {
            return this.estimate?.groups.reduce((total, group) => {
                if (this.isGroupExcluded(group, group.tasks)) return total;
                return total + this.getGroupDays(group);
            }, 0);
        },
        totalHours() {
            return this.estimate?.groups.reduce((total, group) => {
                if (this.isGroupExcluded(group, group.tasks)) return total;
                return total + this.getGroupHours(group);
            }, 0);
        },
        totalGroupCost() {
            return this.estimate?.groups.reduce((total, group) => {
                if (this.isGroupExcluded(group, group.tasks)) return total;
                return total + this.getGroupCost(group);
            }, 0);
        },
        creditValue() {
            return this.estimate.credit?.value || 0;
        },
        totalCost() {
            return this.totalGroupCost - this.creditValue;
        },
        totalGST() {
            return this.totalCost * 0.1;
        },

        // TOGGLES
        showExcluded() {
            return this.values.includes(DOCUMENT_SUMMARY_TOGGLES.EXCLUDED);
        },
        showGST() {
            return this.values.includes(DOCUMENT_SUMMARY_TOGGLES.GST);
        },
        showTaskCost() {
            return this.values.includes(DOCUMENT_SUMMARY_TOGGLES.TASK_COST);
        },
        // DETAIL
        showDays() {
            return this.values.includes(DOCUMENT_SUMMARY_DETAIL.DAYS);
        },
        showHours() {
            return this.values.includes(DOCUMENT_SUMMARY_DETAIL.HOURS);
        },
        showCost() {
            return this.values.includes(DOCUMENT_SUMMARY_DETAIL.COST);
        },
        showTasks() {
            return this.values.includes(DOCUMENT_SUMMARY_DETAIL.TASKS);
        },

        columns() {
            let cols = 0;
            if (this.showDays) ++cols;
            if (this.showHours) ++cols;
            if (this.showCost) ++cols;
            return cols || 1;
        },
    },
    watch: {
        values(val) {
            // If display tasks is turned off, also turn off task_cost
            if (
                val.includes(DOCUMENT_SUMMARY_TOGGLES.TASK_COST) &&
                !val.includes(DOCUMENT_SUMMARY_DETAIL.TASKS)
            ) {
                const i = val.indexOf(DOCUMENT_SUMMARY_TOGGLES.TASK_COST);
                val.splice(i, 1);
            }
            // If only tasks are excluded and display tasks is turned off, also turn off show excluded
            if (
                !this.hasExcludedGroups &&
                val.includes(DOCUMENT_SUMMARY_TOGGLES.EXCLUDED) &&
                !val.includes(DOCUMENT_SUMMARY_DETAIL.TASKS)
            ) {
                const i = val.indexOf(DOCUMENT_SUMMARY_TOGGLES.EXCLUDED);
                val.splice(i, 1);
            }
            this.$emit('update', {
                ...this.block,
                values: val,
            });
        },
    },
    methods: {
        getGroupDays(group) {
            return group.tasks.reduce((total, task) => {
                if (
                    !group.excluded &&
                    !this.isTaskExcluded(task, task.subtasks)
                ) {
                    let days = this.task_qty(task);
                    if (task.unit !== 'day') {
                        days = days / task.hours_per_day;
                    }
                    return (total += days);
                }
                return total;
            }, 0);
        },
        getGroupHours(group) {
            return group.tasks.reduce((total, task) => {
                if (
                    !group.excluded &&
                    !this.isTaskExcluded(task, task.subtasks)
                ) {
                    let hours = this.task_qty(task);
                    if (task.unit === 'day') {
                        hours = hours * task.hours_per_day;
                    }
                    return (total += hours);
                }
                return total;
            }, 0);
        },
        getGroupCost(group) {
            return this.getTotalForGroup(group, group.tasks);
        },
        getTaskDays(task) {
            let days = this.task_qty(task);
            if (task.unit !== 'day') {
                days = days / task.hours_per_day;
            }
            return days;
        },
        getTaskHours(task) {
            let hours = this.task_qty(task);
            if (task.unit === 'day') {
                hours = hours * task.hours_per_day;
            }
            return hours;
        },
        getFilteredTasks(group) {
            return group.tasks.filter((task) => {
                task.excluded_from_summary = this.isTaskExcluded(
                    task,
                    task.subtasks
                );
                if (this.showExcluded || !task.excluded_from_summary)
                    return task;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.content-block {
    margin-bottom: 10px;
}
.heading {
    display: flex;
    align-items: center;

    .grip {
        width: 20px;
        height: 23px;
        text-align: left;
        opacity: 0.25;
        user-select: none;
        cursor: grab;
    }

    .label {
        font-weight: bold;
        font-size: 14px;
        color: $blue;
        margin-right: 20px;
        flex: 1;
    }

    .controls {
        flex: 0.5;
        display: flex;
        align-items: center;
        gap: 10px;

        .el-select {
            width: 100%;
        }

        .el-switch {
            margin: 0 10px;
        }

        .select-detail {
            width: 250px;
        }
    }
}

.summary-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        border: 1px solid $black;
        padding: 5px 10px;
    }

    .task-cost td {
        background-color: $border-grey-light;
        font-weight: bold;
    }

    .excluded td {
        text-decoration: line-through;
        color: rgba($black, 0.5);
    }

    th {
        background-color: $border-grey;
    }

    .col-activity {
        text-align: left;
        &.task {
            padding-left: 30px;
        }

        .discount-note {
            font-size: 12px;
        }
    }
    .tasks .col-activity {
        font-weight: bold;
    }

    .col-value {
        vertical-align: top;
        text-align: right;
        width: 180px;
    }

    .total-days-label,
    .total-days {
        background-color: rgba($border-grey, 0.5);
        font-weight: bold;
    }

    .total-cost-label {
        background-color: $border-grey;
        font-weight: bold;
    }

    .right {
        text-align: right;
    }

    .total-cost {
        background-color: $border-grey;
        font-weight: bold;
        text-align: right;
    }

    .tax-note {
        font-size: 12px;
        font-weight: normal;
        margin-right: 5px;
    }

    .spacer {
        background-color: rgba($border-grey, 0.5);
        padding: 2px;
    }
}

.excluded-notice {
    font-weight: bold;
    justify-content: center;
}
</style>
