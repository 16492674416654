<template>
    <el-popover placement="top" v-model="visible" :title="title" width="180">
        <money
            ref="inputval"
            v-model="working_value"
            v-bind="money"
            class="el-input__inner right"
            @keyup.enter.native="handleSaveValue"
        />
        <template #reference>
            <slot />
        </template>
    </el-popover>
</template>

<script>
import {Money} from 'v-money';

export default {
    name: 'amount-popover',
    components: {Money},
    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            visible: false,
            working_value: this.value ?? 0,
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
            },
        };
    },
    computed: {},
    watch: {
        value(val) {
            this.working_value = val ?? 0;
        },
        visible(val) {
            if (val) {
                this.$nextTick(() => {
                    this.$refs.inputval?.$el?.focus();
                    setTimeout(() => {
                        this.$refs.inputval?.$el?.select();
                    }, 50);
                });
            } else {
                // discard current working value
                this.working_value = this.value ?? 0;
            }
        },
    },
    methods: {
        handleSaveValue() {
            this.$emit('input', this.working_value);
            this.visible = false;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.right {
    text-align: right;
}
</style>
