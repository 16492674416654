<template>
    <div>
        <div class="milestone new" @click="handleClickOnNewMilestone">
            <svgicon name="plus-square" class="icon" />
        </div>
        <ul class="milestones">
            <li
                v-for="milestone in milestones"
                :key="milestone.id"
                class="milestone"
                @click="() => click(milestone)"
            >
                <div class="content">{{ milestone.content }}</div>
                <div class="date">
                    {{ milestone.start.toDate() | dateformat('DD/MM/YYYY') }}
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        types: {
            type: Array,
            default: () => [],
        },
        milestones: {
            type: Array,
            default: () => [],
        },
        project_id: {
            type: String,
            required: true,
        },
    },
    methods: {
        handleClickOnNewMilestone() {
            this.$router.push({
                name: 'events_new',
                params: {
                    project_id: this.project_id,
                    types: this.types,
                },
            });
        },

        click(milestone) {
            this.$emit('click:milestone', milestone);
        },
    },
};
</script>

<style lang="scss" scoped>
.milestones {
    list-style-type: none;
    margin: 20px 0px;
    padding: 0;

    .milestone {
        display: flex;
        border-radius: 5px;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        margin: 0 0 10px 0;
        background: $grey;
        min-height: 36px;

        .date {
            font-size: 12px;
            color: rgba($black, 0.3);
        }
    }
}
.new {
    padding: 20px;
    border-radius: 5px;
    height: 40px;
    background-color: $grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid $grey;
    margin-top: 10px;

    .icon {
        width: 25px;
        height: 25px;
        fill: rgba($black, 0.5);
    }

    span {
        color: rgba($black, 0.5);
        font-size: 14px;
        margin-top: 8px;
    }

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;

        .icon {
            fill: $blue;
        }

        span {
            color: $blue;
        }
    }
}
</style>
