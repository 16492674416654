<template>
    <li v-if="module" class="container" @click="$emit('click', module)">
        <span class="type">{{ module.type.value }}</span>
        <span class="subtype">{{ module.subtype.value }}</span>
    </li>
</template>

<script>
export default {
    name: 'module-block',
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },

        environments() {
            if (!this.module.environments) return [];
            return Object.entries(this.module.environments).map(
                ([key, data]) => {
                    return {name: key, ...data};
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    height: 90px;
    width: 200px;
    padding: 20px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid $border-grey;
    cursor: pointer;
    background-color: $white;
    box-sizing: border-box;
    user-select: none;
    margin-bottom: 10px;

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;
    }

    span.type {
        font-size: 13px;
        display: block;
    }

    span.subtype {
        font-size: 11px;
        display: block;
        margin-top: 3px;
    }
}
</style>
