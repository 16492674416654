<template>
    <fullscreen-loader
        v-if="loading"
        :message="`Generating ${report_name}...`"
    />
    <div class="report-wrapper" v-else-if="report" :class="{show_tracking_bar}">
        <div class="report-table">
            <div
                class="row report-header"
                ref="report_header"
                :class="{shadow: sticky_header}"
            >
                <div class="cell project-col" :class="{shadow: sticky_left}">
                    <financial-year-selector v-model="fy" />
                </div>
                <div
                    class="cell month-col"
                    v-for="month of table_months"
                    :key="month"
                    :class="current_month === month ? 'current' : ''"
                >
                    {{ month }}
                </div>
                <div class="cell total-col" :class="{shadow: sticky_right}">
                    Total
                </div>
            </div>
            <div
                class="report-body"
                ref="report_body"
                @scroll="handleHorizontalScroll"
            >
                <div
                    class="row report-row"
                    v-for="[prj, project] of Object.entries(report.projects)"
                    :key="prj"
                >
                    <div
                        class="cell project-col"
                        :class="{shadow: sticky_left}"
                    >
                        {{ prj }}
                    </div>
                    <div
                        class="cell month-col"
                        v-for="month of table_months"
                        :key="month"
                    >
                        <project-month
                            v-if="project.months[month]?.total > 0"
                            :value="project.months[month]"
                            :project_path="project.path"
                        />
                    </div>
                    <div class="cell total-col" :class="{shadow: sticky_right}">
                        {{ project.total | currency }}
                    </div>
                </div>
            </div>
            <div
                class="report-footer"
                ref="report_footer"
                :class="{shadow: sticky_footer}"
            >
                <!-- break even (monthly totals) -->
                <div class="row">
                    <div
                        class="cell project-col"
                        :class="{shadow: sticky_left}"
                    >
                        <amount-popover
                            :title="`Set breakeven for FY ${fy}`"
                            v-model="breakeven"
                        >
                            <div class="summary-title">
                                Breakeven
                                <div class="value" v-if="breakeven === null">
                                    Click to set
                                </div>
                                <div class="value" v-else>
                                    {{ breakeven | currency }}/mth
                                </div>
                            </div>
                        </amount-popover>
                    </div>
                    <div
                        class="cell month-col"
                        v-for="(month, index) of table_months"
                        :key="month"
                    >
                        <template v-if="report.month_totals[month] !== null">
                            <template v-if="breakeven !== null">
                                <breakeven-value
                                    v-if="total_mode === 'monthly'"
                                    :breakeven="breakeven"
                                    :value="report.month_totals[month]"
                                />
                                <breakeven-value
                                    v-else
                                    :breakeven="breakeven * (index + 1)"
                                    :value="report.ytd_totals[month]"
                                />
                            </template>
                            <div v-else class="amount bold">
                                {{ report.month_totals[month] | currency }}
                            </div>
                        </template>
                    </div>
                    <div class="cell total-col" :class="{shadow: sticky_right}">
                        <breakeven-value
                            v-if="breakeven !== null"
                            :value="report.total"
                            :breakeven="breakeven * month_count"
                            bold
                            style="margin-right: -10px;"
                        />
                        <div v-else class="amount bold">
                            {{ report.total | currency }}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div
                        class="cell project-col"
                        :class="{shadow: sticky_left}"
                    >
                        <amount-popover
                            :title="`Set revenue goal for FY ${fy}`"
                            v-model="revenue_goal"
                        >
                            <div class="summary-title">
                                Revenue goal
                                <div class="value" v-if="revenue_goal === null">
                                    Click to set
                                </div>
                                <div class="value" v-else>
                                    {{ revenue_goal | currency }}/mth
                                </div>
                            </div>
                        </amount-popover>
                    </div>
                    <div
                        class="cell month-col"
                        v-for="(month, index) of table_months"
                        :key="month"
                    >
                        <template
                            v-if="
                                report.month_totals[month] !== null &&
                                revenue_goal !== null
                            "
                        >
                            <revenue-goal-value
                                v-if="total_mode === 'monthly'"
                                :goal="revenue_goal"
                                :value="report.month_totals[month]"
                            />
                            <revenue-goal-value
                                v-else
                                :goal="revenue_goal * (index + 1)"
                                :value="report.ytd_totals[month]"
                            />
                        </template>
                    </div>
                    <div class="cell total-col" :class="{shadow: sticky_right}">
                        <revenue-goal-value
                            v-if="revenue_goal !== null"
                            style="margin-right: -10px;"
                            :value="report.total"
                            :goal="revenue_goal * month_count"
                            bold
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {momentWithTz} from '@/utils';
import debounce from 'lodash.debounce';

import FullscreenLoader from '@/components/generic/FullscreenLoader';
import FinancialYearSelector from '@/pages/reports/components/FinancialYearSelector';
import AmountPopover from '@/pages/reports/components/AmountPopover';
import ProjectMonth from '@/pages/reports/components/ProjectMonth';
import BreakevenValue from '@/pages/reports/components/BreakevenValue';
import RevenueGoalValue from '@/pages/reports/components/RevenueGoalValue';

export default {
    name: 'report-cashflow',
    components: {
        RevenueGoalValue,
        BreakevenValue,
        ProjectMonth,
        AmountPopover,
        FinancialYearSelector,
        FullscreenLoader,
    },
    props: {
        report_name: {
            type: String,
            required: true,
        },
        report_query: {
            type: Function,
            required: true,
        },
        year_mode: {
            type: String,
            default: 'full',
        },
        total_mode: {
            type: String,
            default: 'monthly',
        },
    },
    data() {
        return {
            loading: true,
            fy: null,
            fy_record: null,
            report_data: null,
            breakeven: null,
            revenue_goal: null,
            sticky_header: false,
            sticky_footer: false,
            sticky_left: false,
            sticky_right: false,
        };
    },
    computed: {
        report() {
            // create deep clone of report data
            const data = JSON.parse(JSON.stringify(this.report_data));

            if (this.year_mode === 'ytd') {
                // year-to-date: recalculate totals to only include TYD months, and remove any uninvoiced
                data.total = 0;
                let current_month_total = 0;
                for (const [project_label, project] of Object.entries(
                    data.projects
                )) {
                    project.total = 0;
                    let ytd_stop = false;
                    for (const [month_label, month] of Object.entries(
                        project.months
                    )) {
                        if (ytd_stop) {
                            project.months[month_label] = null;
                        } else {
                            // on the current month, remove any unscheduled invoices and recalculate total
                            if (month_label === this.current_month) {
                                let invoices = [];
                                let project_month_total = 0;
                                for (const invoice of month.invoices) {
                                    if (
                                        invoice.status !== 'scheduled' &&
                                        invoice.status !== 'pending'
                                    ) {
                                        invoices.push(invoice);
                                        project_month_total += invoice.amount;
                                    }
                                }
                                month.invoices = invoices;
                                month.total = project_month_total;
                                current_month_total += month.total;
                                // stop processing after the current month
                                ytd_stop = true;
                            }
                            project.total += month.total;
                        }
                    }
                    data.total += project.total;
                }
                data.month_totals[this.current_month] = current_month_total;
            }

            // calculate YTD totals for each month
            let ytd_stop = false;
            data.ytd_totals = Object.entries(data.month_totals).reduce(
                (ytd, month) => {
                    const [month_label, month_total] = month;
                    ytd.calc += month_total;
                    ytd[month_label] = ytd_stop ? null : ytd.calc;

                    if (ytd_stop) {
                        data.month_totals[month_label] = null;
                    }

                    // If in YTD mode, stop generating YTD data after current month
                    if (
                        this.year_mode === 'ytd' &&
                        month_label === this.current_month
                    ) {
                        ytd_stop = true;
                    }

                    return ytd;
                },
                {calc: 0}
            );
            delete data.ytd_totals.calc;

            return data;
        },
        current_month() {
            const eofy = momentWithTz(`${this.fy}-06-30`).endOf('day');
            const today = momentWithTz();

            if (
                today.isSameOrBefore(eofy) &&
                today.isAfter(eofy.subtract(1, 'year'))
            ) {
                // selected FY is current FY, highlight current month
                return today.format('MMMM');
            }

            return null;
        },
        month_count() {
            let count = 0;
            if (this.report) {
                for (const month of Object.keys(this.report.month_totals)) {
                    ++count;
                    if (
                        this.year_mode === 'ytd' &&
                        month === this.current_month
                    )
                        break;
                }
            }
            return count;
        },
        table_months() {
            if (this.report) {
                return Object.entries(this.report.month_totals).map(
                    ([month, total]) => month
                );
            }
            return [];
        },
        show_tracking_bar() {
            return this.$store.getters.show_tracking_bar;
        },
    },
    watch: {
        fy() {
            this.selectFinancialYear();
        },
        breakeven(val) {
            this.updateFinancialYear('breakeven', val);
        },
        revenue_goal(val) {
            this.updateFinancialYear('revenue_goal', val);
        },
    },
    created() {
        window.addEventListener('resize', this.handleWindowResize);
    },
    mounted() {
        // set default financial year
        const now = momentWithTz();
        let year = now.year();
        if (now.month() > 5) year += 1;

        // setting FY will trigger the report
        this.fy = year;

        document
            .getElementById('app')
            .addEventListener('scroll', this.getVerticalScrollPosition);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        document
            .getElementById('app')
            .removeEventListener('scroll', this.getVerticalScrollPosition);
    },
    methods: {
        async fetchReport() {
            this.loading = true;

            // get the record for the selected financial year, if set
            this.$bind(
                'fy_record',
                this.$fire
                    .collection('financial_years')
                    .doc(this.fy.toString()),
                {maxRefDepth: 0}
            ).then((record) => {
                this.breakeven = record?.breakeven ?? null;
                this.revenue_goal = record?.revenue_goal ?? null;
            });

            const result = await this.report_query(this.fy);
            this.report_data = result.data;
            this.loading = false;

            this.$nextTick(() => {
                // calculate sticky status of rows/cols
                this.getVerticalScrollPosition();
                this.getHorizontalScrollPosition();
            });
        },

        selectFinancialYear: debounce(function () {
            this.fetchReport();
        }, 500),

        updateFinancialYear(key, value) {
            this.$fire
                .collection('financial_years')
                .doc(this.fy.toString())
                .set({[key]: value}, {merge: true})
                .catch((e) => {
                    console.error(e);
                });
        },

        getHorizontalScrollPosition() {
            const scroll_pos = this.$refs.report_body.scrollLeft;
            this.sticky_left = scroll_pos > 0;
            this.sticky_right =
                this.$refs.report_body.scrollWidth - scroll_pos >
                window.innerWidth;
            return scroll_pos;
        },

        getVerticalScrollPosition() {
            const scroll_pos = document.getElementById('app').scrollTop;
            this.sticky_header = scroll_pos > 0;

            // sticky footer is a bit more complicated to calculate
            const visible_body_height =
                this.$refs.report_footer.getBoundingClientRect().bottom -
                this.$refs.report_header.getBoundingClientRect().bottom +
                10;
            const max_scroll =
                this.$refs.report_body.scrollHeight - visible_body_height;
            this.sticky_footer = scroll_pos < max_scroll;
        },

        handleHorizontalScroll() {
            const scroll_pos = this.getHorizontalScrollPosition();
            this.$refs.report_header.scrollLeft = scroll_pos;
            this.$refs.report_footer.scrollLeft = scroll_pos;
        },

        handleWindowResize() {
            this.getVerticalScrollPosition();
            this.getHorizontalScrollPosition();
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.report-wrapper {
    --page-header-height: 139px;
    --page-footer-height: 0px;

    --row-height: 50px;
    --header-row-height: 50px;
    --footer-row-height: 50px;
    --footer-height: calc(var(--footer-row-height) * 2 + 3px);

    --project-col-min-width: 130px;
    --month-col-min-width: 110px;
    --total-col-min-width: 110px;

    &.show_tracking_bar {
        --page-footer-height: 79px;
    }

    .report-table {
        margin-bottom: var(--page-footer-height);
        font-size: 11px;

        .row {
            display: flex;
            flex-direction: row;
            align-items: center;

            .cell {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 5px;
                background-color: white;
                height: var(--header-row-height);

                &:first-child {
                    padding-left: 15px;
                }

                &:last-child {
                    padding-right: 15px;
                }
            }
        }
        .report-header {
            position: sticky;
            top: var(--page-header-height);
            margin-top: -1px;
            text-transform: uppercase;
            color: $black;
            font-size: 13px;
            font-weight: bold;
            overflow-x: hidden;
            z-index: 3;
            transition: box-shadow 0.15s ease-in-out;
            box-shadow: 0 0 10px 0 rgba(34, 48, 73, 0);

            &.shadow {
                box-shadow: 0 0 66px 0 rgba(34, 48, 73, 0.2);
            }

            .cell {
                background-color: $grey;
                border-bottom: 1px solid $border-grey;
            }

            .year-selector {
                width: 100%;
            }

            .current {
                color: $blue;
                border-bottom: 2px solid $blue;
            }
        }

        .report-body {
            padding-bottom: var(--footer-height);
            overflow-x: auto;
        }

        .report-row {
            &:not(:last-child) {
                .cell {
                    border-bottom: 1px solid $border-grey;
                }
            }
        }

        .report-footer {
            margin-top: calc(var(--footer-height) * -1);
            position: sticky;
            bottom: var(--page-footer-height);
            overflow-x: hidden;
            z-index: 3;
            border-bottom: 1px solid $border-grey;
            transition: box-shadow 0.15s ease-in-out;
            box-shadow: 0 0 10px 0 rgba(34, 48, 73, 0);

            &.shadow {
                box-shadow: 0 0 66px 0 rgba(34, 48, 73, 0.2);
            }

            .cell {
                background-color: $grey;
                border-top: 1px solid $border-grey;
            }

            .summary-title {
                font-weight: bold;
                color: $black;

                .value {
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 10px;
                    opacity: 0.5;
                }
            }

            .value-display {
                width: 100%;
            }
        }

        .project-col {
            min-width: var(--project-col-min-width);
            position: sticky;
            left: 0;
            font-size: 13px;
            font-weight: bold;
            z-index: 1;

            &.cell {
                justify-content: flex-start;
            }

            &.shadow {
                &::after {
                    content: '';
                    display: block;
                    width: 10px;
                    height: 50px;
                    position: absolute;
                    right: -10px;
                    background: linear-gradient(
                        90deg,
                        rgba(0, 0, 0, 0.1) 0%,
                        rgba(0, 0, 0, 0) 100%
                    );
                }
            }
        }

        .month-col {
            min-width: var(--month-col-min-width);
            flex: 1;

            // popover in ProjectMonth adds a span that mucks up layout?
            > span {
                width: 100%;
            }

            .amount {
                width: 100%;
                padding-right: 10px;
                text-align: right;
                font-weight: bold;
            }
        }

        .total-col {
            min-width: var(--total-col-min-width);
            position: sticky;
            right: 0;
            font-weight: bold;
            z-index: 1;

            &.cell {
                justify-content: flex-end;
            }

            &.shadow {
                &::before {
                    content: '';
                    display: block;
                    width: 10px;
                    height: 50px;
                    position: absolute;
                    left: -10px;
                    background: linear-gradient(
                        90deg,
                        rgba(0, 0, 0, 0) 0%,
                        rgba(0, 0, 0, 0.1) 100%
                    );
                }
            }
        }
    }

    ::v-deep {
        .el-popper {
            z-index: 4 !important;
        }
    }
}
</style>
