<template>
    <div class="outer">
        <breadcrumb-header :labels="['New User']" go_back_to="management_users">
            <template v-if="result">
                <el-popconfirm
                    v-if="result.password"
                    title="Make sure you have copied the password!"
                    confirm-button-text="OK"
                    cancel-button-text="Go Back"
                    @confirm="reset"
                >
                    <el-button slot="reference" size="mini">
                        Create Another User
                    </el-button>
                </el-popconfirm>
            </template>
        </breadcrumb-header>
        <template v-if="result">
            <el-form label-width="160px" @submit.native.prevent>
                <el-form-item label="Name">
                    <el-input v-model="result.name" :readonly="true">
                        <el-button
                            slot="append"
                            v-clipboard:copy="result.name"
                            class="copy-button"
                            size="small"
                            icon="el-icon-document-copy"
                            @click.native="showClipboardNotification('Name')"
                        />
                    </el-input>
                </el-form-item>
                <el-form-item label="Email">
                    <el-input v-model="result.email" :readonly="true">
                        <el-button
                            slot="append"
                            v-clipboard:copy="result.email"
                            class="copy-button"
                            size="small"
                            icon="el-icon-document-copy"
                            @click.native="showClipboardNotification('Email')"
                        />
                    </el-input>
                </el-form-item>
                <el-form-item v-if="result.password" label="Password">
                    <el-input
                        v-model="result.password"
                        type="password"
                        :readonly="true"
                        show-password
                    >
                        <el-button
                            slot="append"
                            v-clipboard:copy="result.password"
                            class="copy-button"
                            size="small"
                            icon="el-icon-document-copy"
                            @click.native="
                                showClipboardNotification('Password')
                            "
                        />
                    </el-input>
                </el-form-item>
            </el-form>
        </template>
        <el-form
            v-else
            ref="form"
            auto-complete="on"
            :model="form"
            :rules="rules"
            label-width="200px"
            class="form-wrapper"
        >
            <!-- NAME -->
            <!--*******************************************-->

            <el-form-item label="Name" prop="name">
                <el-input
                    v-model="form.name"
                    name="name"
                    type="text"
                    auto-complete="off"
                />
            </el-form-item>

            <!-- Email -->
            <!--*******************************************-->

            <el-form-item label="Email" prop="email">
                <el-input
                    v-model="form.email"
                    name="email"
                    type="email"
                    auto-complete="off"
                    placeholder="user@email.com.au"
                />
            </el-form-item>

            <!-- Role -->
            <!--*******************************************-->

            <el-form-item label="Role" prop="role">
                <el-select
                    v-model="form.role"
                    placeholder="User Role"
                    value-key="role"
                    name="role"
                >
                    <el-option
                        v-for="r in roles"
                        :key="r.name"
                        :label="r.name"
                        :value="r.value"
                    />
                </el-select>
            </el-form-item>

            <el-button
                class="create-button"
                :disabled="creating"
                @click="handleSubmit"
            >
                {{ 'Create' }}
            </el-button>
        </el-form>
    </div>
</template>

<script>
import {createUser} from '@/api/user';
import {Notification} from 'element-ui';
import BreadcrumbHeader from '@/pages/management/components/BreadcrumbHeader';
import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';
import {USER_ROLES} from '@/enums';

export default {
    name: 'users-new',
    components: {BreadcrumbHeader},
    props: {
        client: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            creating: false,
            form: {
                name: null,
                email: null,
                role: null,
            },
            result: null,
            rules: {
                name: [{required: true, trigger: 'blur'}],
                email: [
                    {
                        required: true,
                        trigger: 'blur',
                        type: 'email',
                        message: 'Must be a valid email address',
                    },
                ],
                role: [
                    {
                        required: true,
                        trigger: 'change',
                    },
                ],
            },
            roles: [
                {name: 'User', value: USER_ROLES.USER},
                {name: 'Admin', value: USER_ROLES.ADMIN},
                {name: 'Super admin', value: USER_ROLES.SUPER_ADMIN},
            ],
        };
    },
    computed: {
        using_google_auth() {
            return this.$store.getters.is_feature_enabled(
                SUPPORTED_FEATURES.GOOGLE_AUTH
            );
        },
    },
    methods: {
        showClipboardNotification(key) {
            Notification({
                type: 'success',
                title: 'Copied',
                message: `${key} copied to clipboard.`,
            });
        },
        reset() {
            this.result = null;
            this.creating = false;
            this.form = {
                name: null,
                email: null,
                role: null,
            };
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.creating = true;
                    this.result = null;

                    const usingName = this.form.name;
                    const usingEmail = this.form.email;

                    createUser(
                        this.form.name,
                        this.form.email,
                        this.form.role,
                        !this.using_google_auth
                    )
                        .then((res) => {
                            this.result = {
                                password: res?.data?.password,
                                success: true,
                                name: usingName,
                                email: usingEmail,
                            };
                        })
                        .catch((err) => {
                            Notification({
                                type: 'error',
                                title: 'Error creating user',
                                message: err.message,
                            });
                            this.result = null;
                        })
                        .finally(() => {
                            this.creating = false;
                        });
                } else {
                    console.log('Form not valid...');
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.el-form {
    display: flow-root;
    margin-top: 20px;
    padding: 20px;

    hr {
        margin: 20px 50px;
        border: none;
        height: 1px;
        background-color: $border-grey-light;
    }
    ::v-deep .el-select {
        width: 100%;
    }
    ::v-deep .el-input {
        .el-input__inner {
            cursor: pointer;

            &:hover {
                border-color: $blue-light;
            }
        }

        .el-input-group__append {
            min-width: 0;
        }
    }

    .el-input + .el-input {
        margin-top: 5px;
    }

    .el-input.half {
        width: 50%;
    }

    .el-input.half + .el-input.half {
        width: calc(50% - 5px);
        margin-top: 0;
        margin-left: 5px;
    }

    .copy-button {
        &:focus {
            color: $blue;
        }
    }

    @media screen and (max-width: 992px) {
        ::v-deep {
            .el-form-item {
                display: flex;
                flex-direction: column;

                &__label {
                    text-align: left;
                    width: 100% !important;
                }

                &__content {
                    margin-left: 0 !important;
                }
            }
        }
    }
}
.create-button {
    float: right;
}
</style>
