<template>
    <div class="tenant-selector" v-if="tenants">
        <div class="tenant-list">
            <span>Select a Xero tenant to connect:</span>
            <el-select v-model="selected_tenant">
                <el-option
                    v-for="tenant of tenants"
                    :key="tenant.id"
                    :value="tenant.id"
                    :label="tenant.name"
                />
            </el-select>
            <el-button @click="selectTenant">Select</el-button>
        </div>
    </div>
    <fullscreen-loader v-else message="Connecting to Xero..." />
</template>

<script>
import {handleCallbackFromXero, handleSelectXeroTenant} from '@/api/xero';

import FullscreenLoader from '@/components/generic/FullscreenLoader';
import {Notification} from 'element-ui';
import {firebaseApp} from '@/utils/firebase';

export default {
    name: 'xero',
    components: {
        FullscreenLoader,
    },
    data() {
        return {
            tenants: null,
            selected_tenant: null,
        };
    },
    created() {
        // wait for user auth before calling xero auth
        firebaseApp.auth().onAuthStateChanged(() => {
            this.authenticate();
        });
    },
    methods: {
        refreshUser() {
            this.$store
                .dispatch('Refresh', true)
                .then(async () => {
                    Notification({
                        type: 'success',
                        title: 'Success',
                        message: 'Xero connected!',
                    });

                    this.navigateToHome();
                })
                .catch(() => {});
        },
        async authenticate() {
            const {code, state, error, error_description} = this.$route.query;
            this.clearQueryString();
            if (error || !code) {
                console.log('Received invalid response from xero');
                Notification({
                    type: 'error',
                    title: 'Unable to connect to Xero',
                    message:
                        'Please try authenticating again.' +
                        (error_description || error
                            ? ` (Error: ${error_description || error})`
                            : ''),
                });
                this.navigateToHome();
                return;
            }

            const result = await handleCallbackFromXero(code, state);

            if (result.success) {
                // list tenants
                if (result.data.tenants && result.data.tenants.length) {
                    if (result.data.tenants.length > 1) {
                        // display list of tenants to select
                        this.tenants = result.data.tenants;
                        this.selected_tenant = this.tenants[0].id;
                    } else {
                        // single tenant has already been preselected
                        this.refreshUser();
                    }
                }
            } else {
                Notification({
                    type: 'error',
                    title: 'Unable to connect to Xero',
                    message: 'Please try authenticating again.',
                });
                this.navigateToHome();
            }
        },

        async selectTenant() {
            if (this.selected_tenant) {
                // return to loading state
                this.tenants = null;
                const result = await handleSelectXeroTenant(
                    this.selected_tenant
                );
                if (result.success) {
                    this.refreshUser();
                } else {
                    Notification({
                        type: 'error',
                        title: 'Unable to connect to Xero',
                        message: 'Please try authenticating again.',
                    });
                    this.navigateToHome();
                }
            }
        },

        clearQueryString() {
            window.history.replaceState(null, '', window.location.pathname);
        },
        navigateToHome() {
            this.$router.push({name: 'home'}).catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
body {
    .tenant-selector {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .tenant-list {
        width: 300px;
        background-color: $white-dark;
        border: 1px solid $border-grey;
        border-radius: 5px;
        padding: 20px;
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        > * {
            text-align: center;
            width: 100%;
        }
    }
}
</style>
