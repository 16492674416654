<template>
    <div v-if="estimate">
        <div class="header">
            <el-button @click="goBackAction">Back</el-button>
            <span class="ref">
                /
                <span> {{ estimate.ref }}</span>
            </span>
        </div>

        <hr />

        <estimate-component-assignment :estimate="estimate" />
        <estimate-task-linking :estimate="estimate" />
    </div>
</template>

<script>
import estimateMixin from '@/mixins/estimate.mixin';
import estimateTaskMixin from '@/mixins/estimate.task.mixin';
import documentMixin from '@/mixins/document.mixin';

import EstimateComponentAssignment from '@/components/estimate/EstimateComponentAssignment';
import EstimateTaskLinking from '@/components/estimate/EstimateTaskLinking.vue';

export default {
    name: 'estimate-componentslinking',
    components: {
        EstimateTaskLinking,
        EstimateComponentAssignment,
    },
    mixins: [estimateMixin, estimateTaskMixin, documentMixin],
    data() {
        return {
            estimate: null,
            loading: true,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        /* INIT */
        async init() {
            this.loading = true;

            // TODO: Move these binding to store - to avoid to do it in multiple places
            // Use same logic as estimate_detail page, once fixed

            await this.$bind(
                'estimate',
                this.$fire.doc(`estimates/${this.$route.params.estimate_id}`),
                {
                    maxRefDepth: 0,
                }
            );

            this.loading = false;
        },

        /* NAVIGATION */

        goBackAction() {
            this.$router.push({
                name: 'project_detail_estimate+detail',
                params: {
                    project_id: this.$route.params.project_id,
                    estimate_id: this.$route.params.estimate_id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    span.ref {
        padding: 0 10px;

        span {
            padding: 0 10px;
            font-weight: bold;
        }
    }
}

hr {
    width: 100%;
    margin: 10px 0;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}
</style>
