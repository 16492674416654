<template>
    <transition name="slide" mode="out-in">
        <div v-if="component" class="component-info row" :key="component.id">
            <time-indicator :component="component" />

            <div class="col">
                <div class="primary" @click="gotoProject">
                    <span class="ref">{{ project.ref }}</span>
                    <span class="spacer">\</span>
                    <span class="project">{{ project.name }}</span>
                </div>
                <div class="secondary">
                    <span class="stage">
                        {{ stage ? `#${stage.number}` : null }}
                    </span>
                    <span class="spacer">/</span>
                    <div @click="gotoActivity" class="component">
                        <span class="ref" v-if="component.ref">
                            {{ component.ref }}
                        </span>
                        <span>
                            {{ component.name }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import TimeIndicator from '@/components/generic/TimeIndicator';

export default {
    name: 'component-info',
    components: {
        TimeIndicator,
    },
    props: {
        component: {
            type: Object,
            required: true,
        },
    },
    computed: {
        project() {
            if (!this.component) return null;
            return this.$store.getters.projectWithId(this.component.project);
        },
        stage() {
            if (!this.component) return null;
            return this.$store.getters.stageWithId(this.component.stage);
        },
    },
    methods: {
        gotoProject() {
            this.$router.push({
                name: 'project_detail',
                params: {
                    project_id: this.project.id,
                },
            });
        },
        gotoActivity() {
            this.$router.push({
                name: 'component_detail_activity',
                params: {
                    project_id: this.project.id,
                    stage_id: this.stage.id,
                    component_id: this.component.id,
                },
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.component-info.row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .col {
        display: flex;
        flex-direction: column;
        padding-right: 27px;

        .primary {
            font-size: 10px;
            transition: color 0.3s ease-in-out;
            display: flex;
            align-items: center;

            span.ref {
                width: 32px;
                flex-shrink: 0;
                font-weight: 800;
                font-size: 13px;

                &:hover {
                    color: $blue;
                    cursor: pointer;
                }
            }

            span.spacer {
                margin: 0 8px;
                opacity: 0.3;
            }

            span.project {
                font-size: 12px;

                &:hover {
                    color: $blue;
                    cursor: pointer;
                }
            }
        }

        .secondary {
            font-size: 10px;
            transition: color 0.3s ease-in-out;
            display: flex;
            align-items: center;

            span.stage {
                font-weight: 600;
            }

            span.stage {
                width: 32px;
                flex-shrink: 0;
            }

            span.spacer {
                margin: 0 8px;
                opacity: 0.3;
            }

            .component {
                &:hover {
                    color: $blue;
                    cursor: pointer;
                }

                span.ref {
                    font-size: 9px;
                    font-weight: bold;
                    text-transform: uppercase;
                    margin-right: 5px;
                    padding-right: 3px;
                    border-right: 1px solid $border-grey-light;
                }
            }
        }
    }
}

.slide {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s ease-in-out, transform 0.4s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateX(5px);
    }
}
</style>
