// import {AzureKeyCredential, OpenAIClient} from '@azure/openai';

const APX_CHARS_PER_TOKEN = 4;
const MAX_NUM_USER_TOKENS = 1000;
export const AI_MODES = {
    CATEGORISE: {
        name: 'CATEGORISE',
        displayName: 'Categorise',
        description:
            "Used to categorise text into 'development', 'design' or 'QA'",
        prompt:
            'Your job is to categorise a given task description as either a "DEV" task, ' +
            '"DESIGN" task, or "QA" task. ' +
            "If it is highly unclear how to categorise a given task, reply with 'UNSURE'. " +
            'You should only respond with single word answers: ' +
            "'DEV', 'DESIGN', or 'QA'." +
            'You should give no other explanation or words. ' +
            'The user will send an epic, a feature, and a task. ' +
            'The epic is a high-level grouping which represents the group of work the feature and child task belong to. ' +
            'The feature is a description of a component of work within the epic, and that the child task relates to. ' +
            'The task is a specific unit of work. ' +
            'The category you will provide belongs to the task, but the epic and feature may both help determine the category.',
        modelId: 'TOOLGPT-1',
        options: {maxTokens: 5, temperature: 0.1},
    },
    GENERATE_STORIES: {
        name: 'GENERATE_STORIES',
        displayName: 'Generate Stories',
        description: 'Generates user stories in line with described tasks',
        prompt:
            "Your job is to generate between 2 and 5 user stories based on the user's message." +
            'The user will send an epic, a feature, and a task. ' +
            'The epic is a high-level grouping which represents the group of work the feature and child task belong to. ' +
            'The feature is a description of a component of work within the epic, and that the child task relates to. ' +
            'The task is a specific unit of work. ' +
            'The stories you generate will relate directly to the task, but the feature and epic may help determine necessary tasks' +
            "Each story should be separated by a double line break, i.e. '\n\n'",
        modelId: 'TOOLGPT-1',
        options: {maxTokens: 200, temperature: 0.9},
    },
    GENERATE_TASKS: {
        name: 'GENERATE_TASKS',
        displayName: 'Generate Tasks',
        description:
            'Generates Dev, Design, and QA tasks for a high level task',
        prompt:
            'Your job is to generate ' +
            'development tasks, ' +
            'design tasks, ' +
            // "user stories, " +
            "and QA tasks based on the user's input. " +
            'You should generate no more than 5 tasks. ' + //todo: maybe make it generate fewer so that we don't have as much garbage in the response?
            'The user will send an epic, and a feature.' +
            'The epic is a high-level grouping which represents the group of work the feature and child task belong to. ' +
            'The feature is a description of a component of work within the epic, and that the tasks you generate will relate to. ' +
            "Each task should be separated by a double line break, i.e. '\n\n', and prefixed by its category in capital letters. " +
            'For example, the message "Bluetooth integration" might list the below tasks (as well as others):' +
            '\n\nDESIGN Create figma flow for bluetooth request' +
            '\n\nDEV Implement bluetooth request' +
            '\n\nDEV Bluetooth request screen' +
            '\n\nQA Test bluetooth connection' +
            '\n\nDEV Add logic to interact with bluetooth device',
        modelId: 'TOOLGPT-1',
        options: {maxTokens: 200, temperature: 0.9},
    },
    USER_PROMPT: {
        name: 'USER_PROMPT',
        displayName: 'User Prompt',
        description:
            'The prompt is blank. It is expected the user will determine the prompt.',
        modelId: 'TOOLGPT-1',
        options: {maxTokens: 200, temperature: 0.5},
    },
};

let client = null;
// try {
//     client = new OpenAIClient(
//         process.env.VUE_APP_AZURE_API_URI,
//         new AzureKeyCredential(process.env.VUE_APP_AZURE_API_KEY)
//     );
// } catch (e) {
//     console.error('Error initialising AI client.', e);
// }

const AIMixin = {
    methods: {
        /**
         * Get AI response for a requested input string
         * @param input : String - the user input string (request)
         * @param modeName : String - the name of the AI mode to use. {@see AI_MODES}
         * @returns {Promise<string[] | null>} list of responses from the AI. null on rejection
         */
        async requestSingleAIInteraction(input, modeName) {
            if (!client) {
                console.log(
                    'Cannot interact with the AI, the client was not set successfully'
                );
                return null;
            }

            if (input.length > MAX_NUM_USER_TOKENS * APX_CHARS_PER_TOKEN) {
                console.log('Too long, rejecting');
                return null;
            } else if (input.trim().length === 0) {
                console.log('No input sent, rejecting');
                return null;
            }
            const mode = AI_MODES[modeName];
            if (!mode) {
                console.log('Rejecting, invalid AI mode given:', mode);
                return null;
            }

            const aiInputs = [];
            if (mode.prompt) {
                const prompt = {
                    role: 'system',
                    content: mode.prompt,
                };
                aiInputs.push(prompt);
            }
            const userMessage = {role: 'user', content: input};

            aiInputs.push(userMessage);

            return this.getAIResponseFor(aiInputs, mode.modelId, mode.options);
        },

        async getAIResponseFor(inputs, modelId, options) {
            // return client
            //     .getChatCompletions(modelId, inputs, options)
            //     .then((result) =>
            //         result.choices.map((choice) => choice.message.content)
            //     )
            //     .catch((err) => {
            //         console.error('AI request resulted in error.', err);
            //         throw err;
            //     });
        },
    },
};

export default AIMixin;
