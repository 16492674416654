<template>
    <div
        class="subtask-container"
        :class="{
            invalid: is_invalid,
            excluded: render_excluded,
            expanded: show_notes,
        }"
    >
        <el-form ref="subtask_form" :model="form">
            <el-row :gutter="5">
                <el-col :span="desc_width">
                    <div class="row description">
                        <div v-if="is_estimate_draft" class="subtaskGrip">
                            ⣿
                        </div>
                        <svgicon
                            v-if="jira_category_options.length === 1"
                            :name="ISSUE_ICONS[form.jira_category]"
                            class="jira-icon"
                        />
                        <div v-else>
                            <el-dropdown
                                trigger="click"
                                class="el-dropdown-link"
                                placement="bottom"
                                @command="changeCategory"
                            >
                                <div>
                                    <svgicon
                                        :name="ISSUE_ICONS[form.jira_category]"
                                        class="jira-icon"
                                    />
                                    <div
                                        v-if="
                                            form.jira_category ===
                                            JIRA_CATEGORIES.DEFAULT
                                        "
                                        v-loading="detecting"
                                        class="spinner"
                                        element-loading-spinner="el-icon-loading"
                                        element-loading-background="rgba(0, 0, 0, 0)"
                                    />
                                </div>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item
                                            v-for="value in jira_category_options"
                                            :key="value"
                                            :command="value"
                                            style="
                                                display: flex;
                                                flex-direction: row;
                                                align-items: center;
                                            "
                                        >
                                            <svgicon
                                                :name="ISSUE_ICONS[value]"
                                                width="20px"
                                                height="20px"
                                                style="margin-right: 5px;"
                                            />
                                            <span>{{ value }}</span>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </div>
                        <el-input
                            ref="description"
                            v-model="form.description"
                            class="input-description"
                            :class="{
                                empty: isEmpty(form.description),
                            }"
                            size="small"
                            placeholder="Subtask description"
                            :readonly="readonly"
                            @change="autoDetectCategory"
                            @keyup.enter.native="
                                () => enterOnField('description')
                            "
                        >
                            <el-tooltip slot="suffix" placement="top">
                                <div slot="content">
                                    <strong>Subtasks</strong> define individual
                                    work units for a task.<br /><br />
                                    Subtasks are converted to
                                    <strong>Jira subtasks</strong><br />when the
                                    estimate is accepted.
                                </div>
                                <span class="info-icon">?</span>
                            </el-tooltip>
                        </el-input>
                        <div
                            v-if="!readonly || has_notes"
                            class="notes-button"
                            :class="{
                                visible: show_notes,
                                content: has_notes,
                            }"
                            @click="toggleShowNotes"
                        >
                            <i
                                :class="
                                    has_notes
                                        ? 'el-icon-document'
                                        : 'el-icon-document-add'
                                "
                            />
                        </div>
                    </div>
                </el-col>

                <!-- MIN -->
                <el-col v-if="is_estimate_draft" :span="2">
                    <div class="row">
                        <el-input-number
                            ref="min"
                            v-model="form.min"
                            class="input-value"
                            :class="{
                                empty: isEmpty(form.min),
                            }"
                            :controls="false"
                            size="small"
                            placeholder="min"
                            :min="0"
                            @change="handleBlurMinMax"
                            @focus="selectInput"
                            @keyup.enter.native="() => enterOnField('min')"
                        />
                    </div>
                </el-col>

                <!-- MAX -->
                <el-col v-if="is_estimate_draft" :span="2">
                    <div class="row">
                        <el-input-number
                            ref="max"
                            v-model="form.max"
                            class="input-value"
                            :class="{
                                empty: isEmpty(form.max),
                            }"
                            :controls="false"
                            size="small"
                            placeholder="max"
                            :min="0"
                            @change="handleBlurMinMax"
                            @focus="selectInput"
                            @keyup.enter.native="() => enterOnField('max')"
                        />
                    </div>
                </el-col>

                <!-- QTY / INITIAL -->
                <el-col :span="2">
                    <div class="row">
                        <el-input-number
                            ref="qty"
                            v-model="form.qty"
                            class="input-value"
                            :class="{
                                empty: isEmpty(form.qty),
                            }"
                            :controls="false"
                            size="small"
                            placeholder="qty"
                            :min="0"
                            @change="handleBlurQty"
                            @focus="selectInput"
                            @keyup.enter.native="() => enterOnField('qty')"
                        />
                    </div>
                </el-col>

                <!-- FINAL -->
                <el-col v-if="!is_estimate_draft" :span="2">
                    <div class="row">
                        <el-input-number
                            ref="input_override_qty"
                            v-model="override.qty"
                            class="input-value"
                            :precision="2"
                            :controls="false"
                            size="small"
                            :min="0"
                            :placeholder="parseFloat(form.qty).toFixed(2)"
                            @focus="selectInput"
                            @keyup.enter.native="
                                () => enterOnField('input_override_qty')
                            "
                        />
                    </div>
                </el-col>

                <!-- HOURS -->
                <el-col v-if="is_days" :span="2">
                    <div class="row">
                        <span
                            class="el-input__inner input-value read-only"
                            :class="{adjusted: isNumeric(adjustment)}"
                        >
                            <span>
                                {{ adjusted_hours | hours2duration }}
                            </span>
                            <span
                                v-if="isNumeric(adjustment)"
                                class="adjustment"
                                :class="{negative: hours_difference < 0}"
                            >
                                ({{ hours_difference | hours2duration }})
                            </span>
                        </span>
                    </div>
                </el-col>

                <!-- ACTIONS -->
                <el-col :span="options_width">
                    <div v-if="!readonly && can_delete" class="options-col">
                        <div
                            v-if="is_dirty"
                            v-loading="true"
                            class="option-icon spinner"
                            element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(0, 0, 0, 0)"
                        />
                        <div
                            v-else
                            class="option-icon delete"
                            :class="{disabled: !can_delete}"
                            @click="handleDelete"
                        >
                            <i class="el-icon-close" />
                        </div>
                    </div>
                    <div
                        v-else-if="!is_estimate_locked"
                        class="options-col right"
                    >
                        <div
                            class="exclude-icon"
                            :class="{
                                excluded: is_excluded,
                            }"
                            @click="handleExclude"
                        >
                            <i class="el-icon-circle-close" />
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div
                v-if="show_notes"
                class="notes"
                :class="{draft: is_estimate_draft}"
            >
                <!-- code block and customised links are disabled in subtask notes to simplify parsing for jira -->
                <text-editor
                    theme="dark"
                    :editable="!readonly"
                    :code_block="false"
                    :fancy_links="false"
                    :content.sync="form.notes"
                    @update:content="handleUpdateNotes"
                />
            </div>
        </el-form>
    </div>
</template>

<script>
import {VMoney} from 'v-money';

import aiMixin, {AI_MODES} from '@/mixins/ai.mixin';
import estimateMixin from '@/mixins/estimate.mixin';

import {isNumeric} from '@/utils';
import debouncePromise from '@/utils/debouncePromise';

import {ESTIMATE_TYPES, ISSUE_CATEGORIES, ISSUE_ICONS} from '@/enums';
import TextEditor from '@/components/editor/TextEditor';

export default {
    name: 'estimate-subtask',
    components: {TextEditor},
    directives: {money: VMoney},
    mixins: [estimateMixin, aiMixin],
    props: {
        estimate: {
            type: Object,
            required: true,
        },
        group: {
            type: Object,
            default: null,
        },
        task: {
            type: Object,
            required: true,
        },
        subtask: {
            type: Object,
            required: true,
        },
        can_delete: {
            type: Boolean,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                description: '',
                min: null,
                max: null,
                qty: null,
                jira_category: ISSUE_CATEGORIES.DEFAULT,
                notes: null,
            },
            override: {
                qty: undefined,
            },
            detecting: false,
            show_notes: false,
            is_dirty: false,
        };
    },
    computed: {
        ISSUE_ICONS() {
            return ISSUE_ICONS;
        },
        JIRA_CATEGORIES() {
            return ISSUE_CATEGORIES;
        },
        jira_category_options() {
            switch (this.estimate.type) {
                case ESTIMATE_TYPES.EXPLORE:
                    return [ISSUE_CATEGORIES.ACTION];
                case ESTIMATE_TYPES.CREATE:
                    return [
                        ISSUE_CATEGORIES.DEV,
                        ISSUE_CATEGORIES.DESIGN,
                        ISSUE_CATEGORIES.QA,
                    ];
                default:
                    return [ISSUE_CATEGORIES.DEFAULT];
            }
        },
        is_days() {
            return this.estimate.unit === 'day';
        },
        desc_width() {
            if (!this.is_estimate_draft) {
                return this.is_days ? 15 : 17;
            }
            return this.is_days ? 14 : 16;
        },
        options_width() {
            return this.is_estimate_pending ? 3 : 2;
        },
        has_dirty_override() {
            if (
                this.subtask.override?.qty === null &&
                this.override.qty === undefined
            )
                return false;
            if (!this.subtask.override?.qty && this.override.qty) return true;
            return this.subtask.override?.qty !== this.override.qty;
        },
        is_invalid() {
            // During DRAFT state:
            // - Description has to be valid
            if (!this.has_valid_description) return true;
            return false;
        },
        is_excluded() {
            return this.subtask.excluded;
        },
        render_excluded() {
            return (
                !this.is_estimate_draft &&
                (this.is_excluded || this.task.excluded || this.group.excluded)
            );
        },
        has_valid_description() {
            return !!this.form.description;
        },
        hours() {
            return this.subtask.qty * this.task.hours_per_day;
        },
        adjustment() {
            if (this.is_estimate_draft || !isNumeric(this.override?.qty))
                return null;
            if (this.subtask.qty === 0) return this.override.qty;
            return this.override.qty / this.subtask.qty;
        },
        adjusted_hours() {
            if (this.is_estimate_draft || !isNumeric(this.override?.qty))
                return this.hours;
            if (this.hours === 0) return this.adjustment;
            return this.hours * this.adjustment;
        },
        hours_difference() {
            if (isNumeric(this.adjustment)) {
                return this.adjusted_hours - this.hours;
            }
            return 0;
        },
        has_notes() {
            return !!this.form.notes;
        },
    },
    watch: {
        subtask: {
            handler(val) {
                if (val.id) this.form.id = val.id;
                this.form.description = val.description;
                this.form.min = val.min;
                this.form.max = val.max;
                this.form.qty = val.qty;
                this.form.rate = val.rate;
                this.form.jira_category = val.jira_category;
                this.form.notes = val.notes;
            },
            immediate: true,
        },
        form: {
            handler() {
                this.dirtyCheck();
                this.saveItem();
            },
            deep: true,
        },
        'override.qty'() {
            this.dirtyCheck();
            this.saveItem();
        },
        'subtask.override': {
            handler(newVal, oldVal) {
                // Override values:
                if (newVal?.qty !== oldVal?.qty) {
                    this.override.qty = newVal.qty ?? undefined;
                }
            },
            immediate: true,
        },
        readonly(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.setReadOnlyInputs();
            }
        },
        'estimate.status'() {
            this.setReadOnlyInputs();
        },
    },
    mounted() {
        this.setReadOnlyInputs();
    },
    created() {
        this.saveItem = debouncePromise(this.saveItem, 500);
    },
    methods: {
        dirtyCheck() {
            if (
                this.subtask.description !== this.form.description ||
                this.subtask.jira_category !== this.form.jira_category ||
                this.subtask.min !== this.form.min ||
                this.subtask.max !== this.form.max ||
                this.subtask.qty !== this.form.qty ||
                this.subtask.rate !== this.form.rate ||
                this.has_dirty_override
            ) {
                this.is_dirty = true;
            }
        },
        isNumeric,
        handleBlurMinMax() {
            if (this.form.min != null && this.form.max != null) {
                // Recalculate qty
                this.form.qty = (this.form.min + this.form.max) / 2;
            }

            this.saveItem();
        },
        handleBlurQty() {
            if (this.form.qty != null && this.form.qty !== this.subtask.qty) {
                // If qty set, clear min & max
                this.form.min = null;
                this.form.max = null;
            }
            this.saveItem();
        },
        async saveItem(force) {
            if (this.is_dirty || force) {
                this.$emit('save', {
                    ...this.subtask,
                    ...this.form,
                    override: {
                        qty: this.override.qty ?? null,
                    },
                    is_invalid: this.is_invalid,
                });
                this.is_dirty = false;
                return new Promise((res) => setTimeout(res, 200));
            }
        },
        handleDelete() {
            if (this.is_estimate_draft) {
                this.$emit('delete', this.subtask.id);
            }
        },
        handleExclude() {
            if (this.is_estimate_pending) {
                this.$emit('exclude', this.subtask.id);
            }
        },
        isEmpty(value) {
            return !value && value !== 0;
        },
        setReadOnlyInputs() {
            // Make up for missing 'readonly' prop on el-input-number, set natively
            if (this.$refs.min)
                this.$refs.min.$el.querySelector('input').readOnly =
                    this.readonly || !this.is_estimate_draft;
            if (this.$refs.max)
                this.$refs.max.$el.querySelector('input').readOnly =
                    this.readonly || !this.is_estimate_draft;
            if (this.$refs.qty)
                this.$refs.qty.$el.querySelector('input').readOnly =
                    this.readonly || !this.is_estimate_draft;
            if (this.$refs.input_override_qty)
                this.$refs.input_override_qty.$el.querySelector(
                    'input'
                ).readOnly = this.readonly;
        },
        enterOnField(ref) {
            this.saveItem().then((res) => {
                this.$emit('nextSubtask', this.subtask.id, ref);
            });
        },
        focus(field) {
            if (field) {
                const fieldRef = this.$refs[field];
                if (fieldRef) {
                    fieldRef.focus();
                    return;
                }
            }
            this.$refs.description.focus();
        },
        selectInput(event) {
            setTimeout(() => {
                event.target.select();
            }, 50);
        },
        changeCategory(label) {
            this.form.jira_category = label;
            this.saveItem(false);
        },
        autoDetectCategory: function () {
            if (
                this.form.description === null ||
                this.form.description === '' ||
                this.form.jira_category !== ISSUE_CATEGORIES.DEFAULT
            ) {
                return;
            }
            const epic = this.group.title.trim();
            const feature = this.task.description.trim();
            const inputString = `Epic: ${epic}. Feature: ${feature}. Task: ${this.form.description}`;

            this.detecting = true;

            this.requestSingleAIInteraction(
                inputString,
                AI_MODES.CATEGORISE.name
            )
                .then((responses) => {
                    if (responses) {
                        if (responses.length === 1) {
                            const result = responses[0].toUpperCase();
                            switch (result) {
                                case 'DEV':
                                case 'DESIGN':
                                case 'QA':
                                    this.form.jira_category =
                                        ISSUE_CATEGORIES[result];
                                    return;
                                default:
                                    this.form.jira_category =
                                        ISSUE_CATEGORIES.DEV;
                                    return;
                            }
                        }
                    }

                    // fallthrough sets to DEV instead of default
                    this.form.jira_category = ISSUE_CATEGORIES.DEV;
                    this.saveItem(false);
                })
                .catch((e) => {
                    console.warn(e);
                })
                .finally(() => {
                    this.detecting = false;
                });
        },
        handleUpdateNotes() {
            this.is_dirty = true;
            this.saveItem(true);
        },
        toggleShowNotes() {
            this.show_notes = !this.show_notes;
        },
    },
};
</script>

<style lang="scss" scoped>
.subtask-container {
    padding: 2px;
    transition: background-color 0.1s linear;
    height: 32px;
    border-radius: 6px;

    &:first-of-type {
        margin-top: 36px;
    }

    &.invalid {
        background-color: $red-background;

        ::v-deep input {
            color: $red;
        }
    }

    &.excluded {
        opacity: 0.5;
        position: relative;

        &::after {
            display: block;
            width: calc(100% - 50px);
            height: 1px;
            background: $black;
            content: '';
            position: absolute;
            top: 18px;
            left: 16px;
            opacity: 0.6;
        }

        &.quoted::after {
            width: calc(100% - 12px);
        }
    }

    &.expanded {
        height: auto;
    }

    ::v-deep .el-input.input-description input {
        border: none;
    }

    &:not(.invalid) .empty ::v-deep input {
        border-color: $orange-soft;
    }
}

.row {
    position: relative;
}

::v-deep .input-value {
    width: 100%;

    &.read-only,
    &.read-only input,
    input:read-only {
        background: transparent;
        color: #a0a0a0;
        pointer-events: none;
        border: none;
    }

    input {
        padding-left: 5px !important;
        padding-right: 5px !important;
        text-align: center;
    }
}

.el-input__inner {
    height: 32px;
    font-size: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    .small {
        font-size: 11px;
    }

    &.bold {
        font-weight: bold;
    }
}

.options-col {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .option-icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid $border-grey-light;

        &.delete {
            cursor: pointer;

            i {
                opacity: 0.3;
            }

            &:hover:not(.disabled) {
                border: 1px solid $red;
                box-shadow: 0 0 0 1px $red;

                i {
                    opacity: 1;
                    color: $red;
                }
            }

            &.disabled {
                cursor: not-allowed;
            }
        }
    }
}

.description {
    display: flex;
    align-items: center;
    padding-left: 20px;

    .subtaskGrip {
        width: 10px;
        height: 23px;
        text-align: center;
        opacity: 0.25;
        user-select: none;
    }

    .jira-icon {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        margin-left: 6px;
        box-sizing: border-box;
    }

    .info-icon {
        width: 14px;
        height: 14px;
        font-size: 12px;
        font-weight: normal;
        color: black;
        border: 1px solid black;
        border-radius: 50%;
        margin-top: 8px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.2;
    }

    .notes-button {
        margin-left: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.2;

        &.content {
            opacity: 0.4;
        }

        &.visible {
            opacity: 0.6;
        }

        &:hover {
            opacity: 1;
        }
    }
}

::v-deep .notes {
    font-size: 13px;
    margin-top: 3px;
    padding-left: 56px;
    padding-right: 5px;

    .ProseMirror {
        border: none !important;
    }

    &.draft {
        padding-left: 60px;
        padding-right: 0;
    }

    p {
        margin: 0.5em;
    }
}

.itemSortable .subtaskGrip {
    cursor: grab;
}

.dragging {
    background: $grey;
}

.adjusted {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 5px;

    > span {
        width: 50%;
        text-align: center;
        line-height: initial;
    }
}

@media screen and (max-width: 1300px) {
    .adjusted {
        flex-direction: column;

        > span {
            display: block;
            width: 100%;

            &.adjustment {
                font-size: 11px;
            }
        }
    }
}

.adjustment {
    color: $green;

    &.negative {
        color: rgba($red-soft, 0.5);
    }
}

.exclude-icon {
    opacity: 0.2;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(.disabled):hover {
        opacity: 1;
        color: $red-soft;
    }

    &.excluded {
        opacity: 1;
        color: $red;
    }
}

.el-dropdown-link {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 20px;

    ::v-deep .spinner {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        transform: scale(0.8);

        .el-loading-spinner i {
            color: $black;
        }
    }
}
</style>
