<template>
    <node-view-wrapper class="link">
        <pretty-link v-if="!editing" :link="node.attrs.url"></pretty-link>
        <el-input
            v-else
            v-model="text"
            size="mini"
            @keyup.enter.native="saveContent"
        />
    </node-view-wrapper>
</template>

<script>
import {NodeViewWrapper} from '@tiptap/vue-2';

import PrettyLink from '@/components/links/PrettyLink';

export default {
    name: 'link-node',
    components: {
        NodeViewWrapper,
        PrettyLink,
    },
    props: {
        node: {
            type: Object,
            required: true,
        },
        updateAttributes: {
            type: Function,
            default: () => {},
        },
        deleteNode: {
            type: Function,
            default: () => null,
        },
        getPos: {
            type: Function,
            default: () => {},
        },
        selected: {
            type: Boolean,
        },
        extension: {
            type: Object,
            default: () => {},
        },
        editor: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            editing: false,
            text: '',
        };
    },

    computed: {
        can_edit() {
            return this.editor.options.editable;
        },
    },

    methods: {
        toggleEdit() {
            if (!this.can_edit) return;

            this.editing = !this.editing;

            if (this.editing) {
                this.text = this.node.attrs.url;
            }
        },

        saveContent() {
            this.editing = false;
            this.updateAttributes({url: this.text});
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.link {
    display: inline-block;
    position: relative;

    a {
        color: $black;
        text-decoration: none;
        border-radius: 4px;
        font-size: 14px;
    }

    &:hover {
        .hover-menu {
            display: inline-block;
        }
    }

    .hover-menu {
        display: none;
        left: 100%;
        top: 0;
        padding-left: 4px;
        padding-right: 4px;

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            padding: 2px 10px;
            border-radius: 4px;
            border: 1px solid $border-grey-light;
            box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
        }
    }
}
</style>
