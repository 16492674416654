<template>
    <div class="dump">
        <div class="block">
            <el-button @click="dumpSessions">Dump sessions</el-button>
        </div>
        <div class="block_options">
            <project-picker
                :projects="projects"
                :project="dump_sessions.project"
                @change="changedProject"
            />
        </div>
        <div v-if="dump_sessions.project" class="block_options">
            <stage-picker
                :project_id="dump_sessions.project.id"
                :stage="dump_sessions.stage"
                @change="
                    (val) => {
                        dump_sessions.stage = val;
                    }
                "
            />
        </div>
        <div v-if="dump_sessions.stage" class="block_options">
            <el-date-picker
                v-model="dump_sessions.from_date"
                type="date"
                size="mini"
                :clearable="false"
                format="dd/MM/yyyy"
            />
            <el-date-picker
                v-model="dump_sessions.to_date"
                type="date"
                size="mini"
                :clearable="false"
                format="dd/MM/yyyy"
            />
        </div>
        <div
            v-if="dump"
            id="block_dump"
            class="block_dump"
            @click="copyContentsToClipboard"
        >
            <span v-for="day in Object.keys(dump)" :key="day">
                <b>{{ day }}</b>
                <ul>
                    <li
                        v-for="(session, idx) in dump[day]"
                        :key="`${idx}_${day}`"
                    >
                        <span v-html="session" />
                    </li>
                </ul>
            </span>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {momentWithTz} from '@/utils';

import {Notification} from 'element-ui';

import ProjectPicker from '@/components/generic/ProjectPicker';
import StagePicker from '@/components/generic/StagePicker';

export default {
    name: 'dump-sessions',
    components: {
        ProjectPicker,
        StagePicker,
    },
    data() {
        return {
            dump_sessions: {
                project: null,
                from_date: null,
                to_date: null,
                stage: null,
            },
            dump: null,
        };
    },
    computed: {
        projects() {
            return this.$store.getters.projects;
        },
    },
    methods: {
        changedProject(val) {
            this.dump_sessions.project = val;
            this.dump_sessions.stage = null;
            this.dump = null;
        },
        async dumpSessions() {
            Notification({
                type: 'info',
                title: 'Fetching',
                message: 'sessions to dump...',
            });

            this.dump = null;

            try {
                const range_start = momentWithTz(this.dump_sessions.from_date)
                    .startOf('day')
                    .toDate();

                const range_end = momentWithTz(this.dump_sessions.to_date)
                    .endOf('day')
                    .toDate();

                await this.findSessions(
                    this.dump_sessions.stage,
                    range_start,
                    range_end
                );

                Notification({
                    type: 'success',
                    title: 'Done',
                });
            } catch (err) {
                console.log('Error dumping sessions: ', err);

                Notification({
                    type: 'error',
                    title: 'Error dumping sessions',
                });
            }
        },

        async findSessions(stage, range_start, range_end) {
            const sessionsRef = await this.$fire
                .collection('sessions')
                .where('stage', '==', this.$fire.doc(`stages/${stage.id}`))
                .where('start', '>=', range_start)
                .where('start', '<=', range_end)
                .get();

            const groups = {};

            sessionsRef.forEach((doc) => {
                const sess = doc.data();
                const dayLabel = momentWithTz(moment.unix(sess.start.seconds))
                    .startOf('day')
                    .format('dddd DD/MMMM');

                if (!Object.keys(groups).includes(dayLabel)) {
                    groups[dayLabel] = [];
                }

                const session_items = [];
                if (!sess.bound_tasks) {
                    session_items.push(sess.note);
                } else {
                    sess.bound_tasks.forEach((task) => {
                        const path = task?.path ?? task;
                        const issue = this.$store.getters.issueWithDocumentPath(
                            path
                        );
                        session_items.push(`${issue.key}: ${issue.summary}`);
                    });
                }
                // add unique items for the day
                session_items.forEach((item) => {
                    if (item && !groups[dayLabel].includes(item)) {
                        groups[dayLabel].push(item);
                    }
                });
            });
            this.dump = groups;
        },

        urlify(value) {
            const regex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
            // step 1: replace URLs, with boundary markers
            console.log('urlify', value);
            const valueWithUrls = value.replace(regex, (url) => {
                let label = url;
                if (url.includes('atlassian')) {
                    // just display the jira ticket number
                    const parts = url.split('/');
                    label = parts[parts.length - 1];
                }
                return `[url]<a href="${url}">${label}</a>[url]`;
            });
            // step 2: split on boundary markers and clean non-url portions of string for safety
            const splitString = valueWithUrls.split('[url]').map((fragment) => {
                if (fragment.startsWith('<a href')) return fragment;
                return this.htmlEncode(fragment);
            });
            // step 3: return recombined string
            return splitString.join('');
        },

        htmlEncode(input) {
            return input
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;');
        },

        copyContentsToClipboard() {
            const type = 'text/html';
            const blob = new Blob(
                [document.getElementById('block_dump').innerHTML],
                {type}
            );
            const data = [new ClipboardItem({[type]: blob})];
            navigator.clipboard.write(data);
            Notification({
                type: 'success',
                title: 'Copied',
                message: 'Session dump copied to clipboard',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.dump {
    width: 100%;

    .block_options {
        width: 410px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;

        & > * {
            margin-right: 5px;
            width: 200px;
        }
    }

    .block_dump {
        width: 100%;
        background: white;
        padding: 5px 10px;
        border-radius: 6px;
        border: 1px solid $border-grey;
        &:hover {
            border: 1px solid $blue;
        }
    }
}
</style>
