<template>
    <div>
        <repo-tree-node
            v-for="(branch, idx) of related_pull_requests"
            :key="idx"
            :show_header="idx === 0"
            :node="branch"
            :environments="environments"
        />
    </div>
</template>

<script>
import RepoTreeNode from '@/components/modules/RepoTreeNode';

export default {
    name: 'module-tab-buildtree',
    components: {
        RepoTreeNode,
    },
    props: {
        environments: {
            type: Array,
            required: true,
        },
        related_pull_requests: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped></style>
