<template>
    <div class="status" :class="type">
        <label>{{ label }}</label>

        <span class="row">
            <span>{{ sign }}</span>
            {{ time | milli2duration }}
            <span class="small">
                <template v-if="show_calculations">
                    (
                    <span
                        v-for="(calculation, idx) of filtered_calculations"
                        :key="idx"
                        :class="{
                            offset: calculation.offset,
                            success:
                                calculation.offset && calculation.value > 0,
                            danger: calculation.offset && calculation.value < 0,
                        }"
                        :style="{color: calculation.color}"
                    >
                        <el-tooltip
                            :content="calculation.label"
                            placement="top"
                        >
                            <span>
                                <span v-if="calculation.value < 0"> - </span>
                                <span v-else-if="idx > 0">
                                    +
                                </span>
                                {{
                                    calculation.value
                                        | milli2duration
                                        | replace('-', '')
                                }}
                            </span>
                        </el-tooltip>
                    </span>
                    )
                </template>
            </span>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        time: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            default: '',
        },
        sign: {
            type: String,
            default: '',
        },
        calculations: {
            type: Array,
            default: () => [],
        },
        show_calculations: {
            type: Boolean,
            default: false,
        },
        exclude_zero_calculations: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        filtered_calculations() {
            if (!this.exclude_zero_calculations) return this.calculations;
            return this.calculations.filter(
                (calc) => calc.value > 0 || calc.value < 0
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.status {
    display: flex;
    flex-direction: column;

    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid $border-grey-light;

    &.danger {
        color: $red;
        font-weight: bold;
    }

    &.success {
        color: $green;
        font-weight: bold;
    }

    label {
        font-size: 10px;
    }

    span {
        font-weight: bold;

        &.row {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &.small {
            font-weight: normal;
            font-size: 10px;
            margin-left: 5px;
        }

        &.danger {
            color: $red;
        }

        &.success {
            color: $green-dark;
        }
    }
}
</style>
