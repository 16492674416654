<template>
    <div class="outer">
        <header>
            <el-input
                v-model="search"
                placeholder="Search projects"
                size="mini"
            />
            <el-button size="mini" @click="addProject">
                New
            </el-button>
        </header>

        <div class="inner">
            <ul>
                <li
                    v-for="project in filtered_projects"
                    :key="project.id"
                    @click="() => navigateToProject(project)"
                >
                    <span>
                        <strong>{{ project.ref }}</strong> -
                        {{ project.name }}
                    </span>
                    <svgicon name="triangle" class="triangle" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'management-projects',
    data() {
        return {
            search: '',
        };
    },
    computed: {
        projects() {
            return this.$store.getters.projectsSorted('name');
        },
        filtered_projects() {
            if (this.search.length) {
                return this.projects.filter((i) => {
                    const search = this.search.toLowerCase();
                    return (
                        i.name.toLowerCase().includes(search) ||
                        i.ref.toLowerCase().includes(search)
                    );
                });
            }

            return this.projects;
        },
    },
    methods: {
        addProject() {
            this.$router
                .push({
                    name: 'management_project_new',
                })
                .catch((err) => {});
        },
        navigateToProject(project) {
            this.$router
                .push({
                    name: 'management_project_detail',
                    params: {
                        project_id: project.id,
                    },
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
        box-sizing: border-box;
        height: 65px;
        padding: 10px 20px;
        border-bottom: 1px solid $border-grey-light;
        display: flex;
        user-select: none;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .el-input {
            width: 200px;
        }
    }

    .inner {
        overflow: scroll;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;

                &:hover {
                    svg {
                        display: block;
                    }
                }

                span {
                    flex: 1;
                    user-select: none;
                }

                svg {
                    width: 6px;
                    display: none;
                    opacity: 0.1;
                }
            }
        }
    }
}
</style>
