<template>
    <section
        v-if="
            highlighted_recurring_components.length +
            highlighted_non_recurring_components.length
        "
        class="highlighted-components-container"
    >
        <recurring-component-block
            v-for="component in highlighted_recurring_components"
            :key="`recurring_${component.id}`"
            :component="component"
        />
        <component-block
            v-for="component in highlighted_non_recurring_components"
            :key="`${component.id}`"
            :component="component"
        />
    </section>
</template>

<script>
import ComponentBlock from '@/components/blocks/ComponentBlock';
import RecurringComponentBlock from '@/components/blocks/RecurringComponentBlock';

import projectMixin from '@/mixins/project.mixin';

export default {
    name: 'highlighted-components-block',
    components: {
        RecurringComponentBlock,
        ComponentBlock,
    },
    mixins: [projectMixin],
    computed: {
        highlighted_recurring_components() {
            return this.recurring_components.filter((c) => c.highlight);
        },
        highlighted_non_recurring_components() {
            return this.non_recurring_components.filter((c) => c.highlight);
        },
    },
};
</script>

<style lang="scss" scoped>
.highlighted-components-container {
    padding: 0;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    @media screen and (max-width: 992px) {
        .component {
            width: 100%;
            margin-right: 0;
        }
    }

    .component-container {
        max-width: unset;
    }
}
</style>
