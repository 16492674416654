<template>
    <div class="container">
        <label>Bitbucket</label>

        <div class="right">
            <el-button
                v-if="loading"
                v-loading="true"
                disabled
                class="spinner"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0)"
            >
                <i class="el-icon-loading" style="visibility: hidden;" />
            </el-button>
            <template v-else>
                <el-button
                    v-if="!$store.getters.is_bitbucket_authed"
                    :disabled="loading"
                    @click="redirectToBitbucketAuth"
                >
                    Connect
                </el-button>
                <template v-else>
                    <el-button disabled>
                        Connected
                    </el-button>
                    <el-button
                        style="flex-grow: inherit;"
                        @click="handleDisconnectBitbucket"
                    >
                        <i class="el-icon-delete" />
                    </el-button>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import userMixin from '@/mixins/user.mixin';
import {
    disconnectBitbucket,
    redirectUserToBitbucket,
} from '@/api/bitbucket-auth';

export default {
    name: 'bitbucket-integration',
    components: {},
    mixins: [userMixin],
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        recheckKey() {
            return this.$store.dispatch('fetchBitbucketKeyExists');
        },
        async redirectToBitbucketAuth() {
            this.loading = true;
            redirectUserToBitbucket()
                .then((result) => {
                    window.location = result;
                })
                .catch((e) => {})
                .finally(() => {
                    this.loading = false;
                });
        },
        async handleDisconnectBitbucket() {
            this.loading = true;

            await disconnectBitbucket();
            await this.recheckKey();

            this.loading = false;
        },
    },
};
</script>
