<template>
    <content-block title="Create Event">
        <el-form label-width="160px">
            <el-form-item label="Event Type">
                <el-select v-model="selectedType">
                    <el-option
                        v-for="type of availableTypes"
                        :key="type.id"
                        :value="type.id"
                        :label="type.label"
                    >
                        <div class="event-type-option">
                            <div
                                class="color-preview"
                                :style="`background-color: ${type.color}`"
                            />
                            <span>{{ type.label }}</span>
                        </div>
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item v-if="showProject" label="Project">
                <project-dropdown v-model="selectedProjectId" />
            </el-form-item>
            <el-form-item label="Content">
                <el-input v-model="content" maxlength="70" show-word-limit />
            </el-form-item>
            <el-row>
                <el-col :md="8">
                    <el-form-item
                        :label="showDateRange ? 'Start Date' : 'Date'"
                    >
                        <el-date-picker
                            v-model="startDate"
                            @change="validateEndDate"
                        />
                    </el-form-item>
                </el-col>
                <el-col v-if="showDateRange" :md="8">
                    <el-form-item label="End Date">
                        <el-date-picker
                            v-model="endDate"
                            :picker-options="{disabledDate: isInvalidEndDate}"
                            :placeholder="formattedStartDate"
                            :default-value="startDate"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item>
                <el-switch
                    v-model="external"
                    active-text="External"
                    inactive-text="Internal"
                />
            </el-form-item>
            <el-form-item>
                <div v-if="event" class="update-buttons">
                    <el-button
                        style="width: 200px;"
                        :disabled="invalid"
                        @click="updateEvent"
                    >
                        Save Event
                    </el-button>
                    <el-popconfirm
                        confirm-button-text="OK"
                        cancel-button-text="No, thanks"
                        icon="el-icon-info"
                        icon-color="red"
                        title="Are you sure you want to delete this?"
                        @confirm="deleteEvent"
                    >
                        <el-button
                            slot="reference"
                            type="danger"
                            plain
                            style="width: 200px;"
                        >
                            Delete Event
                        </el-button>
                    </el-popconfirm>
                </div>
                <el-button
                    v-else
                    style="width: 200px;"
                    :disabled="invalid"
                    @click="addEvent"
                >
                    Add Event
                </el-button>
            </el-form-item>
        </el-form>
    </content-block>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import ProjectDropdown from '@/components/generic/ProjectDropdown';
import {momentWithTz} from '@/utils';
import {fireRef2id} from '@/filters';

export default {
    components: {ContentBlock, ProjectDropdown},
    data() {
        return {
            event: null,
            selectedType: null,
            content: null,
            startDate: new Date(),
            endDate: null,
            external: false,
            selectedProjectId: null,
        };
    },
    computed: {
        types() {
            return this.$route.params.types || [];
        },
        availableTypes() {
            if (this.types.length > 0) {
                return this.$store.getters
                    .eventTypes()
                    .filter((type) => this.types.includes(type.type));
            }

            return this.$store.getters.eventTypes();
        },
        showProject() {
            const type = this.getEventType(this.selectedType);
            return (
                !this.$route.params.project_id && type && type.linked !== false
            );
        },
        showDateRange() {
            const type = this.getEventType(this.selectedType);
            return !type || (type && type.range !== false);
        },
        formattedStartDate() {
            if (!this.startDate) return '';
            return momentWithTz(this.startDate).format('YYYY-MM-DD');
        },
        invalid() {
            return this.selectedType === null || !this.content;
        },
    },
    watch: {
        selectedType(t) {
            const type = this.getEventType(t);
            // reset values if type does not support them
            if (type.range === false) {
                this.endDate = null;
            }
            if (type.linked === false) {
                this.selectedProjectId = null;
            }
        },
    },
    async mounted() {
        if (this.$route.params.event_id) {
            // editing an existing event
            await this.$bind(
                'event',
                this.$fire.doc(`events/${this.$route.params.event_id}`),
                {
                    maxRefDepth: 0,
                }
            );

            this.selectedType = fireRef2id(this.event.type);
            this.selectedProjectId = fireRef2id(this.event.project);
            this.content = this.event.content;
            this.startDate = this.event.start.toDate();
            this.endDate = this.event.end.toDate();
            this.external = this.event.external ?? false;
        } else {
            // creating a new event
            if (this.$route.params.project_id) {
                this.selectedProjectId = this.$route.params.project_id;
            }

            if (this.$route.params.startDate) {
                this.startDate = this.$route.params.startDate;
                if (this.$route.params.endDate) {
                    this.endDate = this.$route.params.endDate;
                }
            }
        }
    },
    methods: {
        getEventType(id) {
            return this.availableTypes.find((t) => t.id === id);
        },
        validateEndDate() {
            // if end date is before start date, set to start date
            if (this.isInvalidEndDate(this.endDate)) {
                this.endDate = null;
            }
        },
        isInvalidEndDate(d) {
            if (!this.startDate) return false;
            return momentWithTz(this.startDate).startOf('day').isAfter(d);
        },
        addEvent() {
            this.$store.dispatch('addEvent', {
                type: this.$fire.doc(
                    `system/events/types/${this.selectedType}`
                ),
                project: this.selectedProjectId
                    ? this.$fire.doc(`projects/${this.selectedProjectId}`)
                    : null,
                content: this.content,
                start: this.startDate,
                end: this.endDate ?? this.startDate,
                external: this.external,
            });

            this.$router.go(-1);

            this.content = null;
            this.startDate = new Date();
            this.endDate = this.startDate;
        },
        updateEvent() {
            this.$store.dispatch('updateEventWithId', {
                event_id: this.event.id,
                type: this.$fire.doc(
                    `system/events/types/${this.selectedType}`
                ),
                project: this.selectedProjectId
                    ? this.$fire.doc(`projects/${this.selectedProjectId}`)
                    : null,
                content: this.content,
                start: this.startDate,
                end: this.endDate ?? this.startDate,
                external: this.external,
            });

            this.$router.go(-1);

            this.content = null;
            this.startDate = new Date();
            this.endDate = this.startDate;
        },
        deleteEvent() {
            this.$store.dispatch('deleteEventWithId', {
                event_id: this.event.id,
            });

            this.$router.go(-1);

            this.content = null;
            this.startDate = new Date();
            this.endDate = this.startDate;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.event-type-option {
    display: flex;
    align-items: center;
}
.color-preview {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 6px;
    background-color: transparent;
    border-radius: 2px;
}
.update-buttons {
    display: flex;
    > * {
        margin-right: 10px;
    }
}

@media screen and (max-width: 992px) {
    .el-form .el-form-item {
        display: flex;
        flex-direction: column;

        ::v-deep {
            .el-form-item__label {
                text-align: left;
                width: 100% !important;
            }

            .el-select,
            .el-date-editor,
            button {
                width: 100% !important;
            }

            .el-form-item__content {
                margin-left: 0 !important;

                .el-slider {
                    margin-left: 10px;
                }
            }
        }
    }
}

.el-date-editor {
    width: unset;
}
</style>
