import {firebaseFirestore} from '@/utils/firebase';
import {firestoreAction} from 'vuexfire';

const sonosIntegration = {
    state: {
        data: null,
    },
    mutations: {
        //
    },
    actions: {
        bindSonos: firestoreAction(({bindFirestoreRef}) => {
            return bindFirestoreRef(
                'data',
                firebaseFirestore.doc('system/sonos'),
                {maxRefDepth: 0}
            );
        }),
    },
    getters: {
        sonos_data: (state) => state.data,
        is_sonos_authenticated: (state) => state.data,
    },
};

export default sonosIntegration;
