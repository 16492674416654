<template>
    <li
        :class="{
            associated: is_associated,
            active: is_active,
            special: face_id === 0,
            starting: starting,
            stopping: stopping,
            'archived-background': !is_associated && face_id !== 0,
        }"
    >
        <!-- ASSIGNED TEMPLATE -->
        <template v-if="is_associated">
            <!-- Left actions -->
            <stop-button
                v-if="is_active"
                :key="component.id"
                :loading="stopping"
                @click.native="handleStopTimer"
            />

            <!-- Project info -->
            <div v-if="project" :key="face_id" class="info">
                <component-info :component="component" />
            </div>

            <!-- Right actions -->
            <div v-if="!is_active" class="actions">
                <button
                    class="play"
                    :class="{disabled: !can_track_time, starting}"
                    @click="handleStartTimer"
                >
                    <div v-if="starting" v-loading="true" class="spinner" />
                    <svgicon name="play" />
                </button>
                <button class="cross" @click="handleRemoveAssociation">
                    <svgicon name="cross" />
                </button>
            </div>
            <div v-else-if="project">
                <time-spent :component="component" :show_invoiced="false" />
            </div>
        </template>
        <!-- STOP FACE -->
        <template v-else-if="face_id === 0">
            <div class="info" @click="handleStopTimer">
                <span>
                    <strong v-if="is_active">No tracker is running.</strong>
                    <strong v-else>STOP active tracker.</strong>
                </span>
            </div>
        </template>
        <!-- NOT ASSIGNED TEMPLATE -->
        <template v-else>
            <div class="info" @click="$emit('showTrackerDetail', face_id)">
                <span>
                    Click to assign to a project
                </span>
            </div>
        </template>
    </li>
</template>

<script>
import firebase from 'firebase/app';

import {startTimer, stopAllTimers} from '@/api/sessions';

import ComponentInfo from '@/components/generic/ComponentInfo';
import StopButton from '@/components/generic/StopButton';

import componentSessionMixin from '@/mixins/component.session.mixin';
import userMixin from '@/mixins/user.mixin';
import TimeSpent from '@/pages/sessions/components/TimeSpent';

export default {
    name: 'flat-cube-face',
    components: {
        TimeSpent,
        StopButton,
        ComponentInfo,
    },
    mixins: [componentSessionMixin, userMixin],
    props: {
        face_id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            starting: false,
            stopping: false,
        };
    },
    computed: {
        component() {
            if (!this.cube) return null;
            if (!this.cube.face2component[this.face_id]) return null;
            return this.$store.getters.componentWithId(
                this.cube.face2component[this.face_id]
            );
        },
        project() {
            if (!this.component) return null;
            return this.$store.getters.projectWithId(this.component.project);
        },
        stage() {
            if (!this.component) return null;
            return this.$store.getters.stageWithId(this.component.stage);
        },
    },
    methods: {
        async handleStartTimer() {
            if (this.stopping || this.starting) return;

            try {
                this.starting = true;
                this.stopping = false;
                await startTimer(this.current_user.id, this.face_id);
            } catch (err) {
                console.log(err);
            }

            this.starting = false;
        },
        async handleStopTimer() {
            if (this.stopping || this.starting) return;

            try {
                this.stopping = true;
                this.starting = false;
                await stopAllTimers(this.current_user.id);
            } catch (err) {
                console.log(err);
            }

            this.stopping = false;
        },
        handleRemoveAssociation() {
            this.$fire.doc(`cubes/${this.current_user.id.toString()}`).set(
                {
                    face2component: {
                        [this.face_id]: firebase.firestore.FieldValue.delete(),
                    },
                },
                {merge: true}
            );
        },
    },
};
</script>

<style lang="scss" scoped>
li {
    height: 60px;
    padding: 0;
    border-radius: 5px;
    background-color: white;
    margin: 0 10px 5px 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 20;
    padding-left: 0px;
    transition: all 0.1s ease-out, padding-left 0.3s ease-in-out;

    &.loading {
        cursor: default;
    }

    &.archived-background {
        z-index: 1;

        .info {
            opacity: 0;
        }

        &:hover {
            box-shadow: none;
            cursor: pointer;

            .info {
                opacity: 0.2;
            }
        }
    }

    &.completed {
        background: red;
    }

    &.active:not(.special) {
        padding-left: 50px;

        &:after {
            border-left-width: 60px !important;

            @media screen and (max-width: 992px) {
                border-left-width: 55px !important;
            }
        }
    }

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 60px;
        left: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        transition: all 0.3s ease-in-out;
        border-style: solid;
        border-width: 0;
    }

    &.associated:after {
        border-left-width: 10px;
    }

    .info {
        flex: 1;
        height: 60px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        z-index: 10;
        transition: opacity 0.2s ease-in-out;
        user-select: none;

        @media screen and (max-width: 992px) {
            margin-left: 20px;
        }

        span {
            font-size: 10px;
            transition: color 0.3s ease-in-out;
        }
    }

    .actions {
        width: 120px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        transition: opacity 0.2s ease-in-out;

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }

        &.disabled {
            pointer-events: none;
        }

        button {
            position: relative;

            &.disabled {
                pointer-events: none;
                opacity: 0.1;
            }

            ::v-deep .spinner {
                width: 60px;
                height: 60px;
                position: absolute !important;
                top: 1px;
                left: -1px;

                .el-loading-mask {
                    background: unset;

                    .el-loading-spinner .path {
                        stroke: $black;
                    }
                }
            }
        }
    }

    .time {
        font-size: 13px;
        padding-right: 20px;

        @media screen and (max-width: 992px) {
            display: none;
        }
    }

    &.special {
        cursor: pointer;

        &.active {
            pointer-events: none;

            &:after {
                border-left-width: 230px !important;
            }

            .info {
                padding-left: 7px;

                span {
                    color: white;
                }
            }
        }

        .info {
            margin-left: 25px;
            padding-top: 1px;
        }
    }

    button {
        width: 60px;
        padding: 0;
        height: 60px;
        border: none;
        justify-content: center;
        align-items: center;
        display: none;
        cursor: pointer;
        transition: all 0.1s ease-out;
        z-index: 30;
        background: transparent;

        &:hover {
            background: $border-grey-light;
        }

        svg {
            width: 10px;
            transition: opacity 0.25s ease;
        }

        &.starting {
            svg {
                opacity: 0.4;
            }
        }
    }

    button.cross {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    button.stop {
        animation: fadeIn ease 0.4s;
        position: absolute;
        z-index: 30;
        left: 10px;
        background-color: transparent;
        fill: white;
        display: block;
        padding: 0;
        border-color: transparent;

        ::v-deep .spinner {
            .el-loading-mask .el-loading-spinner .path {
                stroke: $white;
            }
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        60% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    &:hover,
    &.starting {
        box-shadow: 0 20px 66px 0 rgba(34, 48, 73, 0.2);

        .actions button {
            display: flex;
        }
    }
    &.stopping {
        box-shadow: 0 20px 66px 0 rgba(34, 48, 73, 0.2);
    }

    &#face_1:after {
        //border-color: $face_1;
        border-color: $black;
    }

    &#face_2:after {
        //border-color: $face_2;
        border-color: $black;
    }

    &#face_3:after {
        //border-color: $face_3;
        border-color: $black;
    }

    &#face_4:after {
        //border-color: $face_4;
        border-color: $black;
    }

    &#face_5:after {
        //border-color: $face_5;
        border-color: $black;
    }

    &#face_6:after {
        //border-color: $face_6;
        border-color: $black;
    }

    &#face_7:after {
        //border-color: $face_7;
        border-color: $black;
    }
}
</style>
