<template>
    <li class="container" @click="handleClick">
        <div class="row">
            <el-tag
                type="info"
                :disable-transitions="true"
                size="small"
                class="ref"
            >
                {{ change_request.ref }}
            </el-tag>
            <span class="title">
                {{ change_request.title }}
            </span>
            <el-tag
                v-if="change_request.estimate"
                size="small"
                type="info"
                @click.stop="handleViewEstimate"
            >
                <i class="el-icon-link" />
                {{ change_request.estimate | fireRef2id }}
            </el-tag>
            <el-tag :disable-transitions="true" size="small" :type="tagType">
                {{ status2Label(change_request.status) }}
            </el-tag>
        </div>
    </li>
</template>

<script>
import changeRequestsMixin from '@/mixins/changerequests.mixin';

export default {
    name: 'change-request-block',
    mixins: [changeRequestsMixin],
    props: {
        project: {
            type: Object,
            required: true,
        },
        change_request: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        tagType() {
            return this.status2TagType(this.change_request.status);
        },
    },
    methods: {
        handleClick() {
            this.$router.push({
                name: 'project_detail_changerequests+detail',
                params: {
                    project_id: this.project.id,
                    change_request_id: this.change_request.id,
                },
            });
        },
        handleViewEstimate() {
            if (this.change_request.estimate) {
                this.$router.push({
                    name: 'project_detail_estimate+detail',
                    params: {
                        project_id: this.project.id,
                        estimate_id: this.$options.filters.fireRef2id(
                            this.change_request.estimate
                        ),
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    display: flex;
    width: 100%;
    padding: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid $border-grey;
    cursor: pointer;
    background-color: $white;
    box-sizing: border-box;
    user-select: none;

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
    }

    .el-tag.ref {
        width: 120px;
        text-align: center;
        font-size: 11px;
    }

    span.title {
        margin-left: 12px;
        font-size: 13px;
        flex: 1;
    }

    .el-tag {
        width: 100px;
        text-align: center;
        margin-left: 10px;
    }
}
</style>
