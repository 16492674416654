<template>
    <content-block title="Stage" outlined :expanded="!is_stage_linked">
        <div v-if="is_stage_linked" slot="right" class="controls">
            <el-button
                size="mini"
                icon="el-icon-connection"
                @click="handleViewStage(linked_stage)"
            >
                {{ linked_stage?.notes }}
            </el-button>
            <el-alert type="success" show-icon :closable="false">
                Linked
            </el-alert>
        </div>
        <template v-else>
            <el-form
                ref="form"
                auto-complete="on"
                :model="form"
                label-width="160px"
                class="form-wrapper"
            >
                <el-form-item label="New stage">
                    <el-switch
                        v-model="form.is_new"
                        :disabled="stages.length === 0"
                    />
                </el-form-item>

                <template v-if="form.is_new">
                    <el-form-item label="Notes" prop="notes">
                        <el-input v-model="form.notes" />
                    </el-form-item>
                    <el-form-item label="Start date" prop="start_date">
                        <el-date-picker v-model="form.start_date" />
                    </el-form-item>
                    <el-form-item label="Deadline" prop="deadline_date">
                        <el-date-picker v-model="form.deadline_date" />
                    </el-form-item>
                </template>

                <el-form-item
                    v-else
                    label="Stage"
                    prop="id"
                    :rules="{
                        required: true,
                        message: 'Stage is required',
                        trigger: 'blur',
                    }"
                >
                    <el-select v-model="form.id">
                        <el-option
                            v-for="s of stages"
                            :key="s.id"
                            :label="`#${s.number}`"
                            :value="s.id"
                        >
                            <span style="font-size: 13px; margin-right: 30px;">
                                Stage #{{ s.number }}
                            </span>
                            <span
                                v-if="s.notes"
                                style="
                                    color: #8492a6;
                                    font-size: 11px;
                                    float: right;
                                "
                            >
                                {{ s.notes }}
                            </span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <div class="section-footer">
                <el-button
                    :loading="loading"
                    size="mini"
                    icon="el-icon-bottom"
                    @click="handleAssignStage"
                >
                    Assign stage
                </el-button>
            </div>
        </template>
    </content-block>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import {STAGE_STATUS} from '@/enums';

export default {
    name: 'estimate-stage-link',
    components: {ContentBlock},
    props: {
        estimate: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            form: {
                id: null,
                status: 'active',
                is_new: true,
                notes: `${this.estimate.ref} - ${this.estimate.title}`,
                start_date: null,
                deadline_date: null,
            },
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(this.estimate.project);
        },
        stages() {
            return this.$store.getters.stagesForProjectWithId(this.project.id);
        },
        is_stage_linked() {
            return this.estimate.stage;
        },
        linked_stage() {
            if (this.is_stage_linked)
                return this.$store.getters.stageWithId(this.estimate.stage);
            return null;
        },
    },
    methods: {
        handleAssignStage() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    this.loading = true;

                    let stage_id = null;

                    if (this.form.is_new) {
                        // create new stage
                        const snapshot = await this.$store.dispatch(
                            'addStage',
                            {
                                number: this.stages
                                    ? this.stages.length + 1
                                    : 1,
                                project: this.$fire.doc(
                                    `projects/${this.project.id}`
                                ),
                                status: STAGE_STATUS.ACTIVE,
                                notes: this.form.notes,
                                start_date: this.form.start_date,
                                deadline_date: this.form.deadline_date,
                            }
                        );

                        const stage = await snapshot.get();
                        stage_id = stage.id;
                    } else {
                        // using existing stage
                        stage_id = this.form.id;
                    }

                    await this.$store.dispatch('updateEstimateLinkStage', {
                        ref: this.estimate.id,
                        stage_id: stage_id,
                    });

                    this.loading = false;
                } else {
                    console.log('Form not valid...');
                    return false;
                }
            });
        },
        handleViewStage() {
            this.$router.push({
                name: 'stage_detail',
                params: {
                    project_id: this.project.id,
                    stage_id: this.linked_stage.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.el-date-editor.el-input {
    width: 100%;
}
.controls {
    display: flex;
    flex-direction: row;

    .el-button {
        margin-right: 10px;
    }
}
</style>
