var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "container", on: { click: _vm.handleClick } },
        [
          _c(
            "el-tag",
            {
              staticClass: "ref",
              attrs: {
                "disable-transitions": true,
                size: "small",
                type: _vm.tag_type,
              },
            },
            [_vm._v(" " + _vm._s(_vm.estimate.ref) + " ")]
          ),
          _c("span", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.estimate.title) + " "),
          ]),
        ],
        1
      ),
      _vm.show_paymentplan
        ? _c("payment-plan-summary-block", {
            attrs: { estimate: _vm.estimate },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }