<template>
    <div>
        <todo-list />
    </div>
</template>

<script>
import TodoList from '@/components/todo/TodoList';

export default {
    name: 'home-tab-todo',
    components: {
        TodoList,
    },
};
</script>

<style lang="scss" scoped></style>
