import {COMMANDS} from '@/enums';

import NavigateCommand from '@/components/command-palette/commands/NavigateCommand';
import OpenSettingsCommand from '@/components/command-palette/commands/OpenSettingsCommand';
import TodosCommand from '@/components/command-palette/commands/TodosCommand';
import TimerCommand from '@/components/command-palette/commands/TimerCommand';
import NewEstimateCommand from '@/components/command-palette/commands/NewEstimateCommand';
import NewEstimateDocumentCommand from '@/components/command-palette/commands/NewEstimateDocumentCommand';
import DocumentFromLinkCommand from '@/components/command-palette/commands/DocumentFromLinkCommand';

/*
Shortcuts allow a command to be immediately run with a single keypress.
Note that these are not hotkeys, they are still part of the search process.

A command will automatically trigger if the search string exactly matches the shortcut,
and the command is the only result matching the search.

Shortcuts should be:
- unique
- typeable characters only (no modifier keys)
- not characters you may start a search with (so letters are not recommended)
- single characters are preferred, though a short sequence will also work
 */

export const Commands = [
    // CORE COMMANDS - displayed by default
    {
        id: COMMANDS.NAVIGATE,
        label: 'Search',
        sub_label: 'Search for a page in TOOL',
        default_option: true,
        keywords: 'navigate navigation nav search goto go',
        // keywords: 'search navigate navigation nav goto go projects estimates payment plans crs change requests components invoices sessions planner project management events team',
        value: NavigateCommand,
        shortcut: '/',
    },
    {
        id: COMMANDS.SETTINGS,
        label: 'Settings',
        sub_label: 'Open the settings',
        keywords: 'user settings open dialogue profile integrations',
        value: OpenSettingsCommand,
        shortcut: '~',
    },
    {
        id: COMMANDS.ADDTODO,
        label: 'Add Todo',
        sub_label: 'Add a new todo and view current todos',
        keywords: 'new add todo todos tasks notes temp',
        value: TodosCommand,
        shortcut: '@',
    },
    {
        id: COMMANDS.TIMER,
        label: 'Timers',
        sub_label: 'Start and stop your timers',
        keywords: 'start timer stop timer cube session tracker',
        value: TimerCommand,
        shortcut: '-',
    },
    // EXTENDED COMMANDS - only displayed when filtering
    {
        id: COMMANDS.NEW_ESTIMATE,
        label: 'New estimate',
        sub_label: 'Create a new estimate',
        keywords: 'create new add estimate quote invoice',
        value: NewEstimateCommand,
    },
    {
        id: COMMANDS.NEW_ESTIMATE_DOCUMENT,
        label: 'New estimate document',
        sub_label: 'Create a new document',
        keywords: 'create new add estimate quote invoice document',
        value: NewEstimateDocumentCommand,
    },
    {
        id: COMMANDS.NEW_DOCUMENT_FROM_LINK,
        label: 'New document from external link',
        sub_label: 'Create a new document',
        keywords: 'create new add document link external url',
        value: DocumentFromLinkCommand,
    },
];
