<template>
    <a class="link" href="javascript:void(0)" @click.stop="handleClick">
        <el-tag v-if="ref" class="ref" size="mini" type="info">
            {{ ref }}
        </el-tag>
        {{ content }}
        <el-tag v-if="status" class="status" size="mini" :type="status.type">
            {{ status.label }}
        </el-tag>
    </a>
</template>
<script>
import changeRequestMixin from '@/mixins/changerequests.mixin';
export default {
    name: 'project-link',
    props: {
        id: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        url: {
            type: Object,
            required: true,
        },
        resolved: {
            type: Object,
            required: true,
        },
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(this.id);
        },
        stage() {
            if (this.resolved.params.stage_id) {
                return this.$store.getters.stageWithId(
                    this.resolved.params.stage_id
                );
            }
            return null;
        },
        component() {
            if (this.resolved.params.component_id) {
                return this.$store.getters.componentWithId(
                    this.resolved.params.component_id
                );
            }
            return null;
        },
        change_request() {
            if (this.resolved.params.change_request_id) {
                return this.$store.getters.changeRequestWithId(
                    this.resolved.params.change_request_id
                );
            }
            return null;
        },
        status() {
            if (this.change_request) {
                const label = changeRequestMixin.methods.status2Label(
                    this.change_request.status
                );

                const tagType = changeRequestMixin.methods.status2TagType(
                    this.change_request.status
                );

                return {
                    label,
                    type: tagType,
                };
            }

            return null;
        },
        ref() {
            if (this.change_request) {
                return this.change_request.id;
            }

            if (this.project) return this.project.ref;

            return this.project.ref;
        },
        content() {
            if (this.change_request) {
                return `${this.change_request.title}`;
            }

            if (!this.project) return 'Unknown Project';

            let value = this.project.name;

            if (this.component) {
                value = `${value} → ${this.component.name}`;
            } else if (this.stage) {
                value = `${value} → ${
                    this.stage.name
                        ? this.stage.name
                        : `Stage ${this.stage.number}`
                }`;
            } else if (this.change_request) {
                return `${this.change_request.title}`;
            }

            return `${value} → ${this.tab}`;
        },
        icon() {
            return '/favicon.ico';
        },
        tab() {
            const val = this.resolved.meta.tab.substr(1);

            return val.toLowerCase().replace(/\b[a-z]/g, (letter) => {
                return letter.toUpperCase();
            });
        },
        key() {
            return this.resolved.meta.key.replace('.name', '');
        },
    },
    methods: {
        async handleClick() {
            try {
                await this.$router.push(this.resolved);
            } catch (e) {
                console.log(e);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.favicon {
    height: 10px;
}

.ref,
.status {
    font-size: 10px;
    padding: 0px 4px;
}
</style>
