<template>
    <section>
        <span>What would you like to do?</span>

        <move-sessions />

        <hr />

        <move-component />

        <hr />

        <delete-component />
        <delete-stage />

        <hr />

        <convert-completed-stage-components />

        <hr />

        <find-orphaned-sessions />
        <find-orphaned-invoices />
        <find-orphaned-line-items />

        <hr />

        <find-short-sessions />

        <hr />

        <sessions-dump />
    </section>
</template>

<script>
import MoveSessions from './components/MoveSessions';
import MoveComponent from './components/MoveComponent';
import DeleteComponent from './components/DeleteComponent';
import DeleteStage from './components/DeleteStage';
import ConvertCompletedStageComponents from './components/ConvertCompletedStageComponents';
import FindOrphanedSessions from './components/FindOrphanedSessions';
import FindOrphanedInvoices from './components/FindOrphanedInvoices';
import FindOrphanedLineItems from './components/FindOrphanedLineItems';
import FindShortSessions from './components/FindShortSessions';
import SessionsDump from './components/SessionsDump';

export default {
    name: 'convert',
    components: {
        MoveSessions,
        MoveComponent,
        DeleteComponent,
        DeleteStage,
        ConvertCompletedStageComponents,
        FindOrphanedSessions,
        FindOrphanedInvoices,
        FindOrphanedLineItems,
        FindShortSessions,
        SessionsDump,
    },
};
</script>

<style lang="scss">
section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 100px;
}

span:not(.el-select__tags-text) {
    margin-bottom: 40px;
}

hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: black;
    opacity: 0.1;
}

.block {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    .el-button {
        width: 200px;
        margin: 0 !important;
    }

    div {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            font-size: 11px;
            margin: 0;
        }
    }
}
</style>
