<template>
    <div class="background">
        <div class="login-container">
            <el-form class="card-box form">
                <h1>This project can't be found.</h1>
            </el-form>
        </div>

        <span class="copyright">
            Made with <heart :width="12" /> by CodeFish Studio
        </span>
    </div>
</template>

<script>
import Heart from '@/components/generic/Heart';

export default {
    name: 'access-error',
    components: {
        Heart,
    },
};
</script>

<style lang="scss" scoped>
.background {
    background-color: $actual-black;
    width: 100%;
    height: 100vh;
    position: fixed;
}

svg {
    width: 100%;
    margin-bottom: 20px;
}

.copyright {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: block;
    text-align: center;
    user-select: none;
    font-size: 13px;
    font-weight: 400;
    color: white;
    margin-bottom: 50px;

    svg {
        margin: 0 3px;
    }
}

.login-container {
    position: fixed;
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form {
        width: 450px;
        max-width: 100%;
        padding: 35px 35px 15px 35px;
        box-sizing: border-box;

        h1 {
            text-align: center;
            margin: 0;
            margin-bottom: 10px;
            color: white;
            font-size: 40px;
        }
    }
}
</style>
