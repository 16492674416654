<template>
    <div class="placeholder" src="@/assets/img/search.png" draggable="false">
        <search-empty class="image" />
        <slot name="title" class="title" />
        <slot name="subtitle" class="subtitle" />
    </div>
</template>

<script>
import SearchEmpty from '@/assets/svg/search-empty.svg';

export default {
    name: 'placeholder',
    components: {
        SearchEmpty,
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-bottom: 60px;
    margin: auto 0;

    .image {
        width: 142px;
        height: 136px;
        transform: scale(0.7);
    }

    span:first-of-type {
        font-family: 'Rubik', sans-serif;
        text-align: center;
    }

    span:last-of-type {
        display: block;
        font-size: 12px;
        margin-top: 10px;
    }
}
</style>
