var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "value-display", class: _vm.breakeven_status },
    [
      _vm.icon
        ? _c("div", { staticClass: "indicator" }, [
            _c("i", { class: _vm.icon }),
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.percentage) + "%"),
            ]),
          ])
        : _vm._e(),
      _c("span", { staticClass: "value", class: { bold: _vm.bold } }, [
        _vm._v(_vm._s(_vm._f("currency")(_vm.value))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }