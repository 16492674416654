<template>
    <div class="block" :class="{disabled: disabled}">
        <div
            v-if="loading"
            v-loading="true"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0)"
        />
        <el-popconfirm
            v-else
            confirm-button-text="OK"
            cancel-button-text="No, thanks"
            icon="el-icon-info"
            icon-color="red"
            title="Are you sure to delete this?"
            @confirm="deleteSlot"
        >
            <div slot="reference" class="content">
                <div class="info">
                    <span>
                        <strong>{{ project.ref }}</strong>
                    </span>
                    <span>{{ component.name }}</span>
                </div>
                <span v-if="object.time_estimate" class="time">
                    {{ object.time_estimate | seconds2duration }}
                </span>
            </div>
        </el-popconfirm>
    </div>
</template>

<script>
export default {
    name: 'slot-block',
    props: {
        object: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        component() {
            return this.$store.getters.componentWithId(this.object.component);
        },
        project() {
            if (!this.component) return null;
            return this.$store.getters.projectWithId(this.component.project);
        },
        loading() {
            return !this.project;
        },
    },
    methods: {
        deleteSlot() {
            this.$fire.doc(`slots/${this.object.id}`).delete();
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.block {
    flex: 1;

    &.disabled {
        pointer-events: none;
    }

    span {
        flex: 1;
    }

    .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 4px 0;
        cursor: pointer;
        padding: 6px;
        border-radius: 5px;

        &:hover {
            background-color: $border-grey-light;
        }

        .info {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            span {
                font-size: 10px;

                strong {
                    font-size: 12px;
                }
            }
        }

        .time {
            font-size: 12px;
            text-align: right;
        }
    }
}
</style>
