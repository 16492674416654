import request from '@/utils/request';

// {{host}}/sonos/redirect
export function redirectUserToSonos() {
    return request({
        url: '/sonos/redirect',
        method: 'get',
    });
}

// {{host}}/sonos/authenticate
export function handleCallbackFromSonos(code) {
    return request({
        url: '/sonos/authenticate',
        method: 'post',
        data: {
            code,
        },
    });
}

// {{host}}/sonos/disconnect
export function disconnectSonos() {
    return request({
        url: '/sonos/disconnect',
        method: 'delete',
    });
}
