<template>
    <div class="wrapper">
        <header>
            <div class="info"></div>
            <div class="actions">
                <div class="logout" @click="$emit('onLogout')">
                    <div class="content">
                        <i class="el-icon-switch-button" />
                    </div>
                </div>
            </div>
        </header>
        <div v-if="project">
            <div class="stats">
                <h3>{{ project.name }} is on track.</h3>
                <el-progress
                    :percentage="40"
                    :stroke-width="10"
                    :show-text="false"
                    :color="customColors"
                />
                <div class="graphs">
                    <div class="graph-container">
                        <span>Milestones</span>
                        <el-progress
                            :width="80"
                            type="circle"
                            :percentage="100"
                            status="success"
                            :color="customColors"
                        />
                    </div>
                    <div class="graph-container">
                        <span>Time</span>
                        <el-progress
                            :width="80"
                            type="circle"
                            :percentage="64"
                            :color="customColors"
                        />
                    </div>
                    <div class="graph-container">
                        <span>Tasks</span>
                        <el-progress
                            :width="80"
                            type="circle"
                            :percentage="29"
                            :color="customColors"
                        />
                    </div>
                    <div class="graph-container">
                        <span>Finance</span>
                        <el-progress
                            :width="80"
                            type="circle"
                            :percentage="89"
                            :color="customColors"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'access-authenticated',
    props: {
        project: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            customColors: [
                {color: '#f56c6c', percentage: 30},
                {color: '#FFFFFF', percentage: 100},
                {color: '#90d050', percentage: 100},
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    background-color: $border-grey-light;
    border-bottom: 1px solid $border-grey;

    .actions {
        text-align: right;

        .logout {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            height: 100%;
            flex: 1;
            padding-left: 20px;
            border-left: 1px solid $border-grey;

            .content {
                background-color: $white;
                border: 1px solid $border-grey;
                height: 100%;
                width: 100%;
                padding: 8px 20px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.2s cubic-bezier(0.2, 0.5, 0.1, 1);

                i {
                    color: $black;
                    font-weight: bold;
                }
            }

            &:hover .content {
                background-color: $red;
                border: 1px solid $red-dark;

                i {
                    color: $white;
                    font-weight: bold;
                }
            }
        }
    }
}
.stats {
    max-width: calc(100% - 40px);
    background: $black;
    margin: 20px;
    border-radius: 5px;
    padding: 30px;

    h3 {
        margin: 0;
        margin-bottom: 20px;
        color: white;
    }

    ::v-deep .el-progress {
        .el-progress-circle svg path.el-progress-circle__track {
            stroke: $black-darker;
        }
        .el-progress-bar .el-progress-bar__outer {
            background-color: $black-darker;
        }
        .el-progress__text {
            color: white;
        }
    }

    .graphs {
        padding-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .graph-container {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            span {
                color: white;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.wrapper {
    background-color: $white;
    height: 100%;
}
</style>
