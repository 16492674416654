<template>
    <placeholder-block
        v-if="is_template"
        label="Jira List"
        :block="block"
        @delete="deleteBlock"
    />
    <el-card v-else class="content-block estimate-summary" shadow="never">
        <div slot="header" class="heading">
            <div class="grip">⣿</div>
            <span class="label">Jira list</span>
            <div class="controls">
                <el-date-picker
                    v-model="editingBlock.dateRange"
                    class="date-picker"
                    type="daterange"
                    size="large"
                    placeholder="Start date"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    :disabled="!!editingBlock.content"
                    @change="updateDateRange"
                />
                <el-switch
                    v-model="useHyperlinks"
                    active-text="Generate Hyperlinks"
                />
            </div>
            <el-tooltip :content="fetchTooltip">
                <el-popconfirm
                    title="Replace text block with fetched list of jira tickets?"
                    :disabled="loading || !$store.getters.is_jira_enabled"
                    @confirm="getJiraTickets"
                >
                    <el-button
                        slot="reference"
                        class="control"
                        icon="el-icon-refresh"
                        plain
                    />
                </el-popconfirm>
            </el-tooltip>
            <el-button
                class="control"
                plain
                type="danger"
                icon="el-icon-delete"
                @click="deleteBlock"
            />
        </div>
        <el-skeleton v-if="loading" :rows="3" />
        <text-editor
            ref="content_editor"
            :content.sync="editingBlock.content"
            :floating_menu="false"
            :drag_drop="false"
            @update:content="updateBlock"
        />
    </el-card>
</template>

<script>
import userMixin from '@/mixins/user.mixin';
import PlaceholderBlock from '@/pages/documents/components/PlaceholderBlock';
import {getJiraIssuesCompletedInWindowForProject} from '@/api/atlassian';
import TextEditor from '@/components/editor/TextEditor';
import {Notification} from 'element-ui';

export default {
    name: 'jira-list-block',
    components: {TextEditor, PlaceholderBlock},
    mixins: [userMixin],
    props: {
        block: {
            type: Object,
            required: true,
        },
        project: {
            type: Object,
            default: null,
        },
        is_template: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            editingBlock: null,
            loading: false,
            useHyperlinks: false,
        };
    },
    computed: {
        fetchTooltip() {
            if (this.$store.getters.is_jira_enabled)
                return 'Re-fetch Jira tickets finished within date window';
            else
                return 'Cannot fetch jira tickets as jira functionality has been disabled';
        },
    },
    watch: {
        block: {
            handler(newVal, oldVal) {
                this.editingBlock = {...newVal};
                this.useHyperlinks = newVal.use_hyperlinks || false;
            },
            immediate: true,
        },
        useHyperlinks(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$emit('update', {...this.block, use_hyperlinks: newVal});
            }
        },
    },
    methods: {
        async getJiraTickets() {
            if (!this.is_atlassian_authed) {
                Notification({
                    type: 'error',
                    title: 'Error',
                    message:
                        "Cannot fetch Jira tickets as you haven't connected to Jira.",
                });
                return;
            }

            if (this.is_template) return;

            const dateRange = this.editingBlock.dateRange;
            if (
                !dateRange ||
                dateRange.length !== 2 ||
                !dateRange[0] ||
                !dateRange[1]
            )
                return;

            this.$refs.content_editor.setContent(null);
            this.editingBlock.content = null;
            this.loading = true;

            const res = this.$store.getters.getProjectIssues(this.project.id);

            let [start, end] = this.editingBlock.dateRange;
            start = new Date(start);
            end = new Date(end);

            const mappedCards = res
                .filter((x) => {
                    const updated = x.updated.toDate();
                    return updated > start && updated < end;
                })
                .sort((is1, is2) => {
                    if (is1.type.label > is2.type.label) return 1;
                    else if (is1.type.label === is2.type.label) return 0;
                    return -1;
                })
                .map(this.mapCardToText);

            const content = {
                type: 'doc',
                content: [
                    {
                        type: 'bulletList',
                        content: mappedCards,
                    },
                ],
            };

            this.$refs.content_editor.setContent(content);
            this.editingBlock.content = content;
            this.updateBlock();
            this.loading = false;
        },
        mapCardToText(card) {
            const marks = [{type: 'bold'}];

            if (this.useHyperlinks) {
                marks.push({
                    attrs: {
                        href: `https://${card.resource.name}.atlassian.net/browse/${card.key}`,
                        target: '_blank',
                    },
                    type: 'link',
                });
            }

            return {
                type: 'listItem',
                content: [
                    {
                        type: 'paragraph',
                        content: [
                            {
                                type: 'text',
                                marks,
                                text: `${card.key}`,
                            },
                            {
                                type: 'text',
                                text: ` | ${card.summary}`,
                            },
                        ],
                    },
                ],
            };
        },
        updateDateRange() {
            this.getJiraTickets();
            this.updateBlock();
        },
        updateBlock() {
            this.$emit('update', this.editingBlock);
        },
        deleteBlock() {
            this.$emit('delete', this.block.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.content-block {
    margin-bottom: 10px;
}

.heading {
    display: flex;
    align-items: center;

    .grip {
        width: 20px;
        height: 23px;
        text-align: left;
        opacity: 0.25;
        user-select: none;
        cursor: grab;
    }

    .label {
        font-weight: bold;
        font-size: 14px;
        color: $blue;
        margin-right: 20px;
        flex: 1;
    }

    .controls {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-right: 10px;

        .select-detail {
            width: 200px;
        }
    }
}
</style>
