<template>
    <div class="payment_plans-container">
        <el-row :gutter="12">
            <el-col :lg="8" :sm="24">
                <widget-card style="margin-bottom: 10px;" :loading="loading">
                    <div v-if="payment_plans.length" class="key-value">
                        <label>Total planned <span>(ex. GST)</span></label>
                        <span>{{ total_planned | currency }}</span>
                    </div>

                    <el-alert
                        v-else
                        description="No payment plans available."
                        :closable="false"
                    />
                </widget-card>
            </el-col>
            <el-col :lg="8" :sm="24">
                <widget-card style="margin-bottom: 10px;" :loading="loading">
                </widget-card>
            </el-col>
            <el-col :lg="8" :sm="24">
                <widget-card style="margin-bottom: 10px;" :loading="loading">
                </widget-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import WidgetCard from '@/components/generic/WidgetCard';

import PaymentPlanMixin from '@/mixins/paymentplan.mixin';

export default {
    name: 'project-paymentplans',
    components: {
        WidgetCard,
    },
    mixins: [PaymentPlanMixin],
    props: {
        project: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            loading: true,
            payment_plan_invoices: [],
        };
    },
    computed: {
        payment_plans() {
            return this.$store.getters.paymentPlansForProjectWithId(
                this.project.id
            );
        },
        total_planned() {
            return this.payment_plan_invoices.reduce((sum, pp_invoice) => {
                return pp_invoice.line_items.reduce((sum, item) => {
                    const sign = item.credit ? -1 : 1;
                    return (sum +=
                        this.discountValue(item.amount, item.discount) * sign);
                }, sum);
            }, 0);
        },
    },
    mounted() {
        this.$fire
            .collection('payment_plan_invoices')
            .where(
                'project',
                '==',
                this.$fire.collection('projects').doc(this.project.id)
            )
            .get()
            .then((snapshot) => {
                snapshot.forEach((ppl) => {
                    const data = ppl.data();
                    this.payment_plan_invoices.push(data);
                });

                this.loading = false;
            });
    },
};
</script>

<style lang="scss" scoped>
.payment_plans-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;

    ::v-deep .el-card .el-card__body label span {
        font-size: 8px;
        opacity: 0.8;
    }
}
</style>
