<template>
    <div
        class="calendar-view"
        :class="{'padding-tracking-bar': active_session}"
    >
        <el-radio-group v-model="view_type" size="small">
            <el-radio-button :label="0">Daily</el-radio-button>
            <el-radio-button :label="1">Weekly</el-radio-button>
        </el-radio-group>
        <planner-daily-view
            v-if="view_type === CALENDAR_VIEW_TYPE.DAILY"
            :days="days"
            :slots="slots"
            :loading="loading"
            :cursor="cursor"
            @moveCursor="moveCursor"
            @resetCursor="resetCursor"
        />
        <planner-weekly-view
            v-else-if="view_type === CALENDAR_VIEW_TYPE.WEEKLY"
            :days="days"
            :slots="slots"
            :loading="loading"
            :cursor="cursor"
            @moveCursor="moveCursor"
            @resetCursor="resetCursor"
        />
    </div>
</template>

<script>
import {momentWithTz} from '@/utils';
import userMixin from '@/mixins/user.mixin';

import {CALENDAR_VIEW_TYPE} from '@/enums';

import PlannerDailyView from '@/components/planner/PlannerDailyView';
import PlannerWeeklyView from '@/components/planner/PlannerWeeklyView';
import PlannerYearlyView from '@/components/planner/PlannerYearlyView';

export default {
    name: 'planner-tab-schedule',
    components: {
        PlannerDailyView,
        PlannerWeeklyView,
    },
    mixins: [userMixin],
    data() {
        return {
            CALENDAR_VIEW_TYPE,
            view_type: CALENDAR_VIEW_TYPE.WEEKLY,
            week_length: 7,
            delta: 0,
            days: [],
            slots: [],
            loading: true,
        };
    },
    computed: {
        cursor() {
            return momentWithTz().add(this.delta, 'days');
        },
        active_session() {
            return this.$store.getters.activeSessionForUserWithId(
                this.current_user.id
            );
        },
    },
    watch: {
        view_type() {
            this.fetchSlots();
        },
    },
    mounted() {
        this.fetchSlots();
    },
    methods: {
        fetchSlots() {
            this.loading = true;

            if (this.view_type == CALENDAR_VIEW_TYPE.WEEKLY) {
                const week_start = momentWithTz()
                    .add(this.delta, 'days')
                    .startOf('week');
                const week_end = momentWithTz()
                    .add(this.delta, 'days')
                    .endOf('week');

                this.days = [];
                [...Array(this.week_length)].forEach((_, i) => {
                    this.days.push(week_start.clone().add(i, 'days'));
                });

                this.$bind(
                    'slots',
                    this.$fire
                        .collection('slots')
                        .where('day', '>=', week_start.toDate())
                        .where('day', '<=', week_end.toDate()),
                    {maxRefDepth: 0}
                ).then(() => {
                    this.loading = false;
                });
            } else if (this.view_type == CALENDAR_VIEW_TYPE.DAILY) {
                const day_start = momentWithTz()
                    .add(this.delta, 'days')
                    .startOf('day');
                const day_end = momentWithTz()
                    .add(this.delta, 'days')
                    .endOf('day');

                this.days = [momentWithTz().add(this.delta, 'days')];

                this.$bind(
                    'slots',
                    this.$fire
                        .collection('slots')
                        .where('day', '>=', day_start.toDate())
                        .where('day', '<=', day_end.toDate()),
                    {maxRefDepth: 0}
                ).then(() => {
                    this.loading = false;
                });
            }
        },
        moveCursor(delta) {
            this.delta += delta;
            this.fetchSlots();
        },
        resetCursor() {
            this.delta = 0;
            this.fetchSlots();
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.calendar-view {
    position: relative;
}
.padding-tracking-bar {
    padding-bottom: calc(71px + 20px);
}

.el-switch,
.el-radio-group {
    position: absolute;
    right: 20px;
    margin: 15px;
}
</style>
