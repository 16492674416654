/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'invoice_linking': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M256 0C114.833 0 0 114.833 0 256s114.833 256 256 256 256-114.853 256-256S397.167 0 256 0zm0 472.341c-119.275 0-216.341-97.046-216.341-216.341S136.725 39.659 256 39.659c119.295 0 216.341 97.046 216.341 216.341S375.275 472.341 256 472.341z"/><path pid="1" d="M330.232 224.516h-29.36C297.064 197.7 273.754 177 245.651 177h-63.884C151.018 177 126 201.781 126 232.242c0 30.46 25.017 55.242 55.768 55.242h29.36C214.935 314.3 238.246 335 266.349 335h63.884c30.75 0 55.768-24.781 55.768-55.242 0-30.46-25.018-55.242-55.768-55.242zm.42 94.484h-63.815c-21.696 0-39.348-17.426-39.348-38.847 0-21.42 17.652-38.846 39.348-38.846 4.518 0 8.18-3.616 8.18-8.076 0-4.46-3.662-8.075-8.18-8.075-27.804 0-50.914 20.214-55.048 46.538h-29.441c-21.697 0-39.348-17.427-39.348-38.847S160.651 194 182.348 194h63.814c21.696 0 39.348 17.426 39.348 38.847 0 21.42-17.651 38.846-39.348 38.846-4.517 0-8.18 3.616-8.18 8.076 0 4.46 3.663 8.075 8.18 8.075 27.804 0 50.914-20.214 55.049-46.538h29.44c21.697 0 39.349 17.427 39.349 38.847S352.348 319 330.651 319z" _stroke="#000" stroke-width="5"/></g>'
  }
})
