<template>
    <content-block title="Jira">
        <template slot="right">
            <el-button size="mini" @click="adding = !adding">
                {{ adding ? 'Cancel' : 'Add ticket' }}
            </el-button>
        </template>
        <ul class="tickets">
            <li
                v-for="(ticket, idx) in change_request.tickets"
                :key="'ticket_' + idx"
                @click="openTicket(ticket)"
            >
                <label>
                    <span>{{ ticket.issue_id }}</span> - {{ ticket.url }}
                </label>
            </li>
            <li v-if="adding">
                <el-input v-model="url" type="text" placeholder="Ticket URL" />
                <el-button size="mini" type="primary" @click="saveTicket">
                    Save
                </el-button>
            </li>
        </ul>
    </content-block>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import firebase from 'firebase/app';
import {Notification} from 'element-ui';

export default {
    name: 'change-request-jira',
    components: {
        ContentBlock,
    },
    props: {
        change_request: {
            type: Object,
            required: true,
        },
        project: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            adding: false,
            url: null,
        };
    },
    methods: {
        saveTicket() {
            var results = this.url.match(
                /((?!([A-Z0-9a-z]{1,10})-?$)[A-Z]{1}[A-Z0-9]+-\d+)/g
            );
            console.log(results);

            // Output: [ 'BF-18', 'X-88', 'ABCDEFGHIJKL-999', 'ABC-1' ]

            if (!results || !results.length) {
                Notification({
                    type: 'error',
                    title: 'Jira',
                    message: 'Issue ID not found. Try a different link.',
                });
                return;
            }

            this.update({
                tickets: firebase.firestore.FieldValue.arrayUnion({
                    url: this.url,
                    issue_id: results[0],
                }),
            });

            this.adding = false;
            this.url = null;
        },
        openTicket(ticket) {
            console.log(ticket);
            window.open(ticket.url, '_blank');
        },
        update(data) {
            return this.$fire
                .collection('change_requests')
                .doc(this.change_request.id)
                .update(data);
        },
    },
};
</script>

<style lang="scss" scoped>
.tickets {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        margin: 5px 0;
        display: flex;
        flex-direction: row;

        label {
            border: 1px solid $border-grey;
            flex: 1;
            cursor: pointer;
            padding: 10px;
            background-color: $white;
            border-radius: 5px;
            font-size: 14px;

            span {
                background-color: $border-grey-light;
                padding: 3px 7px;
                border-radius: 5px;
                font-size: 15px;
            }

            &:hover {
                border: 1px solid $blue;
            }
        }

        .el-input {
            margin-right: 5px;
        }
    }
}
</style>
