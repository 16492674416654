<template>
    <div class="projects-container">
        <client-project-block
            v-for="(project, index) in projects"
            :key="`${project.ref}_${index}`"
            :project="project"
            :client="client"
        />
    </div>
</template>

<script>
import ClientProjectBlock from '@/components/blocks/ClientProjectBlock';

export default {
    name: 'client-projects',
    components: {
        ClientProjectBlock,
    },
    props: {
        client: {
            type: Object,
            default: null,
        },
    },
    computed: {
        projects() {
            if (!this.client.projects) return [];

            return this.client.projects
                .map((project_ref) =>
                    this.$store.getters.projectWithId(project_ref)
                )
                .filter((p) => p);
        },
    },
};
</script>

<style lang="scss" scoped>
.projects-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;
}
</style>
