<template>
    <div class="datetime">
        <h1>
            <span>{{ hours }}</span>
            :
            <span>{{ minutes }}</span>
        </h1>
        <h3>{{ date }}</h3>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'datetime-widget',
    computed: {
        time() {
            return moment(this.$store.state.current_time);
        },
        hours() {
            return this.time.format('HH');
        },
        minutes() {
            return this.time.format('mm');
        },
        date() {
            return this.time.format('dddd, DD MMMM');
        },
    },
};
</script>

<style lang="scss" scoped>
.datetime {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 250px;
    text-align: center;

    h1 {
        color: white;
        margin: 0;
        padding: 0;
        height: 1em;
        line-height: 1em;
        font-size: 3em;

        span {
            display: inline-block;
            box-sizing: border-box;
            text-align: center;
            margin: 0px;
        }
    }

    h3 {
        color: white;
        font-size: 1em;
        margin: 5px;
    }
}
</style>
