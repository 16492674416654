<template>
    <el-card class="content-block text" shadow="never">
        <template v-if="editingBlock">
            <div slot="header" class="heading">
                <div class="grip">⣿</div>
                <el-input
                    ref="heading"
                    v-model="editingBlock.heading"
                    :readonly="read_only || (!is_template && locked)"
                    placeholder="Block heading"
                    :class="{invalid: headingInvalid}"
                    @input="updateBlock"
                />
                <el-tooltip
                    v-if="!read_only && (is_template || editingBlock.mandatory)"
                    :content="lockTooltip"
                >
                    <el-button
                        class="control"
                        :icon="locked ? 'el-icon-lock' : 'el-icon-unlock'"
                        plain
                        :type="locked ? null : 'warning'"
                        @click="toggleLocked"
                    />
                </el-tooltip>
                <el-button
                    v-if="!read_only"
                    class="control"
                    plain
                    type="danger"
                    icon="el-icon-delete"
                    :disabled="(!is_template && locked) || loading"
                    @click="deleteBlock"
                />
            </div>
            <div v-if="!read_only" class="prompt">
                <el-input
                    ref="prompt_editor"
                    v-model="editingBlock.prompt"
                    :readonly="(!is_template && locked) || loading"
                    placeholder="AI Prompt"
                    :class="{
                        invalid: promptInvalid,
                    }"
                    @input="updateBlock"
                />
                <el-select
                    v-model="editingBlock.mode"
                    class="select-mode"
                    :disabled="loading"
                    @change="updateBlock"
                >
                    <el-tooltip
                        v-if="!is_template"
                        content="Text is based on the prompt alone. "
                        placement="left"
                    >
                        <el-option
                            :value="DOCUMENT_AI_MODES.PROMPT_ONLY"
                            label="Prompt only"
                        />
                    </el-tooltip>

                    <el-tooltip
                        v-if="!is_template"
                        content="Generated text will consider all non-ai text in the document."
                        placement="left"
                    >
                        <el-option
                            :value="DOCUMENT_AI_MODES.SUMMARISE_DOCUMENT"
                            label="Summarise document"
                        />
                    </el-tooltip>
                </el-select>
                <el-tooltip
                    v-if="!is_template"
                    :content="textGeneratorTooltip"
                    placement="top"
                >
                    <el-popconfirm
                        title="Replace text block with AI generated text?"
                        :disabled="loading"
                        @confirm="handleAIGenerateText"
                    >
                        <el-button
                            slot="reference"
                            class="control"
                            icon="el-icon-refresh"
                            :disabled="is_template || loading || promptInvalid"
                            plain
                        />
                    </el-popconfirm>
                </el-tooltip>
            </div>
            <text-editor
                :editable="!read_only"
                ref="content_editor"
                :content.sync="editingBlock.content"
                placeholder="Content"
                :floating_menu="false"
                :drag_drop="false"
                :class="{
                    invalid: contentInvalid,
                    hidden: is_template || loading,
                }"
                @update:content="updateBlock"
            />
            <div v-if="loading" />
        </template>
        <template v-else>
            <div slot="header">
                <el-skeleton :rows="1" animated />
            </div>
            <el-skeleton :rows="3" animated />
        </template>
    </el-card>
</template>

<script>
import TextEditor from '@/components/editor/TextEditor';
import aiMixin, {AI_MODES} from '@/mixins/ai.mixin';
import {DOCUMENT_AI_MODES, DOCUMENT_BLOCK_TYPES} from '@/enums';
import documentMixin from '@/mixins/document.mixin';
import {Notification} from 'element-ui';

export default {
    name: 'ai-text-block',
    components: {TextEditor},
    mixins: [aiMixin, documentMixin],
    props: {
        block: {
            type: Object,
            required: true,
        },
        is_template: {
            type: Boolean,
            required: true,
        },
        read_only: {
            type: Boolean,
            default: false,
        },
        document: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editingBlock: null,
            locked: true,
            loading: false,
        };
    },
    computed: {
        DOCUMENT_AI_MODES() {
            return DOCUMENT_AI_MODES;
        },
        lockTooltip() {
            if (this.is_template) {
                return `This block is ${
                    this.locked ? 'mandatory' : 'optional'
                }`;
            } else {
                if (this.locked) {
                    return 'This block is mandatory. Unlock to edit title or remove';
                } else {
                    return 'This an unlocked mandatory block. Edit or remove with caution!';
                }
            }
        },
        textGeneratorTooltip() {
            if (this.is_template) {
                return 'You cannot generate a summary on a template.';
            } else {
                return 'Click here to obtain a new AI generated summary.';
            }
        },
        headingInvalid() {
            return !this.editingBlock.heading;
        },
        promptInvalid() {
            return !this.editingBlock.prompt;
        },
        contentInvalid() {
            return !this.editingBlock.content;
        },
    },
    watch: {
        block: {
            handler(newVal, oldVal) {
                this.editingBlock = {...newVal};
                // set locked status from block if:
                // - this is a template block
                // - this is a new block
                // - the block is currently in the locked state
                // if it has been manually unlocked, leave it unlocked
                if (
                    this.isTemplate ||
                    !oldVal ||
                    newVal.id !== oldVal.id ||
                    this.locked
                ) {
                    this.locked = newVal.mandatory;
                }
            },
            immediate: true,
        },
    },
    methods: {
        toggleLocked() {
            this.locked = !this.locked;
            if (this.is_template) {
                this.editingBlock.mandatory = this.locked;
                this.updateBlock();
            }
        },
        handleAIGenerateText: function () {
            if (!this.is_template) {
                let request = 'Prompt: ' + this.editingBlock.prompt + '\n';
                switch (this.editingBlock.mode) {
                    case DOCUMENT_AI_MODES.SUMMARISE_DOCUMENT:
                        request += 'Content: ' + this.getDocumentSummaryText();
                        break;
                    case DOCUMENT_AI_MODES.PROMPT_ONLY:
                        break;
                }
                this.loading = true;

                this.requestSingleAIInteraction(
                    request,
                    AI_MODES.USER_PROMPT.name
                )
                    .then((responses) => {
                        if (responses) {
                            const res = responses[0];
                            const content = {
                                type: 'doc',
                                content: [
                                    {
                                        type: 'paragraph',
                                        content: [
                                            {
                                                type: 'text',
                                                text: res,
                                            },
                                        ],
                                    },
                                ],
                            };
                            this.$refs.content_editor.setContent(content);
                            this.editingBlock.content = content;
                            this.updateBlock();
                        }
                        this.loading = false;
                    })
                    .catch((err) => {
                        console.error('Error in AI generation', err);
                        Notification({
                            type: 'error',
                            title: 'AI Generation error',
                            message: err,
                        });
                        this.loading = false;
                    });
            }
        },
        getDocumentSummaryText() {
            let str = '';
            for (const docBlock of this.document.blocks) {
                if (
                    docBlock.id !== this.editingBlock.id &&
                    docBlock.type !== DOCUMENT_BLOCK_TYPES.AI_TEXT
                ) {
                    if (docBlock.heading && docBlock.content) {
                        str += `Section{{Heading: ${
                            docBlock.heading
                        }\nText: ${this.contentToString(docBlock.content)}}}\n`;
                    } else if (docBlock.heading) {
                        str += `Section{{Heading: ${docBlock.heading}}}\n`;
                    } else if (docBlock.content) {
                        str += `Section{{Text: ${this.contentToString(
                            docBlock.content
                        )}}}\n`;
                    }
                }
            }
            return str;
        },
        contentToString(entry) {
            // note: we could just use json stringify but the styling info is likely to cause issues.
            // on top of drastically increasing the token count (and hence cost of using the api)
            let res = '';
            if (!entry.type) return res;
            switch (entry.type) {
                case 'doc':
                case 'paragraph':
                case 'codeBlock':
                case 'listItem':
                    if (!entry.content) break;
                    entry.content.forEach((contentEntry) => {
                        res += this.contentToString(contentEntry);
                    });
                    break;
                case 'bulletList':
                    res += '\n';
                    if (!entry.content) break;
                    entry.content.forEach((contentEntry) => {
                        res += '-' + this.contentToString(contentEntry) + '\n';
                    });
                    break;
                case 'text':
                    if (entry.text) res += entry.text;
                    break;
                case 'hardBreak':
                    res += '\n\n';
                    break;
                default:
                    console.log(
                        'unknown content type, will use JSON.stringify',
                        entry
                    );
                    res += JSON.stringify(entry.content);
            }
            return res;
        },
        updateBlock() {
            this.$emit('update', this.editingBlock);
        },

        deleteBlock() {
            this.$emit('delete', this.editingBlock.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.heading {
    display: flex;
    align-items: center;

    .grip {
        width: 20px;
        height: 23px;
        text-align: left;
        opacity: 0.25;
        user-select: none;
        cursor: grab;
    }

    ::v-deep .el-input {
        margin-right: 10px;

        .el-input__inner {
            font-weight: bold;
            font-size: 18px;
        }

        &.invalid .el-input__inner {
            border-color: $red;
            transition: background-color 0.5s;

            &:not(:focus) {
                background: rgba($red, 0.03);
                border-color: rgba($red, 0.5);
            }
        }
    }
}

.prompt {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .select-mode {
        margin-right: 10px;
        ::v-deep .el-input {
            .el-input__inner {
                font-weight: normal;
                font-size: 12px;
            }
        }
    }
    ::v-deep .el-input {
        margin-right: 10px;

        .el-input__inner {
            font-weight: normal;
            font-size: 14px;
        }

        &.invalid .el-input__inner {
            border-color: $red;
            transition: background-color 0.5s;

            &:not(:focus) {
                background: rgba($red, 0.03);
                border-color: rgba($red, 0.5);
            }
        }
    }
}
::v-deep .text-editor-wrapper.invalid {
    .ProseMirror {
        &:not(.ProseMirror-focused) {
            background: rgba($red, 0.03);
            border-color: rgba($red, 0.5) !important;
        }

        &.ProseMirror-focused {
            box-shadow: 0 0 0 2px $red !important;
        }
    }
}

.dragging {
    cursor: grabbing;
}

.hidden {
    display: none;
}
</style>
