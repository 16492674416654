<template>
    <el-popover
        v-model="visible"
        popper-class="block-popover"
        placement="top"
        title="Edit Component"
        trigger="click"
    >
        <div
            slot="reference"
            :class="[
                'block',
                `depth-${depth}`,
                cur_node.meta.status,
                is_relative ? 'relative' : null,
                cur_node.meta.type,
                has_time_after ? 'has-time-after' : null,
                has_time_before ? 'has-time-before' : null,
            ]"
            :style="block_style"
        >
            <component
                :is="overlay.chartComponent"
                v-for="overlay of cur_node.overlays"
                :key="overlay.id"
                :tree="tree"
                :cur_node="overlay"
                :day_width="day_width"
                :depth="depth + 1"
                :days="days"
                is_relative
                :relative_start="startDate"
                @update:node="(e) => $emit('update:node', e)"
            />
        </div>
        <div>
            <el-date-picker
                :value="startDate"
                type="date"
                size="mini"
                placeholder="Start date"
                format="dd/MM/yyyy"
                @input="(v) => (startDate = v)"
            />

            <el-date-picker
                :value="endDate"
                type="date"
                size="mini"
                placeholder="End date"
                :picker-options="end_date_picker_options"
                format="dd/MM/yyyy"
                @input="(v) => (endDate = v)"
            />
        </div>
        <div style="text-align: right; margin-top: 10px;">
            <el-button size="mini" type="text" @click="handleCancel">
                cancel
            </el-button>
            <el-button type="primary" size="mini" @click="handleSave">
                save
            </el-button>
        </div>
    </el-popover>
</template>

<script>
import dayjs from 'dayjs';
import {
    adjustedStartDate,
    adjustedEndDate,
    workDaysBetween,
    min,
    max,
} from '@/components/timeline-v2/utils';

export default {
    name: 'block',

    props: {
        tree: {
            type: Object,
            required: true,
        },

        cur_node: {
            type: Object,
            required: true,
        },

        day_width: {
            type: Number,
            required: true,
        },

        depth: {
            type: Number,
            required: true,
        },

        days: {
            type: Array,
            required: false,
            default: () => [],
        },

        is_relative: {
            type: Boolean,
            required: false,
            default: false,
        },

        relative_start: {
            type: Date,
            required: false,
            default: null,
        },
    },

    data: function () {
        return {
            visible: false,
            startDate: this.cur_node.startDate.toDate(),
            endDate: this.cur_node.meta.editableEndDate
                ? this.cur_node.meta.editableEndDate.toDate()
                : null,
        };
    },

    computed: {
        has_time_before() {
            if (
                this.cur_node.startDate.toDate() <
                adjustedStartDate(this.tree).toDate()
            )
                return true;
            return false;
        },

        has_time_after() {
            if (
                this.cur_node.endDate.toDate() >
                adjustedEndDate(this.tree).toDate()
            )
                return true;
            return false;
        },
        end_date_picker_options() {
            return {
                disabledDate: (time) => {
                    return time.getTime() < this.startDate.getTime();
                },
            };
        },

        left() {
            if (this.is_relative) {
                return (
                    workDaysBetween(
                        dayjs(this.relative_start),
                        this.cur_node.startDate
                    ) * this.day_width
                );
            }

            return (
                workDaysBetween(
                    adjustedStartDate(this.tree),
                    max([this.cur_node.startDate, adjustedStartDate(this.tree)])
                ) * this.day_width
            );
        },

        block_style() {
            const blockPosition = {
                left: `${this.left}px`,
                width: `${
                    workDaysBetween(
                        max([
                            adjustedStartDate(this.tree),
                            this.cur_node.startDate,
                        ]),
                        min([this.cur_node.endDate, adjustedEndDate(this.tree)])
                    ) * this.day_width
                }px`,
            };

            return blockPosition;
        },
    },

    methods: {
        // event handlers
        handleCancel() {
            this.visible = false;
        },

        handleSave() {
            this.$emit('update:node', {
                type: `update-${this.cur_node.meta.type}`,
                payload: {
                    ...this.cur_node,
                    startDate: this.startDate,
                    endDate: this.endDate,
                },
            });
            this.visible = false;
        },
    },
};
</script>

<style scoped lang="scss">
.block {
    position: absolute;
    border-radius: 4px;
    height: 18px;
    background-color: rgb(25, 137, 250, 0.75);
    cursor: pointer;
    margin-top: -9px;

    &.relative {
        margin-top: 0px;
    }

    // Stage
    &.depth-2 {
        background-color: rgb(25, 137, 250, 0.25);

        &:hover {
            background-color: darken(rgb(25, 137, 250, 0.25), 10%);
        }
    }

    // Component
    &.depth-3 {
        background-color: $blue;

        &:hover {
            background-color: darken($blue, 10%);
        }
    }

    &.completed {
        background-color: $face_4;

        &:hover {
            background-color: darken($face_4, 10%);
        }
    }

    &.blocker {
        background-color: $face_5;
    }

    &.has-time-before {
        border-left: none;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;

        &::before {
            content: '';
            display: none;
            width: 20px;
            height: 100%;

            background-color: red;
        }
    }
    &.has-time-after {
        border-right: none;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;

        &::after {
            content: '';
            width: 20px;
            height: 100%;

            background-color: green;

            position: absolute;

            right: 0;

            top: 0px;

            display: none;
        }
    }
}

.block-popover {
    .el-input {
        width: 100%;
    }
    .el-input + .el-input {
        margin-top: 5px;
    }
}
</style>
