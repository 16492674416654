<template>
    <div class="fullscreen-container">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'fullscreen-layout',
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.fullscreen-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
</style>
