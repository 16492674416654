import {COMMAND_DEPENDENCIES} from '@/enums';
import store from '@/store';

/**
 * Provides a list of components
 * @type {{provides: string, getResults: CommandProvider.getResults, label: string, filteredBy: null}}
 */
export const ComponentProvider = {
    label: 'Components',
    provides: COMMAND_DEPENDENCIES.COMPONENT,
    filteredBy: [COMMAND_DEPENDENCIES.PROJECT, COMMAND_DEPENDENCIES.STAGE],
    getResults: async (data, filter_string) => {
        if (data[COMMAND_DEPENDENCIES.COMPONENT]) return [];

        // only return results if filter, project or stage is set
        if (!filter_string && !data.project && !data.stage) return null;

        let components = store.getters.components;
        if (data.project) {
            components = components.filter(
                (component) =>
                    component.project === `projects/${data.project.value.id}`
            );
        }
        if (data.stage) {
            components = components.filter(
                (component) =>
                    component.stage === `stages/${data.stage.value.id}`
            );
        }
        if (filter_string) {
            const query = filter_string.toLowerCase();
            components = components.filter(
                (component) =>
                    component.name &&
                    component.name.toLowerCase().includes(query)
            );
        }
        return components
            .map((component) => {
                const project = store.getters.projectWithId(component.project);
                if (project) {
                    const stage = store.getters.stageWithId(component.stage);

                    return {
                        id: component.id,
                        label: component.name,
                        sub_label: `${project.name} - #${stage.number}`,
                        value: component,
                    };
                }
            })
            .filter((x) => x); // filter out unmapped components
    },
    cleanup() {
        // if this provider holds any resources during an operation, dispose of them here
    },
};
