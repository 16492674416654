import request from '@/utils/request';

// {{host}}/quotes
export function getXeroQuotes() {
    return request({
        url: '/quotes',
        method: 'get',
    });
}

// {{host}}/quote
export function createXeroQuote(data) {
    return request({
        url: '/quote',
        method: 'post',
        data: data,
    });
}

// {{host}}/quote/:estimate_id/status
export function updateXeroQuoteStatus(estimate_id, status) {
    return request({
        url: `/quote/${estimate_id}/status`,
        method: 'post',
        data: {status},
    });
}
