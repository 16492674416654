<template>
    <div class="container" @keydown.esc="onEscapePress">
        <rendered-input
            ref="input"
            v-model="text"
            :project="selected_project"
            :is_todo="true"
            placeholder="Type here to add a todo"
            @enter="addTodo"
            @backspace="onBackspacePress"
            @keyup="onKeyUp"
        >
            <template slot="prepend">
                <span
                    v-if="show_user"
                    class="user"
                    :class="{
                        'wants-delete': wants_delete_selected_user,
                        'can-delete': !!selected_user_data,
                    }"
                    @click="clearSelectedUser"
                >
                    <el-popover
                        placement="bottom"
                        trigger="click"
                        popper-class="users_suggestions"
                    >
                        <div slot="reference">
                            {{ display_selected_user }}
                        </div>
                        <ul>
                            <!-- TODO: this list of users for the dropdown could be reduced to just the project team in theory-->
                            <li
                                v-for="(usr, idx) in users"
                                :key="usr.id"
                                :class="{
                                    active:
                                        selected_user_data &&
                                        selected_user_data.id === usr.id,
                                    selected:
                                        selected_user_index > -1 &&
                                        idx === selected_user_index,
                                }"
                                @click="manualSelectUser(idx)"
                            >
                                <span class="label">
                                    {{ usr.name }}
                                </span>
                            </li>
                        </ul>
                    </el-popover>
                </span>
                <span
                    v-if="selected_project && show_project"
                    class="project"
                    :class="{
                        'wants-delete': wants_delete_selected_project,
                        'can-delete': !force_project_id,
                    }"
                    @click="clearSelectedProject"
                >
                    {{ selected_project.ref }}
                </span>
            </template>

            <template
                v-if="show_component_picker && selected_project"
                slot="append"
            >
                <div
                    class="action-icon"
                    :class="{
                        'can-delete': !!selected_component,
                    }"
                    @click="handleClickOnComponent"
                >
                    <el-tooltip
                        :open-delay="200"
                        content="Link Component"
                        placement="top"
                        effect="light"
                        popper-class="todo-popper"
                    >
                        <i v-if="!selected_component" class="el-icon-link" />

                        <span v-else>{{ selected_component.name }}</span>
                    </el-tooltip>

                    <component-picker
                        ref="component"
                        class="component-picker"
                        :project_id="selected_project.id"
                        :component="selected_component"
                        @change="selectComponent"
                    />
                </div>
            </template>

            <template v-if="show_date_picker" slot="append">
                <div class="action-icon-group">
                    <div
                        class="action-icon"
                        :class="{
                            'can-delete': !!due_by_date,
                        }"
                        @click="handleClickOnDate"
                    >
                        <el-tooltip
                            :open-delay="200"
                            content="Due Date"
                            placement="top"
                            effect="light"
                            popper-class="todo-popper"
                        >
                            <i v-if="!due_by_date" class="el-icon-date" />
                            <span>{{ due_by_date | relativeDate }}</span>
                        </el-tooltip>
                    </div>
                </div>

                <el-date-picker
                    ref="due_by"
                    v-model="due_by_date"
                    :default-value="new Date()"
                    class="date-picker"
                    :class="{
                        'has-date-set': !!due_by_date,
                        'date-not-set': !due_by_date,
                    }"
                    :disabled="!is_active"
                    :picker-options="pickerOptions"
                    @change="(val) => update('due_by', val)"
                />
            </template>

            <template slot="append">
                <el-popover
                    v-model="show_hours_input"
                    placement="bottom"
                    trigger="click"
                    @show="showHours"
                    @hide="selectHours"
                >
                    <div slot="reference" class="action-icon">
                        <el-tooltip
                            :open-delay="200"
                            content="Time Estimate"
                            placement="top"
                            effect="light"
                            popper-class="todo-popper"
                        >
                            <span v-if="selected_hours"
                                >{{ selected_hours }}h</span
                            >
                            <i v-else class="el-icon-time" />
                        </el-tooltip>
                    </div>

                    <numeric-input
                        ref="hours"
                        :value="selected_hours"
                        @change="selectHours"
                        @input="inputHours"
                    />
                </el-popover>
            </template>
        </rendered-input>

        <template
            v-if="
                show_project && projects_suggestions.length && !selected_project
            "
        >
            <div
                v-shortkey="{
                    up: ['arrowup'],
                    down: ['arrowdown'],
                    tab: ['tab'],
                    space: ['space'],
                    backspace: ['backspace'],
                }"
                @shortkey="(event) => selectProject(null, event)"
            />
            <div class="projects_suggestions">
                <ul>
                    <li
                        v-for="(project, idx) in projects_suggestions"
                        :key="project.id"
                        :class="{
                            active:
                                selected_project &&
                                selected_project.id === project.id,
                            selected:
                                selected_project_index > -1 &&
                                idx === selected_project_index,
                        }"
                        @click="selectProject(idx)"
                    >
                        <span class="label">
                            <b>{{ project.ref }}</b> - {{ project.name }}
                        </span>
                    </li>
                </ul>
            </div>
        </template>

        <template
            v-if="
                show_user &&
                filtered_user_suggestions.length &&
                !selected_user_data
            "
        >
            <div
                v-shortkey="{
                    up: ['arrowup'],
                    down: ['arrowdown'],
                    tab: ['tab'],
                    space: ['space'],
                    backspace: ['backspace'],
                }"
                @shortkey="(event) => selectUser(null, event)"
            />
            <div class="users_suggestions">
                <ul>
                    <li
                        v-for="(usr, idx) in filtered_user_suggestions"
                        :key="usr.id"
                        :class="{
                            active:
                                selected_user_data &&
                                selected_user_data.id === usr.id,
                            selected:
                                selected_user_index > -1 &&
                                idx === selected_user_index,
                        }"
                        @click="selectUser(idx)"
                    >
                        <span class="label">
                            {{ usr.name }}
                        </span>
                    </li>
                </ul>
            </div>
        </template>
    </div>
</template>

<script>
import RenderedInput from '@/components/editor/RenderedInput';
import ComponentPicker from '@/components/generic/ComponentPicker';
import NumericInput from '@/components/generic/NumericInput';

import userMixin from '@/mixins/user.mixin';

export default {
    name: 'todo-input',
    components: {
        RenderedInput,
        ComponentPicker,
        NumericInput,
    },
    mixins: [userMixin],
    props: {
        selected_user: {
            type: Object,
            default: () => {},
        },
        force_project_id: {
            type: String,
            default: null,
        },
        // If set to true the component will avoid to create the TODO
        // but only return the content
        return_only: {
            type: Boolean,
            default: false,
        },
        source: {
            type: String,
            default: null,
        },
        content: {
            type: String,
            default: null,
        },
        due_by: {
            type: Object,
            default: null,
        },
        estimate: {
            type: Number,
            default: 0,
        },
        component: {
            type: Object,
            default: null,
        },
        show_user: {
            type: Boolean,
            default: true,
        },
        show_project: {
            type: Boolean,
            default: true,
        },
        show_date_picker: {
            type: Boolean,
            default: true,
        },
        show_component_picker: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            is_active: false,
            text: this.content,
            selected_project_index: 0,
            selected_project: null,
            wants_delete_selected_project: false,
            selected_user_index: 0,
            selected_user_data: null,
            wants_delete_selected_user: false,
            due_by_date: null,
            selected_component: null,
            show_hours_input: false,
            input_hours: 0,
            selected_hours: 0,
            pickerOptions: {
                firstDayOfWeek: 1,
            },
            // if empty, backspace will emit a cancel event
            empty: true,
        };
    },
    computed: {
        display_selected_user() {
            if (this.selected_user_data) {
                if (
                    this.selected_user_data.id ===
                    this.$store.getters.session_user.id
                ) {
                    return 'Me';
                }
                return this.selected_user_data.name;
            }

            return 'Me';
        },
        projects() {
            return this.$store.getters.projects;
        },
        projects_suggestions() {
            if (this.force_project_id) return [];
            if (this.selected_project) return [];
            if (this.text && this.text.length >= 2) {
                const search = this.text.toLowerCase();
                return this.projects.filter((project) => {
                    if (project.ref.toLowerCase().includes(search)) return true;
                    if (project.name.toLowerCase().includes(search))
                        return true;
                    return false;
                });
            }
            return [];
        },
        filtered_user_suggestions() {
            if (this.selected_user_data) return [];
            if (this.text) {
                const result = this.text.match(/^@(\w*)/);
                if (result) {
                    const search = result[1].toLowerCase();

                    return this.users
                        .filter((u) => u.id !== this.current_user.id)
                        .filter((u) => u.name.toLowerCase().includes(search));
                }
            }
            return [];
        },
    },
    watch: {
        due_by(val) {
            if (val) {
                this.due_by_date = this.$options.filters
                    .timestamp2moment(val)
                    .toDate();
            } else {
                this.due_by_date = null;
            }
        },
        text: function (val) {
            this.wants_delete_selected_user = false;
            this.wants_delete_selected_project = false;
            this.$emit('dirty', val !== this.content);
        },
        selected_user: function (val) {
            this.selected_user_data = val;
        },
        force_project_id(val) {
            this.selected_project = this.$store.getters.projectWithId(val);
        },
        component: {
            handler(val) {
                this.selected_component = val;
            },
            immediate: true,
        },
        estimate: {
            handler(val) {
                this.selected_hours = val;
            },
            immediate: true,
        },
    },
    created() {
        if (this.selected_user) {
            this.selected_user_data = this.selected_user;
        }

        if (this.force_project_id) {
            this.selected_project = this.$store.getters.projectWithId(
                this.force_project_id
            );
        }
    },
    mounted() {
        if (this.due_by) {
            this.due_by_date = this.$options.filters
                .timestamp2moment(this.due_by)
                .toDate();
        } else {
            this.due_by_date = null;
        }

        this.$nextTick(() => {
            this.is_active = true;
            if (this.$refs.input) {
                this.$refs.input.focus();
            }
        });
    },
    methods: {
        handleClickOnComponent() {
            if (!this.selected_component) {
                this.showComponentPicker();
            } else {
                this.selectComponent(null);
            }
        },
        handleClickOnDate() {
            if (!this.due_by_date) this.showDatePicker();
            else this.clearDate();
        },
        showComponentPicker() {
            this.$refs.component.$refs.select.focus();
        },
        showDatePicker() {
            this.$refs.due_by.focus();
        },
        clearSelectedProject() {
            if (this.force_project_id) return;

            this.selected_project_index = 0;
            this.selected_project = null;
        },
        clearSelectedUser() {
            this.selected_user_index = 0;
            this.selected_user_data = null;
        },
        selectProject(idx, event = null) {
            let clear = true;

            if (idx !== null) {
                this.selected_project_index = idx;
            } else if (event) {
                switch (event.srcKey) {
                    case 'up':
                        if (this.selected_project_index > 0)
                            this.selected_project_index--;
                        clear = false;
                        break;
                    case 'down':
                        if (
                            this.selected_project_index <
                            this.projects_suggestions.length - 1
                        )
                            this.selected_project_index++;
                        clear = false;
                        break;
                    case 'tab':
                        break;
                    case 'space':
                        break;
                    case 'backspace':
                        clear = !!this.selected_project;
                        this.text = this.text.slice(0, -1);
                        break;
                }
            }

            if (clear) {
                console.log('Selecting project');
                this.selected_project = this.projects_suggestions[
                    this.selected_project_index
                ];

                this.selected_project_index = 0;
                this.text = null;
            }

            if (this.$refs.input) this.$refs.input.focus();
        },
        manualSelectUser(idx) {
            this.selected_user_data = this.users[idx];
            this.update('user', this.selected_user_data);
        },
        selectUser(idx, event = null) {
            let clear = true;

            if (idx !== null) {
                this.selected_user_index = idx;
            } else if (event) {
                switch (event.srcKey) {
                    case 'up':
                        if (this.selected_user_index > 0)
                            this.selected_user_index--;
                        clear = false;
                        break;
                    case 'down':
                        if (
                            this.selected_user_index <
                            this.filtered_user_suggestions.length - 1
                        )
                            this.selected_user_index++;
                        clear = false;
                        break;
                    case 'tab':
                        break;
                    case 'space':
                        break;
                    case 'backspace':
                        clear = !!this.selected_user_data;
                        this.text = this.text.slice(0, -1);
                        break;
                }
            }

            if (clear) {
                this.selected_user_data = this.filtered_user_suggestions[
                    this.selected_user_index
                ];

                this.selected_user_index = 0;
                this.text = null;
                if (this.$refs.input) this.$refs.input.focus();
            }
        },
        onKeyUp(content) {
            // any key except enter or backspace
            if (content) this.empty = false;
        },
        onBackspacePress(content) {
            // User will have to press backspace twice to actually delete the object
            // First time marks as empty
            if (content === '') {
                if (!this.empty) {
                    this.empty = true;
                } else {
                    this.clear();
                    if (this.$refs.input) {
                        this.$refs.input.focus();
                    }
                    this.$emit('cancel');
                }
            }
        },
        onEscapePress() {
            this.$emit('cancel');
        },
        update(key, val) {
            this.$emit(`updated:${key}`, val);
        },
        addTodo() {
            if (this.projects_suggestions.length) {
                this.selected_project = this.projects_suggestions[
                    this.selected_project_index
                ];

                this.selected_project_index = 0;
                this.text = null;

                if (this.$refs.input) this.$refs.input.focus();

                return;
            }

            if (this.filtered_user_suggestions.length) {
                this.selected_user_data = this.filtered_user_suggestions[
                    this.selected_user_index
                ];

                this.selected_user_index = 0;
                this.text = null;

                return;
            }

            // Avoid to create a todo with empty text
            if (!this.text) return;

            const todo = {
                assigned_to:
                    !this.selected_user_data || this.selected_user_data.id === 0
                        ? this.current_user.id
                        : this.selected_user_data.id,
                user_id: this.current_user.id,
                text: this.text,
                priority: 0,
                project: this.selected_project
                    ? this.$fire.doc(`projects/${this.selected_project.id}`)
                    : null,
                component: this.selected_component
                    ? this.$fire.doc(`components/${this.selected_component.id}`)
                    : null,
                source: this.source,
                status: 'open',
                due_by: this.due_by_date,
                estimate: this.selected_hours,
            };

            if (!this.return_only) {
                this.$store.dispatch('addTodo', todo);
            }

            this.$emit('created', todo);

            this.text = null;

            if (!this.force_project_id) {
                this.selected_project_index = 0;
                this.selected_project = null;
            }

            // clear selected values for next input
            this.due_by_date = null;
            this.selected_component = null;
        },
        setFocus() {
            if (this.$refs.input) this.$refs.input.focus();
        },
        clear() {
            this.text = null;

            if (!this.force_project_id) {
                this.selected_project_index = 0;
                this.selected_project = null;
            }

            this.selected_user_index = 0;
            this.selected_user_data = null;
        },
        clearDate() {
            this.due_by_date = null;
            this.update('due_by', null);
        },
        selectComponent(component) {
            this.selected_component = component;
            this.update('component', component?.id);
        },
        showHours() {
            this.input_hours = this.selected_hours;
            this.$refs.hours.focus();
        },
        inputHours(value) {
            this.input_hours = value;
        },
        selectHours(value = this.input_hours) {
            this.selected_hours = value;
            this.show_hours_input = false;
            this.input_hours = value;
            this.update('estimate', value);
        },
    },
};
</script>
<style lang="scss" scoped>
.container {
    background: white;
    position: relative;
    flex: 1;
}

::v-deep .editor {
    z-index: 11;
    box-shadow: 0 0 0 2px rgb(220 223 230 / 40%);
    padding: 0;

    .prepend {
        font-size: 14px;

        .project {
            cursor: default;
            padding: 10px 12px;
            border-left: 2px solid rgb(220 223 230 / 40%);
        }

        .user {
            cursor: default;
            padding: 10px 12px;
        }
    }

    .can-delete {
        &:hover,
        &.wants-delete {
            cursor: pointer;
            text-decoration: line-through;
        }
    }

    .el-input-group__append {
        padding: 0px;
        align-items: center;
        justify-content: center;
    }
}

.projects_suggestions,
.users_suggestions {
    display: inline-table;
    position: absolute;
    width: 100%;
    z-index: 100;

    .el-input-group__prepend {
        font-size: 14px;
        visibility: hidden;
        border-right: 1px solid $border-grey;
    }

    ul {
        padding: 0px 0;
        margin: 0;
        list-style: none;
        box-sizing: border-box;
        box-shadow: 0 20px 66px 0 rgba(34, 48, 73, 0.2);
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: white;
        width: 100%;

        li {
            cursor: pointer;
            padding: 0 20px;
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;

            span.label {
                color: $black;
                font-size: 14px;
                display: block;
                flex: 1;
            }

            &.selected {
                box-shadow: inset -4px 0px 0px 0px $blue;
                background-color: rgba($blue, 0.05);
            }
            &:hover {
                box-shadow: inset -4px 0px 0px 0px $orange;
                background-color: rgba($orange, 0.05);
            }
        }
    }
}

.action-icon-group {
    display: inline-flex;
    align-items: center;
    padding: 0 10px;

    .action-icon {
        padding: 10px 3px;
    }
}

.action-icon {
    display: inline-block;
    position: relative;
    padding: 10px 15px;
    text-align: center;
    cursor: pointer;

    span {
        font-size: 12px;
        font-weight: bold;
        color: $blue;
    }

    &:hover {
        color: $blue;
    }

    &.remove:hover {
        color: $red;
    }

    .component-picker {
        pointer-events: none;
        width: 0;
        opacity: 0;
        position: absolute;
        left: -5px;
    }
}

::v-deep .date-picker {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    right: 308px;
    bottom: 0;

    input {
        width: 0;
        height: 0;
        padding: 0;
        background-color: transparent;
        border: none;
    }
}
</style>
