<template>
    <content-block v-if="filtered_calendar.length" title="Events" collapsable>
        <div slot="right" style="float: right;">
            <el-switch
                v-model="show_ignored"
                active-text="Show Ignored"
                inactive-text="Hide Ignored"
            />
        </div>

        <div class="items">
            <calendar-event-item
                v-for="item in filtered_calendar"
                :key="item.id"
                :event="item"
                :projects="projects"
                :ignored_events="ignored_events"
            />
        </div>
    </content-block>
</template>

<script>
import {momentWithTz} from '@/utils';

import ContentBlock from '@/components/blocks/ContentBlock';
import CalendarEventItem from './CalendarEventItem';
import {PROJECT_STATUS} from '@/enums';

export default {
    name: 'calendar-events',
    components: {
        CalendarEventItem,
        ContentBlock,
    },
    data() {
        return {
            calendar_ignore: null,
            calendar: [],
            show_ignored: false,
        };
    },
    computed: {
        projects() {
            return this.$store.getters.projects;
        },
        ignored_events() {
            if (!this.calendar_ignore || !this.calendar_ignore.ignored_events)
                return [];
            return this.calendar_ignore.ignored_events;
        },
        filtered_calendar() {
            return this.calendar.filter((cal) => {
                if (
                    (!this.show_ignored &&
                        this.ignored_events.includes(cal.id)) ||
                    this.ignored_events.includes(cal.recurringEventId)
                )
                    return false;
                return true;
            });
        },
    },
    mounted() {
        const start = momentWithTz().startOf('day').toDate();
        const end = momentWithTz().startOf('day').add(2, 'month').toDate();
        const user = this.$store.getters.session_user;

        this.$bind('calendar_ignore', this.$fire.doc('system/calendar'), {
            maxRefDepth: 0,
        });

        this.$bind(
            'calendar',
            this.$fire
                .collection('calendar')
                .where('project', '==', null)
                .where('start', '>', start)
                .where('start', '<', end)
                .where('attendee_emails', 'array-contains', user.email),
            {maxRefDepth: 0}
        );
    },
};
</script>

<style lang="scss" scoped>
.content-block {
    margin-top: 10px;

    .items {
        padding: 20px 0px;
        display: flex;
        flex-wrap: wrap;
    }
}
</style>
