<template>
    <a :href="link" target="_blank" class="link" @click.stop="null">
        <svgicon class="confluence_icon" name="confluence" />
        {{ title }}
        <el-tag class="space" size="mini" type="info">{{ space }}</el-tag>
    </a>
</template>

<script>
const confluence_rgx = /^http[s]{0,1}:\/\/([A-Za-z-]*)\.(atlassian.net)\/wiki\/spaces\/([A-Za-z]*)\/pages\/([0-9]*)($|\/(.*))/i;

export default {
    name: 'confluence-link',
    props: {
        link: {
            type: String,
            required: true,
        },
    },
    computed: {
        regex_match() {
            const matches = this.link.match(confluence_rgx);
            return matches;
        },
        page_id() {
            return this.regex_match[4];
        },
        title() {
            if (this.regex_match.length > 6) {
                return decodeURI(this.regex_match[6]).replaceAll('+', ' ');
            }

            return '';
        },
        space() {
            return this.regex_match[3];
        },
    },
};
</script>

<style lang="scss" scoped>
.link {
    display: inline-block;
    border-radius: 4px;
    text-decoration: none;

    .confluence_icon {
        width: 10px;
        height: 10px;
        cursor: pointer;
    }

    .space {
        font-size: 9px;
        text-transform: uppercase;
        margin-left: 4px;
        align-items: center;
        height: 14px;
        line-height: 14px;
    }
}
</style>
