import request from '@/utils/request';

const toQueryString = (params) => {
    return Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&');
};

const bitbucket = (team = 'codefishstudio') => ({
    workspaces: {
        get() {
            return request({
                url: 'bitbucket/api/workspaces',
                method: 'get',
            });
        },
    },
    repository: (repo) => ({
        get() {
            return request({
                url: `bitbucket/api/repositories/${team}/${repo}`,
                method: 'get',
            });
        },
        async branches(options = {}, all_pages = false) {
            const getResult = async (page = 1) => {
                const result = await request({
                    url: `bitbucket/api/repositories/${team}/${repo}/refs?sort=-target.date&${toQueryString(
                        {...options, page}
                    )}`,
                    method: 'get',
                });
                return result;
            };

            let page = 0;
            let is_finished = false;

            const values = [];

            while (!is_finished) {
                const res = await getResult(page + 1);
                page = res.page;
                values.push(...res.values);
                if (!all_pages || page === Math.ceil(res.size / 10)) {
                    return {...res, values};
                }
            }
        },
        branch: (branch) => ({
            statuses() {
                return request({
                    url: `bitbucket/api/repositories/${team}/${repo}/commit/${branch}/statuses`,
                    method: 'get',
                });
            },
        }),
        commits: {
            get(options = {}) {
                return request({
                    url: `bitbucket/api/repositories/${team}/${repo}/commits?${toQueryString(
                        options
                    )}`,
                    method: 'get',
                });
            },
            branch: (branch, options = {}) => {
                return request({
                    url: `bitbucket/api/repositories/${team}/${repo}/commits/${branch}?${toQueryString(
                        options
                    )}`,
                    method: 'get',
                });
            },
        },
        refs: () => ({
            tags: (options = {}) => {
                return request({
                    url: `bitbucket/api/repositories/${team}/${repo}/refs/tags?${toQueryString(
                        options
                    )}`,
                    method: 'get',
                });
            },
        }),
        commit: (hash) => ({
            get() {
                return request({
                    url: `bitbucket/api/repositories/${team}/${repo}/commit/${hash}`,
                    method: 'get',
                });
            },

            statuses() {
                return request({
                    url: `bitbucket/api/repositories/${team}/${repo}/commit/${hash}/statuses`,
                    method: 'get',
                });
            },
        }),
        pullrequests: (id = null) => ({
            //https://api.bitbucket.org/2.0/repositories/:workspace/:repo/pullrequests?q=destination.branch.name+%3D+%22staging%22+AND+state%3D+%22MERGED%22
            get(options = {}) {
                return request({
                    url: `bitbucket/api/repositories/${team}/${repo}/pullrequests?${toQueryString(
                        options
                    )}`,
                    method: 'get',
                });
            },
            commits(options = {}) {
                return request({
                    url: `bitbucket/api/repositories/${team}/${repo}/pullrequests/${id}/commits?${toQueryString(
                        options
                    )}`,
                    method: 'get',
                });
            },
        }),
    }),
    repositories: {
        get(page) {
            return request({
                url: `bitbucket/api/repositories/${team}?${
                    page ? `page=${page}` : ''
                }`,
                method: 'get',
            });
        },
    },
    projects: {
        get(page) {
            return request({
                url: `bitbucket/api/workspaces/${team}/projects?${
                    page ? `page=${page}` : ''
                }`,
                method: 'get',
            });
        },
    },
    project: (proj) => ({
        repositories() {
            return request({
                url: `bitbucket/api/repositories/${team}?q=project.key%3D%22${proj}%22&pagelen=50`,
                method: 'get',
            });
        },
    }),
});

export default bitbucket;
