<template>
    <div>
        <my-calendar-events />
        <calendar-events v-only-super-admin />
    </div>
</template>

<script>
import MyCalendarEvents from '../components/MyCalendarEvents';
import CalendarEvents from '@/components/calendar/CalendarEvents';

export default {
    name: 'home-tab-events',
    components: {
        MyCalendarEvents,
        CalendarEvents,
    },
};
</script>

<style lang="scss" scoped>
//
</style>
