/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jira': {
    width: 73.275,
    height: 75.76,
    viewBox: '0 0 73.275 75.76',
    data: '<defs id="defs841"><linearGradient gradientUnits="userSpaceOnUse" y2="30.99" x2="19" y1="15.35" x1="34.64" id="linear-gradient"><stop id="stop835" stop-color="#0052cc" offset=".18"/><stop id="stop837" stop-color="#2684ff" offset="1"/></linearGradient><linearGradient xlink:href="#linear-gradient" y2="44.67" x2="54.39" y1="60.28" x1="38.78" id="linear-gradient-2"/><linearGradient y2="30.99" x2="19" y1="15.35" x1="34.64" gradientUnits="userSpaceOnUse" id="linearGradient883" xlink:href="#linear-gradient"/></defs><g transform="translate(-.003)" id="g919"><path pid="0" d="M72.4 35.76L39.8 3.16 36.64 0 12.1 24.54.88 35.76a3 3 0 000 4.24L23.3 62.42l13.34 13.34 24.54-24.54.38-.38L72.4 40a3 3 0 000-4.24zM36.64 49.08l-11.2-11.2 11.2-11.2 11.2 11.2z" id="path869" _fill="#2684ff"/><path pid="1" d="M36.64 26.68A18.86 18.86 0 0136.56.09l-24.51 24.5 13.34 13.34z" id="path871" _fill="url(#linearGradient883)"/><path pid="2" d="M47.87 37.85L36.64 49.08a18.86 18.86 0 010 26.68l24.57-24.57z" id="path873" _fill="url(#linear-gradient-2)"/></g>'
  }
})
