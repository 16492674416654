<template>
    <div class="project-month">
        <div
            class="value-display"
            :class="[month_status, {show_invoices}]"
            ref="project_month_value"
            @click="toggleShowInvoices"
        >
            <i :class="icon" />
            <span class="value">{{ value.total | currency }}</span>
        </div>
        <div
            class="invoice-list"
            :class="popup_class"
            v-if="show_invoices"
            v-on-clickaway="(event) => toggleShowInvoices(event, false)"
        >
            <div
                class="invoice-row"
                v-for="invoice of value.invoices"
                :key="invoice.invoice_number"
                @click="handleClickInvoice(invoice, true)"
            >
                <div>
                    <div
                        class="value-display invoice-number"
                        :class="invoice.status"
                    >
                        <i
                            class="icon"
                            :class="getIconForStatus(invoice.status)"
                        />
                        <span class="value">
                            {{ invoice.invoice_number ?? 'Pending' }}
                        </span>
                    </div>
                    <span class="reference">
                        <b
                            v-if="
                                invoice.status === 'scheduled' ||
                                invoice.status === 'unscheduled'
                            "
                            >{{ fireRef2id(invoice.estimate) }}</b
                        >
                        <b v-else>{{ invoice.reference }}</b>
                    </span>
                </div>
                <span class="amount">{{ invoice.amount | currency }}</span>
                <span class="gst">(inc. GST)</span>
                <i class="arrow el-icon-arrow-right" />
            </div>
        </div>
    </div>
</template>

<script>
import {fireRef2id} from '@/filters';
import ReferenceTemplateMixin from '@/mixins/referencetemplate.mixin';
import {mixin as clickaway} from 'vue-clickaway';

export default {
    name: 'project-month',
    mixins: [ReferenceTemplateMixin, clickaway],
    props: {
        value: {
            type: Object,
            required: true,
        },
        project_path: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            show_invoices: false,
            popup_class: '',
        };
    },
    computed: {
        month_status() {
            const ranked_statuses = [
                'paid',
                'sent',
                'authorised',
                'scheduled',
                'unscheduled',
                'overdue',
            ];

            // check all invoice statuses in this month to get aggregate status
            let group_rank = null;
            if (this.value) {
                for (const invoice of this.value.invoices) {
                    const invoice_rank = ranked_statuses.indexOf(
                        invoice.status
                    );
                    if (!group_rank || group_rank < invoice_rank) {
                        group_rank = invoice_rank;
                    }
                }
                return ranked_statuses[group_rank];
            }
            return null;
        },
        icon() {
            return this.getIconForStatus(this.month_status);
        },
    },
    methods: {
        fireRef2id,
        getIconForStatus(status) {
            switch (status) {
                case 'paid':
                    return 'icon el-icon-check';
                case 'scheduled':
                    return 'icon el-icon-time';
                case 'unscheduled':
                    return 'icon el-icon-more';
                default:
                    return 'icon el-icon-warning-outline';
            }
        },
        toggleShowInvoices(event, state = null) {
            if (state === null) {
                state = !this.show_invoices;
            }

            if (state) {
                // set popup position class
                let position_class = '';
                // approximate popup dimensions
                const popup_width = 400;
                const popup_height = 30 * this.value.invoices.length + 20;
                // determine position of component on screen
                const position = this.$refs.project_month_value.getBoundingClientRect();
                // close to bottom of screen (accounting for possible footer height)
                if (position.bottom + popup_height > window.innerHeight - 180)
                    position_class += ' top';
                // close to left
                if (position.left - popup_width / 2 < 0)
                    position_class += ' left';
                // close to right
                if (position.right + popup_width / 2 > window.innerWidth)
                    position_class += ' right';

                this.popup_class = position_class;
            }

            this.show_invoices = state;
        },
        handleClickInvoice(invoice, newTab = false) {
            let data;
            if (invoice.path.startsWith('invoices')) {
                // xero invoice
                data = {
                    name: 'management_invoices_detail',
                    params: {
                        invoice_number: fireRef2id(invoice.path),
                    },
                };
            } else {
                data = {
                    name: 'project_detail_paymentplan_invoice',
                    params: {
                        project_id: fireRef2id(this.project_path),
                        invoice_id: fireRef2id(invoice.path),
                    },
                };
            }

            if (newTab) {
                let routeData = this.$router.resolve(data);
                window.open(routeData.href, '_blank');
            } else {
                this.$router.push(data);
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.project-month {
    width: 100%;
    position: relative;

    .value-display {
        padding: 10px;
        border-radius: 4px;
        color: $black;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        // default status (mixed)
        background-color: #bdd54d33;

        .icon {
            color: #bdd54d;
        }

        // status styles
        &.paid {
            background-color: rgba($green-dark, 0.2);
            .icon {
                color: $green-dark;
            }
        }

        &.scheduled {
            background-color: $grey;
            .icon {
                color: #00000033;
            }
        }

        &.unscheduled {
            background-color: rgba($orange-soft, 0.2);
            .icon {
                color: $orange-dark;
            }
        }

        &.overdue {
            background-color: rgba($red-soft, 0.2);
            .icon {
                color: $red;
            }
        }

        &.show_invoices {
            box-shadow: 0 0 0 2px $blue;
        }

        .icon {
            flex: 0;
        }

        .value {
            flex: 1;
            text-align: right;
        }
    }

    .invoice-list {
        --popup-width: 400px;
        --invoice-row-height: 25px;

        position: absolute;
        width: var(--popup-width);
        padding: 10px;
        background: white;
        border-radius: 4px;
        box-shadow: 0 0 66px 0 rgba(34, 48, 73, 0.2);
        z-index: 2;
        // default position
        top: 40px;
        left: 50%;
        margin-left: calc(var(--popup-width) / -2);

        &.left {
            left: 0;
            margin-left: 0;
        }

        &.right {
            left: auto;
            right: 0;
            margin-left: 0;
        }

        &.top {
            top: auto;
            bottom: 40px;
        }

        .invoice-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            white-space: nowrap;
            cursor: pointer;
            height: var(--invoice-row-height);

            &:not(:last-child) {
                padding-bottom: 5px;
                margin-bottom: 5px;
                border-bottom: 1px solid $border-grey-light;
            }

            > *:first-child {
                flex: 1;
            }

            .invoice-number {
                width: 80px;
                text-align: center;
                display: inline-flex;
                padding: 5px 10px;
                margin-right: 10px;

                .value {
                    opacity: 0.5;
                }
            }

            .reference {
                color: $black;
            }

            .amount {
                color: $black;
                margin-left: 10px;
            }

            .gst {
                font-size: 8px;
            }

            .arrow {
                font-size: 16px;
                opacity: 0.5;
            }
        }
    }
}
</style>
