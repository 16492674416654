<template>
    <el-select
        filterable
        :value="stage"
        placeholder="Select stage"
        value-key="id"
        size="mini"
        @change="(val) => $emit('change', val)"
    >
        <el-option
            v-for="s in stages"
            :key="s.id"
            :label="`Stage #${s.number}`"
            :value="s"
        >
            <span style="font-size: 13px; margin-right: 30px;">
                Stage #{{ s.number }}
            </span>
            <span
                v-if="s.notes"
                style="color: #8492a6; font-size: 11px; float: right;"
            >
                {{ s.notes }}
            </span>
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: 'stage-picker',
    props: {
        project_id: {
            type: String,
            required: true,
        },
        stage: {
            type: Object,
            default: null,
        },
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(this.project_id);
        },
        stages() {
            if (!this.project) return [];
            return this.$store.getters.stagesForProjectWithId(this.project.id);
        },
    },
};
</script>
