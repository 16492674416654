<template>
    <div>
        <div class="container" v-only-super-admin>
            <label>
                Sonos
                <span v-if="is_authenticated && household">
                    - {{ household.name }}
                </span>
            </label>

            <div class="right">
                <el-button
                    v-if="loading"
                    v-loading="true"
                    disabled
                    class="spinner"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0)"
                >
                    <i class="el-icon-loading" style="visibility: hidden;" />
                </el-button>
                <template v-else>
                    <el-button
                        v-if="!is_authenticated"
                        :disabled="loading"
                        @click="redirectToSonosAuth"
                    >
                        Connect
                    </el-button>
                    <template v-else>
                        <el-button disabled>
                            Connected
                        </el-button>
                        <el-button
                            style="flex-grow: inherit;"
                            @click="handleDisconnect"
                        >
                            <i class="el-icon-delete" />
                        </el-button>
                    </template>
                </template>
            </div>
        </div>

        <template v-if="is_authenticated">
            <sonos-group-player
                v-for="group in groups"
                :key="group.id"
                :group="group"
                :show_name="groups.length > 1"
            />
        </template>
    </div>
</template>

<script>
import {redirectUserToSonos, disconnectSonos} from '@/api/sonos-auth';

import SonosGroupPlayer from '@/components/generic/SonosGroupPlayer';

export default {
    name: 'sonos-integration',
    components: {SonosGroupPlayer},
    data() {
        return {
            loading: false,
        };
    },
    props: {
        show_dialog: {
            type: Boolean,
            required: true,
        },
    },
    mounted() {},
    computed: {
        is_authenticated() {
            return this.$store.getters.is_sonos_authenticated;
        },
        household() {
            return this.$store.getters.sonos_data.household ?? null;
        },
        groups() {
            return this.$store.getters.sonos_data.groups ?? [];
        },
    },
    methods: {
        redirectToSonosAuth() {
            this.loading = true;

            redirectUserToSonos()
                .then((result) => {
                    window.location = result;
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async handleDisconnect() {
            this.loading = true;

            try {
                await disconnectSonos();
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
span {
    margin-right: 10px;
    opacity: 0.8;
    font-size: 12px;

    &.last-updated {
        font-size: 10px;
    }
}
</style>
