import {CR_STATUS} from '../enums';

const changeRequestsMixin = {
    methods: {
        status2TagType(status) {
            switch (status) {
                case CR_STATUS.REQUESTED:
                    return 'info';
                case CR_STATUS.BACKLOG:
                    return 'danger';
                case CR_STATUS.SCOPING:
                    return 'danger';
                case CR_STATUS.PRE_APPROVAL:
                    return 'warning';
                case CR_STATUS.QUOTING:
                    return 'warning';
                case CR_STATUS.CONTRACT:
                    return 'default';
                case CR_STATUS.IN_PROGRESS:
                    return 'default';
                case CR_STATUS.CLOSED:
                    return 'success';
                case CR_STATUS.ARCHIVED:
                    return 'info';
                default:
                    return 'info';
            }
        },
        status2Label(status) {
            switch (status) {
                case CR_STATUS.REQUESTED:
                    return 'Requested';
                case CR_STATUS.BACKLOG:
                    return 'Backlog';
                case CR_STATUS.SCOPING:
                    return 'Scoping';
                case CR_STATUS.PRE_APPROVAL:
                    return 'Pre-approval';
                case CR_STATUS.QUOTING:
                    return 'Quoting';
                case CR_STATUS.CONTRACT:
                    return 'Contract';
                case CR_STATUS.IN_PROGRESS:
                    return 'In progress';
                case CR_STATUS.CLOSED:
                    return 'Closed';
                case CR_STATUS.ARCHIVED:
                    return 'Archived';
            }
        },
    },
};

export default changeRequestsMixin;
