export const Navigations = [
    {
        id: 'nav_11',
        label: 'Home',
        sub_label: '',
        keywords: '',
        value: '/',
    },
    {
        id: 'nav_12',
        label: 'Events',
        sub_label: '',
        keywords: '',
        value: '/events',
    },
    {
        id: 'nav_13',
        label: 'Team',
        sub_label: '',
        keywords: '',
        value: '/team',
    },
    {
        id: 'nav_3',
        label: 'Sessions',
        sub_label: '',
        keywords: 'sessions tracking timer timers start stop face cube',
        value: '/sessions',
    },
];

export const AdminNavigations = [
    {
        id: 'nav_41',
        label: 'Management',
        sub_label: 'Todos',
        value: '/manage/todos',
    },
    {
        id: 'nav_42',
        label: 'Management',
        sub_label: 'Projects',
        value: '/manage/projects',
    },
    {
        id: 'nav_43',
        label: 'Management',
        sub_label: 'Clients',
        value: '/manage/clients',
    },
    {
        id: 'nav_44',
        label: 'Management',
        sub_label: 'Invoices',
        value: '/manage/invoices',
    },
    {
        id: 'nav_45',
        label: 'Management',
        sub_label: 'Payment Plans',
        value: '/manage/paymentplans',
    },
    {
        id: 'nav_46',
        label: 'Management',
        sub_label: 'Overview',
        value: '/manage/overview',
    },
    {
        id: 'nav_47',
        label: 'Management',
        sub_label: 'Users',
        value: '/manage/users',
    },
    {
        id: 'nav_48',
        label: 'Management',
        sub_label: 'Document Templates',
        value: '/manage/templates',
    },
    {
        id: 'nav_481',
        label: 'Management',
        sub_label: 'New Document Template',
        value: '/document/estimate/template/new',
    },
];
