<template>
    <el-form label-width="160px" :model="project">
        <!-- SETTINGS -->
        <!--*******************************************-->

        <content-block title="Settings">
            <!-- NAME -->
            <!--*******************************************-->

            <el-form-item label="Name">
                <el-input v-model="project.name" @change="update('name')" />
            </el-form-item>

            <!-- REFERENCE -->
            <!--*******************************************-->

            <el-form-item label="Reference">
                <el-input
                    v-model="project.ref"
                    name="ref"
                    maxlength="3"
                    @change="(v) => update('ref', v?.toUpperCase())"
                />
            </el-form-item>

            <!-- DESCRIPTION -->
            <!--*******************************************-->

            <el-form-item label="Description">
                <el-input
                    v-model="project.description"
                    type="textarea"
                    :rows="2"
                    autosize
                    placeholder="Please input"
                    @change="update('description')"
                />
            </el-form-item>

            <hr />

            <!-- STATUS -->
            <!--*******************************************-->

            <el-form-item label="Status">
                <el-select
                    v-model="project.status"
                    placeholder="Select"
                    style="width: 100%;"
                    @change="update('status')"
                >
                    <el-option
                        v-for="item in status_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    />
                </el-select>
            </el-form-item>

            <!-- JIRA -->
            <!--*******************************************-->

            <div v-if="is_atlassian_authed">
                <hr />

                <el-form-item
                    label="Jira org"
                    prop="jira_org"
                    :rules="[
                        {
                            validator: handleValidateDomainPrefix,
                            trigger: 'blur',
                        },
                    ]"
                >
                    <el-input
                        v-model="project.jira_org"
                        clearable
                        placeholder="Organisation name - <orgname>.atlassian.net - leave blank to disable"
                        @change="updateJiraOrg"
                    >
                        <el-button
                            slot="append"
                            :icon="
                                jira_projects === null
                                    ? 'el-icon-loading'
                                    : 'el-icon-refresh'
                            "
                            @click="updateJiraOrg"
                        />
                    </el-input>
                </el-form-item>
                <el-form-item label="Project">
                    <el-select
                        v-model="project.jira_project"
                        v-loading="jira_projects === null"
                        element-loading-spinner="el-icon-loading"
                        :disabled="jira_projects === false"
                        :placeholder="jiraProjectsPlaceholder"
                        style="width: 100%;"
                        clearable
                        @change="update('jira_project')"
                    >
                        <el-option
                            v-for="item in jira_projects"
                            :key="item.key"
                            :label="item.name"
                            :value="item.key"
                        />
                    </el-select>
                </el-form-item>

                <hr />

                <!-- CONFLUENCE -->
                <!--*******************************************-->

                <el-form-item
                    label="Confluence org"
                    prop="confluence_org"
                    :rules="[
                        {
                            validator: handleValidateDomainPrefix,
                            trigger: 'blur',
                        },
                    ]"
                >
                    <el-input
                        v-model="project.confluence_org"
                        clearable
                        placeholder="Organisation name - <orgname>.atlassian.net - leave blank to disable"
                        @change="updateConfluenceOrg"
                    >
                        <el-button
                            slot="append"
                            :icon="
                                confluence_spaces === null
                                    ? 'el-icon-loading'
                                    : 'el-icon-refresh'
                            "
                            @click="updateConfluenceOrg"
                        />
                    </el-input>
                </el-form-item>
                <el-form-item label="Space">
                    <el-select
                        v-model="project.confluence_space"
                        v-loading="confluence_spaces === null"
                        element-loading-spinner="el-icon-loading"
                        :disabled="confluence_spaces === false"
                        placeholder="Select a space to enable Confluence integration"
                        style="width: 100%;"
                        clearable
                        @change="update('confluence_space')"
                    >
                        <el-option
                            v-for="item in confluence_spaces"
                            :key="item.key"
                            :label="item.name"
                            :value="item.key"
                        />
                    </el-select>
                </el-form-item>
            </div>
        </content-block>

        <!-- MODULES -->
        <!--*******************************************-->

        <content-block
            v-if="$store.getters.is_bitbucket_authed"
            title="Modules"
        >
            <ul class="modules">
                <module-block
                    v-for="module in modules"
                    :key="module.id"
                    :module="module"
                    @click="handleClickOnModule"
                />

                <div v-only-admin class="new" @click="handleClickOnNewModule">
                    <svgicon name="plus-square" class="icon" />
                </div>
            </ul>
        </content-block>
    </el-form>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import ModuleBlock from '@/components/blocks/ModuleBlock';

import {getConfluenceSpaces, getJiraProjects} from '@/api/atlassian';
import {PROJECT_STATUS} from '@/enums';

import userMixin from '@/mixins/user.mixin';

export default {
    name: 'project-tab-settings',
    components: {
        ContentBlock,
        // FileUploader,
        ModuleBlock,
    },
    mixins: [userMixin],
    data() {
        return {
            project: null,
            status_options: [
                {
                    label: 'Lead',
                    value: PROJECT_STATUS.LEAD,
                },
                {
                    label: 'Active',
                    value: PROJECT_STATUS.ACTIVE,
                },
                {
                    label: 'Maintenance',
                    value: PROJECT_STATUS.MAINTENANCE,
                },
                {
                    label: 'Archived',
                    value: PROJECT_STATUS.ARCHIVED,
                },
            ],

            // Atlassian states -
            // false: no data (disabled); null: loading; array: data
            jira_projects: false,
            confluence_spaces: false,
        };
    },
    computed: {
        modules() {
            return this.$store.getters.modulesForProjectWithId(
                this.$route.params.project_id
            );
        },
        jiraProjectsPlaceholder() {
            return (
                'Select a project to enable Jira integration' +
                (this.jira_projects
                    ? ` (${this.jira_projects.length} projects)`
                    : '')
            );
        },
        _project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
    },
    watch: {
        _project(val) {
            this.project = val;
        },
    },
    created() {
        this.project = this.$store.getters.projectWithId(
            this.$route.params.project_id
        );
    },
    methods: {
        update(key, val = null) {
            let value = val ? val : this.project[key];

            this.$store.dispatch('updateProjectWithId', {
                project_id: this.$route.params.project_id,
                key,
                value,
            });
        },
        async updateJiraOrg() {
            this.update('jira_org');
            this.clearJiraProjects();
            if (
                this.project.jira_org &&
                this.project.jira_org !== '' &&
                this.validateDomainPrefix(this.project.jira_org)
            ) {
                this.jira_projects = null;
                try {
                    const result = await getJiraProjects(this.project.jira_org);
                    const projects = result.data.projects;
                    if (projects && projects.length > 0) {
                        this.jira_projects = projects;
                    }
                } catch (e) {
                    // Unable to load project data
                    console.log(
                        'Error loading Jira project data for org',
                        this.project.jira_org,
                        e
                    );
                }
            }
        },
        async updateConfluenceOrg() {
            this.update('confluence_org');
            this.clearConfluenceSpace();
            if (
                this.project.confluence_org &&
                this.project.confluence_org != '' &&
                this.validateDomainPrefix(this.project.confluence_org)
            ) {
                this.confluence_spaces = null;
                try {
                    const result = await getConfluenceSpaces(
                        this.project.confluence_org
                    );
                    const spaces = result.data.spaces;
                    if (spaces && spaces.length > 0) {
                        this.confluence_spaces = spaces;
                    }
                } catch (e) {
                    // Unable to load project data
                    console.log(
                        'Error loading Confluence space data for org',
                        this.project.confluence_org,
                        e
                    );
                }
            }
        },

        clearJiraProjects() {
            this.project.jira_project = null;
            this.update('jira_project');
            this.jira_projects = false;
        },
        clearConfluenceSpace() {
            this.project.confluence_space = null;
            this.update('confluence_space');
            this.confluence_spaces = false;
        },

        handleValidateDomainPrefix(rule, value, callback) {
            if (value === '') {
                callback(); // empty is valid
                return;
            }

            if (!this.validateDomainPrefix(value)) {
                callback(
                    new Error(
                        'Must be a valid domain prefix (letters, numbers & hyphens only)'
                    )
                );
            } else {
                callback();
            }
        },
        validateDomainPrefix(domain) {
            if (domain) {
                return domain.match(/[a-zA-Z0-9-]/);
            }
            return false;
        },
        // saveIcon(val) {
        //     this.update('icon', val);
        // },
        handleClickOnNewModule() {
            this.$router.push({
                name: 'module_new',
                params: {
                    project_id: this.project.id,
                },
            });
        },
        handleClickOnModule(module) {
            this.$router.push({
                name: 'module_settings',
                params: {
                    project_id: this.project.id,
                    module_id: module.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
section {
    margin: 0;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: $grey;

    h3 {
        margin-top: 0;
    }
}

.item {
    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }
}

hr {
    margin: 20px 50px;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}

.modules {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    margin-bottom: -10px;
    list-style: none;

    .new {
        height: 90px;
        width: 200px;
        padding: 20px;
        border-radius: 5px;
        background-color: $grey;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid $border-grey-light;
        box-sizing: border-box;
        margin-bottom: 10px;

        .icon {
            width: 25px;
            height: 25px;
            fill: rgba($black, 0.5);
        }

        span {
            color: rgba($black, 0.5);
            font-size: 14px;
            margin-top: 8px;
        }

        &:hover {
            box-shadow: 0 0 0 1px $blue;
            border: 1px solid $blue;

            .icon {
                fill: $blue;
            }

            span {
                color: $blue;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .el-form .el-form-item {
        display: flex;
        flex-direction: column;

        ::v-deep {
            .el-form-item__label {
                text-align: left;
            }

            .el-form-item__content {
                margin-left: 0 !important;

                .el-slider {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>

<style lang="scss">
.el-input-group__append {
    min-width: 0;
}
</style>
