<template>
    <div>
        <div class="block">
            <el-button @click="moveComponent">Move component</el-button>
        </div>
        <div class="block_options">
            <el-input
                v-model="move_component.component"
                size="mini"
                placeholder="Component"
            />
            <el-input
                v-model="move_component.to_stage"
                size="mini"
                placeholder="To Stage"
            />
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';

import {Notification} from 'element-ui';

export default {
    name: 'move-component',
    data() {
        return {
            move_component: {
                component: null,
                to_stage: null,
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {
        async moveComponent() {
            Notification({
                type: 'info',
                title: 'Fetching',
                message: 'component to move...',
            });

            const component__snap = await this.$fire
                .doc(`components/${this.move_component.component}`)
                .get();

            const component = await component__snap.data();

            const from_stage__snap = await this.$fire
                .doc(`stages/${component.stage.id}`)
                .get();

            const to_stage__snap = await this.$fire
                .doc(`stages/${this.move_component.to_stage}`)
                .get();

            const from_stage = await from_stage__snap.data();
            const to_stage = await to_stage__snap.data();

            if (from_stage.project.id !== to_stage.project.id) {
                Notification({
                    type: 'error',
                    title: 'Move only allowed on same project',
                });
                return;
            }

            const from_update = {};
            const to_update = {};

            // Move tracked time if any
            if (component.time_spent !== undefined) {
                for (
                    var i = 0;
                    i < Object.keys(component.time_spent).length;
                    i++
                ) {
                    const user_id = Object.keys(component.time_spent)[i];
                    const duration = component.time_spent[user_id];

                    from_update[
                        `time_spent.${user_id}`
                    ] = firebase.firestore.FieldValue.increment(-duration);
                    to_update[
                        `time_spent.${user_id}`
                    ] = firebase.firestore.FieldValue.increment(duration);
                }
            }

            from_update[
                'components'
            ] = firebase.firestore.FieldValue.arrayRemove(
                this.$fire.doc(`components/${this.move_component.component}`)
            );

            to_update['components'] = firebase.firestore.FieldValue.arrayUnion(
                this.$fire.doc(`components/${this.move_component.component}`)
            );

            await this.$fire
                .doc(`components/${this.move_component.component}`)
                .update({
                    stage: this.$fire.doc(`stages/${to_stage__snap.id}`),
                });

            await this.$fire
                .doc(`stages/${from_stage__snap.id}`)
                .update(from_update);
            await this.$fire
                .doc(`stages/${to_stage__snap.id}`)
                .update(to_update);

            this.move_component.component = null;
            this.move_component.to_stage = null;

            Notification({
                type: 'success',
                title: 'Done',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.block_options {
    width: 410px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    & > * {
        margin-right: 5px;
        width: 200px;
    }
}
</style>
