/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jira-activity': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<defs><rect pid="0" id="svgicon_jira-activity_a" x="0" y="0" width="14" height="14" rx="2"/></defs><g transform="translate(1 1)" _fill="none" fill-rule="evenodd"><use _fill="#E5493A" xlink:href="#svgicon_jira-activity_a"/><path pid="1" d="M11.893 7.205L11.9 7.2l-1.5-2-.007.005A.49.49 0 0010 5a.496.496 0 00-.443.278l-.005-.002L8.55 7.28 6.964 3.314l-.002.001a.493.493 0 00-.935.05l-.008-.003-1.647 5.764-1.425-2.85-.005.002a.496.496 0 10-.885.443l-.005.002 2 4 .005-.002a.497.497 0 00.443.28.493.493 0 00.472-.366l.01.002 1.597-5.594 1.457 3.642h.001A.499.499 0 008.5 9c.195 0 .36-.116.442-.279l.005.002 1.138-2.276L11.1 7.8l.007-.005a.492.492 0 10.787-.589" _fill="#FFF"/></g>'
  }
})
