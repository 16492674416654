/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'steps-success-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11.922 6.375l-4.328 4.359c-.188.146-.37.219-.547.219h-.375a.791.791 0 01-.297-.062.739.739 0 01-.25-.157L4.078 8.672A.344.344 0 014 8.445c0-.088.026-.164.078-.226l.813-.797a.254.254 0 01.219-.11c.093 0 .17.037.234.11L6.64 8.719a.308.308 0 00.226.094.314.314 0 00.227-.094l3.562-3.594a.41.41 0 01.243-.078.29.29 0 01.21.078l.813.797a.408.408 0 01.078.242.285.285 0 01-.078.211m3.445-1.477a8.039 8.039 0 00-1.703-2.546A8.118 8.118 0 0011.11.641 7.777 7.777 0 008 0a7.784 7.784 0 00-3.101.633 8.032 8.032 0 00-2.547 1.703A8.11 8.11 0 00.64 4.891 7.748 7.748 0 000 8c0 1.083.21 2.117.633 3.102a8.022 8.022 0 001.703 2.546 8.1 8.1 0 002.555 1.711A7.762 7.762 0 008 16a7.796 7.796 0 003.102-.633 8.055 8.055 0 002.547-1.703 8.15 8.15 0 001.71-2.555A7.748 7.748 0 0016 8a7.796 7.796 0 00-.632-3.102" _fill="currentColor" fill-rule="evenodd"/>'
  }
})
