<template>
    <div class="users">
        <user-block
            v-for="user in users"
            :key="user.id + '_user-tracker'"
            :user="user"
        />
    </div>
</template>

<script>
import UserBlock from '@/components/blocks/UserBlock.vue';

export default {
    name: 'users-widget',
    components: {UserBlock},
    computed: {
        users() {
            return this.$store.getters.users.filter((user) => !user.hide_tv);
        },
    },
};
</script>

<style lang="scss" scoped>
.users {
    display: flex;
    flex-direction: column;

    ::v-deep .user-block {
        min-height: 80px;
        margin-bottom: 6px;
        border-radius: 10px;

        span.name {
            font-weight: 600;
        }

        .info .label {
            // Align 'Not tracking' vertically with tracking text details
            margin-left: 70px;
            opacity: 0.75;
        }

        button {
            // Hide buttons to add/delete IssueTags
            display: none;
        }

        .issue-tag {
            padding-left: 10px;
            padding-right: 10px;
        }

        .stop {
            display: none;
        }

        .note {
            height: fit-content;

            span:first-child {
                display: none;
            }
        }
    }
}
</style>
