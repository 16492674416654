/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jira-epic': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g transform="translate(1 1)" _fill="none" fill-rule="evenodd"><rect pid="0" _fill="#904EE2" width="14" height="14" rx="2"/><path pid="1" d="M9.923 6.757l-.002-.004a.479.479 0 00.08-.253.5.5 0 00-.5-.5H7V3.5a.5.5 0 00-.5-.5.49.49 0 00-.406.223.498.498 0 00-.066.13L4.081 7.237l.002.003A.489.489 0 004 7.5a.5.5 0 00.5.5H7v2.5a.493.493 0 00.918.259h.003l.013-.025c.011-.021.023-.04.031-.062l1.958-3.915z" _fill="#FFF"/></g>'
  }
})
