<template>
    <div class="outer">
        <header>
            <el-input
                v-model="search"
                placeholder="Search client"
                size="mini"
            />
            <el-button size="mini" @click="addContact">
                New
            </el-button>
        </header>

        <div class="inner">
            <ul>
                <li
                    v-for="client in filtered_clients"
                    :key="client.id"
                    @click="() => navigateToClient(client)"
                >
                    <el-tooltip
                        placement="top"
                        content="Client is missing primary contact email"
                    >
                        <div class="tag" v-if="!client.email">
                            <i class="el-icon-warning no-email" />
                        </div>
                    </el-tooltip>

                    <span>
                        {{ client.name }}
                    </span>
                    <svgicon name="triangle" class="triangle" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'management-clients',
    data() {
        return {
            search: '',
        };
    },
    computed: {
        clients() {
            return this.$store.getters.clients;
        },
        filtered_clients() {
            if (this.search.length) {
                return this.clients.filter((i) => {
                    const search = this.search.toLowerCase();

                    return i.name && i.name.toLowerCase().includes(search);
                });
            }

            return this.clients;
        },
    },
    methods: {
        addContact() {
            this.$router
                .push({
                    name: 'management_client_new',
                })
                .catch((err) => {});
        },
        navigateToClient(client) {
            this.$router
                .push({
                    name: 'management_client_detail',
                    params: {
                        client_id: client.id,
                    },
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
        box-sizing: border-box;
        height: 65px;
        padding: 10px 20px;
        border-bottom: 1px solid $border-grey-light;
        display: flex;
        user-select: none;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .el-input {
            width: 200px;
        }
    }

    .inner {
        overflow: scroll;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                s &:hover {
                    svg {
                        display: block;
                    }
                }

                span {
                    flex: 1;
                    user-select: none;
                }

                svg {
                    width: 6px;
                    display: none;
                    opacity: 0.1;
                }

                .tag {
                    width: 24px;
                    height: 24px;
                    border-radius: 4px;
                    background: rgba($red, 0.1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;

                    .no-email {
                        color: $red;
                    }
                }
            }
        }
    }
}
</style>
