var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _vm.project
        ? _c("breadcrumb-header", {
            attrs: {
              labels: [_vm.project.name],
              go_back_to: "management_projects",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "inner" },
        [
          _c(
            "content-block",
            { attrs: { title: "Overview" } },
            [
              _c("el-alert", { attrs: { closable: false } }, [
                _vm._v(" Projects stats and progress will be added here. "),
              ]),
            ],
            1
          ),
          _vm.project
            ? _c(
                "content-block",
                { attrs: { title: "Estimates" } },
                [_c("project-estimates", { attrs: { project: _vm.project } })],
                1
              )
            : _vm._e(),
          _vm.project
            ? _c(
                "content-block",
                { attrs: { title: "Payment plans" } },
                [
                  _c("project-payment-plans", {
                    attrs: { project: _vm.project },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.project && _vm.$store.getters.is_xero_enabled
            ? _c(
                "content-block",
                { attrs: { title: "Invoices" } },
                [_c("project-invoices", { attrs: { project: _vm.project } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }