<template>
    <header>
        <div class="back" @click="goBack">
            <svgicon name="triangle" class="triangle" />
            <label>BACK</label>
            <span class="spacer">/</span>

            <span v-for="(label, index) in labels" :key="`${label}-${index}`">
                <span v-if="index > 0" class="spacer">/</span>{{ label }}
            </span>
        </div>

        <slot></slot>
    </header>
</template>

<script>
export default {
    name: 'breadcrumb-header',
    props: {
        labels: {
            type: Array,
            default: () => [],
        },
        go_back_to: {
            type: String,
            required: true,
        },
        go_back_params: {
            type: Object,
            default: () => null,
        },
    },
    methods: {
        goBack() {
            this.$router
                .push({
                    name: this.go_back_to,
                    params: {...this.go_back_params},
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
header {
    box-sizing: border-box;
    height: 65px;
    padding: 10px 20px;
    border-bottom: 1px solid $border-grey-light;
    display: flex;
    user-select: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .back {
        cursor: pointer;
        margin: 10px 0;
        padding: 20px 20px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .triangle {
            color: $black;
            width: 10px;
            height: 10px;
            margin: 4px 10px 3px 0;
            transform: scaleX(-1) translateX(0);
            filter: FlipH;
            -ms-filter: 'FlipH';
            transition: transform 0.3s ease-in-out 0.1s;
        }

        label {
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            text-align: right;
            color: $black;
            text-transform: uppercase;
            margin-top: 1px;
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;
        }

        span.spacer {
            margin: 1px 6px 0 6px;
        }

        span {
            font-size: 13px;
            margin-top: 1px;
        }

        span {
            transition: transform 0.3s ease-in-out;
        }

        &:hover {
            .triangle {
                transform: scaleX(-1) translateX(3px);
            }

            label,
            span {
                transform: translateX(3px);
            }
        }
    }
}
</style>
