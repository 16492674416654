import 'element-ui/lib/theme-chalk/index.css'; // Theme UI
import 'element-ui/lib/theme-chalk/display.css';

import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import 'vue2vis/dist/vue2vis.css';

import '@/utils/permission';
import SvgIcon from 'vue-svgicon';
import '@/assets/icons';

import VueGlobalVariable from 'vue-global-var';
import VueDragscroll from 'vue-dragscroll';

import {firebaseDB, firebaseFirestore, firebaseStorage} from '@/utils/firebase';
import {EventBus} from '@/utils/event-bus.js';

import UserbackPlugin from '@userback/vue2';
import Hotjar from '@hotjar/browser';

import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';

import * as enums from '@/enums';

import moment from 'moment';

Vue.use(VueGlobalVariable, {
    globals: {
        db: firebaseDB,
        fire: firebaseFirestore,
        storage: firebaseStorage,
        enums: enums,
        bus: EventBus,
    },
});

/* ========== COMPONENTS ============ */

Vue.config.productionTip = false;
Vue.use(ElementUI, {locale});
Vue.use(SvgIcon, {tagName: 'svgicon', isOriginalDefault: true});

const VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);
Vue.use(VueDragscroll);

import money from 'v-money';
Vue.use(money, {
    decimal: ',',
    thousands: '.',
    prefix: '$ ',
    precision: 2,
    masked: true,
});

if (process.env.NODE_ENV !== 'development') {
    Vue.use(UserbackPlugin, {token: 'A-UqqJHRylaxhqintFaQ51fU8nl'});
    Hotjar.init(3819640, 6);
}

/* ========== FILTERS ============ */

import * as filters from '@/filters';

Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});

/* ========== DIRECTIVES ============ */

Vue.directive('only-user', {
    inserted(el, binding, vnode) {
        if (
            !Object.prototype.hasOwnProperty.call(binding, 'value') ||
            binding.value
        ) {
            if (store.getters.session_user.role.includes(USER_ROLES.ADMIN)) {
                vnode.elm.parentElement.removeChild(vnode.elm);
            }
        }
    },
});

Vue.directive('only-admin', {
    inserted(el, binding, vnode) {
        if (
            !Object.prototype.hasOwnProperty.call(binding, 'value') ||
            binding.value
        ) {
            if (!store.getters.session_user.role.includes(USER_ROLES.ADMIN)) {
                vnode.elm.parentElement.removeChild(vnode.elm);
            }
        }
    },
});

Vue.directive('only-super-admin', {
    inserted(el, binding, vnode) {
        if (
            !Object.prototype.hasOwnProperty.call(binding, 'value') ||
            binding.value
        ) {
            if (store.getters.session_user.role !== USER_ROLES.SUPER_ADMIN) {
                vnode.elm.parentElement.removeChild(vnode.elm);
            }
        }
    },
});

Vue.directive('has-project-permission', {
    inserted(_, binding, vnode) {
        if (store.getters.session_user.role.includes(USER_ROLES.ADMIN)) {
            return;
        }

        const project_id = router.history.current.params.project_id;
        const project = store.getters.projectWithId(project_id);

        const teamUser =
            project.team?.find(
                (u) => u.user === `users/${store.getters.user.id}`
            ) || null;

        if (teamUser) {
            const role = store.getters.userRoles.find(
                (r) => `system/users/roles/${r.id}` === teamUser.role
            );

            if (role) {
                if (role?.permissions?.includes(binding.value)) return;
            }
        }

        vnode.elm.parentElement.removeChild(vnode.elm);
    },
});

/* ========== dayjs ============ */
import dayjs from 'dayjs';
import * as dayOfYear from 'dayjs/plugin/dayOfYear';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import * as updateLocale from 'dayjs/plugin/updateLocale';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as duration from 'dayjs/plugin/duration';

import en from 'dayjs/locale/en';

dayjs.extend(dayOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);

dayjs.updateLocale('en', {
    weekStart: 1,
});

moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

/* ========== SENTRY ============ */
Sentry.init({
    Vue,
    enabled: process.env.NODE_ENV !== 'development',
    environment: process.env.NODE_ENV,
    dsn:
        'https://40443cd2d94b4bbb9fa4d4bee011f086@o314497.ingest.sentry.io/5500894',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
        'localhost',
        '*.t-o-o-l.com',
        /^\//,
        process.env.VUE_APP_BASE_URI,
    ],
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
});

/* ========== APP ============ */

import {firestorePlugin} from 'vuefire';
Vue.use(firestorePlugin, {
    serialize: (doc) => {
        const temp = Object.defineProperty(doc.data() || {}, 'id', {
            value: doc.id,
        });
        return Object.defineProperty(temp, 'path', {value: doc.ref.path});
    },
});

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import VueProgressiveImage from 'vue-progressive-image';
Vue.use(VueProgressiveImage);

// Prevent shortkeys from firing when the tool text editor has focus
Vue.use(require('vue-shortkey'), {
    prevent: ['.ProseMirror'],
});

// Leave here to override default CSSs
import './assets/scss/index.scss';
import {USER_ROLES} from '@/enums';

Vue.config.errorHandler = function (err, vm, info) {
    console.error('VUE ERROR:', err, info);
};

new Vue({
    el: '#app',
    router,
    store,
    render: (h) => h(App),
});
