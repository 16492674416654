<template>
    <div class="uploader">
        <el-progress
            v-if="uploadTask"
            :percentage="progressUpload"
            :show-text="false"
        />
        <input
            v-else
            ref="fileupload"
            type="file"
            accept="image/jpeg, image/png, image/svg+xml"
            @change="detectFile($event.target.files)"
        />
    </div>
</template>

<script>
import {Notification} from 'element-ui';

export default {
    props: {
        folder: {
            type: String,
            required: true,
        },
        max_file_size_bytes: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            progressUpload: 0,
            file: File,
            uploadTask: null,
        };
    },
    computed: {
        pretty_max_file_size() {
            if (!this.max_file_size_bytes) return -1;
            const KB = 1024;
            const MB = 1024 * 1024;
            if (this.max_file_size_bytes < KB) {
                return `${this.max_file_size_bytes} bytes`;
            } else if (
                this.max_file_size_bytes >= KB &&
                this.max_file_size_bytes < MB
            ) {
                return `${this.max_file_size_bytes / KB} KB`;
            }
            return `${this.max_file_size_bytes / MB} MB`;
        },
    },
    watch: {
        uploadTask: function (task) {
            if (!task) return;
            this.uploadTask.on(
                'state_changed',
                (sp) => {
                    this.progressUpload = Math.floor(
                        (sp.bytesTransferred / sp.totalBytes) * 100
                    );
                },
                null,
                () => {
                    this.uploadTask.snapshot.ref
                        .getDownloadURL()
                        .then((downloadURL) => {
                            this.$emit('uploaded', downloadURL);
                            this.reset();

                            Notification({
                                type: 'success',
                                title: 'Success',
                                message: 'File uploaded.',
                            });
                        });
                }
            );
        },
    },
    methods: {
        detectFile(files) {
            if (files && files.length) {
                Array.from(files).forEach((file) => {
                    if (file.size > this.max_file_size_bytes) {
                        this.reset();

                        Notification({
                            type: 'error',
                            title: 'File size limit exceeded',
                            message: `${file.name} is too large. Files must be smaller than ${this.pretty_max_file_size}.`,
                        });
                    } else {
                        this.upload(file);
                    }
                });
            }
        },
        upload(file) {
            this.uploadTask = this.$storage
                .child(`${this.folder}/${file.name}`)
                .put(file);
        },
        reset() {
            this.uploadTask = null;
            this.progressUpload = 0;
        },
    },
};
</script>

<style>
.uploader {
    height: 22px;
    min-width: 250px;

    .el-progress {
        margin: 10px 0;
        width: 100%;
    }
}
</style>
