<template>
    <!-- empty -->
    <div />
</template>

<script>
export default {
    name: 'stage-tab-overview',
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
section {
    margin: 0 0 20px 0;
    padding: 20px;
    border-radius: 5px;
    background-color: $grey;

    &:last-of-type {
        margin: 0;
    }

    .head {
        border-bottom: 1px solid $border-grey-light;
        padding: 0 20px 20px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: center;

        .spinner {
            width: 50px;
            height: 21px;
            display: inline-block;
            vertical-align: bottom;
        }

        hr {
            width: 50%;
            margin: 15px 0;
            border: none;
            height: 1px;
            background-color: $border-grey-light;
        }
    }

    span.placeholder {
        text-align: center;
        display: block;
        margin-top: 20px;
        font-size: 12px;
        color: rgba($black, 0.3);
    }

    .el-alert {
        margin-top: 20px;
    }

    .section-footer {
        border-top: 1px solid $black;
        padding-top: 15px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-self: center;

        .total {
            color: rgba($black, 0.5);
            font-size: 13px;

            strong {
                color: $black;
            }
        }
    }
}
</style>
