import {deleteInvoice, updateInvoiceCache} from '@/api/invoices';

import firebase from 'firebase/app';

const invoiceMixin = {
    methods: {
        async updateInvoices() {
            await updateInvoiceCache();
        },
        async deleteInvoiceAndData() {
            return deleteInvoice(this.invoice.id);
        },
    },
};

export default invoiceMixin;
