var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "estimates-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 12 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 8, sm: 24 } },
            [
              _c(
                "widget-card",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { loading: false },
                },
                [
                  _vm.estimates.length
                    ? _c("div", { staticClass: "key-value" }, [
                        _c("label", [
                          _vm._v("Total in draft "),
                          _c("span", [_vm._v("(ex. GST)")]),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("currency")(_vm.total_draft))),
                        ]),
                      ])
                    : _c("el-alert", {
                        attrs: {
                          description: "No estimates available.",
                          closable: false,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, sm: 24 } },
            [
              _c(
                "widget-card",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { loading: false },
                },
                [
                  _vm.estimates.length
                    ? _c("div", { staticClass: "key-value" }, [
                        _c("label", [
                          _vm._v("Total in review "),
                          _c("span", [_vm._v("(ex. GST)")]),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("currency")(_vm.total_review))),
                        ]),
                      ])
                    : _c("el-alert", {
                        attrs: {
                          description: "No estimates available.",
                          closable: false,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, sm: 24 } },
            [
              _c(
                "widget-card",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { loading: false },
                },
                [
                  _vm.estimates.length
                    ? _c("div", { staticClass: "key-value" }, [
                        _c("label", [
                          _vm._v("Total accepted "),
                          _c("span", [_vm._v("(ex. GST)")]),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("currency")(_vm.total_accepted))
                          ),
                        ]),
                      ])
                    : _c("el-alert", {
                        attrs: {
                          description: "No estimates available.",
                          closable: false,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }