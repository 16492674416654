import {COMMAND_DEPENDENCIES} from '@/enums';
import store from '@/store';
import {fireRef2id, pluralize} from '@/filters';

/**
 * Provides a list of payment plans
 * @type {{provides: string, getResults: CommandProvider.getResults, label: string, filteredBy: null}}
 */
export const PaymentPlanProvider = {
    label: 'Payment Plans',
    provides: COMMAND_DEPENDENCIES.PAYMENT_PLAN,
    filteredBy: [COMMAND_DEPENDENCIES.PROJECT, COMMAND_DEPENDENCIES.ESTIMATE],
    getResults: async (data, filter_string) => {
        if (data[COMMAND_DEPENDENCIES.PAYMENT_PLAN]) return [];

        // only return results if filter, project or estimate is set
        if (!filter_string && !data.project && !data.estimate) return null;

        let plans = store.getters.paymentPlans;
        if (data.project) {
            plans = plans.filter(
                (plan) => plan.project === `projects/${data.project.value.id}`
            );
        }
        if (data.estimate) {
            plans = plans.filter(
                (plan) =>
                    plan.estimate === `estimates/${data.estimate.value.id}`
            );
        }
        if (filter_string) {
            const query = filter_string.toLowerCase();
            plans = plans.filter((plan) => {
                const ref_match =
                    plan.ref && plan.ref.toLowerCase().includes(query);

                const estimate_id = fireRef2id(plan.estimate);
                const estimate_match =
                    estimate_id?.toLowerCase().includes(query) || false;
                return ref_match || estimate_match;
            });
        }
        return plans
            .map((plan) => {
                const project = store.getters.projectWithId(plan.project);
                const estimate = store.getters.estimateWithId(plan.estimate);
                if (project && estimate) {
                    return {
                        id: plan.id,
                        label: plan.ref,
                        sub_label: `${estimate.id} - ${
                            plan.quantity
                        } ${pluralize('invoice', plan.quantity)}`,
                        value: plan,
                    };
                }
            })
            .filter((x) => x); // filter out unmapped payment plans
    },
    cleanup() {
        // if this provider holds any resources during an operation, dispose of them here
    },
};
