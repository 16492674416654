<template>
    <div class="outer">
        <breadcrumb-header
            v-if="project"
            :labels="[project.name]"
            go_back_to="management_projects"
        />

        <div class="inner">
            <content-block title="Overview">
                <el-alert :closable="false">
                    Projects stats and progress will be added here.
                </el-alert>
            </content-block>

            <content-block v-if="project" title="Estimates">
                <project-estimates :project="project" />
            </content-block>

            <content-block v-if="project" title="Payment plans">
                <project-payment-plans :project="project" />
            </content-block>

            <content-block
                v-if="project && $store.getters.is_xero_enabled"
                title="Invoices"
            >
                <project-invoices :project="project" />
            </content-block>
        </div>
    </div>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';

import BreadcrumbHeader from '@/pages/management/components/BreadcrumbHeader';
import ProjectInvoices from '@/pages/management/projects/components/ProjectInvoices';
import ProjectPaymentPlans from '@/pages/management/projects/components/ProjectPaymentPlans';
import ProjectEstimates from '@/pages/management/projects/components/ProjectEstimates';

export default {
    name: 'management-project-detail',
    components: {
        BreadcrumbHeader,
        ContentBlock,
        ProjectInvoices,
        ProjectPaymentPlans,
        ProjectEstimates,
    },
    data() {
        return {
            filter: null,
            access_cards: {
                items: [],
                loading: false,
            },
            customColors: [
                {color: '#f56c6c', percentage: 30},
                {color: '#FFFFFF', percentage: 100},
                {color: '#90d050', percentage: 100},
            ],
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
    },
    mounted() {
        if (!this.project) {
            this.$router.replace({name: 'management_projects'});
            return;
        }
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    .inner {
        .stats {
            max-width: calc(100% - 40px);
            background: $black;
            margin: 20px;
            border-radius: 5px;
            padding: 30px;

            h3 {
                margin: 0;
                margin-bottom: 20px;
                color: white;
            }

            ::v-deep .el-progress {
                .el-progress-circle svg path.el-progress-circle__track {
                    stroke: $black-darker;
                }
                .el-progress-bar .el-progress-bar__outer {
                    background-color: $black-darker;
                }
                .el-progress__text {
                    color: white;
                }
            }

            .graphs {
                padding-top: 30px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .graph-container {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    span {
                        color: white;
                    }
                }
            }
        }

        .content-block {
            background: $white;
            margin-bottom: 0;

            .right {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                .spinner {
                    width: 28px;
                    height: 28px;
                    margin-right: 10px;
                    display: inline-block;
                }
            }

            .access-card {
                padding: 15px 20px;
                margin: 10px 20px;
                background-color: $grey;
                border: 1px solid $border-grey-light;
                border-radius: 5px;

                .item {
                    margin: 2px 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    label {
                        font-size: 14px;
                        margin-right: 5px;
                        width: 100px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
</style>
