<template>
    <div v-if="editor" class="editor issue-search" @click.stop.prevent>
        <div class="content">
            <template v-if="!show_editor">
                <add-issue-tag-button @click="showEditor" />
            </template>
            <editor-content
                v-else
                class="editor-content"
                ref="editor_content"
                v-shortkey.avoid
                :editor="editor"
                @keyup.native="handleKeyUp"
            />
        </div>
    </div>
</template>

<script>
import LinkNode from '@/components/editor/extensions/LinkNode.js';
import OneLiner from '@/components/editor/extensions/OneLiner.js';
import IssueSuggestionList from '@/components/issues/IssueSuggestionList.vue';
import AddIssueTagButton from '@/components/issues/AddIssueTagButton.vue';

import Placeholder from '@tiptap/extension-placeholder';

import {Extension} from '@tiptap/core';
import {Editor, EditorContent} from '@tiptap/vue-2';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import FilterNode from '@/components/editor/extensions/FilterNode';

export default {
    name: 'issue-search',
    components: {
        EditorContent,
        AddIssueTagButton,
    },
    props: {
        component_id: {
            type: String,
            default: null,
        },
        issues: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            editor: null,
            bound_tasks: [],
            show_editor: false,
            value: '',
            saved_value: '',
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectForComponentWithId(
                this.component_id || this.$route.params.component_id
            );
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        showEditor() {
            this.show_editor = true;
            this.focus();
        },
        focus() {
            this.$nextTick(() => this.editor.commands.focus('start'));
        },

        handleKeyUp(event) {
            switch (event.key) {
                case 'Enter':
                    this.$emit('enter', this.jsonToText(this.editor.getJSON()));
                    break;
                case 'Backspace':
                    this.$emit(
                        'backspace',
                        this.jsonToText(this.editor.getJSON())
                    );
                    break;
                case 'Escape':
                    this.editor.commands.blur();
                    break;
                default:
                    this.$emit(
                        'keyup',
                        this.jsonToText(this.editor.getJSON()),
                        event.key
                    );
            }
        },
        init() {
            if (this.editor) {
                this.editor.destroy();
            }
            const handleCommand = () => {
                this.$bus.$emit('modal:action', {
                    modal: 'command-palette',
                    show: true,
                });
            };

            const ShortcutExtension = Extension.create({
                addKeyboardShortcuts() {
                    return {
                        'Mod-k': handleCommand,
                    };
                },
            });

            const extensions = [
                OneLiner,
                Paragraph,
                Text,
                LinkNode,
                ShortcutExtension,
                Placeholder.configure({placeholder: '+'}),
            ];

            if (this.project) {
                extensions.push(
                    FilterNode.configure({
                        suggestion: {
                            items: this.filterIssues,
                        },
                        suggestion_component: IssueSuggestionList,
                        onSelect: (item) => {
                            this.$emit('select', item);
                        },
                    })
                );
            }

            this.editor = new Editor({
                extensions,
                editable: this.editable,
            });
        },
        jsonToText(json) {
            if (json && json.content && json.content.length > 0) {
                if (
                    json.content[0].content &&
                    json.content[0].content.length > 0
                ) {
                    return json.content[0].content
                        .map((node) => {
                            if (node.type === 'text') {
                                return node.text;
                            }
                            if (node.type === 'link_node')
                                return node.attrs.url;

                            return '';
                        })
                        .join('');
                }
            }

            return '';
        },
        async filterIssues(query) {
            const issues = [];
            if (this.project) {
                issues.push(
                    ...this.$store.getters.issuesForJiraProject(
                        this.project.jira_project
                    )
                );
            }

            return issues.filter((issue) => {
                return (issue.key + issue.summary)
                    .toLowerCase()
                    .includes(query.toLowerCase());
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.editor {
    display: flex;
    outline: none;
    align-items: center;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    border-radius: 4px;
    color: $black;
    overflow: hidden;
    min-width: 100px;

    .content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        overflow: hidden !important;
        white-space: nowrap !important;
        .editor-content {
            flex-grow: 1;
        }
        ::v-deep .ProseMirror {
            text-align: right;

            p {
                margin: 0;

                &.is-editor-empty:first-child::before {
                    content: attr(data-placeholder);
                    position: absolute;
                    right: 0;
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }

    @media screen and (max-width: 992px) {
        min-height: 30px;
        padding: 0;

        .content ::v-deep .ProseMirror {
            text-align: left !important;
        }
    }
}
</style>
