<template>
    <div v-if="editor" class="text-editor-wrapper" :class="theme">
        <bubble-menu
            v-if="editor && editable && bubble_menu"
            :editor="editor"
            :tippy-options="{duration: 100}"
        >
            <div class="bubble-tool-bar">
                <button
                    type="button"
                    :style="{fontWeight: 'bold'}"
                    class="bubble-button"
                    :class="{'is-active': editor.isActive('bold')}"
                    @click="editor.chain().focus().toggleBold().run()"
                >
                    B
                </button>
                <div class="divider"></div>
                <button
                    type="button"
                    :style="{fontStyle: 'italic'}"
                    class="bubble-button"
                    :class="{'is-active': editor.isActive('italic')}"
                    @click="editor.chain().focus().toggleItalic().run()"
                >
                    I
                </button>
                <div class="divider"></div>
                <button
                    type="button"
                    :style="{textDecoration: 'underline'}"
                    class="bubble-button"
                    :class="{'is-active': editor.isActive('underline')}"
                    @click="editor.chain().focus().toggleUnderline().run()"
                >
                    U
                </button>
                <div class="divider"></div>
                <button
                    type="button"
                    :style="{textDecoration: 'line-through'}"
                    class="bubble-button"
                    :class="{'is-active': editor.isActive('strike')}"
                    @click="editor.chain().focus().toggleStrike().run()"
                >
                    S
                </button>
                <div class="divider"></div>
                <button
                    type="button"
                    class="bubble-button"
                    :class="{'is-active': editor.isActive('link')}"
                    @click="setLink"
                >
                    <i class="el-icon-link" />
                </button>
                <div class="divider"></div>
                <button
                    type="button"
                    style="width: 33px;"
                    class="bubble-button"
                    :class="{'is-active': editor.isActive('code')}"
                    @click="editor.chain().focus().toggleCode().run()"
                >
                    &lt;/&gt;
                </button>
                <template v-if="code_block">
                    <div class="divider"></div>
                    <button
                        type="button"
                        style="width: 33px;"
                        class="bubble-button"
                        :class="{'is-active': editor.isActive('code-block')}"
                        @click="editor.chain().focus().toggleCodeBlock().run()"
                    >
                        <span class="icon-codeblock"
                            >[<span>&lt;/&gt;</span>]</span
                        >
                    </button>
                </template>
            </div>
        </bubble-menu>
        <floating-menu
            v-if="editor && editable && floating_menu"
            :editor="editor"
            :tippy-options="{duration: 100}"
        >
            <div class="floating-tool-bar">
                <button
                    type="button"
                    class="floating-button"
                    :class="{'is-active': editor.isActive('h1')}"
                    @click="
                        editor.chain().focus().toggleHeading({level: 3}).run()
                    "
                >
                    <h1 style="font-size: 15px;">Title</h1>
                </button>
                <div class="divider"></div>
                <button
                    type="button"
                    class="floating-button"
                    :class="{'is-active': editor.isActive('h2')}"
                    @click="
                        editor.chain().focus().toggleHeading({level: 4}).run()
                    "
                >
                    <h2 style="font-size: 12px;">Heading</h2>
                </button>
                <div class="divider"></div>
                <button
                    type="button"
                    class="floating-button"
                    :class="{'is-active': editor.isActive('h4')}"
                    @click="
                        editor.chain().focus().toggleHeading({level: 0}).run()
                    "
                >
                    <p style="font-size: 12px;">Body</p>
                </button>
                <div class="divider"></div>
                <button
                    type="button"
                    class="floating-button"
                    :class="{'is-active': editor.isActive('bullet-list')}"
                    @click="editor.chain().focus().toggleBulletList().run()"
                >
                    ≔
                </button>
                <div style="margin-right: 10px;" class="divider"></div>
                <el-tooltip
                    style="border: 0;"
                    content="Insert image"
                    placement="bottom"
                >
                    <el-button
                        size="mini"
                        style="margin-right: 10px;"
                        :disabled="!!progressUpload"
                        @click="uploadImage"
                    >
                        <i
                            v-if="!progressUpload"
                            class="el-icon-picture-outline-round"
                            style="font-size: 18px;"
                        />
                        <el-progress
                            v-else
                            :percentage="progressUpload"
                            :show-text="false"
                            style="margin: 6px 0; width: 18px;"
                        />
                    </el-button>
                </el-tooltip>
                <input
                    ref="upload_image"
                    type="file"
                    style="display: none;"
                    accept="image/jpeg, image/png"
                    @change="detectFile($event.target.files)"
                />
            </div>
        </floating-menu>
        <editor-content
            ref="editor_content"
            v-shortkey.avoid
            class="editor"
            :editor="editor"
        />
    </div>
</template>

<script>
import TodoNode from './extensions/TodoNode.js';
import SuggestionNode from './extensions/SuggestionNode.js';
import LinkNode from './extensions/LinkNode.js';
//import ColorNode from './extensions/ColorNode.js';
//import ResizableImage from './extensions/ResizableImage';

import {Extension} from '@tiptap/core';
import {Editor, EditorContent, FloatingMenu, BubbleMenu} from '@tiptap/vue-2';
import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Document from '@tiptap/extension-document';
import GapCursor from '@tiptap/extension-gapcursor';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Image from '@tiptap/extension-image';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';

import IssueSuggestionList from '@/components/issues/IssueSuggestionList.vue';

import Placeholder from '@tiptap/extension-placeholder';

export default {
    name: 'text-editor',
    components: {
        EditorContent,
        BubbleMenu,
        FloatingMenu,
    },
    props: {
        submit_on_enter: {
            type: Boolean,
            default: false,
        },
        submit_on_ctrl_enter: {
            type: Boolean,
            default: false,
        },
        content: {
            type: [Object, String],
            default: null,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: null,
        },
        source: {
            type: String,
            default: null,
        },
        theme: {
            type: String,
            default: 'light',
        },
        project: {
            type: Object,
            default: () => null,
        },
        floating_menu: {
            type: Boolean,
            default: true,
        },
        bubble_menu: {
            type: Boolean,
            default: true,
        },
        drag_drop: {
            type: Boolean,
            default: true,
        },
        code_block: {
            type: Boolean,
            default: true,
        },
        user: {
            type: Object,
            default: () => null,
        },
        fancy_links: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            editor: null,
            provider: null,
            connection_status: null,
            issues: [],
            headings: [],
            progressUpload: 0,
            uploadTask: '',
        };
    },
    watch: {
        uploadTask: function () {
            this.uploadTask.on(
                'state_changed',
                (sp) => {
                    this.progressUpload = Math.floor(
                        (sp.bytesTransferred / sp.totalBytes) * 100
                    );
                },
                null,
                () => {
                    this.uploadTask.snapshot.ref
                        .getDownloadURL()
                        .then((url) => {
                            this.insertImage(url);
                            this.progressUpload = 0;
                        });
                }
            );
        },
        editable: {
            immediate: true,
            handler() {
                if (!this.editor) return;
                this.editor.setOptions({
                    editable: this.editable,
                });
            },
        },
    },
    mounted() {
        this.init(this.content);
    },
    beforeDestroy() {
        this.cleanup();
    },
    methods: {
        uploadImage() {
            this.$refs.upload_image.click();
        },
        detectFile(files) {
            Array.from(Array(files.length).keys()).map((x) => {
                this.upload(files[x]);
            });
        },
        upload(file) {
            this.progressUpload = 0;
            this.uploadTask = this.$storage
                .child(`notes/${file.name}`)
                .put(file);
        },
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href;
            const url = window.prompt('URL', previousUrl);

            // cancelled
            if (url === null) {
                return;
            }

            // empty
            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run();

                return;
            }
            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({href: url})
                .run();
        },
        cleanup() {
            // Always destroy your editor instance when it's no longer needed
            if (this.editor) {
                this.editor.destroy();
            }

            if (this.provider) {
                this.provider.destroy();
            }
        },
        init(content) {
            this.cleanup();

            const extensions = [
                Document,
                Paragraph,
                Heading,
                Text,
                Code,
                Bold,
                Italic,
                ListItem,
                OrderedList,
                CodeBlock,
                BulletList,
                Strike,
                HorizontalRule,
                Table,
                TableCell,
                TableHeader,
                TableRow,
                Link.configure({
                    openOnClick: true,
                    HTMLAttributes: {
                        class: 'link',
                    },
                }),
                GapCursor,
                Image,
                HardBreak,
                Blockquote,
                Underline,
                TodoNode.configure({
                    project_id: this.project ? this.project.id : null,
                    source: this.source,
                }),
                Placeholder.configure({placeholder: this.placeholder}),
            ];
            if (this.fancy_links) {
                extensions.push(LinkNode);
            }
            extensions.push(History);

            const handleEnter = () => {
                if (this.submit_on_enter) {
                    this.$emit('submit', this.editor.getJSON());
                }
                return this.submit_on_enter;
            };

            const handleCtrlEnter = () => {
                if (this.submit_on_ctrl_enter) {
                    this.$emit('submit', this.editor.getJSON());
                }
                return this.submit_on_ctrl_enter;
            };

            const handleTab = () => {
                this.editor.chain().insertContent('\t').run();
                return true;
            };

            const handleShiftTab = () => {
                return true;
            };

            const handleCommand = () => {
                this.$bus.$emit('modal:action', {
                    modal: 'command-palette',
                    show: true,
                });
            };

            const customKeyboardShortcuts = Extension.create({
                addKeyboardShortcuts() {
                    return {
                        Enter: handleEnter,
                        'Ctrl-Enter': handleCtrlEnter,
                        Tab: handleTab,
                        'Shift-Tab': handleShiftTab,
                        'Mod-k': handleCommand,
                    };
                },
            });

            extensions.unshift(customKeyboardShortcuts);

            if (this.project && this.project.jira_project) {
                extensions.push(
                    SuggestionNode.configure({
                        search: new RegExp(
                            `^(${
                                this.project ? this.project.jira_project : ''
                            }-[A-Za-z0-9]*)$`,
                            'i'
                        ),
                        suggestion: {
                            items: this.filterIssues,
                        },
                        suggestion_component: IssueSuggestionList,
                        getContent: (item) => [
                            {
                                type: 'link_node',
                                attrs: {
                                    url: `https://${this.project.jira_org}.atlassian.net/browse/${item.key}`,
                                },
                            },
                        ],
                    })
                );
            }
            const editorOptions = {
                extensions,
                editable: this.editable,
                content: this.convertToV2(content ? content : this.content),
                onUpdate: (val) => {
                    // catch empty paragraph and return null
                    this.html = val.editor.getHTML();

                    if (this.html === '<p></p>') {
                        this.$emit('update:content', null);
                        return;
                    }

                    this.$emit('update:content', val.editor.getJSON());
                },
                onBlur: () => {
                    this.$emit('blur');
                },
                onFocus: () => {
                    this.$emit('focus');
                },
            };
            if (!this.drag_drop) {
                // disable dropping content onto editor
                editorOptions.editorProps = {
                    handleDOMEvents: {
                        drop: (view, e) => {
                            e.preventDefault();
                        },
                    },
                };
                // hide the drop position indicator
                editorOptions.dropCursor = {width: 0, color: 'transparent'};
            }

            this.editor = new Editor(editorOptions);
        },
        focus() {
            setTimeout(() => this.editor.commands.focus('end'), 50);
        },
        setContent(content, triggerUpdate = false) {
            const converted = this.convertToV2(content);
            this.editor.commands.setContent(converted, triggerUpdate);

            //this.init(content);
        },
        insertImage(src) {
            this.editor.chain().focus().setImage({src}).run();
        },
        getContent() {
            return this.editor.getJSON();
        },
        convertToV2(content) {
            if (content) {
                const changes = [
                    {old: 'bullet_list', new: 'bulletList'},
                    {old: 'code_block', new: 'codeBlock'},
                    {old: 'hard_break', new: 'hardBreak'},
                    {old: 'horizontal_rule', new: 'horizontalRule'},
                    {old: 'list_item', new: 'listItem'},
                    {old: 'ordered_list', new: 'orderedList'},
                    {old: 'table_cell', new: 'tableCell'},
                    {old: 'table_header', new: 'tableHeader'},
                    {old: 'table_row', new: 'tableRow'},
                    {old: 'todo_list', new: 'taskList'},
                    {old: 'todo_item', new: 'taskItem'},
                    {old: 'jira_node', new: 'link_node'},
                    {old: 'confluence_node', new: 'link_node'},
                ];

                const convertContent = (c) => {
                    const inList = changes.find((o) => o.old === c.type);

                    if (inList) {
                        c.type = inList.new;
                    }

                    if (Array.isArray(c.content)) {
                        c.content = c.content.map((k) => convertContent(k));
                    }

                    return c;
                };

                const converted = convertContent(content);

                return converted;
            }
        },
        async searchIssues() {
            if (!this.project || !this.project.jira_project) return;

            // TODO: use cache instead?
            await this.$store.dispatch('fetchIssuesForProject', this.project);

            this.issues = this.$store.getters.issuesForJiraProject(
                this.project.jira_project
            );
        },
        async filterIssues(query) {
            await this.searchIssues();

            const search_content = query.substr(
                this.project.jira_project.length + 1
            );

            if (+search_content > 0) {
                return this.issues.filter((issue) => {
                    return issue.key.includes(query.toUpperCase());
                });
            }

            return this.issues.filter((issue) => {
                return issue.summary
                    .toLowerCase()
                    .includes(search_content.toLowerCase());
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.link {
    &:hover {
        cursor: pointer;
    }
}
.bubble-tool-bar {
    height: 35px;
    display: flex;
    padding: 5px 0;
    background-color: white;
    justify-content: center;
    align-items: center;
    border: solid 1px $border-grey-light;
    cursor: pointer;
}
.floating-tool-bar {
    height: 35px;
    width: 380px;
    display: flex;
    padding: 5px 0;
    background-color: white;
    justify-content: center;
    align-items: center;
    border: solid 1px $border-grey-light;
    cursor: pointer;
}
.divider {
    height: 100%;
    width: 1px;
    background-color: $border-grey-light;
    cursor: pointer;
}
.floating-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 70px;
    margin: 0 10px;
    font-family: 'Rubik', sans-serif;
    background-color: white;
    border: 0;
    font-size: 26px;
    color: $black;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
        background-color: $border-grey-light;
    }
}

.bubble-button {
    height: 30px;
    width: 30px;
    margin: 0 10px;
    font-family: 'Rubik', sans-serif;
    background-color: white;
    border: 0;
    font-size: 16px;
    color: $black;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
        background-color: $border-grey-light;
    }
}
.text-editor-wrapper {
    display: flex;
    position: relative;

    .editor {
        flex: 1;
    }

    ::v-deep .ProseMirror {
        text-align: left;
        padding: 1px 10px;
        border: 1px solid $border-grey-light !important;
        border-radius: 4px;
        background: $white;
        line-height: 20px;
        box-shadow: 0 0 0 0 $blue;
        transition: box-shadow 0.2s ease-in-out;

        &[contenteditable='true'] {
            &.ProseMirror-focused {
                box-shadow: 0 0 0 2px $blue;
            }
        }

        // Selection highlighting for image nodes
        img {
            max-width: 100%;

            &.ProseMirror-selectednode {
                filter: invert(28%) sepia(100%) hue-rotate(-180deg) saturate(3);
                outline: 2px solid #365384 !important;
            }
        }

        .is-editor-empty:first-child::before {
            content: attr(data-placeholder);
            float: left;
            opacity: 0.3;
            pointer-events: none;
            height: 0;
            font-size: 14px;
        }

        pre {
            color: #f0f0f0;
            background-color: black;
            border-radius: 6px;
            padding: 10px;

            &::before {
                content: attr(data-language);
                text-transform: uppercase;
                display: block;
                text-align: right;
                font-weight: bold;
                font-size: 0.6rem;
            }

            code {
                .hljs-comment,
                .hljs-quote {
                    color: #999999;
                }
                .hljs-attribute {
                    color: #f2444a;
                }
                .hljs-variable,
                .hljs-template-variable,
                .hljs-tag,
                .hljs-name,
                .hljs-regexp,
                .hljs-link,
                .hljs-name,
                .hljs-selector-id,
                .hljs-selector-class {
                    color: #f2777a;
                }
                .hljs-number,
                .hljs-meta,
                .hljs-built_in,
                .hljs-builtin-name,
                .hljs-literal,
                .hljs-type,
                .hljs-params {
                    color: #f99157;
                }
                .hljs-string,
                .hljs-symbol,
                .hljs-bullet {
                    color: #99cc99;
                }
                .hljs-title,
                .hljs-section {
                    color: #ffcc66;
                }
                .hljs-keyword,
                .hljs-selector-tag {
                    color: #6699cc;
                }
                .hljs-emphasis {
                    font-style: italic;
                }
                .hljs-strong {
                    font-weight: 700;
                }
            }
        }
    }

    &.dark {
        ::v-deep .ProseMirror {
            background-color: $black;
            border: none !important;
            color: $white;
        }
    }
}

::v-deep .ProseMirror {
    h1,
    h2,
    h3,
    h4,
    h5 {
        background-color: transparent;

        transition: background-color 0.5s ease-in-out;
    }
}

::v-deep .highlight {
    background-color: yellow !important;
}

.icon-codeblock {
    font-size: 14px;
    display: flex;
    align-items: center;
    span {
        font-size: 10px;
    }
}

// TODO workaround for bubble menu not showing
// (why is the wrapper div set to hidden?)
::v-deep .tippy-content > div {
    visibility: visible !important;
}
</style>
