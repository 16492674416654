<template>
    <div class="row">
        <content-block style="margin-right: 5px;">
            <div class="title">Today</div>
            <template v-if="today_calendar.length > 0">
                <calendar-item-card
                    v-for="event of today_calendar"
                    :key="event.id"
                    class="event"
                    :event="event"
                />
            </template>
            <div v-else class="placeholder">
                No scheduled events
            </div>
        </content-block>
        <content-block style="margin-left: 5px; margin-bottom: 10px;">
            <div class="title">Tomorrow</div>
            <template v-if="tomorrow_calendar.length > 0">
                <calendar-item-card
                    v-for="event of tomorrow_calendar"
                    :key="event.id"
                    class="event"
                    :event="event"
                />
            </template>
            <div v-else class="placeholder">
                No scheduled events
            </div>
        </content-block>
    </div>
</template>

<script>
import {momentWithTz} from '@/utils';

import ContentBlock from '@/components/blocks/ContentBlock';
import CalendarItemCard from '@/components/overview/CalendarItemCard';
export default {
    components: {ContentBlock, CalendarItemCard},
    data() {
        return {
            calendar: [],
            calendar_ignore: null,
        };
    },
    computed: {
        ignored_events() {
            if (!this.calendar_ignore || !this.calendar_ignore.ignored_events)
                return [];
            return this.calendar_ignore.ignored_events;
        },
        filtered_calendar() {
            return this.calendar.filter((cal) => {
                if (
                    this.ignored_events.includes(cal.id) ||
                    this.ignored_events.includes(cal.recurringEventId)
                )
                    return false;
                return true;
            });
        },
        today_calendar() {
            return this.filtered_calendar.filter((cal) => {
                if (
                    momentWithTz(cal.start.toDate()).isSame(
                        momentWithTz(),
                        'day'
                    )
                ) {
                    return true;
                }
                return false;
            });
        },
        tomorrow_calendar() {
            return this.filtered_calendar.filter((cal) => {
                if (
                    momentWithTz(cal.start.toDate()).isSame(
                        momentWithTz().add(1, 'day'),
                        'day'
                    )
                ) {
                    return true;
                }
                return false;
            });
        },
    },
    mounted() {
        const start = momentWithTz().startOf('day').toDate();
        const end = momentWithTz().endOf('day').add(1, 'day').toDate();

        const user = this.$store.getters.session_user;
        this.$bind('calendar_ignore', this.$fire.doc('system/calendar'), {
            maxRefDepth: 0,
        });

        this.$bind(
            'calendar',
            this.$fire
                .collection('calendar')
                .where('start', '>', start)
                .where('start', '<', end)
                .where('attendee_emails', 'array-contains', user.email)
                .orderBy('start'),
            {maxRefDepth: 0}
        );
    },
};
</script>

<style lang="scss" scoped>
.row {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 992px) {
        flex-direction: column;
    }

    .content-block {
        display: flex;
        flex-direction: column;
        flex: 1;

        @media screen and (max-width: 992px) {
            padding-left: 10px;
            padding-right: 10px;
            margin-left: 0 !important;
        }

        .title {
            padding-bottom: 20px;
        }

        .event {
            width: 440px;
        }

        .placeholder {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba($black, 0.4);
            font-size: 12px;

            @media screen and (max-width: 992px) {
                justify-content: flex-start;
            }
        }
    }
}
</style>
