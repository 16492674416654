<template>
    <div class="container">
        <div
            v-if="!project.current_status || updatingStatus"
            v-has-project-permission="'project.status.update'"
            class="current-status"
        >
            <div class="title">
                <h3>Project Status</h3>
                <el-button
                    v-if="project.current_status"
                    size="mini"
                    @click="cancelUpdate"
                >
                    Cancel
                </el-button>
            </div>
            <el-card
                shadow="never"
                :style="{
                    borderTopColor: selectedStatus
                        ? selectedStatus.color
                        : 'transparent',
                }"
            >
                <el-select v-model="selectedStatus" placeholder="Status">
                    <el-option
                        v-for="s of statuses"
                        :key="s.value"
                        :label="s.label"
                        :value="s"
                        value-key="value"
                        class="status-option"
                    >
                        <span class="dot" :style="{backgroundColor: s.color}" />
                        <span>{{ s.label }}</span>
                    </el-option>
                </el-select>

                <el-input
                    v-model="content"
                    resize="none"
                    type="textarea"
                    placeholder="Notes"
                    maxlength="140"
                    show-word-limit
                    @keyup.enter.native="createStatus"
                />
            </el-card>
        </div>
        <div v-else-if="current_status" class="current-status">
            <div class="title">
                <h3 :style="{color: current_status.color}">
                    {{ current_status.label }}
                </h3>
                <el-button
                    v-has-project-permission="'project.status.update'"
                    size="mini"
                    @click="updateStatus"
                >
                    Set Status
                </el-button>
            </div>
            <div class="time-spent">
                <label>Time spent this week: </label>
                <span v-if="!loadingSessions">
                    {{ time_spent_this_week | milli2duration }}
                </span>
            </div>
            <el-card
                shadow="never"
                class="status-card"
                :style="{borderTopColor: current_status.color}"
            >
                <h4>{{ current_event.content }}</h4>
                <div class="time">
                    {{ current_event.created_at.toDate() | timeAgo }}
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import {momentWithTz} from '@/utils';

export default {
    name: 'project-status',
    data() {
        return {
            selectedStatus: null,
            isFocused: false,
            content: '',
            updatingStatus: false,
            sessions: [],
            loadingSessions: true,
        };
    },
    computed: {
        status_event_type() {
            return this.$store.getters
                .eventTypes()
                .find((et) => et.type === 'status');
        },
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        current_event() {
            return this.$store.getters.eventWithId(
                this.$options.filters.fireRef2id(this.project.current_status)
            );
        },
        current_status() {
            if (this.project.current_status) {
                return this.statuses.find(
                    (status) => status?.value === this.current_event?.status
                );
            }

            return null;
        },
        statuses() {
            if (!this.status_event_type) return [];

            return this.status_event_type.statuses;
        },
        user() {
            if (this.project.current_status) {
                return this.$store.getters.userWithId(
                    this.project.current_status.created_by
                );
            }

            return null;
        },
        time_spent_this_week() {
            return this.$options.filters.getTotalDurationForSessions(
                this.sessions
            );
        },
    },
    mounted() {
        const week_start = momentWithTz().startOf('week').toDate();
        const week_end = momentWithTz().endOf('week').toDate();

        this.loadingSessions = true;

        this.$bind(
            'sessions',
            this.$fire
                .collection('sessions')
                .where(
                    'project',
                    '==',
                    this.$fire.doc(`projects/${this.$route.params.project_id}`)
                )
                .where('start', '>=', week_start)
                .where('start', '<=', week_end),
            {maxRefDepth: 0}
        )
            .then(() => {
                this.loadingSessions = false;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    methods: {
        async createStatus() {
            if (this.selectedStatus && this.content.length > 0) {
                await this.$store.dispatch('addEvent', {
                    project: this.$fire.doc(
                        `/projects/${this.$route.params.project_id}`
                    ),
                    status: this.selectedStatus.value,
                    content: this.content,
                    type: this.$fire.doc(
                        `system/events/types/${this.status_event_type.id}`
                    ),
                });

                setTimeout(() => {
                    this.updatingStatus = false;
                    this.selectedStatus = null;
                    this.content = '';
                }, 200);
            }
        },
        updateStatus() {
            this.updatingStatus = true;
        },
        cancelUpdate() {
            this.updatingStatus = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.status-option {
    display: flex;
    align-items: center;

    .dot {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 10px;
    }
}

.container {
    padding: 30px 20px 0px 20px;
    box-sizing: border-box;

    .current-status {
        width: 100%;

        .title {
            display: flex;
            align-items: flex-start;

            h3 {
                flex: 1;
                height: 28px;
                line-height: 28px;
                margin: 0 0 16px 0;
            }
        }

        .time {
            margin-top: 10px;
            font-size: 11px;
            color: rgba($black, 0.6);
        }
    }

    .time-spent {
        margin-bottom: 10px;

        label {
            font-size: 12px;
        }
        span {
            font-size: 12px;
            font-weight: bold;
        }
    }

    .el-card {
        transition: none;
        border-top: 4px solid $border-grey;
        width: 100%;

        .el-select {
            width: 100%;
            margin-bottom: 10px;
        }

        ::v-deep .el-textarea {
            textarea {
                font-family: 'Rubik', sans-serif;
                padding-right: 60px;
            }
        }
    }

    .status-card {
        h4 {
            margin: 0;
        }
    }
}
</style>
