<template>
    <section>
        <text-editor ref="text_editor" @update:content="update" />
        <pre>{{ content }}</pre>
    </section>
</template>

<script>
import TextEditor from '@/components/editor/TextEditor';

export default {
    components: {
        TextEditor,
    },
    data() {
        return {
            content: '',
        };
    },

    methods: {
        update(data) {
            this.content = data;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.tool-editor {
    position: relative;
}
</style>
