<template>
    <div class="numeric-input" @keydown="handleKeys" @keyup="handleKeys">
        <div class="action-icon decrease" @click="handleDecrease">
            <i class="el-icon-minus" />{{ modifierDisplay }}
        </div>
        <el-input
            ref="input"
            v-model="inputValue"
            class="input"
            @input="handleChange"
        />
        <div class="action-icon increase" @click="handleIncrease">
            <i class="el-icon-plus" />{{ modifierDisplay }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'numeric-input',
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            inputValue: this.value,
            numericValue: this.value,
            modifier: 1,
        };
    },
    computed: {
        modifierDisplay() {
            return this.modifier !== 1 ? this.modifier : '';
        },
    },
    watch: {
        value: {
            handler(val) {
                this.inputValue = val;
                this.numericValue = val;
            },
            immediate: true,
        },
    },
    methods: {
        handleKeys(event) {
            // check modifier status
            if (event.shiftKey) {
                this.modifier = 10;
            } else {
                this.modifier = 1;
            }

            // handle control keys
            if (event.type === 'keydown') {
                switch (event.key) {
                    case 'ArrowUp': {
                        this.handleIncrease();
                        break;
                    }
                    case 'ArrowDown': {
                        this.handleDecrease();
                        break;
                    }
                    case 'Enter': {
                        this.$emit('change', this.numericValue);
                    }
                }
            }
        },
        handleChange(newValue) {
            const cleanString = newValue.replace(/[^0-9.]/g, '');
            this.numericValue = parseFloat(cleanString);
            this.inputValue = cleanString;

            this.$emit('input', this.numericValue);
        },
        setValue(newValue) {
            if (newValue < 0) {
                newValue = 0;
            }

            this.numericValue = newValue;
            this.inputValue = newValue;

            this.$emit('input', this.numericValue);
        },
        handleIncrease() {
            this.setValue(this.numericValue + this.modifier);
            this.focusInput();
        },
        handleDecrease() {
            this.setValue(this.numericValue - this.modifier);
            this.focusInput();
        },
        focus() {
            this.modifier = 1;
            this.focusInput();
        },
        focusInput() {
            this.$refs.input.focus();
            setTimeout(() => {
                this.$refs.input.select();
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.numeric-input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .action-icon {
        display: flex;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        color: #888;

        &:hover {
            color: $blue;
        }
    }

    ::v-deep .input {
        width: auto;

        .el-input__inner {
            border: none;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 18px;
            width: 80px;

            &::selection {
                background: none;
            }
        }
    }
}
</style>
