<template>
    <li class="time-change-block">
        <avatar :user="user" size="mini" hide_tooltip />
        <span>
            <span v-if="is_changed_by_user">
                {{ user.name }}
            </span>
            <label
                v-else-if="is_changed_by_change_request"
                class="clickable"
                @click="openChangeRequest"
            >
                {{ change_request.ref }}
            </label>
            <label v-else>
                A change request
            </label>
            changed
            <label>{{ key }}</label>
            {{ data.created_at.seconds | timestamp2moment | timeAgo }}
            from
            <label v-if="data.from === null">not set</label>
            <label v-else>{{ data.from | milli2duration }}</label>
            to
            <label v-if="data.to === null">not set</label>
            <label v-else>{{ data.to | milli2duration }}</label>
        </span>
    </li>
</template>

<script>
import Avatar from '../generic/Avatar';

export default {
    name: 'time-change-block',
    components: {Avatar},
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        is_changed_by_user() {
            return this.data.changed_by.includes('user');
        },
        is_changed_by_change_request() {
            return this.$store.getters.changeRequestWithId(
                this.data.changed_by
            );
        },
        user() {
            return this.$store.getters.userWithId(this.data.changed_by);
        },
        key() {
            return this.data.key.replace('_', ' ');
        },
    },
    methods: {
        openChangeRequest() {
            this.$router.push({
                name: 'project_detail_changerequests+detail',
                params: {
                    project_id: this.$route.params.project_id,
                    change_request_id: this.change_request.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
li.time-change-block {
    border-left: 2px solid $border-grey-light;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;

    .el-tooltip {
        margin-right: 10px;
        margin-bottom: 2px;
    }

    ::v-deep .el-tooltip {
        margin-left: 5px;
    }

    span {
        font-size: 12px;
        line-height: 22px;

        label {
            background-color: $border-grey-light;
            padding: 3px 7px;
            border-radius: 5px;
            margin: 0 5px;

            &.clickable {
                cursor: pointer;

                &:hover {
                    color: $blue;
                }
            }
        }
    }
}
</style>
