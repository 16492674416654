import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';
import {momentWithTz} from '@/utils';
import axios from 'axios';

import {
    getConfluenceTasksForProject,
    getConfluenceTasksForUser,
} from '@/api/atlassian';

const todoMixin = {
    data() {
        return {
            closed_todos: [],
            open_todos: [],
            tasks: [],
            jira_forbidden: false,
            todos_loading: true,
            confluence_loading: false,
            confluence_cancel: null,
        };
    },
    computed: {
        todosTitle() {
            if (
                this.$store.getters.is_feature_enabled(SUPPORTED_FEATURES.TODOS)
            ) {
                return 'TODOs';
            } else if (
                this.$store.getters.is_jira_enabled ||
                this.$store.getters.is_confluence_enabled
            ) {
                return 'Tickets';
            }
            return '';
        },
        loading() {
            return (
                this.todos_loading ||
                this.confluence_loading ||
                this.jira_loading
            );
        },
        has_nothing() {
            return (
                !this.open_todos.length &&
                !this.tasks.length &&
                !this.issues.length
            );
        },
        jira_loading() {
            return this.$store.state.jira_cache_updating;
        },
        issues() {
            if (this.project?.id) {
                return this.$store.getters.issuesForProjectWithId(
                    this.project.id
                );
            } else {
                return this.$store.getters.userIssues;
            }
        },
        grouped_items() {
            return [
                ...this.open_todos,
                ...this.closed_todos,
                ...this.tasks,
                ...this.issues,
            ]
                .reduce((acc, cur) => {
                    const group = {
                        name: this.project ? 'Unassigned' : 'None',
                        key: null,
                        todos: [],
                        tasks: [],
                        issues: [],
                    };

                    // The item is a Jira ticket
                    if (cur.jira_project) {
                        if (!this.project) {
                            // group by project
                            group.name = cur.project.name;
                            group.key = cur.project.ref;
                            group.project_ref = cur.project.ref;
                        } else {
                            // group by user
                            if (cur.assignee) {
                                let assigned_to;
                                if (cur.assignee.email) {
                                    assigned_to = this.$store.getters.userWithEmail(
                                        cur.assignee.email
                                    );
                                } else {
                                    assigned_to = this.$store.getters.userWithName(
                                        cur.assignee.name
                                    );
                                }
                                if (assigned_to) {
                                    group.name = assigned_to?.name;
                                    group.key = assigned_to?.id;
                                } else {
                                    group.name = cur.assignee.name;
                                }
                            }
                        }
                        group.issues.push(cur);
                    } else {
                        // The item is a Confluence task or tool todo -> identical behaviour
                        if (!this.project) {
                            const project = this.$store.getters.projectWithId(
                                cur.project
                            );

                            if (project) {
                                group.name = project.name;
                                group.key = project.ref;
                                group.project_ref = project.ref;
                            }
                        } else {
                            const assigned_to = cur.assigned_to;
                            const user = this.$store.getters.userWithId(
                                assigned_to
                            );
                            group.name = user?.name;
                            group.key = assigned_to;
                        }
                        if (cur.confluence) group.tasks.push(cur);
                        else group.todos.push(cur);
                    }

                    const existing_group = acc.find(
                        (g) => g.name === group.name
                    );

                    if (existing_group) {
                        existing_group.issues.push(...group.issues);
                        existing_group.tasks.push(...group.tasks);
                        existing_group.todos.push(...group.todos);
                    } else {
                        acc.push(group);
                    }

                    return acc;
                }, [])
                .sort((a, b) => {
                    if (!this.project) {
                        if (a.name === 'None') return 1;
                        if (a.name < b.name) return -1;
                        if (b.name < a.name) return 1;
                        return 0;
                    }
                    const my_id = `users/${this.$store.state.user.object.id.toString()}`;
                    if (a.name === 'Unassigned') return 1;
                    if (a.key === b.key) return 0;
                    if (a.key === my_id) return -1;
                    if (a.key !== null && b.key !== my_id) return -1;
                    return 1;
                })
                .map((g) => {
                    const sorted = g.todos.sort((a, b) => {
                        if (a.due_by && b.due_by)
                            return a.due_by.seconds - b.due_by.seconds;
                        if (a.due_by) return -1;
                        if (b.due_by) return 1;
                        return -1;
                    });

                    return {...g, todos: sorted};
                });
        },
    },
    async created() {
        await Promise.all([this.bindTodos(), this.fetchConfluenceTasks()]);
    },
    destroyed() {
        if (this.confluence_cancel !== null) {
            this.confluence_cancel.cancel('Navigated away');
        }
    },
    methods: {
        async fetchConfluenceTasks() {
            if (
                !this.is_atlassian_authed ||
                !this.$store.getters.is_confluence_enabled
            ) {
                return;
            }
            this.confluence_loading = true;
            try {
                const CancelToken = axios.CancelToken;

                this.confluence_cancel = CancelToken.source();

                const promises = [];
                if (this.project) {
                    if (this.project?.confluence_org) {
                        promises.push(
                            getConfluenceTasksForProject(this.project, {
                                cancelToken: this.confluence_cancel.token,
                            })
                        );
                    }
                } else {
                    promises.push(
                        ...this.$store.getters.activeProjectsWithConfluence.map(
                            (project) => {
                                return getConfluenceTasksForUser(project, {
                                    cancelToken: this.confluence_cancel.token,
                                });
                            }
                        )
                    );
                }
                const results = await axios.all(promises);

                this.tasks = results.reduce((acc, curr) => {
                    acc.push(...this.processConfluenceTasks(curr.data.tasks));
                    return acc;
                }, []);
            } catch (err) {
                if (axios.isCancel(err)) return;
                console.error('Error: ', err);
            }

            this.confluence_cancel = null;
            this.confluence_loading = false;
        },
        refreshCachedIssues() {
            if (
                !this.$store.getters.is_jira_enabled ||
                !this.is_atlassian_authed
            ) {
                return;
            }
            if (this.project?.id) {
                return this.$store
                    .dispatch(
                        'updateJiraCacheForProjectWithId',
                        this.project.id
                    )
                    .catch((err) => {
                        if (err?.response?.status === 403) {
                            this.jira_forbidden = true;
                        }
                    });
            } else {
                this.$store.dispatch('updateJiraCache').catch((err) => {
                    if (err?.response?.status === 403) {
                        this.jira_forbidden = true;
                    }
                });
            }
        },
        async bindTodos() {
            if (
                !this.$store.getters.is_feature_enabled(
                    SUPPORTED_FEATURES.TODOS
                )
            )
                return;

            this.todos_loading = true;

            const today_start = momentWithTz().startOf('day').toDate();
            const today_end = momentWithTz().endOf('day').toDate();

            let field = 'assigned_to';
            let ref = this.$fire.doc(`users/${this.$store.getters.user.id}`);
            if (this.project?.id) {
                field = 'project';
                ref = this.$fire.doc(`projects/${this.project.id}`);
            }

            await Promise.all([
                this.$bind(
                    'closed_todos',
                    this.$fire
                        .collection('todo')
                        .where('status', '==', 'closed')
                        .where(field, '==', ref)
                        .where('updated_at', '>=', today_start)
                        .where('updated_at', '<=', today_end)
                        .orderBy('updated_at', 'asc'),
                    {maxRefDepth: 0}
                ),
                this.$bind(
                    'open_todos',
                    this.$fire
                        .collection('todo')
                        .where('status', '==', 'open')
                        .where(field, '==', ref)
                        .orderBy('created_at', 'asc'),
                    {maxRefDepth: 0}
                ),
            ]);
            this.todos_loading = false;
        },
    },
};

export default todoMixin;
