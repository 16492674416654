<template>
    <Estimate__form
        :is_template="is_template"
        :groups="groups"
        :clone_rule="clone_rule"
        :copied_form="copied_form"
    />
</template>

<script>
import Estimate__form from './components/Estimate_form';

export default {
    name: 'estimate-new',
    components: {
        Estimate__form,
    },
    data() {
        return {
            is_template: this.$router.currentRoute.meta.is_template || false,
            groups: this.$route.params.groups,
            clone_rule: this.$route.params.clone_rule,
            copied_form: this.$route.params.copied_form,
        };
    },
};
</script>

<style lang="scss" scoped></style>
