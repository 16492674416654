<template>
    <el-tooltip :open-delay="200" placement="bottom" popper-class="top-tooltip">
        <div slot="content" class="tooltip-content">
            Search <span>cmd + k</span>
        </div>
        <div
            class="search"
            @click="
                $bus.$emit('modal:action', {
                    modal: 'command-palette',
                    show: true,
                })
            "
        >
            <i slot="prepend" class="el-icon-search" />
        </div>
    </el-tooltip>
</template>

<script>
export default {
    name: 'search-input',
};
</script>

<style lang="scss" scoped>
.search {
    margin-right: 10px;
    padding: 0 10px;
    cursor: pointer;
    transition: transform 0.15s ease-in-out;

    i {
        color: black;
        font-size: 12px;
        transition: all 0.4s ease-in-out, color 0.15s ease-in-out;
    }

    &:hover {
        transform: scale(1.05);

        i {
            color: $blue;
        }
    }

    &:not(.focus) {
        i {
            font-size: 16px;
        }
    }
}

.tooltip-content {
    span {
        background-color: rgba(#6f6f6f, 0.3);
        padding: 3px 10px;
        border-radius: 4px;
        margin-left: 5px;
    }
}
</style>
