/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'steps-error-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.138 10h2V5h-2v5zm0 3h2v-2h-2v2zm8.765.109l-5.968-12a2.045 2.045 0 00-.743-.812A1.956 1.956 0 008.138 0a1.952 1.952 0 00-1.782 1.109l-6 12a1.872 1.872 0 00-.226.985c.016.343.117.664.305.961.187.297.431.528.734.695.302.167.625.25.969.25h11.968c.344 0 .669-.086.977-.258.307-.172.549-.404.727-.695a1.959 1.959 0 00.093-1.938z" _fill="currentColor" fill-rule="evenodd"/>'
  }
})
