<template>
    <div class="stage-notes">
        <p
            v-if="!editing"
            class="notes"
            :class="{'can-edit': user_is_admin}"
            @click="toggleEdit"
        >
            {{ value || 'Add notes' }}
        </p>

        <el-input
            v-if="editing"
            v-model="notes"
            v-on-clickaway="toggleEdit"
            size="small"
            placeholder="Insert note"
            @keyup.enter.native="toggleEdit"
        >
        </el-input>
        <span v-if="editing" class="info">( enter to close )</span>
    </div>
</template>

<script>
import {mixin as clickaway} from 'vue-clickaway';
import userMixin from '@/mixins/user.mixin';

export default {
    name: 'stage-notes',
    mixins: [clickaway, userMixin],
    props: {
        value: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            notes: this.value,
            editing: false,
        };
    },
    watch: {
        notes: function (val) {
            this.$emit('change', val.replace(/\n$/, ''));
        },
    },
    methods: {
        toggleEdit() {
            this.editing = !this.editing;
        },
    },
};
</script>

<style lang="scss" scoped>
.stage-notes {
    position: relative;

    p.notes {
        color: rgba($black, 0.6);
        font-size: 11px;
        font-weight: 300;
        pointer-events: none;

        &.can-edit {
            pointer-events: auto;

            &:hover:after {
                content: ' ( click to edit )';
            }
        }
    }

    span.info {
        color: rgba($black, 0.5);
        font-size: 11px;
        font-weight: 300;

        position: absolute;
        right: 0;
        top: -8px;
    }

    .el-input {
        margin: 10px 0;
    }
}
</style>
