import {COMMAND_DEPENDENCIES, USER_ROLES} from '@/enums';
import {
    Navigations,
    AdminNavigations,
} from '@/components/command-palette/providers/Navigations';
import store from '@/store';

/**
 * Provides a list of base navigable pages.
 * Pages requiring selected data will be handled elsewhere
 * @type {{provides: string, getResults: CommandProvider.getResults, label: string, filteredBy: null}}
 */
export const NavigationProvider = {
    label: 'Navigation',
    provides: COMMAND_DEPENDENCIES.NAVIGATION,
    filteredBy: null,
    getResults: async (data, filter_string) => {
        const current_user = store.getters.session_user;
        const is_admin =
            current_user.role.includes(USER_ROLES.ADMIN) ||
            current_user.role.includes(USER_ROLES.SUPER_ADMIN);

        const fullnav = is_admin
            ? [...AdminNavigations, ...Navigations]
            : Navigations;
        if (!filter_string) {
            return Navigations;
        } else {
            const filter = filter_string.toLowerCase();
            return fullnav.filter((c) => {
                return (
                    c.label.toLowerCase().includes(filter) ||
                    c.keywords?.toLowerCase().includes(filter)
                );
            });
        }
    },
    cleanup() {
        // if this provider holds any resources during an operation, dispose of them here
    },
};
