<template>
    <div class="container" @click="handleClick">
        <span class="type">{{ module.type.value }}</span>
        <span class="subtype">{{ module.subtype.value }}</span>
        <div v-if="environments.length" class="environments">
            <module-environment-status
                v-for="env in environments"
                :key="env.name"
                :branch="env.branch"
                :environment="env.name"
                :repository="module.repository"
                :workspace="module.workspace"
            />
        </div>
    </div>
</template>

<script>
import ModuleEnvironmentStatus from '../modules/ModuleEnvironmentStatus';

export default {
    name: 'project-module',
    components: {
        ModuleEnvironmentStatus,
    },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        environments() {
            if (!this.module.environments) return [];
            return Object.entries(this.module.environments).map(
                ([key, data]) => {
                    return {name: key, ...data};
                }
            );
        },
    },

    methods: {
        handleClick() {
            this.$router.push({
                name: 'module_detail',
                params: {
                    module_id: this.module.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    flex: 1;
    min-width: 300px;
    padding: 20px;
    margin: 0px 10px 10px 0px;
    border-radius: 5px;
    border: 1px solid $border-grey;
    background-color: $white;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;

    &:hover {
        border-color: $blue;
    }

    span.type {
        font-size: 13px;
        display: block;
    }

    span.subtype {
        font-size: 11px;
        display: block;
        margin-top: 3px;
    }

    .environments {
        border-top: 1px solid $border-grey-light;
        margin-top: 15px;
        padding-top: 10px;
    }
}
</style>
