import userMixin from '@/mixins/user.mixin';
import lineitemMixin from '@/mixins/lineitem.mixin';

const componentInvoiceMixin = {
    mixins: [userMixin, lineitemMixin],
    data() {
        return {
            line_items: [],
        };
    },
    created() {
        if (this.user_is_super_admin && this.component)
            this.$bind(
                'line_items',
                this.$fire
                    .collection('line_items')
                    .where(
                        'component',
                        '==',
                        this.$fire.doc(`components/${this.component.id}`)
                    ),
                {
                    maxRefDepth: 0,
                }
            );
    },
    computed: {
        total_invoiced() {
            return this.line_items.reduce((acc, line_item) => {
                return acc + (line_item.line_amount || 0);
            }, 0);
        },
        invoiced_hours() {
            return this.line_items.reduce((acc, line_item) => {
                return (
                    acc +
                    (lineitemMixin.methods.lineItem_quantity(line_item) || 0)
                );
            }, 0);
        },
    },
};

export default componentInvoiceMixin;
