<template>
    <div class="wrapper">
        <div
            v-for="project in projects_with_payment_plans"
            :key="project.ref"
            class="project"
        >
            <span>
                <strong>{{ project.ref }}</strong> -
                {{ project.name }}
            </span>
            <div
                v-for="plan in project.payment_plans"
                :key="plan.id"
                class="payment-plan"
                :class="{invoiced: plan.invoiced}"
            >
                <span
                    class="group-label"
                    @click="
                        handleClickEstimate(
                            $store.getters.estimateWithId(
                                $options.filters.fireRef2id(plan.estimate)
                            )
                        )
                    "
                >
                    {{ plan.estimate | fireRef2id }}
                </span>
                <div class="payment-plan-invoices">
                    <payment-plan-invoice-block
                        v-for="invoice in plan.invoices"
                        :key="invoice.id"
                        :invoice="invoice"
                        :project_ref="project.ref"
                        :estimate="invoice.estimate"
                        :invoices_count="plan.quantity"
                        @click="handleClickPaymentPlanInvoice"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentPlanInvoiceBlock from '@/components/blocks/PaymentPlanInvoiceBlock';

import {INVOICE_STATUS} from '@/enums';

import {fireRef2id} from '@/filters';

export default {
    name: 'paymentplans-by-project',
    components: {
        PaymentPlanInvoiceBlock,
    },
    props: {
        search: {
            type: String,
            default: null,
        },
        plan_state: {
            type: String,
            default: null,
        },
        payment_plan_invoices: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        projects() {
            return this.$store.getters.projects;
        },
        payment_plans() {
            return this.$store.getters.paymentPlans;
        },
        projects_with_payment_plans() {
            return this.projects.reduce((projects, p) => {
                const plans = this.payment_plans
                    .filter(
                        (pp) =>
                            this.$options.filters.fireRef2id(pp.project) ===
                            p.id
                    )
                    .reduce((plans, pp) => {
                        let invoices = this.getPaymentPlanInvoices(pp.id);

                        // plan is not fully invoiced if any payment_plan_invoices are missing an invoice_id
                        // (determine status before filtering)
                        const invoiced = !invoices.find((i) => !i.invoice_id);

                        // don't include plan if there are no invoices
                        if (!invoices.length) return plans;

                        let show_plan = false;

                        switch (this.plan_state) {
                            case 'paid': {
                                show_plan = invoices.reduce((acc, i) => {
                                    if (!acc) return false;

                                    const _invoice = this.$store.getters.invoiceWithId(
                                        i.invoice_id
                                    );

                                    return (
                                        _invoice &&
                                        _invoice.status === INVOICE_STATUS.PAID
                                    );
                                }, true);
                                break;
                            }
                            case 'planning': {
                                show_plan = invoices.reduce((acc, i) => {
                                    if (acc) return false;

                                    const _invoice = this.$store.getters.invoiceWithId(
                                        i.invoice_id
                                    );

                                    return !_invoice;
                                }, false);
                                break;
                            }
                            case 'all':
                            default:
                                show_plan = true;
                        }

                        if (show_plan) {
                            plans.push({
                                ...pp,
                                id: pp.id,
                                invoices,
                                invoiced,
                            });
                        }

                        return plans;
                    }, [])
                    .sort((a, b) => a.id.localeCompare(b.id));

                if (plans.length) {
                    projects.push({...p, payment_plans: plans});
                }

                return projects;
            }, []);
        },
    },
    methods: {
        getPaymentPlanInvoices(plan_id) {
            return this.payment_plan_invoices
                .filter((ppi) => {
                    return (
                        this.$options.filters.fireRef2id(ppi.payment_plan) ===
                        plan_id
                    );
                })
                .map((ppi) => {
                    return {
                        ...ppi,
                        id: ppi.id,
                        estimate: this.$store.getters.estimateWithId(
                            ppi.estimate
                        ),
                        total: ppi.line_items.reduce((sum, item) => {
                            return (sum +=
                                (item.amount *
                                    (item.credit ? -1 : 1) *
                                    (100 - (item.discount || 0))) /
                                100);
                        }, 0),
                    };
                })
                .sort((a, b) => a.sequence - b.sequence);
        },
        handleClickEstimate(estimate) {
            this.$router.push({
                name: 'project_detail_paymentplan',
                params: {
                    project_id: this.$options.filters.fireRef2id(
                        estimate.project
                    ),
                    estimate_id: estimate.id,
                },
            });
        },
        handleClickPaymentPlanInvoice(invoice, newWindow) {
            if (newWindow) {
                const project_id = fireRef2id(invoice.project);
                window.open(
                    `/project/${project_id}/paymentplan/invoice/${invoice.id}`,
                    'invoice',
                    'popup'
                );
            } else {
                this.$router.push({
                    name: 'project_detail_paymentplan_invoice',
                    params: {
                        project_id: this.$options.filters.fireRef2id(
                            invoice.project
                        ),
                        invoice_id: invoice.id,
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.project {
    margin-bottom: 20px;

    .payment-plan {
        margin-top: 10px;
        padding: 10px;
        background-color: $grey;
        border-radius: 4px;

        .group-label {
            font-size: 12px;
            font-weight: bold;
            cursor: pointer;
            display: block;
            margin-bottom: 3px;
        }
    }
}
</style>
