<template>
    <div
        v-if="project"
        class="container"
        :class="project.status + '-background'"
        @click="handleClick"
    >
        <span class="name">{{ project.name }}</span>
        <div v-if="total_invoiced" class="total">
            <label>{{ total_invoiced | currency }}</label>
            <span>(ex. GST)</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'client-project-block',
    props: {
        client: {
            type: Object,
            required: true,
        },
        project: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            total_invoiced: 0,
        };
    },
    mounted() {
        this.$fire
            .collection('line_items')
            .where(
                'project',
                '==',
                this.$fire.doc(`/projects/${this.project.id}`)
            )
            .get()
            .then((snapshot) => {
                snapshot.forEach((lineItem) => {
                    const data = lineItem.data();
                    this.total_invoiced += data.line_amount;
                });
            });
    },
    methods: {
        handleClick() {
            this.$router
                .push({
                    name: 'management_project_detail',
                    params: {
                        project_id: this.project.id,
                    },
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    display: grid;
    height: 90px;
    width: calc((100% - 20px) / 3);
    padding: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid $border-grey;
    cursor: pointer;
    background-color: $white;
    box-sizing: border-box;
    user-select: none;

    &:nth-child(3n + 3) {
        margin-right: 0;
    }

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;
    }

    span.name {
        font-size: 15px;
        display: block;
        height: 30px;
        line-height: 30px;
    }

    .total {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            font-size: 11px;
            display: block;
            margin-right: 5px;
        }
        span {
            font-size: 8px;
            opacity: 0.8;
        }
    }
}
</style>
