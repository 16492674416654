<template>
    <div>
        <div class="header">
            <div class="week-selector">
                <div class="users-col">
                    <span>
                        Day {{ cursor.weekday() + 1 }} - #{{ cursor.isoWeek() }}
                    </span>
                    <button @click="moveCursor(-1)">
                        <svgicon name="triangle" class="triangle" />
                    </button>
                    <button @click="moveCursor(1)">
                        <svgicon name="triangle" class="triangle" />
                    </button>
                </div>
                <div class="options">
                    <el-button
                        v-if="!moment().isSame(cursor, 'day')"
                        size="mini"
                        @click="resetCursor()"
                    >
                        Today
                    </el-button>

                    <el-button
                        class="edit-button"
                        size="mini"
                        @click="editing = !editing"
                    >
                        {{ editing ? 'Done' : 'Edit' }}
                    </el-button>
                </div>
            </div>
            <div class="week-days">
                <div class="users-col">
                    <div
                        v-loading="loading"
                        class="spinner"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0)"
                    />
                </div>
                <div
                    v-for="day in days"
                    :key="day.format('DD')"
                    class="day-col"
                    :class="{today: day.isSame(moment(), 'day')}"
                >
                    <span>
                        {{ day.format('dddd') }}
                        <strong>{{ day.format('DD') }}</strong>
                    </span>
                </div>
            </div>
        </div>

        <div class="rows">
            <div
                v-for="user in users"
                :key="user.id"
                class="user-row"
                :class="{
                    'my-row': user.id === current_user.id,
                    editing: editing,
                }"
            >
                <div class="users-col">
                    <span>{{ user.name }}</span>
                </div>

                <div v-for="day in days" :key="day.toString()" class="day-col">
                    <day-bar-block
                        :slots="filterSlots(day, user)"
                        :day="day"
                        :user="user"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import userMixin from '@/mixins/user.mixin';

import DayBarBlock from '@/components/planner/DayBarBlock';

export default {
    name: 'planner-daily-view',
    components: {
        DayBarBlock,
    },
    mixins: [userMixin],
    props: {
        days: {
            type: Array,
            required: true,
        },
        slots: {
            type: Array,
            required: true,
        },
        cursor: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            week_length: 7,
            delta: 0,
            editing: false,
        };
    },
    methods: {
        moment() {
            return moment();
        },
        moveCursor(delta) {
            this.$emit('moveCursor', delta);
        },
        resetCursor() {
            this.$emit('resetCursor');
        },
        filterSlots(day, user) {
            return this.slots.filter((slot) => {
                return (
                    slot.user == `users/${user.id}` &&
                    moment
                        .utc(slot.day.seconds * 1000)
                        .local()
                        .isSame(day, 'day')
                );
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.week-selector {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;

    .users-col {
        button {
            border: none;
            background: none;
            width: 23px;
            height: 100%;
            padding: 0 8px 5px 8px;
            cursor: pointer;

            svg {
                opacity: 0.1;
            }

            &:first-of-type {
                svg {
                    transform: rotate(180deg);
                }
            }

            &:hover svg {
                opacity: 0.05;
            }
        }

        span {
            flex: 1;
            padding-right: 7px;
            padding-bottom: 4px;
            font-size: 13px;
        }
    }

    .options {
        padding: 0 15px;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
}

.week-days {
    width: 100%;
    height: 50px;
    background-color: green;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $grey;

    .day-col {
        height: 50px;
        padding: 0;

        &.today {
            box-shadow: inset 0px -2px 0px 0px $blue;
        }
    }
}

.user-row {
    width: 100%;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    transition: opacity 0.1s ease-in-out;
    border-top: 2px solid $border-grey-light;

    &:hover,
    &.my-row,
    &.editing {
        opacity: 1;
    }

    &.my-row .day-col.my-col {
        background-color: rgba($blue, 0.05);
    }

    &:nth-child(even) {
        background-color: $grey;
    }

    .day-col {
        border-right: solid $border-grey-light 1px;
        height: 100%;
        min-height: 80px;
    }
}

.users-col {
    height: 50px;
    width: 170px;
    border-right: solid $border-grey 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    .spinner {
        width: 16px;
        height: 16px;
        margin-bottom: 4px;
    }
}

.day-col {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: solid $border-grey 1px;
    flex-direction: column;
    padding: 5px 10px;
    box-sizing: border-box;

    span strong {
        margin-left: 5px;
        font-size: 16px;
    }

    &:last-of-type {
        border-right: none;
    }
}
</style>
