/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heart': {
    width: 52,
    height: 44,
    viewBox: '0 0 52 44',
    data: '<defs><path pid="0" d="M44.405 1.792C48.933 4.256 52 8.984 52 14.417c0 1.554-.26 3.048-.728 4.445C48.748 29.979 26.004 44 26.004 44S3.246 29.979.726 18.862A13.967 13.967 0 010 14.417C0 8.982 3.067 4.252 7.595 1.792A14.886 14.886 0 0114.685 0c4.552 0 8.619 2.032 11.32 5.22A14.756 14.756 0 0137.308 0c2.576 0 4.99.654 7.096 1.792z" id="svgicon_heart_a"/></defs><use _fill="#F92139" xlink:href="#svgicon_heart_a" fill-rule="evenodd"/>'
  }
})
