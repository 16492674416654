<template>
    <timeline
        :tree="tree"
        :default_hidden="default_hidden"
        :ready="has_mounted"
        @update:node="handleUpdateNode"
        @click:node="handleNodeClick"
    />
</template>

<script>
import dayjs from 'dayjs';

import {
    max,
    min,
    adjustedStartDate,
    adjustedEndDate,
} from '@/components/timeline-v2/utils';
import Timeline from '@/components/timeline-v2/Timeline';
import Block from '@/components/timeline-v2/Block';
import BlockOverlay from '@/components/timeline-v2/BlockOverlay';
import Milestones from '@/components/timeline-v2/Milestones';
import ProjectListItem from '@/components/timeline-v2/ProjectListItem';
import DefaultListItem from '@/components/timeline-v2/DefaultListItem';
import {COMPONENT_STATUS, STAGE_STATUS} from '@/enums';

const uuid = () => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
};

// node definitions
const RootNode = ({children}) => ({
    id: uuid(),
    label: 'root',
    type: 'root',
    minDate: dayjs(),
    maxDate: dayjs(),
    children,
});

export const ProjectLine = (node) => {
    const milestoneDates = node.milestones.map((m) => m.date);

    return {
        id: uuid(),
        children: [],
        ...node,
        type: 'milestones',
        minDate: min(milestoneDates) || dayjs(),
        maxDate: max(milestoneDates) || dayjs(),
        listComponent: ProjectListItem,
        chartComponent: Milestones,
        containerStyle: {'margin-bottom': '30px'},
        rowStyle: {'margin-bottom': '5px'},
    };
};

export const BlockNode = (node) => ({
    id: uuid(),
    children: [],
    overlays: [],
    ...node,
    type: 'block',
    minDate: node.startDate,
    maxDate: node.endDate,
    listComponent: DefaultListItem,
    chartComponent: Block,
    containerStyle: {'margin-bottom': '0'},
    rowStyle: {'margin-bottom': '5px'},
});

export const OverlayNode = (node) => ({
    id: uuid(),
    children: [],
    ...node,
    type: 'Overlay',
    minDate: node.startDate,
    maxDate: node.endDate,
    chartComponent: BlockOverlay,
});

export default {
    name: 'planner-tab-timeline',
    components: {
        Timeline,
    },
    props: {
        project_ids: {
            type: Array,
            default: () => [],
        },
    },
    data: function () {
        return {
            has_mounted: true,
            create_milestones: false,
        };
    },
    computed: {
        projects() {
            if (this.project_ids.length === 0) {
                return this.$store.getters.projects;
            }

            return this.project_ids.map((p) =>
                this.$store.getters.projectWithId(p)
            );
        },
        activeProjects() {
            return this.projects.filter((p) => p.status === 'active');
        },
        tree() {
            return this.makeTree(this.activeProjects);
        },
        default_hidden() {
            const getHiddenNodes = (nodes) => {
                return nodes.reduce((acc, cur) => {
                    if (cur.meta && cur.meta.hide_children) {
                        acc.push(cur);
                        return acc;
                    }

                    if (cur.children) {
                        const hidden = getHiddenNodes(cur.children);

                        if (hidden.length > 0) {
                            acc.push(...hidden);
                        }
                    }
                    return acc;
                }, []);
            };

            if (!this.tree.children) return [];

            return getHiddenNodes(this.tree.children);
        },
        start_date() {
            return adjustedStartDate([]);
        },
        end_date() {
            return adjustedEndDate([]);
        },
    },
    mounted() {
        setTimeout(() => {
            this.create_milestones = true;
        }, 100);
    },
    methods: {
        handleNodeClick(node) {
            if (node.meta) {
                switch (node.meta.type) {
                    case 'stage':
                        this.handleStageClick(node);
                        break;

                    case 'component':
                        this.handleComponentClick(node);
                        break;

                    case 'project':
                        this.handleProjectClick(node);
                        break;

                    default:
                        break;
                }
            }
        },
        handleProjectClick(node) {
            const project = this.$store.getters.projectWithId(node.id);

            console.log('Project click', project);

            if (project) {
                this.$router.push({
                    name: 'project_detail',
                    params: {
                        project_id: project.id,
                    },
                });
            }
        },
        handleStageClick(node) {
            const stage = this.$store.getters.stageWithId(node.id);

            if (stage) {
                this.$router.push({
                    name: 'stage_detail',
                    params: {
                        stage_id: node.id,
                        project_id: this.$options.filters.fireRef2id(
                            stage.project
                        ),
                    },
                });
            }
        },
        handleComponentClick(node) {
            const component = this.$store.getters.componentWithId(node.id);

            if (component) {
                this.$router.push({
                    name: 'component_detail',
                    params: {
                        component_id: component.id,
                        stage_id: this.$options.filters.fireRef2id(
                            component.stage
                        ),
                        project_id: this.$options.filters.fireRef2id(
                            component.project
                        ),
                    },
                });
            }
        },
        handleUpdateNode(e) {
            const eventHandlers = {
                'update-component': (_, _e) => {
                    return {
                        firestore: [
                            {
                                type: 'updateComponentWithId',
                                payload: {
                                    component_id: _e.payload.id,
                                    key: 'start_date',
                                    value: _e.payload.startDate,
                                },
                            },
                            {
                                type: 'updateComponentWithId',
                                payload: {
                                    component_id: _e.payload.id,
                                    key: 'end_date',
                                    value: _e.payload.endDate,
                                },
                            },
                        ],
                    };
                },
                'update-stage': (_, _e) => {
                    return {
                        firestore: [
                            {
                                type: 'updateStageWithId',
                                payload: {
                                    stage_id: _e.payload.id,
                                    key: 'start_date',
                                    value: _e.payload.startDate,
                                },
                            },
                            {
                                type: 'updateStageWithId',
                                payload: {
                                    stage_id: _e.payload.id,
                                    key: 'end_date',
                                    value: _e.payload.endDate,
                                },
                            },
                        ],
                    };
                },
                'update-event': (_, _e) => {
                    return {
                        firestore: [
                            {
                                type: 'updateEventWithId',
                                payload: {
                                    event_id: _e.payload.id,
                                    start: _e.payload.date,
                                    content: _e.payload.content,
                                    external: _e.payload.external,
                                },
                            },
                        ],
                    };
                },
                'add-event': (_, _e) => {
                    const milestoneType = this.$store.getters.eventTypeForType(
                        'milestone'
                    );

                    return {
                        firestore: [
                            {
                                type: 'addEvent',
                                payload: {
                                    type: this.$fire.doc(
                                        `system/events/types/${milestoneType.id}`
                                    ),
                                    project: this.$fire.doc(
                                        `projects/${_e.payload.project_id}`
                                    ),
                                    content: _e.payload.content,
                                    start: _e.payload.date,
                                    external: _e.payload.external ?? false,
                                },
                            },
                        ],
                    };
                },
                'delete-event': (_, _e) => {
                    return {
                        firestore: [
                            {
                                type: 'deleteEventWithId',
                                payload: {
                                    event_id: _e.payload.id,
                                },
                            },
                        ],
                    };
                },
            };

            // !! impure !!
            const effectHandlers = {
                firestore: (actions) => {
                    console.log(
                        'running firestore effect handler with actions',
                        actions
                    );

                    actions.forEach((action) => {
                        this.$store.dispatch(action.type, action.payload);
                    });
                },
            };

            // !! impure !!
            const handleEvent = (_e) => {
                const eventHandler = eventHandlers[_e.type];
                if (!eventHandler) {
                    // noop
                    console.log('no event of type', _e.type);
                    return;
                }

                const effectsMap = eventHandler({}, _e);

                if (!effectsMap || typeof effectsMap !== 'object') {
                    // noop
                    console.log(
                        'invalid effects map returned from event handler',
                        effectsMap
                    );
                    return;
                }

                // do side effects!
                Object.entries(effectsMap).forEach(
                    ([k, v]) => effectHandlers[k] && effectHandlers[k](v)
                );
            };

            // !! impure !!
            handleEvent(e);
        },
        mapComponent(c) {
            const getEndDate = () => {
                if (
                    c.status === 'completed' &&
                    !c.end_date &&
                    c.completed_date
                ) {
                    return dayjs.unix(c.completed_date.seconds);
                }

                if (!c.end_date) return dayjs();

                return dayjs.unix(c.end_date.seconds);
            };

            const endDate = getEndDate();

            return BlockNode({
                meta: {
                    type: 'component',
                    status: c.status,
                    editableEndDate: c.end_date
                        ? dayjs.unix(c.end_date.seconds)
                        : null,
                },
                id: c.id,
                label: c.name,
                startDate: c.start_date
                    ? dayjs.unix(c.start_date.seconds)
                    : dayjs(),
                endDate,
            });
        },
        getEndDateForStage(s) {
            if (s.status === 'completed') {
                if (!s.end_date) {
                    if (!s.completed_date) {
                        return dayjs();
                    }

                    return dayjs.unix(s.completed_date.seconds);
                }

                if (!s.completed_date || s.end_date < s.completed_date) {
                    return dayjs.unix(s.end_date.seconds);
                }

                return dayjs.unix(s.completed_date.seconds);
            }

            if (s.end_date) {
                return dayjs.unix(s.end_date.seconds);
            }

            return dayjs();
        },
        mapStage(s) {
            let components = [];

            if (s.components) {
                components = s?.components?.map((c) => {
                    return this.$store.getters.componentWithId(c);
                });
            }

            const has_completed = components.some(
                (c) =>
                    c.status === COMPONENT_STATUS.COMPLETED &&
                    s.status !== STAGE_STATUS.COMPLETED
            );

            const children = components
                .filter(
                    (c) =>
                        c.status === COMPONENT_STATUS.ACTIVE ||
                        s.status === STAGE_STATUS.COMPLETED
                )
                .map(this.mapComponent);

            if (has_completed) {
                children.push(
                    BlockNode({
                        meta: {
                            type: 'completed',
                            status: 'completed',
                            hide_children: true,
                        },
                        id: `${s.id}_completed`,
                        label: 'Completed',
                        startDate: dayjs(),
                        endDate: dayjs(),
                        children: components
                            .filter(
                                (c) => c.status === COMPONENT_STATUS.COMPLETED
                            )
                            .map(this.mapComponent),
                    })
                );
            }

            const endDate = this.getEndDateForStage(s).endOf('day');

            return BlockNode({
                meta: {
                    type: 'stage',
                    status: s.status,
                    editableEndDate: s.end_date
                        ? dayjs.unix(s.end_date.seconds)
                        : null,
                },
                id: s.id,
                label:
                    s.notes && s.notes !== '' ? s.notes : `Stage #${s.number}`,
                startDate: s.start_date?.seconds
                    ? dayjs.unix(s.start_date.seconds)
                    : dayjs(),
                endDate,
                children: children,
            });
        },
        eventToMilestone(e) {
            return {
                id: e.id,
                meta: {
                    type: 'manual',
                    external: e.external,
                },
                date: dayjs.unix(e.start.seconds),
                content: e.content,
            };
        },
        blockToMilestones(b) {
            return [
                {
                    id: b.id,
                    meta: {
                        type: 'auto-start',
                    },
                    date: dayjs.unix(b.start_date.seconds),
                    content: `${b.label} Open`,
                },
                {
                    id: b.id,
                    meta: {
                        type: 'auto-end',
                    },
                    date: dayjs.unix(b.end_date.seconds),
                    content: `${b.label} Close`,
                },
            ];
        },
        componentToMilestones(c) {
            return this.blockToMilestones({...c, label: c.name});
        },
        stageToMilestones(s) {
            return this.blockToMilestones({...s, label: s.notes});
        },
        safeMap(fallback) {
            return (coll, fn) => (coll ? coll.map(fn) : fallback);
        },
        safeArrayMap(coll, fn) {
            return this.safeMap([])(coll, fn);
        },
        flatten(coll) {
            return coll.reduce((acc, cur) => [...acc, ...cur], []);
        },
        makeMilestones({events, stages, components}) {
            return [
                ...this.safeArrayMap(events, this.eventToMilestone),
                ...this.flatten(
                    this.safeArrayMap(
                        stages.filter(
                            (s) =>
                                s.start_date &&
                                s.end_date &&
                                s.status === 'active'
                        ),
                        this.stageToMilestones
                    )
                ),
                ...this.flatten(
                    this.safeArrayMap(
                        components.filter(
                            (c) =>
                                c.start_date &&
                                c.end_date &&
                                c.status === 'active'
                        ),
                        this.componentToMilestones
                    )
                ),
            ];
        },
        mapProject(p) {
            const currentStatus = p.currentStatus
                ? {
                      ...p.currentStatus,
                      statusType: this.$store.getters.eventTypeWithId(
                          p.currentStatus.type
                      ),
                  }
                : null;

            if (this.has_mounted) {
                const events = this.create_milestones
                    ? this.$store.getters.eventsForProjectWithId(p.id)
                    : [];

                const stages =
                    this.$store.getters.stagesForProjectWithId(p.id) || [];

                const components = this.create_milestones
                    ? this.flatten(
                          stages.map((s) =>
                              this.$store.getters.componentsForStageWithId(s.id)
                          )
                      )
                    : [];

                const children = stages
                    .filter((s) => s.status === STAGE_STATUS.ACTIVE)
                    .map(this.mapStage);

                const has_completed = stages.some(
                    (s) => s.status === 'completed'
                );

                if (has_completed) {
                    children.push(
                        BlockNode({
                            meta: {
                                type: 'completed',
                                status: 'completed',
                                hide_children: true,
                            },
                            id: `${p.id}_completed`,
                            label: 'Completed',
                            startDate: dayjs(),
                            endDate: dayjs(),
                            children: stages
                                .filter((s) => s.status === 'completed')
                                .map(this.mapStage),
                            hide_children: true,
                        })
                    );
                }

                return ProjectLine({
                    meta: {
                        type: 'project',
                        status: null,
                    },
                    id: p.id,
                    label: p.ref,
                    currentStatus,
                    milestones: this.create_milestones
                        ? this.makeMilestones({
                              events,
                              stages,
                              components,
                          })
                        : [],
                    children,
                });
            }
            return ProjectLine({
                meta: {
                    type: 'project',
                    status: null,
                },
                id: p.id,
                label: p.ref,
                currentStatus,
                milestones: [],
                children: [
                    BlockNode({
                        meta: {
                            type: 'loader',
                            status: 'loading',
                        },
                        id: `${p.id}-loader`,
                        label: 'Loading...',
                        startDate: dayjs(),
                        endDate: dayjs(),
                    }),
                ],
            });
        },
        makeTree(projects) {
            return RootNode({
                children: projects.map(this.mapProject),
            });
        },
    },
};
</script>
