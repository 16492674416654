import Vue from 'vue';
import Vuex from 'vuex';
import dayjs from 'dayjs';
import {
    adjustedEndDate,
    adjustedStartDate,
    insert,
    remove,
} from '@/components/timeline-v2/utils';

Vue.use(Vuex);

const timelineStore = new Vuex.Store({
    state: {
        tree: {minDate: dayjs(), maxDate: dayjs(), children: []},
        hiddenNodes: [],
        filterShyNodes: false,
        shyNodes: [],
        CACHE_daysOmitted: new Map(),
    },

    getters: {
        days: (state) => {
            let res = [];
            let cursor = dayjs(adjustedStartDate(state.tree));

            while (cursor.isBefore(adjustedEndDate(state.tree))) {
                res.push(cursor);
                cursor = cursor.add(1, 'days');
            }

            return res;
        },

        isNodeHidden: (state) => (node) => {
            return state.hiddenNodes.some((n) => n.id === node.id);
        },

        showChildren: (state, getters) => (node) => !getters.isNodeHidden(node),

        isNodeShy: (state) => (node) => {
            return state.shyNodes.some((n) => n.id === node.id);
        },
    },

    mutations: {
        set: (state, payload) => {
            Object.assign(state, {...state, ...payload});
        },
    },

    actions: {
        toggleShowHideNode: ({state, getters, commit}, payload) => {
            const node = payload.node;

            if (!getters.isNodeHidden(node)) {
                commit('set', {
                    hiddenNodes: insert(state.hiddenNodes, node),
                });
                return;
            }

            commit('set', {
                hiddenNodes: remove(state.hiddenNodes, node),
            });
        },

        toggleFilterShyNodes: ({state, commit}) => {
            commit('set', {
                filterShyNodes: !state.filterShyNodes,
            });
        },

        toggleShyNode: ({state, getters, commit}, payload) => {
            const node = payload.node;

            if (!getters.isNodeShy(node)) {
                commit('set', {
                    shyNodes: insert(state.shyNodes, node),
                });
                return;
            }

            commit('set', {
                shyNodes: remove(state.shyNodes, node),
            });
        },
    },
});

export default timelineStore;
