<template>
    <div class="steps">
        <steps-item
            v-for="(step, idx) in steps"
            :key="step.key"
            :title="step.title"
            :type="step.type"
            :status="statusForStepAtIndex(idx)"
            :substeps="step.substeps"
        />
        <steps-item v-if="is_archived" title="Archived" status="completed" />
        <svg
            class="indicator"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="28px"
            :height="`${line_height}px`"
            :viewBox="`0 0 28 ${line_height}`"
        >
            <g>
                <path class="line" :d="`M 16 25 v ${line_height}`" />
                <path
                    class="completion_line"
                    :class="{
                        grey: is_archived,
                    }"
                    :d="`M 16 25 v ${completed_height}`"
                    data-pipe="true"
                />
            </g>
        </svg>
    </div>
</template>

<script>
import StepsItem from '@/components/generic/Steps__item';

import {CR_STATUS} from '@/enums';

export default {
    name: 'steps',
    components: {
        StepsItem,
    },
    props: {
        steps: {
            type: Array,
            required: true,
        },
        current_step: {
            type: Number,
            required: true,
        },
    },
    computed: {
        is_archived() {
            return this.current_step === CR_STATUS.ARCHIVED;
        },
        line_height() {
            return (
                this.steps.reduce((height, step) => {
                    return (
                        height +
                        50 +
                        (step.substeps ? step.substeps.length * 25 : 0)
                    );
                }, 0) - 25
            );
            //return 50 * (this.steps.length - 1) + 25;
        },
        completed_height() {
            const line = this.steps
                .slice(0, this.current_step)
                .reduce((height, step) => {
                    return (
                        height +
                        50 +
                        (step.substeps ? step.substeps.length * 25 : 0)
                    );
                }, 0);
            return line;
            //return 50 * (this.steps.length - 1) + 25;
        },
    },
    methods: {
        statusForStepAtIndex(idx) {
            if (this.is_archived) return 'archived';
            if (this.current_step === this.steps.length - 1) return 'completed';
            if (idx === this.current_step) return 'inprogress';
            if (idx < this.current_step) return 'completed';
            return 'open';
        },
    },
};
</script>

<style lang="scss" scoped>
.steps {
    box-shadow: rgba(9, 30, 66, 0.31) 0px 0px 1px;
    box-sizing: border-box;
    position: relative;
    margin-top: -4px;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    background: rgb(255, 255, 255);

    color: #172b4d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    .indicator {
        position: absolute;
        top: 0px;
        left: 11px;
        pointer-events: none;
        z-index: 1;

        .line {
            fill: none;
            stroke: rgb(223, 225, 230);
            stroke-width: 2;
            stroke-miterlimit: 10;
        }

        .completion_line {
            fill: none;
            stroke: rgb(54, 179, 126);
            stroke-width: 2;

            &.grey {
                stroke: $border-grey-light;
            }
        }
    }
}
</style>
