<template>
    <div class="build">
        <span v-if="pull_request" class="pull-request">{{ pull_request }}</span>
        <span v-if="build_number">Build #{{ build_number }}</span>
        <span class="status">{{ build_status }}</span>
    </div>
</template>

<script>
export default {
    props: {
        pull_request: {
            type: String,
            default: '',
        },
        build_number: {
            type: Number,
            default: null,
        },
        build_status: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
.build {
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    .pull-request {
        flex: 1;
    }

    .status {
        text-align: right;
        flex: 1;
    }
}
</style>
