<template>
    <div class="invoices-container">
        <el-row :gutter="12">
            <el-col :lg="8" :sm="24">
                <widget-card style="margin-bottom: 10px;" :loading="false">
                    <div
                        v-if="invoices.length"
                        class="key-value"
                        :class="{selected: filter === null}"
                        @click="filter = null"
                    >
                        <label>Total invoiced <span>(ex. GST)</span></label>
                        <span>{{ total_invoiced | currency }}</span>
                    </div>

                    <el-alert
                        v-else
                        description="No invoices available."
                        :closable="false"
                    />
                </widget-card>
            </el-col>
            <el-col :lg="8" :sm="24">
                <widget-card style="margin-bottom: 10px;" :loading="false">
                    <div
                        v-if="invoices.length"
                        class="key-value"
                        :class="{selected: filter === 'paid'}"
                        @click="filter = 'paid'"
                    >
                        <label>Total paid <span>(ex. GST)</span></label>
                        <span>{{ total_paid_ex_gst | currency }}</span>
                    </div>

                    <el-alert
                        v-else
                        description="No invoices available."
                        :closable="false"
                    />
                </widget-card>
            </el-col>
            <el-col :lg="8" :sm="24">
                <widget-card style="margin-bottom: 10px;" :loading="false">
                    <div
                        v-if="invoices.length"
                        class="key-value"
                        :class="{selected: filter === 'due'}"
                        @click="filter = 'due'"
                    >
                        <label>Total due <span>(inc. GST)</span></label>
                        <span>{{ total_due | currency }}</span>
                    </div>

                    <el-alert
                        v-else
                        description="No invoices available."
                        :closable="false"
                    />
                </widget-card>
            </el-col>
        </el-row>

        <ul v-if="sorted_invoices.length" class="invoices-list">
            <invoice-block
                v-for="invoice in sorted_invoices"
                :key="invoice.id"
                :invoice="invoice"
            />
        </ul>
        <el-alert
            v-else
            description="No invoices for this filter."
            :closable="false"
        />
    </div>
</template>

<script>
import InvoiceBlock from '@/components/blocks/InvoiceBlock';
import WidgetCard from '@/components/generic/WidgetCard';

import invoiceMixin from '@/mixins/invoice.mixin';

export default {
    name: 'client-invoices',
    components: {
        InvoiceBlock,
        WidgetCard,
    },
    mixins: [invoiceMixin],
    props: {
        client: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            filter: null,
        };
    },
    computed: {
        invoices() {
            return this.$store.getters.invoicesForClientWithId(this.client.id);
        },
        total_invoiced() {
            if (!this.invoices) return 0;
            return this.invoices.reduce(
                (sum, invoice) => sum + parseFloat(invoice.sub_total || 0),
                0
            );
        },
        total_paid_ex_gst() {
            if (!this.invoices) return 0;
            return this.invoices.reduce((sum, invoice) => {
                // Calculate the proportion of amount paid that applies to the sub_total
                const sub_total_paid =
                    invoice.amount_paid * (invoice.sub_total / invoice.total);
                return sum + parseFloat(sub_total_paid || 0);
            }, 0);
        },
        total_due() {
            if (!this.invoices) return 0;
            return this.invoices.reduce(
                (sum, invoice) => sum + parseFloat(invoice.amount_due || 0),
                0
            );
        },
        sorted_invoices() {
            if (!this.invoices) return [];
            return this.invoices
                .slice(0)
                .filter((i) => {
                    switch (this.filter) {
                        case 'due':
                            return i.fully_paid_on_date === null;
                        case 'paid':
                            return !!i.fully_paid_on_date;
                        default:
                            return true;
                    }
                })
                .sort((a, b) => {
                    if (a.invoice_number < b.invoice_number) return 1;
                    if (a.invoice_number > b.invoice_number) return -1;
                    return 0;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.invoices-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;

    ::v-deep .el-card .el-card__body label span {
        font-size: 8px;
        opacity: 0.8;
    }

    ::v-deep .key-value {
        cursor: pointer;
    }

    .invoices-list {
        padding: 0;
        list-style: none;
    }
}
</style>
