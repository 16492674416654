<template>
    <div class="content">
        <template v-if="slots">
            <slot-block v-for="slot in slots" :key="slot.id" :object="slot" />
        </template>

        <el-button
            v-if="editing && time_allocated < 21600"
            size="mini"
            @click="show_selector = true"
        >
            +
        </el-button>

        <el-dialog
            :visible.sync="show_selector"
            width="60%"
            :show-close="false"
            @closed="onCloseDialog"
        >
            <div class="modal-content">
                <transition name="fade" mode="out-in">
                    <project-selector
                        v-if="selection_step == 0"
                        @onSelection="
                            (value) => {
                                selected_project = value;
                                selection_step++;
                            }
                        "
                    />
                    <stage-selector
                        v-else-if="selection_step == 1"
                        :selected_project="selected_project"
                        @onBack="selection_step--"
                        @onSelection="
                            (value) => {
                                // The selected object it's a component, not a stage
                                if (value.stage) {
                                    selected_stage = null;
                                    selected_component = value;
                                    selection_step += 2;
                                } else {
                                    selected_stage = value;
                                    selection_step++;
                                }
                            }
                        "
                    />
                    <component-selector
                        v-else-if="selection_step == 2"
                        :selected_project="selected_project"
                        :selected_stage="selected_stage"
                        @onBack="selection_step--"
                        @onSelection="
                            (value) => {
                                selected_component = value;
                                selection_step++;
                            }
                        "
                    />
                    <div v-else-if="selection_step == 3 && selected_component">
                        <h2>Time estimate:</h2>
                        <hr />
                        <div
                            class="back"
                            @click="
                                () => {
                                    if (selected_stage) selection_step--;
                                    else selection_step -= 2;
                                }
                            "
                        >
                            <svgicon name="triangle" class="triangle"></svgicon>
                            <label>BACK <span>/</span></label>
                            <span>
                                {{ selected_project.name }}
                                {{
                                    selected_stage
                                        ? `/ Stage #
                                    ${selected_stage.number}`
                                        : null
                                }}
                                / {{ selected_component.name }}
                            </span>
                        </div>
                        <hr />
                        <div class="slider-container">
                            <span>
                                Slots:
                                <strong>{{ slots.length }}</strong>
                            </span>
                            <span>
                                Total time allocated:
                                <strong>
                                    {{
                                        (time_allocated + time_estimate)
                                            | seconds2duration
                                    }}
                                </strong>
                                +{{ time_estimate | seconds2duration }}
                            </span>
                            <hr />
                            <el-slider
                                v-model="time_estimate"
                                :step="900"
                                :marks="marks"
                                :min="0"
                                :max="daily_hours - time_allocated"
                                :show-tooltip="false"
                            />
                            <text-editor
                                ref="editor"
                                :content.sync="slot_notes"
                                placeholder="Add notes here…"
                            />
                        </div>
                        <el-button class="create" @click="createSlot">
                            Create
                        </el-button>
                    </div>
                </transition>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SlotBlock from '@/components/planner/SlotBlock';

import ProjectSelector from '@/components/generic/ProjectSelector';
import StageSelector from '@/components/generic/StageSelector';
import ComponentSelector from '@/components/generic/ComponentSelector';
import TextEditor from '@/components/editor/TextEditor';

export default {
    name: 'day-block',
    components: {
        TextEditor,
        SlotBlock,

        ProjectSelector,
        StageSelector,
        ComponentSelector,
    },
    props: {
        slots: {
            type: Array,
            default: null,
        },
        day: {
            type: Object,
            default: null,
        },
        user: {
            type: Object,
            default: null,
        },
        editing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selection_step: 0,
            selected_project: null,
            selected_stage: null,
            selected_component: null,
            slot_notes: null,
            time_estimate: 0,

            daily_hours: +process.env.VUE_APP_DAILY_HOURS * 60 * 60,
            min_tracking_hours: +process.env.VUE_APP_DAILY_MIN_TRACKING_HOURS,
            show_selector: false,
            marks: {
                3600: '1h',
                7200: '2h',
                10800: '3h',
                14400: '4h',
                18000: '5h',
            },
        };
    },
    computed: {
        time_allocated() {
            if (this.slots.length === 0) return 0;
            return (
                this.$options.filters.sum(
                    this.slots.map((slot) => {
                        return slot.time_estimate;
                    })
                ) +
                this.slots.length * 900
            );
        },
    },
    methods: {
        createSlot() {
            this.show_selector = false;

            this.$fire
                .collection('slots')
                .add({
                    component: this.$fire.doc(
                        `components/${this.selected_component.id}`
                    ),
                    user: this.$fire.doc(`users/${this.user.id}`),
                    time_estimate: this.time_estimate,
                    day: this.day.toDate(),
                    notes: this.slot_notes
                        ? this.$refs.editor.getContent()
                        : null,
                })
                .then(() => {
                    this.selection_step = 0;
                    this.selected_project = null;
                    this.selected_stage = null;
                    this.selected_component = null;
                    this.time_estimate = 0;
                });
        },
        onCloseDialog() {
            this.$emit('update:show_dialog', false);

            this.selection_step = 0;
            this.selected_project = null;
            this.selected_stage = null;
            this.selected_component = null;
            this.slot_notes = null;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.content {
    width: 100%;

    .el-button {
        margin-top: 5px;
        width: 100%;
        border-color: $border-grey-light;
    }

    ::v-deep .el-dialog {
        .el-dialog__header {
            padding: 0;
        }
        .el-dialog__body {
            padding: 0 20px;

            .modal-content {
                height: calc(100vh - 33vh);
                overflow: hidden;
                display: flex;
            }
        }
    }

    .fade {
        &-enter-active,
        &-leave-active {
            transition: all 0.2s ease-in-out;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
            transform: translateX(2px);
        }
    }

    .back {
        cursor: pointer;
        margin: 10px 2px;
        padding: 18px 20px;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0 0 0 1px $border-grey-light;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        &:hover {
            box-shadow: 0 0 0 2px $blue;
        }

        .triangle {
            color: $black;
            width: 10px;
            height: 10px;
            margin: 4px 10px 3px 0;
            transform: scaleX(-1) translateX(0);
            filter: FlipH;
            -ms-filter: 'FlipH';
            transition: transform 0.3s ease-in-out 0.1s;
        }

        label {
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            text-align: right;
            color: $black;
            text-transform: uppercase;
            margin-right: 4px;
            margin-top: 1px;
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;

            span {
                margin: 0 3px;
                font-size: 13px;
                margin-top: 1px;
            }
        }

        span {
            transition: transform 0.3s ease-in-out;
        }

        &:hover {
            .triangle {
                transform: scaleX(-1) translateX(3px);
            }

            label,
            span {
                transform: translateX(3px);
            }
        }
    }

    h2 + hr {
        margin-bottom: 0;
    }

    hr {
        width: 100%;
        margin: 0;
        border: none;
        height: 1px;
        background-color: $border-grey-light;
    }

    .slider-container {
        cursor: pointer;
        margin: 10px 2px;
        padding: 18px 20px;
        border-radius: 5px;
        margin-top: 10px;
        background-color: white;
        box-shadow: 0 0 0 1px $border-grey-light;
        position: relative;

        hr {
            margin: 10px 0;
        }

        span {
            display: block;
            padding: 5px 0;
        }

        .text-editor-wrapper {
            margin-top: 30px;
        }
    }

    .el-button.create {
        margin-bottom: 20px;
    }
}
</style>
