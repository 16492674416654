<template>
    <div v-if="component" class="component-wrapper">
        <div
            class="component-container"
            :class="component.status + '-background'"
            @click="handleClick"
        >
            <div class="header">
                <time-indicator
                    v-if="is_component_active"
                    :component="component"
                />
                <div class="title">
                    <span class="ref" v-if="component.ref">
                        {{ component.ref }}
                    </span>
                    <span class="name">
                        {{ component.name }}
                        <component-ref-links :component="component" />
                    </span>
                </div>
                <el-popover
                    v-model="settingsVisible"
                    placement="bottom"
                    trigger="click"
                    popper-class="options-popover"
                >
                    <a
                        slot="reference"
                        class="options-trigger visible-on-hover"
                        @click.stop=""
                    >
                        <svgicon name="settings" />
                    </a>
                    <ul>
                        <li
                            @click="
                                hidePopoverAndUpdateComponent(
                                    'highlight',
                                    component.highlight ? null : true
                                )
                            "
                        >
                            {{
                                component.highlight
                                    ? 'Highlighted'
                                    : 'Highlight'
                            }}
                        </li>
                        <li class="header">
                            Change status:
                        </li>
                        <li
                            v-if="!is_component_active"
                            @click="
                                hidePopoverAndUpdateComponent(
                                    'status',
                                    COMPONENT_STATUS.ACTIVE
                                )
                            "
                        >
                            Active
                        </li>
                        <li
                            v-if="!is_component_standby"
                            @click="
                                hidePopoverAndUpdateComponent(
                                    'status',
                                    COMPONENT_STATUS.STANDBY
                                )
                            "
                        >
                            Standby
                        </li>
                        <li
                            v-if="!is_component_completed"
                            @click="
                                hidePopoverAndUpdateComponent(
                                    'status',
                                    COMPONENT_STATUS.COMPLETED
                                )
                            "
                        >
                            Completed
                        </li>
                    </ul>
                </el-popover>
            </div>

            <div v-if="is_component_active" class="info-container">
                <time-spent class="span-raw" :component="component" />
                <el-progress
                    :percentage="time_left_progress"
                    :show-text="false"
                    :color="time_left_progress < 100 ? '#1989FA' : '#F56C6C'"
                />
            </div>

            <div v-if="!is_component_active" class="info-container">
                <time-spent class="span-raw" :component="component" />
                <span class="tag">
                    {{ component.status }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import TimeIndicator from '@/components/generic/TimeIndicator';
import ComponentRefLinks from '@/components/generic/ComponentRefLinks';
import TimeSpent from '@/pages/sessions/components/TimeSpent';

import componentMixin from '@/mixins/component.mixin';
import componentSessionMixin from '@/mixins/component.session.mixin';
import componentInvoiceMixin from '@/mixins/component.invoice.mixin';
import componentRecurringMixin from '@/mixins/component.recurring.mixin';
import userMixin from '@/mixins/user.mixin';

import {COMPONENT_STATUS} from '@/enums';

export default {
    name: 'component-block',
    components: {
        TimeSpent,
        TimeIndicator,
        ComponentRefLinks,
    },
    mixins: [
        userMixin,
        componentSessionMixin,
        componentInvoiceMixin,
        componentMixin,
        componentRecurringMixin,
    ],
    props: {
        component: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            COMPONENT_STATUS: COMPONENT_STATUS,
            toggleInvoicedHours: false,
            settingsVisible: false,
        };
    },
    computed: {
        stage() {
            return this.$store.getters.stageWithId(this.component.stage);
        },
        active_users() {
            return this.$options.filters.getUsersWithActiveSession(
                this.sessions
            );
        },
        time_left_progress() {
            let percentage = this.has_reported_hours_rounding
                ? this.time_reportable_percentage
                : this.time_spent_percentage;

            if (this.component.recurring) {
                percentage = this.recurring_time_reportable_percentage;
            }

            return Math.min(percentage, 100);
        },
    },
    methods: {
        hidePopoverAndUpdateComponent(key, val) {
            // Hide the settings popover on action click
            this.settingsVisible = false;

            this.updateComponent(key, val);
        },
        handleClick() {
            this.$router.push({
                name: 'component_detail',
                params: {
                    project_id: this.$options.filters.fireRef2id(
                        this.component.project
                    ),
                    stage_id: this.$options.filters.fireRef2id(
                        this.component.stage
                    ),
                    component_id: this.component.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.component-wrapper {
    display: grid;
    flex-basis: 30%;
    flex-grow: 1;
    max-width: calc((100% - 20px) / 3);

    @media screen and (max-width: 992px) {
        flex-basis: 100%;
        max-width: none;
    }

    .component-container {
        padding: 20px;
        border-radius: 5px;
        border: 1px solid $border-grey;
        height: 130px;
        user-select: none;
        cursor: pointer;
        background-color: white;
        box-sizing: border-box;
        position: relative;

        ::v-deep .el-progress-bar__outer {
            box-shadow: 0 0 0 4px white;
        }

        &.standby-background,
        &.completed-background {
            opacity: 0.7;
        }

        .header {
            display: flex;
            flex-direction: row;

            .time-indicator {
                padding-left: 0;
                margin-left: -12px;
                padding-right: 5px;
            }

            .visible-on-hover {
                display: none;
            }

            a.options-trigger {
                height: 20px;
                background-color: white;
            }
        }

        .tag {
            position: absolute;
            right: 0;
            bottom: 0;
            margin: 10px 10px;
            font-size: 10px !important;
            text-transform: capitalize;
            background-color: white;
            padding: 5px 10px;
            border-radius: 20px;
            font-weight: 600;
        }

        &:hover {
            box-shadow: 0 0 0 1px $blue;
            border: 1px solid $blue;

            .header {
                .visible-on-hover {
                    display: flex;
                }
            }

            .info-container {
                ::v-deep .span-raw span.hidden {
                    display: flex;
                }
            }
        }

        .title {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: 30px;
            overflow: hidden;

            span.ref {
                font-size: 9px;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 3px;
            }

            span.name {
                font-size: 15px;
                display: inline-flex;
            }
        }

        .el-tag {
            font-size: 10px;
            float: right;
            width: 80px;
            text-align: center;
        }

        .info-container {
            min-height: 50px;

            .el-progress {
                margin-bottom: 8px;
            }

            ::v-deep .span-raw {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 20px;

                span {
                    font-size: 12px;

                    &.hidden {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
