<template>
    <div>
        <actions-buttons>
            <el-tabs v-model="activeTab" @tab-click="tabChanged">
                <el-tab-pane
                    v-if="store.getters.are_todo_screens_enabled"
                    :label="todosTitle"
                    name="_todo"
                />
                <el-tab-pane
                    v-if="store.getters.is_gcal_enabled"
                    label="Events"
                    name="_events"
                />
                <el-tab-pane label="Team" name="_team" />
            </el-tabs>
        </actions-buttons>

        <transition name="slide" mode="out-in">
            <HomeTab_todo v-if="activeTab == '_todo'" key="todo" />
            <HomeTab_events v-else-if="activeTab == '_events'" key="events" />
            <HomeTab_team v-else-if="activeTab == '_team'" key="team" />
        </transition>
    </div>
</template>

<script>
import ActionsButtons from '@/components/generic/ActionsButtons';

import HomeTab_todo from '@/pages/home/tabs/HomeTab_todo';
import HomeTab_events from '@/pages/home/tabs/HomeTab_events';
import HomeTab_team from '@/pages/home/tabs/HomeTab_team';
import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';
import store from '@/store';
import todoMixin from '@/mixins/todo.mixin';

export default {
    name: 'component-detail',
    components: {
        ActionsButtons,
        HomeTab_todo,
        HomeTab_events,
        HomeTab_team,
    },
    mixins: [todoMixin],
    data() {
        return {
            activeTab: '_todo',
            SUPPORTED_FEATURES: SUPPORTED_FEATURES,
        };
    },
    computed: {
        store() {
            return store;
        },
    },
    watch: {
        $route(to) {
            this.activeTab = to.meta.tab;
        },
    },
    created() {
        this.activeTab = this.$router.currentRoute.meta.tab;
    },
    methods: {
        tabChanged(event) {
            this.$router
                .replace({
                    name: `home${event.name}`,
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.slide {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateX(5px);
    }
}

.buttons-container {
    display: block !important;
}
</style>
