<template>
    <div class="estimates-container">
        <el-row :gutter="12">
            <el-col :lg="8" :sm="24">
                <widget-card style="margin-bottom: 10px;" :loading="false">
                    <div v-if="estimates.length" class="key-value">
                        <label>Total in draft <span>(ex. GST)</span></label>
                        <span>{{ total_draft | currency }}</span>
                    </div>

                    <el-alert
                        v-else
                        description="No estimates available."
                        :closable="false"
                    />
                </widget-card>
            </el-col>
            <el-col :lg="8" :sm="24">
                <widget-card style="margin-bottom: 10px;" :loading="false">
                    <div v-if="estimates.length" class="key-value">
                        <label>Total in review <span>(ex. GST)</span></label>
                        <span>{{ total_review | currency }}</span>
                    </div>

                    <el-alert
                        v-else
                        description="No estimates available."
                        :closable="false"
                    />
                </widget-card>
            </el-col>
            <el-col :lg="8" :sm="24">
                <widget-card style="margin-bottom: 10px;" :loading="false">
                    <div v-if="estimates.length" class="key-value">
                        <label>Total accepted <span>(ex. GST)</span></label>
                        <span>{{ total_accepted | currency }}</span>
                    </div>

                    <el-alert
                        v-else
                        description="No estimates available."
                        :closable="false"
                    />
                </widget-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import WidgetCard from '@/components/generic/WidgetCard';

import PaymentPlanMixin from '@/mixins/paymentplan.mixin';
import {ESTIMATE_STATUS} from '@/enums';

export default {
    name: 'project-estimates',
    components: {
        WidgetCard,
    },
    mixins: [PaymentPlanMixin],
    props: {
        project: {
            type: Object,
            default: null,
        },
    },
    computed: {
        estimates() {
            return this.$store.getters.estimatesForProjectWithId(
                this.project.id
            );
        },
        total_draft() {
            return this.estimates
                .filter((e) => e.status === ESTIMATE_STATUS.DRAFT)
                .reduce((sum, e) => sum + e.amount, 0);
        },
        total_review() {
            return this.estimates
                .filter((e) => e.status === ESTIMATE_STATUS.PENDING)
                .reduce((sum, e) => sum + e.amount, 0);
        },
        total_accepted() {
            return this.estimates
                .filter((e) => e.status === ESTIMATE_STATUS.ACCEPTED)
                .reduce((sum, e) => sum + e.final_cost, 0);
        },
    },
};
</script>

<style lang="scss" scoped>
.estimates-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;

    ::v-deep .el-card .el-card__body label span {
        font-size: 8px;
        opacity: 0.8;
    }
}
</style>
