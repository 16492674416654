<template>
    <div class="buttons-container">
        <transition appear name="slide-buttons">
            <div>
                <slot />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'actions-buttons',
};
</script>

<style lang="scss" scoped>
.buttons-container {
    top: 61px;
    right: calc((100% - 1300px) / 2);
    position: fixed;
    height: 78px;
    padding: 0;
    padding-right: 40px;
    z-index: 3100;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 40px;
    }

    ::v-deep .el-tabs__header {
        user-select: none;
        margin: 0;

        .el-tabs__nav-wrap {
            &::after {
                height: 0 !important;
            }
        }

        .el-tabs__active-bar {
            transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
                -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        .el-tabs__item {
            height: 79px;
            padding-top: 20px;
        }
    }

    @media screen and (max-width: 1300px) {
        right: 0;
    }

    @media screen and (max-width: 992px) {
        display: flex;
        position: relative;
        justify-content: flex-start;
        height: auto;
        top: 0;
        padding: 0;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $border-grey-light;

        & > div {
            width: 100%;
        }

        ::v-deep .el-tabs__header {
            .el-tabs__nav {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            .el-tabs__item {
                height: auto;
                padding: 2px 10px;
            }

            .el-tabs__active-bar {
                display: none;
            }
        }
    }

    .slide-buttons {
        &-enter-active {
            transition: opacity 0.3s ease-in-out 0.2s,
                transform 0.4s ease-in-out 0.2s;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
            transform: translateX(5px);
        }
    }
}
</style>
