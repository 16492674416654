<template>
    <div class="referenceTemplateHelp">
        <i class="close-icon el-icon-close" @click="closeHelp" />
        <p><b>REFERENCE TEMPLATE HELP</b></p>
        <p>Reference template can contain <i>tags</i> for autofilled values:</p>
        <p><b>{prj}</b>: project code</p>
        <p><b>{est}</b>: estimate code</p>
        <p><b>{seq}</b>: sequence of invoice in group, eg. 1/4</p>
        <hr />
        <p>
            Date values are based on the invoice date:<br />
            <b>{week}</b>: week number of year | <b>{mm}</b>: month number |
            <b>{mon}</b>: month abbreviation | <b>{month}</b>: month name |
            <b>{year}</b>
        </p>

        <p>
            Values can be adjusted with an offset, +/- the units being
            displayed:<br />
            <b>{month|+1}</b>: add 1 month to the invoice date, then display the
            month name
        </p>
        <p>
            Values can be adjusted by a different unit:
            <b>{year|+1 month}</b> - valid units are <b>d/day</b> |
            <b>w/week</b> | <b>m/mm/mon/month</b>
        </p>
        <p>
            Note that each tag is applied independently, so if more than one
            date value is used, they
            <i>all</i> need to be adjusted the same:<br />
            eg.
            <b>{week|+2 week}_{year|+2 week}</b>
        </p>

        <p>
            Tags are evaluated when the <i>invoice</i> is created, not when the
            payment plan is created.<br />
            Unscheduled invoices will display tags based on the current date.
        </p>

        <hr />

        <p>
            Example template:<br /><b>{prj}_MNT_W{week}_{year}_{seq}</b>
            - CFS_MNT_W31_2023_1/4
        </p>
    </div>
</template>

<script>
export default {
    name: 'paymentplan-reference-help',
    methods: {
        closeHelp() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.referenceTemplateHelp {
    position: relative;
    margin: 5px 0;
    background: $transparent-grey;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid $transparent-grey;
    font-size: 11px;
}
.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
}

hr {
    width: 100%;
    margin: 10px 0;
    border: none;
    height: 1px;
    background-color: $border-grey;
}
</style>
