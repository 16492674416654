/* eslint-disable */
require('./confluence')
require('./cross')
require('./cube-face')
require('./heart')
require('./invoice_authorised')
require('./invoice_error')
require('./invoice_linking')
require('./invoice_paid')
require('./invoice_pending')
require('./invoice_scheduled')
require('./invoice_sent')
require('./invoice_share')
require('./jira-action')
require('./jira-activity')
require('./jira-admin')
require('./jira-bug')
require('./jira-default')
require('./jira-design')
require('./jira-dev')
require('./jira-epic')
require('./jira-feature')
require('./jira-qa')
require('./jira-story')
require('./jira-task')
require('./jira')
require('./logo-text')
require('./logo')
require('./menu')
require('./pause')
require('./play')
require('./plus-circle')
require('./plus-square')
require('./search-empty')
require('./settings')
require('./skip')
require('./steps-alert-icon')
require('./steps-block-icon')
require('./steps-done-icon')
require('./steps-error-icon')
require('./steps-progress-icon')
require('./steps-success-icon')
require('./steps-todo-icon')
require('./triangle')
