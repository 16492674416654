<template>
    <div key="project" class="selection">
        <div class="row">
            <h2 class="title">Choose project:</h2>
            <el-input
                ref="search"
                v-model="search"
                placeholder="Filter by name or ref"
                size="mini"
                class="search-field"
            />
        </div>
        <hr />
        <ul class="projects">
            <li
                v-for="project in projects"
                :key="project.id"
                @click="$emit('onSelection', project)"
            >
                <label>
                    <span>{{ project.ref }}</span>
                    {{ project.name }}
                </label>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'project-selector',
    data() {
        return {
            search: '',
        };
    },
    computed: {
        projects() {
            return this.$store.getters.projects
                .filter((p) => {
                    return p.status != 'archived';
                })
                .filter((p) => {
                    if (!!this.search === false) return true;
                    return (
                        p.name
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        p.ref.toLowerCase().includes(this.search.toLowerCase())
                    );
                });
        },
    },
    mounted() {
        if (this.$refs.search) this.$refs.search.focus();
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.selection {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 5px;

    div.row {
        display: flex;
        flex-direction: row;
        align-items: center;

        h2.title {
            font-size: 15px;
            width: 150px;
        }

        ::v-deep .el-input {
            width: auto;
            margin-left: 10px;

            input {
                border-color: $border-grey-light;
            }
        }

        @media screen and (max-width: 992px) {
            flex-direction: column;
            align-items: flex-start;

            .el-input {
                margin-left: 0;
                margin-bottom: 10px;
            }
        }
    }

    ul {
        padding: 10px 2px 20px 2px;
        margin: 0;
        overflow: scroll;

        li {
            cursor: pointer;
            margin: 0 0 10px 0;
            padding: 20px 20px;
            border-radius: 5px;
            background-color: white;
            box-shadow: 0 0 0 1px $border-grey-light;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            position: relative;

            &.placeholder {
                pointer-events: none;

                span {
                    font-size: 12px;
                    opacity: 0.7;
                }
            }

            &:hover {
                box-shadow: 0 0 0 2px $blue;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    h2 + hr {
        margin-bottom: 0;
    }

    hr {
        width: 100%;
        margin: 0;
        border: none;
        height: 1px;
        background-color: $border-grey-light;
    }

    ul.projects {
        li {
            span.clear {
                font-size: 12px;
                opacity: 0.6;
            }
            label {
                font-size: 13px;
                display: block;

                span {
                    font-weight: bold;
                    margin-right: 10px;
                    width: 40px;
                    display: inline-block;
                    border-right: 1px solid $border-grey-light;
                }
            }
        }
    }

    .search-field {
        width: 100% !important;
    }
}
</style>
