<template>
    <div class="outer">
        <header>
            <el-radio-group v-model="templateType" size="mini">
                <el-radio-button
                    v-for="type in TEMPLATE_TYPES"
                    :key="type"
                    :label="type"
                >
                    {{ type | capitalize }}
                </el-radio-button>
            </el-radio-group>
        </header>

        <div class="inner">
            <component :is="component" />
        </div>
    </div>
</template>

<script>
import {TEMPLATE_TYPES} from '@/enums';

import DocumentsTemplates from './components/DocumentsTemplates';
import EstimatesTemplates from './components/EstimatesTemplates';

export default {
    name: 'management-templates',
    components: {DocumentsTemplates, EstimatesTemplates},
    data() {
        return {
            templateType: TEMPLATE_TYPES.ESTIMATE,
        };
    },
    computed: {
        component() {
            switch (this.templateType) {
                case TEMPLATE_TYPES.ESTIMATE:
                    return EstimatesTemplates;
                case TEMPLATE_TYPES.DOCUMENT:
                    return DocumentsTemplates;
                default:
                    return null;
            }
        },
        TEMPLATE_TYPES() {
            return TEMPLATE_TYPES;
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
        box-sizing: border-box;
        height: 65px;
        padding: 10px 20px;
        border-bottom: 1px solid $border-grey-light;
        display: flex;
        user-select: none;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .el-input {
            width: 200px;
        }
    }
}
</style>
