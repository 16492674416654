<template>
    <div
        :class="{
            'component-tracked-issue': true,
            epic: issue_category === 'Epic',
            'show-column-labels': !!show_column_labels,
        }"
    >
        <div class="icon">
            <svgicon
                v-if="issue_category"
                :name="ISSUE_ICONS[issue_category]"
                :class="ISSUE_ICONS[issue_category]"
                class="issue-icon"
            />
            <avatar v-else-if="user" :user="user" />
        </div>

        <section class="description-section">
            <session-input
                v-if="session"
                class="el-input session-input"
                placeholder="What did you work on?"
                :session="session"
                :simple_responsive_design="true"
            />
            <span v-else class="description">{{ title }}</span>
        </section>

        <section v-if="session" class="date-section">
            <span class="session-date">
                {{ session.start.toDate() | dateformat }}
            </span>
        </section>

        <section class="issue-section">
            <template v-if="linked_issue">
                <div class="column">
                    <div class="clickable" @click="navigateToIssue">
                        <issue-item-compact
                            :data="linked_issue"
                            :show_summary="false"
                        />
                    </div>
                    <button @click="$emit('unbind')" class="icon remove">
                        <svgicon name="cross" />
                    </button>
                </div>
                <div class="column">
                    <span>{{ display_start_date }}</span>
                </div>
                <div class="column">
                    <span class="separator">→</span>
                    <span>{{ display_end_date }}</span>
                </div>
            </template>
            <template v-else-if="can_bind_issue">
                <div class="issue-search-container">
                    <issue-search @select="(issue) => $emit('bind', issue)" />
                </div>
            </template>
        </section>
        <section class="time-section">
            <div title="Spent" class="column">
                <span :class="{empty: !time_spent}">
                    {{ display_time_spent }}
                </span>
            </div>
            <div title="Estimated" class="column">
                <span class="separator">/</span>
                <span :class="{empty: !time_estimate}">
                    {{ display_time_estimate }}
                </span>
            </div>
            <div title="Difference" class="column column-difference">
                <div
                    :class="{
                        frame: true,
                        'under-estimate': !consumed_over_estimate && time_spent,
                        'over-estimate': consumed_over_estimate,
                    }"
                >
                    <span v-if="time_spent" class="sign">
                        {{ consumed_over_estimate ? '+' : '−' }}
                    </span>
                    <span :class="{empty: !time_spent}">
                        {{ display_time_difference }}
                    </span>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {ISSUE_ICONS} from '@/enums';
import {
    hours2duration,
    hours2seconds,
    seconds2date,
    seconds2duration,
} from '@/filters';
import IssueItemCompact from '@/components/issues/IssueItemCompact.vue';
import Avatar from '@/components/generic/Avatar.vue';
import IssueSearch from '@/components/editor/IssueSearch.vue';
import SessionInput from '@/components/editor/SessionInput.vue';

export default {
    name: 'component-tracked-issue',
    components: {SessionInput, IssueSearch, Avatar, IssueItemCompact},
    props: {
        title: {
            type: String,
            required: true,
        },
        session: {
            type: Object,
            default: null,
        },
        issue_category: {
            type: String,
            default: null,
        },
        user_id: {
            type: String,
            default: null,
        },
        linked_issue: {
            type: Object,
            default: null,
        },
        can_bind_issue: {
            type: Boolean,
            default: false,
        },
        time_spent: {
            type: Number,
            default: null,
        },
        time_estimate: {
            type: Number,
            default: null,
        },
        start_date: {
            type: Number,
            default: null,
        },
        end_date: {
            type: Number,
            default: null,
        },
        show_column_labels: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            is_expanded: false,
        };
    },
    computed: {
        user() {
            return this.$store.getters.userWithId(this.user_id);
        },
        ISSUE_ICONS() {
            return ISSUE_ICONS;
        },
        display_time_estimate() {
            if (this.time_estimate) return hours2duration(this.time_estimate);
            return '-';
        },
        display_time_spent() {
            if (this.time_spent) return seconds2duration(this.time_spent);
            return '-';
        },
        display_time_difference() {
            if (this.time_spent) {
                if (this.time_estimate) {
                    const difference = Math.abs(
                        hours2seconds(this.time_estimate) - this.time_spent
                    );
                    return seconds2duration(difference);
                }
                return seconds2duration(this.time_spent);
            }
            return '-';
        },
        display_start_date() {
            if (!this.start_date) {
                return '-';
            }
            return seconds2date(this.start_date, 'DD/MM');
        },
        display_end_date() {
            if (!this.end_date) {
                return '-';
            }
            return seconds2date(this.end_date, 'DD/MM');
        },
        consumed_over_estimate() {
            if (this.time_spent) {
                if (this.time_estimate) {
                    return this.time_spent > hours2seconds(this.time_estimate);
                }
                return true;
            }
            return false;
        },
    },
    methods: {
        navigateToIssue() {
            //TODO: this assumes the task is a jira issue and not a todo
            window.open(
                `https://${this.linked_issue.resource.name}.atlassian.net/browse/${this.linked_issue.key}`,
                '_blank'
            );
        },
    },
};
</script>

<style scoped lang="scss">
.component-tracked-issue {
    --min-height: 32px;
    --column-width: 86px;

    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;

    gap: 5px;

    * {
        box-sizing: border-box;
    }

    .container span {
        color: $black;
        font-size: 13px;
    }

    .icon {
        display: flex;
        align-items: center;
        margin-right: 3px;
        height: var(--min-height);

        .issue-icon {
            width: 20px;
            height: 20px;
        }
    }

    section {
        display: flex;
        flex-direction: row;
        min-height: var(--min-height);
        background-color: $white;
        border-radius: 4px;
    }

    .description-section {
        flex: 1;
        padding: 0 12px;
        align-items: center;
    }

    .date-section {
        align-items: center;
        width: 110px;
        justify-content: center;
    }

    &.epic {
        .description-section {
            background-color: transparent;

            span {
                font-weight: 700;
                font-size: 15px;
            }
        }

        .column:before {
            content: attr(title);
            position: absolute;
            top: -20px;
            left: 0;
            right: 0;
            font-size: 11px;
            color: rgba($black, 0.5);
            text-align: center;
            opacity: 0;
            transform: translateY(10px);
            transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
        }

        &.show-column-labels .column:before {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: var(--column-width);

        span {
            text-align: center;
        }

        .clickable {
            cursor: pointer;
            width: 100%;
        }

        .issue-item-container {
            justify-content: flex-start;
            padding-left: 8px;
        }

        > .icon.remove {
            --size: 16px;

            position: absolute;
            z-index: 1;
            top: calc(var(--size) / -3);
            right: calc(var(--size) / -2);
            background-color: $white;
            border: none;
            width: var(--size);
            height: var(--size);
            padding: 0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: opacity 0.1s ease-out;
            opacity: 0;
            box-shadow: 0px 0px 0px 1px rgba($black, 0.15);

            svg {
                width: 50%;
                height: 50%;

                ::v-deep path {
                    fill: rgba($black, 0.5);
                }
            }

            &:hover svg ::v-deep path {
                fill: rgba($black, 0.75);
            }
        }

        &:hover > .icon.remove {
            opacity: 1;
        }
    }

    .separator {
        color: rgba($black, 0.25);
        font-size: 19px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .issue-search-container {
        width: calc(var(--column-width) * 3);
        padding: 0 6px;

        > div {
            width: 100%;
            height: 100%;
        }
    }

    .time-section {
        span:not(.empty):not(.separator) {
            font-weight: 700;
        }

        .column-difference {
            padding: 0 5px;
        }

        .frame {
            align-self: stretch;
            display: flex;
            align-items: center;
            border-radius: 3px;
            height: 22px;
            position: relative;

            color: rgba($black, 0.8);
            background-color: rgba($black, 0.03);

            span {
                color: inherit;
            }

            span:not(.sign) {
                flex: 1;
            }

            .sign {
                padding-left: 6px;
            }

            &.over-estimate {
                color: $red;
                background-color: rgba($red, 0.15);
            }

            &.under-estimate {
                color: $green-dark;
                background-color: rgba($green, 0.15);
            }
        }
    }
}
</style>
