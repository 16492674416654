<template>
    <div class="value-display" :class="goal_status">
        <div class="indicator">
            <i v-if="icon" :class="icon" />
            <span class="label">{{ percentage }}%</span>
        </div>
        <span class="value" :class="{bold}">{{ goal_value | currency }}</span>
    </div>
</template>

<script>
export default {
    name: 'revenue-goal-value',
    props: {
        goal: {
            type: Number,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
        bold: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        goal_value() {
            return this.value - this.goal;
        },
        goal_status() {
            return this.goal_value >= 0 ? 'above' : 'below';
        },
        icon() {
            if (this.goal_value >= 0) {
                return 'icon el-icon-arrow-up';
            } else {
                return 'icon el-icon-arrow-down';
            }
        },
        percentage() {
            return ((this.value / this.goal) * 100).toFixed(0);
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.value-display {
    padding: 10px;
    border-radius: 4px;
    color: $black;
    display: flex;
    flex-direction: row;
    align-items: center;

    .indicator {
        flex: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .label {
            font-size: 7px;
            font-weight: bolder;
        }
    }

    .value {
        flex: 1;
        text-align: right;

        &.bold {
            font-weight: bold;
        }
    }

    &.above {
        background-color: #a06ee128;

        .icon,
        .label {
            color: #a06ee1;
        }
    }

    &.below {
        background-color: $border-grey-light;

        .icon,
        .label {
            opacity: 0.5;
        }
    }
}
</style>
