<template>
    <div>
        <transition name="slide-time-tracking-bar">
            <div v-if="show_tracking_bar" key="bar" class="bar">
                <div class="medium_wrapper">
                    <!-- Showing when user is tracking - or session is starting up -->
                    <tracking-block
                        v-if="active_session || is_loading_session"
                        :user="user"
                        :editable="true"
                        show_stop_button
                    />

                    <!-- Showing when user selected a session for editing -->
                    <session-detail
                        v-else-if="selected_session"
                        v-on-clickaway="deselectSession"
                        :session="selected_session"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import TrackingBlock from '@/components/blocks/TrackingBlock';
import SessionDetail from '@/pages/sessions/components/SessionDetail';

import {mixin as clickaway} from 'vue-clickaway';

export default {
    name: 'time-tracking-bar',
    components: {
        TrackingBlock,
        SessionDetail,
    },
    mixins: [clickaway],
    computed: {
        user() {
            return this.$store.getters.user;
        },
        show_tracking_bar() {
            return this.$store.getters.show_tracking_bar;
        },
        is_loading_session() {
            return (
                // Tracking bar is showing
                this.show_tracking_bar &&
                // But there is no active session (Cloud function might be starting it)
                !this.active_session &&
                // ...also we didn't select a session (for editing)
                !this.$store.state.session_selected
            );
        },
        active_session() {
            return this.$store.getters.activeSessionForUserWithId(this.user.id);
        },
        selected_session() {
            return this.$store.state.session_selected;
        },
    },
    methods: {
        deselectSession(event) {
            if (![...event.target.classList].some((cl) => cl.includes('vis'))) {
                this.$bus.$emit('session_deselected');
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.slide-time-tracking-bar {
    &-enter-active,
    &-leave-active {
        transition: transform $bottom-bar-transition-speed ease-in-out;
    }

    &-enter,
    &-leave-to {
        transform: translateY(80px);
    }
}

.bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: $bottom-bar-height;
    z-index: 1000;
    border-top: 1px solid $border-grey-light;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);

    .medium_wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0 20px;
    }

    @media screen and (max-width: 992px) {
        padding: 0;

        & > .medium_wrapper {
            padding: 0 18px;
        }
    }
}
</style>
