<template>
    <div v-if="is_template">
        <placeholder-block
            label="Cover sheet"
            :block="block"
            :can_delete="false"
        />
    </div>
    <div v-else>
        <el-card class="content-block coversheet" shadow="never">
            <div slot="header" class="heading">
                <div class="grip">⣿</div>
                <span>Cover sheet</span>
            </div>

            <el-skeleton v-if="!estimate" :rows="2" animated />

            <div v-else>
                <div class="title">{{ estimate.title }}</div>
                <div class="desc">
                    <span>{{ estimate.ref }}</span>
                    <span v-if="estimate.description">
                        |
                        <span v-if="typeof estimate.description === 'string'">
                            {{ estimate.description }}
                        </span>
                        <span v-else class="invalid">
                            legacy description requires updating (will not be
                            included in output)
                        </span>
                    </span>
                </div>
                <div :key="client?.id" class="client">
                    <div class="label">Client address</div>
                    <div class="name">{{ client?.name }}</div>
                    <div class="address">
                        <span v-if="clientAddress.address">
                            {{ clientAddress.address }}<br />
                        </span>
                        <span>
                            {{ clientAddress.city }},
                            {{ clientAddress.region }}
                            {{ clientAddress.postcode }}
                        </span>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import PlaceholderBlock from '@/pages/documents/components/PlaceholderBlock';

export default {
    name: 'cover-sheet-block',
    components: {PlaceholderBlock},
    props: {
        estimate: {
            type: Object,
            default: null,
        },
        is_template: {
            type: Boolean,
            required: true,
        },
        block: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            client: null,
        };
    },
    computed: {
        clientAddress() {
            if (this.client?.addresses?.length) {
                return this.client.addresses[0];
            }
            return {
                address: null,
                city: 'Adelaide',
                region: 'SA',
                postcode: '5000',
            };
        },
    },
    watch: {
        estimate: {
            async handler(est) {
                if (est) {
                    if (est.client) {
                        let clientRef = est.client;
                        if (!est.client.id) {
                            clientRef = this.$fire.doc(clientRef);
                        }
                        await this.$bind('client', clientRef, {maxRefDepth: 0});
                    }
                }
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.content-block {
    margin-bottom: 10px;
}
.heading {
    display: flex;
    align-items: center;

    .grip {
        width: 20px;
        height: 23px;
        text-align: left;
        opacity: 0.25;
        user-select: none;
        cursor: grab;
    }

    span {
        font-weight: bold;
        font-size: 14px;
        color: $blue;
        display: flex;
        flex: 1;
    }
}

.title {
    font-weight: bold;
}

.desc {
    margin-bottom: 20px;

    .invalid {
        font-weight: bold;
        font-style: italic;
        color: $red;
    }
}

.client {
    .label {
        font-size: 12px;
        color: $blue;
    }

    .name {
        font-weight: bold;
    }
}
</style>
