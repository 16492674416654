<template>
    <div class="wrapper">
        <component-info :component="component" />

        <div class="session-detail">
            <div class="info">
                <div class="row">
                    <span>
                        Start:
                    </span>
                    <label>
                        {{ (session.start.seconds * 1000) | milli2date }}
                    </label>
                </div>
                <div class="row">
                    <span>
                        Stop:
                    </span>
                    <label>
                        {{ (session.stop.seconds * 1000) | milli2date }}
                    </label>
                </div>
            </div>

            <div class="spacer" />

            <span class="time">
                {{
                    ((session.stop.seconds - session.start.seconds) * 1000)
                        | milli2duration
                }}
            </span>

            <div class="note">
                <span>What was this about?</span>
                <session-input class="el-input" :session="session" />
            </div>
        </div>
    </div>
</template>

<script>
import ComponentInfo from '@/components/generic/ComponentInfo.vue';
import SessionInput from '@/components/editor/SessionInput.vue';

export default {
    name: 'session-detail',
    components: {SessionInput, ComponentInfo},
    props: {
        session: {
            type: Object,
            required: true,
        },
    },
    computed: {
        component() {
            if (!this.session) return null;
            return this.$store.getters.componentWithId(this.session.component);
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    height: $bottom-bar-height;

    .component-info {
        height: $bottom-bar-height;
    }

    .session-detail {
        position: absolute;
        right: 0;
        top: 0;
        width: calc(100% - 350px);
        height: $bottom-bar-height;
        z-index: 1000;
        border-top: 1px solid $border-grey-light;
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        padding: 10px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $black;

        @media screen and (max-width: 992px) {
            display: none;
        }

        &:before {
            content: '';
            line-height: 0;
            font-size: 0;
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-bottom: 100px solid $black;
            border-left: 50px solid transparent;
            border-right: 0px solid $black;
            position: absolute;
            top: -50px;
            left: -50px;
        }

        &:after {
            content: '';
            top: -1px;
            border-top: 1px solid $border-grey-light;
            right: -200%;
            width: 200%;
            height: $bottom-bar-height;
            position: absolute;
            background-color: $black;
        }

        .info {
            width: 140px;
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .row {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 2px 0;

                span {
                    display: inline-block;
                    font-size: 14px;
                    width: 40px;
                }

                label {
                    color: white;
                    margin-left: 10px;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }

        .spacer {
            background-color: rgba(white, 0.1);
            width: 1px;
            margin: 0 20px;
            height: 24px;
        }

        .time {
            font-size: 20px;
            min-width: 150px;
            line-height: 40px;
            margin-left: 10px;
        }

        span {
            color: white;
            display: block;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .note {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            span {
                margin: 8px 0 2px 0;
                font-size: 12px;
                font-weight: bold;
            }

            ::v-deep input {
                text-align: right;
                border: none;
                background-color: unset;
                font-size: 16px;
                min-height: 39px;
                color: white;
            }

            ::v-deep .el-input {
                color: $white;

                .link {
                    color: $white;

                    a {
                        color: $white;
                    }
                }

                .ProseMirror p.is-editor-empty:first-child::before {
                    position: absolute;

                    right: 0;
                }

                .content {
                    .ProseMirror {
                        text-align: right;
                    }
                }
            }
        }
    }
}
</style>
