<template>
    <div v-if="change_request">
        <div class="header">
            <el-button @click="goBackAction">Back</el-button>
            <span class="ref">
                /
                <span>
                    {{ change_request.ref }}
                </span>
            </span>
        </div>

        <hr />

        <el-row :gutter="12">
            <el-col :sm="6" style="min-width: 220px;">
                <content-block title="Status">
                    <steps
                        :steps="steps"
                        :current_step="crStatusIndex"
                        :offset="1"
                    />
                </content-block>
                <ul v-only-admin class="options">
                    <li>
                        <a
                            v-if="
                                change_request.status === CR_STATUS.IN_PROGRESS
                            "
                            class="archive-button"
                            @click="updateStatus(CR_STATUS.CLOSED)"
                        >
                            <b>Close</b> - Job done!
                        </a>
                    </li>
                    <li>
                        <a
                            v-if="change_request.status != CR_STATUS.ARCHIVED"
                            class="archive-button"
                            @click="updateStatus(CR_STATUS.ARCHIVED)"
                        >
                            Archive
                        </a>
                    </li>
                    <li>
                        <a
                            v-if="change_request.status > CR_STATUS.BACKLOG"
                            class="archive-button"
                            @click="updateStatus(CR_STATUS.BACKLOG)"
                        >
                            Bring back to Backlog
                        </a>
                    </li>
                </ul>
            </el-col>
            <el-col :sm="18">
                <ChangeRequest__scope
                    :change_request="change_request"
                    :project="project"
                />

                <ChangeRequest__link
                    :change_request="change_request"
                    :project="project"
                />

                <ChangeRequest__estimate
                    :change_request="change_request"
                    :project="project"
                    :estimate="estimate"
                />

                <ChangeRequest__modules
                    :change_request="change_request"
                    :project="project"
                />

                <ChangeRequest__jira
                    :change_request="change_request"
                    :project="project"
                />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import Steps from '@/components/generic/Steps';

import {CR_STATUS} from '@/enums';

import ChangeRequest__scope from './components/ChangeRequest_scope';
import ChangeRequest__jira from './components/ChangeRequest_jira';
import ChangeRequest__link from './components/ChangeRequest_link';
import ChangeRequest__estimate from './components/ChangeRequest_estimate';
import ChangeRequest__modules from './components/ChangeRequest_modules';

export default {
    name: 'change-request-detail',
    components: {
        ContentBlock,
        Steps,
        ChangeRequest__scope,
        ChangeRequest__jira,
        ChangeRequest__link,
        ChangeRequest__estimate,
        ChangeRequest__modules,
    },
    data() {
        return {
            CR_STATUS: CR_STATUS,
            steps: [
                {
                    title: 'Backlog',
                    key: 'backlog',
                    type: 'state',
                },
                {
                    title: 'Scoping',
                    key: 'scoping',
                    type: 'action',
                },
                {
                    title: 'Pre-approval',
                    key: 'preapproval',
                    type: 'action',
                },
                {
                    title: 'Quoting',
                    key: 'quoting',
                    type: 'action',
                },
                {
                    title: 'Contract',
                    key: 'contract',
                    type: 'action',
                },
                {
                    title: 'In progress',
                    key: 'inprogress',
                    type: 'action',
                },
                {
                    title: 'Closed',
                    key: 'closed',
                    type: 'state',
                },
            ],
            estimate: null,
            quotes: [],
        };
    },
    computed: {
        change_request() {
            return this.$store.getters.changeRequestWithId(
                this.$route.params.change_request_id
            );
        },
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        crStatusIndex() {
            // treat backlog as the new minimum status (accounts for pre-existing CRs)
            return Math.max(0, this.change_request.status - 1);
        },
        now() {
            return new Date();
        },
    },
    watch: {
        '$route.params.change_request_id'(val, oldVal) {
            if (val !== oldVal) {
                this.load();
            }
        },
        'change_request.estimate': {
            async handler(val, oldVal) {
                if (val) {
                    // estimate has been assigned
                    const estimateId = await this.$options.filters.fireRef2id(
                        val
                    );
                    // get assigned estimate
                    this.estimate = this.$store.getters.estimateWithId(
                        estimateId
                    );
                } else if (val !== oldVal) {
                    this.estimate = null;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            await this.$store.dispatch('bindProjectChangeRequests', {
                project: this.$route.params.project_id,
                closed: true,
            });
        },
        goBackAction() {
            this.$router.push({
                name: 'project_detail_changerequests',
                params: {
                    project_id: this.$route.params.project_id,
                },
            });
        },
        updateStatus(status) {
            return this.$fire
                .collection('change_requests')
                .doc(this.change_request.id)
                .update({
                    archived_at: this.now,
                    status,
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    span.ref {
        padding: 0 10px;

        span {
            padding: 0 10px;
            font-weight: bold;
        }
    }
}

hr {
    width: 100%;
    margin: 10px 0;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}

::v-deep .markdown {
    font-size: 13px;
}

.form-wrapper {
    max-width: 100%;
}

.options {
    padding: 10px 20px;
    margin-top: 20px;
    list-style: none;
    border-top: 1px solid $grey;

    li a {
        padding: 10px 0;
        display: block;
        font-size: 11px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
