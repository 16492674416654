<template>
    <div class="outer">
        <breadcrumb-header
            :labels="['New project']"
            go_back_to="management_projects"
        />

        <div class="inner">
            <projects-new @created="onProjectCreated" />
        </div>
    </div>
</template>

<script>
import BreadcrumbHeader from '@/pages/management/components/BreadcrumbHeader';
import ProjectsNew from '@/pages/projects/new';

export default {
    name: 'management-projects-new',
    components: {
        BreadcrumbHeader,
        ProjectsNew,
    },
    data() {
        return {};
    },
    methods: {
        onProjectCreated() {
            this.navigateToProject();
        },
        navigateToProject() {
            this.$router
                .push({
                    name: 'management_project_detail',
                    params: {
                        project_id: this.project.id,
                    },
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    .inner {
        padding: 20px;

        .el-button {
            float: right;
            margin-right: 20px;
            margin-bottom: 20px;
        }
    }
}
</style>
