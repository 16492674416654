<template>
    <div class="container">
        <div class="inner">
            <div class="shy-icon">
                <el-checkbox
                    :value="!isNodeShy(cur_node)"
                    @input="$emit('toggle:shy')"
                />
            </div>
            <div class="label" @click="$emit('click', cur_node)">
                <span>{{ cur_node.label }}</span>
                <project-status-tag :status="currentStatusType" size="mini" />
            </div>
            <el-popover
                v-model="visible"
                placement="right"
                title="Add Milestone"
                trigger="click"
                popper-class="milestone-popover"
            >
                <div slot="reference" class="add-button">
                    <i class="el-icon-circle-plus-outline"></i>
                </div>
                <div>
                    <el-form>
                        <el-input
                            v-model="content"
                            placeholder="Content"
                            size="mini"
                        />
                        <el-date-picker
                            v-model="date"
                            size="mini"
                            format="dd/MM/yyyy"
                        />
                    </el-form>
                </div>
                <div style="text-align: right; margin-top: 10px;">
                    <el-button size="mini" type="text" @click="handleCancel">
                        cancel
                    </el-button>
                    <el-button type="primary" size="mini" @click="handleSave">
                        save
                    </el-button>
                </div>
            </el-popover>

            <div
                v-if="has_children"
                class="action"
                @click="$emit('toggle:hide')"
            >
                <span v-if="children_collapsed" class="el-icon-caret-right">
                </span>
                <span v-else class="el-icon-caret-bottom"> </span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ProjectStatusTag from '../generic/ProjectStatusTag';

export default {
    name: 'project-list-item',

    components: {ProjectStatusTag},
    props: {
        tree: {
            type: Object,
            required: true,
        },

        cur_node: {
            type: Object,
            required: true,
        },

        depth: {
            type: Number,
            required: true,
        },
    },

    data: function () {
        return {
            date: new Date(),
            content: '',
            visible: false,
        };
    },

    computed: {
        currentStatusType() {
            if (this.cur_node.currentStatus) {
                return (
                    this.cur_node.currentStatus.statusType.statuses.find(
                        (s) => s.value === this.cur_node.currentStatus.status
                    ) ?? null
                );
            }
            return null;
        },

        has_children() {
            return this.cur_node.children.length > 0;
        },

        children_collapsed() {
            if (!this.has_children) return true;
            return this.$store.getters.isNodeHidden(this.cur_node);
        },
        ...mapGetters(['showChildren', 'isNodeShy']),
    },

    methods: {
        handleCancel() {
            this.visible = false;
        },

        handleSave() {
            if (!this.content) return;

            this.$emit('update:node', {
                type: 'add-event',
                payload: {
                    content: this.content,
                    project_id: this.cur_node.id,
                    date: this.date,
                },
            });

            this.visible = false;
        },
    },
};
</script>

<style scoped lang="scss">
.container {
    height: 100%;
    width: 100%;
    padding: 0 10px;

    .inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $black;
        border-radius: 5px;
        padding: 0 7px;

        ::v-deep .shy-icon {
            margin-right: 7px;

            .el-checkbox__input {
                &.is-checked .el-checkbox__inner {
                    border-color: $blue;
                }

                .el-checkbox__inner {
                    background-color: $black-darker;
                    border-color: $black-darker;
                    transition: border-color 0.15s
                        cubic-bezier(0.71, -0.46, 0.29, 1.46);

                    &:after {
                        transition: opacity 0.15s
                            cubic-bezier(0.71, -0.46, 0.29, 1.46);
                    }
                }
            }
        }

        .collapse-icon {
            font-size: 12px;
            margin-right: 3px;
        }

        .label {
            color: $white;
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .add-button {
            margin-left: auto;
            padding: 7px;
            transition: 0.15s;
            color: white;

            &:hover {
                transform: scale(1.2);
            }
        }

        .action {
            color: white;
            padding: 0px 10px;
        }
    }
}

.milestone-popover {
    .el-input {
        width: 100%;
    }

    .el-input + .el-input {
        margin-top: 5px;
    }
}
</style>
