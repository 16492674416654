<template>
    <div class="projects-detail">
        <actions-buttons>
            <el-tabs v-model="activeTab" @tab-click="tabChanged">
                <el-tab-pane label="Overview" name="_overview" />
                <el-tab-pane label="Components" name="_components" />
                <el-tab-pane
                    v-if="
                        $store.getters.is_feature_enabled(
                            SUPPORTED_FEATURES.PROJECT_ESTIMATES
                        )
                    "
                    label="Estimates"
                    name="_estimates"
                />
                <el-tab-pane
                    v-if="
                        $store.getters.is_feature_enabled(
                            SUPPORTED_FEATURES.PROJECT_CHANGE_REQUESTS
                        )
                    "
                    label="CRs"
                    name="_changerequests"
                />
                <el-tab-pane label="Timeline" name="_timeline" />
                <el-tab-pane
                    v-if="
                        $store.getters.is_feature_enabled(
                            SUPPORTED_FEATURES.PROJECT_UTILS
                        )
                    "
                    label="Utils"
                    name="_utils"
                />
                <el-tab-pane label="Documents" name="_documents" />
                <el-tab-pane
                    v-if="user_is_admin"
                    label="Settings"
                    name="_settings"
                />
            </el-tabs>
        </actions-buttons>

        <transition v-if="project" name="slide" mode="out-in">
            <ProjectTab_overview
                v-if="activePageKey === '_overview'"
                key="overview"
            />
            <ProjectTab_components
                v-if="activePageKey === '_components'"
                key="components"
            />
            <ProjectTab_estimates
                v-else-if="activePageKey === '_estimates'"
                key="estimates"
            />
            <ProjectTab_estimate
                v-else-if="activePageKey === '_estimates+detail'"
                key="estimates+detail"
            />
            <ProjectTab_changerequests
                v-else-if="activePageKey === '_changerequests'"
                key="changerequests"
            />
            <ProjectTab_changerequest
                v-else-if="activePageKey === '_changerequests+detail'"
                key="changerequests+detail"
            />
            <ProjectTab_timeline
                v-else-if="activePageKey === '_timeline'"
                key="timeline"
            />
            <ProjectTab_utils
                v-else-if="activePageKey === '_utils'"
                key="utils"
            />
            <ProjectTab_documents
                v-else-if="activePageKey === '_documents'"
                key="documents"
            />
            <ProjectTab_notes
                v-else-if="activePageKey === '_notes'"
                key="notes"
            />
            <ProjectTab_settings
                v-else-if="activePageKey === '_settings'"
                key="settings"
            />
        </transition>
    </div>
</template>

<script>
import ActionsButtons from '@/components/generic/ActionsButtons';

import ProjectTab_overview from '@/pages/projects/tabs/ProjectTab_overview';
import ProjectTab_components from '@/pages/projects/tabs/ProjectTab_components';
import ProjectTab_estimates from '@/pages/projects/tabs/ProjectTab_estimates';
import ProjectTab_estimate from '@/pages/projects/tabs/ProjectTab_estimate';
import ProjectTab_changerequests from '@/pages/projects/tabs/ProjectTab_changerequests';
import ProjectTab_changerequest from '@/pages/projects/tabs/ProjectTab_changerequest';
import ProjectTab_timeline from '@/pages/projects/tabs/ProjectTab_timeline';
import ProjectTab_utils from '@/pages/projects/tabs/ProjectTab_utils';
import ProjectTab_documents from '@/pages/projects/tabs/ProjectTab_documents';
import ProjectTab_notes from '@/pages/projects/tabs/ProjectTab_notes';
import ProjectTab_settings from '@/pages/projects/tabs/ProjectTab_settings';

import userMixin from '@/mixins/user.mixin';
import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';

export default {
    name: 'projects-detail',
    components: {
        ActionsButtons,
        ProjectTab_overview,
        ProjectTab_components,
        ProjectTab_estimates,
        ProjectTab_estimate,
        ProjectTab_documents,
        ProjectTab_changerequests,
        ProjectTab_changerequest,
        ProjectTab_timeline,
        ProjectTab_utils,
        ProjectTab_notes,
        ProjectTab_settings,
    },
    mixins: [userMixin],
    data() {
        return {
            activeTab: '_overview',
            activePageKey: '_overview',
        };
    },
    computed: {
        SUPPORTED_FEATURES() {
            return SUPPORTED_FEATURES;
        },
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
    },
    watch: {
        $route(to) {
            this.detectTab(to.meta.tab);
        },
    },
    created() {
        if (!this.project) {
            this.$router.replace({name: 'projects'});
            return;
        }

        this.detectTab(this.$router.currentRoute.meta.tab);
    },
    methods: {
        detectTab(tab) {
            this.activeTab = tab.includes('+') ? tab.split('+')[0] : tab;
            this.activePageKey = tab;
        },
        tabChanged(event) {
            this.$router
                .replace({
                    name: `project_detail${event.name}`,
                    params: {project_id: this.$route.params.project_id},
                })
                .catch((err) => {
                    console.error(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.projects-detail {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.slide {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateX(5px);
    }
}

@media screen and (max-width: 992px) {
    .projects-detail {
        ::v-deep {
            #tab-_timeline,
            #tab-_changerequests,
            #tab-_estimates,
            #tab-_documents {
                display: none;
            }
        }
    }
}
</style>
