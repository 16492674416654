import {COMPONENT_STATUS, STAGE_STATUS} from '../enums';

const stageMixin = {
    computed: {
        // The stage ID
        stage_id() {
            if (this.stage && this.stage.id) return this.stage.id;
            return this.$route.params.stage_id;
        },

        // The stage object
        // ======
        stage() {
            return this.$store.getters.stageWithId(this.stage_ref);
        },

        // The project object for the given stage
        // ======
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },

        // If the stage is active
        is_stage_active() {
            return !!(this.stage.status === STAGE_STATUS.ACTIVE);
        },
        // If the stage has been closed/completed
        is_stage_completed() {
            return !!(this.stage.status === STAGE_STATUS.COMPLETED);
        },

        // All the components for the given stage
        // ======
        components() {
            const components = this.stage.components;
            if (!components) return [];

            return components
                .map((component) =>
                    this.$store.getters.componentWithId(component)
                )
                .sort(function (a, b) {
                    if (
                        a.status === COMPONENT_STATUS.ACTIVE &&
                        b.status !== COMPONENT_STATUS.ACTIVE
                    )
                        return -1;
                    if (
                        a.status !== COMPONENT_STATUS.ACTIVE &&
                        b.status === COMPONENT_STATUS.ACTIVE
                    )
                        return 1;
                    return 0;
                });
        },

        // All the non-recurring components for the given stage
        // ======
        non_recurring_components() {
            return this.components.filter((c) => !c.recurring);
        },

        // All the recurring components for the given stage
        // ======
        recurring_components() {
            return this.components.filter((c) => c.recurring);
        },
    },
    methods: {
        updateStage(key, val = null) {
            const value = val ? val : null;
            this.$store.dispatch('updateStageWithId', {
                stage_id: this.stage_id,
                key,
                value,
            });

            if (key === 'status' && val === STAGE_STATUS.COMPLETED) {
                console.log(
                    'Stage marked completed - saving completion date and completing components...'
                );

                this.$store.dispatch('updateStageWithId', {
                    stage_id: this.stage_id,
                    key: 'completed_date',
                    value: new Date(),
                });

                this.completeComponents();
            }
        },
        completeComponents() {
            const components = this.$store.getters.componentsForStageWithId(
                this.stage.id
            );

            if (components.length) {
                console.log('Completing components...');
            } else {
                console.log('No components to complete');
            }

            components.forEach((c) => {
                if (c.status === COMPONENT_STATUS.ACTIVE) {
                    this.$store.dispatch('updateComponentWithId', {
                        component_id: c.id,
                        payload: {
                            status: COMPONENT_STATUS.COMPLETED,
                            completed_at:
                                this.stage.end_date &&
                                this.stage.end_date.toDate() < new Date()
                                    ? this.stage.end_date.toDate()
                                    : new Date(),
                        },
                    });
                }
            });
        },
    },
};

export default stageMixin;
