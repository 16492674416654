<template>
    <div class="spacer" :class="size">
        <div class="arrow"></div>
    </div>
</template>

<script>
export default {
    name: 'arrow-spacer',
    props: {
        size: {
            type: String,
            default: 'normal',
        },
    },
};
</script>

<style lang="scss" scoped>
.spacer {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.normal {
        transform: scale(0.4, 1);
        margin-left: -5px;
        margin-right: 4px;
    }

    &.small {
        transform: scale(0.3, 0.5);
        margin-left: -4px;
        margin-right: 3px;
    }

    .arrow {
        border: solid $border-grey;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        width: 30px;
        height: 30px;
    }
}
</style>
