<template>
    <command-layout :show_back="true" @goBack="$emit('goBack')">
        <div slot="header" class="header">
            <todo-input
                ref="input"
                :content="shared_query"
                @cancel="$emit('goBack')"
            />
        </div>
        <todo-list slot="body" />
    </command-layout>
</template>

<script>
import CommandLayout from '@/components/command-palette/components/CommandLayout.vue';
import TodoInput from '@/components/todo/TodoInput.vue';
import TodoList from '@/components/todo/TodoList.vue';

export default {
    name: 'todos-command-layout',
    components: {TodoList, TodoInput, CommandLayout},
    props: {
        shared_query: {
            default: null,
            type: String,
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.input.setFocus();
        });
    },
};
</script>

<style lang="scss" scoped>
::v-deep .header {
    border-radius: 4px;

    .container {
        background: transparent;
        .editor {
            background: white;
            box-shadow: none;
        }
    }
}

::v-deep .todo-list {
    label.section-title {
        padding-left: 15px;
    }

    ul.section {
        padding-left: 10px;

        li {
            .info {
                .spacer {
                    display: none;
                }
            }
        }
    }
}
</style>
