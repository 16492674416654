<template>
    <el-card class="content-block text" shadow="never">
        <template v-if="editingBlock">
            <div slot="header" class="heading">
                <div class="grip">⣿</div>
                <el-input
                    ref="heading"
                    v-model="editingBlock.heading"
                    :readonly="read_only || (!is_template && locked)"
                    :placeholder="headingPlaceholderText"
                    :class="{
                        invalid: blockInvalid,
                        empty: !blockInvalid && !locked && headingEmpty,
                    }"
                    @input="updateBlock"
                />
                <template v-if="!read_only">
                    <el-tooltip
                        v-if="is_template || editingBlock.mandatory"
                        :content="lockTooltip"
                    >
                        <el-button
                            class="control"
                            :icon="locked ? 'el-icon-lock' : 'el-icon-unlock'"
                            plain
                            :type="locked ? null : 'warning'"
                            @click="toggleLocked"
                        />
                    </el-tooltip>
                    <el-button
                        class="control"
                        plain
                        type="danger"
                        icon="el-icon-delete"
                        :disabled="!is_template && locked"
                        @click="deleteBlock"
                    />
                </template>
            </div>
            <text-editor
                :editable="!read_only"
                ref="content_editor"
                :content.sync="editingBlock.content"
                placeholder="Block content"
                :floating_menu="false"
                :drag_drop="false"
                :class="{
                    invalid: blockInvalid,
                    empty: !blockInvalid && contentEmpty,
                }"
                @update:content="updateBlock"
            />
        </template>
        <template v-else>
            <div slot="header"><el-skeleton :rows="1" animated /></div>
            <el-skeleton :rows="3" animated />
        </template>
    </el-card>
</template>

<script>
import TextEditor from '@/components/editor/TextEditor';

export default {
    name: 'text-block',
    components: {TextEditor},
    props: {
        block: {
            type: Object,
            required: true,
        },
        is_template: {
            type: Boolean,
            required: true,
        },
        read_only: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            editingBlock: null,
            locked: true,
        };
    },
    computed: {
        lockTooltip() {
            if (this.is_template) {
                return `This block is ${
                    this.locked ? 'mandatory' : 'optional'
                }`;
            } else {
                if (this.locked) {
                    return 'This block is mandatory. Unlock to edit title or remove';
                } else {
                    return 'This an unlocked mandatory block. Edit or remove with caution!';
                }
            }
        },
        headingPlaceholderText() {
            if (!this.is_template && this.locked) {
                return 'No Heading';
            } else {
                return 'Block heading';
            }
        },
        headingEmpty() {
            return !this.editingBlock.heading;
        },
        contentEmpty() {
            return !this.editingBlock.content;
        },
        blockInvalid() {
            return !this.editingBlock.heading && !this.editingBlock.content;
        },
    },
    watch: {
        block: {
            handler(newVal, oldVal) {
                this.editingBlock = {...newVal};
                // set locked status from block if:
                // - this is a template block
                // - this is a new block
                // - the block is currently in the locked state
                // if it has been manually unlocked, leave it unlocked
                if (
                    this.isTemplate ||
                    !oldVal ||
                    newVal.id !== oldVal.id ||
                    this.locked
                ) {
                    this.locked = newVal.mandatory;
                }
            },
            immediate: true,
        },
    },
    methods: {
        toggleLocked() {
            this.locked = !this.locked;
            if (this.is_template) {
                this.editingBlock.mandatory = this.locked;
                this.updateBlock();
            }
        },

        updateBlock() {
            this.$emit('update', this.editingBlock);
        },

        deleteBlock() {
            this.$emit('delete', this.editingBlock.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.heading {
    display: flex;
    align-items: center;
    .grip {
        width: 20px;
        height: 23px;
        text-align: left;
        opacity: 0.25;
        user-select: none;
        cursor: grab;
    }

    ::v-deep .el-input {
        margin-right: 10px;

        .el-input__inner {
            font-weight: bold;
            font-size: 18px;
        }

        &.invalid .el-input__inner {
            border-color: $red;
            transition: background-color 0.5s;

            &:not(:focus) {
                background: rgba($red, 0.03);
                border-color: rgba($red, 0.5);
            }
        }
        &.empty .el-input__inner {
            border-color: $orange;
            transition: background-color 0.5s;

            &:not(:focus) {
                background: rgba($orange, 0.03);
                border-color: rgba($orange, 0.5);
            }
        }
    }
}

::v-deep .text-editor-wrapper.invalid {
    .ProseMirror {
        &:not(.ProseMirror-focused) {
            background: rgba($red, 0.03);
            border-color: rgba($red, 0.5) !important;
        }
        &.ProseMirror-focused {
            box-shadow: 0 0 0 2px $red !important;
        }
    }
}
::v-deep .text-editor-wrapper.empty {
    .ProseMirror {
        &:not(.ProseMirror-focused) {
            background: rgba($orange, 0.03);
            border-color: rgba($orange, 0.5) !important;
        }
        &.ProseMirror-focused {
            box-shadow: 0 0 0 2px $orange !important;
        }
    }
}

.dragging {
    cursor: grabbing;
}
</style>
