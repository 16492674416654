import request from '@/utils/request';

// {{host}}/invoices
export function updateInvoiceCache() {
    return request({
        url: '/invoices',
        method: 'post',
    });
}

// {{host}}/invoice/:invoice_id
export function getInvoice(invoice_number) {
    return request({
        url: `/invoice/${invoice_number}`,
        method: 'get',
    });
}

// {{host}}/invoice/:invoice_id
export function deleteInvoice(invoice_id) {
    return request({
        url: `invoice/${invoice_id}`,
        method: 'delete',
    });
}

// {{host}}/invoices/contact/:contact_id
export function getInvoicesForContact(contact_id) {
    return request({
        url: `/invoices/contact/${contact_id}`,
        method: 'get',
    });
}
