<template>
    <div class="accordion" :class="{expanded: is_expanded}">
        <div class="header">
            <span
                class="collapse"
                :class="{disabled: !$slots.default}"
                @click="toggleExpanded"
            >
                <svgicon
                    v-if="$slots.default"
                    name="triangle"
                    class="triangle"
                />
            </span>
            <slot name="header" :is_expanded="is_expanded" />
        </div>
        <div v-show="is_expanded" class="accordion-body">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    name: 'accordion',
    data() {
        return {
            is_expanded: false,
        };
    },
    methods: {
        toggleExpanded() {
            if (this.$slots.default) {
                this.is_expanded = !this.is_expanded;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.accordion {
    border-radius: 5px;
    background-color: $grey;
    position: relative;

    .accordion-body {
        padding-left: 20px;
        padding-top: 10px;
    }
    &.expanded {
        padding-bottom: 10px;

        > .header > .collapse > .triangle {
            transform: rotate(90deg);
        }
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;

        .collapse {
            padding: 2px 0 2px 0;
            margin-left: -10px;
            opacity: 0.3;
            cursor: pointer;
            display: flex;
            width: 20px;
            &.disabled {
                cursor: default;
            }

            .triangle {
                width: 10px;
                height: 10px;
                margin: 4px 10px 3px 0;
                transform: rotate(0deg);
                transition: transform ease-in-out 0.1s;
            }
        }
    }
}
</style>
