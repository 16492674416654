<template>
    <el-card class="content-block pagebreak" shadow="never">
        <div class="heading">
            <div class="grip">⣿</div>
            <div class="divider">
                <span>Page break</span>
                <hr />
            </div>
            <el-button
                v-if="!read_only"
                class="control"
                plain
                type="danger"
                icon="el-icon-delete"
                @click="deleteBlock"
            />
        </div>
    </el-card>
</template>

<script>
export default {
    name: 'page-break-block',
    components: {},
    props: {
        block: {
            type: Object,
            required: true,
        },
        is_template: {
            type: Boolean,
            required: true,
        },
        read_only: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        deleteBlock() {
            this.$emit('delete', this.block.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.heading {
    display: flex;
    align-items: center;

    .grip {
        width: 20px;
        height: 23px;
        text-align: left;
        opacity: 0.25;
        user-select: none;
        cursor: grab;
    }

    .divider {
        flex: 1;
        margin-right: 20px;
        display: flex;

        span {
            color: $blue;
            font-weight: bold;
            font-size: 14px;
            text-align: center;
            margin-right: 10px;
        }

        hr {
            flex: 1;
            border: none;
            border-bottom: 2px dashed rgba($black, 0.2);
        }
    }
}
</style>
