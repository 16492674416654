<template>
    <component
        v-if="custom_component"
        :is="custom_component"
        :show_back="show_back"
        :initial_data="active_data"
        @goBack="handleGoBack"
        @close="handleClose"
    />
    <command-layout v-else :show_back="show_back" @goBack="handleGoBack">
        <div slot="header" class="header">
            <div class="command-prompt">{{ prompt }}</div>
            <el-input
                ref="search"
                v-model="search_string"
                :disabled="!!loading"
                class="search"
                size="small"
                @keyup.enter.native="() => addData()"
                @keyup.backspace.native="() => handleBackspacePress()"
            />
            <div class="breadcrumbs">
                <span v-for="(crumb, index) of breadcrumbs" :key="index">
                    <svgicon name="triangle" class="breadcrumb-arrow" />
                    {{ crumb }}
                </span>
            </div>
        </div>
        <div
            slot="body"
            v-loading="!!loading"
            :element-loading-text="loading"
            class="body"
        >
            <ul
                v-if="provider_results.length"
                v-shortkey="{
                    up: ['arrowup'],
                    down: ['arrowdown'],
                    pageup: ['pageup'],
                    pagedown: ['pagedown'],
                    top: ['home'],
                    bottom: ['end'],
                }"
                class="results"
                @shortkey="selectItem"
            >
                <li
                    v-for="provider of filtered_results"
                    :key="provider.provides"
                    class="provider"
                >
                    <span class="label">
                        {{ provider.label }}
                        <i
                            v-if="provider.results === undefined"
                            class="el-icon-loading"
                        />
                    </span>
                    <span v-if="provider.results === null" class="info">
                        type a filter string to view
                    </span>
                    <ul v-else>
                        <li
                            v-for="result of provider.results"
                            :key="result.id"
                            :ref="result.id"
                            class="result"
                            :class="{
                                selected:
                                    selected_item &&
                                    result.id === selected_item.id,
                            }"
                            @click.prevent="() => addData(result)"
                        >
                            <el-tag
                                class="shortcut"
                                type="info"
                                size="mini"
                                v-if="result.shortcut"
                            >
                                {{ result.shortcut }}
                            </el-tag>
                            <span class="label">
                                {{ result.label }}
                            </span>
                            <span class="sub-label">
                                {{ result.sub_label }}
                            </span>
                        </li>
                    </ul>
                </li>
            </ul>
            <div v-else class="no-results">No matching results</div>
            <component
                :is="active_command"
                ref="command"
                :provided_data="active_data"
                :search_string="search_string"
                @command="changeCommand"
                @component="showCustomComponent"
                @loading="setLoading"
                @close="handleClose"
            />
        </div>
    </command-layout>
</template>

<script>
import CommandLayout from '@/components/command-palette/components/CommandLayout.vue';
import DefaultCommand from '@/components/command-palette/commands/DefaultCommand.vue';

// Providers
import {Providers} from '@/components/command-palette/Providers';
import userMixin from '@/mixins/user.mixin';
import commandSelectorMixin from '@/components/command-palette/CommandSelectorMixin';
import Vue from 'vue';

export default {
    name: 'command-palette',
    components: {CommandLayout},
    mixins: [userMixin, commandSelectorMixin],
    props: {
        command: {
            type: Object,
            default: null,
        },
        initial_data: {
            type: Object,
            default: null,
        },
        showing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            search_string: null,
            // each entry in the data stack must always have a command defined
            data_stack: [{}],
            loading: false,
            fetching: false,
            provider_results: [],
            custom_component: null,
        };
    },
    computed: {
        active_data() {
            // get the active data resource from the top of the stack
            return this.data_stack[this.data_stack.length - 1];
        },
        active_command() {
            // get the active command, in this order of priority:
            // - from active data
            // - command supplied via prop
            // - default command
            return (
                this.active_data.command?.value ||
                this.command ||
                DefaultCommand
            );
        },
        command_dependencies() {
            return this.active_command?.command_props.dependencies || [];
        },
        providers() {
            return Providers;
        },
        command_providers() {
            // filter providers list to those in the command dependency supply chain
            return this.filterProviders(this.command_dependencies);
        },
        filtered_providers() {
            // filter command providers list to top level providers
            // (directly providing a dependency, or with no filteredBy)
            // unless a filter_string is provided
            if (!this.filter_string) {
                return this.command_providers.filter((provider) => {
                    return (
                        this.command_dependencies.includes(provider.provides) ||
                        !provider.filteredBy ||
                        provider.filteredBy.length === 0
                    );
                });
            }
            return this.command_providers;
        },
        filtered_results() {
            // remove providers with undefined results after loading
            if (this.fetching) {
                return this.provider_results;
            } else {
                return this.provider_results.filter((provider) => {
                    return provider.results !== undefined;
                });
            }
        },
        selectable_results() {
            // just the selectable rows from results
            return this.provider_results
                .map((s) => {
                    return s.results;
                })
                .flat()
                .filter((x) => x);
        },
        prompt() {
            if (this.active_command) {
                return this.active_command.command_props.prompt;
            }
            return 'What would you like to do?';
        },
        breadcrumbs() {
            // display labels of provided data in order
            const data = Object.values(this.active_data);
            return data.map((d) => d.label);
        },
        show_back() {
            return this.data_stack.length > 1;
        },
        selected_item() {
            if (!this.selectable_results.length) return null;
            if (this.selectable_results.length === 1)
                return this.selectable_results[0];
            if (this.selected_index < 0) return null;
            return this.selectable_results[this.selected_index];
        },
    },
    watch: {
        initial_data: {
            handler(val) {
                this.resetDataStack();
                this.getProviderResults(true);
            },
            immediate: true,
        },
        search_string: {
            handler() {
                this.getProviderResults();
                this.selectFirstAvailable();
            },
        },
        showing: {
            handler(val) {
                if (val) {
                    // modal is shown, set focus on search input
                    this.setFocusSearch();
                    this.getProviderResults(true);
                } else {
                    // modal was closed
                    this.reset();
                }
            },
            immediate: true,
        },
        selectable_results: {
            handler(val) {
                this.selectFirstAvailable();

                // Shortcut handler:
                // if only 1 result is shown (not including default option)
                // and you have typed its shortcut, automatically select it
                let options = val;
                if (options.length > 1) {
                    // remove default option from check
                    options = options.filter((i) => i.default_option !== true);
                }
                if (
                    options.length === 1 &&
                    options[0].shortcut === this.search_string
                ) {
                    this.addData(options[0]);
                }
            },
        },
        filtered_providers: {
            handler(newVal, oldVal) {
                // call cleanup on any provider that has been removed from the filtered list
                if (oldVal) {
                    oldVal.forEach((provider) => {
                        if (!newVal.includes(provider)) {
                            provider.cleanup();
                        }
                    });
                }
            },
        },
    },
    mounted() {
        this.getProviderResults();
    },
    methods: {
        reset() {
            this.resetDataStack(true);
            this.resetProviderResults();
            this.custom_component = null;
            this.search_string = null;
            this.selected_index = -1;
            this.loading = false;
        },
        resetProviderResults() {
            this.provider_results = [];
        },
        selectFirstAvailable() {
            if (this.selectable_results?.length > 0) {
                this.selected_index = 0;
            } else {
                this.selected_index = -1;
            }
            this.$nextTick(() => {
                if (this.selected_item) {
                    this.$refs[this.selected_item.id][0]?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            });
        },
        setFocusSearch(clear_search = true) {
            // reset search string and set focus
            if (clear_search) {
                this.search_string = null;
            }
            this.selected_index = -1;
            this.$nextTick(() => {
                this.$refs.search?.focus();
            });
        },
        /**
         * Filter providers by the supplied dependencies (and also recursively include filteredBy providers)
         * @param dependencies
         * @param filtered
         * @returns {*[]}
         */
        filterProviders(dependencies, filtered = []) {
            for (const provider of this.providers) {
                // skip this provider if it is already in the filtered list
                if (!filtered.some((p) => p.provides === provider.provides)) {
                    if (dependencies.includes(provider.provides)) {
                        filtered.push(provider);
                        if (
                            provider.filteredBy &&
                            provider.filteredBy.length > 0
                        ) {
                            // if this provider can be filtered by other providers, include them too
                            filtered = this.filterProviders(
                                provider.filteredBy,
                                filtered
                            );
                        }
                    }
                }
            }
            return filtered;
        },
        getProviderResults(reset = false) {
            if (reset) {
                this.resetProviderResults();
            }
            // aggregated results for all providers

            // initially, set provider list with empty results to display loading state
            this.provider_results = this.filtered_providers.map((provider) => ({
                label: provider.label,
                provides: provider.provides,
            }));

            this.fetching = true;

            // fetch results for each provider
            const promises = [];
            for (const provider of this.filtered_providers) {
                promises.push(
                    provider
                        .getResults(this.active_data, this.search_string)
                        .then((results) => {
                            const index = this.provider_results.findIndex(
                                (prov) => prov.provides === provider.provides
                            );
                            if (index >= 0) {
                                const pending_provider = this.provider_results[
                                    index
                                ];
                                // results is returned as null to indicate a provider potentially has results,
                                // but will only provide them when a filter or refining data is set
                                if (results === null || results.length > 0) {
                                    // inject provided type into result rows
                                    if (results !== null) {
                                        results = results.map((result) => ({
                                            ...result,
                                            provides: provider.provides,
                                        }));
                                    }
                                    Vue.set(this.provider_results, index, {
                                        ...pending_provider,
                                        results,
                                    });
                                }
                            }
                            return true;
                        })
                );
            }
            Promise.all(promises).then(() => {
                this.fetching = false;
            });
        },
        addData(item = null) {
            if (this.loading || (!this.selected_item && !item)) return;
            const selectedItem = item || this.selected_item;
            this.data_stack.push({
                ...this.active_data,
                [selectedItem.provides]: selectedItem,
            });
            this.setFocusSearch(selectedItem?.clear_search !== false ?? true);
            this.getProviderResults();
        },
        changeCommand(command) {
            this.addData({command});
        },
        resetDataStack(full_reset = false) {
            const resetData = full_reset ? {} : this.initial_data || {};
            this.data_stack = [resetData];
        },
        handleBackspacePress() {
            if (this.search_string === null) this.handleGoBack();
            if (this.search_string === '') this.search_string = null;
        },
        handleGoBack() {
            this.custom_component = null;
            // step back through data stack, or close at root
            if (this.data_stack.length > 1) {
                this.data_stack.splice(-1);
                this.setFocusSearch();
                this.getProviderResults();
            } else {
                this.handleClose();
            }
        },
        handleClose() {
            this.reset();
            this.$emit('close');
        },
        setLoading(message) {
            this.loading = message;
        },
        showCustomComponent(component) {
            this.custom_component = component;
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .search {
        background: transparent;
        width: 100%;

        ::v-deep .el-input__inner {
            background-color: rgba($white, 0.05);
            border: none;
        }
    }

    .breadcrumbs {
        font-size: 12px;
        opacity: 0.7;
        height: 12px;

        .breadcrumb-arrow {
            width: 8px;
            height: 8px;
            margin: 0 5px;
            opacity: 0.5;

            ::v-deep path {
                fill: $white;
            }
        }
    }
}
.body {
    ul.results {
        &,
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .provider {
            > .label {
                display: block;
                padding: 10px 20px 2px;
                font-size: 12px;
                border-top: 1px solid $border-grey;
            }

            > .info {
                display: block;
                padding: 0 20px 10px;
            }

            .result {
                cursor: pointer;
                padding: 10px 20px;
                min-height: 40px;
                display: flex;
                flex-direction: row;
                align-items: center;

                span.label {
                    color: $black;
                    font-size: 14px;
                    display: block;
                    flex: 1;
                    padding-right: 10px;
                }

                .shortcut {
                    font-family: monospace;
                    margin-right: 10px;
                }

                span.sub-label {
                    opacity: 0.2;
                    font-family: 'Rubik', sans-serif;
                    font-size: 12px;
                }

                &.selected {
                    box-shadow: inset -4px 0px 0px 0px $blue;
                    background-color: rgba($blue, 0.05);
                }

                &:hover {
                    box-shadow: inset -4px 0px 0px 0px $orange;
                    background-color: rgba($orange, 0.05);
                }
            }
        }
    }
    .no-results {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
