<template>
    <project-invoice-report
        report_name="Cashflow Report"
        :report_query="getCashflowReport"
        :year_mode="year_mode"
        :total_mode="total_mode"
    />
</template>

<script>
import ProjectInvoiceReport from '@/pages/reports/components/ProjectInvoiceReport';
import {getCashflowReport} from '@/api/reports';

export default {
    name: 'report-cashflow',
    methods: {getCashflowReport},
    components: {
        ProjectInvoiceReport,
    },
    props: {
        year_mode: {
            type: String,
            required: true,
        },
        total_mode: {
            type: String,
            required: true,
        },
    },
};
</script>
