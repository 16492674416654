<template>
    <el-form
        ref="clientForm"
        label-width="160px"
        :model="client"
        :rules="rules"
        class="form-wrapper"
        @submit.native.prevent
    >
        <!-- Client name -->
        <el-form-item label="Name" prop="name">
            <el-input v-model="client.name" :readonly="readonly">
                <el-button
                    slot="append"
                    v-clipboard:copy="client.name"
                    class="copy-button"
                    size="small"
                    icon="el-icon-document-copy"
                    @click.native="showNotification('Name')"
                />
            </el-input>
        </el-form-item>
        <el-form-item
            v-if="!readonly || client.tax_number"
            label="ABN"
            prop="tax_number"
        >
            <el-input v-model="client.tax_number" :readonly="readonly">
                <el-button
                    slot="append"
                    v-clipboard:copy="client.tax_number"
                    class="copy-button"
                    size="small"
                    icon="el-icon-document-copy"
                    @click.native="showNotification('ABN')"
                />
            </el-input>
        </el-form-item>

        <!-- Primary contact -->
        <hr v-if="!readonly || client.first_name || client.email" />
        <el-form-item
            v-if="!readonly || client.first_name"
            label="Primary person"
        >
            <el-input
                v-model="client.first_name"
                class="half"
                placeholder="First name"
                :readonly="readonly"
            />
            <el-input
                v-model="client.last_name"
                class="half"
                placeholder="Last name"
                :readonly="readonly"
            >
                <el-button
                    slot="append"
                    v-clipboard:copy="
                        `${client.first_name} ${
                            client.last_name ? client.last_name : ''
                        }`
                    "
                    class="copy-button"
                    size="small"
                    icon="el-icon-document-copy"
                    @click.native="showNotification('Contact')"
                />
            </el-input>
        </el-form-item>
        <el-form-item
            v-if="!readonly || client.email"
            label="Email"
            prop="email"
        >
            <el-input
                v-model="client.email"
                :readonly="readonly"
                suffix-icon="el-icon-message"
            >
                <el-button
                    slot="append"
                    v-clipboard:copy="client.email"
                    class="copy-button"
                    size="small"
                    icon="el-icon-document-copy"
                    @click.native="showNotification('Email')"
                />
            </el-input>
        </el-form-item>

        <!-- Additional contacts -->
        <template v-if="!readonly || client.additional_contacts?.length">
            <template v-for="(contact, index) of client.additional_contacts">
                <hr />
                <el-form-item :key="`${index}_name`" label="Additional person">
                    <el-input
                        v-model="contact.first_name"
                        class="half"
                        placeholder="First name"
                        :readonly="readonly"
                    />
                    <el-input
                        v-model="contact.last_name"
                        class="half"
                        placeholder="Last name"
                        :readonly="readonly"
                    >
                        <el-button
                            slot="append"
                            v-clipboard:copy="
                                `${contact.first_name} ${
                                    contact.last_name ? contact.last_name : ''
                                }`
                            "
                            class="copy-button"
                            size="small"
                            icon="el-icon-document-copy"
                            @click.native="showNotification('Contact')"
                        />
                    </el-input>
                </el-form-item>
                <el-form-item
                    :key="`${index}_email`"
                    label="Email"
                    prop="email"
                    class="additional_email"
                >
                    <el-input
                        v-model="contact.email"
                        :readonly="readonly"
                        :suffix-icon="
                            readonly && contact.include_in_emails
                                ? 'el-icon-message'
                                : null
                        "
                    >
                        <el-button
                            slot="append"
                            v-clipboard:copy="contact.email"
                            class="copy-button"
                            size="small"
                            icon="el-icon-document-copy"
                            @click.native="showNotification('Email')"
                        />
                    </el-input>
                </el-form-item>
            </template>
            <div style="text-align: right;">
                <el-button
                    v-if="!readonly"
                    size="mini"
                    @click="handleAddContact"
                >
                    Add another contact
                </el-button>
            </div>
        </template>

        <!-- Addresses -->
        <hr v-if="!readonly || addresses.length" />
        <el-form-item v-if="!readonly || addresses.length" label="Address">
            <div v-for="(a, index) in addresses" :key="`address_${index}`">
                <el-input
                    v-model="a.address"
                    :readonly="readonly"
                    placeholder="Street address"
                >
                    <el-button
                        slot="append"
                        v-clipboard:copy="
                            `${a.address}, ${a.city}, ${a.country}`
                        "
                        class="copy-button"
                        size="small"
                        icon="el-icon-document-copy"
                        @click.native="showNotification('Address')"
                    />
                </el-input>
                <el-input
                    v-model="a.city"
                    :readonly="readonly"
                    placeholder="City"
                />
                <el-input
                    v-model="a.country"
                    :readonly="readonly"
                    placeholder="Country"
                />
            </div>
        </el-form-item>

        <el-button class="submit" :loading="loading" @click="$emit('submit')">
            Create
        </el-button>
    </el-form>
</template>

<script>
import {Notification} from 'element-ui';

export default {
    name: 'client-form',
    props: {
        client: {
            type: Object,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        clients() {
            return this.$store.getters.clients;
        },
        addresses() {
            if (this.client.addresses) {
                return this.client.addresses.filter(
                    (a) => !a.type || a.type === 'POBOX' // POBOX is billing address
                );
            }
            return [{}];
        },
        rules() {
            if (this.readonly) return {};
            return {
                name: [
                    {
                        required: true,
                        message: 'Client must have a name',
                        trigger: 'blur',
                    },
                    {
                        validator: this.validateName,
                        trigger: 'blur',
                    },
                ],
                tax_number: [
                    {
                        validator: this.validateABN,
                        trigger: 'blur',
                    },
                ],
                email: [
                    {
                        required: true,
                        type: 'email',
                        message: 'Must be a valid email address',
                        trigger: 'blur',
                    },
                ],
            };
        },
    },
    methods: {
        showNotification(key) {
            Notification({
                type: 'success',
                title: 'Copied',
                message: `${key} copied to clipboard.`,
            });
        },
        validate(callback) {
            this.$refs['clientForm'].validate(callback);
        },
        validateName(rule, value, callback) {
            // check if another client already exists with this name
            if (this.clients.find((c) => c.name === value)) {
                callback('A client with this name already exists');
            }
            callback();
        },
        validateABN(rule, value, callback) {
            if (value) {
                // check if another client already exists with this ABN
                if (
                    this.clients.find((c) => {
                        // match ABN regardless of whitespace in strings
                        const clientABN = c.tax_number?.replace(/\s/g, '');
                        const formABN = value.replace(/\s/g, '');
                        return clientABN === formABN;
                    })
                ) {
                    callback('A client with this ABN already exists');
                }
            }
            callback();
        },
        handleAddContact() {
            if (!this.client.additional_contacts) {
                this.client.additional_contacts = [];
            }
            this.client.additional_contacts.push({
                first_name: '',
                last_name: '',
                email: '',
                include_in_emails: false,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.el-form {
    display: flow-root;
    margin-top: 20px;

    hr {
        margin: 20px 50px;
        border: none;
        height: 1px;
        background-color: $border-grey-light;
    }

    ::v-deep .el-input {
        .el-input__inner {
            cursor: pointer;

            &:hover {
                border-color: $blue-light;
            }
        }
        .el-input-group__append {
            min-width: 0;
        }
    }

    .el-input + .el-input {
        margin-top: 5px;
    }
    .el-input.half {
        width: 50%;
    }
    .el-input.half + .el-input.half,
    .el-input.half + span.half {
        width: calc(50% - 5px);
        margin-top: 0;
        margin-left: 5px;
    }
    span.half {
        display: inline-block;
    }
    span.right {
        text-align: right;
    }
    .copy-button {
        &:focus {
            color: $blue;
        }
    }
    .additional_email {
        ::v-deep .el-form-item__content {
            display: flex;
            flex-direction: row;
            .el-input {
                flex: 1;
            }
            span {
                margin-left: 10px;
            }
        }
    }

    .el-button.submit {
        margin-top: 20px;
        width: 200px;
        float: right;
    }

    @media screen and (max-width: 992px) {
        ::v-deep {
            .el-form-item {
                display: flex;
                flex-direction: column;

                &__label {
                    text-align: left;
                    width: 100% !important;
                }

                &__content {
                    margin-left: 0 !important;
                }
            }
        }
    }
}
</style>
