/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'confluence': {
    width: 62.848,
    height: 60.389,
    viewBox: '0 0 62.848 60.389',
    data: '<defs id="defs841"><linearGradient gradientUnits="userSpaceOnUse" y2="45.05" x2="20.35" y1="67.65" x1="59.68" id="linear-gradient"><stop id="stop835" stop-color="#0052cc" offset=".18"/><stop id="stop837" stop-color="#2684ff" offset="1"/></linearGradient><linearGradient xlink:href="#linear-gradient" gradientTransform="rotate(180 141.415 -808.355)" y2="-1638.95" x2="240.42" y1="-1616.34" x1="279.76" id="linear-gradient-2"/><linearGradient y2="45.05" x2="20.35" y1="67.65" x1="59.68" gradientUnits="userSpaceOnUse" id="linearGradient877" xlink:href="#linear-gradient"/></defs><g transform="translate(.049 -3.429)" id="g906"><path pid="0" d="M2.23 49.53c-.65 1.06-1.38 2.29-2 3.27a2 2 0 00.67 2.72l13 8a2 2 0 002.77-.68c.52-.87 1.19-2 1.92-3.21 5.15-8.5 10.33-7.46 19.67-3l12.89 6.13a2 2 0 002.69-1l6.19-14a2 2 0 00-1-2.62c-2.72-1.28-8.13-3.83-13-6.18C28.51 30.45 13.62 31 2.23 49.53z" id="path865" _fill="url(#linearGradient877)"/><path pid="1" d="M60.52 17.76c.65-1.06 1.38-2.29 2-3.27a2 2 0 00-.67-2.72l-13-8a2 2 0 00-2.85.66c-.52.87-1.19 2-1.92 3.21-5.15 8.5-10.33 7.46-19.67 3l-12.85-6.1a2 2 0 00-2.69 1l-6.19 14a2 2 0 001 2.62c2.72 1.28 8.13 3.83 13 6.18 17.56 8.5 32.45 7.93 43.84-10.58z" id="path867" _fill="url(#linear-gradient-2)"/></g>'
  }
})
