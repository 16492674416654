<template>
    <span class="priority" :class="{show: show}">
        {{ priority ? priority : null }}
    </span>
</template>

<script>
export default {
    name: 'todo-priority',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        priority: {
            type: Number,
            default: null,
        },
    },
};
</script>
<style lang="scss" scoped>
.priority {
    align-self: center;
    background-color: $black;

    display: inline-block;

    text-align: center;
    padding: 2px 5px;

    border-radius: 5px;

    font-weight: 600;

    opacity: 0;

    font-size: 11px;

    margin-left: -34px;

    width: 24px;

    margin-right: 0;
    margin-bottom: 2px;
    user-select: none;

    color: $black;

    transition: all 0.2s ease-in-out;

    &.show {
        opacity: 1;
        margin-left: 0px;
        margin-right: 8px;
        color: $white;
    }
}
</style>
