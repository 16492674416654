<template>
    <component :is="component" :event="event" :event_types="event_types" />
</template>

<script>
import TimelineCalendarItem from './TimelineCalendarItem';
import TimelineMilestoneItem from './TimelineMilestoneItem';
import TimelineStatusItem from './TimelineStatusItem';

export default {
    name: 'timeline-item',
    props: {
        event: {
            type: Object,
            required: true,
        },
        event_types: {
            type: Array,
            required: true,
        },
    },
    computed: {
        component() {
            switch (this.type_value) {
                case 'calendar':
                    return TimelineCalendarItem;
                case 'milestone':
                    return TimelineMilestoneItem;
                case 'status':
                    return TimelineStatusItem;
                default:
                    return null;
            }
        },
        type() {
            if (this.event.type) {
                return this.event_types.find(
                    (type) =>
                        `system/events/types/${type.id}` === this.event.type
                );
            }
            return null;
        },
        type_value() {
            if (this.type) return this.type.type;
            if (this.event.event_type) return this.event.event_type;

            return 'none';
        },
    },
};
</script>

<style lang="scss" scoped>
.status {
    .content {
        margin-bottom: 10px;
    }

    .info {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .time {
            margin-left: 10px;
            font-size: 12px;
            color: rgba($black, 0.6);
        }
    }
}

.milestone {
    label {
        margin: 0;
    }

    p {
        margin: 0;
        margin-top: 10px;
        font-size: 12px;
        color: rgba($black, 0.6);
    }
}
</style>
