var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      class: { paid: _vm.is_paid },
      on: {
        click: [
          function ($event) {
            if (
              $event.ctrlKey ||
              $event.shiftKey ||
              $event.altKey ||
              $event.metaKey
            )
              return null
            return _vm.navigateToInvoice(_vm.invoice)
          },
          function ($event) {
            if (!$event.metaKey) return null
            if ($event.ctrlKey || $event.shiftKey || $event.altKey) return null
            return _vm.navigateToInvoice(_vm.invoice, true)
          },
        ],
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 12 } },
        [
          _c("el-col", { attrs: { lg: 16 } }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "el-tag",
                  {
                    staticClass: "number",
                    attrs: {
                      type: _vm.is_new
                        ? "danger"
                        : _vm.is_updated
                        ? "default"
                        : "info",
                      "disable-transitions": true,
                      size: "small",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.invoice.invoice_number) + " ")]
                ),
                _c(
                  "span",
                  { staticClass: "ref" },
                  [
                    _vm.invoice.reference
                      ? [
                          _c("span", { staticClass: "ref__slash" }, [
                            _vm._v("/"),
                          ]),
                          _c("strong", [_vm._v(_vm._s(_vm.invoice.reference))]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("span", { staticClass: "date" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("dateformat")(_vm.invoice.date, "DD/MM/YYYY")
                      ) +
                      " "
                  ),
                ]),
                _c("span", { staticClass: "total" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.invoice.sub_total)) +
                      " "
                  ),
                  _c("span", { staticClass: "small" }, [_vm._v("(ex. GST)")]),
                ]),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "row right" }, [
              _c(
                "span",
                { staticClass: "due" },
                [
                  _vm.is_paid
                    ? [
                        _vm._v(" Paid "),
                        _vm.invoice.fully_paid_on_date
                          ? _c("span", [
                              _vm._v(
                                " : " +
                                  _vm._s(
                                    _vm._f("dateformat")(
                                      _vm.invoice.fully_paid_on_date,
                                      "DD/MM/YYYY"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm.invoice.due_date
                    ? [
                        _vm._v(
                          " Due: " +
                            _vm._s(
                              _vm._f("dateformat")(
                                _vm.invoice.due_date,
                                "DD/MM/YYYY"
                              )
                            ) +
                            " "
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "row tooltip-container",
                  staticStyle: { flex: "1", "justify-content": "flex-end" },
                },
                [
                  !_vm.is_linked
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            "open-delay": 200,
                            content: "Needs linking",
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "badge" },
                            [
                              _c("svgicon", {
                                staticClass: "icon red",
                                attrs: { name: "invoice_linking" },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.is_sent
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            "open-delay": 200,
                            content: "Sent to client",
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "badge" },
                            [
                              _c("svgicon", {
                                staticClass: "icon",
                                attrs: { name: "invoice_sent" },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "Open in XERO",
                        placement: "top",
                        "open-delay": 200,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "badge" },
                        [
                          _c("svgicon", {
                            staticClass: "icon",
                            attrs: { name: "invoice_share" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }