<template>
    <div>
        <div class="timeline-container">
            <el-timeline>
                <timeline-item
                    v-for="event in events"
                    :key="event.id"
                    :event="event"
                    :event_types="event_types"
                />
            </el-timeline>
        </div>
        <div v-if="!eventsLoaded" class="load-more" @click="loadEvents">
            <span>Load more events</span>
        </div>
    </div>
</template>

<script>
import {momentWithTz} from '@/utils';

import TimelineItem from './TimelineItem';

export default {
    name: 'project-events',
    components: {TimelineItem},
    props: {
        current_milestone: {
            type: Object,
            default: () => null,
        },
        calendar: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            eventsLoaded: false,
            otherEvents: [],
        };
    },
    computed: {
        event_types() {
            return this.$store.getters.eventTypes();
        },
        calendar_events() {
            const date = momentWithTz().startOf('day').add(2, 'day').toDate();
            return this.calendar.reduce((acc, cur) => {
                if (cur.start.toDate() < date) {
                    return acc;
                }

                if (cur.recurringEventId !== null) {
                    const recurring_exists = acc.find(
                        (c) => c.recurringEventId === cur.recurringEventId
                    );

                    if (recurring_exists) {
                        if (
                            recurring_exists.start.toDate() > cur.start.toDate()
                        ) {
                            acc.push({
                                ...cur,
                                id: cur.id,
                                event_type: 'calendar',
                            });

                            return acc.filter(
                                (a) => a.id === recurring_exists.id
                            );
                        }

                        return acc;
                    }
                }
                acc.push({...cur, id: cur.id, event_type: 'calendar'});

                return acc;
            }, []);
        },
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        events() {
            const evs = [
                ...(this.eventsLoaded
                    ? this.otherEvents
                    : this.$store.getters.eventsForProjectWithId(
                          this.$route.params.project_id
                      )),
                ...this.calendar_events,
            ].filter((ev) => {
                if (
                    this.current_milestone &&
                    this.current_milestone.id === ev.id
                ) {
                    return false;
                }

                if (
                    this.project.current_status &&
                    this.project.current_status === `events/${ev.id}`
                ) {
                    return false;
                }

                return true;
            });

            return evs.slice(0).sort((a, b) => {
                const date1 = a.start || a.created_at;
                const date2 = b.start || b.created_at;
                if (date1.toDate() < date2.toDate()) return 1;
                if (date2.toDate() < date1.toDate()) return -1;
                return 0;
            });
        },
    },
    methods: {
        loadEvents() {
            this.$bind(
                'otherEvents',
                this.$fire
                    .collection('events')
                    .where(
                        'project',
                        '==',
                        this.$fire.doc(
                            `projects/${this.$route.params.project_id}`
                        )
                    ),
                {maxRefDepth: 0}
            );

            this.eventsLoaded = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.timeline-container {
    padding: 30px 20px 5px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .el-timeline ::v-deep {
        padding-left: 23px;
    }
}
.load-more {
    cursor: pointer;
    margin: 0 10px;
    box-sizing: border-box;
    padding: 20px 0;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $border-grey-light;

    span {
        font-size: 12px;
        color: $black;
        opacity: 0.5;
        transition: all 0.2s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
}
</style>
