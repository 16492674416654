var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.has_links
    ? _c(
        "div",
        {
          staticClass: "ref-links",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom",
                trigger: "click",
                "popper-class": "options-popover",
              },
              model: {
                value: _vm.linksVisible,
                callback: function ($$v) {
                  _vm.linksVisible = $$v
                },
                expression: "linksVisible",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "icon",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [_c("i", { staticClass: "el-icon-link" })]
              ),
              _vm._l(_vm.links, function (link, index) {
                return _c("ref-link-button", {
                  key: `${link.key}_${index}`,
                  attrs: { link_key: link.key, link_value: link.value },
                })
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }