import moment from 'moment';

const componentTimelineMixin = {
    methods: {
        calculateComponentStartDate(component) {
            if (component.depends_on_stage) {
                const stage = this.$store.getters.stageWithId(component.stage);
                return this.calculateStageStartDate(stage);
            }
            if (component.depends_on) {
                const c = this.$store.getters.componentWithId(
                    component.depends_on
                );

                if (!c) return component.start_date.toDate();

                return this.calculateComponentEndDate(c);
            } else if (component.start_date) {
                return component.start_date.toDate();
            } else if (!component.timeframe_weeks) return null;

            const stage = this.$store.getters.stageWithId(component.stage);

            return this.calculateStageStartDate(stage);
        },

        calculateComponentEndDate(component) {
            const start = this.calculateComponentStartDate(component);

            if (component.status === 'completed' && component.completed_date) {
                return component.completed_date.toDate();
            }

            const date = moment(start).add(component.timeframe_weeks, 'weeks');

            if (date < moment()) return moment().toDate();

            return date.toDate();
        },

        calculateStageStartDate(stage) {
            if (stage.depends_on) {
                const c = this.$store.getters.componentWithId(stage.depends_on);

                if (!c) {
                    return stage.start_date.toDate();
                }

                return this.calculateComponentEndDate(c);
            } else if (stage.start_date) {
                return stage.start_date.toDate();
            }

            return null;
        },

        doesStageHaveTimelineComponents(stage) {
            return this.getStageComponentDates(stage).length > 0;
        },

        getStageFirstDate(stage) {
            const start = this.calculateStageStartDate(stage);

            if (start) return start;

            const dates = this.getStageComponentDates(stage);

            if (dates.length > 0) return dates[0];

            return null;
        },

        getStageComponentDates(stage) {
            if (stage.components) {
                const components = this.$store.getters.componentsForStageWithId(
                    stage.id
                );

                const dates = components
                    .map((component) => this.calculateStageStartDate(component))
                    .filter((date) => !!date)
                    .sort();

                if (dates.length > 0) return dates;
            }

            return [];
        },

        canComponentBeDependedOn(component) {
            return (
                (component.depends_on || component.start_date) &&
                component.timeframe_weeks
            );
        },
    },
};

export default componentTimelineMixin;
