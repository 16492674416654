var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h4", [_vm._v("Details:")]),
    _c("div", { staticClass: "container" }, [
      _c("label", [_vm._v("Logo")]),
      _c(
        "div",
        { staticClass: "row", staticStyle: { width: "80%" } },
        [
          _c("file-uploader", {
            staticStyle: { "margin-bottom": "-5px" },
            attrs: { folder: "organisation" },
            on: { uploaded: (val) => _vm.update("logo", val) },
          }),
          _c("img", {
            staticClass: "logo",
            attrs: { src: _vm.$store.state.organisation.logo },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "container description" }, [
      _c("p", [
        _vm._v(
          " Adjust these fields to customize how your organization's information appears in various areas, ensuring consistency and accuracy across all outputs. "
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("label", [_vm._v("Name")]),
              _c("el-input", {
                attrs: { size: "medium" },
                on: { change: (val) => _vm.update("name", val) },
                model: {
                  value: _vm.$store.state.organisation.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.$store.state.organisation, "name", $$v)
                  },
                  expression: "$store.state.organisation.name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("label", [_vm._v("Email")]),
              _c("el-input", {
                attrs: { size: "medium" },
                on: { change: (val) => _vm.update("email", val) },
                model: {
                  value: _vm.$store.state.organisation.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.$store.state.organisation, "email", $$v)
                  },
                  expression: "$store.state.organisation.email",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("label", [_vm._v("Phone")]),
              _c("el-input", {
                attrs: { size: "medium" },
                on: { change: (val) => _vm.update("phone", val) },
                model: {
                  value: _vm.$store.state.organisation.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.$store.state.organisation, "phone", $$v)
                  },
                  expression: "$store.state.organisation.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("label", [_vm._v("Address")]),
              _c("el-input", {
                attrs: { size: "medium" },
                on: { change: (val) => _vm.update("address", val) },
                model: {
                  value: _vm.$store.state.organisation.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.$store.state.organisation, "address", $$v)
                  },
                  expression: "$store.state.organisation.address",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("label", [_vm._v("Time zone")]),
        _c("div", { staticStyle: { width: "80%" } }, [
          _vm._v(" " + _vm._s(_vm.timezone) + " "),
          _vm._m(0),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("label", [_vm._v("Color")]),
        _c("color-picker", {
          attrs: { value: _vm.color, show_dialog: _vm.show_dialog },
          on: { input: (val) => _vm.update("color", val) },
        }),
      ],
      1
    ),
    _c("hr"),
    _c("h4", [_vm._v("Emails:")]),
    _c("div", { staticClass: "container description" }, [
      _c("p", [
        _vm._v(
          " Configure these fields to ensure that automated emails sent from the platform are properly addressed, helping to manage responses and maintain organized communication channels. "
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("label", [_vm._v("General")]),
              _c("el-input", {
                attrs: {
                  placeholder: `no-reply@${_vm.email_domain}`,
                  size: "medium",
                },
                on: { change: (val) => _vm.update("sender_general", val) },
                model: {
                  value: _vm.$store.state.organisation.replyto_general,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$store.state.organisation,
                      "replyto_general",
                      $$v
                    )
                  },
                  expression: "$store.state.organisation.replyto_general",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("label", [_vm._v("Invoices")]),
              _c("el-input", {
                attrs: {
                  placeholder:
                    _vm.$store.state.organisation.replyto_general ||
                    `no-reply@${_vm.email_domain}`,
                  size: "medium",
                },
                on: { change: (val) => _vm.update("sender_invoice", val) },
                model: {
                  value: _vm.$store.state.organisation.replyto_invoice,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$store.state.organisation,
                      "replyto_invoice",
                      $$v
                    )
                  },
                  expression: "$store.state.organisation.replyto_invoice",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("h4", [_vm._v("Documents:")]),
    _c("div", { staticClass: "container description" }, [
      _c("p", [
        _vm._v(
          " Adjust these settings to tailor the content and appearance of documents created by the platform. "
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("label", [_vm._v("Legal name")]),
              _c("el-input", {
                attrs: {
                  placeholder:
                    _vm.$store.state.organisation.name ||
                    "Legal name of business entity",
                  size: "medium",
                },
                on: { change: (val) => _vm.update("legal_name", val) },
                model: {
                  value: _vm.$store.state.organisation.legal_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.$store.state.organisation, "legal_name", $$v)
                  },
                  expression: "$store.state.organisation.legal_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("label", [_vm._v("Agreement")]),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder: "Estimate agreement clause",
                  size: "medium",
                },
                on: { change: (val) => _vm.update("agreement_clause", val) },
                model: {
                  value: _vm.$store.state.organisation.agreement_clause,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$store.state.organisation,
                      "agreement_clause",
                      $$v
                    )
                  },
                  expression: "$store.state.organisation.agreement_clause",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("label", [_vm._v("Footer")]),
              _c("el-input", {
                attrs: { placeholder: "None", size: "medium" },
                on: { change: (val) => _vm.update("doc_footer", val) },
                model: {
                  value: _vm.$store.state.organisation.doc_footer,
                  callback: function ($$v) {
                    _vm.$set(_vm.$store.state.organisation, "doc_footer", $$v)
                  },
                  expression: "$store.state.organisation.doc_footer",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "p",
      { staticStyle: { "margin-top": "5px", "margin-bottom": "0" } },
      [
        _vm._v(
          " Determines day boundaries for all operations in your organisation."
        ),
        _c("br"),
        _vm._v(
          " If you require changes to your organisation's time zone, please contact your administrator. "
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }