import {
    DOCUMENT_BLOCK_TYPES,
    DOCUMENT_GROUP_DETAIL,
    DOCUMENT_VALUES,
} from '@/enums';
import {generateUUID} from '@/utils/firebase';
import {fireRef2id} from '@/filters';

const documentMixin = {
    computed: {
        documentTypeCollection() {
            // collections are plural form of document type
            // handle any special cases here
            if (this.documentType.slice(-1) === 'y') {
                //y -> ies
                return `${this.documentType.slice(0, -1)}ies`;
            }
            // other -> others
            return `${this.documentType}s`;
        },
    },
    methods: {
        sortTemplates(list) {
            const sortedList = [];

            // map IDs in cloned list
            list.forEach((t) => {
                sortedList.push({...t, id: t.id});
            });

            return sortedList.sort((a, b) => {
                // sort templates by sort order (if an explicit order has been set), otherwise alphabetically
                let sort = (a.sort ?? 0) - (b.sort ?? 0);
                if (sort === 0) {
                    sort = a.name.localeCompare(b.name);
                }
                return sort;
            });
        },
        async updateDocumentEstimateGroups(estimate, groups) {
            // check if any documents exist for this estimate, and update the group blocks if required:
            //  - remove any groups from the doc that have been removed from the estimate
            //  - add any missing groups immediately after the last doc group
            //  - added groups inherit the settings from the last doc group

            const docSnapshots = await this.$fire
                .collection('documents')
                .where(
                    'linked_ref',
                    '==',
                    this.$fire.doc(`estimates/${estimate.id}`)
                )
                .get();

            for (const doc of docSnapshots.docs) {
                const processedGroups = [];
                const readBlocks = doc.get('blocks');
                const writeBlocks = [];
                let lastGroupBlock = null;
                let lastGroupBlockIndex = null;

                // copy document blocks, excluding groups missing from estimate
                readBlocks.forEach((block) => {
                    if (block.type === DOCUMENT_BLOCK_TYPES.ESTIMATE_GROUP) {
                        lastGroupBlock = block;
                        lastGroupBlockIndex = writeBlocks.length;
                        const group = groups.find(
                            (g) => g.id === fireRef2id(block.group)
                        );
                        if (group) {
                            processedGroups.push(group.id);
                        } else {
                            return;
                        }
                    }
                    writeBlocks.push(block);
                });

                if (lastGroupBlockIndex === null)
                    lastGroupBlockIndex = writeBlocks.length;

                // add any new groups
                groups.forEach((group) => {
                    if (!processedGroups.includes(group.id)) {
                        writeBlocks.splice(lastGroupBlockIndex + 1, 0, {
                            id: generateUUID(),
                            type: DOCUMENT_BLOCK_TYPES.ESTIMATE_GROUP,
                            group: this.$fire.doc(
                                `estimate_groups/${group.id}`
                            ),
                            values: lastGroupBlock?.values ?? [],
                        });
                    }
                });

                await doc.ref.update({blocks: writeBlocks});
            }
        },
        sortGroupsInDocumentOrder(groups, document) {
            const result = [];
            document.blocks.forEach((block) => {
                if (block.type === DOCUMENT_BLOCK_TYPES.ESTIMATE_GROUP) {
                    const block_group_id = fireRef2id(block.group);
                    const group = groups.find((g) => g.id === block_group_id);
                    if (group) result.push(group);
                }
            });
            return result;
        },
    },
};

export default documentMixin;
