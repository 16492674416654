<template>
    <div class="year-selector">
        <div class="year-display">{{ year_label }}</div>
        <div class="nav" @click="handleNavigate(-1)">
            <i class="el-icon-caret-left" />
        </div>
        <div class="nav" @click="handleNavigate(1)">
            <i class="el-icon-caret-right" />
        </div>
    </div>
</template>

<script>
import userMixin from '@/mixins/user.mixin';
import PlannerYearlyView from '@/components/planner/PlannerYearlyView';
import {getPipelineReport} from '@/api/reports';
import {momentWithTz} from '@/utils';
import FullscreenLoader from '@/components/generic/FullscreenLoader.vue';

export default {
    name: 'financial-year-selector',
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
    computed: {
        year_label() {
            const prev_year = this.value - 1;
            const this_year = this.value.toString().substring(2);
            return `FY ${prev_year}/${this_year}`;
        },
    },
    methods: {
        handleNavigate(step) {
            this.$emit('input', this.value + step);
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.year-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    .year-display {
        flex: 1;
    }
    .nav {
        color: rgba($black, 0.5);
        padding: 0 5px;
        cursor: pointer;
        &:hover {
            color: $black;
        }
    }
}
</style>
