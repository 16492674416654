<template>
    <div v-only-admin>
        <content-block title="Settings">
            <el-form label-width="160px" @submit.native.prevent>
                <!-- NOTES -->
                <!--*******************************************-->

                <el-form-item label="Notes">
                    <el-input
                        v-model="stage.notes"
                        type="textarea"
                        :rows="2"
                        @change="(v) => updateStage('notes', v)"
                    />
                </el-form-item>

                <!-- STATUS -->
                <!--*******************************************-->

                <el-form-item label="Status">
                    <el-select
                        v-model="stage.status"
                        placeholder="Select"
                        style="width: 100%;"
                        @change="(v) => updateStage('status', v)"
                    >
                        <el-option
                            v-for="item in status_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>

                <hr />

                <!-- BUDGET -->
                <!--*******************************************-->

                <el-form-item label="Budget">
                    <span class="budget">
                        <strong>{{ stage.budget | currency }}</strong>
                    </span>

                    <el-slider
                        v-model="stage.budget"
                        :min="1000"
                        :max="100000"
                        :step="1000"
                        :format-tooltip="budgetTooltip"
                        @change="(v) => updateStage('budget', v)"
                    />
                </el-form-item>

                <!-- HOURLY RATE -->
                <!--*******************************************-->

                <el-form-item label="Hourly rate">
                    <div class="el-input el-input-group el-input-group--append">
                        <money
                            v-model="stage.hourly_rate"
                            v-bind="money"
                            class="el-input__inner"
                            @keyup.native="(v) => updateStage('hourly_rate', v)"
                        />
                        <div class="el-input-group__append">/ hour</div>
                    </div>
                </el-form-item>

                <hr />

                <!-- TIMELINE -->
                <!--*******************************************-->

                <el-form-item label="Start Date">
                    <el-date-picker
                        :value="computed_start_date"
                        type="date"
                        :picker-options="pickerOptions"
                        format="dd/MM/yyyy"
                        @input="(v) => updateStage('start_date', v)"
                    />
                </el-form-item>

                <el-form-item label="End Date">
                    <el-date-picker
                        :value="computed_end_date"
                        type="date"
                        :picker-options="pickerOptions"
                        format="dd/MM/yyyy"
                        @input="(v) => updateStage('end_date', v)"
                    />
                </el-form-item>
            </el-form>
        </content-block>
    </div>
</template>

<script>
import {VMoney} from 'v-money';
import moment from 'moment';
import ContentBlock from '@/components/blocks/ContentBlock';

import {STAGE_STATUS} from '@/enums';

import stageMixin from '@/mixins/stage.mixin';

export default {
    name: 'stages-settings',
    components: {ContentBlock},
    directives: {money: VMoney},
    mixins: [stageMixin],
    data() {
        return {
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
            },
            status_options: [
                {
                    label: 'Active',
                    value: STAGE_STATUS.ACTIVE,
                },
                {
                    label: 'Completed',
                    value: STAGE_STATUS.COMPLETED,
                },
            ],
            pickerOptions: {
                shortcuts: [
                    {
                        text: 'Two weeks',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(moment().add({days: 14}));
                            picker.$emit('pick', date);
                        },
                    },
                    {
                        text: 'One month',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(moment().add({months: 1}));
                            picker.$emit('pick', date);
                        },
                    },
                    {
                        text: 'Two month',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(moment().add({months: 2}));
                            picker.$emit('pick', date);
                        },
                    },
                ],
            },
        };
    },
    computed: {
        stage() {
            return this.$store.getters.stageWithId(this.$route.params.stage_id);
        },
        computed_start_date() {
            if (!this.stage.start_date) return null;
            return this.stage.start_date.toDate();
        },
        computed_end_date() {
            if (!this.stage.end_date) return null;
            return this.stage.end_date.toDate();
        },
    },
    methods: {
        budgetTooltip(val) {
            return this.$options.filters.currency(val);
        },
    },
};
</script>

<style lang="scss" scoped>
.el-form-item {
    .el-input,
    .el-select {
        flex: 1;
        height: 100%;
    }
}

hr {
    margin: 20px 50px;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}
</style>
