<template>
    <div />
</template>

<script>
import TodosCommandLayout from '@/components/command-palette/components/TodosCommandLayout';

export default {
    // command attributes (referenced externally)
    command_props: {
        dependencies: [],
        prompt: '',
    },

    name: 'todos-command',
    components: {},
    props: {
        provided_data: {
            type: Object,
            required: true,
        },
        search_string: {
            type: String,
            required: false,
            default: null,
        },
    },
    mounted() {
        // no dependencies, immediately load Todo command component
        this.$emit('component', TodosCommandLayout);
    },
};
</script>
