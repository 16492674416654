<template>
    <div class="head">
        <el-row>
            <el-col :sm="16" :xs="24">
                <el-row>
                    <el-col :lg="8" :sm="24" @click="$emit('click')">
                        <label class="title">
                            {{ component.name }}
                        </label>
                        <span class="date_range">
                            {{ start | dateformat('DD/MM/YY') }}
                            -
                            {{ end | dateformat('DD/MM/YY') }}
                        </span>
                    </el-col>
                    <el-col :lg="16">
                        <div class="row times">
                            <slot name="times" />
                        </div>
                    </el-col>
                </el-row>
            </el-col>

            <el-col v-if="show_controls" :sm="8" :xs="24" class="options-col">
                <div class="top-right">
                    <a
                        v-only-super-admin
                        class="highlight-button desktop-only"
                        @click.stop="
                            updateComponent(
                                'highlight',
                                component.highlight ? null : true
                            )
                        "
                    >
                        <i class="el-icon-star-off" />
                    </a>

                    <el-button
                        v-if="current_label !== selected_label && next_enabled"
                        size="mini"
                        style="margin-right: 5px;"
                        :disabled="!current_enabled"
                        @click.stop="() => $emit('reset')"
                    >
                        Latest
                    </el-button>
                    <el-button-group class="buttons">
                        <el-button
                            size="mini"
                            :disabled="!prev_enabled"
                            @click.stop="() => $emit('prev-block')"
                        >
                            <div style="display: flex;">
                                <svgicon
                                    width="5"
                                    style="
                                        transform: rotate(180deg);
                                        margin-right: 5px;
                                    "
                                    name="triangle"
                                    class="triangle"
                                    :class="{disabled: !prev_enabled}"
                                />
                                {{ prev_label }}
                            </div>
                        </el-button>
                        <el-button
                            size="mini"
                            style="pointer-events: none; width: 120px;"
                        >
                            <b>{{ selected_label }}</b>
                        </el-button>
                        <el-button
                            size="mini"
                            :disabled="!next_enabled"
                            @click.stop="() => $emit('next-block')"
                        >
                            <div style="display: flex;">
                                {{ next_label }}
                                <svgicon
                                    style="margin-left: 5px;"
                                    width="5"
                                    name="triangle"
                                    class="triangle"
                                    :class="{disabled: !next_enabled}"
                                />
                            </div>
                        </el-button>
                    </el-button-group>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import dayjs from 'dayjs';

import componentMixin from '@/mixins/component.mixin';

export default {
    mixins: [componentMixin],
    props: {
        component: {
            type: Object,
            required: true,
        },
        selected_label: {
            type: String,
            default: null,
        },
        start: {
            type: dayjs,
            default: null,
        },
        end: {
            type: dayjs,
            default: null,
        },
        prev_enabled: {
            type: Boolean,
            default: false,
        },
        prev_label: {
            type: String,
            default: '',
        },
        next_enabled: {
            type: Boolean,
            default: false,
        },
        next_label: {
            type: String,
            default: '',
        },
        current_enabled: {
            type: Boolean,
            default: false,
        },
        current_label: {
            type: String,
            default: '',
        },
        show_controls: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep.head {
    position: relative;
    z-index: 2;
    padding: 0;

    .row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        user-select: none;
    }

    .col {
        display: flex;
        flex-direction: column;
    }

    .title {
        text-transform: capitalize;
        cursor: pointer;
        display: block;
    }

    .date_range {
        font-size: 9px;
        color: rgba($black, 0.6);
        font-weight: 500;
        pointer-events: none;
        display: block;
        margin-top: 1px;
    }

    .times {
        @media screen and (max-width: 1199px) {
            margin-top: 10px;

            .status:first-of-type {
                margin: 0;
                border: none;
                padding: 0;
            }
        }
    }

    .options-col {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        @media screen and (max-width: 768px) {
            justify-content: flex-start;
            margin-top: 20px;
        }

        .top-right {
            display: flex;
            flex-direction: row;

            .highlight-button {
                padding: 5px 15px;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    background-color: white;
                    border-radius: 50%;
                    box-shadow: 0 0 0 2px white;
                }

                &:hover i {
                    color: $blue !important;
                }
            }
        }

        .buttons {
            .el-button {
                display: flex;
                height: 28px;
                justify-content: center;
                align-items: center;
            }
        }
    }

    hr {
        width: 50%;
        margin: 20px 0;
        border: none;
        height: 1px;
        background-color: $border-grey-light;
    }

    .triangle {
        &.disabled {
            opacity: 0.2;
        }
    }
}
</style>
