<template>
    <node-view-wrapper>
        <div
            class="todo-node"
            :class="{selected}"
            data-name="todo_node"
            :data-ref="node.attrs.ref"
        >
            <todo-input
                v-if="editing"
                ref="input"
                :return_only="true"
                :source="source"
                :force_project_id="project_id"
                @created="submit"
                @cancel="() => deleteNode(true)"
            />
            <todo-item
                v-else
                :id="node.attrs.ref"
                ref="todo_item"
                class="todo-item"
                :item="todo"
                :show_user="true"
                :show_project="true"
                :in_editor="true"
                :editable="editable"
                @saved="setTodo"
                @updated="() => setCursor(true)"
                @cancel="() => setCursor(true)"
                @nav_out="setCursor"
                @deleted="handleDeleteNode"
            />
        </div>
    </node-view-wrapper>
</template>

<script>
import {NodeViewWrapper} from '@tiptap/vue-2';
import TodoInput from '@/components/todo/TodoInput';
import TodoItem from '@/components/todo/TodoItem';

export default {
    components: {
        TodoInput,
        TodoItem,
        NodeViewWrapper,
    },
    props: {
        node: {
            type: Object,
            required: true,
        },
        updateAttributes: {
            type: Function,
            default: () => {},
        },
        deleteNode: {
            type: Function,
            default: () => null,
        },
        getPos: {
            type: Function,
            default: () => {},
        },
        selected: {
            type: Boolean,
        },
        extension: {
            type: Object,
            default: () => {},
        },
        editor: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            todo: null,
        };
    },
    computed: {
        editing() {
            return !this.todo && !this.node.attrs.ref;
        },
        project_id() {
            return this.node.attrs.project_id;
        },
        source() {
            return this.node.attrs.source;
        },
        editable() {
            return this.editor.options.editable;
        },
    },
    watch: {
        selected(newVal, oldVal) {
            if (!this.editing && newVal && newVal != oldVal) {
                this.$refs.todo_item.startEditing();
            }
        },
    },
    async mounted() {
        await this.$nextTick();
        if (this.editing) {
            this.$refs.input.setFocus();
        }
    },
    methods: {
        submit(todo) {
            this.todo = todo;
            // position cursor before or after new node
            this.editor.chain().focus().createParagraphNear().run();

            this.editor.commands.setTextSelection(this.getPos() + 2);
        },
        setTodo(id) {
            // todo is saved in Firebase, persist in doc
            this.updateAttributes({
                ref: id,
            });
        },
        setCursor(after) {
            this.editor.commands.setTextSelection(
                this.getPos() + (after ? 1 : 0)
            );
        },
        handleDeleteNode(itemClicked) {
            this.deleteNode(itemClicked);
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.todo-node {
    margin-bottom: 10px;
    margin-top: 10px;

    flex: 0;

    max-width: 800px;
}
.inputnode {
    background-color: yellow;
    padding: 10px;
    margin: 0.5em 0;
    &.selected {
        background-color: orange;
    }
}

.list {
    margin: 0;
    padding: 0;
}
</style>
