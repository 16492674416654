<template>
    <div class="block">
        <el-button @click="convert">Convert incomplete</el-button>

        <div>
            <span>
                {{ incomplete_components.length }} active components in
                completed stages
            </span>
        </div>
    </div>
</template>

<script>
import {COMPONENT_STATUS, STAGE_STATUS} from '@/enums';
import {Notification} from 'element-ui';

export default {
    name: 'convert-completed-stage-components',

    data() {
        return {
            stages: [],
            components: [],
        };
    },
    computed: {
        incomplete_components() {
            return this.stages.reduce((acc, cur) => {
                const components = this.components.filter(
                    (c) => c.stage === `stages/${cur.id}`
                );

                if (components.length > 0) acc.push(...components);

                return acc;
            }, []);
        },
    },
    mounted() {
        this.$bind(
            'stages',
            this.$fire
                .collection('stages')
                .where('status', '==', STAGE_STATUS.COMPLETED),
            {maxRefDepth: 0}
        );

        this.$bind(
            'components',
            this.$fire
                .collection('components')
                .where('status', '==', COMPONENT_STATUS.ACTIVE),
            {maxRefDepth: 0}
        );
    },
    methods: {
        convert() {
            Notification({
                type: 'info',
                title: 'Converting',
                message: 'active components...',
            });

            this.incomplete_components.forEach((c) => {
                const stage = this.stages.find(
                    (s) => `stages/${s.id}` === c.stage
                );

                let completed_at =
                    stage.end_date && stage.end_date.toDate() < new Date()
                        ? this.stage.end_date.toDate()
                        : new Date();

                if (c.end_date && c.end_date.toDate() < completed_at) {
                    completed_at = c.end_date.toDate();
                }

                this.$fire.doc(`components/${c.id}`).update({
                    status: 'completed',
                    completed_at,
                });
            });

            Notification({
                type: 'success',
                title: 'Done',
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
