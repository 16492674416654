var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "actions-buttons",
        [
          _c(
            "div",
            { staticClass: "report_options" },
            [
              _c(
                "el-select",
                {
                  staticClass: "year-mode-selector",
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.year_mode,
                    callback: function ($$v) {
                      _vm.year_mode = $$v
                    },
                    expression: "year_mode",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { value: "full", label: "Full year" },
                  }),
                  _c("el-option", {
                    attrs: { value: "ytd", label: "Invoiced only" },
                  }),
                ],
                1
              ),
              _c(
                "el-select",
                {
                  staticClass: "total-mode-selector",
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.total_mode,
                    callback: function ($$v) {
                      _vm.total_mode = $$v
                    },
                    expression: "total_mode",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { value: "monthly", label: "Monthly totals" },
                  }),
                  _c("el-option", {
                    attrs: { value: "accruing", label: "Accruing totals" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.tabChanged },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "Profit & Loss", name: "_profitloss" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "Cashflow", name: "_cashflow" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide", mode: "out-in" } },
        [
          _vm.activeTab == "_profitloss"
            ? _c("ReportTab_profitloss", {
                key: "profitloss",
                attrs: { year_mode: _vm.year_mode, total_mode: _vm.total_mode },
              })
            : _vm._e(),
          _vm.activeTab == "_cashflow"
            ? _c("ReportTab_cashflow", {
                key: "cashflow",
                attrs: { year_mode: _vm.year_mode, total_mode: _vm.total_mode },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }