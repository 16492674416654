<template>
    <div v-if="module">
        <actions-buttons>
            <el-tabs v-model="activeTab">
                <el-tab-pane label="Releases" name="_releases" />
                <el-tab-pane
                    v-for="env of environments"
                    :key="env.name"
                    :name="`_env_${env.name}`"
                    :label="env.name"
                />
                <el-tab-pane label="Build Tree" name="_buildtree" />
            </el-tabs>
        </actions-buttons>

        <transition name="slide" mode="out-in">
            <module-tab-releases
                v-if="activeTab == '_releases'"
                :releases="releases"
            />
            <module-tab-environment
                v-else-if="env_from_active_tab"
                :environment="env_from_active_tab"
                :module="module"
                :pull_requests="pullRequestsForEnvironment(env_from_active_tab)"
                :releases="releases"
                @release:create="
                    (data) => createRelease(env_from_active_tab, data)
                "
            />
            <module-tab-buildtree
                v-if="activeTab == '_buildtree'"
                :environments="environments"
                :related_pull_requests="related_pull_requests"
            />
        </transition>
    </div>
</template>

<script>
import bitbucket from '@/api/bitbucket';

import ActionsButtons from '@/components/generic/ActionsButtons';
import ModuleTabReleases from '@/pages/modules/tabs/ModuleTab_releases';
import ModuleTabEnvironment from '@/pages/modules/tabs/ModuleTab_environment';
import ModuleTabBuildtree from '@/pages/modules/tabs/ModuleTab_buildtree';

export default {
    components: {
        ActionsButtons,
        ModuleTabReleases,
        ModuleTabEnvironment,
        ModuleTabBuildtree,
    },
    data() {
        return {
            activeTab: '_releases',
            tags: [],
            releases: [],
            pull_requests: [],
        };
    },
    computed: {
        module() {
            if (this.$route.params.module_id === undefined) return null;

            return this.$store.getters.moduleWithId(
                this.$route.params.module_id
            );
        },
        environments() {
            if (!this.module.environments) return [];
            return Object.entries(this.module.environments)
                .map(([key, value]) => {
                    return {
                        name: key,
                        ...value,
                    };
                })
                .sort((a, b) => {
                    if (a.name > b.name) return 1;
                    if (b.name > a.name) return -1;
                    return 0;
                });
        },
        env_from_active_tab() {
            if (this.activeTab.includes('_env_')) {
                const env_name = this.activeTab.substring(5);
                return this.environments.find((e) => e.name === env_name);
            }

            return null;
        },
        all_pull_requests() {
            return this.pull_requests
                .reduce((acc, cur) => {
                    acc.push(
                        ...cur.pull_requests.map((pr) => {
                            pr.env = cur.environment;
                            return pr;
                        })
                    );
                    return acc;
                }, [])
                .sort((a, b) => {
                    return (
                        new Date(b.updated_on).getTime() -
                        new Date(a.updated_on).getTime()
                    );
                });
        },
        related_pull_requests() {
            const prs = this.all_pull_requests.map((pr) => {
                const release =
                    this.releases.find((r) => {
                        return r.pull_request_id === pr.id;
                    }) || null;

                return {
                    id: pr.id,
                    merge_hash: pr.merge_commit.hash,
                    dest_hash: pr.destination.commit.hash,
                    source_hash: pr.source.commit.hash,
                    branch: pr.destination.branch.name,
                    source_branch: pr.source.branch.name,
                    build: pr.build,
                    env: pr.env.name,
                    title: pr.title,
                    release,
                };
            });

            const added = [];
            const values = [];

            const envIndexes = this.environments.reduce((acc, cur, idx) => {
                return {...acc, [cur.name]: idx};
            }, {});

            prs.forEach((el) => {
                const envs = new Array(this.environments.length).fill(null);

                if (added.includes(`${el.env}-${el.id}`)) return;

                added.push(`${el.env}-${el.id}`);

                const siblings = prs.filter((pr) => {
                    return (
                        pr.id !== el.id &&
                        (pr.merge_hash === el.source_hash ||
                            pr.source_hash === el.merge_hash)
                    );
                });

                envs[envIndexes[el.env]] = el;

                const siblingsOfSiblings = siblings.reduce((acc, cur) => {
                    const siblings = prs.filter((pr) => {
                        return (
                            pr.id !== cur.id &&
                            (pr.merge_hash === cur.source_hash ||
                                pr.source_hash === cur.merge_hash)
                        );
                    });

                    return [...acc, cur, ...siblings];
                }, []);

                siblingsOfSiblings.forEach((sib) => {
                    envs[envIndexes[sib.env]] = sib;
                    added.push(`${sib.env}-${sib.id}`);
                });

                values.push(envs);
            });

            return values;
        },
    },
    created() {
        this.getTags();

        this.$bind(
            'releases',
            this.$fire
                .collection(`modules/${this.module.id}/releases`)
                .orderBy('created_at', 'desc'),
            {maxRefDepth: 1}
        );

        this.getPullRequests();
    },
    methods: {
        pullRequestsForEnvironment(env) {
            const prs = this.pull_requests.find((e) => {
                if (e.environment.name === env.name) return true;
                return false;
            });

            if (prs) return prs.pull_requests;
            return [];
        },
        async getTags() {
            try {
                const result = await bitbucket(this.module.workspace)
                    .repository(this.module.repository)
                    .refs()
                    .tags({sort: '-target.date'});

                if (result.values.length > 0) {
                    this.tags = result.values;
                }
            } catch (err) {
                console.log(err);
            }
        },
        async createRelease(env, data) {
            const noteRef = await this.$fire.collection('notes').add({
                author: this.$fire.doc(`users/${this.$store.getters.user.id}`),
                content: data.content,
                title: data.title,
                project: this.$fire.doc(
                    `projects/${this.$route.params.project_id}`
                ),
                source: null,
                tag: 'module_release',
                is_temporary: false,
                is_private: false,
            });

            const releaseRef = await this.$fire
                .collection(`modules/${this.module.id}/releases`)
                .add({
                    environment: env.name,
                    note: noteRef,
                    version: data.version,
                    created_at: new Date(),
                    pull_request_id: data.pull_request_id,
                });

            noteRef.update({source: releaseRef});
        },
        async getPullRequests() {
            const results = await Promise.all(
                this.environments.map((env) => {
                    return this.getEnvironmentPullRequests(env);
                })
            );

            const prms = results.reduce((acc, env) => {
                const prm = env.pull_requests.map((pr) => {
                    return new Promise((resolve, reject) => {
                        pr.build = {status: 'Loading', number: null};

                        if (pr.merge_commit) {
                            bitbucket(this.module.workspace)
                                .repository(this.module.repository)
                                .commit(pr.merge_commit.hash)
                                .statuses()
                                .then((result) => {
                                    if (result.size > 0) {
                                        let buildNumber = null;
                                        const rgx = /.*#([0-9]*)/;

                                        const matches = result.values[0].name.match(
                                            rgx
                                        );
                                        if (matches.length > 1)
                                            buildNumber = +matches[1];

                                        pr.build = {
                                            status: result.values[0].state,
                                            number: buildNumber,
                                        };
                                    } else {
                                        pr.build = {
                                            status: 'No Build',
                                            number: null,
                                        };
                                    }
                                    resolve(true);
                                })
                                .catch((err) => reject(err));
                        } else {
                            pr.build = {status: 'No Build', number: null};
                            resolve(true);
                        }
                    });
                });

                return [...acc, ...prm];
            }, []);

            await Promise.all(prms);

            this.pull_requests = results;
        },
        async getCommitsForPullRequest(id) {
            const result = await bitbucket(this.module.workspace)
                .repository(this.module.repository)
                .pullrequests(id)
                .commits();

            return {id, commits: result.values};
        },
        async getEnvironmentPullRequests(environment, page = 1) {
            try {
                const query = encodeURIComponent(
                    `destination.branch.name="${environment.branch}" AND state = "MERGED"`
                );

                const sort = '-updated_on';

                const result = await bitbucket(this.module.workspace)
                    .repository(this.module.repository)
                    .pullrequests()
                    .get({q: query, sort, page});

                const all = [];

                all.push(...result.values);

                if (page < 2 && Math.ceil(result.size / 10) > 1) {
                    const results = await this.getEnvironmentPullRequests(
                        environment,
                        page + 1
                    );

                    all.push(...results);
                }

                if (page === 1) {
                    return {
                        environment: environment,
                        pull_requests: all,
                    };
                } else {
                    return [...all];
                }
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
