<template>
    <el-form
        ref="form"
        auto-complete="on"
        :model="form"
        :rules="rules"
        label-width="200px"
        class="form-wrapper"
    >
        <!-- NAME -->
        <!--*******************************************-->

        <el-form-item label="Name" prop="name">
            <el-input
                v-model="form.name"
                name="name"
                type="text"
                auto-complete="off"
                @input="generateReference"
            />
        </el-form-item>

        <!-- REFERENCE -->
        <!--*******************************************-->

        <el-form-item label="Reference" prop="ref">
            <el-input
                v-model="form.ref"
                name="ref"
                type="text"
                maxlength="3"
                auto-complete="off"
                placeholder="XXX"
            />
        </el-form-item>

        <!-- CLIENTS -->
        <!--*******************************************-->

        <el-form-item v-if="!client" label="Client">
            <el-select
                v-model="form.client"
                filterable
                clearable
                placeholder="Assign to an existing client"
                value-key="id"
                @change="assignClient"
            >
                <el-option
                    v-for="c in clients"
                    :key="c.id"
                    :label="c.name"
                    :value="c"
                />
            </el-select>
        </el-form-item>

        <el-button :disabled="creating" @click="handleSubmit">
            {{ client ? 'Create and link' : 'Create' }}
        </el-button>
    </el-form>
</template>

<script>
import {PROJECT_STATUS} from '@/enums';
import firebase from 'firebase/app';

export default {
    name: 'projects-new',
    props: {
        client: {
            type: Object,
            default: null,
        },
    },
    data() {
        const validateRef = (rule, value, callback) => {
            if (!value || value.length != 3) {
                callback(new Error('Invalid reference'));
            } else {
                callback();
            }
        };
        return {
            creating: false,
            form: {
                name: null,
                ref: null,
                client: null,
                status: PROJECT_STATUS.LEAD,
            },
            rules: {
                name: [{required: true, trigger: 'blur'}],
                ref: [
                    {required: true, trigger: 'blur', validator: validateRef},
                ],
            },
        };
    },
    computed: {
        clients() {
            return this.$store.getters.clients;
        },
    },
    methods: {
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.creating = true;

                    //Make sure the ref is always uppercased
                    this.form.ref = this.form.ref.toUpperCase();
                    this.form.max_ref_number = 0;

                    this.assigned_client =
                        this.form.client || this.client || null;

                    // Don't save the client to the project
                    delete this.form.client;

                    this.$store
                        .dispatch('addProject', this.form)
                        .then((response) => {
                            // If a client has been passed to the component or it has been selected
                            // Link the project to that client
                            if (this.assigned_client) {
                                this.$fire
                                    .doc(`clients/${this.assigned_client.id}`)
                                    .update({
                                        projects: firebase.firestore.FieldValue.arrayUnion(
                                            this.$fire
                                                .collection('projects')
                                                .doc(response.id)
                                        ),
                                    });
                            }

                            // If a client has been passed to the component (management pages)
                            // Only emit a completion event and don't navigate away
                            if (this.client) {
                                this.$emit('created', response);
                            } else {
                                this.$router.push({
                                    name: 'project_detail',
                                    params: {
                                        project_id: response.id,
                                    },
                                });
                            }
                        });
                } else {
                    console.log('Form not valid...');
                    return false;
                }
            });
        },
        generateReference() {
            if (this.form.name.length < 3) {
                this.form.ref = null;
            }
            if (this.form.ref === null && this.form.name.length >= 3) {
                this.form.ref = this.form.name.substring(0, 3);
            }
        },
        assignClient() {},
    },
};
</script>

<style lang="scss" scoped>
.el-form {
    display: flow-root;
    margin: 40px auto;

    .el-form-item {
        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        .el-input,
        .el-select {
            flex: 1;
            height: 100%;
            width: 100%;
        }
    }
}

.el-button {
    margin-top: 20px;
    width: 200px;
    float: right;
}

span.warning {
    color: $red;
}

hr {
    margin: 20px 50px;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}
</style>
