<template>
    <div class="users">
        <div
            v-for="user of computed_users"
            :key="user.id"
            :class="{selected: selected.includes(user.id)}"
            class="user"
            @click="() => handleClick(user)"
        >
            <avatar :user="user" :hide_tooltip="!show_tooltips" />
        </div>
        <el-dropdown
            v-if="available_users.length > 0 && !fixed_list"
            trigger="click"
            placement="right"
            @command="handleCommand"
        >
            <div class="add">
                <svgicon name="plus-circle" class="icon"></svgicon>
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-for="user in available_users"
                    :key="user.id"
                    :command="user.id"
                >
                    {{ user.name }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import Avatar from './Avatar';
export default {
    components: {Avatar},
    props: {
        users: {
            type: Array,
            default: () => [],
        },

        selected: {
            type: Array,
            default: () => [],
        },
        show_tooltips: {
            type: Boolean,
            default: false,
        },
        fixed_list: {
            type: Boolean,
            default: false,
        },
        single_select: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        computed_users() {
            return this.users.map((u) => this.$store.getters.userWithId(u));
        },
        available_users() {
            return this.$store.getters.users.filter((u) => {
                return !this.users.includes(`users/${u.id}`);
            });
        },
    },
    methods: {
        handleClick(user) {
            if (this.single_select) {
                if (this.selected.includes(user.id)) {
                    this.$emit('change', []);
                } else {
                    this.$emit('change', [user.id]);
                }
            } else {
                if (this.selected.includes(user.id)) {
                    this.$emit(
                        'change',
                        this.selected.filter((u) => u !== user.id)
                    );
                } else {
                    this.$emit('change', [...this.selected, user.id]);
                }
            }
        },
        handleCommand(userId) {
            this.$emit('add_user', userId);
        },
    },
};
</script>

<style lang="scss" scoped>
.users {
    display: flex;

    .user {
        max-height: 24px;
        border: 2px solid transparent;
        border-radius: 50%;
        padding: 2px;
        margin-right: 4px;
        opacity: 0.5;
        transition: opacity 0.25s, border-color 0.25s ease-in-out;

        &.selected {
            border-color: $blue;
            opacity: 1;
        }
    }

    .icon {
        padding: 4px;
        width: 24px;
        height: 24px;
        fill: rgba($black, 0.5);
    }
}
</style>
