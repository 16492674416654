<template>
    <transition name="loadingicon-fade">
        <div
            v-loading="loading"
            element-loading-background="transparent"
            class="loading-icon"
        >
            <svgicon class="svgicon" :name="icon" />
        </div>
    </transition>
</template>

<script>
export default {
    name: 'loading-icon',
    props: {
        icon: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.loading-icon {
    width: 48px;
    text-align: center;
    padding-bottom: 2px;

    .svgicon {
        width: 12px;
    }
}

.loadingicon-fade {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.4s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
</style>
