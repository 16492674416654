<template>
    <section>
        <change-request-detail />
    </section>
</template>

<script>
import ChangeRequestDetail from '@/pages/changerequests/detail';

export default {
    name: 'project-tab-changerequest',
    components: {
        ChangeRequestDetail,
    },
};
</script>

<style lang="scss" scoped>
//
</style>
