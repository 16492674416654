<template>
    <div class="container" :class="[cur_node.meta.status, cur_node.meta.type]">
        <div
            :class="['label', `depth-${depth}`]"
            :style="labelCSS"
            @click="$emit('click', cur_node)"
        >
            {{ cur_node.label }}
        </div>
        <div v-if="has_children" class="action" @click="$emit('toggle:hide')">
            <span v-if="children_collapsed" class="el-icon-caret-right"> </span>
            <span v-else class="el-icon-caret-bottom"> </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'default-list-item',

    props: {
        tree: {
            type: Object,
            required: true,
        },

        cur_node: {
            type: Object,
            required: true,
        },

        depth: {
            type: Number,
            required: true,
        },
    },

    computed: {
        labelCSS() {
            return {
                paddingLeft: `${this.depth * 10}px`,
            };
        },

        has_children() {
            return this.cur_node.children.length > 0;
        },

        children_collapsed() {
            if (!this.has_children) return true;

            return this.$store.getters.isNodeHidden(this.cur_node);
        },
    },
};
</script>

<style scoped lang="scss">
.container {
    display: flex;
    width: 100%;

    &.stage {
        .label {
            font-weight: 600;
        }
    }

    &.loading {
        .label {
            opacity: 0.6;
        }
    }

    .action {
        text-align: right;

        padding-right: 15px;
        padding-left: 15px;

        margin-right: 10px;

        &:hover {
            opacity: 0.6;
        }
    }
}

.label {
    flex: 1;
    &.depth-2 {
        font-weight: 600;
    }

    &:hover {
        opacity: 0.6;
    }
}
</style>
