import componentInvoiceMixin from '@/mixins/component.invoice.mixin';
import userMixin from '@/mixins/user.mixin';

const stageSessionMixin = {
    mixins: [userMixin, componentInvoiceMixin],
    data() {
        return {
            line_items: [],
            component_totals: [],
            user_totals: {},
            has_reported_hours_rounding: false,
        };
    },
    mounted() {
        if (this.user_is_super_admin && this.components) {
            const user_totals = {};
            this.components.forEach(async (component) => {
                const line_items_snap = await this.$fire
                    .collection('line_items')
                    .where(
                        'component',
                        '==',
                        this.$fire.doc(`components/${component.id}`)
                    )
                    .get();
                line_items_snap.forEach((line_item) => {
                    this.line_items.push(line_item.data());
                });

                const component_total = {
                    id: component.id,
                    total_allocated: component.time_estimate,
                    total_spent: 0,
                    total_reportable: 0,
                };

                const has_rounding = component.min || component.round;
                if (has_rounding) this.has_reported_hours_rounding = true;

                if (component.time_spent) {
                    // get the total spent time for component
                    component_total.total_spent = Object.entries(
                        component.time_spent
                    ).reduce((sum, user) => {
                        if (!has_rounding) {
                            // increment user total
                            user_totals[user[0]] ??= 0;
                            user_totals[user[0]] += user[1];
                        }
                        return sum + user[1];
                    }, 0);
                }

                if (component.time_reportable) {
                    // get the total reportable time for component
                    component_total.total_reportable = Object.entries(
                        component.time_reportable
                    ).reduce((sum, day) => {
                        const sessions = Object.entries(day[1]);
                        return sessions.reduce((daySum, session) => {
                            if (has_rounding) {
                                // increment user total
                                user_totals[session[0]] ??= 0;
                                user_totals[session[0]] += session[1];
                            }
                            return daySum + session[1];
                        }, sum);
                    }, 0);
                }

                if (component.recurring) {
                    // get the total allocated time for all blocks (including adjustments)
                    const block_snap = await this.$fire
                        .collection('component_recurring_blocks')
                        .where(
                            'component',
                            '==',
                            this.$fire.doc(`components/${component.id}`)
                        )
                        .get();
                    component_total.total_allocated = block_snap.docs.reduce(
                        (sum, block) => {
                            const blockData = block.data();
                            const adjustment =
                                blockData.time_adjustment?.reduce(
                                    (sum, item) => sum + item.time,
                                    0
                                ) ?? 0;
                            return sum + blockData.time_allocated + adjustment;
                        },
                        0
                    );
                }

                this.component_totals.push(component_total);
            });
            this.user_totals = user_totals;
        }
    },
    computed: {
        // All the active sessions for the given stage
        // ======
        active_sessions() {
            if (!this.components) return [];

            var sessions = [];

            this.components.forEach((component) => {
                sessions = [
                    ...sessions,
                    ...this.$store.getters.activeSessionsForComponentWithId(
                        component.id
                    ),
                ];
            });

            return sessions;
        },

        // Time
        // --------------------------

        // How much time has been spent for the given stage
        // -- Only for active sessions
        // return: milliseconds
        // ======
        tspent__active_sessions() {
            if (this.active_sessions.length) {
                return (
                    this.active_sessions
                        .map((session) =>
                            this.$options.filters.secondsDiff(
                                session.start.seconds,
                                this.$store.state.current_time / 1000
                            )
                        )
                        .reduce((a, b) => a + b, 0) * 1000
                );
            }
            return 0;
        },
        // The sum of the estimated time for each component in the given stage
        // return: milliseconds
        // ======
        total_estimate() {
            return this.component_totals.reduce(
                (sum, comp) => (sum += comp.total_allocated || 0),
                0
            );
        },
        // The sum of the time spent for each component in the given stage
        // return: milliseconds
        // ======
        total_spent() {
            return (
                this.component_totals.reduce(
                    (sum, comp) => (sum += comp.total_spent || 0),
                    0
                ) + this.tspent__active_sessions
            );
        },
        // The sum of the time reportable for each component in the given stage
        // return: milliseconds
        // ======
        total_reportable() {
            return (
                this.component_totals.reduce((sum, comp) => {
                    return sum + (comp.total_reportable || 0);
                }, 0) + this.tspent__active_sessions
            );
        },
    },
};

export default stageSessionMixin;
