<template>
    <a class="link" :href="link" target="_blank" @click.stop="() => null">
        XERO {{ invoices }}
    </a>
</template>

<script>
export default {
    name: 'xero-link',
    props: {
        link: {
            type: String,
            required: true,
        },
        show_summary: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        invoices() {
            console.log(this.$store.getters.invoices);
            return 'test';
        },
    },
};
</script>

<style lang="scss" scoped>
.link {
    display: inline-block;

    .issue {
        display: flex;
        align-items: center;
        justify-content: center;

        .status {
            font-size: 9px;
            text-transform: uppercase;
            margin-left: 4px;
            align-items: center;
            height: 14px;
            line-height: 14px;
        }

        .type-icon {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 4px;
        }
    }
}
</style>
