<template>
    <li class="container" @click="handleClick">
        <div class="row">
            <el-tag
                type="info"
                :disable-transitions="true"
                size="small"
                class="ref"
            >
                {{ release.version }}
            </el-tag>
            <span class="title">{{ release.note.title }}</span>
            <span class="date">
                {{ release.created_at.toDate() | timeAgo }}
            </span>
        </div>
    </li>
</template>

<script>
export default {
    name: 'module-release-block',
    props: {
        release: {
            type: Object,
            required: true,
        },
    },
    methods: {
        handleClick() {
            this.$router.push({
                name: 'note',
                params: {
                    note_id: this.release.note.id,
                },
            });
        },
    },
};
</script>
<style scoped lang="scss">
.container {
    position: relative;
    display: flex;
    width: 100%;
    padding: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid $border-grey;
    cursor: pointer;
    background-color: $white;
    box-sizing: border-box;
    user-select: none;

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;

        .el-tag.ref {
            width: 120px;
            text-align: center;
            font-size: 11px;
        }

        span.title {
            margin-left: 12px;
            font-size: 13px;
            flex: 1;
        }

        .el-tag {
            width: 100px;
            text-align: center;
        }

        .date {
            font-size: 12px;
            color: rgba($black, 0.6);
        }
    }
}
</style>
