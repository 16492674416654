import {COMPONENT_STATUS, STAGE_STATUS} from '../enums';

const projectMixin = {
    computed: {
        // The project object for the given stage
        // ======
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },

        // All the components for the given stage
        // ======
        components() {
            const components = this.project.components;
            if (!components) return [];

            return components
                .map((component) =>
                    this.$store.getters.componentWithId(component)
                )
                .sort(function (a, b) {
                    if (
                        a.status === COMPONENT_STATUS.ACTIVE &&
                        b.status !== COMPONENT_STATUS.ACTIVE
                    )
                        return -1;
                    if (
                        a.status !== COMPONENT_STATUS.ACTIVE &&
                        b.status === COMPONENT_STATUS.ACTIVE
                    )
                        return 1;
                    return 0;
                });
        },

        // All the non-recurring components for the given project
        // ======
        non_recurring_components() {
            return this.components.filter((c) => !c.recurring);
        },

        // All the recurring components for the given project
        // ======
        recurring_components() {
            return this.components.filter((c) => c.recurring);
        },
    },
};

export default projectMixin;
