import {COMMAND_DEPENDENCIES} from '@/enums';
import {Commands} from '@/components/command-palette/providers/Commands';

/**
 * Provides the list of commands available in the command palette.
 * Unfiltered results returns only the primary commands
 * @type {{provides: string, getResults: CommandProvider.getResults, label: string, filteredBy: null}}
 */
export const CommandProvider = {
    label: 'Commands',
    provides: COMMAND_DEPENDENCIES.COMMAND,
    filteredBy: null,
    getResults: async (data, filter_string) => {
        if (!filter_string) {
            // return the core commands
            return Commands.slice(0, 4);
        } else {
            // return any command matching filter string
            return Commands.filter((c) => {
                if (
                    c.keywords.includes(filter_string) ||
                    c.shortcut === filter_string
                ) {
                    c.clear_search = true;
                    return true;
                } else {
                    c.clear_search = false;
                    return c.default_option;
                }
            });
        }
    },
    cleanup() {
        // if this provider holds any resources during an operation, dispose of them here
    },
};
