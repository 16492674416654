<template>
    <div>
        <div class="block">
            <el-button @click="find">
                Find unlinked line items
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'find-orphaned-invoices',
    data() {
        return {
            orphans: {
                line_items: [],
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {
        async find() {
            const lineItemSnapshot = await this.$fire
                .collection('line_items')
                .get();

            lineItemSnapshot.forEach(async (line_item) => {
                const data = line_item.data();
                if (data.component) {
                    const component = await data.component.get();

                    if (!component.exists) {
                        console.log(line_item.id, component.id);
                        this.orphans.line_items.push(line_item);
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.block_options {
    width: 410px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    & > * {
        margin-right: 5px;
        width: 200px;
    }
}
</style>
