<template>
    <project-invoice-report
        report_name="Profit & Loss Report"
        :report_query="getProfitLossReport"
        :year_mode="year_mode"
        :total_mode="total_mode"
    />
</template>

<script>
import ProjectInvoiceReport from '@/pages/reports/components/ProjectInvoiceReport';
import {getProfitLossReport} from '@/api/reports';

export default {
    name: 'report-cashflow',
    methods: {getProfitLossReport},
    components: {
        ProjectInvoiceReport,
    },
    props: {
        year_mode: {
            type: String,
            required: true,
        },
        total_mode: {
            type: String,
            required: true,
        },
    },
};
</script>
