import request from '@/utils/request';

// {{host}}/estimate/:estimate_id/submit
export function submitEstimateToXero(estimate_id) {
    return request({
        url: `/estimate/${estimate_id}/submit`,
        method: 'post',
        data: {},
    });
}

// {{host}}/estimate/:estimate_id/issues
export function createJiraIssuesForEstimate(estimate_id) {
    return request({
        url: `/estimate/${estimate_id}/issues`,
        method: 'post',
    });
}
