<template>
    <content-block title="Estimate" collapsable :expanded="is_expanded">
        <el-form
            ref="form"
            auto-complete="on"
            label-width="160px"
            class="form-wrapper"
        >
            <!-- LINK / CREATE ESTIMATE -->
            <!--*******************************************-->

            <el-form-item label="Estimate">
                <div class="estimate-selector">
                    <estimate-dropdown
                        v-model="selectedEstimate"
                        :project_id="project.id"
                        :change_request_linked="change_request.id"
                        :disabled="!is_selectable"
                    />
                    <template v-if="is_selectable">
                        <el-button
                            v-if="change_request.estimate"
                            @click="handleViewEstimate"
                        >
                            View
                        </el-button>
                        <template v-else>
                            <span>or</span>
                            <el-button @click="handleCreateEstimate">
                                Create estimate
                            </el-button>
                        </template>
                    </template>
                </div>
            </el-form-item>
        </el-form>
    </content-block>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import EstimateDropdown from '@/components/generic/EstimateDropdown';

export default {
    name: 'change-request-estimate',
    components: {
        EstimateDropdown,
        ContentBlock,
    },
    props: {
        change_request: {
            type: Object,
            required: true,
        },
        project: {
            type: Object,
            required: false,
            default: null,
        },
        estimate: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            selectedEstimate: null,
        };
    },
    computed: {
        is_expanded() {
            return this.change_request.status === this.$enums.CR_STATUS.QUOTING;
        },
        is_selectable() {
            // TODO additional conditions TBA
            return this.is_expanded;
        },
        multiplier() {
            return (
                3600 *
                (this.time_unit === 'hour'
                    ? 1
                    : +process.env.VUE_APP_DAILY_HOURS) *
                1000
            );
        },
    },
    watch: {
        change_request: {
            handler(cr, oldCr) {
                if (!oldCr || cr.ref !== oldCr.ref) {
                    this.selectedEstimate = this.$options.filters.fireRef2id(
                        cr.estimate
                    );
                }
            },
            immediate: true,
        },
        async selectedEstimate(id, old_id) {
            if (id !== old_id) {
                // set the new assignment
                await this.$store.dispatch('assignEstimateToChangeRequest', {
                    estimate_id: id,
                    change_request_id: this.change_request.id,
                });
            }
        },
    },
    methods: {
        update(data) {
            return this.$fire
                .collection('change_requests')
                .doc(this.change_request.id)
                .update(data);
        },

        handleCreateEstimate() {
            this.$router.push({
                name: 'estimate_new',
                params: {
                    project_id: this.$route.params.project_id,
                    title: this.change_request.title,
                    description: this.change_request.content,
                    change_request_id: this.change_request.id,
                },
            });
        },

        handleViewEstimate() {
            this.$router.push({
                name: 'project_detail_estimate+detail',
                params: {
                    project_id: this.$route.params.project_id,
                    estimate_id: this.selectedEstimate,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
span.info {
    font-size: 11px;
    opacity: 0.5;
    margin-left: 10px;
}

::v-deep .el-input-number,
::v-deep .el-input {
    .el-input-number__decrease,
    .el-input-number__increase,
    .el-input-group__append {
        width: 80px;
        box-sizing: border-box;
    }

    .el-input__inner {
        text-align: left;
    }
}

.estimate-selector {
    display: flex;
    align-items: center;
    > * {
        margin-right: 10px;
    }
}

.info-icon {
    margin-left: 10px;
    width: 14px;
    height: 14px;
    font-size: 12px;
    font-weight: normal;
    color: black;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.2;
}
</style>
