<template>
    <button @click="onClick">
        <i class="el-icon-plus" />
    </button>
</template>

<script>
export default {
    methods: {
        onClick() {
            this.$emit('click', this.data);
        },
    },
};
</script>

<style lang="scss" scoped>
button {
    background: none;
    border: none;
    border-radius: 3px;
    width: 28px;
    height: 28px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba($black, 0.5);

    i {
        font-size: 16px;
    }

    &:hover {
        color: rgba($black, 0.7);
        background-color: rgba($black, 0.07);
    }
}

.dark {
    button {
        color: rgba($white, 0.5);

        &:hover {
            color: $white;
            background-color: rgba($white, 0.2);
        }
    }
}
</style>
