<template>
    <el-timeline-item
        :timestamp="timestamp"
        :color="color"
        :icon="icon"
        :size="size"
        :placement="placement"
    >
        <el-card shadow="never" class="milestone">
            <label>{{ title }}</label>
            <p>{{ content }}</p>
        </el-card>
    </el-timeline-item>
</template>
<script>
import moment from 'moment';

export default {
    name: 'timeline-item',
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    computed: {
        timestamp() {
            return moment(this.event.start.toDate()).fromNow();
        },
        color() {
            return '#cecece';
        },
        created_by() {
            if (!this.event.created_by) return null;
            return this.$store.getters.userWithId(this.event.created_by);
        },
        title() {
            return this.event.content;
        },
        content() {
            return moment(this.event.start.toDate()).format('DD/MM/YY');
        },
        icon() {
            return null;
        },
        size() {
            return 'large';
        },
        placement() {
            return 'top';
        },
    },
};
</script>

<style lang="scss" scoped>
.status {
    .content {
        margin-bottom: 10px;
    }

    .info {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .time {
            margin-left: 10px;
            font-size: 12px;
            color: rgba($black, 0.6);
        }
    }
}

.milestone {
    label {
        margin: 0;
    }

    p {
        margin: 0;
        margin-top: 10px;
        font-size: 12px;
        color: rgba($black, 0.6);
    }
}
</style>
