var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("project-invoice-report", {
    attrs: {
      report_name: "Cashflow Report",
      report_query: _vm.getCashflowReport,
      year_mode: _vm.year_mode,
      total_mode: _vm.total_mode,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }