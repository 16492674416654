<template>
    <div />
</template>

<script>
import DocumentFromLinkCommandLayout from '@/components/command-palette/components/DocumentFromLinkCommandLayout';

import {COMMAND_DEPENDENCIES} from '@/enums';

export default {
    // command attributes (referenced externally)
    command_props: {
        dependencies: [COMMAND_DEPENDENCIES.PROJECT],
        prompt: 'Select a project for this document',
    },

    name: 'document-from-link-command',
    components: {},
    props: {
        provided_data: {
            type: Object,
            required: true,
        },
        search_string: {
            type: String,
            required: false,
            default: null,
        },
    },
    watch: {
        provided_data: {
            handler(data) {
                // Load document-from-link component
                if (data[COMMAND_DEPENDENCIES.PROJECT]) {
                    this.$emit('component', DocumentFromLinkCommandLayout);
                }
            },
            immediate: true,
        },
    },
};
</script>
