<template>
    <div class="policy-settings">
        <div
            v-for="[key, children] of user_policy_groups"
            :key="key"
            class="container"
        >
            <label>{{ key | capitalize }}</label>
            <div class="right">
                <div
                    v-for="[label, value] of Object.entries(children)"
                    :key="`${key}-${label}`"
                >
                    <el-switch
                        :inactive-text="$options.filters.capitalize(label)"
                        :value="value"
                        @change="(val) => updatePolicyValue(key, label, val)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userMixin from '@/mixins/user.mixin';

const defaultUserPolicy = () => ({
    notifications: {
        todos: true,
    },
});

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    mixins: [userMixin],
    data() {
        return {
            policies: [],
        };
    },
    computed: {
        user_policy() {
            const policy =
                this.policies.find((policy) => policy.type === 'user') ?? null;

            if (!policy) {
                return {
                    policy: defaultUserPolicy(),
                    user: this.$fire.doc(`users/${this.current_user.id}`),
                    type: 'user',
                };
            }

            const mergedPolicies = Object.entries(policy.policy).reduce(
                (acc, [key, value]) => {
                    acc[key] = {...acc[key], ...value};
                    return acc;
                },
                defaultUserPolicy()
            );

            return {
                ...policy,
                policy: mergedPolicies,
            };
        },
        user_policy_groups() {
            return Object.entries(this.user_policy.policy);
        },
    },
    mounted() {
        this.$bind(
            'policies',
            this.$fire
                .collection('policies')
                .where(
                    'user',
                    '==',
                    this.$fire.doc(`users/${this.current_user.id}`)
                ),
            {maxRefDepth: 0}
        );
    },
    methods: {
        updatePolicyValue(parent, key, value) {
            const policy = {...this.user_policy};
            policy.policy[parent][key] = value;

            this.$fire.doc(`policies/${this.current_user.id}-user`).set(policy);
        },
    },
};
</script>

<style lang="scss" scoped></style>
