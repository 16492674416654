<template>
    <li
        v-if="todo_item && todo_item.id"
        ref="todo"
        v-on-clickaway="stopEditing"
        :class="{
            completed: is_completed,
            editing: is_editing,
            in_editor,
            highlight,
        }"
        class="todo-item"
        @mouseenter="mouseover"
        @mouseleave="mouseout"
        @click="startEditing"
    >
        <rendered-todo-item
            :todo_id="todo_item.id"
            :is_checked="is_completed"
            :priority="todo_item.priority"
            @status:toggle="toggleStatus"
            @priority:change="priorityChanged"
        >
            <template>
                <todo-priority
                    :priority="todo_item.priority"
                    :show="!is_read_only && (show_priority || priority_changed)"
                />
                <span
                    v-if="user && show_user && !todo_item.confluence"
                    class="user"
                >
                    <span @click.stop="handleClickOnUser">
                        {{ user.name }}
                    </span>
                </span>

                <span v-if="project && show_project" class="project">
                    <span @click.stop="handleClickOnProject">
                        {{ project.ref.toUpperCase() }}
                    </span>
                    -
                </span>

                <todo-item-content
                    v-show="!is_editing || is_read_only"
                    :text="todo_item.original_text"
                    :jira_project="jira_project"
                    :jira_org="jira_org"
                />

                <template v-if="is_editing && !is_read_only">
                    <todo-input
                        ref="todoInput"
                        class="todo-edit"
                        :content="todo_item.original_text"
                        :due_by="todo_item.due_by"
                        :component="component"
                        :estimate="todo_item.estimate"
                        :selected_user="user"
                        :return_only="true"
                        :show_user="true"
                        :show_project="false"
                        :show_date_picker="true"
                        :force_project_id="project ? project.id : null"
                        @dirty="setDirty"
                        @created="handleSaveTodo"
                        @cancel="cancelEditing"
                        @updated:user="handleUpdateTodoUser"
                        @updated:due_by="handleUpdateTodoDate"
                        @updated:component="handleUpdateComponent"
                        @updated:estimate="handleUpdateEstimate"
                    />
                    <span v-if="is_dirty" class="info">
                        ( Press enter to save )
                    </span>
                </template>

                <div v-if="todo_item.dependencies" class="dependencies">
                    <span>
                        {{ Object.keys(todo_item.dependencies).length }}
                    </span>
                </div>
            </template>
        </rendered-todo-item>

        <div v-if="todo_item.component && !is_editing" class="date component">
            {{ todo_item.component.name }}
        </div>

        <div v-if="component && !is_editing" class="component">
            {{ component.name }}
        </div>

        <div
            v-if="todo_item.due_by && !is_editing"
            class="date due-by"
            :class="date_priority"
        >
            {{ todo_item.due_by | relativeDate() }}
        </div>

        <div v-if="todo_item.estimate && !is_editing" class="estimate">
            {{ todo_item.estimate }}h
        </div>

        <div v-if="todo_item.confluence" class="icon-container">
            <a :href="confluence_link" target="_blank">
                <svgicon class="confluence_icon" name="confluence" />
            </a>
        </div>
        <div v-else-if="has_source && !in_editor" class="icon-container">
            <i
                size="mini"
                :class="source_icon"
                class="source_icon"
                @click="handleNavigateSource"
            />
        </div>

        <el-dropdown
            v-if="!is_read_only"
            class="options"
            trigger="click"
            placement="bottom-end"
            @command="runCommand"
        >
            <div class="down-icon">
                <svg class="icon" focusable="false" viewBox="0 0 24 24">
                    <path
                        d="M3.5,8.9c0-0.4,0.1-0.7,0.4-1C4.5,7.3,5.4,7.2,6,7.8l5.8,5.2l6.1-5.2C18.5,7.3,19.5,7.3,20,8c0.6,0.6,0.5,1.5-0.1,2.1 l-7.1,6.1c-0.6,0.5-1.4,0.5-2,0L4,10.1C3.6,9.8,3.5,9.4,3.5,8.9z"
                    />
                </svg>
            </div>

            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="delete" class="destructive">
                    Delete
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </li>
</template>

<script>
import TodoInput from './TodoInput';

import RenderedTodoItem from './RenderedTodoItem';
import TodoItemContent from './TodoItemContent';
import TodoPriority from './TodoPriority';

import moment from 'moment';
import {mixin as clickaway} from 'vue-clickaway';

import {updateConfluenceTask} from '@/api/atlassian';

export default {
    name: 'todo-item',
    components: {
        TodoInput,
        RenderedTodoItem,
        TodoItemContent,
        TodoPriority,
    },
    mixins: [clickaway],
    props: {
        // ID for displaying an existing todo item
        id: {
            type: String,
            default: null,
        },
        // data for creating/updating a todo item
        item: {
            type: Object,
            default: null,
        },
        show_user: {
            type: Boolean,
            default: false,
        },
        show_project: {
            type: Boolean,
            default: true,
        },
        show_priority: {
            type: Boolean,
            default: false,
        },
        in_editor: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            todo_item: {},
            is_editing: false,
            priority_changed: false,
            hovered: false,
            highlight: false,
            is_dirty: false,
        };
    },
    computed: {
        has_source() {
            if (
                this.todo_item.source &&
                typeof this.todo_item.source === 'string' &&
                // Only consider a valid source a string pointing to a reference
                this.todo_item.source.includes('/')
            ) {
                return true;
            }
            const from_fb_ref = this.$options.filters.fireRefToRefString(
                this.todo_item.source
            );
            if (from_fb_ref && from_fb_ref.includes('/')) {
                return true;
            }
            return false;
        },
        source_type() {
            return this.source_ref?.split('/')[0];
        },
        source_ref() {
            if (!this.has_source) return null;
            return this.$options.filters.fireRefToRefString(
                this.todo_item.source
            );
        },
        source_id() {
            if (!this.has_source) return null;
            return this.todo_item.source.split('/').reverse()[0];
        },
        source_link() {
            switch (this.source_type) {
                case 'notes':
                    return {
                        name: 'note',
                        params: {
                            note_id: this.source_id,
                        },
                    };
                case 'estimates':
                    return {
                        name: 'project_detail_estimate+detail',
                        params: {
                            project_id: this.project.id,
                            estimate_id: this.source_id,
                        },
                    };
                default:
                    return null;
            }
        },
        source_icon() {
            if (!this.has_source) return null;
            switch (this.source_type) {
                case 'notes':
                    return 'el-icon-notebook-2';
                case 'estimates':
                    return 'el-icon-coin';
                default:
                    console.warn(
                        'Unexpected source for todo',
                        this.source_type
                    );
                    return 'el-icon-toilet-paper';
            }
        },
        is_completed() {
            if (this.todo_item) {
                return this.todo_item.status === 'closed';
            }
            return false;
        },
        user() {
            return this.$store.getters.users.find((user) => {
                return this.todo_item.assigned_to == `users/${user.id}`;
            });
        },
        project() {
            if (
                !this.todo_item.project ||
                typeof this.todo_item.project !== 'string'
            ) {
                return null;
            }

            return this.$store.getters.projectWithId(this.todo_item.project);
        },
        component() {
            if (!this.todo_item.component) return null;
            else if (typeof this.todo_item.component === 'object')
                return this.$store.getters.componentWithId(
                    this.todo_item.component.id
                );

            return this.$store.getters.componentWithId(
                this.todo_item.component
            );
        },
        date_priority() {
            if (!this.todo_item.due_by) return null;

            const date = moment(
                this.todo_item.due_by.toDate
                    ? this.todo_item.due_by.toDate()
                    : this.todo_item.due_by
            );

            if (date.isSame(moment(), 'day')) {
                return 'today';
            } else if (date < moment()) {
                return 'late';
            } else if (date.isSame(moment().add(1, 'days'), 'day')) {
                return 'soon';
            }

            return 'low';
        },
        status() {
            if (this.todo_item) return this.todo_item.status;
            return 'open';
        },
        confluence_link() {
            return `https://${this.todo_item.confluence.resource}.atlassian.net/wiki/pages/viewpage.action?pageId=${this.todo_item.confluence.content_id}`;
        },
        jira_org() {
            if (!this.project) return null;
            return this.project.jira_org;
        },
        jira_project() {
            if (!this.project) return null;
            return this.project.jira_project;
        },
        is_read_only() {
            return this.todo_item.confluence;
        },
    },
    watch: {
        status(newVal, oldVal) {
            if (oldVal != newVal && newVal == 'deleted') {
                // data for this todo has been marked deleted; notify parent
                this.$emit('deleted', false); // this item did not trigger the delete
            }
        },
        editable(val) {
            if (!val) {
                this.is_editing = false;
            }
        },
        is_editing(val) {
            if (val) {
                this.$emit('editing', this.todo_item.id);
            } else {
                this.$emit('cancel', this.todo_item.id);
            }
        },
        hovered(val) {
            this.$emit('hover', val);
        },
    },
    async created() {
        if (this.item && this.item.confluence) {
            // todo item from Confluence, do not bind to Firestore
            this.todo_item = {...this.item};
        } else {
            if (this.item) {
                this.todo_item = {...this.item};
                // Vuefire IDs are not enumerable, assign directly
                if (this.item.id) {
                    this.todo_item.id = this.item.id;
                }

                // Data has been supplied
                if (!this.id && !this.todo_item.id) {
                    // No ID set; this is a new item that needs creating
                    await this.saveItem();
                    return;
                }
            } else if (this.id) {
                // No data, existing ID provided
                this.todo_item.id = this.id;
            }

            if (this.todo_item.id) {
                this.bindItem();
            }
        }
    },
    destroyed() {
        if (this.item) {
            this.$bus.$off(
                `todo:${this.item.id}:highlight`,
                this.handleHighlight
            );
        }
    },
    mounted() {
        if (this.item) {
            if (
                this.$route.params.notification_todo_id &&
                this.$route.params.notification_todo_id === this.item.id
            ) {
                this.handleHighlight();
                this.$route.params.notifiation_todo_id = null;
            }

            this.$bus.$on(
                `todo:${this.item.id}:highlight`,
                this.handleHighlight
            );
        }
    },
    methods: {
        setDirty(state) {
            this.is_dirty = state;
        },
        handleSaveTodo(todo) {
            this.todo_item.original_text = todo.text;
            this.todo_item.due_by = todo.due_by;

            this.updateTodo();

            this.is_dirty = false;
            this.is_editing = false;
        },
        handleUpdateComponent(component_id) {
            if (component_id)
                this.todo_item.component = this.$fire.doc(
                    `components/${component_id}`
                );
            else this.todo_item.component = null;

            this.updateTodo();
        },
        handleUpdateTodoDate(date) {
            this.todo_item.due_by = date;

            this.updateTodo();
        },
        handleUpdateTodoUser(user) {
            this.todo_item.assigned_to = user;

            this.updateTodo();
        },
        handleUpdateEstimate(hours) {
            this.todo_item.estimate = hours;

            this.updateTodo();
        },
        handleHighlight() {
            this.highlight = false;
            setTimeout(() => {
                const bounding = this.$refs.todo.getBoundingClientRect();

                if (
                    !(
                        bounding.top >= 0 &&
                        bounding.bottom <=
                            (window.innerHeight ||
                                document.documentElement.clientHeight)
                    )
                ) {
                    this.$refs.todo.scrollIntoView({behavior: 'smooth'});
                }
                this.highlight = true;
            }, 100);
        },
        handleNavigateSource() {
            if (!this.has_source) return;

            this.$router.push(this.source_link);
        },
        keydown(ev) {
            if (!this.is_editing) {
                if ((ev.keyCode > 47 && ev.keyCode < 59) || ev.keyCode === 45) {
                    this.priorityChanged(
                        ev.keyCode === 45 ? 100 : (ev.keyCode - 48) * 10
                    );
                }
            }
        },
        mouseover(ev) {
            this.hovered = true;
            window.addEventListener('keypress', this.keydown);
        },
        mouseout(ev) {
            this.hovered = false;
            window.removeEventListener('keypress', this.keydown);
        },
        bindItem() {
            this.$bind(
                'todo_item',
                this.$fire.doc(`todo/${this.todo_item.id}`),
                {
                    maxRefDepth: 0,
                }
            );
        },
        toggleStatus() {
            switch (this.todo_item.status) {
                case 'hidden': //Used for management todos
                case 'open':
                    this.todo_item.status = 'closed';
                    break;
                case 'closed':
                    this.todo_item.status = 'open';
                    break;
                default:
                    this.todo_item.status = 'open';
                    break;
            }

            this.updateTodo();
        },
        runCommand(value) {
            switch (value) {
                case 'delete':
                    this.deleteTodo();
                    break;
                default:
                    break;
            }
        },
        priorityChanged(value) {
            if (this.todo_item) {
                this.priority_changed = true;

                this.todo_item.priority = value;

                setTimeout(() => {
                    this.priority_changed = false;
                }, 1500);

                this.updateTodo();
            }
        },
        deleteTodo() {
            this.todo_item.status = 'deleted';
            this.updateTodo();
            this.$emit('deleted', true); // this item triggered the delete
        },
        updateTodo() {
            this.is_editing = false;

            if (this.todo_item.confluence) {
                const newStatus =
                    this.todo_item.status == 'open' ? 'incomplete' : 'complete';

                updateConfluenceTask(
                    this.todo_item.confluence.resource,
                    this.todo_item.confluence.content_id,
                    this.todo_item.confluence.id,
                    newStatus
                );
            } else {
                this.$store.dispatch('updateTodo', this.todo_item);
                this.$emit('updated');
            }
        },
        handleClickOnProject(event) {
            this.$router.push({
                name: 'project_detail',
                params: {
                    project_id: this.project.id,
                },
            });
        },
        handleClickOnUser(event) {
            //
        },
        startEditing() {
            this.is_editing = true;
        },
        stopEditing() {
            if (this.is_dirty) {
                setTimeout(() => {
                    this.is_dirty = false;
                    if (this.is_editing && !this.is_read_only)
                        this.updateTodo();
                });
            }
            this.is_editing = false;
        },
        cancelEditing() {
            this.is_dirty = false;
            this.is_editing = false;
        },
        async saveItem() {
            const save_data = await this.$store.dispatch(
                'addTodo',
                this.todo_item
            );
            this.todo_item.id = save_data;
            this.bindItem();
            this.$emit('saved', this.todo_item.id);
        },
        navOut(event, after) {
            if (this.in_editor) {
                event.preventDefault();
                this.is_editing = false;
                this.$emit('nav_out', after);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
li.todo-item {
    padding: 5px 12px 5px 15px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    user-select: auto;
    box-shadow: none;
    border-radius: 10px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    align-items: center;

    @media screen and (max-width: 992px) {
        padding: 5px 10px;
    }

    &.highlight {
        animation-name: highlight;
        animation-duration: 2.5s;
        animation-delay: 0.25s;
    }

    .user {
        background-color: rgba($blue, 0.05);
        border: 1px solid rgba($blue, 0.2);
        padding: 0px 4px;
        border-radius: 4px;
        margin-right: 10px;
        min-width: 40px;
        color: $blue;

        span {
            display: block;
            text-align: center;
            font-size: 12px;
        }
    }

    .options {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        border: none;
        width: 40px;
        height: 40px;
        padding: 0;
        cursor: pointer;
        border-radius: 10px;
        opacity: 0;

        @media screen and (max-width: 992px) {
            display: none;
        }

        .down-icon {
            cursor: pointer;
            fill: rgba($black, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .icon {
                flex: 0 0 auto;
                height: 12px;
                width: 12px;
            }
        }
    }

    &.editing {
        background: $white;
        box-shadow: 0 20px 66px 0 rgba(34, 48, 73, 0.2);
        transform: scale(1.008);
        z-index: 100;
        position: relative;

        .options {
            display: flex;
        }
    }

    &:hover,
    &.editing {
        .options {
            opacity: 1;

            &:hover {
                background: $white;
                box-shadow: 0 0 0 1px rgba($border-grey, 0.2);
            }
        }
    }

    .avatar {
        margin-right: 5px;
    }

    .checkbox {
        margin-right: 10px;
        z-index: 10;
    }

    .dependencies {
        left: -7px;
        position: absolute;
        border-radius: 17px;
        width: 50px;
        height: 32px;
        background: $white;
        border: 1px solid rgba($border-grey, 0.2);
        pointer-events: none;
        display: flex;
        align-items: center;
        padding-left: 7px;
        box-sizing: border-box;
        z-index: 8;

        span {
            color: $red;
            font-weight: 600;
            font-size: 11px;
        }
    }

    .el-button {
        margin-left: 5px;
    }

    &.completed {
        opacity: 0.3;

        span {
            text-decoration: line-through;
        }
    }

    .project {
        margin-right: 4px;

        span {
            cursor: pointer;

            &:not(:hover) {
                letter-spacing: 0.3px;
            }

            &:hover {
                font-weight: bold;
            }
        }
    }

    .link {
        color: $black;
        text-decoration: none;
        cursor: pointer;

        &:not(:hover) {
            letter-spacing: 0.3px;
        }

        &:hover {
            font-weight: bold;
        }
    }

    .date {
        display: inline-block;
        padding: 4px 8px;
        color: $black;
        font-size: 12px;
        font-weight: bold;
        border-radius: 4px;

        &.today {
            background-color: rgba($blue, 0.1);
        }

        &.late {
            background-color: rgba($red, 0.1);
        }

        &.soon {
            background-color: rgba($orange, 0.1);
        }
    }

    .content {
        cursor: pointer;
        flex: 1;
    }

    ::v-deep .editor {
        min-height: 27px;
        max-height: unset;
        border: none;
        padding: 0;
        font-family: Montserrat, sans-serif;
        background: unset;
        font-size: 14px;
        color: $black;
        box-shadow: none;

        .append {
            background-color: transparent;
            border: 0px;

            .date-icon {
                padding: 0px 10px;
            }
        }
    }

    @keyframes opacityOn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    span.info {
        color: rgba($black, 0.5);
        font-size: 10px;
        font-weight: 300;
        position: absolute;
        top: -15px;
        right: 60px;
        background: $white;
        padding: 2px 15px;
        padding-bottom: 5px;
        border-radius: 6px;
        z-index: 8;
        opacity: 0;

        animation-name: opacityOn;
        animation-duration: 0.2s;
        animation-delay: 0.2s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

    .icon-container,
    .icon-container a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 10px;

        &:empty {
            pointer-events: none;
        }

        &:hover {
            cursor: pointer;
            background-color: $white;
            box-shadow: 0 0 0 1px rgba($border-grey, 0.2);
        }

        a {
            .confluence_icon {
                width: 12px;
            }
        }
    }

    .component {
        font-size: 10px;
        margin-right: 10px;
        opacity: 0.7;
    }

    .due-by {
        font-size: 12px;
        margin-right: 10px;
    }

    .estimate {
        display: inline-block;
        padding: 4px 8px;
        margin-right: 10px;
        color: $black;
        font-size: 12px;
        font-weight: bold;
        border-radius: 4px;
        background: $transparent-grey;
    }
}

@keyframes highlight {
    25% {
        box-shadow: 0px 20px 66px 0px rgba($blue, 0.4);
        background: $white;
        transform: scale(1.008);
    }

    50% {
        box-shadow: 0px 20px 66px 0px rgba($blue, 0.4);
        background: $white;
        transform: scale(1.008);
    }
    100% {
        box-shaodw: none;
        background: none;
        transform: scale(1);
    }
}
</style>
