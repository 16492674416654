<template>
    <div>
        <timeline
            ref="timeline"
            class="timeline"
            :groups="groups"
            :items="items"
            :options="options"
            @select="selected"
            @items-mounted="getDataSet"
            @currentTimeTick="currentTimeTick"
        />
    </div>
</template>

<script>
import moment from 'moment';
import {momentWithTz} from '@/utils';

import {Timeline} from 'vue2vis';

import url from 'url';

export default {
    name: 'sessions-timeline',
    components: {
        Timeline,
    },
    props: {
        component_id: {
            type: String,
            default: null,
        },
        active_users: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            dataSet: null,
            sessions: [],
            options: {
                editable: {
                    updateTime: true,
                    updateGroup: false,
                    remove: false,
                },
                stack: true,
                stackSubgroups: true,
                selectable: true,
                start: this.tv_mode
                    ? momentWithTz().set({h: 8, m: 0})
                    : momentWithTz().add(-32, 'hours'),
                end: this.tv_mode
                    ? momentWithTz().set({h: 20, m: 0})
                    : momentWithTz().add(8, 'hours'),
                onMove: (item) => {
                    this.updateSessionForItem(item);
                },
                zoomMax: 2419200000,
                zoomMin: 3600000,
                margin: {
                    item: 10,
                    axis: 50,
                },
            },
        };
    },
    computed: {
        groups() {
            const uniqueProjects = [
                ...new Set(
                    this.sessions.map((session) => {
                        return session.project;
                    })
                ),
            ];

            return uniqueProjects.map((project) => {
                return {
                    id: project,
                    subgroupOrder: function (a, b) {
                        return a.subgroupOrder - b.subgroupOrder;
                    },
                    subgroupStack: {
                        user_1: true,
                        user_2: true,
                        user_3: true,
                        user_4: true,
                        user_5: true,
                        user_6: true,
                        user_7: true,
                        user_8: true,
                        user_9: true,
                        user_10: true,
                        user_11: true,
                        user_12: true,
                        user_13: true,
                        user_14: true,
                        user_15: true,
                    },
                    content: project
                        ? this.getProjectName(
                              this.$options.filters.fireRef2id(project)
                          )
                        : '-',
                };
            });
        },
        items() {
            return this.sessions
                .filter((session) => {
                    return this.active_users.includes(
                        this.$options.filters.fireRef2id(session.user)
                    );
                })
                .map((session) => {
                    var start = momentWithTz(
                        moment.utc(session.start.seconds * 1000)
                    )
                        .local()
                        .format('YYYY-MM-DD HH:mm:ss');
                    var stop = session.stop
                        ? momentWithTz(moment.utc(session.stop.seconds * 1000))
                              .local()
                              .format('YYYY-MM-DD HH:mm:ss')
                        : momentWithTz().local().format('YYYY-MM-DD HH:mm:ss');

                    const isActive = !session.stop;

                    return {
                        id: session.id,
                        group: session.project,
                        subgroup: session.user,
                        subgroupOrder: session.user,
                        start: start,
                        end: stop,
                        content: this.stripLinks(session.note) || '',
                        session: session,
                        editable: {
                            updateTime: !isActive,
                            updateGroup: false,
                            remove: false,
                        },
                        className: `user_${this.$options.filters.fireRef2id(
                            session.user
                        )} ${isActive ? 'active' : ''}`,
                        style: `background-color: ${
                            this.$store.getters.userWithId(session.user)
                                .color || '#1989fa'
                        }`,
                        is_active: isActive,
                    };
                });
        },
    },
    mounted() {
        setTimeout(() => {
            this.redrawGraph();
        }, 500);

        const limit = momentWithTz().startOf('day').add(-2, 'week').toDate();

        this.$bind(
            'sessions',
            this.$fire
                .collection('sessions')
                .where('start', '>=', limit)
                .orderBy('start', 'desc'),
            {maxRefDepth: 0}
        );

        this.$bus.$on('session_deselected', this.deselectSession);
    },

    unmounted() {
        this.$bus.$off('session_deselected', this.deselectSession);
    },
    methods: {
        stripLinks(content) {
            if (!content) return content;
            const expression = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.?[a-zA-Z0-9()]{0,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/gi;

            let match = null;
            let startIndex = 0;

            const text = [];

            while ((match = expression.exec(content)) != null) {
                text.push(content.substr(startIndex, match.index - startIndex));

                const link = content.substr(match.index, match[0].length);

                const parsed = url.parse(link);

                if (parsed.hostname.includes('atlassian.net')) {
                    const jira_rgx = /(.*)\/(browse|issues)\/([A-Za-z]{3,}-[0-9]{1,})(\?.*|$)/;

                    const m = link.match(jira_rgx);

                    if (m && m.length >= 3) {
                        text.push(m[3]);
                    } else {
                        text.push(parsed.hostname);
                    }
                } else {
                    text.push(parsed.hostname);
                }

                startIndex = match.index + match[0].length;
            }

            if (startIndex < content.length) {
                text.push(content.substr(startIndex));
            }

            return text.join('');
        },
        deselectSession() {
            this.selected_session = null;

            if (this.$refs.timeline) {
                this.$refs.timeline.setSelection([]);
            }
        },
        selected(item) {
            if (!item.items || !item.items[0]) this.selected_session = null;

            this.$bus.$emit(
                'session_selected',
                this.sessions.find((session) => session.id === item.items[0])
            );
        },
        getDataSet(dataSet) {
            this.dataSet = dataSet;
            this.redrawGraph();
        },
        getActiveItems() {
            return this.items.filter((item) => {
                return item.is_active;
            });
        },
        currentTimeTick() {
            this.getActiveItems().map((i) => {
                i.end = momentWithTz().toDate();
                this.dataSet.update(i);
            });
        },
        async updateSessionForItem(item) {
            const new_start = momentWithTz(item.start).unix();
            let new_stop = momentWithTz(item.end).unix();
            let new_end_point = item.end;
            let new_duration = new_stop - new_start;
            if (new_duration < 0) {
                // new stop is before new start
                new_duration = 0;
                new_end_point = item.start;
            }

            const old_start = item.session.start.seconds;
            const old_stop = item.session.stop.seconds;
            const old_duration = old_stop - old_start;

            const delta_duration = new_duration - old_duration;

            this.$fire
                .doc(`sessions/${item.id}`)
                .update({
                    start: item.start,
                    stop: new_end_point,
                })
                .then((x) => {
                    this.$bus.$emit(
                        'session_selected',
                        this.sessions.find((session) => session.id === item.id)
                    );
                });
        },

        getProjectName(project_id) {
            var project = this.$store.getters.projectWithId(project_id);
            if (project) return project.name;
            return null;
        },
        redrawGraph() {
            // NEED THIS to Adjust graph layout
            setTimeout(() => {
                if (this.$refs.timeline) this.$refs.timeline.redraw();
            }, 0);
        },
    },
};
</script>

<style lang="scss" scoped>
//
</style>
