import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from '@/assets/pdfmake_vfs_fonts';

import {
    DOCUMENT_BLOCK_TYPES,
    DOCUMENT_GROUP_DETAIL,
    DOCUMENT_GROUP_TOGGLES,
    DOCUMENT_SUMMARY_DETAIL,
    DOCUMENT_SUMMARY_TOGGLES,
    DOCUMENT_TYPES,
    KEYWORDS,
} from '@/enums';

import aiMixin from '@/mixins/ai.mixin';
import estimateMixin from '@/mixins/estimate.mixin';
import estimateTaskMixin from '@/mixins/estimate.task.mixin';
import documentMixin from '@/mixins/document.mixin';

import dayjs from 'dayjs';

import {Notification} from 'element-ui';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const pdfMixin = {
    mixins: [estimateMixin, estimateTaskMixin, aiMixin, documentMixin],
    data() {
        return {
            pdf_data: null,
        };
    },
    methods: {
        /* standard styles for PDFMake formatting */
        pdfDefaultStyle() {
            return {
                fontSize: 10,
                font: 'Montserrat',
                lineHeight: 1.5,
            };
        },
        pdfStyles() {
            return {
                title: {
                    font: 'MontserratBlack',
                    fontSize: 24,
                    bold: true,
                    color: this.$store.state.organisation.color,
                    margin: [0, 0, 0, 10],
                },
                subtitle: {
                    font: 'Montserrat',
                    fontSize: 22,
                    lineHeight: 1,
                    bold: true,
                    color: this.$store.state.organisation.color,
                    margin: [0, 0, 0, 10],
                },
                address: {
                    margin: [0, 60, 0, 100],
                    alignment: 'right',
                },
                heading: {
                    fontSize: 16,
                    bold: true,
                    color: this.$store.state.organisation.color,
                    margin: [0, 0, 0, 10],
                },
                heading_2: {
                    fontSize: 15,
                    bold: true,
                    color: 'black',
                    margin: [0, 0, 0, 8],
                },
                heading_3: {
                    fontSize: 14,
                    bold: true,
                    color: 'black',
                    margin: [0, 0, 0, 5],
                },
                heading_4: {
                    fontSize: 13,
                    bold: true,
                    color: 'black',
                    margin: [0, 0, 0, 5],
                },
                paragraph: {
                    lineHeight: 1.2,
                },
                codeBlock: {
                    font: 'Monospaced', // TODO whitespace formatting is not preserved
                },
                list: {
                    margin: [0, 0, 0, 10],
                },
                link: {
                    color: '#2754cd',
                    decoration: 'underline',
                },
                endBlock: {
                    margin: [0, 0, 0, 10],
                },
                groupHeading: {
                    fontSize: 12,
                    bold: true,
                },
                groupTable: {
                    lineHeight: 1,
                    margin: [0, 5, 0, 15],
                },
                summaryTable: {
                    lineHeight: 1,
                    margin: [0, 5, 0, 15],
                },
                tableHeader: {
                    color: 'white',
                },
                footnote: {
                    fontSize: 8,
                },
                link_accept: {
                    color: '#2754cd',
                    decoration: 'underline',
                    margin: [0, 15],
                },
                signatureForm: {
                    lineHeight: 1,
                    margin: [0, 5, 0, 25],
                },
            };
        },
        pdfFonts() {
            // static TTF URLs obtained from https://google-webfonts-helper.herokuapp.com
            return {
                Montserrat: {
                    normal:
                        'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Hw5aX8.ttf',
                    bold:
                        'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM73w5aX8.ttf',
                    italics:
                        'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9WXh0ow.ttf',
                    bolditalics:
                        'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6WXh0ow.ttf',
                },
                MontserratBlack: {
                    normal:
                        'https://fonts.cdnfonts.com/s/14883/Montserrat-Black.woff',
                    bold:
                        'https://fonts.cdnfonts.com/s/14883/Montserrat-Black.woff',
                },
                Monospaced: {
                    normal:
                        'https://fonts.gstatic.com/s/jetbrainsmono/v13/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yKxTOlOQ.ttf',
                },
                Heart: {
                    normal: 'heart.ttf',
                },
            };
        },
        pdfTableLayouts() {
            return {
                quoteGroupTable: {
                    hLineWidth: function () {
                        return 0.5;
                    },
                    vLineWidth: function () {
                        return 0.5;
                    },
                    hLineColor: function () {
                        return '#aaa';
                    },
                    vLineColor: function () {
                        return '#aaa';
                    },
                    fillColor: function (i, node) {
                        if (i === 0) return '#eee';
                        if (i === node.table.body.length - 1) {
                            return node.style === 'summaryTable'
                                ? '#ccc'
                                : '#eee';
                        }
                        return '';
                    },
                },

                signatureFormTable: {
                    hLineWidth: function () {
                        return 0.5;
                    },
                    vLineWidth: function () {
                        return 0.5;
                    },
                    hLineColor: function () {
                        return 'black';
                    },
                    vLineColor: function () {
                        return 'black';
                    },
                    paddingTop: function () {
                        return 8;
                    },
                    paddingBottom: function () {
                        return 8;
                    },
                    paddingRight: function () {
                        return 6;
                    },
                    fillColor: function (i, node) {
                        if (i === 0) {
                            return 'black';
                        }
                        return '';
                    },
                },
            };
        },

        /* Basic converter from TipTap format to PDFMake format
         * Unexpected content will be ignored
         * */
        pdfParseRichText(doc) {
            if (!doc.content) return null; // unknown format

            let pdf = [];

            // iterate over doc sections
            doc.content.forEach((section) => {
                if (
                    section.type === 'bulletList' ||
                    section.type === 'orderedList'
                ) {
                    pdf.push(this.pdfParseList(section));
                } else {
                    let suffix = '';
                    if (section.attrs?.level > 1)
                        suffix = `_${Math.min(section.attrs.level, 4)}`;
                    const style = section.type + suffix;
                    this.pdfParseRichTextBlock(pdf, style, section, true);
                }
            });
            return pdf;
        },

        pdfParseList(section) {
            let list = [];
            section.content.forEach((item) => {
                let listItem = [];
                item.content.forEach((block) => {
                    if (
                        block.type === 'bulletList' ||
                        block.type === 'orderedList'
                    ) {
                        listItem.push(this.pdfParseList(block));
                    } else {
                        this.pdfParseRichTextBlock(listItem, '', block, false);
                    }
                });
                list.push(listItem);
            });
            if (section.type === 'orderedList') {
                return {ol: list, style: 'list'};
            } else {
                return {ul: list, style: 'list'};
            }
        },

        pdfParseRichTextBlock(container, style, section, end = false) {
            let line = [];
            section.content?.forEach((block) => {
                if (block.type === 'text') {
                    let text = {text: block.text};
                    if (block.marks) {
                        block.marks.forEach((mark) => {
                            switch (mark.type) {
                                case 'bold': {
                                    text.bold = true;
                                    break;
                                }
                                case 'italic': {
                                    text.italics = true;
                                    break;
                                }
                                case 'underline': {
                                    text.decoration = 'underline'; // only one decoration can apply
                                    break;
                                }
                                case 'strike': {
                                    text.decoration = 'lineThrough'; // only one decoration can apply
                                    break;
                                }
                                case 'link': {
                                    text.link = mark.attrs.href;
                                    text.style = 'link';
                                    break;
                                }
                            }
                        });
                    }
                    line.push(text);
                } else if (block.type === 'hardBreak') {
                    container.push({text: line, style});
                    line = [];
                }
            });
            if (line.length) {
                let endStyle = [style];
                if (end) endStyle.push('endBlock');
                container.push({text: line, style: endStyle});
            }
        },

        pdfGroupTable(showDays = false, showHours = false) {
            const widths = ['*'];
            if (showDays) {
                widths.push(30);
            }
            if (showHours) {
                widths.push(35);
            }
            const groupBody = [{text: 'Task', bold: true}];
            if (showDays) {
                groupBody.push({
                    text: 'Days',
                    bold: true,
                    alignment: 'right',
                });
            }
            if (showHours) {
                groupBody.push({
                    text: 'Hours',
                    bold: true,
                    alignment: 'right',
                });
            }
            return {
                table: {
                    headerRows: 1,
                    widths,
                    body: [groupBody],
                },
                style: 'groupTable',
                layout: 'quoteGroupTable',
            };
        },

        pdfSummaryTable(showDays = false, showHours = false, showCost = false) {
            const tableHeader = [
                {
                    text: 'Activity',
                    bold: true,
                },
            ];

            const widths = ['*'];

            let cols = 0;
            if (showDays) {
                ++cols;
                tableHeader.push({
                    text: 'Days',
                    bold: true,
                    alignment: 'right',
                });
            }
            if (showHours) {
                ++cols;
                tableHeader.push({
                    text: 'Hours',
                    bold: true,
                    alignment: 'right',
                });
            }
            if (showCost) {
                ++cols;
                tableHeader.push({
                    text: 'Cost',
                    bold: true,
                    alignment: 'right',
                });
            }
            if (!showDays && !showHours && !showCost) {
                cols = 1;
                tableHeader.push({
                    text: '',
                });
            }

            // set column widths, last column being widest
            for (let i = 1; i < cols; i++) {
                widths.push(35);
            }
            widths.push(80);

            return {
                table: {
                    headerRows: 1,
                    widths: widths,
                    body: [tableHeader],
                },
                style: 'summaryTable',
                layout: 'quoteGroupTable',
            };
        },

        pdfFooter() {
            if (!this.$store.state.organisation.doc_footer) return null;
            if (
                this.$store.state.organisation.doc_footer ===
                KEYWORDS.CODEFISH_FOOTER
            ) {
                return {
                    text: [
                        'Made with  ',
                        {
                            text: '\ue800',
                            font: 'Heart',
                            color: this.$store.state.organisation.color,
                        },
                        '  in Adelaide by CodeFish Studio',
                    ],
                    alignment: 'center',
                    fontSize: 7,
                };
            } else {
                return {
                    text: this.$store.state.organisation.doc_footer,
                    alignment: 'center',
                    fontSize: 7,
                };
            }
        },

        signatureForm() {
            return {
                table: {
                    headerRows: 1,
                    widths: [80, '*', 40, 120],
                    body: [
                        [
                            {
                                text: 'Authorised Person',
                                colSpan: 4,
                                color: 'white',
                                bold: true,
                            },
                            {text: ''},
                            {text: ''},
                            {text: ''},
                        ],
                        [
                            {text: 'Name', alignment: 'right'},
                            {text: '', colSpan: 3},
                            {text: ''},
                            {text: ''},
                        ],
                        [
                            {text: 'Title', alignment: 'right'},
                            {text: '', colSpan: 3},
                            {text: ''},
                            {text: ''},
                        ],
                        [
                            {text: 'Signature', alignment: 'right'},
                            {text: ''},
                            {text: 'Date', alignment: 'right'},
                            {text: ''},
                        ],
                    ],
                },
                style: 'signatureForm',
                layout: 'signatureFormTable',
            };
        },

        pdfInitDocument() {
            const doc = {};
            pdfMake.fonts = this.pdfFonts();
            pdfMake.tableLayouts = this.pdfTableLayouts();

            doc.pageMargins = 70;

            doc.defaultStyle = this.pdfDefaultStyle();
            doc.styles = this.pdfStyles();

            // define custom page breaks
            doc.pageBreakBefore = function (
                currentNode,
                followingNodesOnPage,
                nodesOnNextPage,
                previousNodesOnPage
            ) {
                // don't break tables across page boundary
                // TODO may fail catastrophically if a table is longer than one page!
                if (currentNode.table && currentNode.pageNumbers.length > 1) {
                    //return true;
                }
                return false;
            };

            // add standard footer to all pages
            doc.footer = this.pdfFooter();

            return doc;
        },

        getClientAddress(client) {
            if (client?.addresses?.length) {
                return client.addresses[0];
            }
            return {
                address: null,
                city: 'Adelaide',
                region: 'SA',
                postcode: '5000',
            };
        },

        async generateDocumentPDF(sourceDocument, download = true) {
            // get linked reference data
            if (!sourceDocument.linked_ref) return;

            const refId = this.$fire.doc(sourceDocument.linked_ref).id;
            const orgDetails = this.$store.state.organisation;

            let refData = null;
            switch (sourceDocument.type) {
                case DOCUMENT_TYPES.ESTIMATE:
                    await this.bindNestedEstimate(refId);
                    refData = this.nested_estimate;
                    break;
                case DOCUMENT_TYPES.SUMMARY:
                    refData = await this.getProject(refId);
                    break;
                default:
                    const refSnapshot = await this.$fire
                        .doc(sourceDocument.linked_ref)
                        .get();
                    refData = refSnapshot.data();
            }

            // get client data from ref
            let clientName = '';
            let clientAddress = this.getClientAddress(null);
            let clientRef = refData.client;
            if (clientRef) {
                if (!clientRef.id) clientRef = this.$fire.doc(clientRef);
                const clientSnapshot = await clientRef.get();
                const clientData = clientSnapshot.data();
                clientName = clientData.name;
                clientAddress = this.getClientAddress(clientData);
            }

            const doc = this.pdfInitDocument();
            doc.content = [];

            // loop through sourceDocument sections and generate PDF output
            try {
                sourceDocument.blocks.forEach((block) => {
                    //TODO: this switch-case results in very tightly coupled code. Surely we can separate this somehow
                    switch (block.type) {
                        /** COVER SHEET ******************************************/
                        case DOCUMENT_BLOCK_TYPES.SUMMARY_COVER_SHEET: {
                            if (orgDetails) {
                                doc.content.push(
                                    orgDetails.name && {
                                        text: orgDetails.name.toUpperCase(),
                                        style: 'title',
                                    },
                                    orgDetails.address && {
                                        text: orgDetails.address,
                                        bold: true,
                                    },
                                    orgDetails.phone && {
                                        text: `Phone: ${orgDetails.phone}`,
                                        link: `tel:${orgDetails.phone}`,
                                        margin: [0, 10, 0, 0],
                                    },
                                    orgDetails.email && {
                                        text: `Email: ${orgDetails.email}`,
                                        link: `mailto:${orgDetails.email}`,
                                        margin: [0, 0, 0, 50],
                                    }
                                );
                            }

                            doc.content.push({
                                text: refData.name,
                                style: 'subtitle',
                                pageBreak: 'after',
                            });

                            break;
                        }

                        case DOCUMENT_BLOCK_TYPES.COVER_SHEET: {
                            if (orgDetails) {
                                doc.content.push(
                                    orgDetails.name && {
                                        text: orgDetails.name.toUpperCase(),
                                        style: 'title',
                                    },
                                    orgDetails.address && {
                                        text: orgDetails.address,
                                        bold: true,
                                    },
                                    orgDetails.phone && {
                                        text: `Phone: ${orgDetails.phone}`,
                                        link: `tel:${orgDetails.phone}`,
                                        margin: [0, 10, 0, 0],
                                    },
                                    orgDetails.email && {
                                        text: `Email: ${orgDetails.email}`,
                                        link: `mailto:${orgDetails.email}`,
                                    }
                                );
                            }

                            doc.content.push(
                                {
                                    style: 'address',
                                    stack: [
                                        {
                                            text: clientName,
                                            bold: true,
                                        },
                                        clientAddress.address,
                                        `${clientAddress.city}, ${
                                            clientAddress.region || ''
                                        } ${clientAddress.postcode || ''}`,
                                        {
                                            text: dayjs().format('DD.MM.YY'),
                                            margin: [0, 10, 0, 0],
                                        },
                                    ],
                                },
                                {
                                    text: refData.title,
                                    style: 'subtitle',
                                },
                                {
                                    bold: true,
                                    text: [
                                        refId,
                                        refData.description &&
                                        typeof refData.description === 'string'
                                            ? ` | ${refData.description}`
                                            : '',
                                    ],
                                    pageBreak: 'after',
                                }
                            );

                            break;
                        }

                        /** PAGE BREAK ******************************************/
                        case DOCUMENT_BLOCK_TYPES.PAGE_BREAK: {
                            doc.content.push({
                                text: '',
                                pageBreak: 'after',
                            });

                            break;
                        }

                        //text based document-block-types
                        case DOCUMENT_BLOCK_TYPES.AI_TEXT:
                        case DOCUMENT_BLOCK_TYPES.JIRA_LIST:
                        case DOCUMENT_BLOCK_TYPES.MODULE_LIST:
                        case DOCUMENT_BLOCK_TYPES.TEXT: {
                            if (block.heading) {
                                doc.content.push({
                                    text: block.heading,
                                    style: 'heading',
                                });
                            }

                            if (block.content) {
                                doc.content.push(
                                    this.pdfParseRichText(block.content)
                                );
                            }

                            doc.content.push({
                                text: '',
                                margin: [0, 0, 0, 20],
                            });

                            break;
                        }

                        /** ESTIMATE GROUP ****************************************/
                        case DOCUMENT_BLOCK_TYPES.ESTIMATE_GROUP: {
                            const groupId = this.$fire.doc(block.group).id;
                            const group = refData.groups.find(
                                (g) => g.id === groupId
                            );
                            if (!group) break;

                            const groupExcluded = this.isGroupExcluded(
                                group,
                                group.tasks
                            );

                            const values = block.values ?? [];

                            // Toggles
                            const showExcluded = values.includes(
                                DOCUMENT_GROUP_TOGGLES.EXCLUDED
                            );
                            const showSubtaskTime = values.includes(
                                DOCUMENT_GROUP_TOGGLES.SUBTASK_TIME
                            );

                            // Details
                            const showSubtasks = values.includes(
                                DOCUMENT_GROUP_DETAIL.SUBTASKS
                            );
                            const showDays = values.includes(
                                DOCUMENT_GROUP_DETAIL.DAYS
                            );
                            const showHours = values.includes(
                                DOCUMENT_GROUP_DETAIL.HOURS
                            );

                            const spanRows = showSubtasks && !showSubtaskTime;

                            if (!groupExcluded || showExcluded) {
                                doc.content.push({
                                    text: group.title,
                                    style: 'groupHeading',
                                    decoration: groupExcluded
                                        ? 'lineThrough'
                                        : null,
                                    color: groupExcluded ? '#aaa' : 'black',
                                });

                                if (group.tasks.length) {
                                    let tableSection = this.pdfGroupTable(
                                        showDays,
                                        showHours
                                    );
                                    group.tasks
                                        .filter((t) =>
                                            showExcluded ? true : !t.excluded
                                        )
                                        .forEach((task) => {
                                            const taskExcluded = this.isTaskExcluded(
                                                task,
                                                task.subtasks
                                            );

                                            const taskRow = [
                                                {
                                                    text: task.description,
                                                    bold: showSubtasks,
                                                    decoration: taskExcluded
                                                        ? 'lineThrough'
                                                        : null,
                                                    color: taskExcluded
                                                        ? '#aaa'
                                                        : 'black',
                                                    fillColor: showSubtaskTime
                                                        ? '#f4f4f4'
                                                        : null,
                                                },
                                            ];

                                            if (showDays) {
                                                taskRow.push({
                                                    text: Number(
                                                        this.task_days(
                                                            task
                                                        ).toFixed(1)
                                                    ),
                                                    bold: showSubtasks,
                                                    alignment: 'right',
                                                    rowSpan: spanRows
                                                        ? task.subtasks.length +
                                                          1
                                                        : 1,
                                                    decoration: taskExcluded
                                                        ? 'lineThrough'
                                                        : null,
                                                    color: taskExcluded
                                                        ? '#aaa'
                                                        : 'black',
                                                    fillColor: showSubtaskTime
                                                        ? '#f4f4f4'
                                                        : null,
                                                });
                                            }

                                            if (showHours) {
                                                taskRow.push({
                                                    text: Number(
                                                        this.task_hours(
                                                            task
                                                        ).toFixed(1)
                                                    ),
                                                    bold: showSubtasks,
                                                    alignment: 'right',
                                                    rowSpan: spanRows
                                                        ? task.subtasks.length +
                                                          1
                                                        : 1,
                                                    decoration: taskExcluded
                                                        ? 'lineThrough'
                                                        : null,
                                                    color: taskExcluded
                                                        ? '#aaa'
                                                        : 'black',
                                                    fillColor: showSubtaskTime
                                                        ? '#f4f4f4'
                                                        : null,
                                                });
                                            }

                                            tableSection.table.body.push(
                                                taskRow
                                            );

                                            if (showSubtasks) {
                                                // include subtasks
                                                task.subtasks
                                                    .filter((s) =>
                                                        showExcluded
                                                            ? true
                                                            : !s.excluded
                                                    )
                                                    .forEach((subtask) => {
                                                        const subtaskExcluded =
                                                            group.excluded ||
                                                            task.excluded ||
                                                            subtask.excluded;
                                                        if (!showSubtaskTime) {
                                                            tableSection.table.body.push(
                                                                [
                                                                    {
                                                                        text:
                                                                            subtask.description,
                                                                        margin: [
                                                                            10,
                                                                            0,
                                                                            0,
                                                                            0,
                                                                        ],
                                                                        decoration: subtaskExcluded
                                                                            ? 'lineThrough'
                                                                            : null,
                                                                        color: subtaskExcluded
                                                                            ? '#aaa'
                                                                            : 'black',
                                                                    },
                                                                ]
                                                            );
                                                        } else {
                                                            let subtaskDays = this.task_qty(
                                                                subtask
                                                            );
                                                            let subtaskHours =
                                                                subtaskDays *
                                                                task.hours_per_day;
                                                            if (
                                                                task.unit !==
                                                                'day'
                                                            ) {
                                                                subtaskHours = this.task_qty(
                                                                    subtask
                                                                );
                                                                subtaskDays =
                                                                    subtaskHours /
                                                                    task.hours_per_day;
                                                            }

                                                            const subtaskRow = [
                                                                {
                                                                    text:
                                                                        subtask.description,
                                                                    margin: [
                                                                        10,
                                                                        0,
                                                                        0,
                                                                        0,
                                                                    ],
                                                                    decoration: subtaskExcluded
                                                                        ? 'lineThrough'
                                                                        : null,
                                                                    color: subtaskExcluded
                                                                        ? '#aaa'
                                                                        : 'black',
                                                                },
                                                            ];
                                                            if (showDays) {
                                                                subtaskRow.push(
                                                                    {
                                                                        text: Number(
                                                                            subtaskDays.toFixed(
                                                                                1
                                                                            )
                                                                        ),
                                                                        alignment:
                                                                            'right',
                                                                        fontSize: 9,
                                                                        decoration: subtaskExcluded
                                                                            ? 'lineThrough'
                                                                            : null,
                                                                        color: subtaskExcluded
                                                                            ? '#aaa'
                                                                            : 'black',
                                                                    }
                                                                );
                                                            }
                                                            if (showHours) {
                                                                subtaskRow.push(
                                                                    {
                                                                        text: Number(
                                                                            subtaskHours.toFixed(
                                                                                1
                                                                            )
                                                                        ),
                                                                        alignment:
                                                                            'right',
                                                                        fontSize: 9,
                                                                        decoration: subtaskExcluded
                                                                            ? 'lineThrough'
                                                                            : null,
                                                                        color: subtaskExcluded
                                                                            ? '#aaa'
                                                                            : 'black',
                                                                    }
                                                                );
                                                            }

                                                            tableSection.table.body.push(
                                                                subtaskRow
                                                            );
                                                        }
                                                    });

                                                if (showSubtasks) {
                                                    // separator row
                                                    let cols = 1;
                                                    if (showDays) ++cols;
                                                    if (showHours) ++cols;
                                                    tableSection.table.body.push(
                                                        [
                                                            {
                                                                text: '',
                                                                fillColor:
                                                                    '#ddd',
                                                                colSpan: cols,
                                                            },
                                                        ]
                                                    );
                                                }
                                            }
                                        });

                                    if (showDays || showHours) {
                                        const groupTotalDays = group.tasks.reduce(
                                            (total, task) => {
                                                if (
                                                    !groupExcluded &&
                                                    this.isTaskExcluded(
                                                        task,
                                                        task.subtasks
                                                    )
                                                )
                                                    return total;
                                                return (total += this.task_days(
                                                    task
                                                ));
                                            },
                                            0
                                        );

                                        const groupTotalHours = group.tasks.reduce(
                                            (total, task) => {
                                                if (
                                                    !groupExcluded &&
                                                    this.isTaskExcluded(
                                                        task,
                                                        task.subtasks
                                                    )
                                                )
                                                    return total;
                                                return (total += this.task_hours(
                                                    task
                                                ));
                                            },
                                            0
                                        );

                                        const totalTimeRow = [
                                            {
                                                text: 'Total Time',
                                                alignment: 'right',
                                                bold: true,
                                            },
                                        ];
                                        if (showDays) {
                                            totalTimeRow.push({
                                                text: Number(
                                                    groupTotalDays.toFixed(1)
                                                ),
                                                alignment: 'right',
                                                bold: true,
                                                decoration: groupExcluded
                                                    ? 'lineThrough'
                                                    : null,
                                                color: groupExcluded
                                                    ? '#aaa'
                                                    : 'black',
                                            });
                                        }
                                        if (showHours) {
                                            totalTimeRow.push({
                                                text: Number(
                                                    groupTotalHours.toFixed(1)
                                                ),
                                                alignment: 'right',
                                                bold: true,
                                                decoration: groupExcluded
                                                    ? 'lineThrough'
                                                    : null,
                                                color: groupExcluded
                                                    ? '#aaa'
                                                    : 'black',
                                            });
                                        }

                                        tableSection.table.body.push(
                                            totalTimeRow
                                        );
                                    } else if (!showSubtasks) {
                                        // empty footer for styling
                                        tableSection.table.body.push([
                                            {
                                                text: '',
                                            },
                                        ]);
                                    }

                                    doc.content.push(tableSection);
                                }
                            }

                            break;
                        }

                        /** ESTIMATE SUMMARY ***************************************/
                        case DOCUMENT_BLOCK_TYPES.ESTIMATE_SUMMARY: {
                            const values = block.values ?? [];

                            // Toggles
                            const showExcluded = values.includes(
                                DOCUMENT_SUMMARY_TOGGLES.EXCLUDED
                            );
                            const showTaskCost = values.includes(
                                DOCUMENT_SUMMARY_TOGGLES.TASK_COST
                            );
                            const showGST = values.includes(
                                DOCUMENT_SUMMARY_TOGGLES.GST
                            );

                            // Details
                            const showTasks = values.includes(
                                DOCUMENT_SUMMARY_DETAIL.TASKS
                            );
                            const showDays = values.includes(
                                DOCUMENT_SUMMARY_DETAIL.DAYS
                            );
                            const showHours = values.includes(
                                DOCUMENT_SUMMARY_DETAIL.HOURS
                            );
                            const showCost = values.includes(
                                DOCUMENT_SUMMARY_DETAIL.COST
                            );

                            let cols = 0;
                            if (showDays) ++cols;
                            if (showHours) ++cols;
                            if (showCost) ++cols;
                            const columns = cols || 1;

                            let dailyRate = refData.rate;
                            if (refData.unit === 'hour') {
                                dailyRate = dailyRate * refData.hours_per_day;
                            }

                            // totals of estimate groups
                            let summarySection = this.pdfSummaryTable(
                                showDays,
                                showHours,
                                showCost
                            );
                            let finalTotalDays = 0;
                            let finalTotalHours = 0;
                            let finalTotalCost = 0;

                            const orderedGroups = this.sortGroupsInDocumentOrder(
                                refData.groups,
                                sourceDocument
                            );
                            orderedGroups.forEach((group) => {
                                const groupExcluded = this.isGroupExcluded(
                                    group,
                                    group.tasks
                                );

                                const filteredTasks = group.tasks.filter(
                                    (task) => {
                                        task.excluded = this.isTaskExcluded(
                                            task,
                                            task.subtasks
                                        );
                                        if (showExcluded || !task.excluded)
                                            return task;
                                    }
                                );

                                const finalGroupDays = filteredTasks.reduce(
                                    (total, task) => {
                                        if (!groupExcluded && task.excluded) {
                                            return total;
                                        }
                                        return (total += this.task_days(task));
                                    },
                                    0
                                );
                                const finalGroupHours = filteredTasks.reduce(
                                    (total, task) => {
                                        if (!groupExcluded && task.excluded) {
                                            return total;
                                        }
                                        return (total += this.task_hours(task));
                                    },
                                    0
                                );

                                if (showExcluded || !groupExcluded) {
                                    const finalGroupTotal = this.getTotalForGroup(
                                        group,
                                        group.tasks,
                                        groupExcluded ? true : false
                                    );

                                    let groupTitle = [
                                        {
                                            text: group.title,
                                            decoration: groupExcluded
                                                ? 'lineThrough'
                                                : null,
                                            color: groupExcluded
                                                ? '#aaa'
                                                : 'black',
                                            bold: showTasks,
                                        },
                                    ];

                                    // show discount if set, not excluded
                                    if (!groupExcluded && group.discount > 0) {
                                        groupTitle.push({
                                            text: `\nincludes ${group.discount}% discount`,
                                            style: 'footnote',
                                        });
                                    }

                                    const groupRow = [
                                        {
                                            text: groupTitle,
                                            fillColor:
                                                showTasks && showTaskCost
                                                    ? '#f4f4f4'
                                                    : null,
                                        },
                                    ];

                                    if (showDays) {
                                        groupRow.push({
                                            text: Number(
                                                finalGroupDays.toFixed(1)
                                            ),
                                            bold: showTasks && showTaskCost,
                                            alignment: 'right',
                                            decoration: groupExcluded
                                                ? 'lineThrough'
                                                : null,
                                            color: groupExcluded
                                                ? '#aaa'
                                                : 'black',
                                            fillColor:
                                                showTasks && showTaskCost
                                                    ? '#f4f4f4'
                                                    : null,
                                            rowSpan:
                                                showTasks && !showTaskCost
                                                    ? filteredTasks.length + 1
                                                    : 1,
                                        });
                                    }

                                    if (showHours) {
                                        groupRow.push({
                                            text: Number(
                                                finalGroupHours.toFixed(1)
                                            ),
                                            bold: showTasks && showTaskCost,
                                            alignment: 'right',
                                            decoration: groupExcluded
                                                ? 'lineThrough'
                                                : null,
                                            color: groupExcluded
                                                ? '#aaa'
                                                : 'black',
                                            fillColor:
                                                showTasks && showTaskCost
                                                    ? '#f4f4f4'
                                                    : null,
                                            rowSpan:
                                                showTasks && !showTaskCost
                                                    ? filteredTasks.length + 1
                                                    : 1,
                                        });
                                    }

                                    if (showCost) {
                                        groupRow.push({
                                            text: this.$options.filters.currency(
                                                finalGroupTotal
                                            ),
                                            bold: showTasks && showTaskCost,
                                            alignment: 'right',
                                            decoration: groupExcluded
                                                ? 'lineThrough'
                                                : null,
                                            color: groupExcluded
                                                ? '#aaa'
                                                : 'black',
                                            fillColor:
                                                showTasks && showTaskCost
                                                    ? '#f4f4f4'
                                                    : null,
                                            rowSpan:
                                                showTasks && !showTaskCost
                                                    ? filteredTasks.length + 1
                                                    : 1,
                                        });
                                    }

                                    if (!showDays && !showHours && !showCost) {
                                        groupRow.push({
                                            text: '',
                                        });
                                    }

                                    summarySection.table.body.push(groupRow);

                                    if (!groupExcluded) {
                                        finalTotalDays += finalGroupDays;
                                        finalTotalHours += finalGroupHours;
                                        finalTotalCost += finalGroupTotal;
                                    }
                                }

                                if (showTasks) {
                                    for (const task of filteredTasks) {
                                        const taskRow = [
                                            {
                                                text: task.description,
                                                alignment: 'left',
                                                margin: [10, 0, 0, 0],
                                                color: task.excluded
                                                    ? '#aaa'
                                                    : 'black',
                                                decoration: task.excluded
                                                    ? 'lineThrough'
                                                    : null,
                                            },
                                        ];

                                        if (showDays) {
                                            taskRow.push({
                                                text: Number(
                                                    this.task_days(
                                                        task
                                                    ).toFixed(1)
                                                ),
                                                alignment: 'right',
                                                color: task.excluded
                                                    ? '#aaa'
                                                    : 'black',
                                                decoration: task.excluded
                                                    ? 'lineThrough'
                                                    : null,
                                            });
                                        }
                                        if (showHours) {
                                            taskRow.push({
                                                text: Number(
                                                    this.task_hours(
                                                        task
                                                    ).toFixed(1)
                                                ),
                                                alignment: 'right',
                                                color: task.excluded
                                                    ? '#aaa'
                                                    : 'black',
                                                decoration: task.excluded
                                                    ? 'lineThrough'
                                                    : null,
                                            });
                                        }
                                        if (showCost) {
                                            taskRow.push({
                                                text: this.$options.filters.currency(
                                                    task.cost
                                                ),
                                                alignment: 'right',
                                                color: task.excluded
                                                    ? '#aaa'
                                                    : 'black',
                                                decoration: task.excluded
                                                    ? 'lineThrough'
                                                    : null,
                                            });
                                        }
                                        if (
                                            !showDays &&
                                            !showHours &&
                                            !showCost
                                        ) {
                                            taskRow.push({
                                                text: '',
                                            });
                                        }

                                        summarySection.table.body.push(taskRow);
                                    }
                                }
                            });

                            if (showDays || showHours) {
                                const totalTimeRow = [
                                    {
                                        text: 'Total Time',
                                        alignment: 'right',
                                        fillColor: '#eee',
                                    },
                                ];

                                if (showDays) {
                                    totalTimeRow.push({
                                        text: Number(finalTotalDays.toFixed(1)),
                                        alignment: 'right',
                                        fillColor: '#eee',
                                    });
                                }
                                if (showHours) {
                                    totalTimeRow.push({
                                        text: Number(
                                            finalTotalHours.toFixed(1)
                                        ),
                                        alignment: 'right',
                                        fillColor: '#eee',
                                    });
                                }
                                if (showCost) {
                                    totalTimeRow.push({
                                        text: '',
                                        fillColor: '#eee',
                                    });
                                }

                                summarySection.table.body.push(totalTimeRow);
                            } else if (showGST && !refData.credit?.value) {
                                summarySection.table.body.push([
                                    {
                                        text: '',
                                        fillColor: '#eee',
                                        colSpan: columns + 1,
                                    },
                                ]);
                            }

                            const spanFiller = [];
                            for (let i = 1; i < columns; i++) {
                                spanFiller.push({text: ''});
                            }

                            if (refData.credit?.value) {
                                // subtotal
                                summarySection.table.body.push(
                                    [
                                        {
                                            text: 'Subtotal',
                                            alignment: 'right',
                                            bold: false,
                                            fillColor: '#ccc',
                                            colSpan: columns,
                                        },
                                        ...spanFiller,
                                        {
                                            text: {
                                                text: this.$options.filters.currency(
                                                    finalTotalCost
                                                ),
                                                bold: true,
                                            },
                                            alignment: 'right',
                                            fillColor: '#ccc',
                                        },
                                    ],
                                    // credit row
                                    [
                                        {
                                            text:
                                                refData.credit.label ||
                                                'Credit',
                                            bold: false,
                                            alignment: 'right',
                                            colSpan: columns,
                                        },
                                        ...spanFiller,
                                        {
                                            text: this.$options.filters.currency(
                                                -refData.credit.value
                                            ),
                                            // bold: true,
                                            alignment: 'right',
                                        },
                                    ]
                                );

                                finalTotalCost -= refData.credit.value;
                            }

                            if (showGST) {
                                const finalTotalGST = finalTotalCost * 0.1;
                                summarySection.table.body.push(
                                    [
                                        {
                                            text: 'Subtotal',
                                            bold: false,
                                            alignment: 'right',
                                            colSpan: columns,
                                        },
                                        ...spanFiller,
                                        {
                                            text: [
                                                {
                                                    text: this.$options.filters.currency(
                                                        finalTotalCost
                                                    ),
                                                    bold: false,
                                                },
                                            ],
                                            alignment: 'right',
                                        },
                                    ],
                                    [
                                        {
                                            text: 'GST',
                                            bold: false,
                                            alignment: 'right',
                                            colSpan: columns,
                                        },
                                        ...spanFiller,
                                        {
                                            text: [
                                                {
                                                    text: this.$options.filters.currency(
                                                        finalTotalGST
                                                    ),
                                                    bold: false,
                                                },
                                            ],
                                            alignment: 'right',
                                        },
                                    ],
                                    [
                                        {
                                            text: 'Total',
                                            bold: true,
                                            alignment: 'right',
                                            colSpan: columns,
                                        },
                                        ...spanFiller,
                                        {
                                            text: [
                                                {
                                                    text: this.$options.filters.currency(
                                                        finalTotalCost +
                                                            finalTotalGST
                                                    ),
                                                    bold: true,
                                                },
                                            ],
                                            alignment: 'right',
                                        },
                                    ]
                                );
                            } else {
                                summarySection.table.body.push([
                                    {
                                        text: [
                                            {
                                                text: 'ex. GST   ',
                                                fontSize: 8,
                                            },
                                            {
                                                text: 'Total',
                                                bold: true,
                                            },
                                        ],
                                        colSpan: columns,
                                        alignment: 'right',
                                    },
                                    ...spanFiller,
                                    {
                                        text: [
                                            {
                                                text: this.$options.filters.currency(
                                                    finalTotalCost
                                                ),
                                                bold: true,
                                            },
                                        ],
                                        alignment: 'right',
                                        colSpan: 1,
                                    },
                                ]);
                            }

                            doc.content.push(summarySection);

                            if (!showGST) {
                                doc.content.push({
                                    text:
                                        '* All prices are displayed excluding GST',
                                    style: 'footnote',
                                });
                            }
                            doc.content.push({
                                text: `** Prices are calculated at a rate of ${this.$options.filters.currency(
                                    dailyRate,
                                    false,
                                    false
                                )} per day`,
                                style: 'footnote',
                                margin: [0, 0, 0, 30],
                            });

                            break;
                        }

                        /** AGREEMENT ***************************************
                         * This is a predefined block that always renders the same.
                         ****************************************************/

                        case DOCUMENT_BLOCK_TYPES.AGREEMENT: {
                            const legal_name =
                                orgDetails?.legal_name || orgDetails?.name;

                            doc.content.push(
                                {
                                    text: 'Agreement',
                                    style: 'heading',
                                    pageBreak: 'before',
                                },
                                {
                                    text: [
                                        'In closing, please ensure this proposal has been reviewed fully and by all means, ',
                                        "if you have any queries please don't hesitate to contact us.",
                                    ],
                                    margin: [0, 0, 0, 10],
                                },
                                orgDetails &&
                                    orgDetails.agreement_clause && {
                                        text: orgDetails.agreement_clause,
                                        bold: true,
                                        margin: [0, 0, 0, 25],
                                    },
                                {
                                    text: [
                                        'Signed by ',
                                        {
                                            text: clientName,
                                            bold: true,
                                        },
                                    ],
                                    margin: [0, 0, 0, 10],
                                },
                                this.signatureForm(),
                                {
                                    text: [
                                        'Signed by ',
                                        legal_name && {
                                            text: legal_name,
                                            bold: true,
                                        },
                                    ],
                                    margin: [0, 0, 0, 10],
                                },
                                this.signatureForm()
                            );

                            break;
                        }
                    }
                });

                const pdfGenerator = pdfMake.createPdf(doc);
                if (download) {
                    // download immediately
                    pdfGenerator.download(`${sourceDocument.name}`);
                } else {
                    // set PDF data for preview or deferred download
                    this.pdf_data = await new Promise(function (resolve) {
                        pdfGenerator.getBase64(function (data) {
                            resolve(data);
                        });
                    });
                }
            } catch (err) {
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: `An error occurred while generating pdf, ${err}.`,
                });
            }
        },
    },
};

export default pdfMixin;
