var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("fullscreen-loader", {
        attrs: { message: `Generating ${_vm.report_name}...` },
      })
    : _vm.report
    ? _c(
        "div",
        {
          staticClass: "report-wrapper",
          class: { show_tracking_bar: _vm.show_tracking_bar },
        },
        [
          _c("div", { staticClass: "report-table" }, [
            _c(
              "div",
              {
                ref: "report_header",
                staticClass: "row report-header",
                class: { shadow: _vm.sticky_header },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "cell project-col",
                    class: { shadow: _vm.sticky_left },
                  },
                  [
                    _c("financial-year-selector", {
                      model: {
                        value: _vm.fy,
                        callback: function ($$v) {
                          _vm.fy = $$v
                        },
                        expression: "fy",
                      },
                    }),
                  ],
                  1
                ),
                _vm._l(_vm.table_months, function (month) {
                  return _c(
                    "div",
                    {
                      key: month,
                      staticClass: "cell month-col",
                      class: _vm.current_month === month ? "current" : "",
                    },
                    [_vm._v(" " + _vm._s(month) + " ")]
                  )
                }),
                _c(
                  "div",
                  {
                    staticClass: "cell total-col",
                    class: { shadow: _vm.sticky_right },
                  },
                  [_vm._v(" Total ")]
                ),
              ],
              2
            ),
            _c(
              "div",
              {
                ref: "report_body",
                staticClass: "report-body",
                on: { scroll: _vm.handleHorizontalScroll },
              },
              _vm._l(Object.entries(_vm.report.projects), function ([
                prj,
                project,
              ]) {
                return _c(
                  "div",
                  { key: prj, staticClass: "row report-row" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "cell project-col",
                        class: { shadow: _vm.sticky_left },
                      },
                      [_vm._v(" " + _vm._s(prj) + " ")]
                    ),
                    _vm._l(_vm.table_months, function (month) {
                      return _c(
                        "div",
                        { key: month, staticClass: "cell month-col" },
                        [
                          project.months[month]?.total > 0
                            ? _c("project-month", {
                                attrs: {
                                  value: project.months[month],
                                  project_path: project.path,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "cell total-col",
                        class: { shadow: _vm.sticky_right },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm._f("currency")(project.total)) + " "
                        ),
                      ]
                    ),
                  ],
                  2
                )
              }),
              0
            ),
            _c(
              "div",
              {
                ref: "report_footer",
                staticClass: "report-footer",
                class: { shadow: _vm.sticky_footer },
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "cell project-col",
                        class: { shadow: _vm.sticky_left },
                      },
                      [
                        _c(
                          "amount-popover",
                          {
                            attrs: { title: `Set breakeven for FY ${_vm.fy}` },
                            model: {
                              value: _vm.breakeven,
                              callback: function ($$v) {
                                _vm.breakeven = $$v
                              },
                              expression: "breakeven",
                            },
                          },
                          [
                            _c("div", { staticClass: "summary-title" }, [
                              _vm._v(" Breakeven "),
                              _vm.breakeven === null
                                ? _c("div", { staticClass: "value" }, [
                                    _vm._v(" Click to set "),
                                  ])
                                : _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(_vm.breakeven)
                                        ) +
                                        "/mth "
                                    ),
                                  ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.table_months, function (month, index) {
                      return _c(
                        "div",
                        { key: month, staticClass: "cell month-col" },
                        [
                          _vm.report.month_totals[month] !== null
                            ? [
                                _vm.breakeven !== null
                                  ? [
                                      _vm.total_mode === "monthly"
                                        ? _c("breakeven-value", {
                                            attrs: {
                                              breakeven: _vm.breakeven,
                                              value:
                                                _vm.report.month_totals[month],
                                            },
                                          })
                                        : _c("breakeven-value", {
                                            attrs: {
                                              breakeven:
                                                _vm.breakeven * (index + 1),
                                              value:
                                                _vm.report.ytd_totals[month],
                                            },
                                          }),
                                    ]
                                  : _c("div", { staticClass: "amount bold" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.report.month_totals[month]
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "cell total-col",
                        class: { shadow: _vm.sticky_right },
                      },
                      [
                        _vm.breakeven !== null
                          ? _c("breakeven-value", {
                              staticStyle: { "margin-right": "-10px" },
                              attrs: {
                                value: _vm.report.total,
                                breakeven: _vm.breakeven * _vm.month_count,
                                bold: "",
                              },
                            })
                          : _c("div", { staticClass: "amount bold" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("currency")(_vm.report.total)) +
                                  " "
                              ),
                            ]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "cell project-col",
                        class: { shadow: _vm.sticky_left },
                      },
                      [
                        _c(
                          "amount-popover",
                          {
                            attrs: {
                              title: `Set revenue goal for FY ${_vm.fy}`,
                            },
                            model: {
                              value: _vm.revenue_goal,
                              callback: function ($$v) {
                                _vm.revenue_goal = $$v
                              },
                              expression: "revenue_goal",
                            },
                          },
                          [
                            _c("div", { staticClass: "summary-title" }, [
                              _vm._v(" Revenue goal "),
                              _vm.revenue_goal === null
                                ? _c("div", { staticClass: "value" }, [
                                    _vm._v(" Click to set "),
                                  ])
                                : _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(_vm.revenue_goal)
                                        ) +
                                        "/mth "
                                    ),
                                  ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.table_months, function (month, index) {
                      return _c(
                        "div",
                        { key: month, staticClass: "cell month-col" },
                        [
                          _vm.report.month_totals[month] !== null &&
                          _vm.revenue_goal !== null
                            ? [
                                _vm.total_mode === "monthly"
                                  ? _c("revenue-goal-value", {
                                      attrs: {
                                        goal: _vm.revenue_goal,
                                        value: _vm.report.month_totals[month],
                                      },
                                    })
                                  : _c("revenue-goal-value", {
                                      attrs: {
                                        goal: _vm.revenue_goal * (index + 1),
                                        value: _vm.report.ytd_totals[month],
                                      },
                                    }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "cell total-col",
                        class: { shadow: _vm.sticky_right },
                      },
                      [
                        _vm.revenue_goal !== null
                          ? _c("revenue-goal-value", {
                              staticStyle: { "margin-right": "-10px" },
                              attrs: {
                                value: _vm.report.total,
                                goal: _vm.revenue_goal * _vm.month_count,
                                bold: "",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }