var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            { name: "only-super-admin", rawName: "v-only-super-admin" },
          ],
          staticClass: "container",
        },
        [
          _c("label", [
            _vm._v(" Sonos "),
            _vm.is_authenticated && _vm.household
              ? _c("span", [_vm._v(" - " + _vm._s(_vm.household.name) + " ")])
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _vm.loading
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: true,
                          expression: "true",
                        },
                      ],
                      staticClass: "spinner",
                      attrs: {
                        disabled: "",
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(0, 0, 0, 0)",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-loading",
                        staticStyle: { visibility: "hidden" },
                      }),
                    ]
                  )
                : [
                    !_vm.is_authenticated
                      ? _c(
                          "el-button",
                          {
                            attrs: { disabled: _vm.loading },
                            on: { click: _vm.redirectToSonosAuth },
                          },
                          [_vm._v(" Connect ")]
                        )
                      : [
                          _c("el-button", { attrs: { disabled: "" } }, [
                            _vm._v(" Connected "),
                          ]),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "flex-grow": "inherit" },
                              on: { click: _vm.handleDisconnect },
                            },
                            [_c("i", { staticClass: "el-icon-delete" })]
                          ),
                        ],
                  ],
            ],
            2
          ),
        ]
      ),
      _vm.is_authenticated
        ? _vm._l(_vm.groups, function (group) {
            return _c("sonos-group-player", {
              key: group.id,
              attrs: { group: group, show_name: _vm.groups.length > 1 },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }