<template>
    <div
        class="dot"
        :style="{backgroundColor: color, animation: animated ? null : 'none'}"
    />
</template>

<script>
export default {
    name: 'live-dot',
    props: {
        color: {
            type: String,
            default: null,
        },
        animated: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dot {
    width: 6px;
    height: 6px;
    background-color: $red;
    border-radius: 3px;
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 1px;
    animation: PULSE 1.4s infinite;
}
</style>
