<template>
    <div>
        <content-block title="Trackers">
            <flat-cube @selected="faceSelected" />
        </content-block>

        <user-day-tracker
            :user="current_user"
            :show_tracking_block="false"
            theme="dark"
        />

        <content-block title="Timeline">
            <div slot="right" v-only-admin class="users">
                <div
                    v-for="user in users"
                    :key="user.id"
                    class="user-color"
                    :class="[
                        `user_${user.id}`,
                        {active: active_users.includes(user.id)},
                    ]"
                    :style="{
                        backgroundColor: user.color || '#1989fa',
                    }"
                    @click="toggleUser(user)"
                >
                    <span>{{ user.name }}</span>
                </div>
            </div>

            <sessions-timeline :active_users="active_users" />
        </content-block>
    </div>
</template>

<script>
import UserDayTracker from '@/components/generic/UserDayTracker';
import FlatCube from '@/pages/sessions/components/FlatCube';
import SessionsTimeline from '@/pages/sessions/components/SessionsTimeline';
import ContentBlock from '@/components/blocks/ContentBlock';

import userMixin from '@/mixins/user.mixin';

export default {
    name: 'sessions',
    components: {
        UserDayTracker,
        ContentBlock,
        FlatCube,
        SessionsTimeline,
    },
    mixins: [userMixin],
    data() {
        return {
            sessions: [],
            show_dialog: false,
            active_users: [this.$store.getters.user.id.toString()],
        };
    },
    methods: {
        faceSelected(face_id) {},
        toggleUser(usr) {
            var index = this.active_users.indexOf(usr.id);
            if (index > -1) this.active_users.splice(index, 1);
            else this.active_users.push(usr.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.associate {
    padding-top: 20px;

    &.disable {
        pointer-events: none;
    }

    .col-border {
        border-right: 1px solid $border-grey-light;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .el-col {
        position: relative;
    }

    .exploded-cube {
        width: 100%;
        height: 490px;
        overflow: inherit;
        position: relative;
        display: flex;
        justify-self: center;
        align-items: center;
    }

    .loading {
        width: 100%;
        height: 490px;
    }

    .slide {
        &-enter-active,
        &-leave-active {
            transition: opacity 0.15s ease-in-out, transform 0.2s ease-in-out;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
            transform: translateX(5px);
        }
    }
}

.users {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .user-color {
        padding: 2px 20px;
        border-radius: 10px;
        margin-right: 8px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        user-select: none;

        .dot {
            margin-right: 15px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
        }

        span {
            color: black;
            font-size: 11px;
            font-weight: 400;
        }

        &:not(.active) {
            background-color: transparent !important;
        }

        &.active {
            span {
                color: white;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .users {
        display: none;
    }
}
</style>
