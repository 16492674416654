<template>
    <div>
        <actions-buttons>
            <el-tabs v-model="activeTab" @tab-click="tabChanged">
                <el-tab-pane label="Overview" name="_overview" />
                <el-tab-pane label="Activity" name="_activity" />
                <el-tab-pane
                    v-if="user_is_admin"
                    label="Settings"
                    name="_settings"
                />
            </el-tabs>
        </actions-buttons>

        <transition name="slide" mode="out-in">
            <ComponentTab_overview
                v-if="activeTab == '_overview'"
                key="overview"
            />
            <ComponentTab_activity
                v-else-if="activeTab == '_activity'"
                key="activity"
            />
            <ComponentTab_settings
                v-else-if="activeTab == '_settings'"
                key="settings"
            />
        </transition>
    </div>
</template>

<script>
import ActionsButtons from '@/components/generic/ActionsButtons';

import ComponentTab_overview from '@/pages/components/tabs/ComponentTab_overview';
import ComponentTab_activity from '@/pages/components/tabs/ComponentTab_activity';
import ComponentTab_settings from '@/pages/components/tabs/ComponentTab_settings';

import userMixin from '@/mixins/user.mixin';

import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';

export default {
    name: 'component-detail',
    components: {
        ActionsButtons,
        ComponentTab_overview,
        ComponentTab_activity,
        ComponentTab_settings,
    },
    mixins: [userMixin],
    data() {
        return {
            activeTab: '_overview',
        };
    },
    computed: {
        SUPPORTED_FEATURES() {
            return SUPPORTED_FEATURES;
        },
    },
    watch: {
        $route(to) {
            this.activeTab = to.meta.tab;
        },
    },
    created() {
        this.activeTab = this.$router.currentRoute.meta.tab;
    },
    mounted() {
        if (!this.$store.getters.projectWithId(this.$route.params.project_id)) {
            // project not found, navigate to projects list
            this.$router.replace({name: 'projects'});
            return;
        }

        if (
            !this.$store.getters.stageWithId(this.$route.params.stage_id) ||
            !this.$store.getters.componentWithId(
                this.$route.params.component_id
            )
        ) {
            // component or stage nto found, navigate to project components list
            this.$router.replace({
                name: 'project_detail_components',
                params: {project_id: this.$route.params.project_id},
            });
            return;
        }
    },
    methods: {
        tabChanged(event) {
            this.$router
                .replace({
                    name: `component_detail${event.name}`,
                    params: {
                        project_id: this.$route.params.project_id,
                        stage_id: this.$route.params.stage_id,
                        component_id: this.$route.params.component_id,
                    },
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.slide {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateX(5px);
    }
}
</style>
