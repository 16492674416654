<template>
    <div>
        <div class="block">
            <el-button @click="moveSessions">Move sessions</el-button>
            <div>
                <span>Date filters are needed</span>
            </div>
        </div>
        <div class="block_options checkbox">
            <el-checkbox v-model="move_sessions.move_all">
                Move all sessions in selected component
            </el-checkbox>
        </div>
        <div class="block_options">
            <el-select
                v-model="move_sessions.users"
                filterable
                multiple
                placeholder="Filter by users"
                value-key="id"
                size="mini"
                :disabled="move_sessions.move_all"
            >
                <el-option
                    v-for="u in users"
                    :key="u.id"
                    :label="u.name"
                    :value="u"
                />
            </el-select>
            <el-input
                v-model="move_sessions.note"
                size="mini"
                placeholder="Filter by note"
                :disabled="move_sessions.move_all"
            />
        </div>
        <div class="block_options">
            <el-date-picker
                v-model="move_sessions.from_date"
                type="date"
                size="mini"
                :clearable="false"
                format="dd/MM/yyyy"
                :disabled="move_sessions.move_all"
            />
            <el-date-picker
                v-model="move_sessions.to_date"
                type="date"
                size="mini"
                :clearable="false"
                format="dd/MM/yyyy"
                :disabled="move_sessions.move_all"
            />
        </div>
        <div class="block_options">
            <project-picker
                :projects="projects"
                :project="move_sessions.from_project"
                placeholder="From Project"
                @change="changedFromProject"
            />
            <i class="el-icon-right" style="width: 20px;" />
            <project-picker
                :projects="projects"
                :project="move_sessions.to_project"
                placeholder="To Project"
                @change="changedToProject"
            />
        </div>
        <div v-if="move_sessions.from_project" class="block_options">
            <component-picker
                :project_id="move_sessions.from_project.id"
                :component="move_sessions.from_component"
                placeholder="From Component"
                @change="
                    (val) => {
                        move_sessions.from_component = val;
                    }
                "
            />
            <i class="el-icon-right" style="width: 20px;" />
            <component-picker
                :project_id="move_sessions.to_project.id"
                :component="move_sessions.to_component"
                placeholder="To Component"
                @change="
                    (val) => {
                        move_sessions.to_component = val;
                    }
                "
            />
        </div>
    </div>
</template>

<script>
import {momentWithTz} from '@/utils';
import firebase from 'firebase/app';

import {Notification} from 'element-ui';

import ProjectPicker from '@/components/generic/ProjectPicker';
import ComponentPicker from '@/components/generic/ComponentPicker';

export default {
    name: 'move-sessions',
    components: {
        ComponentPicker,
        ProjectPicker,
    },
    data() {
        return {
            move_sessions: {
                from_project: null,
                to_project: null,
                users: [],
                note: null,
                from_date: null,
                to_date: null,
                from_component: null,
                to_component: null,
                move_all: false,
            },
        };
    },
    computed: {
        projects() {
            return this.$store.getters.projects;
        },
        users() {
            return this.$store.getters.users;
        },
    },
    methods: {
        changedFromProject(val) {
            this.move_sessions.from_project = val;
            this.move_sessions.to_project = val;
            this.move_sessions.from_component = null;
            this.move_sessions.to_component = null;
        },
        changedToProject(val) {
            this.move_sessions.to_project = val;
            this.move_sessions.to_component = null;
        },
        async moveSessions() {
            Notification({
                type: 'info',
                title: 'Fetching',
                message: 'sessions to move...',
            });

            try {
                const range_start = !this.move_sessions.move_all
                    ? momentWithTz(this.move_sessions.from_date)
                          .startOf('day')
                          .toDate()
                    : null;

                const range_end = !this.move_sessions.move_all
                    ? momentWithTz(this.move_sessions.to_date)
                          .endOf('day')
                          .toDate()
                    : null;

                const from_component__snap = await this.$fire
                    .doc(`components/${this.move_sessions.from_component.id}`)
                    .get();
                const to_component__snap = await this.$fire
                    .doc(`components/${this.move_sessions.to_component.id}`)
                    .get();

                const from_component = await from_component__snap.data();
                const to_component = await to_component__snap.data();

                await this.moveComponentSessions(
                    from_component,
                    to_component,
                    range_start,
                    range_end
                );

                this.move_sessions.users = null;
                this.move_sessions.note = null;
                this.move_sessions.from_date = null;
                this.move_sessions.to_date = null;
                this.move_sessions.from_project = null;
                this.move_sessions.to_project = null;
                this.move_sessions.from_component = null;
                this.move_sessions.to_component = null;

                Notification({
                    type: 'success',
                    title: 'Done',
                });
            } catch (e) {
                console.log('Error moving sessions: ', e);

                Notification({
                    type: 'error',
                    title: 'Error moving sessions',
                    message: e.message ?? null,
                });
            }
        },

        async moveComponentSessions(from, to, range_start, range_end) {
            // find sessions to move for the given component
            let sessionsQuery = this.$fire
                .collection('sessions')
                .where(
                    'component',
                    '==',
                    this.$fire.doc(
                        `components/${this.move_sessions.from_component.id}`
                    )
                );

            // filter for the time range - if provided
            if (range_start && range_end) {
                sessionsQuery = sessionsQuery
                    .where('start', '>=', range_start)
                    .where('start', '<=', range_end);
            }

            const sessionsRef = await sessionsQuery.get();
            const sessionsList = [];

            // filter sessions by users / note
            for (const doc of sessionsRef.docs) {
                const sess = doc.data();

                if (
                    ((!this.move_sessions.users ||
                        this.move_sessions.users.length === 0 ||
                        this.move_sessions.users
                            .map((u) => u.id)
                            .includes(sess.user.id)) &&
                        !this.move_sessions.note) ||
                    (sess.note &&
                        this.move_sessions.note &&
                        sess.note
                            .toLowerCase()
                            .includes(this.move_sessions.note.toLowerCase()))
                ) {
                    sessionsList.push(doc.ref);
                }
            }

            // Add count of sessions to both components' batches.
            // After each move is processed by the cloud trigger, this value will decrement.
            // Final calculations are deferred until after the last operation in a batch.
            await this.$fire
                .doc(`components/${this.move_sessions.from_component.id}`)
                .update({
                    batch_count: firebase.firestore.FieldValue.increment(
                        sessionsList.length
                    ),
                });
            await this.$fire
                .doc(`components/${this.move_sessions.to_component.id}`)
                .update({
                    batch_count: firebase.firestore.FieldValue.increment(
                        sessionsList.length
                    ),
                });

            // perform each move operation sequentially, so as not to overload the cloud trigger
            // TODO Still necessary? Revert to promise.all after batch revisions?
            for (const session of sessionsList) {
                await session.update({
                    component: this.$fire.doc(
                        `components/${this.move_sessions.to_component.id}`
                    ),
                    stage: this.$fire.doc(
                        this.move_sessions.to_component.stage
                    ),
                    project: this.$fire.doc(
                        this.move_sessions.to_component.project
                    ),
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.block_options {
    width: 410px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    & > * {
        margin-right: 5px;
        width: 200px;
    }

    &.checkbox {
        ::v-deep span {
            margin-bottom: 0;
        }
    }
}
</style>
