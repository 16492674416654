var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outer" }, [
    _c(
      "header",
      [
        _c(
          "el-radio-group",
          {
            attrs: { size: "mini" },
            model: {
              value: _vm.view,
              callback: function ($$v) {
                _vm.view = $$v
              },
              expression: "view",
            },
          },
          [
            _c(
              "el-radio-button",
              { attrs: { label: _vm.ESTIMATE_STATUS.DRAFT } },
              [_vm._v(" Draft ")]
            ),
            _c(
              "el-radio-button",
              { attrs: { label: _vm.ESTIMATE_STATUS.PENDING } },
              [_vm._v(" In Review ")]
            ),
            _c(
              "el-radio-button",
              { attrs: { label: _vm.ESTIMATE_STATUS.SENT } },
              [_vm._v(" Sent ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "inner" }, [
      _c(
        "ul",
        _vm._l(_vm.grouped_estimates, function ({ estimates, project }) {
          return _c("li", { key: project.id, staticClass: "group" }, [
            _c("span", { staticClass: "name" }, [
              _c("strong", [_vm._v(_vm._s(project.ref))]),
              _vm._v(" - " + _vm._s(project.name) + " "),
            ]),
            _c(
              "ul",
              _vm._l(estimates, function (estimate) {
                return _c(
                  "li",
                  { key: estimate.id },
                  [
                    _c("estimate-block", {
                      attrs: { estimate: estimate, project: project },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }