<template>
    <content-block title="Component assignment">
        <estimate-stage-link :estimate="estimate" />

        <template v-if="estimate.stage">
            <estimate-component-link
                v-for="group of sorted_groups"
                :key="group.id"
                :estimate="estimate"
                :group="group"
            />
        </template>
        <el-alert
            v-else
            description="A stage is required to assign components."
            :closable="false"
            style="margin-top: 20px;"
        />
    </content-block>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';
import EstimateStageLink from '@/components/estimate/EstimateStageLink';
import EstimateComponentLink from '@/components/estimate/EstimateComponentLink';
import estimateMixin from '@/mixins/estimate.mixin';

export default {
    name: 'estimate-component-assignment',
    components: {EstimateStageLink, EstimateComponentLink, ContentBlock},
    props: {
        estimate: {
            type: Object,
            required: true,
        },
    },
    mixins: [estimateMixin],
    data() {
        return {
            groups: [],
            tasks: [],
            subtasks: [],
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        stages() {
            return this.$store.getters.stagesForProjectWithId(
                this.$route.params.project_id
            );
        },
        sorted_groups() {
            // groups, sorted and filtered by exclusion status
            return [...this.groups]
                .filter((g) => !this.isGroupExcluded(g, this.groupTasks(g.id)))
                .sort((a, b) => {
                    return a.sort - b.sort;
                });
        },
        mapped_tasks() {
            // tasks list, with injected subtasks
            return this.tasks.map((t) => {
                return {
                    ...t,
                    id: t.id,
                    subtasks: this.subtasks.filter(
                        (s) => t.id === this.$options.filters.fireRef2id(s.task)
                    ),
                };
            });
        },
    },
    mounted() {
        this.$bind(
            'groups',
            this.$fire
                .collection('estimate_groups')
                .where(
                    'estimate',
                    '==',
                    this.$fire.doc(`estimates/${this.estimate.id}`)
                ),
            {
                maxRefDepth: 0,
            }
        );
        this.$bind(
            'tasks',
            this.$fire
                .collection('estimate_tasks')
                .where(
                    'estimate',
                    '==',
                    this.$fire.doc(`estimates/${this.estimate.id}`)
                ),
            {
                maxRefDepth: 0,
            }
        );
        this.$bind(
            'subtasks',
            this.$fire
                .collection('estimate_subtasks')
                .where(
                    'estimate',
                    '==',
                    this.$fire.doc(`estimates/${this.estimate.id}`)
                ),
            {
                maxRefDepth: 0,
            }
        );
    },
    methods: {
        groupTasks(group_id) {
            return this.mapped_tasks.filter(
                (t) => group_id === this.$options.filters.fireRef2id(t.group)
            );
        },
    },
};
</script>
