<template>
    <div class="tree-list-container" :style="containerStyle">
        <!-- the thing -->
        <div v-if="cur_node.listComponent" class="row" :style="rowStyle">
            <component
                :is="cur_node.listComponent"
                :cur_node="cur_node"
                :depth="depth"
                :tree="tree"
                @toggle:shy="toggleShyNode({node: cur_node})"
                @toggle:hide="toggleShowHideNode({node: cur_node})"
                @update:node="handleUpdateNode"
                @click="handleClickNode"
            />
        </div>

        <!-- the recursion -->
        <template v-if="show_children">
            <tree-list
                v-for="node in filteredNodes"
                :key="`tree-list-${node.id}`"
                :depth="depth + 1"
                :cur_node="node"
                :nodes="node.children"
            />
        </template>
    </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import EventBus from '@/components/timeline-v2/EventBus';

export default {
    name: 'tree-list',
    props: {
        nodes: {
            type: Array,
            required: true,
        },
        depth: {
            type: Number,
            required: true,
        },
        cur_node: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {};
    },

    computed: {
        show_children() {
            return this.showChildren(this.cur_node);
        },
        filteredNodes() {
            if (!this.filterShyNodes) {
                return this.nodes;
            }

            return this.nodes.filter((n) => !this.isNodeShy(n));
        },

        translateX() {
            return 10;
        },

        containerStyle() {
            return this.cur_node.containerStyle;
        },

        rowStyle() {
            return this.cur_node.rowStyle;
        },

        ...mapState(['tree', 'filterShyNodes']),
        ...mapGetters(['showChildren', 'isNodeShy']),
    },

    methods: {
        handleUpdateNode(payload) {
            console.log(payload);
            EventBus.$emit('update:node', payload);
        },

        handleClickNode(node) {
            EventBus.$emit('click:node', node);
        },

        ...mapActions(['toggleShowHideNode', 'toggleShyNode']),
    },
};
</script>

<style scoped lang="scss">
.tree-list-container {
    width: 100%;

    .row {
        cursor: pointer;
    }
}
</style>
