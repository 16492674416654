<template>
    <transition name="fade">
        <div class="fullscreen">
            <loader v-if="loading" :message="message" />

            <span class="copyright">
                Made with <heart :width="12" /> by CodeFish Studio
            </span>
        </div>
    </transition>
</template>

<script>
import Heart from '@/components/generic/Heart';
import Loader from '@/components/generic/Loader';

export default {
    name: 'fullscreen-loader',
    components: {
        Heart,
        Loader,
    },
    props: {
        message: {
            type: String,
            required: false,
            default: null,
        },
        loading: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 99999;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .copyright {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: block;
        text-align: center;
        user-select: none;
        font-size: 13px;
        font-weight: 400;
        color: $black;
        margin-bottom: 50px;

        svg {
            margin: 0 3px;
        }
    }
}

.fade {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.3s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
</style>
