<template>
    <div v-if="line_item" class="container">
        <el-row :gutter="12">
            <el-col :lg="12" :sm="12" class="info">
                <span class="description">
                    {{ show_invoice_id ? line_item.id : line_item.description }}
                </span>
                <div class="info">
                    <label>Amount:</label>
                    <span>{{ line_item.line_amount | currency }}</span>

                    <label>Unit:</label>
                    <span>
                        {{ line_item.unit_amount | currency }}
                        <small v-if="line_item.discount_rate">
                            ( -{{ line_item.discount_rate }}% )
                        </small>
                    </span>

                    <label>(prices ex. GST)</label>
                </div>
                <div class="info">
                    <label>Quantity:</label>
                    <span>{{ line_item.quantity }}</span>
                </div>
                <div class="info">
                    <label>Rate:</label>
                    <span class="flex">
                        <b>
                            {{ lineItem_rate(line_item).toFixed(2) | currency }}
                        </b>
                        <i>|</i>
                        {{ lineItem_rate_type(line_item) }}
                        <small
                            v-if="
                                lineItem_rate_type(line_item) === RATE_UNIT.DAY
                            "
                        >
                            ( {{ lineItem_daily_hours(line_item) }}h )
                        </small>
                    </span>

                    <el-popover v-model="override.rate.visible" placement="top">
                        <div class="popover">
                            <el-form
                                size="mini"
                                @submit.prevent.native="setRate"
                            >
                                <el-form-item>
                                    <money
                                        v-model="override.rate.value"
                                        v-bind="money"
                                        class="el-input__inner"
                                    />
                                    <el-button @click="setDefaultRateValue">
                                        Default
                                    </el-button>
                                </el-form-item>
                                <el-form-item
                                    v-if="override.rate.type === RATE_UNIT.DAY"
                                >
                                    <el-input-number
                                        v-model="override.rate.daily_hours"
                                        size="mini"
                                        controls-position="right"
                                    />
                                    <el-button @click="setCalculatedRateValue">
                                        Calculated
                                    </el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-radio-group
                                        v-model="override.rate.type"
                                        size="mini"
                                    >
                                        <el-radio-button
                                            :label="RATE_UNIT.HOUR"
                                        />
                                        <el-radio-button
                                            :label="RATE_UNIT.DAY"
                                        />
                                    </el-radio-group>

                                    <el-button
                                        type="primary"
                                        size="mini"
                                        :loading="override.rate.saving"
                                        @click="setRate"
                                    >
                                        Save
                                    </el-button>
                                </el-form-item>
                            </el-form>
                        </div>

                        <span slot="reference" class="link">
                            Edit
                        </span>
                    </el-popover>
                </div>
                <div class="info">
                    <label>Given time:</label>
                    <span>
                        <b>{{ lineItem_quantity(line_item).toFixed(1) }}h</b>
                    </span>
                </div>

                <div v-if="is_linked || line_item.project_ref" class="status">
                    <template v-if="is_linked">
                        <div class="default">
                            <invoice-status-icon
                                :status="INVOICE_STATUS.PAID"
                                class="icon"
                            />
                            <span>
                                Line item linked
                            </span>
                        </div>
                        <div class="hover" @click="removeLink">
                            <invoice-status-icon
                                :status="INVOICE_STATUS.ERROR"
                                class="icon-error"
                            />
                            <span>
                                Remove link
                            </span>
                        </div>
                    </template>
                    <template v-else-if="line_item.project_ref">
                        <span>
                            Suggestion:
                        </span>
                        <el-tag
                            type="info"
                            :disable-transitions="true"
                            size="small"
                        >
                            {{ line_item.project_ref }}
                        </el-tag>
                    </template>
                </div>
            </el-col>

            <el-col :lg="12" :sm="12">
                <project-picker
                    :projects="projects"
                    :project="project"
                    @change="changedProject"
                />

                <component-picker
                    v-if="project"
                    :project_id="project.id"
                    :component="component"
                    @change="changedComponent"
                />
                <el-alert v-if="error" :description="error" :closable="false" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import InvoiceStatusIcon from '@/components/generic/InvoiceStatusIcon';
import ProjectPicker from '@/components/generic/ProjectPicker';
import ComponentPicker from '@/components/generic/ComponentPicker';

import {Notification} from 'element-ui';

import componentInvoiceMixin from '@/mixins/component.invoice.mixin';

import {VMoney} from 'v-money';
import {INVOICE_STATUS, RATE_UNIT} from '@/enums';

export default {
    name: 'invoice-line-item-link-block',
    components: {
        ComponentPicker,
        InvoiceStatusIcon,
        ProjectPicker,
    },
    directives: {money: VMoney},
    mixins: [componentInvoiceMixin],
    props: {
        line_item: {
            type: Object,
            default: null,
        },
        projects: {
            type: Array,
            default: () => [],
        },
        show_invoice_id: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            RATE_UNIT: RATE_UNIT,
            hourly_rate: +process.env.VUE_APP_HOURLY_RATE,
            daily_rate:
                +process.env.VUE_APP_HOURLY_RATE *
                +process.env.VUE_APP_DAILY_HOURS,

            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
            },

            project: null,
            component: null,
            error: null,

            manually_linked: false,

            override: {
                rate: {
                    visible: false,
                    value: 0,
                    type: RATE_UNIT.DAY,
                    daily_hours: +process.env.VUE_APP_DAILY_HOURS,
                    editing: false,
                    saving: false,
                },
            },
        };
    },
    computed: {
        INVOICE_STATUS() {
            return INVOICE_STATUS;
        },
        stages() {
            if (!this.project) return [];

            const stages = this.$store.getters.stagesForProjectWithId(
                this.project.id
            );

            return stages.map((stage) => {
                stage._components = this.$store.getters.componentsForStageWithId(
                    stage.id
                );
                return stage;
            });
        },
        is_linked() {
            if (!this.line_item) return false;
            return !!this.line_item.component || this.manually_linked;
        },
    },
    watch: {
        'override.rate.type': function (val) {
            if (val && val === RATE_UNIT.DAY) {
                this.override.rate.value =
                    this.line_item.unit_amount / this.override.rate.daily_hours;
            }
        },
        'override.rate.daily_hours': function (val) {
            if (this.override.rate.type === RATE_UNIT.DAY) {
                this.override.rate.value = this.line_item.unit_amount / val;
            }
        },
    },
    mounted() {
        // Get component object from line item
        if (this.line_item.component) {
            this.component = this.$store.getters.componentWithId(
                this.line_item.component
            );
        }

        // Get project object from line item
        if (this.line_item.project) {
            this.project = this.$store.getters.projectWithId(
                this.line_item.project
            );
        }

        // Init overrides
        this.override.rate.value = this.lineItem_rate(this.line_item);
        this.override.rate.type = this.lineItem_rate_type(this.line_item);
        this.override.rate.daily_hours = this.lineItem_daily_hours(
            this.line_item
        );

        this.detectProject();
    },
    methods: {
        detectProject() {
            if (this.line_item.project) return;

            // Only one available project for this client
            // Preselect it
            if (this.projects.length === 1) {
                this.project = this.projects[0];
            }
            // More than one project
            // Loop through to try to find a matching one
            else {
                this.projects.forEach((project) => {
                    if (
                        project &&
                        project.ref &&
                        this.line_item.project_ref &&
                        project.ref.toLowerCase() ===
                            this.line_item.project_ref.toLowerCase()
                    ) {
                        this.project = project;
                    }
                });
            }

            // Show error if the project has not been found
            if (this.line_item.project_ref && !this.project) {
                this.error = 'Project not found between the available options.';
            }
        },
        changedProject(val) {
            this.removeLink();
            this.error = null;
            this.project = val;
        },
        setDefaultRateValue() {
            if (this.override.rate.type === RATE_UNIT.DAY) {
                this.override.rate.value = this.daily_rate;
            } else if (this.override.rate.type === RATE_UNIT.HOUR) {
                this.override.rate.value = this.hourly_rate;
            }
        },
        setCalculatedRateValue() {
            if (this.override.rate.type === RATE_UNIT.DAY)
                this.override.rate.value = this.lineItem_rate(
                    this.line_item,
                    false
                );
        },
        setRate() {
            this.override.rate.saving = true;

            this.$fire
                .doc(`line_items/${this.line_item.id}`)
                .update({
                    rate: parseFloat(this.override.rate.value),
                    rate_type: this.override.rate.type,
                    daily_hours: this.override.rate.daily_hours,
                })
                .then(() => {
                    console.log('Line item rate updated');
                    this.override.rate.editing = false;
                })
                .catch((e) => {
                    Notification({
                        type: 'error',
                        title: 'Error',
                        message: 'Something went wrong while setting rate.',
                    });
                })
                .finally(() => {
                    this.override.rate.saving = false;
                    this.override.rate.visible = false;
                });
        },
        changedComponent(val) {
            this.component = val;
            this.manually_linked = true;
            console.log('Linking...');

            this.$fire
                .doc(`line_items/${this.line_item.id}`)
                .update({
                    project: this.$fire.doc(`projects/${this.project.id}`),
                    component: this.$fire.doc(
                        `components/${this.component.id}`
                    ),
                })
                .then(() => {
                    console.log('Component linked!');
                })
                .catch((e) => {
                    Notification({
                        type: 'error',
                        title: 'Error',
                        message:
                            'Something went wrong while linking the invoice. (200)',
                    });
                });
        },
        removeLink() {
            this.error = null;
            this.project = null;
            this.component = null;
            this.manually_linked = false;
            console.log('Removing link...');

            this.$fire
                .doc(`line_items/${this.line_item.id}`)
                .update({
                    project: null,
                    component: null,
                })
                .then(() => {
                    console.log('Link removed!');
                    this.detectProject();
                })
                .catch((e) => {
                    Notification({
                        type: 'error',
                        title: 'Error',
                        message:
                            'Something went wrong while removing the link. (201)',
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    display: grid;
    width: 100%;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid $border-grey;
    background-color: $white;
    box-sizing: border-box;
    user-select: none;

    .el-select {
        width: 100%;
        margin-bottom: 10px;

        &:last-of-type {
            margin: 0;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span.description {
            font-size: 14px;
            display: block;
            user-select: all;
            margin-bottom: 10px;
            white-space: break-spaces;
        }

        .info {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 5px;

            label {
                font-size: 10px;
                opacity: 0.8;
                margin-right: 5px;
            }

            span {
                font-size: 12px;
                margin-right: 10px;
                padding-right: 10px;
                border-right: 1px solid $border-grey-light;

                i {
                    margin: 0 5px;
                    margin-top: -2px;
                }

                small {
                    margin-left: 4px;
                }

                &.flex {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }

                &:last-of-type {
                    border: none;
                }

                &.link {
                    font-size: 10px;
                    color: $blue;
                    margin: 0;
                    padding: 0;
                    cursor: pointer;
                }
            }
        }

        .status {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid $border-grey-light;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 200px;

            .el-tag {
                margin-left: 10px;
            }

            & .default,
            & .hover {
                flex: 1;
                cursor: pointer;
            }

            & .hover {
                display: none;
            }

            div {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            span {
                font-size: 12px;
                opacity: 0.8;
                flex: 1;
            }

            ::v-deep .badge {
                margin-right: 10px;
                display: inline-block;

                &.icon-error svg {
                    fill: $red;
                }
            }

            &:hover {
                & .hover {
                    display: flex;
                }
                & .default {
                    display: none;
                }
            }
        }
    }
}

.popover {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    ::v-deep .el-form-item {
        margin-bottom: 5px;

        .el-form-item__content {
            display: flex;
            align-items: flex-start;

            .el-input__inner {
                height: 28px;
                line-height: 28px;
                font-size: 12px;
                width: 130px;
            }
        }

        .el-button {
            height: 28px;
            margin-left: 5px;
            flex: 1;
            min-width: 80px;
        }
    }
}
</style>
