var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outer" }, [
    _c(
      "header",
      [
        _c(
          "el-radio-group",
          {
            attrs: { size: "mini" },
            model: {
              value: _vm.templateType,
              callback: function ($$v) {
                _vm.templateType = $$v
              },
              expression: "templateType",
            },
          },
          _vm._l(_vm.TEMPLATE_TYPES, function (type) {
            return _c(
              "el-radio-button",
              { key: type, attrs: { label: type } },
              [_vm._v(" " + _vm._s(_vm._f("capitalize")(type)) + " ")]
            )
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "inner" },
      [_c(_vm.component, { tag: "component" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }