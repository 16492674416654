/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'jira-default': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<rect pid="0" _stroke="#FFFFFF80" width="14" height="14" rx="2" transform="translate(1 1)" _fill="none" fill-rule="evenodd"/>'
  }
})
