<template>
    <span v-html="text"></span>
</template>

<script>
export default {
    name: 'todo-text',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
};
</script>

<style></style>
