<template>
    <div>
        <navbar />
        <breadcrumb />
        <div class="page">
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>

            <time-tracking-bar />
        </div>

        <!-- MODALS -->
        <settings-modal :show_dialog.sync="show['settings-modal']" />
        <change-password-modal :show_dialog.sync="show['change-password']" />
        <command-palette-modal
            v-shortkey="{meta: ['meta', 'k'], ctrl: ['ctrl', 'k']}"
            :show_dialog.sync="show['command-palette']"
            @shortkey.native.prevent="
                modalAction({
                    modal: 'command-palette',
                    show: !show['command-palette'],
                })
            "
        />
    </div>
</template>

<script>
import Navbar from '@/layout/Base/components/Navbar';
import Breadcrumb from '@/layout/Base/components/Breadcrumb';
import TimeTrackingBar from '@/layout/Base/components/TimeTrackingBar';
import ChangePasswordModal from '@/components/settings/ChangePasswordModal.vue';
import SettingsModal from '@/components/settings/SettingsModal.vue';
import CommandPaletteModal from '@/components/command-palette/CommandPaletteModal';

import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';

export default {
    name: 'base-layout',
    components: {
        ChangePasswordModal,
        CommandPaletteModal,
        Navbar,
        Breadcrumb,
        TimeTrackingBar,
        SettingsModal,
    },
    data() {
        return {
            show: {
                ['settings-modal']: false,
                ['change-password']: false,
                ['command-palette']: false,
            },
        };
    },
    computed: {
        SUPPORTED_FEATURES() {
            return SUPPORTED_FEATURES;
        },
    },
    mounted() {
        this.$bus.$on('modal:action', this.modalAction);
    },
    unmounted() {
        this.$bus.$off('modal:action');
    },
    methods: {
        modalAction(arg) {
            if (arg.show)
                Object.keys(this.show).forEach((modal) => {
                    this.show[modal] = modal === arg.modal;
                });
            else this.show[arg.modal] = arg.show;
            // if emit arg is set, fire a followup event
            if (arg.emit) {
                this.$bus.$emit(arg.emit.event, arg.emit.payload);
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
::v-deep .page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    .fade {
        &-enter-active,
        &-leave-active {
            transition: opacity 0.2s ease-in-out;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
        }
    }
}
</style>
